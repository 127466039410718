import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

// Connects to data-controller="notification"
export default class extends Controller {
  static targets = [ "notification" ]
  static values = { url: String, filter: String }

  read() {
    patch(this.urlValue)
  }

  read_all() {
    const notification_ids = this.notificationTargets.map(target => target.id.replace(/^\D+/g, ''));
    patch(`/profile/notifications/bulk_update?ids=${notification_ids}&filter=${this.filterValue}` ,{
      responseKind: "turbo-stream"
    });
  }
}