import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "title", "description", "duration", "language", "categories", "reportObjectives" , "reportExampleUrl", "subtasks"];

  closeModal() {
    this.modalTarget.classList.add('hidden');
    document.getElementById('overlay').classList.add('hidden');
  }
}
