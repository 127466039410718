import {Controller} from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"

// This stimulus controller will convert the rendered aside into a Flowbite drawer. It has a toggle method to open/close the drawer.
// Connects to data-controller="sidebar"
export default class extends Controller {
  static debounces = ['submitWithDebounce']

  connect() {
    useDebounce(this, { wait: 500 })

    this.drawer = new Drawer(this.element, {
      placement: 'right',
      backdrop: false,
      bodyScrolling: true
    })
  }

  toggle() {
    this.drawer.toggle()
  }

  hide() {
    this.drawer.hide()
  }

  submit() {
    event.target.form.requestSubmit();
  }

  submitWithDebounce(event) {
    event.target.form.requestSubmit();
  }
}
