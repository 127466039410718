import {Controller} from "@hotwired/stimulus"
import {useHotkeys} from "stimulus-use"

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ['query']
  static outlets = ['filterbar', 'sidebar']

  connect() {
    useHotkeys(this, {
      '/': [this.focusSearch]
    })
  }

  process() {
    this.filterbarOutlet.search(this.queryTarget.value)
  }

  toggleSidebar() {
    this.sidebarOutlet.toggle()
  }

  focusSearch(event) {
    event.preventDefault()
    this.queryTarget.focus()
    this.queryTarget.select()
  }
}
