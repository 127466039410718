import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['submit']

  connect() {
    this.element.addEventListener('input', () => { this.toggleSubmit() })
    this.element.addEventListener('formStateChanged', () => { this.toggleSubmit() });
    if (this.element.dataset.reload === 'true') { this.toggleSubmit() }
  }

  toggleSubmit() {
    if (this.element.checkValidity() === true && this.requiredSelects() === true) {
      this.submitTargets.forEach(target => {
        target.disabled = false;
        target.classList.remove('cursor-not-allowed');
      });
    } else {
      this.submitTargets.forEach(target => {
        target.disabled = true;
        target.classList.add('cursor-not-allowed');
      });
    }
  }

  requiredSelects() {
    let valid_selects = true
    let required_selects = document.querySelectorAll(".required")
    required_selects.forEach(select => {
      if (select.value === '') {
        valid_selects = false
      }
    });
    return valid_selects
  }

  disable(){
    this.submitTargets.forEach(target => {
      target.disabled = true;
      target.classList.add('cursor-not-allowed');
    });
    this.element.submit();
  }
}
