import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static values = { 'text': String }

  copy() {
    navigator.clipboard.writeText(this.textValue);
  }
}
