import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = [ "info-modal" ]

  static values = {
    title: String,
    description: String,
    duration: String,
    language: String,
    reportObjectives: String,
    categories: String,
    reportExampleUrl: String,
    subtasks: String,
  };

  openModal() {
    const modal = this.infoModalOutletElement;
    if (modal) {

      this.assignValueToTarget('title', this.titleValue, false);
      this.assignValueToTarget('description', this.descriptionValue, false);
      this.assignValueToTarget('language', this.languageValue, true);
      this.assignValueToTarget('duration', this.durationValue, true);
      this.assignValueToTarget('categories', this.categoriesValue, true);
      this.assignValueToTarget('reportObjectives', this.reportObjectivesValue, true);
      this.assignValueToTarget('subtasks', this.subtasksValue, false)

      if (this.infoModalOutlet.reportExampleUrlTarget) {
        this.assignValueToExampleUrlTarget()
      }

      modal.classList.remove('hidden');
      document.getElementById('overlay').classList.remove('hidden');
    }
  }

  assignValueToTarget(targetName, value, hideSiblingSvgWhenEmpty) {
    const target = this.infoModalOutlet[`${targetName}Target`];
    if (target) {
      target.textContent = value;
      this.updateSiblingSvgVisibility(target, value, hideSiblingSvgWhenEmpty);
    }
  }

  updateSiblingSvgVisibility(target, value, hideWhenEmpty) {
    const siblingSvg = target.previousElementSibling;
    if (!siblingSvg) return;

    const shouldHide = hideWhenEmpty && value == '';
    siblingSvg.classList.toggle('hidden', shouldHide);
  }

  assignValueToExampleUrlTarget() {
    if (this.reportExampleUrlValue != '') {
      this.infoModalOutlet.reportExampleUrlTarget.previousElementSibling.classList.remove('hidden')
      this.infoModalOutlet.reportExampleUrlTarget.classList.remove('invisible')
      this.infoModalOutlet.reportExampleUrlTarget.href = this.reportExampleUrlValue
    } else {
      this.infoModalOutlet.reportExampleUrlTarget.previousElementSibling.classList.add('hidden')
      this.infoModalOutlet.reportExampleUrlTarget.classList.add('invisible')
    }
  }
}
