{
  "fr": {
    "accounts": {
      "passwords": {
        "form": {
          "login": "se connecter",
          "submit": "Demander"
        }
      },
      "sessions": {
        "form": {
          "forgot_your_password": "Vous avez oublié votre mot de passe?",
          "submit": "se connecter"
        },
        "new": {
          "title": "se connecter"
        }
      },
      "switch_users": {
        "success": "Connexion réussie à %{organization}"
      }
    },
    "action_menu": {
      "admin": {
        "mail_templates": {
          "single": {
            "delete_confirm": "Êtes-vous sûr de vouloir supprimer ce modèle d'e-mail ?"
          }
        }
      },
      "assessments": {
        "labels": {
          "single": {
            "delete_confirm": "Êtes-vous sûr de vouloir supprimer ce libellé ?"
          }
        },
        "program_templates": {
          "single": {
            "delete_confirm": "Êtes-vous sûr de vouloir supprimer ce processus ?"
          }
        },
        "programs": {
          "single": {
            "delete_confirm": "Êtes-vous sûr de vouloir supprimer ce processus ?"
          }
        }
      },
      "deactivation_confirm": "En désactivant le %{name}, %{name} n'aura plus accès au site et sera automatiquement supprimé sur %{retention_period}. Veuillez noter que les %{type} inactifs sont masqués par les paramètres de filtre par défaut. Ajustez vos filtres si vous souhaitez les inclure dans l'aperçu.",
      "delete_confirm": "La suppression de %{name} supprime également automatiquement toutes les trajectoires sous-jacentes possibles.",
      "ixly": {
        "migration_organizations": {
          "default": {
            "add": "Ajouter une nouvelle organisation de migration",
            "start": "Démarrer les migrations"
          }
        },
        "subscriptions": {
          "single": {
            "delete_confirm": "Êtes-vous sûr de vouloir supprimer cet abonnement ?"
          }
        }
      },
      "selection": {
        "job_profiles": {
          "single": {
            "delete_confirm": "Êtes-vous sûr de vouloir supprimer ce profil d'emploi ?"
          }
        }
      }
    },
    "activemodel": {
      "attributes": {
        "queries/usage": {
          "end_date": "Date de fin",
          "organization": "Organisation",
          "start_date": "Date de début"
        }
      }
    },
    "admin": {
      "authentication_actions": {
        "filter_form": {
          "authenticate": "se connecter",
          "impersonate": "Se connecter en tant que..."
        },
        "index": {
          "action": "Action",
          "browser": "Browser",
          "caption": "Vous trouverez ci-dessous tous les événements liés à l'authentification. Ces événements sont conservés pendant une durée maximale de 6 mois.",
          "created_at": "Quand",
          "ip": "adresse IP",
          "title": "Journaux d'authentification"
        }
      },
      "candidate_csv_imports": {
        "import_file": {
          "log": "Enregistrer",
          "original": "Original"
        },
        "index": {
          "list": "Celui-ci contient les fichiers d'importation de candidats au sein de l'organisation",
          "title": "Importation CSV du candidat"
        },
        "new": {
          "title": "Importation CSV du candidat"
        }
      },
      "color_settings": {
        "destroy": {
          "destroyed": "Les paramètres de couleur ont été réinitialisés aux valeurs par défaut.",
          "reset_button": "Restaurer le calendrier par défaut"
        },
        "edit": {
          "color_settings_explanation": "Vous pouvez modifier la palette de couleurs de la page de résultats interactive pour tous les utilisateurs en utilisant les champs ci-dessous",
          "title": "Schéma de couleur"
        },
        "update": {
          "updated": "Les paramètres de couleur de l'organisation ont été mis à jour avec succès."
        }
      },
      "default_mail_templates": {
        "could_not_be_saved": "Le modèle de courrier par défaut ne peut pas être enregistré",
        "explain": "Ici, vous pouvez choisir parmi les modèles de courrier organisationnel publiés et les définir par défaut. Il n'est pas possible de choisir les modèles de courrier de l'application Ixly. Si vous le souhaitez, vous devrez d'abord les copier dans votre propre organisation.",
        "header": "Paramètres de messagerie",
        "saved_successfully": "Modèle de courrier par défaut enregistré !",
        "updated_successfully": "Modèle de courrier par défaut mis à jour !"
      },
      "entries": {
        "index": {
          "candidate_assessment_entry": "Évaluation",
          "candidate_assessment_program_entry": "Programme d'évaluation",
          "candidate_report_entry": "Rapport",
          "generated_report_entry": "Rapport",
          "leniency_entry": "Gentillesse",
          "list": {
            "candidate": "Candidat",
            "cost_center": "Centre de coûts",
            "credits": "Nombre",
            "date": "Date",
            "description": "description",
            "entry_type": "Type de transaction"
          },
          "order": {
            "frequent_credits": "Débit mensuel des crédits utilisés",
            "incidental_credits": "Achat d'un forfait de crédits",
            "intensive_credits": "Débit mensuel des crédits utilisés"
          },
          "order_entry": "Commande",
          "title": "Aperçu du grand livre général"
        }
      },
      "mail_templates": {
        "add": "Ajouter un modèle d'e-mail",
        "create": {
          "success": "Le nouveau modèle d'e-mail a été créé"
        },
        "destroy": {
          "deleted": "Le modèle d'e-mail a été supprimé"
        },
        "duplicate": {
          "duplicated": "Le modèle d'e-mail '%{title}' a été copié"
        },
        "duplicated_title": "Copie de %{title}",
        "edit": {
          "title": "Modifier le modèle d'e-mail"
        },
        "form": {
          "assessment_platform_link_explanation": "Un lien qui dirige le candidat vers le bon endroit dans la plateforme d'évaluation. S'ils n'ont pas encore défini de mot de passe, ils seront dirigés vers la page de définition du mot de passe, sinon vers la page de connexion. Ce champ est obligatoire*",
          "assignment_title_explanation": "Les titres des missions assignées",
          "candidate_name_explanation": "Le nom d'utilisateur",
          "end_date_explanation": "La dernière date à laquelle la piste peut être terminée",
          "explanation_text": "Les variables peuvent être utilisées dans le texte du modèle de courrier pour insérer dynamiquement du contenu. Ces variables sont entourées de %{VARIABLE}, où VARIABLE est le nom, et où il est complètement remplacé. Nous prenons actuellement en charge les éléments suivants",
          "organization_name_explanation": "Le nom de l'organisation actuelle"
        },
        "index": {
          "help_text": "Les modèles de courrier sont les e-mails prédéfinis que vos conseillers peuvent envoyer aux candidats. Seuls les modèles publiés peuvent être sélectionnés par un conseiller. Des modèles de candidature sont fournis à titre d’exemple.",
          "title": "Modèles d'e-mails"
        },
        "list": {
          "application_template": "Modèles de candidature",
          "language": "Langue",
          "state": "Statut",
          "subject": "Sujet",
          "title": "Titre",
          "updated_at": "Mis à jour le"
        },
        "new": {
          "title": "Nouveau modèle d'e-mail"
        },
        "publish": {
          "cannot_publish": "Le modèle d'e-mail %{title} ne peut pas être publié :<br> %{error_messages}",
          "published": "Le modèle d'e-mail %{title} a été publié"
        },
        "show": {
          "body": "Contenu de l'e-mail",
          "example_title": "Exemple de l'email qui sera envoyé",
          "subject": "Sujet",
          "title": "Exemple du modèle d'e-mail %{title}"
        },
        "update": {
          "cannot_publish": "Le modèle d'e-mail n'a pas pu être mis à jour :<br> %{error_messages}",
          "success": "Modèle d'e-mail mis à jour",
          "title": "Modifier le modèle d'e-mail"
        },
        "withdraw": {
          "withdrawn": "Le modèle d'e-mail %{title} a été retiré"
        }
      },
      "organization_permissions": {
        "edit": {
          "help_text": "Ici, vous pouvez limiter les rôles d'utilisateur qui ont accès à quelles parties de l'application",
          "title": "Droits de l'utilisateur"
        },
        "update": {
          "updated": "Les autorisations de l'organisation ont été mises à jour."
        }
      },
      "organization_settings": {
        "default_report_objective": {
          "advice": "Conseil",
          "selection": "Sélection"
        },
        "default_report_to": {
          "advisor": "L'entraîneur",
          "both": "Les deux",
          "candidate": "Le candidat"
        },
        "edit": {
          "available_languages_explanation": "Si vous désactivez les langues utilisées par vos utilisateurs, leurs paramètres de langue passeront automatiquement à la langue par défaut de votre organisation.",
          "default_language_explanation": "La langue sélectionnée sert de paramètre par défaut pour les nouveaux utilisateurs au sein de l'organisation.",
          "default_report_objective_explanation": "L'objectif de reporting par défaut sera sélectionné par défaut lors de la création d'un nouveau candidat. Lorsque vous évaluez des candidats pour les conseiller dans leur évolution, choisissez « Conseil » ; lorsque vous évaluez des candidats pour les sélectionner parmi eux, choisissez « Sélection ». Sur la base de cet objectif, nous sélectionnerons automatiquement le rapport le plus pertinent pour ce candidat.",
          "default_report_to_explanation": "Ce paramètre détermine qui obtient un aperçu du rapport une fois l'évaluation terminée : le candidat, le conseiller ou les deux. Si « conseiller » est sélectionné, le conseiller aura la possibilité de définir cela par évaluation. Lors de la modification d'un processus, cela peut toujours être défini par évaluation.",
          "title": "Paramètres de l'organisation"
        },
        "update": {
          "required_languages": "Les langues disponibles ne peuvent pas être vides et la langue par défaut doit être disponible dans l'organisation",
          "updated": "Les paramètres de l'organisation ont été mis à jour avec succès."
        }
      },
      "privacy_settings": {
        "edit": {
          "advisor_deactivation_period_explanation": "La période d'inactivité après laquelle les coachs sont désactivés.",
          "candidate_deactivation_period_explanation": "La période d'inactivité après laquelle les candidats sont désactivés.",
          "impersonation_explanation": "Si vous activez cette option, les conseillers disposant des droits « Se connecter en tant que » peuvent se connecter aux comptes qu'ils gèrent.",
          "ixly_support_explanation": "Si vous activez cette option, vous donnez la possibilité aux collaborateurs du helpdesk Ixly de suivre vos utilisateurs pour résoudre les problèmes signalés via le helpdesk.",
          "retention_period_explanation": "Le Règlement Général sur la Protection des Données (RGPD) stipule que les données personnelles ne peuvent être conservées plus longtemps que nécessaire. Pour respecter cela, les candidats, conseillers et contacts inactifs sont automatiquement supprimés en fonction de la durée de conservation choisie. Cette durée de conservation s’applique également aux évaluations utilisées et aux rapports générés.",
          "title": "Confidentialité et conservation des données"
        },
        "update": {
          "updated": "Les paramètres de confidentialité de l'organisation ont été mis à jour avec succès."
        }
      },
      "whitelabel": {
        "edit": {
          "title": "Paramètres de marque blanche"
        },
        "update": {
          "whitelabel_updated": "La marque blanche a été mise à jour"
        }
      }
    },
    "advisors": {
      "index": {
        "title": "Conseillers"
      }
    },
    "alt_texts": {
      "approval_request_send_reason": "Envoyer cette raison",
      "component_add": "Ajouter cet élément",
      "component_delete": "Supprimer cet élément",
      "component_move": "Déplacer cet élément",
      "component_settings": "Ouvrez les paramètres de l'élément",
      "details_collapse": "Réduire les détails",
      "details_expand": "Développez les détails",
      "document_delete": "Supprimer ce rapport enregistré",
      "document_download": "Téléchargez ce rapport enregistré",
      "document_save": "Enregistrez ce rapport",
      "document_share": "Partagez ce rapport enregistré avec le candidat",
      "document_unshare": "Partagez les paramètres ci-dessus avec le candidat",
      "enrollment_page_print": "Imprimer cette étape",
      "enrollment_page_requiring_action": "Contient des éléments avec des paramètres supplémentaires",
      "file_download": "Téléchargez ce fichier",
      "filter_bar_collapse": "Réduire les filtres",
      "filter_bar_expand": "Développez les filtres",
      "interactive_results_agile_average": "Graphique en ligne pointillée à %{percentage} pour cent à partir de la droite. Plus ce pourcentage est élevé, plus vous êtes agile.",
      "interactive_results_agile_pillars": "Graphique montrant vos sous-scores sous forme de secteurs cliquables d'un diagramme circulaire. Force %{power}, Changement %{change}, Croissance personnelle %{growth}.",
      "interactive_results_info_birthdate": "Date de naissance",
      "interactive_results_info_date_of_birth": "Date de naissance",
      "interactive_results_info_education": "Niveau d'éducation",
      "interactive_results_info_email": "Adresse e-mail",
      "interactive_results_info_study_subject": "Éducation",
      "interactive_results_info_work_experience": "Expérience professionnelle la plus récente",
      "interactive_results_info_years_experience": "Nombre d'années d'expérience",
      "interactive_results_information": "Ouvrir l'explication des scores",
      "interactive_results_intelligence_chart": "Graphique montrant votre score de QI de %{iq} sur une distribution normale. Les lignes pointillées montrent les marges d'erreur.",
      "interactive_results_next": "suivante",
      "interactive_results_paging": "Page %{page} sur %{pages}",
      "interactive_results_previous": "Précédent",
      "interactive_results_rating": "%{score} étoiles sur %{max}",
      "interactive_results_tip": "Ouvrez la pointe",
      "ixly_test_download": "Téléchargez ce rapport",
      "ixly_test_settings": "Ouvrez les paramètres du rapport",
      "loading": "Chargeons...",
      "navbar_expand": "Développez le menu de navigation",
      "notification_close": "Fermez cette tâche",
      "notification_reopen": "Rouvrir cette tâche",
      "organization_logo": "Logo de %{name}",
      "organization_logo_change": "Logo de %{name} – cliquez pour changer",
      "organization_logo_home": "Logo de %{name} – cliquez pour commencer",
      "post_rule_delete": "Supprimer cette ligne de partage",
      "price_in_euros": "en euros",
      "program_page_delete": "Supprimer cette étape",
      "program_page_move": "Déplacez cette étape",
      "program_page_settings": "Ouvrez les paramètres de l'étape",
      "program_settings": "Ouvrez les paramètres du processus",
      "quiz_correct": "Cette réponse est correcte",
      "quiz_incorrect": "Cette réponse est incorrecte",
      "quiz_selected": "Cette réponse a été sélectionnée",
      "saved": "Vos changements ont été enregistrés",
      "saving": "Vos modifications sont enregistrées",
      "template_column_delete": "Supprimer cette colonne",
      "template_column_move": "Déplacer cette colonne",
      "template_column_settings": "Ouvrez les paramètres de la colonne",
      "template_element_delete": "Supprimer cette rubrique",
      "template_element_move": "Déplacer cette section",
      "template_element_settings": "Ouvrez la section Paramètres",
      "template_element_variables": "Ouvrez les paramètres des variables",
      "template_item_delete": "Supprimer cet élément",
      "template_row_delete": "Supprimer cette ligne",
      "template_row_move": "Déplacer cette ligne",
      "template_zoom_in": "Agrandir",
      "template_zoom_out": "Dézoomer",
      "top_step_delete": "Supprimer ce groupe d'étapes",
      "top_step_insert": "Insérer un groupe d'étapes",
      "user_avatar": "Photo du profil de %{name}",
      "user_avatar_change": "Photo de profil de %{name} – cliquez pour modifier",
      "view_switcher_cards": "Afficher sous forme de tuiles",
      "view_switcher_table": "Afficher sous forme de tableau"
    },
    "approval_requests": {
      "index": {
        "help_text": "Vous trouverez ici tous les documents qu'il vous a été demandé d'approuver",
        "table_header": [
          "Déposer",
          "Candidat",
          "Processus",
          "Statut",
          "Roulé",
          "Entraîneur"
        ],
        "title": "Accords"
      }
    },
    "assessments": {
      "advisors": {
        "account_email": "E-mail",
        "create": {
          "created": "Le conseiller a été créé",
          "created_and_invited": "Le conseiller a été créé et accueilli par email",
          "title": "Ajouter un conseiller"
        },
        "created_at": "Fait sur",
        "deactivate": {
          "advisor_deactivated": "Le conseiller a été désactivé"
        },
        "edit": {
          "labels": "Étiquettes",
          "title": "Modifier le conseiller"
        },
        "form": {
          "add_label": "Ajouter une étiquette",
          "impersonation_explanation": "Si vous sélectionnez cette option, vous donnez à cet utilisateur le droit de se connecter en tant qu'autre utilisateur",
          "role_explanation": "Cliquez pour afficher la documentation détaillée du rôle"
        },
        "index": {
          "list": "Tous les conseillers au sein de votre organisation sont affichés ici",
          "title": "Conseillers"
        },
        "label": {
          "remove": "Supprimer"
        },
        "last_activity": "Dernère activité",
        "list": {
          "candidates": "Candidats",
          "labels": "Étiquettes"
        },
        "name": "Nom",
        "new": {
          "title": "Ajouter un conseiller"
        },
        "organization": "Organisation",
        "privacy_accepted_at": "Confidentialité acceptée",
        "reactivate": {
          "advisor_reactivated": "Le conseiller a été réactivé"
        },
        "roles": "Rouler",
        "send_welcome": {
          "welcome_mail_sent": "L'email de bienvenue a été envoyé"
        },
        "status": "Statut",
        "two_factor": "Vérification en deux étapes",
        "type": "Taper",
        "unconfirmed_email": "E-mail non confirmé",
        "update": {
          "advisor_updated": "Le conseiller a été mis à jour",
          "title": "Modifier le conseiller"
        }
      },
      "assessment_report_settings": {
        "edit": {
          "title": "Paramètres du rapport"
        },
        "update": {
          "success": "Les paramètres du rapport ont été mis à jour avec succès"
        }
      },
      "assessment_verifications": {
        "link": "Vérification",
        "show": {
          "about_verification_tests": "Un test de vérification est une version facultative et raccourcie du %{assessment} complet. Les tests de capacité sont de plus en plus réalisés à distance, sans supervision. Dans cette situation, il est difficile d'évaluer si le candidat a passé l'examen lui-même et sans aucune aide. Vous pouvez améliorer cette évaluation en utilisant un test de vérification, effectué sous supervision.",
          "about_verification_tests_title": "À propos des tests de vérification",
          "run_test": "Maintenant, lancez le test de vérification",
          "title": "Authentification requise pour %{assessment} sur %{candidate}",
          "verification_tests_report": "Une fois le test de vérification créé, vous recevrez une page supplémentaire dans le rapport indiquant si les scores du %{assessment} précédemment créé correspondent quelque peu aux scores du test de vérification. Si tel est le cas, vous verrez une coche verte dans le rapport. Si vous voyez une croix rouge dans le rapport, la différence entre les résultats du %{assessment} distant et le test de vérification est plus grande que ce à quoi on pourrait s'attendre sur la base du hasard.",
          "verification_tests_report_title": "Différences dans le rapport",
          "why_skip_verification_tests": "Si un candidat a complété un C'est également moins pertinent si les résultats du candidat sont inférieurs au niveau attendu. Le test de vérification n'indique pas si le candidat a obtenu un score plus élevé après avoir passé ce test de vérification. Vous pouvez seulement voir si les résultats du deuxième test correspondent à ceux du premier test.",
          "why_skip_verification_tests_title": "Parier ou ne pas parier ?"
        },
        "skip": {
          "success": "La vérification de %{title} a été ignorée avec succès"
        },
        "update": {
          "success": "La vérification de %{title} a démarré avec succès"
        }
      },
      "assignments": {
        "edit": {
          "end_date": "Ajuster la date de fin",
          "end_date_explanation": "Dernier jour où l'activité peut être réalisée",
          "invitation_date": "Modifier la date de l'invitation",
          "invitation_mail_title": "Personnaliser l'e-mail d'invitation"
        },
        "invite": {
          "candidate_double_invited": "Le candidat a été invité par email et prévenu à %{title}",
          "candidate_invited": "Le candidat a été prévenu par email à %{title}",
          "cannot_invite": "Il n'a pas été possible d'inviter le candidat à %{title}, le candidat était déjà invité."
        },
        "prepare_invite": {
          "title": "Inviter %{candidate} à %{assessment}"
        },
        "updated": "Devoir mis à jour avec succès"
      },
      "bulk_assignments": {
        "already_assigned": "%{assignment} est déjà sélectionné",
        "assessment_not_available_in_subscription": "Ce processus comprend une évaluation qui n'est plus disponible dans votre abonnement. Ajustez le processus afin qu'il puisse être à nouveau attribué.",
        "blank_description": "Aucune description n'est disponible pour ce processus.",
        "create": {
          "action_required": "Le procès a été accordé. Une action est encore requise avant que le candidat puisse être invité.",
          "candidate_invited": "Le candidat a été informé du processus nouvellement attribué par e-mail",
          "cannot_enroll": "Vous ne pouvez pas actuellement attribuer ce processus",
          "enrollment_created": "Le procès a été accordé. Le candidat n'a pas encore été informé."
        },
        "duration": "%{n} minutes",
        "example_report": "Ouvrir un exemple de rapport dans un nouvel onglet",
        "more_information": "Plus d'information",
        "new": {
          "selected_assessment_programs": "Programmes d'évaluation sélectionnés",
          "selected_assessments": "Évaluations assignées",
          "selected_programs": "Processus assignés",
          "title": "Attribuer une évaluation - %{candidate}"
        },
        "preview": "Exemple"
      },
      "candidate_assessments": {
        "confirm_destroy": "Êtes-vous sûr de vouloir supprimer %{assessment_title} de %{candidate_name}?",
        "confirm_reset": "Êtes-vous sûr de vouloir réinitialiser l'évaluation %{assessment_title}? La réinitialisation supprimera toutes les réponses afin que le candidat puisse recommencer l'évaluation.",
        "destroy": {
          "success": "%{title} a été retiré avec succès"
        },
        "report_tos": {
          "advisor": "L'entraîneur",
          "both": "Les deux",
          "candidate": "Le candidat"
        },
        "reset": {
          "success": "%{title} a été réinitialisé avec succès"
        }
      },
      "candidate_email_messages": {
        "index": {
          "title": "E-mails envoyés à %{candidate}"
        },
        "list": {
          "sent_at": "Envoyé sur",
          "status": "Statut",
          "subject": "Sujet"
        }
      },
      "candidate_reports": {
        "add": "Générer un rapport",
        "create": {
          "success": "Le rapport est en cours de génération. Vous recevrez une notification lorsque le document sera prêt dans la documents & médiathèque."
        },
        "download": "Télécharger le rapport",
        "form": {
          "external_report": "Rapport pour %{candidate_assessment}",
          "generated_reports": "Rapports générés pour %{candidate_assessment}",
          "no_external_reports_generated_yet": "Aucun rapport n'a été généré pour %{candidate_assessment}",
          "no_reports_generated_yet": "Aucun rapport n'a été généré pour %{candidate_assessment}",
          "report_objective": "Objectif de reporting: %{norm_group}"
        },
        "form_list": {
          "document_options": "Options de rapport",
          "language": "Langue",
          "media_item": "Document",
          "no_media_item_available": "Document pas encore disponible",
          "norm_group_id": "Objectif du reporting",
          "shared_with": "Partagé avec",
          "title": "Rapport généré"
        },
        "index": {
          "link_title": "Rapports",
          "title": "Rapports de %{candidate}"
        },
        "list": {
          "assessment": "Assessment",
          "created_at": "Fait sur",
          "document_options": "Options de rapport",
          "language": "Langue",
          "media_item": "Document",
          "no_media_item_available": "Document pas encore disponible",
          "norm_group_id": "Objectif de reporting",
          "shared_with": "Partagé avec",
          "title": "Rapport"
        },
        "new": {
          "link_title": "Générer un rapport",
          "title": "Générer un rapport pour %{candidate}"
        },
        "shared_with": {
          "one": "et responsable de l'organisation %{count}",
          "other": "et %{count} responsables de l'organisation"
        },
        "stitch_reports": "Fusionner les rapports"
      },
      "candidate_results": {
        "index": {
          "title": "Aperçu des résultats personnels"
        }
      },
      "candidate_selection": {
        "active_filter_info": "<span class='font-bold'> / %{count}</span> du filtre actif sont sélectionnés.",
        "deselect_button": "tout désélectionner",
        "select_button": "Sélectionnez tout",
        "total_info": "<span class='font-bold'> / %{count} candidats</span> ont été sélectionnés."
      },
      "candidate_steps": {
        "create": {
          "action_required": "Le candidat a été créé. Une action est encore nécessaire pour inviter le candidat au processus",
          "candidate_created": "Le candidat a été créé",
          "candidate_invited": "Le candidat a été informé du processus nouvellement attribué par e-mail",
          "cannot_enroll": "Vous ne pouvez pas actuellement attribuer ce processus",
          "enrollment_created": "Vous avez créé <a class='underline font-bold' href='%{candidate_link}'>%{name}</a> et attribué <a class='underline font-bold' href='%{enrollment_link}'>%{title}</a>."
        },
        "filter": {
          "all": "Tous",
          "assessment_definitions": "Évaluations",
          "assessment_programs": "Programmes d'évaluation",
          "programs": "Processus"
        },
        "info_block": "Des informations détaillées",
        "new": {
          "candidate_title": "Ajouter un candidat",
          "coadvisor_title": "Attribuer une personne de contact",
          "enrollment_title": "Attribuer une évaluation"
        }
      },
      "candidates": {
        "add": "Ajouter un candidat",
        "add_label": "Ajouter une étiquette",
        "added": "Le candidat <a class='underline' href='%{link}'>%{name}</a> a été créé.",
        "assign_bulk_button": "Accorder",
        "contact_person": {
          "remove": "Supprimer"
        },
        "create": {
          "title": "Ajouter un candidat"
        },
        "deactivate": "Le candidat a été désactivé",
        "delete": "Supprimer le candidat",
        "deleted": "Le candidat %{name} a été supprimé",
        "edit": {
          "contact_persons": "Contacts",
          "labels": "Étiquettes",
          "title": "Modifier le candidat"
        },
        "export": {
          "started": "L'export des candidats est lancé. Vous recevrez une notification une fois l'exportation terminée."
        },
        "index": {
          "title": "Candidats"
        },
        "label": {
          "remove": "Supprimer"
        },
        "list": {
          "advisor": "Conseiller",
          "api_identifier": "Identifiant API",
          "coadvisor": "Co-conseiller",
          "contact_person": "Contacts",
          "cost_center": "Centre de coûts",
          "created_at": "Fait sur",
          "email": "E-mail",
          "first_name": "Prénom",
          "labels": "Étiquettes",
          "language": "Langue",
          "last_activity": "Dernier actif",
          "last_name": "nom de famille",
          "name": "Nom",
          "organization": "Organisation",
          "privacy_accepted_at": "Confidentialité acceptée",
          "report_objective": "Objectif du reporting",
          "status": "Statut",
          "track": "Dernière activité",
          "unconfirmed_email": "E-mail non confirmé"
        },
        "premium_candidate_tooltip": "C'est un candidat premium",
        "reactivated": "Le participant a été réactivé",
        "review_required": "Révision nécessaire",
        "update": {
          "title": "Modifier le candidat"
        },
        "updated": "Participant mis à jour",
        "upload_candidates": "Télécharger des candidats"
      },
      "candidates_contact_persons": {
        "show": {
          "Datum": "Créé à",
          "account_email": "Adresse e-mail",
          "contact_person": "Contact",
          "created_at": "A été fait pour",
          "name": "Nom",
          "organization": "Organisation",
          "privacy_accepted_at": "Confidentialité acceptée",
          "role": "Rôle",
          "status": "Statut"
        }
      },
      "clients": {
        "add": "Ajouter un client",
        "contact_person": {
          "edit": "Ajuster",
          "remove": "Supprimer"
        },
        "create": {
          "client_created": "Le client a été créé",
          "help_text": "Créez ici un nouveau client pour votre organisation",
          "title": "Nouvelle cliente"
        },
        "edit": {
          "labels": "Étiquettes",
          "title": "Modifier le client"
        },
        "form": {
          "add_contact_person": "Ajouter une personne à contacter",
          "add_label": "Ajouter une étiquette",
          "labels": "Étiquettes"
        },
        "index": {
          "help_text": "Vous trouverez ici tous les clients (entreprises) de votre organisation",
          "new_button": "Nouvelle cliente",
          "title": "Clientèle"
        },
        "label": {
          "remove": "Supprimer"
        },
        "list": {
          "candidates": "Candidats",
          "contact_persons": "Contacts",
          "name": "Nom",
          "updated_at": "Mis à jour le"
        },
        "new": {
          "help_text": "Créez ici un nouveau client pour votre organisation",
          "title": "Nouvelle cliente"
        },
        "row": {
          "number_of_candidates": "%{number} actif",
          "number_of_contact_persons": "%{number} actif"
        },
        "show": {
          "help_text": "Vous retrouverez ici tous vos contacts et candidats chez ce client",
          "new_button": "Nouvelle personne de contact",
          "table_header": [
            "Faits",
            "Adresse e-mail",
            "Statut"
          ]
        },
        "update": {
          "client_updated": "Le client a été mis à jour",
          "title": "Modifier le client"
        }
      },
      "clients_contact_persons": {
        "add": "Ajouter une personne à contacter",
        "create": {
          "created": "La personne de contact %{name} a été créée"
        },
        "deactivate": {
          "deactivated": "Le contact a été désactivé"
        },
        "edit": {
          "labels": "Étiquettes",
          "title": "Modifier le contact"
        },
        "form": {
          "add_label": "Ajouter une étiquette",
          "new": "Nouvelle personne de contact"
        },
        "index": {
          "title": "Contacts"
        },
        "label": {
          "remove": "Supprimer"
        },
        "list": {
          "account_email": "E-mail",
          "candidates": "Candidats",
          "client": "Client",
          "created_at": "A été fait pour",
          "labels": "Étiquettes",
          "list_text": "Ceci répertorie toutes les personnes de contact au sein de votre organisation",
          "name": "Nom",
          "organization": "Organisation",
          "privacy_accepted_at": "Confidentialité acceptée",
          "status": "Statut",
          "two_factor": "Authentification à 2 facteurs",
          "unconfirmed_email": "E-mail non confirmé",
          "updated_at": "Mis à jour le"
        },
        "new": {
          "title": "Nouvelle personne de contact",
          "title_with_client": "Nouvelle personne de contact pour le %{client}"
        },
        "reactivate": {
          "reactivated": "Le contact a été réactivé"
        },
        "send_welcome": {
          "welcome_mail_sent": "L'email de bienvenue a été envoyé"
        },
        "show": {
          "client": "Client",
          "email": "Adresse e-mail",
          "email_address": "Adresse e-mail",
          "status": "Statut"
        },
        "update": {
          "updated": "La personne de contact a été mise à jour"
        }
      },
      "contact_persons_labels": {
        "destroy": {
          "removed": "Libellé supprimé avec succès du contact"
        },
        "form": {
          "new": "Nouvelle étiquette"
        }
      },
      "dashboards": {
        "show": {
          "title": "Tableau de bord"
        }
      },
      "enrollers": {
        "add": "Ajouter un inscrit",
        "create": {
          "created": "Le lien d'inscription a été créé",
          "created_with_premium_components": {
            "one": "Le lien d'inscription a été créé (%{costs} par inscription dû à 1 élément premium)",
            "other": "Le lien d'inscription a été créé (%{costs} par inscription en raison de %{count} éléments premium)"
          },
          "title": "Nouveau lien d'inscription"
        },
        "created": "Le lien d'inscription a été créé",
        "created_with_premium_components": {
          "one": "Le lien d'inscription a été créé (%{costs} par inscription dû à 1 élément premium)",
          "other": "Le lien d'inscription a été créé (%{costs} par inscription en raison de %{count} éléments premium)"
        },
        "deactivate": {
          "deactivated": "Le lien d'inscription a été désactivé"
        },
        "destroy": {
          "deleted": "Le lien d'inscription a été supprimé",
          "destroy_confirm": "Etes-vous sûr de vouloir supprimer ce lien d'inscription ?"
        },
        "edit": {
          "explanation": "Les participants peuvent utiliser le lien ci-dessus pour s'inscrire à l'essai sélectionné. Vous pouvez partager ce lien par e-mail ou, par exemple, via votre site internet. Veuillez noter que n'importe qui peut s'inscrire si vous rendez le lien public.",
          "link": "Embrayage",
          "title": "Modifier le lien d'inscription"
        },
        "form": {
          "help_texts": {
            "advisor": "Le coach guidera le candidat tout au long du processus une fois le candidat inscrit.",
            "show_default": "Par défaut, un court texte s'affiche pour expliquer comment s'inscrire. Vous pouvez également fournir votre propre texte dans la description du processus.",
            "url": "Lien d'inscription",
            "url_explanation": "Les candidats peuvent s'inscrire au processus spécifié en utilisant le lien ci-dessus. Vous pouvez le diffuser par e-mail ou sur votre site Web, par exemple. Veuillez noter que n'importe qui peut s'inscrire si vous rendez le lien public."
          }
        },
        "index": {
          "actions": "Actions",
          "add": "Nouveau lien d'inscription",
          "coach": "Entraîneur",
          "enrolments": "Inscriptions",
          "list_of_enrollers": "Vous trouverez ici tous les liens d'inscription pour votre organisation",
          "process": "Processus",
          "title": "Liens d'inscription",
          "url": "Lien d'inscription"
        },
        "new": {
          "title": "Nouveau lien d'inscription"
        },
        "reactivate": {
          "reactivated": "Le lien d'inscription a été réactivé"
        },
        "show": {
          "coach": "Entraîneur",
          "created_at": "Date",
          "enrolments": "Inscriptions",
          "process": "Processus",
          "status": "Statut",
          "url": "Lien d'inscription"
        },
        "update": {
          "title": "Modifier le lien d'inscription",
          "updated": "Le lien d'inscription a été mis à jour"
        }
      },
      "enrollments": {
        "create": {
          "cannot_enroll": "Vous ne pouvez pas actuellement attribuer ce processus"
        },
        "destroy": {
          "cannot_destroy": "Vous ne pouvez pas le supprimer car il a déjà démarré.",
          "enrollment_destroyed": "Processus supprimé"
        },
        "form": {
          "add": "Ajouter une personne à contacter"
        },
        "invite": {
          "cannot_invite": "Vous ne pouvez pas inviter car l'inscription est dans un statut invalide."
        },
        "new": {
          "contact_persons": "Contacts"
        }
      },
      "external_reports": {
        "create": {
          "error": "Le rapport ne peut pas être généré",
          "success": "Le rapport est généré."
        }
      },
      "integrations": {
        "show": {
          "api_status": {
            "incorrect": "Il existe actuellement des problèmes techniques qui empêchent l'attribution d'évaluations aux candidats. Si ce problème persiste, veuillez contacter le support.",
            "new": "Les évaluations ne peuvent actuellement pas être attribuées aux candidats au sein de l'organisation. Veuillez contacter l'assistance pour rendre cette fonctionnalité disponible."
          }
        }
      },
      "label_multiples": {
        "create": {
          "send": "Des étiquettes ont été apposées sur %{number} candidats"
        },
        "index": {
          "title": "Ajouter des étiquettes"
        }
      },
      "labels": {
        "create": {
          "label_created": "Le label a été créé"
        },
        "destroy": {
          "removed": "Étiquette supprimée avec succès"
        },
        "edit": {
          "title": "Ajuster l'étiquette"
        },
        "form": {
          "new": "Nouvelle étiquette"
        },
        "index": {
          "help_text": "Vous trouverez ici toutes vos étiquettes que vous pouvez utiliser au sein de l'application",
          "title": "Étiquettes"
        },
        "list": {
          "advisors": "Conseillers",
          "candidates": "Candidats",
          "contact_persons": "Contacter des personnes",
          "name": "Nom",
          "programs": "Processus",
          "updated_at": "Dernière modification"
        },
        "new": {
          "title": "Nouvelle étiquette"
        },
        "update": {
          "label_updated": "L'étiquette a été mise à jour"
        }
      },
      "mail_multiples": {
        "create": {
          "send": {
            "other": "E-mail envoyé à %{count} candidats"
          }
        },
        "index": {
          "title": "Envoyer un e-mail"
        }
      },
      "multiple_bulk_assignments": {
        "create": {
          "candidates_invited": "Les candidats ont été informés des processus nouvellement attribués par courrier électronique",
          "cannot_enroll": "Vous ne pouvez pas actuellement attribuer ce processus",
          "processes_created": "Les processus ont été attribués. Les candidats n'ont pas encore été informés du processus nouvellement assigné."
        },
        "new": {
          "title": "Attribuer des processus aux candidats : %{names}"
        }
      },
      "multiple_talent_solution_assignments": {
        "create": {
          "cannot_enroll": "Le processus attribué a déjà été attribué à tous les candidats."
        }
      },
      "news": {
        "index": {
          "title": "Nouvelles"
        },
        "show": {
          "title": "Nouvelles"
        }
      },
      "norm_groups": {
        "advice": "Conseil",
        "selection": "Sélection"
      },
      "organization_tasks": {
        "index": {
          "title": "Rapports standards"
        },
        "list": {
          "credits": "Crédits",
          "default_report": "Rapport standard",
          "help_text": "Vous pouvez définir ici le rapport standard pour chaque évaluation disponible",
          "key": "Clé",
          "language": "Langue",
          "module": "Module",
          "name": "Nom",
          "price": "Prix"
        },
        "task": {
          "de": "Allemand",
          "en": "Anglais",
          "nl": "Néerlandais"
        }
      },
      "program_templates": {
        "destroy": {
          "destroyed": "Le processus '%{program_title}' a été supprimé"
        },
        "duplicate": {
          "duplicate_prefix": "Copie:",
          "duplicate_suffix": "[copie]",
          "program_duplicated": "Le processus '%{program_title}' a été copié",
          "template_duplicated": "Le processus « %{program_title} » a été copié dans les propres processus de votre organisation."
        },
        "index": {
          "list": "Liste des processus de modèles d'organisation conçus",
          "title": "Modèles de processus"
        },
        "list": {
          "actions": "Actions",
          "assigned": "Lié à",
          "date": "Ajusté à",
          "labels": "Étiquette",
          "length": "Pas",
          "status": "Statut",
          "title": "Titre",
          "type": "Taper"
        },
        "new": "Ajouter un processus",
        "program": {
          "candidates": "Candidats",
          "delete_confirm": "Êtes-vous sûr de vouloir supprimer ce processus ?",
          "steps": "Pas",
          "type": {
            "program": "Propre processus",
            "program_template": "Modèle"
          }
        },
        "publish": {
          "all_pages_invisible": "Vous ne pouvez pas publier ce processus car aucune des étapes n'est définie comme visible par le candidat",
          "published": "Le processus '%{program_title}' a été publié"
        },
        "publish_as_template": {
          "published_as_template": "Le processus '%{program_title}' a été publié comme modèle"
        },
        "update": {
          "update_failed": "Le processus n'a pas pu être mis à jour",
          "updated": "Le processus a été mis à jour"
        },
        "withdraw": {
          "withdrawn": "Le processus '%{program_title}' a été retiré"
        }
      },
      "programs": {
        "destroy": {
          "destroyed": "Le processus '%{program_title}' a été supprimé"
        },
        "duplicate": {
          "duplicate_prefix": "Copie:",
          "duplicate_suffix": "[copie]",
          "program_duplicated": "Le processus '%{program_title}' a été copié",
          "template_duplicated": "Le processus « %{program_title} » a été copié dans les propres processus de votre organisation."
        },
        "index": {
          "list": "Concevez, modifiez ou visualisez les processus de votre organisation. Des processus sont disponibles pour les conseillers ayant le rôle de coach et les candidats premium.",
          "title": "Développer des processus"
        },
        "list": {
          "actions": "Actions",
          "assigned": "Lié à",
          "date": "Ajusté à",
          "labels": "Étiquette",
          "length": "Pas",
          "status": "Statut",
          "title": "Titre",
          "type": "Taper"
        },
        "new": "Ajouter un processus",
        "program": {
          "candidates": "Candidats",
          "delete_confirm": "Êtes-vous sûr de vouloir supprimer ce processus ?",
          "steps": "Pas",
          "type": {
            "program": "Propre processus",
            "program_template": "Modèle"
          }
        },
        "publish": {
          "all_pages_invisible": "Vous ne pouvez pas publier ce processus car aucune des étapes n'est définie comme visible par le candidat",
          "published": "Le processus '%{program_title}' a été publié"
        },
        "publish_as_template": {
          "published_as_template": "Le processus '%{program_title}' a été publié comme modèle"
        },
        "update": {
          "update_failed": "Le processus n'a pas pu être mis à jour",
          "updated": "Le processus a été mis à jour"
        },
        "withdraw": {
          "withdrawn": "Le processus '%{program_title}' a été retiré"
        }
      },
      "rooms": {
        "add": "Ajouter un appel vidéo",
        "create": {
          "created": "L'appel vidéo a été créé",
          "title": "Nouvel appel vidéo"
        },
        "destroy": {
          "deleted": "L'appel vidéo a été supprimé"
        },
        "edit": {
          "title": "Modifier un appel vidéo"
        },
        "form": {
          "help_texts": {
            "end_to_end_encryption": "Cette couche de sécurité supplémentaire ne fonctionne que sur les navigateurs les plus récents prenant en charge les flux insérables. Cela empêche la participation de la plupart des navigateurs."
          }
        },
        "index": {
          "title": "Appels vidéo"
        },
        "list": {
          "actions": "Actions",
          "help_text": "Vous trouverez ici tous les appels vidéo de votre organisation",
          "participants": "Participants",
          "subject": "Sujet",
          "updated_at": "Dernère Activité"
        },
        "new": {
          "title": "Nouvel appel vidéo"
        },
        "room": {
          "participants_present": "%{number} présent"
        },
        "update": {
          "title": "Modifier un appel vidéo",
          "updated": "L'appel vidéo a été mis à jour"
        }
      },
      "shared": {
        "assessment_program_card": {
          "assessment_program_subtasks": "Ce programme d'évaluation combine les évaluations %{subtasks}."
        },
        "candidate_form": {
          "add_contact_person": "Ajouter une personne à contacter",
          "add_label": "Ajouter une étiquette",
          "help_texts": {
            "advisor": "Le conseiller peut attribuer des programmes au candidat et guider le candidat dans ces démarches.",
            "coadvisor": "Un co-conseiller est un conseiller supplémentaire qui peut visualiser les processus du candidat.",
            "contact_person": "Une personne de contact est partie prenante dans le processus d'un candidat. Ils peuvent visualiser les progrès d'un candidat, partager des documents avec le candidat et ses conseillers et être invités à approuver des documents.",
            "intro": {
              "text": "Choisissez 'Enregistrer' et le candidat ne sera pas encore invité à travailler sur la plateforme, aucun email ne sera envoyé. Choisissez « Suivant » et vous pourrez inviter le candidat à créer un compte et à passer une évaluation.",
              "title": "Ajouter un candidat"
            },
            "outro": {
              "text": "Choisissez « Enregistrer » pour inviter un candidat plus tard, le candidat ne recevra pas encore d'e-mail. Choisissez « Suivant » pour inviter un candidat directement et lui attribuer immédiatement une évaluation.",
              "title": "Attribuer une évaluation"
            },
            "report_objective": "Lorsque vous évaluez des candidats pour les conseiller dans leur évolution, choisissez « Conseils » ; lorsque vous évaluez des candidats pour les sélectionner parmi eux, choisissez « Sélection ». Sur la base de cet objectif, nous sélectionnerons automatiquement le rapport le plus pertinent pour ce candidat."
          },
          "primary_title": "Donnée primaire"
        },
        "mail_and_settings_form": {
          "invitation_mail_title": "Configurer l'e-mail d'invitation",
          "report_settings_explanation": "Lorsqu'un candidat a terminé une évaluation, un rapport est automatiquement généré. Nous avons déjà sélectionné un rapport adapté en fonction des paramètres de votre organisation et des objectifs de reporting de votre candidat.",
          "report_settings_title": "Paramètres du rapport"
        },
        "selected_candidates": "Candidats sélectionnés"
      },
      "stitched_reports": {
        "create": {
          "success": "Le rapport fusionné est généré. Vous recevrez une notification lorsque le rapport sera prêt dans vos Documents et Médias."
        },
        "new": {
          "stitch_button": "Combiner",
          "title": "Fusionner les rapports pour %{candidate}"
        }
      },
      "whitelabel": {
        "edit": {
          "title": "Paramètres de marque blanche"
        },
        "update": {
          "whitelabel_updated": "La marque blanche a été mise à jour"
        }
      }
    },
    "authentication_actions": {
      "authenticate": "%{actor} s'est connecté avec succès.",
      "impersonate": "%{actor} connecté en tant que %{target}",
      "unknown": "Action inconnue : %{action}"
    },
    "boolean": {
      "yes": "Oui"
    },
    "builder": {
      "component": {
        "component_delete_confirm": "Êtes-vous sûr de vouloir supprimer cet élément ?",
        "settings": "Paramètres des éléments"
      },
      "elements": {
        "advisor_introduction": "Présentation de l'entraîneur",
        "advisor_introduction_tooltip": "Cela permet au coach de se présenter au candidat",
        "amc": "AMC",
        "amc_tooltip": "Avec l'AMC, le conseiller peut identifier les limites du candidat et avoir un aperçu des options professionnelles",
        "appointment": "Planificateur de rendez-vous",
        "appointment_tooltip": "Cela permet au coach et au candidat d'organiser une rencontre (physique)",
        "blog": "Blog",
        "blog_tooltip": "Blog avec tous les articles partagés avec le candidat",
        "bov": "BOV",
        "bov_tooltip": "Le BOV permet au candidat d'effectuer une recherche dans une base de données de métiers",
        "checkboxes": "Cases à cocher",
        "checkboxes_tooltip": "Nombre de choix parmi lesquels sélectionner plusieurs",
        "consent": "Autorisation",
        "consent_tooltip": "Question oui-non à laquelle le candidat doit répondre pour continuer",
        "custom_html": "HTML personnalisé",
        "custom_html_tooltip": "Insérez votre propre code HTML",
        "date": "Entrée de date",
        "date_tooltip": "Un champ de saisie pour une date",
        "document_approval": "Approbation des documents",
        "document_approval_tooltip": "Cela permet au coach de télécharger un fichier pour que le candidat ou la personne de contact l'approuve.",
        "document_download": "Téléchargement de documents",
        "document_download_tooltip": "Cela permet au coach de télécharger des fichiers que le candidat pourra télécharger",
        "document_upload": "Téléchargement de documents",
        "document_upload_tooltip": "Cela permet au candidat de télécharger des fichiers que le coach pourra télécharger",
        "docx_template": "Générateur de CV et de lettres (Word)",
        "docx_template_tooltip": "Cela permet de télécharger un modèle de document DOCX, que le candidat peut remplir avec des informations personnelles.",
        "fit": "AJUSTER",
        "fit_tooltip": "Avec le FIT, le candidat peut identifier ses limites et s'orienter sur ses options de carrière",
        "image": "Image",
        "image_tooltip": "Image à voir",
        "input_text": "Saisie de texte (court)",
        "input_text_tooltip": "Un champ de saisie d'une seule ligne à remplir par le candidat",
        "ixly_test": "Bilan Ixly",
        "ixly_test_tooltip": "Cela permet au candidat de compléter un test ou un questionnaire puis de télécharger un rapport",
        "job_market_scan": "Opportunités sur le marché du travail",
        "job_market_scan_en": "Opportunités sur le marché du travail en anglais",
        "job_market_scan_en_tooltip": "Avec Labor Market Opportunities, le candidat peut calculer ses chances sur le marché du travail",
        "job_market_scan_tooltip": "Avec Labor Market Opportunities, le candidat peut calculer ses chances sur le marché du travail",
        "job_suggestions": "Suggestions d'emploi",
        "job_suggestions_plus": "Suggestions d'emploi Plus",
        "job_suggestions_plus_tooltip": "Avec Job Suggestions Plus, le candidat peut découvrir des emplois appropriés, ainsi que des formations et des postes vacants associés.",
        "job_suggestions_tooltip": "Avec les suggestions d'emploi, le candidat peut découvrir des emplois appropriés",
        "jobfeed": "Fil d'emploi",
        "jobfeed_tooltip": "Avec Jobfeed, le candidat peut rechercher parmi tous les postes vacants aux Pays-Bas",
        "logbook": "Enregistrer",
        "logbook_tooltip": "Cela permet au candidat de garder des notes",
        "no_premium_components_available_yet": "(aucun élément premium disponible pour le moment)",
        "pdf_template": "Générateur de CV et de lettres",
        "pdf_template_tooltip": "Cela permet de créer des modèles pour les documents PDF, que le candidat peut remplir avec des informations personnelles.",
        "premium": "Prime",
        "premium_costs_are_per_assignment": "(les prix ci-dessus sont par allocation du processus et hors TVA %{vat}%)",
        "quiz": "Questionnaire",
        "quiz_tooltip": "Cela permet au candidat de répondre à des questions (ouvertes ou à choix multiples) puis de vérifier ses réponses",
        "radio_buttons": "Choix multiple",
        "radio_buttons_tooltip": "Nombre de choix parmi lesquels en sélectionner un (boutons radio)",
        "search_job": "Localisateur d'emploi Indeed",
        "search_job_tooltip": "Localisateur d'emploi intégré pour la base de données Indeed",
        "selectbox": "Sélection",
        "selectbox_tooltip": "Nombre de choix parmi lesquels en sélectionner un (liste déroulante)",
        "standard": "Standard",
        "text": "Lecture de texte",
        "text_tooltip": "Texte à lire",
        "textarea": "Saisie de texte (long)",
        "textarea_tooltip": "Un champ de saisie de plusieurs lignes, à remplir par le candidat",
        "title": "Éléments",
        "vacancy": "Gestionnaire de postes vacants",
        "vacancy_tooltip": "Cela permet au candidat de suivre les postes vacants intéressants et leur progression",
        "youtube_video": "Vidéo Youtube",
        "youtube_video_tooltip": "Vidéo YouTube à regarder"
      },
      "menu_item_page": {
        "page_delete_confirm": "Êtes-vous sûr de vouloir supprimer cette étape et tous ses éléments ?",
        "settings": "Paramètres des étapes"
      },
      "sidebar": {
        "new_page": "Nouvelle étape",
        "settings": "Paramètres de processus"
      },
      "top_steps": {
        "placeholder": "Nom du groupe d'étapes..."
      }
    },
    "button": {
      "add": "Ajouter",
      "add_migration_organization": "Migrer l'organisation TTK",
      "back": "Dos",
      "cancel": "Annuler",
      "click_to_close_modal": "(Cliquez ici pour fermer cette fenêtre)",
      "close": "Fermer",
      "complete_setup": "Envoyer une invitation",
      "copy": "Copie",
      "copy_token_authentication_link": "Copier le lien pour une connexion unique sans mot de passe",
      "deactivate": "Désactiver",
      "delete": "Retirer",
      "download_csv": "Télécharger au format CSV",
      "duplicate": "Copie",
      "edit": "Modifier",
      "export": "Exporter",
      "filter": "Postuler",
      "inspect": "Inspecter",
      "invite": "Inviter",
      "join": "Participer",
      "mark_all_as_read": "Marquer comme lu",
      "mark_as_template": "Marquer comme modèle",
      "next": "suivante",
      "publish": "Publier",
      "publish_as_template": "Publier comme modèle",
      "reactivate": "Réactiver",
      "refresh": "Recharger",
      "reset": "Réinitialiser",
      "reset_password": "Mot de passe oublié envoyer un email",
      "select": "Sélection",
      "send": "Envoyez",
      "skip": "Passer",
      "start": "Commencer",
      "test_results": "Résultats",
      "unmark_as_template": "Démarquer comme modèle",
      "verify": "Vérifier",
      "view": "Regarder",
      "withdraw": "Se retirer"
    },
    "candidate": {
      "assessments": {
        "advisor_action": "Le conseiller doit agir en fonction de cette évaluation",
        "finished_at": "Arrondi à",
        "started_at": "Commencé le"
      },
      "assignments": {
        "active_count": "%{number} attributions commencée(s)",
        "finished_count": "%{number} attributions terminée(s)",
        "not_started_count": "%{number} attributions non commencée(s)"
      },
      "created": {
        "empty_list": "Aucune nouvelle tâche n'est prête"
      },
      "enrollment": {
        "deadline": "Ce processus doit être terminé au plus tard à %{date}.",
        "download_alt_text": "Télécharger le rapport",
        "enrolment_link_alt_text": "Ouvrir %{title}",
        "enrolment_link_disabled_alt_text": "%{title} non disponible - date limite dépassée",
        "finished_at": "Arrondi à",
        "invite_alt_text": "Inviter un candidat pour %{title}",
        "invited_at": "Le candidat sera invité à ce processus le %{date}.",
        "not_invited": "Le candidat n'a pas encore été invité à ce processus.",
        "not_started": "Pas encore commencé",
        "started_at": "Commencé le"
      },
      "enrollments": {
        "finished": "arrondi",
        "not_started": "pas encore commencé",
        "started": "commencé"
      },
      "finished": {
        "empty_list": "Il n'y a pas encore de tâches terminées"
      },
      "started": {
        "empty_list": "Il n'y a aucune tâche démarrée"
      },
      "title": "Mes voyages",
      "unknown_initials": "n / A."
    },
    "candidates": {
      "advisors": {
        "show": {
          "coach_role": "Entraîneur",
          "email": "E-mail",
          "role": "Rôle"
        }
      },
      "confirmations": {
        "advisors": {
          "show": {
            "coach_role": "Entraîneur",
            "email": "E-mail",
            "role": "Rôle"
          }
        },
        "contact_people": {
          "show": {
            "client": "Client",
            "contact_person_role": "Contact",
            "email": "E-mail",
            "role": "Rôle"
          }
        },
        "show": {
          "title": "faire un compte"
        }
      },
      "contact_people": {
        "show": {
          "client": "Client",
          "contact_person_role": "Contact",
          "email": "E-mail",
          "role": "Rôle"
        }
      },
      "external_reports": {
        "create": {
          "error": "Le rapport ne peut pas être généré",
          "success": "Le rapport est généré."
        }
      },
      "results": {
        "index": {
          "title": "Aperçu des résultats personnels"
        }
      },
      "send_mail": {
        "blank_email": "L'e-mail ne peut pas être envoyé, le candidat n'a pas d'adresse e-mail",
        "inactive_candidate": "L'e-mail ne peut pas être envoyé, le candidat est inactif",
        "message": "Envoyer un e-mail",
        "resend_welcome": "Renvoyer l'e-mail de bienvenue",
        "send_welcome": "Envoyer un e-mail de bienvenue"
      },
      "token_authentications": {
        "new": {
          "invalid": "Le lien de connexion sans mot de passe n'est plus valide"
        },
        "update": {
          "copied": "Le lien pour une connexion unique sans mot de passe a été copié !"
        }
      }
    },
    "categories": {
      "adaptive": "Test adaptatif",
      "all": "Toutes catégories",
      "e_assessment": "Évaluation électronique",
      "game": "Jeu psychométrique",
      "intelligence": "Intelligence",
      "personality": "Personnalité",
      "sample_tests": "Exemples de tests",
      "simulation": "Simulation"
    },
    "clients": {
      "create": {
        "client_created": "Le client a été créé",
        "help_text": "Créez ici un nouveau client pour votre organisation",
        "title": "Nouvelle cliente"
      },
      "edit": {
        "title": "Modifier le client"
      },
      "form": {
        "labels": "Étiquettes"
      },
      "index": {
        "help_text": "Vous trouverez ici tous les clients (entreprises) de votre organisation",
        "new_button": "Nouvelle cliente",
        "table_header": [
          "Nom",
          "Contacts",
          "Candidats"
        ],
        "title": "Clientèle"
      },
      "new": {
        "help_text": "Créez ici un nouveau client pour votre organisation",
        "title": "Nouvelle cliente"
      },
      "row": {
        "number_of_candidates": "%{number} actif",
        "number_of_contact_persons": "%{number} actif"
      },
      "show": {
        "help_text": "Vous retrouverez ici tous vos contacts et candidats chez ce client",
        "new_button": "Nouvelle personne de contact",
        "table_header": [
          "Faits",
          "Adresse e-mail",
          "Statut"
        ]
      },
      "update": {
        "client_updated": "Le client a été mis à jour",
        "title": "Modifier le client"
      }
    },
    "comments": {
      "index": {
        "title": "Historique des messages"
      }
    },
    "common": {
      "by": "À travers"
    },
    "competencies": {
      "accuracy": "Précision",
      "ambition": "Ambition",
      "assertiveness": "Assurance",
      "collaboration": "Coopération",
      "commitment": "Miser",
      "customer_orientation": "Orientation client",
      "decisiveness": "Esprit de décision",
      "delegation": "Déléguer",
      "employee_coaching": "Coacher les employés",
      "entrepreneurship": "Entrepreneuriat",
      "flexibility": "Flexibilité",
      "group_leadership": "Gestion des groupes",
      "independence": "Indépendance",
      "individual_leadership": "Gérer les individus",
      "initiative": "Initiative",
      "negotiation": "Négocier",
      "organizational_sensitivity": "Sensibilité organisationnelle",
      "performance": "Performance",
      "perseverance": "Persévérance",
      "persuasiveness": "Force de persuasion",
      "planning_organizing": "Planifier et organiser",
      "progress_monitoring": "Vérification des progrès",
      "quality_focus": "Orientation qualité",
      "relationship_management": "Gestion de la relation",
      "results_orientation": "Resultat d'orientation",
      "sensitivity": "Sensibilité",
      "sociability": "Sociabilité",
      "stress_resistance": "Résistance aux contraintes",
      "willingness_to_learn": "Envie d'apprendre"
    },
    "components": {
      "iframe": {
        "back": "Retour au processus"
      },
      "report_visible_for_explanation": "Le rapport peut toujours être rendu visible par l'autre personne par la suite.",
      "update": {
        "error": "Cet élément n'a pas pu être enregistré car :"
      }
    },
    "consents": {
      "cannot_share": "Impossible de partager le rapport.",
      "confirm_retract": "Êtes-vous sûr de vouloir retirer l'autorisation de partager les résultats ?",
      "consent_retracted": "Consentement retiré",
      "retract_consent": "Révoquer le consentement",
      "share_consent": "Partager les résultats",
      "shared_successfully": "Le rapport a été partagé avec succès"
    },
    "contact_people": {
      "enrollments": {
        "list": {
          "advisor": "Entraîneur",
          "candidate": "Candidat",
          "coadvisor": "Cocoach",
          "contact_person": "Contact",
          "pages": "Pages",
          "status": "Statut"
        }
      }
    },
    "current": "Actuel",
    "datatables": {
      "filtered_from_after_count": "Résultats",
      "filtered_from_before_count": "filtré",
      "loading": "Chargeons...",
      "next": "suivante",
      "previous": "Précédent",
      "showing_page_after_count": "par",
      "showing_page_before_count": "Page",
      "zero_records": "Aucun résultat disponible"
    },
    "date": {
      "abbr_day_names": [
        "dim",
        "lun",
        "mar",
        "mer",
        "jeu",
        "ven",
        "sam"
      ],
      "abbr_month_names": [
        null,
        "jan.",
        "fév.",
        "mars",
        "avr.",
        "mai",
        "juin",
        "juil.",
        "août",
        "sept.",
        "oct.",
        "nov.",
        "déc."
      ],
      "day_names": [
        "dimanche",
        "lundi",
        "mardi",
        "mercredi",
        "jeudi",
        "vendredi",
        "samedi"
      ],
      "formats": {
        "date_without_day": "%e %B %Y",
        "date_without_day_and_year": "%d %b",
        "default": "%d/%m/%Y",
        "long": "%-d %B %Y",
        "short": "%-d %b"
      },
      "month_names": [
        null,
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "environ une heure",
          "other": "environ %{count} heures"
        },
        "about_x_months": {
          "one": "environ un mois",
          "other": "environ %{count} mois"
        },
        "about_x_years": {
          "one": "environ un an",
          "other": "environ %{count} ans"
        },
        "almost_x_years": {
          "one": "presque un an",
          "other": "presque %{count} ans"
        },
        "half_a_minute": "une demi‑minute",
        "less_than_x_minutes": {
          "one": "moins d'une minute",
          "other": "moins de %{count} minutes",
          "zero": "moins d'une minute"
        },
        "less_than_x_seconds": {
          "one": "moins d'une seconde",
          "other": "moins de %{count} secondes",
          "zero": "moins d'une seconde"
        },
        "over_x_years": {
          "one": "plus d'un an",
          "other": "plus de %{count} ans"
        },
        "x_days": {
          "one": "%{count} jour",
          "other": "%{count} jours"
        },
        "x_minutes": {
          "one": "%{count} minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "%{count} mois",
          "other": "%{count} mois"
        },
        "x_seconds": {
          "one": "%{count} seconde",
          "other": "%{count} secondes"
        },
        "x_years": {
          "one": "%{count} an",
          "other": "%{count} ans"
        }
      },
      "prompts": {
        "day": "Jour",
        "days": "jusqu'à l'aube",
        "hour": "Heure",
        "minute": "Minute",
        "month": "mois",
        "months": "mois",
        "second": "Seconde",
        "year": "année",
        "years": "années"
      }
    },
    "delimit_options_by_commas": "Séparez les différents choix par des virgules (par exemple : pomme, poire, banane).",
    "delimit_sections_by_commas": "Séparez les différentes sections par des virgules.",
    "devise": {
      "confirmations": {
        "confirmed": "Votre compte a bien été confirmé.",
        "new": {
          "resend_confirmation_instructions": "Renvoyer les instructions de confirmation"
        },
        "send_instructions": "Vous allez recevoir sous quelques minutes un email comportant des instructions pour confirmer votre compte.",
        "send_paranoid_instructions": "Si votre email existe sur notre base de données, vous recevrez sous quelques minutes un message avec des instructions pour confirmer votre compte."
      },
      "failure": {
        "already_authenticated": "Vous êtes déjà connecté(e).",
        "inactive": "Votre compte n’est pas encore activé.",
        "invalid": "Aucun compte n'a été trouvé avec la combinaison e-mail/mot de passe spécifiée",
        "last_attempt": "Il vous reste une chance avant que votre compte soit bloqué.",
        "locked": "Votre compte est verrouillé.",
        "not_found_in_database": "Aucun compte n'a été trouvé avec la combinaison e-mail/mot de passe spécifiée",
        "organization_inactive": "Vous ne pouvez pas vous connecter, l'organisation dont vous êtes membre n'est pas active",
        "timeout": "Votre session est expirée, veuillez vous reconnecter pour continuer.",
        "unauthenticated": "vous devez vous connecter pour continuer",
        "unconfirmed": "Vous devez confirmer votre compte par email.",
        "user_status_created": "Votre compte n'est pas encore actif",
        "user_status_inactive": "Votre compte n'est pas actif",
        "user_status_invited": "Vous ne pouvez pas encore vous connecter, utilisez l'e-mail d'invitation pour créer un mot de passe"
      },
      "mailer": {
        "confirmation_instructions": {
          "action": "Confirmer mon email",
          "greeting": "Bienvenue %{recipient} !",
          "instruction": "Vous pouvez confirmer votre email grâce au lien ci-dessous :",
          "subject": "Instructions de confirmation"
        },
        "email_changed": {
          "greeting": "Bonjour %{recipient} !",
          "message": "Nous vous contactons pour vous informer que votre email a été changé en %{email}.",
          "message_unconfirmed": "Nous vous contactons pour vous informer que votre email est en train d'être changé par %{email}.",
          "subject": "Email modifié"
        },
        "password_change": {
          "greeting": "Bonjour %{recipient} !",
          "message": "Nous vous contactons pour vous notifier que votre mot de passe a été modifié.",
          "subject": "Mot de passe modifié"
        },
        "reset_password_instructions": {
          "action": "Changer mon mot de passe",
          "greeting": "Bonjour %{recipient} !",
          "instruction": "Quelqu'un a demandé un lien pour changer votre mot de passe, le voici :",
          "instruction_2": "Si vous n'avez pas émis cette demande, merci d'ignorer cet email.",
          "instruction_3": "Votre mot de passe ne changera pas tant que vous n'aurez pas cliqué sur ce lien et renseigné un nouveau mot de passe.",
          "subject": "Instructions pour changer le mot de passe"
        },
        "unlock_instructions": {
          "action": "Débloquer mon compte",
          "greeting": "Bonjour %{recipient} !",
          "instruction": "Suivez ce lien pour débloquer votre compte :",
          "message": "Votre compte a été bloqué suite à un nombre d'essais de connexions manquées trop important.",
          "subject": "Instructions pour déverrouiller le compte"
        }
      },
      "omniauth_callbacks": {
        "failure": "Nous ne pouvons pas vous authentifier depuis %{kind} pour la raison suivante : '%{reason}'.",
        "success": "Autorisé par votre compte %{kind}."
      },
      "passwords": {
        "edit": {
          "change_my_password": "Changer mon mot de passe",
          "change_your_password": "Changer votre mot de passe",
          "confirm_new_password": "Confirmez votre nouveau mot de passe",
          "new_password": "Nouveau mot de passe"
        },
        "new": {
          "forgot_your_password": "Mot de passe oublié ?",
          "send_me_reset_password_instructions": "Envoyez-moi des instructions pour réinitialiser mon mot de passe"
        },
        "no_token": "Vous ne pouvez pas accéder à cette page si vous n’y accédez pas depuis un email de réinitialisation de mot de passe. Si vous venez en effet d’un tel email, vérifiez que vous avez copié l’adresse URL en entier.",
        "send_instructions": "Vous allez recevoir sous quelques minutes un email vous indiquant comment réinitialiser votre mot de passe.",
        "send_paranoid_instructions": "Si votre email existe dans notre base de données, vous recevrez un lien vous permettant de récupérer votre mot de passe.",
        "updated": "Votre mot de passe a bien été modifié. Vous êtes maintenant connecté(e).",
        "updated_not_active": "Votre mot de passe a bien été modifié."
      },
      "registrations": {
        "destroyed": "Au revoir ! Votre compte a bien été supprimé. Nous espérons vous revoir bientôt.",
        "edit": {
          "are_you_sure": "Êtes-vous sûr ?",
          "cancel_my_account": "Supprimer mon compte",
          "currently_waiting_confirmation_for_email": "Confirmation en attente pour: %{email}",
          "leave_blank_if_you_don_t_want_to_change_it": "laissez ce champ vide pour le laisser inchangé",
          "title": "Éditer %{resource}",
          "unhappy": "Pas content ?",
          "update": "Modifier",
          "we_need_your_current_password_to_confirm_your_changes": "nous avons besoin de votre mot de passe actuel pour valider ces modifications"
        },
        "new": {
          "sign_up": "Inscription"
        },
        "signed_up": "Bienvenue ! Vous vous êtes bien enregistré(e).",
        "signed_up_but_inactive": "Vous vous êtes bien enregistré(e). Cependant, nous n’avons pas pu vous connecter car votre compte n’a pas encore été activé.",
        "signed_up_but_locked": "Vous vous êtes bien enregistré(e). Cependant, nous n’avons pas pu vous connecter car votre compte est verrouillé.",
        "signed_up_but_unconfirmed": "Un message avec un lien de confirmation vous a été envoyé par mail. Veuillez suivre ce lien pour activer votre compte.",
        "update_needs_confirmation": "Vous avez bien modifié votre compte, mais nous devons vérifier votre nouvelle adresse email. Veuillez consulter vos emails et cliquer sur le lien de confirmation pour confirmer votre nouvelle adresse.",
        "updated": "Votre compte a bien été modifié.",
        "updated_but_not_signed_in": "Votre compte a été mis à jour avec succès. Vous devez vous reconnecter car votre mot de passe a été modifié."
      },
      "sessions": {
        "already_signed_out": "Déconnecté(e).",
        "new": {
          "sign_in": "Connexion"
        },
        "signed_in": "Connecté(e).",
        "signed_out": "Déconnecté(e)."
      },
      "shared": {
        "links": {
          "back": "Retour",
          "didn_t_receive_confirmation_instructions": "Vous n'avez pas reçu l'email de confirmation ?",
          "didn_t_receive_unlock_instructions": "Vous n'avez pas reçu l'email de déblocage ?",
          "forgot_your_password": "Mot de passe oublié ?",
          "sign_in": "Connexion",
          "sign_in_with_provider": "Connexion avec %{provider}",
          "sign_up": "Inscription"
        },
        "minimum_password_length": {
          "one": "(%{count} caractère au moins)",
          "other": "(%{count} caractères au moins)"
        }
      },
      "sign_out": "Se déconnecter",
      "two_factor_authentication": {
        "attempt_failed": "Code incorrect.",
        "code_has_been_sent": "Votre code de vérification en deux étapes a été envoyé.",
        "contact_administrator": "Contactez un administrateur de l'organisation.",
        "max_login_attempts_reached": "Accès complètement refusé car vous avez atteint le nombre maximum de tentatives",
        "success": "Authentification à deux facteurs réussie."
      },
      "unlocks": {
        "new": {
          "resend_unlock_instructions": "Renvoyer les instructions de déblocage"
        },
        "send_instructions": "Vous allez recevoir sous quelques minutes un email comportant des instructions pour déverrouiller votre compte.",
        "send_paranoid_instructions": "Si votre email existe sur notre base de données, vous recevrez sous quelques minutes un message avec des instructions pour déverrouiller votre compte.",
        "unlocked": "Votre compte a bien été déverrouillé. Veuillez vous connecter."
      }
    },
    "discounts": {
      "create": {
        "created": "Les réductions ont été créées"
      },
      "destroy": {
        "already_started": "Cette réduction a déjà commencé."
      },
      "index": {
        "new_button": "Nouvelles réductions",
        "table_header": [
          "Sur",
          "Pourcentage",
          "Date de début",
          "Date de fin",
          "Actions"
        ],
        "title": "Réductions"
      },
      "new": {
        "premium_components": "Éléments premium",
        "subscriptions": "Abonnements",
        "title": "Nouvelles réductions pour %{organization}"
      },
      "row": {
        "discount_delete": "Êtes-vous sûr de vouloir supprimer cette réduction ?"
      }
    },
    "education_levels": {
      "academic": "WO",
      "higher_vocational": "HBO",
      "pre_vocational": "VMB",
      "secondary_vocational": "MBO"
    },
    "education_levels_rnd": {
      "academic": "Épouser",
      "higher_vocational": "HBO",
      "pre_vocational": "enseignement secondaire préprofessionnel",
      "secondary_vocational": "MBO"
    },
    "enrollers": {
      "enroll": {
        "cannot_enroll": "Vous ne pouvez pas actuellement vous inscrire à ce processus",
        "enrolled": "Vous participez désormais à ce processus"
      },
      "enroll_existing": {
        "cannot_enroll": "Vous ne pouvez pas actuellement vous inscrire à ce processus",
        "enrolled": "Vous participez désormais à ce processus",
        "enrolling_user_is_a_contact_person": "En tant que personne de contact, vous ne pouvez pas participer vous-même aux processus",
        "enrolling_user_is_enrollers_advisor": "En tant que coach de ce lien d'inscription, vous ne pouvez pas participer vous-même à ce processus"
      },
      "enroll_test": {
        "cannot_enroll": "Vous ne pouvez pas actuellement vous inscrire à ce processus"
      },
      "form": {
        "accept": "J'accepte",
        "and": "et",
        "participate": "Participer",
        "privacy_label": "la <a class='btn-link' href='%{privacy_link}' target='_blank'>déclaration de confidentialité</a>",
        "terms_label": "les <a class='btn-link' href='%{terms_link}' target='_blank'>conditions générales</a>"
      },
      "show": {
        "deactivated": "Ce lien d'inscription n'est plus disponible",
        "sign_in_or_sign_up": "Si vous possédez déjà un compte, vous pouvez vous <a class='btn-link' href='%{login_link}'>vous connecter</a> pour participer à ce processus. Sinon, vous devez d’abord vous inscrire au Process Toolkit en utilisant le formulaire ci-dessous."
      },
      "signed_in_form": {
        "already_enrolled": "Vous participez déjà à ce processus, continuez à partir de <a class='alert-link' href='%{link}'>%{text}</a>",
        "participate": "Participer",
        "participate_as": "Vous pouvez participer directement à ce processus via le bouton ci-dessous."
      },
      "test_only_form": {
        "accept": "J'accepte",
        "and": "et",
        "participate": "Participer",
        "privacy_label": "la <a class='btn-link' href='%{privacy_link}' target='_blank'>déclaration de confidentialité</a>",
        "terms_label": "les <a class='btn-link' href='%{terms_link}' target='_blank'>conditions générales</a>"
      },
      "tests_assigned": {
        "email_sent": "Merci pour votre participation. Vous recevrez un e-mail de no-reply@test-toolkit.nl avec des instructions sur la façon de commencer."
      }
    },
    "enrollment_pages": {
      "control_panel": {
        "accept_page": "Approuver l'étape",
        "back_to_tracks_as_advisor": "Retour à l'aperçu des candidats",
        "back_to_tracks_as_candidate": "Retour à ma liste d'itinéraires",
        "comments": "Informer",
        "finish_enrollment": "Processus complet",
        "finish_enrollment_confirm": "Êtes-vous sûr de vouloir terminer le processus ? Un processus terminé ne peut plus être modifié par le candidat ou le coach.",
        "finish_page": "Étape complète",
        "finish_page_and_next": "Conclusion et ensuite",
        "next_page": "Étape suivante",
        "next_page_unavailable": "La prochaine étape n'est pas encore disponible...",
        "reject_page": "Ignorer l'étape",
        "reopen_enrollment": "Processus de réouverture",
        "reopen_enrollment_confirm": "Êtes-vous sûr de vouloir rouvrir le processus ? Le candidat pourra à nouveau apporter des modifications.",
        "submit_page": "Étape de soumission",
        "submit_page_and_next": "Soumettre et suivant"
      },
      "insert_optional": {
        "cannot_enroll": "La page n'a pas pu être insérée car elle contient une évaluation qui n'est pas actuellement disponible dans l'abonnement.",
        "page_inserted": "L'étape '%{page_title}' a été insérée"
      },
      "show": {
        "insert": "Insérer",
        "is_optional": "Cette étape ne fait actuellement pas partie de ce processus, mais peut être ajoutée en option",
        "original_page_deleted": "Cette étape facultative ne peut plus être insérée car l'original a été supprimé",
        "page_insert_confirm_part_1": "L'insertion est irréversible",
        "page_insert_confirm_part_2": "Etes-vous sûr de vouloir insérer cette étape au point indiqué du processus ?",
        "with_premium_components": {
          "one": "et coûte %{costs} en raison de 1 élément premium",
          "other": "et coûte %{costs} en raison de %{count} éléments premium"
        }
      }
    },
    "enrollments": {
      "confirm_withdraw": "Êtes-vous sûr de vouloir annuler ce processus ?",
      "create": {
        "candidate_double_invited": "Le candidat a été invité par email et informé du processus nouvellement assigné",
        "candidate_invited": "Le candidat a été informé du processus nouvellement attribué par e-mail",
        "cannot_enroll": "Vous ne pouvez pas actuellement attribuer ce processus",
        "enrollment_created": "Le procès a été accordé. Le candidat n'a pas encore été informé."
      },
      "destroy": {
        "candidate_enrollment_already_started": "Ce processus ne peut pas être retiré car le processus a déjà commencé",
        "candidate_enrollment_destroyed": "Le procès a été retiré"
      },
      "finish": {
        "enrollment_finished": "Le processus est terminé",
        "finish_enrollment_failed": "Le processus ne peut pas être terminé car il n'a pas démarré ou est déjà terminé"
      },
      "navbar_page": {
        "not_available_yet": "Disponible après avoir terminé les étapes précédentes"
      },
      "new": {
        "help_text": "Sélectionnez l'un de vos processus publiés à attribuer au candidat",
        "table_header": [
          "Processus",
          "Actions"
        ],
        "title": "Attribuer un processus"
      },
      "progress": {
        "done": "arrondi",
        "rejected": "encore",
        "submitted": "remis"
      },
      "reopen": {
        "enrollment_reopened": "Le procès a été rouvert",
        "reopen_enrollment_failed": "Le processus ne peut pas être rouvert car le processus n'est pas terminé"
      },
      "row": {
        "add": "Accorder",
        "enrollment_create_confirm": {
          "one": "Le processus sélectionné contient 1 élément premium, qui coûte %{costs}. Etes-vous sûr de vouloir l'accorder ?",
          "other": "Le processus sélectionné contient %{count} éléments premium, qui coûtent ensemble %{costs}. Etes-vous sûr de vouloir l'accorder ?"
        },
        "invite_later": "Inviter plus tard",
        "view": "Regarder"
      }
    },
    "errors": {
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "doit être accepté(e)",
        "advisor_cannot_be_coadvisor": "Il n'est pas possible de désigner un conseiller sélectionné comme co-conseiller",
        "already_confirmed": "a déjà été confirmé(e)",
        "already_generated": "est déjà généré",
        "already_passed": "est dans le passé",
        "aspect_ratio_is_not": "doit avoir un rapport hauteur / largeur de %{aspect_ratio}",
        "aspect_ratio_not_landscape": "doit être une image en format paysage",
        "aspect_ratio_not_portrait": "doit être une image en format portrait",
        "aspect_ratio_not_square": "doit être une image en format carrée",
        "aspect_ratio_unknown": "a un rapport d'aspect inconnu",
        "attachment_size": "Doit être inférieur à 50 Mo",
        "before_start_date": "doit être postérieur à la date de début",
        "blank": "doit être rempli(e)",
        "candidate_limit_reached": "Votre compte ne peut pas être activé car le nombre maximum de candidats actifs a été atteint",
        "cannot_downgrade_before_year": "doit être au moins un an après la date de début de l’abonnement précédent",
        "confirmation": "ne correspond pas à %{attribute}",
        "confirmation_period_expired": "doit être confirmé(e) en %{period}, veuillez en demander un(e) autre",
        "contains_unpermitted_variables": "n'est pas valide car il contient des variables non autorisées",
        "content_type_blacklist_error": "Les images SVG ne sont pas prises en charge",
        "content_type_invalid": "a un type de contenu non valide",
        "content_type_is_invalid": "Seuls les fichiers CSV sont pris en charge",
        "content_type_whitelist_error": "Le fichier téléchargé n'est pas une image",
        "create_otp_organization_failed": "Un problème est survenu lors de la création de l'organisation dans Test Toolkit",
        "current_included": "ne peut pas se contenir",
        "destroy_otp_organization_failed": "Un problème est survenu lors de la suppression de l'organisation dans Test Toolkit",
        "dimension_height_equal_to": "la hauteur doit être égale à %{length} pixels",
        "dimension_height_greater_than_or_equal_to": "la hauteur doit être supérieure ou égale à %{length} pixels",
        "dimension_height_inclusion": "la hauteur n'est pas comprise entre %{min} et %{max} pixels",
        "dimension_height_less_than_or_equal_to": "la hauteur doit être inférieure ou égale à %{length} pixels",
        "dimension_max_inclusion": "doit être inférieur ou égal à %{width} x %{height} pixels",
        "dimension_min_inclusion": "doit être supérieur ou égal à %{width} x %{height} pixels",
        "dimension_width_equal_to": "la largeur doit être égale à %{length} pixels",
        "dimension_width_greater_than_or_equal_to": "la largeur doit être supérieure ou égale à %{length} pixels",
        "dimension_width_inclusion": "la largeur n'est pas comprise entre %{min} et %{max} pixels",
        "dimension_width_less_than_or_equal_to": "la largeur doit être inférieure ou égale à %{length} pixels",
        "email_invalid": "doit avoir la structure suivante : nom@domaine.nl",
        "empty": "doit être rempli(e)",
        "equal_to": "doit être égal à %{count}",
        "even": "doit être pair",
        "exclusion": "n'est pas disponible",
        "expired": "est expiré, veuillez en demander un autre",
        "file_size_not_between": "la taille du fichier doit être comprise entre %{min_size} et %{max_size} (la taille actuelle est %{file_size})",
        "file_size_not_greater_than": "la taille du fichier doit être supérieure à %{min_size} (la taille actuelle est %{file_size})",
        "file_size_not_greater_than_or_equal_to": "la taille du fichier doit être supérieure ou égale à %{min_size} (la taille actuelle est %{file_size})",
        "file_size_not_less_than": "la taille du fichier doit être inférieure à %{max_size} (la taille actuelle est %{file_size})",
        "file_size_not_less_than_or_equal_to": "la taille du fichier doit être inférieure ou égale à %{max_size} (la taille actuelle est %{file_size})",
        "free_included": "ne peut pas contenir d'abonnements gratuits",
        "greater_than": "doit être supérieur à %{count}",
        "greater_than_or_equal_to": "doit être supérieur ou égal à %{count}",
        "image_metadata_missing": "n'est pas une image valide",
        "image_not_processable": "n'est pas une image valide",
        "in": "doit être dans l'intervalle %{count}",
        "inclusion": "n'est pas inclus(e) dans la liste",
        "incomplete_post_rule": "La sous-ligne ci-dessous est incomplète",
        "insufficient_credits": "Le rapport ne peut pas être généré car il n'y a pas suffisamment de crédits prépayés.",
        "internet_explorer_error": "Le navigateur que vous utilisez (Internet Explorer) n'est plus pris en charge. Utilisez un autre navigateur.",
        "invalid": "n'est pas valide",
        "invalid_annual_end_date": "de %{subscription_type} doit être un multiple de %{period_in_months} mois après sa date de début (- 1 jour)",
        "invalid_annual_start_date": "de %{subscription_type} doit être un multiple de %{period_in_months} mois avant sa date de fin (+ 1 jour)",
        "invalid_category": "La catégorie %{value} n'est pas valide",
        "invalid_date_range": "n'est pas valide, doit être postérieur à la date d'invitation",
        "invalid_otp": "Le code saisi est incorrect",
        "invalid_subscription_type": "le passage à %{subscription_type} n'est pas autorisé",
        "invalid_vat": "n’est pas un numéro de TVA %{country_adjective} valide",
        "language_not_available": "n'est pas disponible dans l'organisation",
        "less_than": "doit être inférieur à %{count}",
        "less_than_or_equal_to": "doit être inférieur ou égal à %{count}",
        "limit_out_of_range": "le nombre total est hors limites",
        "login_not_allowed": "Votre organisation ou votre compte n'est pas actif",
        "maximum_number_of_advisors_reached": "Il n'est pas possible d'ajouter un nouveau coach car le nombre maximum de coachs a été atteint",
        "maximum_number_of_candidates_reached": "Il n'est pas possible d'ajouter un nouveau candidat car le nombre maximum de candidats a été atteint",
        "mini_magick_processing_error": "La manipulation avec MiniMagick a échoué",
        "model_invalid": "Validation échouée : %{errors}",
        "mollie_error": "Une erreur s'est produite avec notre fournisseur de paiement Mollie ; Réessayez plus tard",
        "more_than_two_decimals": "ne peut pas avoir plus de 2 décimales",
        "not_a_number": "n'est pas un nombre",
        "not_an_integer": "doit être un nombre entier",
        "not_found": "n’a pas été trouvé(e)",
        "not_locked": "n’était pas verrouillé(e)",
        "not_saved": {
          "one": "une erreur a empêché ce (cet ou cette) %{resource} d’être enregistré(e) :",
          "other": "%{count} erreurs ont empêché ce (cet ou cette) %{resource} d’être enregistré(e) :"
        },
        "odd": "doit être impair",
        "one_pending_subscription": "n'est pas autorisé car un abonnement est déjà prévu",
        "organization_address_missing": "l'adresse doit être définie pour attribuer un abonnement payant",
        "organization_must_be_ixly": "Il n'est pas possible de créer un collaborateur Ixly en dehors de l'organisation Ixly",
        "organization_vat_number_missing": "Pour attribuer un abonnement payant, un numéro de TVA doit être paramétré",
        "other_than": "doit être différent de %{count}",
        "overlaps_existing_dates": "ne doit pas chevaucher des dates existantes",
        "present": "doit être vide",
        "required": "doit exister",
        "restrict_dependent_destroy": {
          "has_many": "Impossible de supprimer l'élément car %{record} dépendant existe",
          "has_one": "Impossible de supprimer l'élément car un %{record} dépendant existe"
        },
        "subscriptions_attached": "ne peut pas être modifié car il existe des abonnements avec ce formulaire",
        "taken": "est déjà utilisé(e)",
        "too_long": {
          "one": "est trop long (pas plus d'un caractère)",
          "other": "est trop long (pas plus de %{count} caractères)"
        },
        "too_short": {
          "one": "est trop court (au moins un caractère)",
          "other": "est trop court (au moins %{count} caractères)"
        },
        "url_invalid": "doit avoir la structure suivante : https://www.example.com/path/to/page",
        "user_limit_reached": "Votre compte ne peut pas être activé car le nombre maximum de coachs a été atteint",
        "vies_down": "Impossible de valider votre numéro de TVA : le service de validation VIES est indisponible. Merci de réessayer ultérieurement.",
        "wrong_length": {
          "one": "ne fait pas la bonne longueur (doit comporter un seul caractère)",
          "other": "ne fait pas la bonne longueur (doit comporter %{count} caractères)"
        }
      },
      "template": {
        "body": "Il y a des problèmes avec les champs suivants :",
        "header": {
          "one": "%{model} non enregistré : 1 erreur trouvée",
          "other": "%{model} non enregistré : erreurs %{count} trouvées"
        }
      }
    },
    "filterable": {
      "no_results": "aucun résultat",
      "reset": "réinitialiser les filtres",
      "title": "Filtre"
    },
    "flash": {
      "try_again": "essayer à nouveau"
    },
    "helpers": {
      "select": {
        "prompt": "fais un choix",
        "sr_only": "sélectionner"
      },
      "submit": {
        "create": "Créer",
        "submit": "Enregistrer %{model}",
        "update": "Sauvegarder"
      }
    },
    "i18n": {
      "plural": {
        "keys": [
          "one",
          "other"
        ]
      },
      "transliterate": {
        "rule": {
          "À": "A",
          "Â": "A",
          "Æ": "Ae",
          "Ç": "C",
          "È": "E",
          "É": "E",
          "Ê": "E",
          "Ë": "E",
          "Î": "I",
          "Ï": "I",
          "Ô": "O",
          "Ù": "U",
          "Û": "U",
          "Ü": "U",
          "à": "a",
          "â": "a",
          "æ": "ae",
          "ç": "c",
          "è": "e",
          "é": "e",
          "ê": "e",
          "ë": "e",
          "î": "i",
          "ï": "i",
          "ô": "o",
          "ù": "u",
          "û": "u",
          "ü": "u",
          "ÿ": "y",
          "Œ": "Oe",
          "œ": "oe",
          "Ÿ": "Y"
        }
      }
    },
    "impersonated_as": "Vous êtes connecté en tant que %{name}.",
    "impersonation_ended": "Vous êtes à nouveau connecté en tant que vous-même.",
    "import": {
      "current_template": "Modèle",
      "download_template": "Télécharger le modèle",
      "errors": {
        "duplicate": "Dupliquer"
      },
      "import/candidate_file": {
        "candidate": {
          "account": {
            "email": "E-mail %{message}"
          },
          "advisor": "Conseiller %{message}",
          "language": "Langue %{message}"
        },
        "file": {
          "headers": "En-têtes de colonnes incorrects %{message}"
        }
      },
      "import/organization_file": {
        "file": {
          "headers": "En-têtes de colonnes incorrects %{message}",
          "unique": "L'organisation « %{organization_name} » existe déjà. (api_organization_id : %{api_organization_id}"
        }
      },
      "labels": {
        "description": "Étiquettes",
        "placeholder": "Saisir des étiquettes"
      },
      "loading": "Chargeons...",
      "processing": "Le dossier est en cours de traitement. Vous pouvez maintenant fermer cette page.",
      "status": {
        "errors": "Des problèmes",
        "existed": "Inchangé",
        "invalid": "Invalide",
        "new": "Nouveau",
        "previous_data": "Laver pour ça",
        "updated": "Modifié"
      },
      "store": "Sauvegarder",
      "title": "Importation CSV",
      "upload": "Télécharger CSV"
    },
    "info": {
      "error_occured": "Quelque chose s'est mal passé. Transmettez ce message d’erreur à votre coach ou responsable organisationnel.",
      "flagged_elements": "Les pages en surbrillance contiennent des éléments en surbrillance qui nécessitent une configuration supplémentaire avant que le candidat puisse commencer le processus.",
      "no_results_found": "Aucun résultat trouvé",
      "review_required": "Une révision est nécessaire. L'entraîneur doit revoir l'évaluation avant que les options de rapport ne soient disponibles.",
      "review_started": "Complétez la révision dans l’autre onglet et rechargez cette page peu de temps après.",
      "verification_planned": "Le candidat doit commencer le test de vérification.",
      "verification_required": "Une vérification est requise. L'entraîneur doit revoir l'évaluation avant que les options de rapport ne soient disponibles.",
      "verification_started": "Le candidat doit réussir le test de vérification avant que de nouveaux rapports puissent être téléchargés."
    },
    "invoice_kind": {
      "administrative_costs": "Frais administratifs",
      "change_internal_limit": "Ajustement de la limite interne",
      "change_mandate": "Paiement d'autorisation",
      "extra_external_credit": "Augmentation du crédit extérieur",
      "fixed_price": "Évaluations terminées",
      "frequent_credits": "Utilisation du crédit",
      "incidental_credits": "Forfait de crédit",
      "initial_authorization": "Autorisation initiale",
      "intensive_credits": "Utilisation du crédit",
      "monthly_bill": "Amortissement mensuel",
      "premium_components": "Éléments premium"
    },
    "invoice_status": {
      "canceled": "annulé",
      "charged_back": "renversé",
      "closed": "annulé",
      "expired": "expiré",
      "failed": "échoué",
      "open": "Ouvrir",
      "paid": "payé",
      "processed": "mis en œuvre",
      "refunded": "remboursé",
      "revoked": "annulé",
      "sent": "envoyé",
      "settled": "réglé",
      "unconfirmed": "non confirmé"
    },
    "invoices": {
      "no_longer_unconfirmed": "Cet ordre de paiement a déjà été confirmé",
      "show": {
        "administrative_costs": "Frais administratifs",
        "annual_fee": "Frais d'abonnement périodique",
        "back_to_account": "(cliquez ici pour revenir à votre facturation)",
        "change_internal_limit_explanation": "Les ajustements n'affectent les coûts du mois en cours que si la limite est augmentée jusqu'à un niveau non encore atteint ce mois-ci. La différence au plafond le plus élevé (%{previous_limit}) sera alors réglée sur la facture mensuelle suivante, proportionnellement au nombre de jours restant dans le mois.",
        "confirm_direct_debit": "Amortissement de x-%{mandate_string}",
        "confirm_direct_invoice": "J'ai reçu cette facture",
        "confirm_direct_payment": "Vers l'écran de paiement",
        "costs_next_months": "A payer à partir du mois prochain",
        "costs_now": "Payable maintenant",
        "credit_charge": "Crédits",
        "external_user_spots": "Candidats actifs (prix mensuel)",
        "external_user_spots_partial": {
          "general": "Candidats actifs",
          "one": "Candidats actifs (1 jour)",
          "other": "Candidats actifs (%{count} jours)"
        },
        "fill_out_address": "Il nous manque les coordonnées nécessaires pour envoyer une facture correcte pour votre achat. Veuillez vérifier et corriger les informations ci-dessous pour effectuer un achat.",
        "internal_user_spots": "Coachs actifs (tarif mensuel)",
        "internal_user_spots_partial": {
          "general": "Coachs actifs",
          "one": "Coachs actifs (1 jour)",
          "other": "Coachs actifs (%{count} jours)"
        },
        "internal_users": "Places utilisateurs internes (prix mensuel)",
        "monthly_bill": "Facture mensuelle pour %{month} %{year}",
        "premium_components": "Attribution des éléments premium : %{kind}",
        "premium_components_invoice": "Attribution d'éléments premium",
        "status_flash_part_1": "Cet ordre de paiement a été émis par",
        "status_flash_part_2": "et est %{status} sur %{updated_at}",
        "system_initiated": "la boîte à outils de processus",
        "table_header": [
          "Date",
          "Produit",
          "Centre de coûts",
          "Prix unitaire",
          "Nombre",
          "Montant"
        ],
        "terms_and_services": "En payant vous acceptez automatiquement",
        "terms_and_services_link": "les conditions générales d'Ixly",
        "title": "Confirmez votre commande",
        "total_with_vat": "Total TTC",
        "total_without_vat": "Total hors TVA",
        "vat": "%{vat}% TVA"
      }
    },
    "ixly": {
      "accounts": {
        "add": "créer un compte",
        "index": {
          "title": "Tous les comptes"
        }
      },
      "advisors": {
        "create": {
          "success": "Conseiller créé !",
          "title": "Ajouter un nouveau conseiller"
        },
        "edit": {
          "title": "Modifier le conseiller"
        },
        "new": {
          "title": "Ajouter un nouveau conseiller"
        },
        "update": {
          "success": "Conseiller changé !",
          "title": "Modifier le conseiller"
        }
      },
      "candidates": {
        "index": {
          "title": "Tous les candidats"
        },
        "new": {
          "title": "Tous les candidats"
        }
      },
      "discounts": {
        "create": {
          "created": "Les réductions ont été créées",
          "title": "Nouvelle remise"
        },
        "destroy": {
          "already_started": "Cette réduction a déjà commencé.",
          "destroyed": "Remise supprimée"
        },
        "edit": {
          "title": "Modifier la réduction"
        },
        "index": {
          "actions": "Actions",
          "add": "Ajouter une remise",
          "end_date": "Date de fin",
          "help_text": "Un aperçu de toutes les réductions. Utilisez les filtres pour voir les remises pour une organisation ou un type de prix.",
          "on": "Sur",
          "organization": "Organisation",
          "percentage": "Pourcentage",
          "start_date": "Date de début",
          "title": "Réductions",
          "true": "Sur"
        },
        "new": {
          "title": "Nouvelle réduction"
        },
        "update": {
          "title": "Modifier la remise",
          "updated": "La réduction a été mise à jour"
        }
      },
      "entries": {
        "create": "Gentillesse"
      },
      "import_files": {
        "import_file": {
          "delete": "Supprimer",
          "delete_confirm": "Êtes-vous sûr de vouloir supprimer cette importation ?",
          "failed": "Échoué",
          "original": "Original",
          "processed": "Incorporé"
        },
        "index": {
          "title": "Fichiers CSV importés"
        },
        "new": {
          "title": "Importer un fichier CSV"
        }
      },
      "integrations": {
        "edit": {
          "title": "Intégration de la boîte à outils de test"
        },
        "form": {
          "api_anonymous_explanation": "Si vous sélectionnez cette option, les noms d'utilisateur ne seront pas partagés avec Test Toolkit",
          "checking_integration": "L'état de l'API est en cours de vérification"
        },
        "link_testtoolkit": "Afficher l'organisation liée dans la boîte à outils de test",
        "show": {
          "api_status": {
            "correct": "L'organisation a une connexion de travail avec le Test Toolkit",
            "incorrect": "La connexion avec l'API Test-Toolkit a échoué, veuillez contacter l'équipe SD si ce problème persiste",
            "new": "L'API n'est pas encore configurée. Ajoutez un utilisateur API, une clé API et un uuid d'organisation de l'organisation Test-Toolkit pour générer un jeton d'accès."
          }
        }
      },
      "ixly_employees": {
        "create": {
          "created": "L'employé Ixly a créé avec succès <a class='underline' href='%{link}'>%{name}</a>."
        },
        "new": {
          "title": "Ajouter un collaborateur Ixly"
        }
      },
      "leniency_entries": {
        "create": {
          "created": "La transaction de clémence a été créée avec succès.",
          "title": "Nouvelle opération de clémence"
        },
        "new": {
          "form": {
            "in_euro": "Montant en euros"
          },
          "title": "Nouvelle opération de clémence"
        }
      },
      "mail_templates": {
        "mark_as_template": {
          "marked": "Le modèle d'e-mail %{title} est marqué comme modèle"
        },
        "unmark_as_template": {
          "unmarked": "Le modèle d'e-mail %{title} n'est plus marqué comme modèle"
        }
      },
      "migrated_organizations": {
        "index": {
          "title": "Organisations migrées"
        }
      },
      "migration_organizations": {
        "create": {
          "success": "Organisation de migration créée avec succès"
        },
        "index": {
          "title": "Organisations à migrer"
        },
        "new": {
          "title": "Migrer la nouvelle organisation TTK"
        },
        "reset": {
          "reset": "L'organisation de migration rétablie à « créée »"
        },
        "run_migration": {
          "run_migration": "La migration a commencé !"
        },
        "show": {
          "title": "Détails de l'organisation de migration"
        }
      },
      "organization_csv_imports": {
        "import_file": {
          "log": "Enregistrer",
          "original": "Original"
        },
        "index": {
          "list": "Liste des fichiers d'importation d'organisation",
          "title": "Importations CSV de l'organisation"
        },
        "new": {
          "title": "Importation CSV de l'organisation"
        }
      },
      "organization_settings": {
        "edit": {
          "title": "Paramètres Ixly"
        }
      },
      "organization_statistics": {
        "customer_credits": {
          "index": {
            "candidate_assessment_entry": "Évaluation",
            "candidate_assessment_program_entry": "Programme d'évaluation",
            "candidate_report_entry": "Rapport (test uniquement)",
            "generated_report_entry": "Rapport (Processus)",
            "leniency_entry": "Gentillesse",
            "list": {
              "balance_at": "Solde créditeur à %{date} :",
              "candidate": "Candidat",
              "cost_center": "Centre de coûts",
              "credits": "Nombre",
              "date": "Date",
              "description": "description",
              "entry_type": "Type de transaction",
              "no_entries": "Aucun crédit n'a été utilisé pendant cette période"
            },
            "order": {
              "frequent_credits": "Débit mensuel des crédits utilisés",
              "incidental_credits": "Achat d'un forfait de crédits",
              "intensive_credits": "Débit mensuel des crédits utilisés"
            },
            "order_entry": "Commande",
            "title": "Aperçu du crédit"
          }
        },
        "finished_assessments": {
          "index": {
            "title": "Évaluations terminées"
          },
          "list": {
            "number_finished": "Nombre arrondi",
            "task_key": "Clé de tâche",
            "task_name": "Nom de la tâche"
          }
        },
        "premium_components": {
          "index": {
            "list": {
              "number_assigned": "Numéro attribué",
              "premium_component": "Composant Premium"
            },
            "title": "Composants Premium récompensés"
          }
        },
        "show": {
          "title": "Aperçu de la gestion"
        }
      },
      "organizations": {
        "create": {
          "organization_created": "L'organisation est créée ! <a class='alert-link' href='%{link}'>Démarrez un abonnement</a> pour que ses utilisateurs puissent se connecter",
          "title": "Nouvelle organisation"
        },
        "destroy": {
          "organization_deleted": "L'organisation a été supprimée",
          "organization_deletion_failed": "L'organisation n'a pas pu être supprimée"
        },
        "index": {
          "add": "Ajouter une organisation",
          "statistics_button": "Usage",
          "subscription_button": "Abonnements",
          "title": "Organisations"
        },
        "new": {
          "title": "Nouvelle organisation"
        }
      },
      "prices": {
        "create": {
          "title": "Nouveau prix"
        },
        "edit": {
          "title": "Modifier le prix"
        },
        "index": {
          "help_text": "Voici la liste des prix",
          "title": "Des prix"
        },
        "new": {
          "title": "Nouveau prix"
        },
        "show": {
          "amc_price": "Prix AMC",
          "bov_price": "Prix BOV",
          "fit_price": "Prix FIT",
          "help_text": "Détails des prix",
          "job_market_opportunities_price": "Prix des opportunités sur le marché du travail",
          "job_suggestions_price": "Prix des suggestions d'emploi",
          "jobfeed_price": "Prix du fil d'emploi",
          "monthly_fee_per_coach": "Tarif mensuel par coach",
          "monthly_fee_per_participant": "Cotisation mensuelle par candidat",
          "title": "Prix"
        },
        "update": {
          "title": "Modifier le prix"
        }
      },
      "products": {
        "create": {
          "created": "Le module a été créé"
        },
        "deactivate": {
          "deactivated": "Le module est désactivé"
        },
        "destroy": {
          "delete_confirm": "Êtes-vous sûr de vouloir supprimer ce module ?",
          "deleted": "Le module a été supprimé"
        },
        "edit": {
          "title": "Modifier le module"
        },
        "index": {
          "help_text": "Voici la liste de tous les modules",
          "title": "Modules"
        },
        "list": {
          "actions": "Actions",
          "name": "Nom",
          "status": "Statut",
          "status_changed_at": "Statut modifié le",
          "updated_at": "Mis à jour le"
        },
        "new": {
          "title": "Nouveau module"
        },
        "reactivate": {
          "reactivated": "Le module a été réactivé"
        },
        "update": {
          "updated": "Le module a été mis à jour"
        }
      },
      "subscription_type_products": {
        "create": {
          "created": "Les modules ont été ajoutés au formulaire d'inscription",
          "products_blank": "Aucun module n'a été sélectionné"
        },
        "form": {
          "add_module": "Ajouter un module"
        },
        "new": {
          "title": "Ajouter des modules à %{subscription_type}"
        }
      },
      "subscriptions": {
        "create": {
          "incidental_subscription_credits_left": "S'il reste des crédits au début du nouvel abonnement, ils seront utilisés en premier",
          "success": "L'abonnement a été ajouté."
        },
        "destroy": {
          "success": "L'abonnement a été supprimé"
        },
        "edit": {
          "title": "Modifier l'abonnement %{subscription}"
        },
        "index": {
          "add": "Ajouter un nouvel abonnement",
          "help_text": "Vous trouverez ici l'aperçu des abonnements attribués à l'organisation",
          "invoice_settings": "Paramètres de facturation",
          "title": "Abonnements à partir de %{organization}"
        },
        "list": {
          "end_date": "Date de fin",
          "name": "Nom",
          "start_date": "Date de début"
        },
        "new": {
          "title": "Nouvel abonnement pour %{organization}"
        },
        "update": {
          "success": "L'abonnement a été mis à jour avec succès"
        }
      },
      "users": {
        "index": {
          "title": "Utilisateurs"
        }
      }
    },
    "javascript": {
      "before_unload": "Votre dernière modification n'a pas encore été enregistrée.",
      "components": {
        "add_to_calendar": {
          "add_appointment": "Mettez un rendez-vous dans votre agenda"
        },
        "advisor_introduction": {
          "avatar_placeholder": "Une fois attribué, la photo de profil de l'entraîneur sera affichée ici",
          "biography_placeholder": "La description personnelle du coach est affichée ici, telle qu'il l'a définie dans son profil.",
          "default_biography": "%{advisor_first_name} vous guide tout au long de votre voyage. Vous pouvez contacter votre coach pour toute question.",
          "default_biography_explanation": "(votre candidat voit actuellement le texte standard ci-dessus car vous n'avez pas encore défini de description personnelle dans votre profil)",
          "title_placeholder": "[nom de l'entraîneur]",
          "your_advisor": "Votre coach"
        },
        "appointment": {
          "accept": "Accepter",
          "appointment": "Planificateur de rendez-vous",
          "appointment_accepted": "Rendez-vous accepté",
          "appointment_cancelled": "Rendez-vous annulé",
          "appointment_created": "Rendez-vous créé",
          "appointment_declined": "Rendez-vous refusé",
          "appointment_submitted": "Rendez-vous suggéré",
          "at_time": "à",
          "back": "Dos",
          "cancel": "Annuler",
          "date": "Date",
          "decline": "Rejeter",
          "delete": "Retirer",
          "description": "Description",
          "description_placeholder": "explication facultative",
          "end_time": "jusqu'à",
          "help_text": "Aucune nomination n'a encore été proposée.",
          "join": "Rejoindre la conversation",
          "location": "lieu",
          "location_placeholder": "lieu de rencontre",
          "on_date": "sur",
          "plan_appointment": "Proposer un rendez-vous",
          "propose": "Présenter",
          "start_time": "par",
          "title": "Titre",
          "title_placeholder": "titre de ce rendez-vous"
        },
        "blog": {
          "help_text": "Affiche tous les articles de blog partagés avec le candidat.",
          "manage": "Gérer les articles",
          "title": "Blog"
        },
        "comments": {
          "placeholder": "message",
          "send": "Envoyer le message",
          "step_unavailable": "Cette étape n'est actuellement pas accessible au candidat. Le candidat recevra une notification concernant ce message dès que l'étape sera disponible."
        },
        "consent": [
          "non",
          "Oui",
          "Entrez la question dans les paramètres de cet élément"
        ],
        "custom_html": {
          "html_placeholder": "Entrez le code HTML dans les paramètres de cet élément pour créer un élément personnalisé"
        },
        "document_approval": {
          "approve": "Accord",
          "approved": "Approuvé",
          "candidate": "le candidat",
          "contact_person": "le contact",
          "no_file_uploaded": "Aucun fichier téléchargé par le coach",
          "no_file_uploaded_again": "Aucun nouveau fichier téléchargé par le coach",
          "reason_placeholder": "raison (facultatif)",
          "reason_placeholder_table": "Refusé ? Entrez une raison",
          "reject": "Rejeter",
          "rejected": "Refusé",
          "reset": "Encore",
          "status_message_responded": "%{action} à %{date} à %{time}",
          "status_message_submitted": "%{can_approve} n'a pas encore répondu à la demande d'approbation",
          "status_message_submitted_again": "%{can_approve} n'a pas encore répondu à la nouvelle demande d'approbation",
          "submitted": "Soumis",
          "upload_file": "Téléverser un fichier",
          "upload_file_again": "Télécharger un nouveau fichier",
          "with_reason": "à cause de \"%{reason}\""
        },
        "document_upload": {
          "no_file_uploaded": {
            "advisor": "Aucun fichier téléchargé par le coach",
            "candidate": "Aucun dossier déposé par le candidat"
          },
          "upload_file": "Téléverser un fichier"
        },
        "filter_bar": {
          "labels": {
            "filter": "Filtre",
            "records": "Enregistrements",
            "search": "Chercher",
            "sort": "Trier"
          },
          "no_filters": "tous",
          "sort": {
            "ascending": "Ascendant",
            "default": "Standard",
            "descending": "descendant"
          }
        },
        "filter_notifications": {
          "all": "Tous",
          "done": "Fait",
          "label": "Statut",
          "open": "Faire"
        },
        "image": {
          "no_image_uploaded": "Aucune image téléchargée",
          "upload_image": "Télécharger une image"
        },
        "interactive_results": {
          "agile": {
            "descriptions": {
              "groups": {
                "early_adopter": [
                  "Vous appartenez à la catégorie des « early adopters ». Vous êtes ouvert aux changements et prêt à les accompagner, même si les problèmes qui surviendront ou quelles en seront exactement les conséquences ne sont pas encore tout à fait clairs. Vous pouvez si bien gérer les innovations et le monde en évolution rapide, ce qui vous rend agile.\n\nUn risque peut être que vous n’évaluiez pas correctement les conséquences des changements. Essayez de faire une pause de temps en temps et d'écouter les idées des autres et les problèmes qu'ils voient encore dans un changement particulier.\n\nLe travail agile vous conviendra bien, car il correspond bien à votre agilité. Il est important que vous n’acceptiez pas toujours chaque changement, mais que vous terminiez également les choses avant de commencer quelque chose de nouveau.",
                  "Vous appartenez à la catégorie des « early adopters ». Vous êtes ouvert aux changements et prêt à les accompagner, même si les problèmes qui surviendront ou quelles en seront exactement les conséquences ne sont pas encore tout à fait clairs. Vous pouvez si bien gérer les innovations et le monde en évolution rapide, ce qui vous rend agile.\n\nUn risque peut être que vous n’évaluiez pas correctement les conséquences des changements. Essayez de faire une pause de temps en temps et d'écouter les idées des autres et les problèmes qu'ils voient encore dans un changement particulier.\n\nLe travail agile vous conviendra bien, car il correspond bien à votre agilité. Il est important que vous n’acceptiez pas toujours chaque changement, mais que vous terminiez également les choses avant de commencer quelque chose de nouveau."
                ],
                "early_majority": [
                  "Vous appartenez à la « première majorité ». Vous êtes légèrement plus enclin que la moyenne à adhérer à l'innovation, mais vous aimez toujours attendre que les problèmes initiaux soient résolus. Vous avez une attitude positive envers le changement, mais vous voulez savoir ce que cela signifie pour vous. Les plans, les changements et les nouvelles méthodes de travail doivent d'abord être minutieusement testés par vous.\n\nVous vous entendez assez bien avec le travail Agile et vous apprécierez qu'il existe des méthodes de travail structurées pour cela. Cependant, ne faites pas de la méthode un objectif en soi, il s'agit en fin de compte du style de travail et de la réalisation de nouveaux produits et méthodes de travail utiles.",
                  "Vous appartenez à la « première majorité ». Vous êtes plus enclin que la moyenne à suivre l’innovation, mais vous aimez attendre que les problèmes initiaux soient résolus. Vous avez une attitude positive envers le changement, mais vous voulez savoir ce que cela signifie pour vous. Les plans, les changements et les nouvelles méthodes de travail ont peut-être été bien testés pour vous.\n\nVous pouvez bien accepter le travail Agile et vous apprécierez qu’il existe des méthodes de travail structurées pour cela. Cependant, ne faites pas de la méthode un objectif en soi, il s'agit en fin de compte du style de travail et de la réalisation de nouveaux produits et méthodes de travail utiles."
                ],
                "innovator": [
                  "Vous êtes un « innovateur » et un précurseur. Vous initiez souvent des changements, proposez de nouvelles idées et êtes ouvert à de nouvelles aventures. Vous encouragez les autres à participer aux processus de changement. Vous aimez particulièrement le travail agile car c'est une manière de travailler qui est agile et qui laisse place à l'innovation. Vous aimez que cela crée un mouvement plus rapide. Parce que vous travaillez principalement dans les grandes lignes, il est possible que vous négligez les détails. Utilisez les talents des autres pour cela.\n\nIl peut y avoir un risque pour vous que vous souhaitiez parfois aller trop vite. Assurez-vous de ne pas trop devancer les troupes et que vos plans restent réalistes. La résistance et les malentendus peuvent vous irriter. Le reporting n’est pas non plus vraiment votre truc. Cela peut conduire à la négligence et vous rendre moins imitable aux yeux des autres.",
                  "Vous êtes un « innovateur » et un pionnier incontesté. Vous initiez le changement, proposez de nouvelles idées et êtes ouvert à de nouvelles aventures. Vous encouragez les autres à participer aux processus de changement. Vous aimez particulièrement le travail agile car c'est une manière de travailler qui est agile et qui laisse place à l'innovation. Vous aimez que cela crée un mouvement plus rapide. Parce que vous travaillez principalement dans les grandes lignes, il est possible que vous négligez les détails. Utilisez les talents des autres pour cela.\n\nIl pourrait y avoir un risque pour vous que vous souhaitiez aller trop vite. Assurez-vous de ne pas trop devancer les troupes et que vos plans restent réalistes. La résistance et les malentendus vous irritent. Le reporting n’est pas non plus vraiment votre truc. Cela peut conduire à la négligence et vous rendre moins imitable aux yeux des autres."
                ],
                "laggards": [
                  "Vous appartenez à ce qu'on appelle les « retardataires », les retardataires. Face aux changements, vous avez tendance à tout ignorer et préférez que tout reste pareil. Il faut vraiment être convaincu de changer, et vous préférez le faire par petites étapes plutôt que par grands pas en même temps. Vous êtes sceptique quant au changement et c’est parfois vrai. Après tout, tout changement n’est pas nécessairement une amélioration. Mais veillez à ne pas prendre trop de retard et à vouloir à tout prix conserver le vieux familier. Après tout, le monde qui nous entoure évolue rapidement. Parfois, vous devrez vous dépasser pour apprendre et appliquer de nouvelles méthodes.\n\nLà où vous pouvez être utile lors des changements, c’est en veillant à ce que l’attention soit portée aux détails, garantissant ainsi la qualité d’un changement. Vous pouvez également contribuer à rendre l’innovation pratique, en veillant à ce que tout soit élaboré et enregistré dans les moindres détails.\n\nTravailler dans des équipes Agile vous conviendra moins. Vous préférez faire partie d’une équipe dans laquelle le travail est structuré et de nature plus routinière.",
                  "Vous appartenez à ce qu'on appelle les « retardataires », les retardataires. Face aux changements, vous avez tendance à ignorer la situation. Vous avez tendance à vouloir que tout reste pareil. Il faut vraiment être convaincu de changer, et vous préférez le faire par petites étapes plutôt que par grands pas en même temps. Vous êtes sceptique quant au changement et c’est parfois vrai. Après tout, tout changement n’est pas nécessairement une amélioration. Mais faites attention à ne pas prendre trop de retard et à vouloir à tout prix conserver le vieux familier. Après tout, le monde qui nous entoure évolue rapidement. Parfois, vous devrez vous dépasser pour apprendre et appliquer de nouvelles méthodes.\n\nLà où vous pouvez être utile lors des changements, c’est en veillant à ce que l’attention soit portée aux détails, garantissant ainsi la qualité d’un changement. Vous pouvez également contribuer à rendre l’innovation pratique, en veillant à ce que tout soit élaboré et enregistré dans les moindres détails.\n\nTravailler dans des équipes Agile peut vous convenir moins. Vous préférez faire partie d’une équipe dans laquelle le travail est structuré et éventuellement plus routinier.",
                  "Vous appartenez à ce qu'on appelle les « retardataires », les retardataires. Face aux changements, vous avez tendance à ignorer la situation. Vous avez tendance à vouloir que tout reste pareil. Il faut vraiment être convaincu de changer, et vous préférez le faire par petites étapes plutôt que par grands pas en même temps. Vous êtes sceptique quant au changement et c’est parfois vrai. Après tout, tout changement n’est pas nécessairement une amélioration. Mais veillez à ne pas prendre trop de retard et à vouloir à tout prix conserver le vieux familier. Après tout, le monde qui nous entoure évolue rapidement. Parfois, vous devrez vous dépasser pour apprendre et appliquer de nouvelles méthodes.\n\nLà où vous pouvez être utile lors des changements, c’est en veillant à ce que l’attention soit portée aux détails, garantissant ainsi la qualité d’un changement. Vous pouvez également contribuer à rendre l’innovation pratique, en veillant à ce que tout soit élaboré et enregistré dans les moindres détails.\n\nTravailler dans des équipes Agile peut vous convenir moins. Vous préférez faire partie d’une équipe dans laquelle le travail est structuré et éventuellement plus routinier."
                ],
                "late_majority": [
                  "Vous appartenez à la « majorité tardive ». En fin de compte, vous acceptez les changements, mais généralement pas de tout cœur. Il vous faut des efforts pour vous adapter. Vous voulez donc toujours savoir en premier lieu quels sont les bénéfices du changement, quelle est son utilité et ce qu’il vous apportera personnellement. Faites attention à ne pas avoir tendance à adopter un « état d'esprit fixe », à ne pas vouloir trop vous accrocher au vieux familier. Bien sûr, il ne faut pas être trop à la traîne, car après tout, le monde ne reste pas immobile.\n\nVotre valeur dans les processus de changement est que vous pouvez identifier clairement les objections, les points qui peuvent encore être améliorés, avant que cela ne soit utile en pratique. Votre attitude critique augmente les chances de réussite du changement.\n\nLa question est de savoir si travailler dans des équipes Agile vous convient. Votre rôle au sein de l’équipe pourrait peut-être être celui de contrôleur ou de testeur. Quelqu'un qui identifie les risques et rend visibles les objections. Il y a aussi un besoin en équipe !",
                  "Vous appartenez à la « majorité tardive ». En fin de compte, vous acceptez les changements, mais pas toujours de tout cœur. Il faut un certain effort d'adaptation. Vous souhaitez donc savoir quels sont les bénéfices du changement, quelle est son utilité et en quoi il vous bénéficiera personnellement. Faites attention à ne pas avoir tendance à adopter un « état d'esprit fixe », à ne pas vouloir trop vous accrocher au vieux familier. Bien sûr, il ne faut pas être trop à la traîne, car après tout, le monde ne reste pas immobile.\n\nVotre valeur dans les processus de changement est que vous pouvez identifier clairement les objections, les points qui peuvent encore être améliorés, avant que cela ne soit utile en pratique. Votre attitude critique augmente les chances de réussite du changement.\n\nLa question est de savoir si travailler dans des équipes Agile vous convient. Votre rôle au sein de l’équipe pourrait peut-être être celui de contrôleur ou de testeur. Quelqu'un qui identifie les risques et rend visibles les objections. Il y a aussi un besoin en équipe !"
                ]
              },
              "pillars": {
                "change": [
                  "Vous aimez savoir où vous en êtes. Vous aimez quand les choses restent les mêmes et que vous avez une certaine routine dans votre travail. Vous préférez travailler sur un ensemble de tâches fixes, selon des procédures prescrites. Vous préférez jouer la sécurité plutôt que de prendre des risques. Vous n’avez pas nécessairement besoin de changer ou d’innover. Mettre en œuvre des changements dans votre travail est quelque chose que vous pourriez trouver ennuyeux. D’autres peuvent également éprouver cela comme une difficulté, comme une résistance. Essayez de voir le positif dans ce qui vous arrive. Faites de petits pas, mais continuez à avancer. Faites attention à ne pas devenir l’un de ceux qui sont laissés pour compte.",
                  "Vous aimez que les choses restent les mêmes, mais les petits changements ne vous dérangent pas. Vous préférez généralement travailler sur un ensemble fixe de tâches selon des procédures prescrites. Même si vous aimez savoir où vous en êtes, vous êtes parfois aussi curieux des changements visant à vous améliorer. Vous ne serez pas à l’avant-garde de ces changements, car vous préférez limiter votre prise de risque au strict minimum, mais vous vous conformerez aux changements. Vous ne le comprendrez pas non plus très rapidement.",
                  "Vous êtes neutre face aux changements. Bien que vous aimiez avoir certaines tâches fixes, vous aimez également expérimenter une certaine variété dans votre environnement ou vos tâches. Lorsqu’un changement survient dans votre organisation, vous ne serez pas immédiatement celui qui initiera ce changement, mais vous pourrez vous adapter assez facilement à la nouvelle situation. Vous ne trouvez pas nécessaire de travailler selon des cadres fixes, même si cela ne vous dérange pas. De plus, vous accueillez favorablement les nouvelles tâches qui doivent encore être réfléchies.",
                  "Vous aimez quand des changements surviennent régulièrement dans votre travail. Vous avez peu besoin d'un ensemble de tâches fixes, vous préférez un travail varié. Lorsque vous effectuerez des tâches régulières, vous réfléchirez à de nouvelles façons d’aborder ce travail différemment. Vous trouvez démotivant qu’on vous dise comment faire certaines choses. Lorsque quelqu’un initie un changement, vous y serez ouvert, mais les risques associés ne devraient pas être trop élevés à votre avis.",
                  "Vous acceptez les changements. Vous êtes également souvent à l'initiative de l'innovation ; de nouvelles idées et expériences vous viennent naturellement. Idéalement, chaque journée de travail serait différente pour vous. Vous aimez avoir des tâches stimulantes et variées que vous pouvez organiser comme bon vous semble. Vous considérez les procédures, la routine et les réglementations comme démotivantes. En règle générale, vous serez à l’avant-garde des changements, ce que vous initiez régulièrement. Prendre des risques ne vous dissuade pas, car vous considérez l’innovation plus importante que la certitude."
                ],
                "growth": [
                  "Vous avez besoin de certitude et de routine. Vous ne considérez pas l’ambition ou le fait de vous mesurer aux autres comme stimulant, mais cela vous décourage. Vous maîtrisez un certain nombre de tâches pour lesquelles vous excellez et vous ne jugez pas nécessaire d'apprendre d'autres tâches. Vous êtes généralement satisfait du niveau auquel vous effectuez actuellement les tâches et vous ne ressentez aucune pression pour effectuer les tâches mieux que vos collègues. Vous évitez les tâches qui, selon vous, dépassent vos capacités, car vous souhaitez commettre peu d’erreurs. Parce que vous êtes tellement concentré sur la qualité, vous ne pouvez pas toujours l’ignorer lorsque vous faites une erreur.",
                  "Vous avez plus besoin de sécurité que de développement. Vous maîtrisez un certain nombre de tâches pour lesquelles vous excellez et vous êtes généralement satisfait du niveau auquel vous exécutez désormais ces tâches. Vous souhaitez apprendre comment vous pouvez encore mieux effectuer ces tâches, mais vous n’avez guère besoin d’apprendre d’autres tâches. Vous évitez les tâches qui, selon vous, dépassent vos capacités, car vous souhaitez commettre peu d’erreurs. Vous pouvez prendre personnellement en compte les commentaires sur votre travail, car vous aimez paraître bon aux autres.",
                  "Vous avez un besoin moyen de développement personnel. Vous aimez avoir un certain nombre de tâches fixes, mais vous êtes également ouvert à l'apprentissage de nouvelles tâches. Vous aimez également vous améliorer dans vos tâches actuelles. Vous voyez votre performance de manière isolée et n’êtes pas enclin à comparer votre performance à celle de vos collègues. Même si vous n'aimez pas faire des erreurs, vous comprenez que cela fait partie du processus d'apprentissage.",
                  "Vous avez un grand besoin de développement personnel. Votre objectif est d'apprendre beaucoup et de vous améliorer dans les tâches dont vous êtes responsable. Vous préférez mieux accomplir vos tâches et vous attaquer à des tâches plus difficiles que vos collègues. Cela ne vous dérange pas de faire des erreurs, car cela fait partie du processus d'apprentissage. Vous avez une approche résiliente et vous n’êtes pas facilement arrêté. Enfin, cela ne vous dérange pas d'avoir certaines tâches qui offrent moins de possibilités d'évolution.",
                  "Vous trouvez votre développement personnel extrêmement important. Vous avez une grande soif de connaissances et vous aimeriez faire des démarches. Vous trouvez les tâches routinières ennuyeuses, car vous préférez réfléchir le plus possible. Vous avez pour objectif d’apprendre beaucoup et n’avez aucun problème à faire des erreurs. Vous considérez ces erreurs comme des sources d’informations, car ce sont les points sur lesquels vous pouvez vous développer encore davantage. Vous abordez des tâches difficiles à deux mains. Autant que possible, vous essayez de tirer le meilleur de vous-même et vous voulez être meilleur que les autres."
                ],
                "power": [
                  "Vous avez à cœur de préserver ce qui est bon, en vous basant sur votre démarche axée sur la qualité. Vous préférez travailler sur des activités ou des projets familiers pour lesquels vous maîtrisez déjà les compétences, car vous souhaitez livrer un travail irréprochable. Démarrer de nouveaux projets ou apprendre d’autres tâches vous attire moins car vous avez peur de vous tromper. Les projets que vous maîtrisez par l’expérience et la routine sont donc votre préférence. Vous êtes critique envers les changements qui peuvent bénéficier à la qualité de ces innovations. Cependant, parce que vous voyez régulièrement des obstacles sur la route, vous avez aussi tendance à résister aux changements. Les changements peuvent également vous coûter trop d’énergie. Il est conseillé d’étudier comment renforcer votre pouvoir, disposer de suffisamment d’énergie pour maintenir votre place dans un monde en évolution et y contribuer positivement.",
                  "Vous avez une préférence pour vous immerger dans un seul sujet. Vous préférez travailler plus longtemps sur un projet dans lequel vous gagnez en expertise, plutôt que de vous concentrer sur l’élargissement de votre expertise. Vous aimez également travailler sur des projets dans lesquels tous les processus sont connus et rationalisés. Vous préférez les tâches et les projets que vous maîtrisez grâce à l'expérience et à la routine. Les problèmes de démarrage au sein des nouveaux projets vous agacent. Vous adopterez donc une attitude critique face aux innovations, qui pourra bénéficier à la qualité de ces changements. Attention à ne pas trop vous laisser freiner par les éventuels obstacles que vous croisez sur la route, car les changements peuvent aussi avoir de nombreuses conséquences positives. Il est conseillé d’étudier comment renforcer votre pouvoir, disposer de suffisamment d’énergie pour maintenir votre place dans un monde en évolution et y contribuer positivement.",
                  "Vous disposez de suffisamment d’énergie pour faire face aux changements sans jouer un rôle d’initiateur. D’une part, vous voyez les avantages des changements et, d’autre part, vous trouvez agréable de vous en tenir à ce qui vous est familier. Vous êtes ouvert à de nouvelles tâches et activités, mais vous aimez quand il n'y a pas trop de changements en même temps. L’avantage de cette variété est qu’elle vous donne l’espace nécessaire pour faire face aux éventuels problèmes initiaux liés à ces changements.",
                  "Vous possédez une forte dose d’énergie d’innovation. Vous faites preuve de beaucoup de force et d’engagement et pouvez donc très bien gérer les changements. En général, vous voyez les aspects positifs de l'innovation, même si vous pouvez parfois trouver ennuyeux que tous les processus n'aient pas encore été optimisés. Cependant, vous ne vous laissez pas arrêter et vous contribuez activement à l’amélioration des processus de changement sur le lieu de travail.",
                  "Vous possédez une énorme dose de tempérament innovant. Cela signifie que vous avez la force, l’engagement et l’attitude nécessaires pour bien gérer les changements. Même si les innovations peuvent avoir de nombreuses conséquences positives, il existe souvent des problèmes initiaux ou des processus qui n’ont pas encore été optimisés. Vous comprenez que cela fait partie du processus de changement et pouvez bien le gérer. En cas d’un tel revers, vous ne vous laisserez pas décourager et continuerez à travailler avec une attitude positive. Vous pensez qu’avec du travail acharné et de la persévérance, vous pouvez surmonter n’importe quel défi."
                ]
              },
              "tips": {
                "change": [
                  "Même si, rationnellement, chaque changement comporte ses points positifs et ses opportunités, vous pouvez accorder davantage d’attention aux dangers et aux menaces qu’il implique. Lors des processus de changement, demandez-vous régulièrement ce qui peut réellement être conclu avec certitude et ce que vous en avez fait vous-même. Interrogez les ours que vous voyez sur la route. En prenant conscience de la frontière entre les faits et vos propres interprétations, vous apprenez à gérer les changements de manière plus neutre. Une autre façon de gérer les choses que vous considérez comme des menaces est de noter tous les risques et inconvénients qui vous viennent à l’esprit. Forcez-vous ensuite à offrir en retour autant d’avantages et de nouvelles opportunités.",
                  "Même si, rationnellement, chaque changement comporte ses points positifs et ses opportunités, vous pouvez accorder davantage d’attention aux dangers et aux menaces qu’il implique. Lors des processus de changement, demandez-vous régulièrement ce qui peut réellement être conclu avec certitude et ce que vous en avez fait vous-même. Interrogez les ours que vous voyez sur la route. En prenant conscience de la frontière entre les faits et vos propres interprétations, vous apprenez à gérer les changements de manière plus neutre. Une autre façon de gérer les choses que vous considérez comme des menaces est de noter tous les risques et inconvénients qui vous viennent à l’esprit. Forcez-vous ensuite à offrir en retour autant d’avantages et de nouvelles opportunités.",
                  "Vous vous adaptez assez facilement aux changements. Cependant, vous ne serez pas toujours enclin à réagir avec enthousiasme aux changements. D’un point de vue rationnel, chaque changement a ses points positifs et ses opportunités. Pour augmenter votre enthousiasme pour le changement, faites une liste de tous les avantages et nouvelles opportunités que ce changement apporte. En prenant conscience des conséquences positives du changement, vous vous enthousiasmerez plus facilement.",
                  "Grâce à votre attitude ouverte aux changements, vous serez une bonne boussole pour les innovateurs, qui veulent parfois prendre trop d'avance sur le peloton. Estimez les risques des changements et dans quelle mesure vous les considérez réalistes, et discutez-en avec les innovateurs.",
                  "Assurez-vous de rester en contact avec les autres, ne marchez pas trop loin devant les troupes. Faire attention à garantir la qualité des changements ne fait pas de mal."
                ],
                "growth": [
                  "Recherchez quelles compétences vous pouvez et souhaitez développer davantage et quelles compétences sont utiles pour votre employabilité. Parce que vous vous concentrez sur la certitude et la routine, vous risquez de moins vous concentrer sur les compétences pertinentes et intéressantes pour votre employeur à l'avenir. Essayez de vous prémunir contre cela et saisissez les opportunités lorsque vous voyez des opportunités de développer davantage les compétences que vous jugez utiles.",
                  "Recherchez quelles compétences vous pouvez et souhaitez développer davantage et quelles compétences sont utiles pour votre employabilité. Parce que vous avez plus besoin de sécurité que d’évolution, vous risquez de moins vous concentrer sur les compétences pertinentes et intéressantes pour votre employeur à l’avenir. Essayez de vous prémunir contre cela en découvrant quelles compétences pertinentes vous pouvez maîtriser et en prenant des initiatives pour développer ces compétences.",
                  "Vous disposez d'une bonne combinaison de qualités : vous n'avez aucun problème à effectuer des tâches familières, mais vous aimez également continuer à apprendre. Vérifiez si votre solde actuel correspond à vos souhaits. Y a-t-il des compétences que vous aimeriez maîtriser ? Que pouvez-vous faire pour développer ces compétences ? Essayez de prendre des initiatives en ce sens et créez des opportunités dans votre travail.",
                  "Vous disposez d'une bonne combinaison de qualités : vous aimez vous développer dans divers domaines, mais vous n'avez également aucun problème à effectuer des tâches familières. Vérifiez si votre solde actuel correspond à vos souhaits. Y a-t-il des compétences que vous aimeriez maîtriser ? Que pouvez-vous faire pour développer ces compétences ? Essayez de prendre des initiatives en ce sens et créez des opportunités dans votre travail.",
                  "Vous avez une grande soif de connaissance et de développement personnel, une bonne qualité lorsqu'il s'agit de changements dans les organisations. Cependant, rappelez-vous également que tout ne changera pas en même temps et que certaines tâches routinières font simplement partie de votre travail. Ne laissez pas votre humeur et votre motivation vous influencer trop lorsque vous devez effectuer des tâches plus familières, car grâce à votre ouverture au développement, une nouvelle tâche stimulante se présentera automatiquement à vous."
                ],
                "power": [
                  "Pour renforcer votre pouvoir, vous pourriez découvrir quelles conséquences positives d’autres personnes ont subies à la suite de changements. Essayez également de vous demander quels changements ont eu lieu dans votre vie ou sur votre lieu de travail et comment vous les avez gérés. Concentrez-vous principalement sur les aspects que vous avez bien abordés. Y a-t-il eu des moments où vous avez initié des changements vous-même ? Quelles en ont été les conséquences positives ? En vous concentrant sur ces éléments, vous pouvez augmenter votre confiance en vous pour faire face aux changements.",
                  "Pour renforcer votre pouvoir, vous pourriez découvrir quelles conséquences positives d’autres personnes ont subies à la suite de changements. Essayez également de deviner quels changements ont eu lieu dans votre vie ou sur votre lieu de travail et comment vous les avez gérés. Concentrez-vous principalement sur les aspects que vous avez bien abordés. Y a-t-il eu des moments où vous avez initié des changements vous-même ? Quelles en ont été les conséquences positives ? En vous concentrant sur ces éléments, vous pouvez augmenter votre confiance en vous pour faire face aux changements.",
                  "Voyez votre opportunité : vous avez un rôle important dans la modération des projets trop ambitieux, dans lesquels les gens veulent en même temps s'attaquer à trop de choses. Même si vous êtes ouvert aux changements, vous gardez les pieds sur terre. Cette attitude peut faire réfléchir les innovateurs.",
                  "Voyez votre opportunité : vous avez un rôle important dans la modération des projets trop ambitieux, dans lesquels les gens veulent en même temps s'attaquer à trop de choses. Même si vous êtes très ouvert aux changements, vous êtes également conscient des éventuels obstacles. Essayez de le signaler aux innovateurs et réfléchissez à la manière dont vous pouvez prévenir ou résoudre les obstacles.",
                  "C'est une bonne qualité d'avoir beaucoup d'énergie pour faire face aux changements. Assurez-vous cependant de ne pas vous laisser aveugler par votre enthousiasme et écoutez les préoccupations et les souhaits que vos collègues expriment concernant les changements. Prenez au sérieux les obstacles possibles qu’ils voient et essayez de trouver une solution. Cela améliorera la qualité des changements."
                ]
              }
            },
            "header": "Agilité",
            "tip": "ASTUCE!",
            "titles": {
              "groups": {
                "early_adopter": "Adopteur précoce",
                "early_majority": "Majorité précoce",
                "innovator": "Innovateur",
                "laggards": "Les retardataires",
                "late_majority": "Majorité tardive"
              },
              "pillars": {
                "change": "Changement",
                "growth": "Croissance personnelle (croissance)",
                "power": "Pouvoir"
              }
            }
          },
          "behavior": {
            "header": "Traits comportementaux",
            "keys": {
              "aanspreken": "Parler et parler",
              "durft": "Oser",
              "veranderbereid": "Changer la volonté"
            }
          },
          "candidate": {
            "date_of_birth": "Date de naissance",
            "education": "Niveau d'éducation",
            "email": "Adresse e-mail",
            "header": "Données personnelles",
            "most_recent_work_experience": "Expérience professionnelle la plus récente",
            "study_subject": "Éducation",
            "years": {
              "other": "%{years} ans"
            },
            "years_of_experience": "Nombre d'années d'expérience"
          },
          "competences": {
            "descriptions": {
              "aansturenvangroepen": "Être capable d'animer un groupe.",
              "aansturenvanindividuen": "Être capable de diriger un individu.",
              "ambitie": "Être motivé, vouloir performer au-dessus de la moyenne.",
              "assertiviteit": "Défend vos propres opinions, même lorsque la pression est exercée sur lui par l'environnement.",
              "besluitvaardigheid": "Être capable de prendre des décisions rapidement et adéquatement.",
              "coachenvanmedewerkers": "Être capable de stimuler le rôle des collaborateurs cadres et de les guider dans leur développement.",
              "delegeren": "Être capable de transférer du travail à d'autres de manière ciblée.",
              "doorzettingsvermogen": "Se concentrer sur l’accomplissement des choses une fois commencées, malgré les revers.",
              "flexibiliteit": "Peut agir de manière ciblée et efficace dans des circonstances différentes et changeantes en changeant les styles de comportement.",
              "initiatief": "Être capable d’initier des choses et d’agir seul de manière efficace.",
              "inzet": "Être capable de faire un gros effort sur une longue période.",
              "klantorientatie": "Être capable de faire preuve d'empathie et de répondre aux besoins d'un client.",
              "kwaliteitsgerichtheid": "Se concentrer sur la fourniture de haute qualité et le perfectionnement des choses.",
              "leerbereidheid": "Être prêt à développer et à élargir ses connaissances et ses compétences par l’apprentissage.",
              "nauwkeurigheid": "Être capable de travailler avec précision et avoir le sens des détails et des connexions.",
              "onderhandelen": "Être capable d'explorer des intérêts et des positions mutuels afin de parvenir à un accord acceptable pour toutes les parties.",
              "ondernemerschap": "Se concentrer sur la réalisation du profit en identifiant et en saisissant les opportunités d'affaires et en osant prendre des risques calculés.",
              "optreden": "Faire une forte impression professionnelle sur les autres.",
              "organisatiesensitiviteit": "Être capable de reconnaître l'influence et les conséquences de ses propres décisions ou activités sur d'autres unités organisationnelles.",
              "overtuigingskracht": "Être capable d'amener les autres à être d'accord avec un certain point de vue, une proposition ou une idée.",
              "plannenenorganiseren": "Être capable de planifier et d’organiser des activités et du travail.",
              "relatiebeheer": "Établir et entretenir des relations de manière constructive.",
              "resultaatgerichtheid": "Être déterminé à atteindre des objectifs et des résultats concrets.",
              "samenwerking": "Atteindre des objectifs communs avec d’autres de manière constructive.",
              "sensitiviteit": "Être capable de détecter les signaux des autres et d’y répondre de manière appropriée.",
              "sociabiliteit": "Se déplace volontiers et facilement en groupe de personnes.",
              "stressbestendigheid": "Être capable de continuer à fonctionner efficacement dans des circonstances stressantes.",
              "voortgangscontrole": "Être capable de suivre les dossiers une fois lancés et de vérifier les progrès.",
              "zelfstandigheid": "Être capable d'effectuer un travail de manière autonome, en fixant des objectifs et en leur donnant forme et contenu."
            },
            "header": "Vos 5 principales compétences",
            "keys": {
              "aansturenvangroepen": "Gestion des groupes",
              "aansturenvanindividuen": "Gérer les individus",
              "ambitie": "Ambition",
              "assertiviteit": "Assurance",
              "besluitvaardigheid": "Esprit de décision",
              "coachenvanmedewerkers": "Coacher les employés",
              "delegeren": "Déléguer",
              "doorzettingsvermogen": "Persévérance",
              "flexibiliteit": "La flexibilité",
              "initiatief": "Initiative",
              "inzet": "Miser",
              "klantorientatie": "Orientation client",
              "kwaliteitsgerichtheid": "Orientation qualité",
              "leerbereidheid": "Envie d'apprendre",
              "nauwkeurigheid": "Précision",
              "onderhandelen": "Négocier",
              "ondernemerschap": "Entrepreneuriat",
              "optreden": "Performance",
              "organisatiesensitiviteit": "Sensibilité organisationnelle",
              "overtuigingskracht": "Force de persuasion",
              "plannenenorganiseren": "Planifier et organiser",
              "relatiebeheer": "Gestion de la relation",
              "resultaatgerichtheid": "Resultat d'orientation",
              "samenwerking": "Coopération",
              "sensitiviteit": "Sensibilité",
              "sociabiliteit": "Sociabilité",
              "stressbestendigheid": "Résistance aux contraintes",
              "voortgangscontrole": "Vérification des progrès",
              "zelfstandigheid": "Indépendance"
            }
          },
          "development": {
            "header": "Potentiel de développement",
            "keys": {
              "ambitie": "Ambition",
              "growth": "Croissance personnelle",
              "onafhankelijkheid": "Indépendance",
              "samenwerking": "Travailler en équipe",
              "sociabiliteit": "Sociabilité"
            }
          },
          "documents": {
            "date": "Date",
            "header": "Documents (%{documents})",
            "less": "Montrer moins",
            "more": "Montre plus",
            "refresh": "Votre session a expiré. Cliquez sur le bouton ci-dessous pour recharger la page."
          },
          "explanations": {
            "behavior": {
              "description": "Les caractéristiques comportementales sont les principes qui constituent la base de la manière dont nous souhaitons collaborer les uns avec les autres au sein de NS."
            },
            "intelligence": {
              "description": "Dans ce widget, vous voyez deux types de scores. Votre score d'intelligence est affiché sur la première page. Un score de 100 est la moyenne de la population active néerlandaise.\n\nDans les pages suivantes, votre score est comparé à des groupes de référence de différents niveaux d'éducation. Les chiffres sur ces pages sont des scores Sten, qui montrent votre score par rapport au groupe de référence. Des scores de 5 ou 6, par exemple, sont moyens et courants. Des scores de 1 ou 10, par exemple, sont exceptionnels et rares."
            },
            "interests": {
              "description": "Dans ce widget, vous voyez deux types de scores, votre intérêt pour les secteurs et pour les tâches. Votre intérêt pour les secteurs montre dans quel secteur du marché du travail vous aimeriez travailler. Votre intérêt pour les tâches indique les activités que vous aimeriez faire. Ces informations peuvent vous aider à découvrir quel travail vous intéresse. Vous effectuerez ce travail avec plus de motivation et de plaisir qu’un travail moins pertinent par rapport à vos intérêts.\n\nLa première page montre vos 5 principaux intérêts dans les secteurs. Les scores vont de 1 à 10, où 1 représente un intérêt bien inférieur à la moyenne et 10 représente un intérêt bien supérieur à la moyenne. La deuxième page montre vos 5 principaux intérêts pour les tâches. Les scores vont de 1 à 5, où 1 représente un intérêt bien inférieur à la moyenne et 5 représente un intérêt bien supérieur à la moyenne."
            },
            "star_scores": {
              "description": "Les étoiles de ce widget indiquent à quel point les compétences sont développables pour vous. Plus il y a d’étoiles jaunes, plus votre talent est grand. Cela ne signifie pas que la compétence a déjà été développée et à quel niveau."
            },
            "sten_scores": {
              "description": "Les chiffres dans ce widget sont des scores Sten, qui montrent votre score par rapport à la population active néerlandaise. Des scores de 5 ou 6, par exemple, sont moyens et courants. Des scores de 1 ou 10, par exemple, sont exceptionnels et rares. Les scores inférieurs et supérieurs à la moyenne comportent à la fois des qualités et des pièges.",
              "scores": [
                "Bien en dessous de la moyenne",
                "Bien en dessous de la moyenne",
                "Sous la moyenne",
                "Juste en dessous de la moyenne",
                "Moyenne",
                "Moyenne",
                "Juste au dessus de la moyenne",
                "Au dessus de la moyenne",
                "Bien supérieur à la moyenne",
                "Bien au-dessus de la moyenne"
              ]
            }
          },
          "filter": {
            "clear": "Effacer la sélection",
            "empty": "Sélectionnez jusqu'à 10 compétences pour voir vos résultats",
            "header": "Filtrer les compétences"
          },
          "hybrid_work": {
            "average": {
              "gemiddeld": "moyenne",
              "hoog": "haut",
              "laag": "bas"
            },
            "header": "Compétences du 21ème siècle",
            "skills": {
              "autonomie": {
                "descriptions": {
                  "gemiddeld": "Lorsque vous travaillez à domicile, vous bénéficiez d’une certaine liberté pour décider de manière indépendante de la manière dont vous effectuez votre travail. Cependant, vous avez parfois besoin d’une autorisation pour effectuer certaines tâches et vous bénéficiez d’un certain degré de contrôle. De plus, vous pouvez parfois être distrait par votre environnement familial. Vous vous sentez probablement capable d'utiliser vos compétences pour contribuer à l'équipe et/ou à l'organisation dans laquelle vous travaillez. Cela peut conduire à une certaine motivation et satisfaction.",
                  "hoog": "Lorsque vous travaillez à domicile, vous bénéficiez de la liberté de décider de manière indépendante de la manière dont vous effectuez votre travail. Vous n'êtes pas beaucoup surveillé. Vous faites l'expérience d'un haut degré de responsabilité et êtes capable de planifier et d'organiser votre travail de manière indépendante. Vous êtes rarement dérangé par les distractions de votre environnement familial et vous ne rencontrez pratiquement aucun conflit entre vie privée et travail. Vous vous sentez probablement capable d'utiliser vos compétences pour contribuer à l'équipe et/ou à l'organisation dans laquelle vous travaillez. Cela peut conduire à une motivation et une satisfaction élevées.",
                  "laag": "Lorsque vous travaillez à domicile, vous disposez de peu de liberté pour décider de manière indépendante de la manière d'effectuer votre travail. Vous êtes souvent surveillé et avez besoin d’une autorisation pour effectuer certaines tâches. Cela vous rend dépendant des autres pour prendre des décisions concernant votre travail. Vous êtes probablement parfois distrait par votre environnement familial pendant votre travail et vous vivez des conflits entre vie privée et vie professionnelle. Vous pouvez vous sentir inhibé et avoir peu de motivation et de satisfaction."
                },
                "label": "Autonomie"
              },
              "competentie": {
                "descriptions": {
                  "gemiddeld": "Lorsque vous travaillez à domicile, vous ressentez un sentiment de compétence moyen. Cela signifie que vous possédez généralement les compétences et les connaissances nécessaires pour faire votre travail efficacement, mais que vous pouvez parfois avoir du mal à bien accomplir certaines tâches. Vous pouvez parfois être limité par vos compétences technologiques. En général, vous avez une certaine confiance en vos propres compétences, mais vous ne vous sentez peut-être pas sûr de votre capacité à bien faire votre travail. Cela peut conduire à une diminution de la motivation et de la satisfaction.",
                  "hoog": "Lorsque vous travaillez à domicile, vous bénéficiez d'un haut degré de compétence. Vous possédez les compétences et les connaissances nécessaires pour effectuer votre travail efficacement. Vous rencontrez peu ou pas de problèmes avec vos compétences techniques lorsque vous travaillez à domicile. Vous avez très confiance en vos compétences et êtes susceptible d'être motivé et très satisfait de votre performance au travail.",
                  "laag": "Lorsque vous travaillez à domicile, vous ressentez un faible sentiment de compétence. Cela signifie que vous disposez probablement de compétences et de connaissances limitées pour faire votre travail efficacement et que vous pourriez avoir des difficultés à accomplir certaines tâches. Vous pouvez rencontrer des problèmes avec vos compétences technologiques lorsque vous travaillez à domicile. C'est probablement la raison pour laquelle vous avez peu confiance en vos propres compétences. Vous ne vous sentez peut-être pas sûr de votre capacité à bien faire votre travail. Cela peut conduire à une diminution de la motivation et de la satisfaction."
                },
                "label": "Compétence"
              },
              "verbondenheid": {
                "descriptions": {
                  "gemiddeld": "Lorsque vous travaillez à domicile, vous bénéficiez d’un degré raisonnable de connexion avec vos collègues. Il se peut que vous ayez parfois des difficultés à entrer en contact avec vos collègues. Vous ne ressentez pas toujours l’implication de vos collègues. Cela peut conduire à une diminution de la motivation et de la satisfaction.",
                  "hoog": "Lorsque vous travaillez à domicile, vous ressentez un fort sentiment de connexion avec vos collègues. Vous entrez facilement en contact avec vos collègues et bénéficiez de leur soutien et de leur implication lorsque vous en avez besoin. Cela contribue à votre intérêt pour votre travail. Vous êtes probablement motivé à performer et satisfait de votre travail.",
                  "laag": "Lorsque vous travaillez à domicile, vous ressentez un faible sentiment de connexion avec vos collègues. Vous avez des difficultés à entrer en contact avec vos collègues et ressentez peu de soutien et d'implication de leur part. Cela peut conduire à une diminution de la motivation et de la satisfaction."
                },
                "label": "Connectivité"
              }
            },
            "title": "Travail hybride"
          },
          "information": {
            "skills": {
              "informatievaardigheden": {
                "label": "Compétences informationnelles : Gérer l’information",
                "tips": [
                  "La manière dont vous recherchez et traitez les informations peut être améliorée dans un certain nombre de domaines. Vous pouvez en savoir plus sur le modèle Big 6 de maîtrise de l'information sur <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">ce</a> site Web. Vérifiez par vous-même quelles sont les étapes que vous pouvez encore améliorer !",
                  "La manière dont vous recherchez et traitez les informations peut être améliorée dans un certain nombre de domaines. Vous pouvez en savoir plus sur le modèle Big 6 de maîtrise de l'information sur <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">ce</a> site Web. Vérifiez par vous-même quelles sont les étapes que vous pouvez encore améliorer !",
                  "La manière dont vous recherchez et traitez l’information est déjà bonne à bien des égards ! Mais il y a encore place à l’amélioration. Vous pouvez en savoir plus sur le modèle Big 6 de maîtrise de l'information sur <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">ce</a> site Web. Vérifiez par vous-même quelles étapes vous faites déjà bien et quelles étapes vous pouvez améliorer.",
                  "La façon dont vous recherchez et traitez les informations est déjà à un niveau élevé. Mais qui sait, il y a encore place à l’amélioration. Sur <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">ce</a> site Web, vous pouvez en savoir plus sur le modèle Big 6 de maîtrise de l'information. Vérifiez par vous-même quelles étapes vous maîtrisez déjà bien et quelles étapes vous pouvez encore améliorer !",
                  "La façon dont vous recherchez et traitez les informations est déjà à un niveau élevé. Mais qui sait, il y a encore place à l’amélioration. Vous pouvez en savoir plus sur le modèle Big 6 de maîtrise de l'information sur <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">ce</a> site Web. Vérifiez par vous-même quelles sont les étapes que vous maîtrisez déjà bien et celles que vous pouvez encore améliorer !"
                ]
              },
              "kritisch": {
                "description": "Examen critique des informations",
                "label": "Critique",
                "tips": [
                  "Vous ne portez pas de regard critique sur la source de vos informations et vous avez tendance à copier les informations de manière littérale. Essayez de combiner plus souvent des informations provenant de différentes sources et de les exprimer dans vos propres mots. De cette façon, vous êtes automatiquement plus critique sur les informations que vous adoptez.",
                  "Vous n’êtes pas toujours très critique quant à la source de vos informations. Vous avez tendance à prendre les informations au pied de la lettre. Essayez de combiner plus souvent des informations provenant de différentes sources et de les exprimer dans vos propres mots. De cette façon, vous êtes automatiquement plus critique sur les informations que vous adoptez.",
                  "Vous essayez de ne pas simplement accepter les informations et de les copier littéralement. Mais vous pourriez faire cela encore plus. Essayez de combiner plus souvent des informations provenant de différentes sources et de les exprimer dans vos propres mots. De cette façon, vous êtes automatiquement plus critique sur les informations que vous adoptez.",
                  "Vous êtes minutieux lors de votre recherche et lors du traitement des informations. Vous examinez de manière critique diverses sources. Vous essayez également de mettre les informations dans vos propres mots au lieu de simplement les copier. Ne relâchez pas cette vision critique.",
                  "Vous êtes minutieux lors de votre recherche et lors du traitement des informations. Vous examinez de manière critique diverses sources. Vous essayez également de mettre les informations dans vos propres mots au lieu de simplement les copier. Gardez cet œil critique."
                ]
              },
              "organisatie": {
                "description": "Organisation des informations",
                "label": "Organisation",
                "tips": [
                  "Vous ne prenez pas toujours le temps de préparer ou d'évaluer votre processus de recherche. Par conséquent, vous ne trouverez pas toujours les informations correctes et votre processus de recherche peut prendre plus de temps que nécessaire.",
                  "Vous pouvez parfois prendre plus de temps pour préparer ou évaluer votre processus de recherche. Cela vous permet d'effectuer une recherche encore plus précise et d'accélérer votre processus de recherche.",
                  "Vous essayez de préparer et d’évaluer votre processus de recherche, mais il y a encore place à l’amélioration. Avec une bonne préparation et un œil critique lors du processus de recherche, vous pouvez effectuer une recherche encore plus précise.",
                  "Vous prenez le temps de préparer et d’évaluer votre processus de recherche. Essayez de le faire plus souvent, car avec une bonne préparation et un œil critique pendant le processus de recherche, vous pouvez effectuer une recherche encore plus précise.",
                  "Vous prenez beaucoup de temps pour préparer et évaluer votre processus de recherche. De cette façon, vous évitez les erreurs et la duplication du travail."
                ]
              },
              "presenteren": {
                "description": "Présentation des informations",
                "label": "Présent",
                "tips": [
                  "La façon dont vous présentez les informations n’est pas toujours claire et ordonnée. Peut-être avez-vous du mal à créer une structure ou ne tenez pas compte de votre public.",
                  "La façon dont vous présentez les informations peut être plus claire et plus organisée. Peut-être avez-vous du mal à créer une structure ou ne tenez pas compte de votre public.",
                  "Vous faites de votre mieux pour présenter les informations de manière claire et concise. Vous pourriez améliorer encore davantage cela en ajoutant plus de structure. Vous pouvez également prendre encore plus en compte le niveau et les connaissances préalables de votre public.",
                  "La manière dont vous présentez les informations est généralement claire et ordonnée. Pour améliorer encore cela, il est bon de réfléchir à qui est votre public. Vous pourrez ainsi mieux prendre en compte le niveau et les connaissances préalables de votre audience.",
                  "La façon dont vous présentez les informations est claire et ordonnée. Vous vous assurez que votre histoire a un fil conducteur. Vous tenez également compte du niveau et des connaissances préalables de votre audience."
                ]
              },
              "strategie": {
                "description": "La recherche stratégique d’informations",
                "label": "Stratégie",
                "tips": [
                  "Vous êtes limité à une seule méthode de recherche. De ce fait, vous ne trouverez pas toujours les bonnes informations. Votre recherche prendra également plus de temps que nécessaire. Recherchez des conseils pour affiner votre recherche. Changez de stratégie si vous constatez que vous ne trouvez pas la bonne information.",
                  "Vous avez tendance à vous limiter à une seule méthode de recherche. Cela peut rendre difficile la recherche des bonnes informations. Votre recherche prendra également plus de temps que nécessaire. Recherchez des conseils pour améliorer votre recherche. Changez de stratégie si vous constatez que vous ne trouvez pas la bonne information.",
                  "Vous essayez d’adapter votre manière de rechercher à la situation. Cela signifie généralement que vous pouvez trouver rapidement la bonne information. Déterminez si certains types d’informations sont plus difficiles à trouver. Pourriez-vous améliorer cela en utilisant, par exemple, des filtres ou des termes de recherche plus spécifiques ?",
                  "Vous adaptez généralement votre méthode de recherche aux informations que vous recherchez. Cela permet généralement de trouver rapidement la bonne information. Utilisez ces connaissances pour rationaliser encore plus votre processus de recherche.",
                  "Pendant votre recherche, ajustez votre méthode de recherche pour obtenir les meilleurs résultats. Cela vous permet souvent de trouver facilement et rapidement les informations que vous recherchez."
                ]
              }
            }
          },
          "intelligence": {
            "closest_group": "Votre score correspond le mieux au groupe de référence :",
            "description": "Dans ce graphique, vous pouvez voir votre score par rapport à la population active néerlandaise. Nous pouvons dire avec 80 % de certitude que votre score se situe entre les lignes pointillées.",
            "groups": {
              "hbo_ba": "HBO/Licence",
              "mbo": "MBO",
              "mbo1": "MBO1",
              "mbo2": "MBO2",
              "mbo3": "MBO3",
              "mbo4": "MBO4",
              "vmbo": "VMB",
              "wo_ma": "Adjudant/Maître"
            },
            "header": "Niveau travail et réflexion",
            "keys": {
              "abstract": "Abstrait",
              "numeriek": "Numérique",
              "totaalscore": "Score total",
              "verbaal": "Verbal"
            },
            "labels": {
              "average": "Moyenne",
              "high": "Au dessus de la moyenne",
              "low": "Sous la moyenne"
            },
            "subtitles": [
              "Par rapport à un groupe de référence %{group}, votre score total est bien inférieur à la moyenne",
              "Par rapport à un groupe de référence %{group}, votre score total est inférieur à la moyenne",
              "Par rapport à un groupe de référence %{group}, votre score total se situe autour de la moyenne",
              "Par rapport à un groupe de référence %{group}, votre score total est supérieur à la moyenne",
              "Par rapport à un groupe de référence %{group}, votre score total est bien supérieur à la moyenne"
            ],
            "title": "Groupe de référence %{group}"
          },
          "interests": {
            "sectoren": {
              "header": "Top 5 des secteurs d’intérêt",
              "keys": {
                "administratie": "Administration",
                "advieseninformatie": "Conseils et informations",
                "agrarischeondersteuning": "Soutien agricole",
                "beheer": "Gestion",
                "bouw": "Construire",
                "commercieledienstverlening": "Service commercial",
                "communicatie": "Communication",
                "cultuur": "Culture",
                "delfstofwinning": "Exploitation minière",
                "dieren": "Animaux",
                "elektrotechniek": "ingénierie électrique",
                "gezondheidszorg": "soins de santé",
                "glasaardewerkkeramiek": "Verre, poterie et céramique",
                "grafischetechniek": "Technologie graphique",
                "handel": "Commerce",
                "horeca": "l'industrie de la restauration",
                "houtmeubileringstechniek": "Technologie de l'ameublement en bois",
                "huishouding": "Entretien ménager",
                "industrielereiniging": "Nettoyage industriel",
                "installatietechniek": "Technologie d'installation",
                "magazijnopslagbezorging": "Entrepôt, stockage et livraison",
                "media": "Médias",
                "metaal": "Métal",
                "milieu": "Environnement",
                "onderwijs": "Éducation",
                "ontwerpenontwikkeling": "Design et développement",
                "openbaarbestuur": "Administration publique",
                "organisatieenadvies": "Organisation et conseils",
                "personeelarbeidloopbaan": "Personnel, travail et carrière",
                "persoonlijkeverzorging": "Soins personnels",
                "planningproductiebegeleiding": "Supervision de la planification et de la production",
                "planten": "Plantes",
                "procestechniek": "Génie des procédés",
                "rechtspraak": "Juridiction",
                "recreatie": "des loisirs",
                "schoonmaak": "Nettoyage",
                "sportontspanning": "Sports et loisirs",
                "textielmode": "Textile et mode",
                "toerisme": "Tourisme",
                "veiligheid": "Sécurité",
                "vervoer": "Transport",
                "visserij": "Pêche",
                "welzijn": "Bien-être",
                "wetenschap": "Science"
              }
            },
            "taken": {
              "header": "Top 5 des intérêts pour les tâches",
              "keys": {
                "administratievetaken": "Tâches administratives",
                "analyseren": "Analyser",
                "assisteren": "Assister",
                "conflictenoplossen": "Résoudre les conflits",
                "contactonderhouden": "Maintenir le contact",
                "creatiefzijn": "être créatif",
                "cursussentrainingengeven": "Assurer des cours et des formations",
                "elektronischeapparatierepareren": "Réparer des appareils électroniques",
                "fysiekinspannen": "L'effort physique",
                "grotemachinesbedienen": "Utiliser de grandes machines",
                "informatieverzamelen": "Obtenir des informations",
                "inroosteren": "Planification",
                "klantenhelpen": "Aider les clients",
                "leidinggeven": "Mener",
                "lesgeven": "Enseignement",
                "lezen": "Lire",
                "machinesrepareren": "Réparation de machines",
                "mensenadviseren": "Conseiller les gens",
                "mensenbegeleiden": "Pour guider les gens",
                "mensenbeinvloeden": "Influencer les gens",
                "mensenovertuigen": "Convaincre les gens",
                "metcollegasoverleggen": "Consulter des collègues",
                "metcomputerswerken": "Travailler avec des ordinateurs",
                "metelektronischeapparatenwerken": "Travailler avec des appareils électroniques",
                "methandenwerken": "Travailler avec les mains",
                "metmachineswerken": "Travailler avec des machines",
                "middelenvoorwerpenbeheren": "Gérer les ressources et les éléments",
                "motiveren": "Motiver",
                "onderhandelen": "Négocier",
                "organiseren": "Organiser",
                "projectenopzetten": "Mise en place de projets",
                "rekenen": "Calculer",
                "schrijven": "Pour écrire",
                "strategischdenken": "Réflexion stratégique",
                "teplannen": "Planifier",
                "voertuigenbesturen": "Conduire des véhicules",
                "werkzaamhedenplannen": "Planifier le travail"
              }
            }
          },
          "it": {
            "descriptions": {
              "gemiddeld": "Vos compétences de base en TIC sont suffisantes, mais vous avez encore de la marge pour apprendre.",
              "hoog": "Vos compétences de base en TIC sont excellentes.",
              "laag": "Vos compétences de base en TIC pourraient nécessiter une mise à jour."
            },
            "header": "Compétences du 21ème siècle",
            "title": "Compétences de base en TIC : gérer la technologie"
          },
          "media": {
            "categories": [
              "Tu es le meilleur dans ce domaine",
              "Tu es très doué pour ça",
              "Tu es aussi bon que la plupart dans ce domaine",
              "Vous pouvez encore vous améliorer",
              "Tu as encore beaucoup à apprendre",
              "C'est ainsi que vous gérez les médias sociaux"
            ],
            "header": "Compétences du 21ème siècle",
            "skills": {
              "consumptie": {
                "description": "Être critique quant aux informations que vous partagez et consommez",
                "label": "Consommation",
                "tips": [
                  "Vous n’êtes pas critique quant aux informations que vous partagez. Essayez d’être plus attentif à ce que l’on peut trouver sur vous en ligne et aux messages que vous diffusez.",
                  "Vous n’êtes pas toujours critique à l’égard des informations que vous partagez. Essayez d’être plus attentif à ce que l’on peut trouver sur vous en ligne et aux messages que vous diffusez.",
                  "Vous réfléchissez généralement avant de partager des informations et vous vous assurez que rien d’étrange ne puisse être trouvé sur vous en ligne.",
                  "Vous êtes généralement critique à l’égard des informations que vous partagez et de ce que l’on peut trouver sur vous en ligne.",
                  "Vous êtes critique quant aux informations que vous partagez et à ce que l’on peut trouver sur vous en ligne."
                ]
              },
              "maatschappelijk": {
                "description": "L'influence sociale des médias",
                "label": "Sociale",
                "tips": [
                  "Vous avez du mal à estimer l’influence sociale des médias. On ne réalise pas toujours à quel point les médias peuvent influencer ou induire les gens en erreur.",
                  "Vous avez une connaissance modérée de l’influence sociale des médias. On ne comprend pas toujours pleinement dans quelle mesure les médias peuvent être utilisés pour influencer, voire induire les gens en erreur.",
                  "Vous avez une compréhension moyenne de l’influence sociale des médias. Vous voyez généralement comment les gens utilisent les médias et peuvent être induits en erreur par ceux-ci. On ne peut pas encore reconnaître cette influence des médias dans toutes les situations.",
                  "Vous avez une excellente connaissance de l’influence sociale des médias. Vous voyez généralement comment les gens utilisent les médias et comment ils peuvent être induits en erreur.",
                  "Vous avez une bonne idée de l’influence sociale des médias. Vous voyez comment les gens utilisent les médias et comment ils peuvent être induits en erreur."
                ]
              },
              "mediawijsheid": {
                "label": "Éducation aux médias : gérer les médias",
                "tips": [
                  "Les réseaux sociaux sont devenus indispensables. Les réseaux sociaux jouent un rôle non seulement dans la vie privée, mais aussi sur le lieu de travail. Comment gérez-vous les réseaux sociaux en tant que salarié ? Obtenez un aperçu et des outils pour utiliser les médias sociaux en tant que fonctionnaire grâce à l'atelier <a href=\"https://www.ubrijk.nl/service/vocaten-en-adviseurs-laborsrecht/masterclasses-en-trainingen/social-media -et-fonctionnaires\" target=\"_blank\">Médias sociaux et fonctionnaires</a>.",
                  "Les réseaux sociaux sont devenus indispensables. Les réseaux sociaux jouent un rôle non seulement dans la vie privée, mais aussi sur le lieu de travail. Comment gérez-vous les réseaux sociaux en tant que salarié ? Obtenez un aperçu et des outils pour utiliser les médias sociaux en tant que fonctionnaire grâce à l'atelier <a href=\"https://www.ubrijk.nl/service/vocaten-en-adviseurs-laborsrecht/masterclasses-en-trainingen/social-media -et-fonctionnaires\" target=\"_blank\">Médias sociaux et fonctionnaires</a>.",
                  "Vous êtes conscient des forces et des faiblesses des médias modernes, mais des améliorations sont encore possibles. L’éducation aux médias ne consiste pas à savoir si vous utilisez beaucoup les médias. L’éducation aux médias consiste souvent à peser consciemment le pour et le contre. Donnez-vous de l’espace pour (re)considérer vos choix. Êtes-vous peut-être trop critique et prudent et n’utilisez-vous donc pas suffisamment les pouvoirs des médias modernes ? Ou n’êtes-vous pas suffisamment conscient des risques et vous rendez-vous donc vulnérable ?",
                  "Profitez de votre attitude active et pointue envers l’éducation aux médias. Restez informé des derniers développements technologiques et abonnez-vous au <a href=\"https://ibestuur.nl/nieuwsbrief/\" target=\"_blank\">magazine iBestuur</a>.",
                  "Profitez de votre attitude active et pointue envers l’éducation aux médias. Restez informé des derniers développements technologiques et abonnez-vous au <a href=\"https://ibestuur.nl/nieuwsbrief/\" target=\"_blank\">magazine iBestuur</a>."
                ]
              },
              "schuwheid": {
                "descriptions": [
                  "Vous utilisez peu les réseaux sociaux par rapport aux autres.",
                  "Vous utilisez les réseaux sociaux moins que la moyenne.",
                  "Vous utilisez les réseaux sociaux autant que les autres.",
                  "Par rapport aux autres, vous utilisez davantage les réseaux sociaux.",
                  "Vous utilisez beaucoup les réseaux sociaux par rapport aux autres."
                ],
                "label": "La timidité",
                "tips": [
                  "Vous utilisez peu ou pas les médias sociaux/numériques. De nombreuses personnes utilisent les réseaux sociaux pour rester informés ou entretenir des contacts. Se pourrait-il qu’il vous manque des informations ou des opportunités parce que vous n’êtes pas actif en ligne ? Ou voyez-vous comme un avantage le fait que vous décidiez vous-même des informations que vous recevez ?",
                  "Vous utilisez relativement peu les médias sociaux/numériques. De nombreuses personnes utilisent les réseaux sociaux pour rester informés ou entretenir des contacts. Se pourrait-il qu’il vous manque des informations ou des opportunités parce que vous n’êtes pas actif en ligne ? Ou voyez-vous comme un avantage le fait que vous décidiez vous-même des informations que vous recevez ?",
                  "Vous utilisez régulièrement les réseaux sociaux/numériques. Cela peut vous aider à rester informé et à maintenir des contacts. Mais ce type de média peut aussi donner une image déformée de la réalité.",
                  "Vous utilisez les médias sociaux/numériques au-dessus de la moyenne. Cela vous tient probablement informé de ce qui se passe (en ligne) et vous aide à maintenir des contacts. Mais sachez que ce type de média peut donner une image déformée de la réalité.",
                  "Vous utilisez relativement souvent les médias sociaux/numériques. Cela signifie probablement que vous êtes bien informé de ce qui se passe (en ligne). Cela permet également de maintenir vos contacts. Mais sachez que ce type de média peut donner une image déformée de la réalité."
                ]
              },
              "trend": {
                "description": "Soyez à l'affût des tendances",
                "label": "S'orienter",
                "tips": [
                  "Vous n'avez pas un sens aigu des tendances. Vous ne vous souciez pas de ce qui est populaire ou non.",
                  "Vous n’avez pas vraiment l’œil pour les tendances. Vous ne vous souciez pas beaucoup de ce qui est populaire ou non.",
                  "Vous essayez de rester au courant des tendances. Vous remarquez des choses qui sont très populaires ou qui deviennent obsolètes. Mais vous n’êtes pas non plus quelqu’un qui suit immédiatement toutes les tendances.",
                  "Vous restez informé des tendances. Vous réalisez généralement ce qui est populaire et le considérez comme des choses qui deviennent obsolètes.",
                  "Vous restez bien informé des tendances. Vous avez un aperçu de ce qui est populaire et remarquez quand les choses deviennent obsolètes."
                ]
              }
            }
          },
          "no_data": "(aucun résultat disponible pour l'instant)",
          "no_relevant_data": "(il n'y a pas encore de résultats disponibles pour lesquels des widgets existent)",
          "personality": {
            "descriptions": {
              "competitie": "Vouloir être meilleur que les autres.",
              "conformisme": "Être capable de s’adapter aux normes et valeurs applicables. Respectez l’autorité.",
              "contactbehoefte": "Besoin de compagnie.",
              "dominantie": "Prendre l'initiative. Jouer un rôle déterminant dans la collaboration.",
              "energie": "Avoir l'énergie pour faire beaucoup de choses.",
              "frustratietolerantie": "Soyez tolérant envers les autres.",
              "hartelijkheid": "Être gentil, joyeux et joyeux envers les autres.",
              "incasseringsvermogen": "Être capable de gérer adéquatement les critiques et les revers.",
              "nauwkeurigheid": "Travailler avec soin, avec le souci du détail.",
              "onafhankelijkheid": "Vouloir faire les choses à sa manière.",
              "ordelijkheid": "Créez vous-même une structure.",
              "originaliteit": "Proposer de nouvelles solutions. Être créatif.",
              "positivisme": "Ayez une vision positive de la vie.",
              "regelmaat": "Avoir un besoin d'ordre et de règles.",
              "sociaal_ontspannen": "Se sentir détendu au contact des autres.",
              "status": "Vouloir obtenir le meilleur possible. Vouloir aller plus haut.",
              "vernieuwing": "Aime le changement et acquérir de nouvelles expériences.",
              "vertrouwen": "Croire aux bonnes intentions des autres.",
              "volharding": "Montrer de l'enthousiasme. Prendre les tâches et les accords au sérieux et les respecter.",
              "weloverwogen": "Réfléchissez bien avant d'agir.",
              "zelfonthulling": "Vouloir partager vos propres sentiments avec les autres.",
              "zelfontwikkeling": "Poussé à utiliser pleinement ses propres qualités.",
              "zelfvertoon": "J’aime être le centre d’attention.",
              "zelfvertrouwen": "Soyez confiant.",
              "zorgzaamheid": "Se sentir impliqué avec les autres. J'aimerais aider les autres."
            },
            "header": "Personnalité",
            "keys": {
              "competitie": "Concours",
              "conformisme": "Conformisme",
              "contactbehoefte": "Besoin de contact",
              "dominantie": "Dominance",
              "energie": "Énergie",
              "frustratietolerantie": "Tolérance à la frustration",
              "hartelijkheid": "Cordialité",
              "incasseringsvermogen": "Capacité de collecte",
              "nauwkeurigheid": "Précision",
              "onafhankelijkheid": "Indépendance",
              "ordelijkheid": "Ordre",
              "originaliteit": "Originalité",
              "positivisme": "Positivisme",
              "regelmaat": "Régularité",
              "sociaal_ontspannen": "Socialement relaxant",
              "status": "Statut",
              "vernieuwing": "Besoin de variété",
              "vertrouwen": "Faire confiance",
              "volharding": "Persévérance",
              "weloverwogen": "Considéré",
              "zelfonthulling": "Divulgation de soi",
              "zelfontwikkeling": "Développement personnel",
              "zelfvertoon": "Auto-affichage",
              "zelfvertrouwen": "Confiance en soi",
              "zorgzaamheid": "Soin"
            },
            "titles": [
              "Vos qualités les plus caractéristiques",
              "Vos traits caractéristiques",
              "Vos qualités les moins caractéristiques"
            ]
          },
          "thinking": {
            "average": {
              "benedengemiddeld": "Sous la moyenne",
              "bovengemiddeld": "Au dessus de la moyenne",
              "gemiddeld": "Moyenne",
              "hoog": "Haut",
              "laag": "Bas"
            },
            "header": "Compétences du 21ème siècle",
            "skills": {
              "communiceren": {
                "descriptions": {
                  "benedengemiddeld": "Au 21e siècle, la communication se fait de plus en plus par écrit, par exemple via des applications de messagerie ou de courrier électronique. Pour une bonne communication, il est important que votre message soit bien structuré et puisse être suivi par d’autres. Vous devez également tenir compte du destinataire de votre message. Par rapport aux autres fonctionnaires, communiquer est naturellement moins facile pour vous.",
                  "bovengemiddeld": "Au 21e siècle, la communication se fait de plus en plus par écrit, par exemple via des applications de messagerie ou de courrier électronique. Pour une bonne communication, il est important que votre message soit bien structuré et puisse être suivi par d’autres. Vous devez également tenir compte du destinataire de votre message. Par rapport aux autres employés du gouvernement, communiquer est naturellement relativement facile pour vous.",
                  "gemiddeld": "Au 21e siècle, la communication se fait de plus en plus par écrit, par exemple via des applications de messagerie ou de courrier électronique. Pour une bonne communication, il est important que votre message soit bien structuré et puisse être suivi par d’autres. Vous devez également tenir compte du destinataire de votre message. Vous avez une aptitude à communiquer moyenne par rapport au groupe de référence des fonctionnaires.",
                  "hoog": "Au 21e siècle, la communication se fait de plus en plus par écrit, par exemple via des applications de messagerie ou de courrier électronique. Pour une bonne communication, il est important que votre message soit bien structuré et puisse être suivi par d’autres. Vous devez également tenir compte du destinataire de votre message. Vous possédez naturellement une forte aptitude à communiquer.",
                  "laag": "Au 21e siècle, la communication se fait de plus en plus par écrit, par exemple via des applications de messagerie ou de courrier électronique. Pour une bonne communication, il est important que votre message soit bien structuré et puisse être suivi par d’autres. Vous devez également tenir compte du destinataire de votre message. Par rapport aux autres employés du gouvernement, vous avez moins d’aptitudes à la communication."
                },
                "label": "Communiquer"
              },
              "creatief_denken": {
                "descriptions": {
                  "benedengemiddeld": "Par rapport aux autres employés du gouvernement, vous êtes naturellement moins susceptible de proposer de nouvelles idées et applications. Les créatifs ont des idées originales. Ils ont souvent moins besoin de structure et de sécurité. La pensée créative vous aide à voir de nouvelles possibilités et à vous développer davantage.",
                  "bovengemiddeld": "Comparé aux autres employés du gouvernement, il vous est naturellement plus facile de proposer de nouvelles idées et applications. Les créatifs ont des idées originales. Ils ont souvent moins besoin de structure et de sécurité. La pensée créative vous aide à voir de nouvelles possibilités et à vous développer davantage. Recherchez consciemment les situations dans lesquelles vous pouvez appliquer et maintenir cette compétence.",
                  "gemiddeld": "Par rapport aux autres fonctionnaires, vous avez une aptitude moyenne à proposer de nouvelles idées et applications. Les créatifs ont des idées originales. Ils ont souvent moins besoin de structure et de sécurité. La pensée créative vous aide à voir de nouvelles possibilités et à vous développer davantage. Il y a de la place pour évoluer dans ce domaine.",
                  "hoog": "Comparé à d’autres employés du gouvernement, il vous est naturellement facile de proposer de nouvelles idées et applications. Les créatifs ont des idées originales. Ils ont souvent moins besoin de structure et de sécurité. La pensée créative vous aide à voir de nouvelles possibilités et à vous développer davantage. Recherchez consciemment les situations dans lesquelles vous pouvez appliquer et maintenir cette compétence.",
                  "laag": "Par rapport aux autres fonctionnaires, vous avez naturellement plus de difficulté à proposer de nouvelles idées et applications. Les créatifs ont des idées originales. Ils ont souvent moins besoin de structure et de sécurité. La pensée créative vous aide à voir de nouvelles possibilités et à vous développer davantage."
                },
                "label": "La pensée créative"
              },
              "kritisch_denken": {
                "descriptions": {
                  "benedengemiddeld": "Les penseurs critiques sont indépendants et aiment aller au fond des choses. La pensée critique vous permet de rester alerte et garantit que vous ne vous fiez pas simplement à ce que la technologie nous dit. Votre capacité naturelle à aborder un problème de manière critique et approfondie est inférieure à la moyenne par rapport aux autres employés du gouvernement.",
                  "bovengemiddeld": "Les penseurs critiques sont indépendants et aiment aller au fond des choses. Votre capacité naturelle à aborder un problème de manière critique et approfondie est supérieure à la moyenne. La pensée critique vous permet de rester alerte et garantit que vous ne vous fiez pas simplement à ce que la technologie nous dit.",
                  "gemiddeld": "Les penseurs critiques sont indépendants et aiment aller au fond des choses. La pensée critique vous permet de rester alerte et garantit que vous ne vous fiez pas simplement à ce que la technologie nous dit. Votre capacité naturelle à aborder un problème de manière critique et approfondie est comparable à celle d’un employé gouvernemental moyen.",
                  "hoog": "Les penseurs critiques sont indépendants et aiment aller au fond des choses. Votre capacité naturelle à aborder un problème de manière critique et approfondie est forte. La pensée critique vous permet de rester alerte et garantit que vous ne vous fiez pas simplement à ce que la technologie nous dit.",
                  "laag": "Les penseurs critiques sont indépendants et aiment aller au fond des choses. La pensée critique vous permet de rester alerte et garantit que vous ne vous fiez pas simplement à ce que la technologie nous dit. Votre capacité naturelle à aborder un problème de manière critique et approfondie est moins forte que celle des autres employés du gouvernement."
                },
                "label": "Esprit critique"
              },
              "probleem_oplossen": {
                "descriptions": {
                  "benedengemiddeld": "Les personnes possédant de solides compétences en résolution de problèmes peuvent être battues et n’abandonnent pas facilement. Ils persévèrent et font facilement adhérer les autres à leurs idées. De ce fait, ils savent également résoudre des problèmes complexes ou des conflits de longue durée. Par rapport aux autres employés du gouvernement, votre aptitude à résoudre des problèmes est inférieure à la moyenne.",
                  "bovengemiddeld": "Les personnes possédant de solides compétences en résolution de problèmes peuvent être battues et n’abandonnent pas facilement. Ils persévèrent et font facilement adhérer les autres à leurs idées. De ce fait, ils savent également résoudre des problèmes complexes ou des conflits de longue durée. Votre aptitude à résoudre des problèmes est supérieure à la moyenne.",
                  "gemiddeld": "Les personnes possédant de solides compétences en résolution de problèmes peuvent être battues et n’abandonnent pas facilement. Ils persévèrent et font facilement adhérer les autres à leurs idées. De ce fait, ils savent également résoudre des problèmes complexes ou des conflits de longue durée. Votre aptitude à résoudre des problèmes est comparable à celle d’un employé gouvernemental moyen.",
                  "hoog": "Les personnes possédant de solides compétences en résolution de problèmes peuvent être battues et n’abandonnent pas facilement. Ils persévèrent et font facilement adhérer les autres à leurs idées. De ce fait, ils savent également résoudre avec succès des problèmes complexes ou des conflits de longue durée. Par rapport à l’employé gouvernemental moyen, vous avez une forte aptitude à résoudre des problèmes.",
                  "laag": "Les personnes possédant de solides compétences en résolution de problèmes peuvent être battues et n’abandonnent pas facilement. Ils persévèrent et font facilement adhérer les autres à leurs idées. De ce fait, ils savent également résoudre des problèmes complexes ou des conflits de longue durée. Par rapport à l’employé gouvernemental moyen, vous avez une aptitude moins forte à résoudre des problèmes."
                },
                "label": "Résoudre les problèmes"
              },
              "samenwerken": {
                "descriptions": {
                  "benedengemiddeld": "Vous avez naturellement une moindre aptitude à la collaboration que l’employé gouvernemental moyen. Pour bien travailler ensemble, il faut oser faire confiance aux autres et bien communiquer entre eux. Il faut aussi prendre ses responsabilités.",
                  "bovengemiddeld": "Vous avez naturellement un talent pour la collaboration supérieur à la moyenne. L’habileté de collaborer consiste à prendre des responsabilités, à oser faire confiance aux autres et à communiquer entre eux.",
                  "gemiddeld": "Vous avez naturellement une aptitude à la collaboration moyenne par rapport aux autres fonctionnaires. Pour bien travailler ensemble, il faut oser faire confiance aux autres et bien communiquer entre eux. Il faut aussi prendre ses responsabilités.",
                  "hoog": "Vous possédez naturellement une forte aptitude à la collaboration. L’habileté de collaborer consiste à prendre des responsabilités, à oser faire confiance aux autres et à communiquer entre eux.",
                  "laag": "Vous avez naturellement moins d’aptitude à collaborer que l’employé gouvernemental moyen. Pour bien travailler ensemble, il faut oser faire confiance aux autres et bien communiquer entre eux. Il faut aussi prendre ses responsabilités."
                },
                "label": "Collaborer"
              },
              "sociale_culturele_vaardigheden": {
                "descriptions": {
                  "benedengemiddeld": "Par rapport au groupe de référence, vous avez naturellement plus de difficulté à prendre en compte le parcours d'une personne. Afin de bien interagir avec des personnes d’horizons différents, il est important que vous soyez ouvert à différents points de vue et idées. Vous avez également besoin de bonnes antennes sociales et vous devez être ouvert à l’auto-amélioration.",
                  "bovengemiddeld": "Par rapport au groupe de référence, vous tenez naturellement facilement compte du parcours d’une personne. Afin de bien interagir avec des personnes d’horizons différents, il est important que vous soyez ouvert à différents points de vue et idées. Vous avez également besoin de bonnes antennes sociales et vous êtes ouvert à l’amélioration personnelle.",
                  "gemiddeld": "Par rapport au groupe de référence, vous faites à peu près aussi bien que la moyenne en matière de prise en compte de l'origine d'une personne. Afin de bien interagir avec des personnes d’horizons différents, il est important que vous soyez ouvert à différents points de vue et idées. Vous avez également besoin de bonnes antennes sociales et vous devez être ouvert à l’auto-amélioration.",
                  "hoog": "Par rapport au groupe de référence, vous tenez naturellement facilement compte du parcours d'une personne. Afin de bien interagir avec des personnes d’horizons différents, il est important que vous soyez ouvert à différents points de vue et idées. Vous avez également besoin de bonnes antennes sociales et vous êtes ouvert à l’amélioration personnelle.",
                  "laag": "Par rapport au groupe de référence, vous avez naturellement du mal à prendre en compte le parcours d'une personne. Afin de bien interagir avec des personnes d’horizons différents, il est important que vous soyez ouvert à différents points de vue et idées. Vous avez également besoin de bonnes antennes sociales et vous devez être ouvert à l’auto-amélioration."
                },
                "label": "Compétences sociales et culturelles"
              },
              "zelfregulering": {
                "descriptions": {
                  "benedengemiddeld": "Dans le travail, aujourd'hui et certainement demain, il devient de plus en plus important qu'en tant qu'employé, vous fassiez plus que simplement suivre les ordres. C'est pourquoi il est important que vous preniez la responsabilité de planifier votre propre travail et de le terminer comme prévu. Par rapport aux autres fonctionnaires, vous avez naturellement un peu plus de difficulté à réguler votre propre travail.",
                  "bovengemiddeld": "Dans le travail, aujourd'hui et certainement demain, il devient de plus en plus important qu'en tant qu'employé, vous fassiez plus que simplement suivre les ordres. C'est pourquoi il est important que vous preniez la responsabilité de planifier votre propre travail et de le terminer comme prévu. Par rapport aux autres fonctionnaires, vous avez une aptitude supérieure à la moyenne à réglementer votre travail.",
                  "gemiddeld": "Dans le travail, aujourd'hui et certainement demain, il devient de plus en plus important qu'en tant qu'employé, vous fassiez plus que simplement suivre les ordres. C'est pourquoi il est important que vous preniez la responsabilité de planifier votre propre travail et de le terminer comme prévu. Vous avez une aptitude moyenne pour cela par rapport aux autres fonctionnaires.",
                  "hoog": "Dans le travail, aujourd'hui et certainement demain, il devient de plus en plus important qu'en tant qu'employé, vous fassiez plus que simplement suivre les ordres. C'est pourquoi il est important que vous preniez la responsabilité de planifier votre propre travail et de le terminer comme prévu. Vous avez un talent naturel pour réguler votre travail.",
                  "laag": "Dans le travail, aujourd'hui et certainement demain, il devient de plus en plus important qu'en tant qu'employé, vous fassiez plus que simplement suivre les ordres. C'est pourquoi il est important que vous preniez la responsabilité de planifier votre propre travail et de le terminer comme prévu. Réguler votre travail vous convient naturellement moins bien."
                },
                "label": "Autorégulation"
              }
            },
            "titles": {
              "thinking_styles": "Styles de pensée : comment collaborer avec la technologie ?",
              "work_competences": "Compétences professionnelles : Comment effectuer mon travail ?"
            }
          },
          "title": "Aperçu des résultats personnels",
          "values": {
            "descriptions": {
              "demotivators": {
                "aandacht": "Vous préférez rester en retrait. Si vous deviez vous profiler, vous seriez déçu.",
                "analyseren": "L’analyse des problèmes vous semble démotivante, quelque chose que vous préféreriez ne pas faire.",
                "autonomie": "Vous préférez ne pas rechercher l’autonomie et l’indépendance. Vous préférez vous coordonner avec les autres et laisser les autres prendre des décisions à votre place.",
                "beinvloeden": "Vous n'aimez pas devoir influencer les autres.",
                "carriere": "Vous pouvez avoir une aversion pour le statut et un besoin de réussite. Si on vous demande de vous concentrer là-dessus, cela aura probablement un effet démotivant.",
                "concrete_resultaten": "Devoir obtenir des résultats concrets vous démotivera plutôt que vous stimulera. Vous pouvez être axé sur les processus plutôt que sur les résultats.",
                "creatief_denken": "Lorsque votre créativité est sollicitée, elle vous démotivera probablement plutôt que vous stimulera.",
                "financiele_beloning": "Les objectifs financiers peuvent vous démotiver. Vous n’êtes pas intéressé par l’aspect financier des choses, donc si vous vous orientez vers cela, cela peut vous démotiver.",
                "fysiek_actief_zijn": "Vous préférez ne pas être physiquement actif au travail. Si cela demande trop d’effort physique, vous risquez d’en faire l’expérience négative.",
                "hectiek": "Vous préférez un environnement calme, où vous savez ce qui vous attend et où vous pouvez travailler tranquillement.",
                "hulp_verlenen": "Aider directement les autres vous-même vous démotive.",
                "kwaliteit": "Vous êtes probablement plus préoccupé par la situation dans son ensemble que par les détails. Des exigences de qualité élevées peuvent vous gêner.",
                "ondernemen": "Vous êtes heureux de laisser les initiatives aux autres. Il ne faut pas s'attendre à cela de votre part ; cela vous démotiverait en fait.",
                "ontwikkelen": "Vous n'êtes pas obligé de vous développer. Vous aimez aussi moins faire ça. Peut-être pensez-vous que vous pouvez très bien vous en sortir avec vos qualités actuelles.",
                "samenwerking": "Vous préférez travailler seul plutôt qu'à plusieurs. Devoir travailler ensemble et surtout se coordonner avec les autres vous frustre plutôt que d'en profiter.",
                "taakuitdaging": "Les défis nouveaux ou difficiles vous démotivent. Vous préférez avoir la certitude de pouvoir gérer vos tâches.",
                "waardering_en_erkenning": "Vous n’êtes pas sensible à l’appréciation et à la reconnaissance, cela vous déçoit plutôt.",
                "zekerheid_en_stabiliteit": "La sécurité et la stabilité vous démotivent. Vous préférez quand vous ne savez pas exactement où vous en êtes et quand vous êtes surpris.",
                "zinvolle_bijdrage": "Vous n'êtes pas un idéaliste. En fait, cela vous démotive probablement lorsque vous devez travailler pour une organisation à vocation sociale."
              },
              "neutral_motivators": {
                "aandacht": "Vous ne cherchez pas toujours des opportunités pour vous présenter. Au contraire, vous êtes d'accord avec le fait de ne pas avoir tous les yeux rivés sur vous.",
                "analyseren": "L’analyse d’informations ou de problèmes ne vous semble pas motivante, mais vous ne détestez pas cela non plus.",
                "autonomie": "L'indépendance et l'autonomie dans votre travail ne vous motivent pas beaucoup. Vous n’avez aucun problème à rendre des comptes aux autres.",
                "beinvloeden": "Vous trouvez moins important d’avoir de l’influence. Vous pouvez également vous laisser guider par d’autres.",
                "carriere": "Le statut et la poursuite d’une carrière ne vous stimulent que dans une mesure limitée. Vous n’êtes pas nécessairement concentré sur l’obtention d’une position honorable.",
                "concrete_resultaten": "Vous n’êtes pas nécessairement obligé de travailler vers des résultats clairs ou concrets. Vous pouvez également être davantage orienté vers les processus.",
                "creatief_denken": "Vous n'êtes pas obligé d'être créatif ou de penser de manière créative dans votre travail.",
                "financiele_beloning": "Vous aimez peut-être les récompenses financières, mais vous ne les trouvez pas très importantes.",
                "fysiek_actief_zijn": "Vous êtes neutre vis-à-vis du mouvement et des activités physiques dans votre travail.",
                "hectiek": "Le dynamisme ne vous motive pas particulièrement. Vous voulez avoir le temps de terminer les choses et de vous concentrer sur votre travail.",
                "hulp_verlenen": "Apporter de l’aide aux autres ne vous motive pas forcément, vous ne la recherchez pas activement.",
                "kwaliteit": "Vous êtes moins motivé par des normes de qualité élevées. Cela ne vous met pas au défi.",
                "ondernemen": "Il n’est pas toujours nécessaire de disposer de l’espace nécessaire pour prendre des initiatives soi-même.",
                "ontwikkelen": "Cela ne vous motive pas beaucoup pour le moment à vous développer davantage. Vous souhaitez probablement mettre à profit les connaissances et les compétences que vous possédez actuellement.",
                "samenwerking": "Vous êtes neutre envers la coopération avec les autres. Vous pourriez travailler en équipe, mais vous pourriez aussi bien fonctionner seul. Vous n’êtes pas nécessairement obligé d’appartenir à quelque part ou de travailler en équipe.",
                "taakuitdaging": "Votre défi ne réside pas tant dans la difficulté de votre travail. Vous n’êtes pas non plus obligé d’être confronté à de nouveaux défis à chaque fois.",
                "waardering_en_erkenning": "L'appréciation et la reconnaissance sont agréables, mais elles vous motivent dans une mesure limitée. Vous êtes probablement plus concentré sur votre propre opinion sur vos actions que sur les commentaires des autres.",
                "zekerheid_en_stabiliteit": "La sécurité et la stabilité ne sont pas si importantes pour vous. Vous n'avez pas besoin de savoir exactement où vous en êtes.",
                "zinvolle_bijdrage": "Apporter une contribution significative à la société ne contribue pas spécifiquement à votre motivation. Vous ne pensez pas qu’il est important de signifier quelque chose aux autres avec votre travail."
              },
              "primary_motivators": {
                "aandacht": "Vous aimez être au premier plan et êtes motivé lorsque les yeux sont rivés sur vous. Vous pouvez en profiter lorsque les autres vous admirent. Vous aimerez un poste qui offre un certain statut. Un risque peut être que vous demandiez à tort de l’attention ou que vous n’offriez pas suffisamment d’espace aux autres.",
                "analyseren": "Vous trouvez motivant de faire des analyses, de réfléchir aux enjeux et aux problèmes. Vous aimez les casse-tête et la réflexion. Un risque peut être que vous continuiez à analyser trop longtemps et que vous restiez coincé dans un problème pendant trop longtemps.",
                "autonomie": "Vous trouvez motivant de pouvoir travailler de manière autonome et de déterminer vous-même la manière dont vous effectuez votre travail. La liberté et l'indépendance vous stimulent beaucoup. Lorsque les autres vous tiennent court ou vous dirigent, cela peut entraîner une irritation. Un risque peut être que les autres vous trouvent trop indépendant et éventuellement têtu. Une bonne intégration dans une organisation est importante pour vous. Travailler en toute autonomie peut être une option pour vous.",
                "beinvloeden": "Vous trouvez motivant d’influencer les autres. Vous aimeriez voir votre opinion reflétée dans les décisions et dans la manière dont les choses se déroulent. Convaincre et motiver les autres vous attire. Un risque peut être que vous soyez moins sensible aux opinions des autres ou que vous en fassiez trop.",
                "carriere": "Vous trouvez important de réaliser quelque chose dans votre travail, de progresser. Le statut et le prestige jouent un rôle à cet égard. Vous pouvez également être compétitif et vous mesurer aux autres. Un risque peut être que vous soyez trop tourné vers l'extérieur, veillez à ne pas vous dépasser et à être aussi intrinsèquement motivé.",
                "concrete_resultaten": "Vous aimez travailler vers un résultat clair et concret. Vous trouvez un résultat final visible dans lequel votre contribution directe peut être reconnue comme stimulante. Un risque peut être que vous vous relâchiez lorsque des résultats concrets ne sont pas obtenus régulièrement.",
                "creatief_denken": "Vous souhaitez utiliser votre créativité, générer des idées et sortir des sentiers battus. Vous recherchez souvent de nouvelles solutions. Un risque peut être que vous ayez trop d’idées pour toutes les mettre en œuvre, ou que toutes les idées ne soient pas également pratiques.",
                "financiele_beloning": "Vous êtes motivé par l’obtention d’une bonne récompense financière. Les incitations financières, telles que les objectifs et la rémunération variable, vous encouragent davantage. Cela s’applique également à l’obtention de résultats financiers pour vos clients ou employeurs. Un risque peut être qu'en raison d'incitations financières, vous entrepreniez quelque chose que vous n'auriez pas dû faire, par exemple parce que cela vous procure moins de satisfaction dans d'autres aspects.",
                "fysiek_actief_zijn": "Vous aimez être physiquement actif. Vous considérez l’exercice, le sport ou les activités physiques comme stimulants. Cela peut signifier que vous aimez faire un travail physique. Mais comme cela n’est pas toujours possible, il vous faudra au moins un débouché dans le privé, dans le sport par exemple. Un risque peut être que vous soyez trop agité pour un vrai travail de bureau.",
                "hectiek": "Vous aimez la dynamique et l'agitation autour de vous : un environnement mouvementé vous stimule. Vous aimez aussi être occupé vous-même. Lorsque le temps de travail est trop calme, vous pouvez vous détendre et éventuellement moins vous concentrer.",
                "hulp_verlenen": "Lorsque vous pouvez rendre service aux autres ou aider dans des activités concrètes, cela vous procure beaucoup de satisfaction. Vous serez également fortement porté sur le bien-être des autres. Un risque de cette valeur est que vous alliez trop loin et que vous surveilliez trop peu vos limites.",
                "kwaliteit": "Vous considérez qu’il est stimulant de fournir une qualité élevée. Le bien n’est pas toujours suffisant pour vous, vous voulez répondre à des normes élevées. Un risque peut être que nous n'ayons pas toujours le temps pour cela ou que d'autres ne répondent pas dans la même mesure à vos exigences de qualité.",
                "ondernemen": "Vous aimeriez être entreprenant. Vous aimez prendre des initiatives et saisir les opportunités. Cela est possible en tant qu'entrepreneur indépendant, mais aussi au sein d'une organisation qui offre un espace pour l'entrepreneuriat. Un inconvénient de cette motivation peut être que vous êtes trop optimiste et que vous prenez trop de risques.",
                "ontwikkelen": "Vous attachez une grande importance à vous développer et à acquérir de nouvelles connaissances et compétences. Pour vous, rester immobile signifie reculer. Vous aimerez souvent aussi aider les autres à se développer davantage. Un risque peut être que vous vous ennuyiez (trop) vite et que vous souhaitiez apprendre quelque chose de nouveau.",
                "samenwerking": "La collaboration avec les autres est très importante pour vous. Vous aimez être en contact direct avec les autres et travailler ensemble sur une tâche ou un objectif commun. Un risque peut être que vous vous relâchiez lorsque vous travaillez de manière indépendante ou seul.",
                "taakuitdaging": "Vous êtes stimulé par les tâches stimulantes qui exigent beaucoup de vous. Vous voulez tirer le meilleur parti de vous-même. Un risque peut être que vous soyez prêt assez rapidement pour quelque chose de nouveau. Le défi peut aussi être trop grand, ce qui signifie que vous courez le risque que les choses ne se passent pas bien.",
                "waardering_en_erkenning": "L'appréciation et la reconnaissance sont très motivantes pour vous. Lorsque les autres vous complimentent et vous reconnaissent, cela vous stimule. Vous vous y engagez également. Un risque peut être que vous deveniez trop dépendant de l’appréciation. Continuez également à être satisfait du travail lui-même.",
                "zekerheid_en_stabiliteit": "Vous voulez la sécurité et la stabilité. Vous vous sentez bien quand vous savez où vous en êtes. Dans votre travail, vous aimez avoir un éventail de tâches clairement défini, afin de savoir ce que l'on attend de vous. Un risque peut être que vous soyez moins capable de faire face aux changements et aux incertitudes.",
                "zinvolle_bijdrage": "Vous aimez apporter une contribution significative à la société ou à des œuvres caritatives. Vous pensez qu’il est important de signifier quelque chose aux autres avec votre travail. Cela peut se refléter dans votre poste ou dans l'organisation pour laquelle vous travaillez. Un risque peut être que vous soyez trop idéaliste et donc moins réaliste ou que vous mettiez trop vos propres intérêts de côté."
              },
              "secondary_motivators": {
                "aandacht": "Vous aimez être au premier plan et êtes motivé lorsque les yeux sont rivés sur vous. Vous pouvez en profiter lorsque les autres vous admirent.",
                "analyseren": "Vous trouvez motivant de faire des analyses, de réfléchir aux enjeux et aux problèmes. Vous aimez les casse-tête et la réflexion.",
                "autonomie": "Vous trouvez motivant de pouvoir travailler de manière autonome. Vous trouvez motivant de pouvoir décider vous-même de la manière dont vous effectuez votre travail. La liberté et l'indépendance vous stimulent beaucoup.",
                "beinvloeden": "Vous trouvez motivant d’influencer les autres. Vous aimeriez voir votre opinion reflétée dans les décisions et dans la manière dont les choses se déroulent. Convaincre et motiver les autres vous attire.",
                "carriere": "Vous trouvez important de réaliser quelque chose dans votre travail, de progresser. Le statut et le prestige jouent un rôle à cet égard. Vous pouvez être quelque peu compétitif et vous mesurer aux autres.",
                "concrete_resultaten": "Vous aimez travailler vers un résultat clair et concret. Vous trouvez un résultat final visible dans lequel votre contribution directe peut être reconnue comme stimulante.",
                "creatief_denken": "Vous souhaitez utiliser votre créativité, générer des idées et sortir des sentiers battus. Vous recherchez souvent de nouvelles solutions.",
                "financiele_beloning": "Vous êtes motivé par l’obtention d’une bonne récompense financière. Les incitations financières, telles que les objectifs et la rémunération variable, vous encouragent davantage. Cela s’applique également à l’obtention de résultats financiers pour vos clients ou employeurs.",
                "fysiek_actief_zijn": "Vous aimez être physiquement actif. Vous considérez l’exercice, le sport ou les activités physiques comme stimulants. Cela peut signifier que vous aimez faire un travail physique. Mais comme cela n’est pas toujours possible, il vous faudra au moins un débouché dans le privé, dans le sport par exemple.",
                "hectiek": "Vous aimez la dynamique et l'agitation autour de vous : un environnement occupé vous stimule. Vous aimez aussi être occupé vous-même.",
                "hulp_verlenen": "Lorsque vous pouvez rendre service aux autres ou aider dans des activités concrètes, cela vous procure beaucoup de satisfaction. Vous serez également fortement porté sur le bien-être des autres.",
                "kwaliteit": "Vous considérez qu’il est stimulant de fournir une qualité élevée. Le bien n’est pas toujours suffisant pour vous, vous voulez répondre à des normes élevées.",
                "ondernemen": "Vous aimeriez être entreprenant. Vous aimez prendre des initiatives et saisir les opportunités. Cela est également possible au sein d’une organisation qui offre un espace à l’entrepreneuriat.",
                "ontwikkelen": "Vous attachez une grande importance à vous développer et à acquérir de nouvelles connaissances et compétences. Pour vous, rester immobile signifie reculer. Vous aimerez souvent aussi aider les autres à se développer davantage.",
                "samenwerking": "La collaboration avec les autres est très importante pour vous. Vous aimez être en contact direct avec les autres et travailler ensemble sur une tâche ou un objectif commun.",
                "taakuitdaging": "Vous êtes stimulé par les tâches stimulantes qui exigent beaucoup de vous. Vous voulez tirer le meilleur parti de vous-même.",
                "waardering_en_erkenning": "L'appréciation et la reconnaissance sont très motivantes pour vous. Lorsque les autres vous complimentent et vous reconnaissent, cela vous stimule.",
                "zekerheid_en_stabiliteit": "Vous voulez la sécurité et la stabilité. Vous vous sentez bien quand vous savez où vous en êtes. Dans votre travail, vous aimez avoir un éventail de tâches clairement défini, afin de savoir ce que l'on attend de vous.",
                "zinvolle_bijdrage": "Vous aimez apporter une contribution significative à la société ou à des œuvres caritatives. Vous pensez qu’il est important de signifier quelque chose aux autres avec votre travail. Cela peut se refléter dans votre poste ou dans l'organisation pour laquelle vous travaillez."
              }
            },
            "header": "Valeurs de carrière",
            "keys": {
              "aandacht": "Profilage",
              "analyseren": "Analyser",
              "autonomie": "Autonomie",
              "beinvloeden": "Influencer",
              "carriere": "Carrière",
              "concrete_resultaten": "Des résultats concrets",
              "creatief_denken": "La pensée créative",
              "financiele_beloning": "Récompense financière",
              "fysiek_actief_zijn": "Soyez physiquement actif",
              "hectiek": "Dynamique",
              "hulp_verlenen": "Fournir de l'aide",
              "kwaliteit": "Qualité",
              "ondernemen": "À entreprendre",
              "ontwikkelen": "Développer",
              "samenwerking": "Coopération",
              "taakuitdaging": "Défi de tâche",
              "waardering_en_erkenning": "Appréciation et reconnaissance",
              "zekerheid_en_stabiliteit": "Sécurité et stabilité",
              "zinvolle_bijdrage": "Contribution significative"
            },
            "titles": {
              "demotivators": "Quels sont vos démotivateurs ?",
              "neutral_motivators": "Quelles sont vos motivations neutres ?",
              "primary_motivators": "Quelles sont vos principales motivations ?",
              "secondary_motivators": "Quelles sont vos motivations secondaires ?"
            }
          }
        },
        "ixly_test": {
          "calculating_result": "Le résultat est calculé...",
          "component": "Élément",
          "document_option_for": "Options de documents pour",
          "download_modal_header": "Télécharger le rapport",
          "download_report": "Télécharger le rapport",
          "generating_report": "Le rapport est en cours de génération...",
          "make_visible_confirm": "Cette action est irréversible. Es-tu sur de vouloir continuer?",
          "no_test_selected_desc": "Sélectionnez une évaluation via les paramètres de cet élément",
          "no_test_selected_title": "Aucune évaluation sélectionnée",
          "norm_group_for": "Groupes de normes disponibles",
          "payment_required": "Votre organisation ne dispose pas de suffisamment de crédits pour télécharger ce rapport.",
          "program_subtasks": "%{program} se compose de %{subtest_names}",
          "report_name": "Nom du rapport",
          "report_settings": "Paramètres du rapport",
          "report_visible_for_advisor": "Résultat visible pour le coach",
          "report_visible_for_both": "Résultat visible pour les deux",
          "report_visible_for_candidate": "Résultat visible par le candidat",
          "resume_subtest": "Reprendre %{test}",
          "resume_test": "Reprendre l'évaluation",
          "save_date": "Date d'enregistrement",
          "select_document_option_placeholder": "Sélectionnez l'option de document souhaitée pour ce rapport :",
          "select_norm_group_placeholder": "Sélectionnez le groupe de normes souhaité pour ce rapport :",
          "settings_modal_header": "Paramètres du rapport",
          "skip_verification": "Passer",
          "start_review": "Commencer la révision",
          "start_subtest": "Démarrer %{test}",
          "start_test": "Commencer l'évaluation",
          "start_verification": "Commencer",
          "start_verification_subtest": "Commencez à vérifier %{test}",
          "start_verification_test": "Commencer la vérification",
          "task": "Devoir",
          "test_completed": "arrondi",
          "test_inprogress": "Commencé",
          "test_pending": "Pas encore commencé",
          "test_reviewrequired": "Examen requis",
          "test_verificationrequired": "Verification requise",
          "verification_completed": "Le test de vérification est terminé.",
          "verification_restart": "Redémarrage",
          "verification_skipped": "La vérification a été ignorée. Si nécessaire, vous pouvez le redémarrer à l'aide du bouton.",
          "verification_skipped_processing": "La vérification a été ignorée. Si nécessaire, vous pouvez le redémarrer à l'aide du bouton après la génération du rapport.",
          "view_results": "Voir les résultats",
          "waiting_verification_start": "Le candidat n'a pas encore commencé l'épreuve de vérification ; la vérification peut toujours être ignorée."
        },
        "job_market_scan": {
          "compare": "Comparez les métiers",
          "description_created": "Labour Market Opportunities calcule les opportunités actuelles et futures sur le marché du travail néerlandais. Après avoir saisi certaines informations sur l'éducation et l'emploi, une note unique est attribuée entre 1 (très mauvais) et 99 (très bon). À la fin, vous pouvez télécharger un rapport contenant des résultats détaillés.",
          "description_finished": "Les opportunités sur le marché du travail sont complétées ! Tous les résultats ont été calculés. Cliquez sur le bouton « Télécharger » pour (re)télécharger le rapport au format PDF.",
          "description_in_progress": "Les opportunités sur le marché du travail ne sont pas encore pleinement exploitées. Ceci est nécessaire pour générer le rapport. Click sur le bouton suivant pour continuer.",
          "download": "Télécharger",
          "download_alert": "Le projet Opportunités sur le marché du travail a été achevé il y a plus de six mois. de nouveaux rapports ne peuvent plus être générés.",
          "download_description_1": "Téléchargez tous les rapports enregistrés ci-dessous",
          "download_description_2": "pour générer un nouveau rapport avec les postes vacants, les formations et les cours mis à jour (disponible pendant 6 mois)",
          "download_link_text": "Ou cliquez ici",
          "download_title": "Télécharger les rapports",
          "resume": "CV",
          "start": "Commencer",
          "terms_and_conditions": "J'accepte les termes et conditions",
          "title": "Opportunités sur le marché du travail",
          "title_finished": "Résultats",
          "working": "Occupé..."
        },
        "job_market_scan_en": {
          "title": "Opportunités sur le marché du travail en anglais",
          "title_finished": "Résultats"
        },
        "logbook": {
          "back": "Dos",
          "content_placeholder": "voici le contenu",
          "delete": "Retirer",
          "help_text": "Vous pouvez conserver des pages de notes dans votre journal de bord.",
          "logbook": "Enregistrer",
          "new_page": "Nouvelle page",
          "title_placeholder": "titre de la page de journal"
        },
        "quiz": {
          "add_answer": "Ajouter une réponse",
          "answer_correct": "Correct",
          "answer_incorrect": "Incorrect",
          "answer_placeholder": "Entrez une réponse ici",
          "answer_placeholder_viewer": "Le candidat saisit ici sa réponse",
          "answer_text_placeholder": "Entrez la réponse %{index} ici",
          "back_current": "Précédent",
          "back_started": "Dos",
          "correct_placeholder": "Entrez la bonne réponse ici",
          "delete": "Retirer",
          "next_add": "Ajouter une question",
          "next_check": "Vérifier",
          "next_current": "suivante",
          "question_of": "QUESTION %{index} DE %{length}",
          "question_text_placeholder": "Entrez la question ici",
          "question_type_choice": "Choix multiple",
          "question_type_open": "Ouvrir",
          "selections_number": "Nombre de sélections",
          "selections_to": "jusqu'à",
          "start_text_checking": "Afficher les réponses",
          "start_text_resume": "CV",
          "start_text_set_up": "Ensemble",
          "start_text_start": "Commencer",
          "start_text_viewer": "Afficher les questions"
        },
        "report_visibility": {
          "advisor": {
            "both": "Le rapport est maintenant à la disposition du candidat. Le droit du candidat de télécharger/consulter le rapport peut être retiré en désactivant le curseur à droite.",
            "self": "Le rapport n'est pas encore disponible pour le candidat. En activant le curseur à droite, le candidat a le droit de télécharger et de consulter le rapport."
          },
          "candidate": {
            "both": "Le rapport est maintenant disponible pour l'entraîneur. Le droit du coach de télécharger/consulter le rapport peut être révoqué en désactivant le curseur à droite.",
            "self": "Le rapport n'est pas encore disponible pour l'entraîneur. En activant le curseur à droite, le coach a le droit de télécharger et de consulter le rapport."
          }
        },
        "search_job": {
          "delete": "Supprimer",
          "favorite": "Préféré",
          "favorites": "favoris",
          "location": "Où?",
          "no_favorites_chosen": "(aucun favori sélectionné)",
          "no_jobs_found": "(aucun poste vacant trouvé)",
          "query": "Quoi?",
          "search": "Chercher",
          "view": "Regarder"
        },
        "templates": {
          "add": "ajouter",
          "add_template": "Ajouter un modèle",
          "added": "Sélectionnable",
          "builder": {
            "add_column": "Ajouter une colonne",
            "add_element": "Ajouter une rubrique",
            "add_row": "Ajouter une rangée",
            "block": "Bloc",
            "circle": "Cercle",
            "date_formats": {
              "D": "numéro du jour (1 ou 2 chiffres)",
              "DD": "numéro du jour (2 chiffres)",
              "M": "numéro du mois (1 ou 2 chiffres)",
              "MM": "numéro du mois (2 chiffres)",
              "MMM": "nom du mois (abrégé)",
              "MMMM": "nom du mois",
              "YY": "année (2 chiffres)",
              "YYYY": "année (4 chiffres)",
              "ddd": "nom du jour (abrégé)",
              "dddd": "nom du jour"
            },
            "description": "Description",
            "destroy_confirm_column": "Êtes-vous sûr de vouloir supprimer cette colonne ?",
            "destroy_confirm_element": "Êtes-vous sûr de vouloir supprimer cette section ?",
            "destroy_confirm_row": "Êtes-vous sûr de vouloir supprimer cette ligne ?",
            "global": "Pour toutes les organisations ?",
            "header": "Petite tasse",
            "header_font_size": "Taille de la police d'en-tête",
            "height": "Hauteur",
            "hide_borders": "Masquer les contours",
            "hide_preview": "Masquer l'aperçu",
            "margin_from_top": "Espace au-dessus de la section",
            "optional": "Facultatif",
            "padding": "Espace autour du bloc",
            "shape": "Formulaire",
            "show_borders": "Afficher les contours",
            "show_header": "Afficher le titre",
            "show_icon": "Montrer l'icône",
            "show_preview": "Afficher l'exemple",
            "square": "Carré",
            "title": "Titre",
            "type": "Type de section",
            "variable_add_option": "Ajouter un choix",
            "variable_date": "Date",
            "variable_date_format": "Format",
            "variable_dropdown": "Sélection",
            "variable_help_text": "Texte d'aide",
            "variable_options": "Les choix",
            "variable_text": "Texte",
            "variable_type": "Type de variable",
            "width": "Largeur"
          },
          "change_template": "Changer de modèle",
          "default": "Choisi",
          "delete": "Retirer",
          "deselect": "Désélectionner",
          "destroy_confirm": "Êtes-vous sûr de vouloir supprimer ce modèle ?",
          "documentation": "(cliquez ici pour la documentation du générateur de CV et de lettres)",
          "edit": "Modifier",
          "edit_replacements": "Modifier les textes d'aide",
          "filler": {
            "add_item": "Ajouter un item",
            "add_optional": "Ajouter cette section au document",
            "change": "Changer",
            "crop_and_save": "Recadrer et enregistrer",
            "download_docx": "Télécharger DOCX",
            "download_pdf": "Télécharger le PDF",
            "remove": "Supprimer",
            "remove_optional": "Supprimer cette section du document",
            "upload_image": "Choisissez une image"
          },
          "image": "Image",
          "list": "Liste",
          "no_template_selected": "Aucun modèle sélectionné",
          "not_ready_yet_text": "Pas encore fini",
          "open": "Ouvrir",
          "remove": "Emporter",
          "remove_template": "Supprimer le modèle",
          "select": "Sélectionner",
          "select_template": "Sélectionnez un modèle",
          "switch_template": "Sélection de modèle",
          "text": "Texte"
        },
        "text": {
          "placeholder": "Cliquez ici pour saisir du texte (puis sélectionnez-le pour des options de style supplémentaires)"
        },
        "vacancy": {
          "action": "Action",
          "action_deadline": "Action de date limite",
          "action_placeholder": "Postuler à un emploi, préparer le premier entretien...",
          "back": "Dos",
          "before": "pour",
          "delete": "Retirer",
          "help_text": "Notez les postes vacants intéressants et déterminez les actions de suivi avec l'aide du gestionnaire de postes vacants.",
          "new_vacancy": "Nouveau poste vacant",
          "next_action": "L'action suivante peut être convenue ci-dessous avec une date limite associée :",
          "notes": "Remarques",
          "notes_placeholder": "autres commentaires",
          "status": "Statut",
          "statuses": {
            "created": "Créé",
            "first_interview": "Premier entretien",
            "hired": "Accepté",
            "interested": "Intéressé",
            "letter_sent": "Lettre écrite",
            "other": "Autrement",
            "rejected": "Refusé",
            "second_interview": "Deuxième interview"
          },
          "title": "Titre",
          "title_placeholder": "Livreur de courrier chez PostNL",
          "vacancy_link": "Lien vers l'emploi",
          "vacancy_link_placeholder": "ajouter un lien vers l'offre d'emploi",
          "vacancy_manager": "Gestionnaire des postes vacants",
          "view_vacancy": "voir le poste vacant"
        },
        "visibility": {
          "advisor": {
            "both": "Le document est désormais à la disposition du candidat. Le droit du candidat de télécharger/consulter le document peut être retiré en désactivant le curseur à droite.",
            "self": "Le document n'est pas encore disponible pour le candidat. En activant le curseur à droite, le candidat a le droit de télécharger et de visualiser le document."
          },
          "candidate": {
            "both": "Le document est désormais à la disposition du coach. Le droit du coach de télécharger/consulter le document peut être retiré en désactivant le curseur à droite.",
            "self": "Le document n'est pas encore disponible pour l'entraîneur. En activant le curseur de droite, le coach a le droit de télécharger et de visualiser le document."
          }
        },
        "youtube_video": {
          "url_placeholder": "Saisissez l'URL complète d'une vidéo YouTube dans les paramètres de cet élément"
        }
      },
      "cost_centers": {
        "number_of_users": "%{number} actif"
      },
      "editable_empty": "(Non)",
      "program_templates": {
        "number_of_pages": {
          "one": "1 étape",
          "other": "%{count} étapes"
        },
        "status": {
          "deleted": "Supprimé",
          "editable": "Modifiable",
          "hidden": "Supprimé",
          "published": "Publié",
          "template": "Modèle"
        }
      },
      "programs": {
        "number_of_pages": {
          "one": "1 étape",
          "other": "%{count} étapes"
        },
        "status": {
          "deleted": "Supprimé",
          "editable": "Modifiable",
          "hidden": "Supprimé",
          "published": "Publié",
          "template": "Modèle"
        }
      },
      "rooms": {
        "browser_unsupported": "Ce navigateur n'est pas pris en charge. Utilisez un navigateur moderne tel que Chrome ou Firefox.",
        "default_local_display_name": "je",
        "default_remote_display_name": "Anonymement"
      }
    },
    "label": {
      "search": "Chercher"
    },
    "labels": {
      "index": {
        "title": "Étiquettes"
      }
    },
    "language": {
      "name": "Français"
    },
    "languages": {
      "all": "Toutes les langues",
      "de": "Allemand",
      "en": "Anglais",
      "fr": "Français",
      "it": "Italien",
      "nl": "Néerlandais"
    },
    "layouts": {
      "assessment": {
        "header": {
          "logout": "Se déconnecter",
          "notifications": "Notifications"
        },
        "language_menu": {
          "select_language": "Choisir la langue"
        },
        "resource_header": {
          "notifications": "Notifications",
          "profile": "Profil",
          "switch_account": "Passer à un autre compte"
        },
        "users": {
          "dropdown": {
            "notifications": "Notifications"
          }
        }
      },
      "mailer": {
        "personal_info": "Modifier les informations personnelles",
        "privacy_policy": "politique de confidentialité",
        "rights": "Ixly 2023 Vleutensevaart 100 3532 AD Utrecht",
        "terms": "Termes et conditions",
        "unsubscribe": "Se déconnecter"
      }
    },
    "legacy_invoices": {
      "show": {
        "title": "Facture du kit d'outils de test"
      }
    },
    "login_mailer": {
      "confirmation_button": {
        "text": "créer un compte"
      },
      "welcome_mail": {
        "subject": "Accueillir"
      }
    },
    "media_item_share": {
      "empty_shared_with_list": "Il n’y a personne avec qui partager un élément multimédia",
      "send": "Partager",
      "share_with": "Partager avec"
    },
    "media_item_shares": {
      "deleted": "Le partage d'éléments multimédias a été supprimé avec succès.",
      "drawer_content": {
        "download": "Télécharger"
      },
      "failed_create": "L'élément multimédia n'a pas pu être partagé. Veuillez sélectionner un utilisateur avec lequel partager l'élément multimédia.",
      "list": {
        "created_at": "Date",
        "filename": "Nom",
        "relates_to": "Relatif à"
      },
      "success_create": "L'élément multimédia a été partagé avec succès."
    },
    "media_items": {
      "add": "Ajouter un fichier",
      "allowed_formats": "Formats de fichiers autorisés :",
      "cannot_delete": "La suppression a échoué. Un élément multimédia en cours d’utilisation ne peut pas être supprimé.",
      "created": "Fait",
      "deleted": "Élément multimédia supprimé avec succès.",
      "destroy": "Supprimer",
      "download": "Télécharger",
      "failed_upload": "La taille du fichier est trop grande (maximum 50 Mo)",
      "files": "Mes dossiers",
      "list": {
        "created_at": "Date",
        "filename": "Nom",
        "relates_to": "Relatif à",
        "shared_with": "Partagé avec"
      },
      "relates_to": "Relatif à",
      "shared_by": "Divisé par",
      "shared_with": "Partagé avec",
      "shared_with_me": "Partagé avec moi",
      "success_upload": "L'élément multimédia a été créé avec succès.",
      "title": "Documents & médiathèque",
      "upload_title": "Choisir un fichierï"
    },
    "notice": {
      "bulk": {
        "candidates_enrolled": "Les candidats ont été informés du processus nouvellement attribué par courrier électronique",
        "send_welcome": "Les e-mails de bienvenue ont été envoyés"
      },
      "password_has_been_updated": "Le mot de passe a été modifié"
    },
    "notification": {
      "details": {
        "assessment_finished_notification": "%{candidate} a terminé %{assessment}.",
        "candidate_assessment": {
          "finished": "%{candidate} a terminé %{assessment}.",
          "invited": "L'évaluation '%{assessment}' vous a été attribuée."
        },
        "comment_notification": "%{sender} vous a envoyé un message : \"%{message}\"",
        "credit_limit_reached_notification": "Vous recevez ce message car les crédits prépayés disponibles pour votre organisation sont tombés en dessous de %{credit_limit}. Nous vous recommandons d'acheter davantage de crédits afin de pouvoir continuer à générer des rapports.",
        "document": {
          "approved_notification": "%{sender} a approuvé le document %{filename}.",
          "rejected_notification": "%{sender} a rejeté le document %{filename}. Vous pouvez télécharger un nouveau document et le faire approuver à nouveau.",
          "submitted_notification": "%{sender} vous demande d'approuver le document %{filename}.",
          "uploaded_notification": "%{sender} a téléchargé le document %{filename}."
        },
        "enroller_notification": "%{candidate} s'est enregistré dans '%{title}'.",
        "enrollment": {
          "coach_action_notification": "Le processus « %{title} » de %{candidate} comporte des évaluations pour lesquelles un rapport doit être sélectionné. Une fois les rapports souhaités sélectionnés, le candidat peut être invité à démarrer le processus.",
          "created_notification": "Le processus '%{title}' vous a été attribué."
        },
        "enrollment_page": {
          "component_action_notification": "L'étape %{title} du processus %{process} contient un élément qui nécessite une action.",
          "done_notification": "L'étape %{title} du processus %{process} est terminée.",
          "rejected_notification": "L'étape %{title} du processus %{process} a été rejetée. Vous pouvez mettre à jour les parties de l'étape et les soumettre à nouveau.",
          "submitted_notification": "L'étape %{title} du processus %{process} a été soumise. Vous pouvez vérifier l'étape et l'approuver ou la rejeter."
        },
        "media_item_notification": "Le fichier %{filename} a été ajouté à la Bibliothèque Documents & Média.",
        "media_item_share_notification": "%{name} a partagé %{filename} avec vous dans la bibliothèque Documents et média.",
        "post_notification": "L'article de blog %{title} a été partagé avec vous.",
        "postmark": {
          "inactive_recipient": "Une erreur s'est produite lors de l'envoi d'un e-mail à %{candidate}. Veuillez vérifier l'adresse email",
          "invalid_address": "L'adresse email %{email} de %{candidate} n'est pas valide. Veuillez corriger l'adresse e-mail afin que %{candidate} puisse recevoir des e-mails."
        },
        "review_required": "L’évaluation %{assessment_title} de %{candidate_name} nécessite une révision.",
        "suppressed_email": "Nous avons essayé d'envoyer un e-mail à %{name}, mais cela n'a pas fonctionné pour la raison %{reason}. Assurez-vous que l'adresse e-mail utilisée est correcte (%{email}).\n%{name} n'a pas reçu d'e-mail.\n",
        "unsuppressed_email": "Nous pouvons à nouveau envoyer des messages via %{email} à %{name}.",
        "verification_required": "L'évaluation %{assessment_title} de %{candidate_name} doit être vérifiée.\nChoisissez si la vérification peut être lancée ou si elle est ignorée afin que l'évaluation puisse être terminée.\n",
        "verification_started": "L'évaluation %{assessment_title} comporte un test de vérification qui peut être démarré"
      },
      "type": {
        "AppointmentAcceptedNotification": "Rendez-vous accepté",
        "AppointmentCancelledNotification": "Rendez-vous annulé",
        "AppointmentDeclinedNotification": "Rendez-vous refusé",
        "AppointmentSubmittedNotification": "Rendez-vous suggéré",
        "assessment_finished_notification": "Évaluation terminée",
        "candidate_assessment": {
          "finished": "Évaluation terminée",
          "invited": "Évaluation nouvellement attribuée"
        },
        "comment_notification": "Nouveau message",
        "credit_limit_reached_notification": "Il reste peu de crédits",
        "document": {
          "approved_notification": "Document approuvé",
          "rejected_notification": "Document rejeté",
          "submitted_notification": "Document à approuver",
          "uploaded_notification": "Fichier téléchargé"
        },
        "enroller_notification": "Nouvelle application",
        "enrollment": {
          "coach_action_notification": "Sélection du rapport requise",
          "created_notification": "Processus nouvellement accordé"
        },
        "enrollment_page": {
          "component_action_notification": "Action requise",
          "done_notification": "Étape terminée",
          "rejected_notification": "Étape rejetée",
          "submitted_notification": "Étape soumise"
        },
        "media_item_notification": "Un nouveau fichier a été ajouté à la Bibliothèque Documents & Médiathèque",
        "media_item_share_notification": "Un fichier a été partagé avec vous",
        "post_notification": "Nouvel article du blog",
        "postmark_notification": "Impossible de remettre l'e-mail",
        "review_required": "Révision requise pour %{assessment_title}",
        "suppressed_email": "Nous n'avons pas pu joindre %{member_type} par e-mail",
        "unsuppressed_email": "Le %{member_type} est à nouveau joignable par e-mail",
        "verification_required": "Authentification requise pour %{assessment_title}",
        "verification_started": "L'évaluation %{assessment_title} comporte un test de vérification qui peut être démarré"
      }
    },
    "notifications_overview_mailer": {
      "notification_mail": {
        "subject": "Vous avez reçu de nouvelles notifications"
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": " ",
          "format": "%n %u",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": " ",
        "precision": 3,
        "round_mode": "default",
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "milliard",
            "million": "million",
            "quadrillion": "million de milliards",
            "thousand": "millier",
            "trillion": "billion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "octet",
              "other": "octets"
            },
            "eb": "Eo",
            "gb": "Go",
            "kb": "ko",
            "mb": "Mo",
            "pb": "Po",
            "tb": "To"
          }
        }
      },
      "nth": {
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "organization": "Organisation",
    "organization_products": {
      "create": {
        "created": "Les modules ont été ajoutés à l'organisation"
      },
      "destroy": {
        "already_started": "Ce module a déjà démarré."
      },
      "index": {
        "from_subscription": "Hors abonnement",
        "new_button": "Ajouter des modules",
        "table_header": [
          "Nom",
          "Date de début",
          "Date de fin",
          "Actions"
        ],
        "title": "Modules"
      },
      "new": {
        "title": "Ajouter des modules à %{organization}"
      },
      "row": {
        "product_delete": "Êtes-vous sûr de vouloir supprimer ce module de l'organisation ?"
      }
    },
    "organization_status": {
      "active": "Actif",
      "created": "Créé",
      "deleted": "Supprimé",
      "inactive": "Inactif"
    },
    "organizations": {
      "billing": {
        "details": {
          "cost_centers": {
            "in_use": "Ce centre de coûts compte toujours des utilisateurs actifs.",
            "new_button": "Nouveau centre de coûts",
            "table_header": [
              "Nom",
              "Description",
              "Utilisateurs",
              "Candidats",
              "Actions"
            ],
            "title": "Centres de coûts"
          },
          "organization": "Organisation",
          "title": "Faits"
        },
        "help_text": "Vous trouverez ici un aperçu de votre abonnement, de votre utilisation, de vos factures et de vos données.",
        "info": "Choisissez un mode de paiement et le forfait de crédits que vous souhaitez acheter.",
        "invoices": {
          "legacy_invoices": "Factures de la boîte à outils de test",
          "no_orders": "(aucune commande n'a encore été passée)",
          "previous_orders": "Commandes précédentes",
          "title": "Factures"
        },
        "payment": "Moyen de paiement",
        "payment_title": "Acheter un forfait de crédits",
        "subscription": {
          "credits": {
            "invoice": "L'augmentation du crédit a été mise en œuvre ! Une facture sera envoyée. Vous disposez désormais de %{count} crédits",
            "open": "L'augmentation du crédit a été mise en œuvre ! Le montant sera débité automatiquement. Vous disposez désormais de %{count} crédits",
            "paid": "L'augmentation du crédit a été mise en œuvre ! Vous disposez désormais de %{count} crédits"
          },
          "mandate": {
            "activate_credit_purchase": "Activez votre organisation avec un achat de forfait de crédits pour que vos candidats puissent se lancer !",
            "change_html": "<a href='%{link}' data-method='post'>Cliquez ici pour autoriser un nouveau prélèvement</a> .",
            "credit_card": "la carte de crédit portant le numéro XXXX XXXX XXXX %{number}.",
            "direct_debit": "le compte IBAN avec le numéro %{number}.",
            "not_valid_html": "Vos utilisateurs ne peuvent se connecter qu'après un paiement d'autorisation. Cela garantit qu'Ixly BV est autorisé à débiter le compte utilisé à cet effet. <a class='alert-link' href='%{link}' target='_blank'>Nos conditions générales</a> s'appliquent.",
            "open": "Le paiement de l'autorisation est actuellement toujours en cours de traitement ; recharger la page pour une mise à jour",
            "paid": "Le paiement d'autorisation a réussi ! Désormais, vos frais seront débités à partir de %{number}",
            "valid": "Vos frais seront automatiquement débités de"
          },
          "not_active": "Votre organisation n'a pas d'abonnement actif",
          "order": {
            "canceled": "Le paiement a été annulé"
          },
          "payment": {
            "automatic_method": {
              "explain": "Autorisez en achetant un forfait de crédit initial pour des transactions de crédit fluides et sans effort, maintenant et à l'avenir.",
              "title": "Paiement automatique"
            },
            "direct_method": {
              "info_text1": "Bénéficiez de paiements directs sans frais supplémentaires avec iDEAL",
              "info_text2": "Sûr, rapide et facile.",
              "title": "Paiement immédiat"
            },
            "info_text1": "Ne vous souciez plus des paiements !",
            "info_text2": "Avec le prélèvement automatique, vous pouvez l'organiser rapidement et facilement.",
            "manual_method": {
              "confirmation": "Es-tu sûr? Cela signifie qu'à partir de juin, vous paierez 9,99 € par facture.",
              "explain": "À compter du 1er juillet, nous facturerons 9,99 € de frais de dossier pour chaque facture",
              "info_text1": "Vous préférez payer après ?",
              "info_text2": "Ceci est possible avec le paiement manuel.",
              "please_note": "Veuillez noter",
              "title": "Paiement manuel"
            },
            "mollie": {
              "conditional1": "Les paiements manuels appartiennent au passé",
              "conditional2": "Vous avez le contrôle total de vos finances",
              "conditional3": "Économisez sur les coûts supplémentaires et les tracas avec les factures"
            },
            "payment_type": {
              "automatic": "Paiement automatique",
              "direct": "Paiement direct avec idéal",
              "invoice": "Paiement manuel"
            }
          },
          "subscriptions": {
            "active_advisors_title": "Conseillers actifs",
            "active_candidates": "(à partir de %{max_candidates})",
            "active_candidates_title": "Candidats actifs",
            "active_premium_candidates_title": "Candidats premium actifs",
            "active_users": "(à partir de %{max_users})",
            "active_users_title": "Coachs actifs",
            "already_started": "Cet abonnement a déjà commencé.",
            "credits": "Crédits:",
            "downgrade": "Rétrograder",
            "downgrades": "Déclassements",
            "incidental_subscription_credits_left": "S'il reste des crédits au début du nouvel abonnement, ils seront utilisés en premier",
            "new_button": "Nouvel abonnement",
            "prices": "Des prix",
            "purchase": "Acheter",
            "settings": "Caractéristiques",
            "table_header": [
              "Nom",
              "Date de début",
              "Date de fin",
              "Actions"
            ],
            "title": "Abonnements",
            "total_advisors": "(à partir de %{max_users})",
            "total_advisors_title": "Tous les conseillers",
            "total_candidates": "(à partir de %{max_candidates})",
            "total_candidates_title": "Tous les candidats",
            "total_coaches_title": "Tous les entraîneurs",
            "total_premium_candidates_title": "Tous les candidats premium",
            "upgrade": "Mise à niveau",
            "upgrades": "Mises à niveau",
            "user_counts": "Numéros d'utilisateur"
          },
          "title": "Abonnement"
        },
        "title": "Facturation",
        "usage": {
          "active_candidates": {
            "prepaid": "prépayé",
            "table_header": [
              "Candidat",
              "Activé le",
              "Prix"
            ],
            "title": "Candidats actifs"
          },
          "active_coaches": {
            "table_header": [
              "Entraîneur",
              "Activé le",
              "Prix"
            ],
            "title": "Coachs actifs"
          },
          "aycr_periods": {
            "table_header": [
              "Candidat",
              "Valable",
              "Prix"
            ],
            "title": "Candidats dont les évaluations ont commencé"
          },
          "generated_reports": {
            "table_header": [
              "Évaluation",
              "Rapport",
              "Candidat",
              "Généré le",
              "Crédits",
              "Prix"
            ],
            "title": "Rapports générés"
          },
          "period": "Période",
          "premium_components": {
            "table_header": [
              "Élément",
              "Candidat",
              "Commencé le",
              "Prix"
            ],
            "title": "Éléments premium démarrés"
          },
          "title": "Usage",
          "total_with_vat": "Coûts totaux incluant %{vat}% TVA : %{price}"
        }
      },
      "edit": {
        "available_languages_confirm": "Vous êtes sur le point de désactiver les langues utilisées par vos utilisateurs. Leur langue est remplacée par la langue par défaut de votre organisation. Es-tu sûr?",
        "explanation": "La langue sélectionnée sert de paramètre par défaut pour les nouveaux utilisateurs au sein de l'organisation.",
        "logo": "Logo",
        "title": "Paramètres de l'organisation",
        "upload_logo": "Cliquez ici pour télécharger un logo"
      },
      "index": {
        "table_header": [
          "Nom",
          "Abonnement",
          "Utilisateurs",
          "Processus",
          "Statut",
          "Actions"
        ]
      },
      "payment_method_mollie_direct": {
        "success": "Le paiement direct a été créé avec succès"
      },
      "row": {
        "impersonate": "Se connecter en tant que",
        "organization_delete": "Êtes-vous sûr de vouloir supprimer cette organisation ?"
      },
      "texts": {
        "welcome_mail": {
          "example_mail_body": "Salut %{user_name}. Bienvenue sur la plateforme d'évaluation.\n\nUn nouveau compte a été créé pour vous sur Assessment Platform.\n\nDéfinissez immédiatement votre mot de passe pour accéder à votre environnement personnel.\n\n<a href='%{password_set_link}'>Créer un compte</a>.\n\nIl est important de choisir un mot de passe fort et unique pour protéger votre compte. Par conséquent, assurez-vous que votre nouveau mot de passe comporte au moins dix caractères et est une combinaison de lettres, de chiffres et de symboles.\n\nCordialement,\n%{organization_name}"
        }
      },
      "update": {
        "organization_updated": "L'organisation a été mise à jour"
      }
    },
    "otp_secret": {
      "cancel_otp": "Annuler le processus d'authentification",
      "input_the_code": "Entrez le code fourni par l'application d'authentification",
      "scan_the_code": "Scannez le code avec une application d'authentification telle que %{app_1} ou %{app_2}."
    },
    "page_status": {
      "created": {
        "seen": "Non lu",
        "set": "Ouvrir",
        "submit": "Ouvrir"
      },
      "done": {
        "seen": "Vu",
        "set": "arrondi",
        "submit": "Approuvé"
      },
      "hidden": "Caché",
      "optional": "Facultatif",
      "rejected": {
        "submit": "Encore"
      },
      "submitted": {
        "submit": "Remis"
      }
    },
    "pagy": {
      "combo_nav_js": "<label>Page %{page_input} sur %{pages}</label>",
      "info": {
        "multiple_pages": "Affichage des %{item_name} <b>%{from} à %{to}</b> sur <b>%{count}</b> au total",
        "no_items": "Aucun %{item_name} trouvé",
        "single_page": "Affichage de <b>%{count}</b> %{item_name}"
      },
      "item_name": {
        "one": "élément",
        "other": "éléments"
      },
      "items_selector_js": "<label>Afficher %{items_input} %{item_name} par page</label>",
      "nav": {
        "gap": "&hellip;",
        "next": "Suivant&nbsp;&rsaquo;",
        "prev": "&lsaquo;&nbsp;Précédent"
      }
    },
    "pallet": {
      "image": {
        "label": "Choisissez une image"
      },
      "ixly_test": {
        "description": "Explication de l'évaluation",
        "label": "Nom de l'évaluation"
      },
      "label": "Étiquette",
      "quiz": {
        "title": "Questionnaire"
      },
      "templates": {
        "title": "Générateur de CV et de lettres"
      }
    },
    "password": {
      "forgot_password": {
        "help_text": "Entrez votre adresse e-mail pour demander un nouveau mot de passe",
        "request": "Demander",
        "title": "Vous avez oublié votre mot de passe"
      },
      "reset_password_form": {
        "button": "Modifier",
        "help_text": "Entrez et confirmez votre nouveau mot de passe",
        "invalid_link": "Votre lien de récupération est utilisé, expiré ou invalide : veuillez en demander un à nouveau",
        "title": "changer le mot de passe"
      },
      "send_reset_password_link": {
        "help_text": "Dans quelques minutes, vous recevrez l'e-mail avec lequel vous pourrez définir un nouveau mot de passe",
        "title": "E-mail de récupération de mot de passe envoyé",
        "unknown_email": "L'adresse e-mail que vous avez fournie ne nous est pas connue"
      },
      "update_password_with_reset_token": {
        "invalid_link": "Votre lien de récupération est utilisé, expiré ou invalide : veuillez en demander un à nouveau",
        "password_has_been_reset": "Votre mot de passe a été modifié et vous êtes connecté avec succès",
        "password_is_of_insufficient_length": "Le nouveau mot de passe doit contenir au moins 10 caractères",
        "passwords_dont_match": "Le mot de passe et la confirmation ne correspondent pas"
      }
    },
    "placeholder": {
      "search": "Chercher"
    },
    "posts": {
      "available_locales": "Langues disponibles",
      "destroy": {
        "destroyed": "L'article a été supprimé"
      },
      "destroy_confirm": "Êtes-vous sûr de vouloir supprimer cet article ?",
      "edit": "Modifier l'article",
      "edit_rules": "Modifier les règles de partage",
      "empty": "(aucun article n'a encore été publié)",
      "help_text": "Gérer ces articles et leurs règles de partage",
      "hide_more": "Cacher",
      "index": {
        "title": "Articles de blog"
      },
      "new": "Nouvel article",
      "open": "Ouvrir",
      "post_rules": {
        "add": "Ajouter une ligne de partage",
        "private": "(personne pour l'instant)",
        "program_labels": {
          "multiple": ", auxquels sont affectés les <strong>processus portant les étiquettes %{list}</strong>",
          "single": ", auxquels sont affectés les <strong>processus portant le label %{list}</strong>"
        },
        "programs": {
          "multiple": ", auquel les <strong>processus",
          "single": ", auquel le <strong>processus"
        },
        "public": "(visible par tous)",
        "roles": {
          "account_managers": "Gestionnaires de compte",
          "accountmanagers": "Gestionnaires de compte",
          "advisors": "conseillers",
          "all_internal_users": "entraîneurs",
          "all_users": "utilisateurs",
          "coachs": "coachs de processus",
          "contact_persons": "contacter des personnes",
          "ixly_admins": "Administrateurs Ixly",
          "ixly_employees": "Employés Ixly",
          "organization_managers": "gestionnaires d'organisation",
          "selected": "Tous les <strong>%{list}</strong>",
          "selected_advisor": "avec <strong>entraîneur %{author_name}</strong>",
          "selected_global": "de <strong>toutes les organisations</strong>",
          "users": "Participants"
        },
        "user_labels": {
          "multiple": "avec <strong>étiquettes %{list}</strong>",
          "single": "avec <strong>étiquette %{list}</strong>"
        },
        "users": {
          "multiple": "Utilisateurs <strong>%{names}</strong>",
          "single": "Utilisateur <strong>%{names}</strong>"
        },
        "users_type": {
          "certain": "Partager avec des utilisateurs spécifiques",
          "filters": "Partager avec des rôles, des étiquettes et des processus",
          "public": "Publique"
        }
      },
      "private": "En privé",
      "published_by": "Publié par",
      "shared": "partagé",
      "shared_with": "Cet article a été partagé avec :",
      "show_more": "Montre plus",
      "sidebar": {
        "advisor": "Pour vos propres candidats",
        "global": "Pour toutes les organisations",
        "organization": "Pour %{organization_name}",
        "shared": "Partagé avec vous"
      },
      "status": "Statut",
      "title": "Titre",
      "update": {
        "updated": "Les règles de partage ont été mises à jour"
      }
    },
    "prices": {
      "active": "Actif",
      "amc": "Prix AMC",
      "bov": "Prix BOV",
      "cannot_modify_expired": "Vous ne pouvez pas modifier les listes de prix actives et expirées",
      "created_by": "faite par",
      "does_not_exist": "Liste de prix introuvable",
      "edit": "Modifier la liste de prix",
      "effective_from": "En vigueur à partir de",
      "expired": "Expiré",
      "external_user": "Coûts mensuels par candidat",
      "fit": "Prix CONVENABLE",
      "internal_user": "Coûts mensuels par autocar",
      "job_market_scan": "Prix des opportunités sur le marché du travail",
      "job_market_scan_en": "Évaluer les opportunités sur le marché du travail en anglais",
      "job_suggestions": "Suggestions d'emplois",
      "jobfeed": "Prix Jobfeed",
      "new": "Nouvelle liste de prix",
      "pending": "En attente",
      "price_created": "La liste de prix a été créée",
      "price_updated": "La liste de prix a été mise à jour",
      "something_went_wrong": "Quelque chose s'est mal passé",
      "status": "Statut",
      "updated_at": "Mis à jour le"
    },
    "product_program_templates": {
      "create": {
        "created": "Des modèles de processus ont été ajoutés au module",
        "product_program_templates_blank": "Aucun modèle de processus n'a été sélectionné"
      },
      "destroy": {
        "product_program_template_destroyed": "Le modèle de processus a été supprimé du module"
      },
      "new": {
        "title": "Ajouter des modèles de processus à %{product}"
      },
      "row": {
        "product_program_template_delete": "Êtes-vous sûr de vouloir supprimer ces modèles de processus du module ?"
      }
    },
    "product_status": {
      "active": "Actif",
      "deactivated": "Désactivé"
    },
    "product_tasks": {
      "create": {
        "created": "Les évaluations ont été ajoutées au module",
        "product_tasks_blank": "Aucune évaluation n'a été sélectionnée"
      },
      "deactivate": {
        "deactivated": "La connexion à l'évaluation a été désactivée"
      },
      "destroy": {
        "task_destroyed": "L'évaluation a été supprimée du module"
      },
      "new": {
        "title": "Ajouter des évaluations à %{product}"
      },
      "reactivate": {
        "reactivated": "La connexion à l'évaluation a été réactivée"
      },
      "row": {
        "task_delete": "Êtes-vous sûr de vouloir supprimer cette évaluation du module ?"
      }
    },
    "products": {
      "create": {
        "product_created": "Le module a été créé",
        "title": "Nouveau module"
      },
      "deactivate": {
        "deactivated": "Le module est désactivé"
      },
      "destroy": {
        "deleted": "Le module a été supprimé"
      },
      "edit": {
        "title": "Modifier le module"
      },
      "index": {
        "new_button": "Nouveau module",
        "table_header": [
          "Nom",
          "Statut",
          "Statut modifié le",
          "Actions"
        ],
        "title": "Modules"
      },
      "new": {
        "title": "Nouveau module"
      },
      "reactivate": {
        "reactivated": "Le module a été réactivé"
      },
      "row": {
        "product_delete": "Êtes-vous sûr de vouloir supprimer ce module ?"
      },
      "show": {
        "new_button": "Ajouter des évaluations",
        "new_program_template_button": "Ajouter des processus",
        "new_task_button": "Ajouter des évaluations",
        "organizations": "Organisations",
        "product_program_templates": "Modèles de processus",
        "product_program_templates_table_header": [
          "Nom",
          "Langue",
          "Statut",
          "Actions"
        ],
        "product_tasks": "Évaluations",
        "product_tasks_table_header": [
          "Nom",
          "Clé",
          "Langue",
          "Crédits",
          "Statut",
          "Lien",
          "Actions"
        ],
        "status": "Statut",
        "subscription_types": "Types d'abonnement"
      },
      "update": {
        "product_updated": "Le module a été mis à jour",
        "title": "Modifier le module"
      }
    },
    "profile": {
      "audits": {
        "show": {
          "action": "Action",
          "browser": "Navigateur",
          "caption": "Vous trouverez ci-dessous toutes les actions d'authentification pour votre compte.",
          "created_at": "Connecté à",
          "ip": "IP",
          "title": "Historique des audits"
        }
      },
      "base": {
        "tabs": {
          "audit_history": "Historique des audits",
          "personal_information": "Informations personnelles",
          "settings": "Établissements"
        }
      },
      "candidates": {
        "update": {
          "updated": "Vos informations personnelles ont été mises à jour"
        }
      },
      "images": {
        "destroy": {
          "deleted": "Photo de profil supprimée",
          "destroy_confirm": "Supprimer la photo de profil"
        },
        "form": {
          "allowed_formats": "Formats de fichiers autorisés : JPG, PNG",
          "upload": "Envoyer la photo"
        },
        "update": {
          "updated": "Photo de profil ajustée"
        }
      },
      "modal": {
        "profile_image": {
          "change": "Ajuster",
          "save": "Sauvegarder",
          "title": "Ajuster la photo de profil",
          "upload": "Télécharger"
        }
      },
      "notification_settings": {
        "email_notifications": {
          "help_text": "Choisissez les paramètres de notification souhaités, gardez à l'esprit que seules les notifications invisibles sont envoyées par e-mail.",
          "title": "Fréquence des e-mails de notification"
        },
        "update": {
          "updated": "Vos paramètres personnels ont été mis à jour"
        }
      },
      "notifications": {
        "all": "Afficher tout",
        "bulk_update": {
          "marked_as_read": "Les notifications sont marquées comme lues"
        },
        "document_approvals": "Approbations des documents",
        "documents": "Documents",
        "last_30_days": "Les 30 derniers jours",
        "messages": "Informer",
        "news": "Nouvelles",
        "system": "Système",
        "title": "Notifications",
        "tracks": "Trajectoires"
      },
      "passwords": {
        "update": {
          "sent": "Vous recevrez un e-mail avec des instructions pour changer votre mot de passe"
        }
      },
      "profiles": {
        "show": {
          "password": {
            "instructions": "Si vous souhaitez modifier le mot de passe de votre compte, vous recevrez un e-mail de notre part avec des instructions.",
            "request": "demander de",
            "title": "Changer le mot de passe"
          },
          "profile_image": "Image de profil",
          "title": "Vos informations"
        }
      },
      "settings": {
        "activate_2fa": {
          "activate": "Activer",
          "inactive": "2FA n’a pas encore été activé"
        },
        "deactivate_2fa": {
          "active": "2FA est activé",
          "deactivate": "Désactiver",
          "disabled": "Le recours à la vérification en deux étapes est requis par l'organisation"
        },
        "two_factor_authentication": {
          "title": "Authentification à 2 facteurs"
        }
      },
      "title": "Mon profil",
      "two_factor_authentications": {
        "destroy": {
          "destroyed": "2FA est désactivé",
          "tfa_required": "2FA ne peut pas être désactivé"
        }
      },
      "users": {
        "update": {
          "updated": "Vos informations personnelles ont été mises à jour"
        }
      }
    },
    "profile_picture_disclaimer": {
      "accept": "Accepter",
      "cancel": "Annuler",
      "title": "Avis de non-responsabilité concernant la photo de profil"
    },
    "program_pages": {
      "edit": {
        "drop": "Faites glisser les éléments ici"
      },
      "new": {
        "default_page_title": "Nouvelle étape"
      },
      "show": {
        "not_editable": "Ce processus a été publié et est donc attribuable, mais ne peut être modifié à nouveau qu'après retrait.",
        "not_editable_advisor": "En tant que coach de processus, vous ne pouvez pas modifier ce processus, mais vous pouvez le visualiser (y compris tous les paramètres)",
        "not_editable_deleted": "Ce processus a été supprimé et n'est donc plus modifiable ni attribuable",
        "not_editable_template": "Vous pouvez afficher ou copier ce modèle dans les propres processus de votre organisation"
      }
    },
    "program_templates": {
      "bulk": {
        "publish": "Plusieurs procédés ont été publiés",
        "withdraw": "Plusieurs processus ont été retirés"
      },
      "destroy": {
        "destroyed": "Le processus '%{program_title}' a été supprimé"
      },
      "duplicate": {
        "duplicate_prefix": "Copie:",
        "program_duplicated": "Le processus '%{program_title}' a été copié",
        "template_duplicated": "Le processus « %{program_title} » a été copié dans les propres processus de votre organisation."
      },
      "form": {
        "invite_mail": "E-mail d'invitation",
        "invite_mail_documentation": "(cliquez ici pour la documentation de l'e-mail d'invitation)",
        "labels": "Étiquettes",
        "options": "Possibilités",
        "title_placeholder": "Choisissez un titre",
        "your_labels": "Vos étiquettes"
      },
      "index": {
        "help_text": "Concevez, modifiez ou visualisez les processus de votre organisation. Des processus sont disponibles pour les conseillers ayant le rôle de coach et les candidats premium.",
        "new_button": "Nouveau processus",
        "own_programs": "Propres processus",
        "table_header": [
          "Statut",
          "Faits",
          "Longueur",
          "Actions"
        ],
        "templates": "Modèles",
        "title": "Développer des processus"
      },
      "new": {
        "default_program_title": "Processus"
      },
      "publish": {
        "all_pages_invisible": "Vous ne pouvez pas publier ce processus car aucune des étapes n'est définie comme visible par le candidat",
        "published": "Le processus '%{program_title}' a été publié"
      },
      "publish_as_template": {
        "published_as_template": "Le processus '%{program_title}' a été publié comme modèle"
      },
      "update": {
        "update_failed": "Le processus n'a pas pu être mis à jour",
        "updated": "Le processus a été mis à jour"
      },
      "withdraw": {
        "withdrawn": "Le processus '%{program_title}' a été retiré"
      }
    },
    "programs": {
      "bulk": {
        "publish": "Plusieurs procédés ont été publiés",
        "withdraw": "Plusieurs processus ont été retirés"
      },
      "destroy": {
        "destroyed": "Le processus '%{program_title}' a été supprimé"
      },
      "duplicate": {
        "duplicate_prefix": "Copie:",
        "program_duplicated": "Le processus '%{program_title}' a été copié",
        "template_duplicated": "Le processus « %{program_title} » a été copié dans les propres processus de votre organisation."
      },
      "form": {
        "labels": "Étiquettes",
        "options": "Possibilités",
        "title_placeholder": "Choisissez un titre",
        "your_labels": "Vos étiquettes"
      },
      "index": {
        "help_text": "Concevez, modifiez ou visualisez les processus de votre organisation. Des processus sont disponibles pour les conseillers ayant le rôle de coach et les candidats premium.",
        "new_button": "Nouveau processus",
        "own_programs": "Propres processus",
        "table_header": [
          "Statut",
          "Faits",
          "Longueur",
          "Actions"
        ],
        "templates": "Modèles",
        "title": "Développer des processus"
      },
      "new": {
        "default_program_title": "Processus"
      },
      "publish": {
        "all_pages_invisible": "Vous ne pouvez pas publier ce processus car aucune des étapes n'est configurée pour être visible par le participant",
        "published": "Le processus '%{program_title}' a été publié"
      },
      "publish_as_template": {
        "published_as_template": "Le processus '%{program_title}' a été publié comme modèle"
      },
      "update": {
        "update_failed": "Le processus n'a pas pu être mis à jour",
        "updated": "Le processus a été mis à jour"
      },
      "withdraw": {
        "withdrawn": "Le processus '%{program_title}' a été retiré"
      }
    },
    "prompt": {
      "cost_center_delete_confirm": "Etes-vous sûr de vouloir supprimer ce centre de coûts ?",
      "file_remove_confirm": "Êtes-vous sûr de vouloir supprimer ce fichier ?",
      "program_delete_confirm": "Êtes-vous sûr de vouloir supprimer ce processus avec toutes ses étapes et tous ses éléments ?",
      "room_delete_confirm": "Êtes-vous sûr de vouloir supprimer cet appel vidéo ?",
      "subscription_delete_confirm": "Êtes-vous sûr de vouloir supprimer cet abonnement ?",
      "subscription_upgrade_confirm": "Êtes-vous sûr de vouloir mettre à niveau votre abonnement ? Vous ne pouvez pas rétrograder dans l'année suivant le début du nouvel abonnement."
    },
    "resource": {
      "candidate_email_messages": "E-mails envoyés",
      "close": "Fermer",
      "edit": "Modifier",
      "filter": "Filtre",
      "impersonate": "Se connecter en tant que",
      "interactive_results": "Résultats",
      "new_enrollment": "Créer un itinéraire",
      "reinvite": "Inviter à nouveau"
    },
    "role": {
      "AccountManager": "Gestionnaire de compte",
      "Coach": "Coach de processus",
      "IxlyAdmin": "Administrateur Ixly",
      "OrganizationManager": "Responsable d'organisation",
      "SelectionManager": "Responsable de sélection"
    },
    "rooms": {
      "show": {
        "cannot_join": "Cet appel vidéo est actuellement fermé"
      }
    },
    "score": {
      "no_score": "Sans partition",
      "with_score": "Avec partition"
    },
    "selection": {
      "job_profiles": {
        "create": {
          "success": "Le profil d'emploi a été créé",
          "title": "Nouveau profil d'emploi"
        },
        "destroy": {
          "failure": "ne peut pas être supprimé car il est associé à une ou plusieurs commandes Matching",
          "success": "Le profil d'emploi sélectionné a été supprimé"
        },
        "edit": {
          "title": "Modifier le profil d'emploi"
        },
        "form": {
          "job_profile_explanation": "Avec Compare & Select, nous utilisons des évaluations avancées qui mesurent objectivement les compétences et l'intelligence des candidats. Nos évaluations standardisées fournissent des scores concrets, qui constituent la base de notre correspondance basée sur les données. Avec cette approche scientifique, nous transcendons la subjectivité et introduisons les soins"
        },
        "index": {
          "compare_select_icon_alt": "Icône Comparer et sélectionner",
          "list": "Accédez à l'<strong>aperçu des candidats, sélectionnez les candidats</strong> et appuyez sur le bouton <strong>'Comparer et sélectionner'</strong> %{icon} pour voir les résultats correspondants.",
          "title": "Profils d'emploi"
        },
        "list": {
          "created_at": "Fait sur",
          "title": "Titre",
          "updated_at": "Dernière modification le"
        },
        "new": {
          "title": "Nouveau profil d'emploi",
          "tooltip": "Fonctionnalité Ajouter un profil"
        },
        "update": {
          "success": "La modification du profil d'emploi a réussi",
          "title": "Modifier le profil d'emploi"
        }
      },
      "matching_assignments": {
        "create": {
          "candidates": "Candidats",
          "success": "L'affectation correspondante a été créée avec succès",
          "title": "Rédiger un devoir de correspondance"
        },
        "edit": {
          "title": "Modifier la commande correspondante"
        },
        "index": {
          "candidate": "candidats",
          "caption": "Vous trouverez ici un aperçu de vos missions correspondantes",
          "list": {
            "candidates": "Candidats",
            "created_at": "Fait sur",
            "job_title": "Titre d'emploi",
            "label": "Étiquette",
            "status": "Des stands",
            "title": "Fonctionnalité",
            "updated_at": "Dernière modification le"
          },
          "title": "Missions correspondantes"
        },
        "list": {
          "assignment_note": "<span class='font-bold'>Veuillez noter :</span> Tous les candidats n'ont pas reçu un ACT et un WPV. <a href='%{assignment_link}' class='font-bold underline hover:text-blue-light' data-turbo-frame='_top'>Cliquez ici</a> pour passer immédiatement un test d'intelligence (ACT) et une compétence test (WPV) à attribuer.<br> Nous veillons au bon déroulement de ce processus et évitons les doubles affectations.",
          "name": "Nom"
        },
        "new": {
          "title": "Rédiger un devoir de correspondance"
        },
        "show": {
          "education_level": "Niveau d'éducation mesuré",
          "title": "Résultats d'Appariement"
        },
        "tooltip": {
          "start_comparison": "Comparez les candidats"
        },
        "update": {
          "success": "La modification de l'affectation correspondante a réussi",
          "title": "Modifier la commande correspondante"
        }
      },
      "matching_results": {
        "index": {
          "education_level": "Niveau d'éducation mesuré",
          "list": {
            "competencies": "Compétences",
            "education_level": "Niveau d'éducation mesuré",
            "matching_score": "Score correspondant",
            "name": "Nom"
          },
          "title": "Mission de correspondance"
        },
        "list": {
          "assignment_note": "<span class='font-bold'>Remarque :</span> tous les candidats n'ont pas été affectés à des évaluations. <a href='%{assignment_link}' class='font-bold underline hover:text-blue-light' data-turbo-frame='_top'>Cliquez ici</a> pour attribuer directement des avis.",
          "name": "Nom"
        }
      },
      "results_overview": {
        "index": {
          "title": "Aperçu des résultats"
        }
      },
      "title": "Compare & Select"
    },
    "sessions": {
      "create": {
        "combination_does_not_exist": "Votre adresse e-mail ou votre mot de passe est incorrect"
      },
      "destroy": {
        "logout": "Vous vous êtes déconnecté avec succès"
      },
      "new": {
        "forgot_your_password": "Vous avez oublié votre mot de passe?",
        "help_text": "Bienvenue sur la page de connexion du Process Toolkit",
        "new_login": "Se connecter",
        "title": "se connecter"
      },
      "verify_otp": {
        "invalid": "Le code fourni était incorrect"
      }
    },
    "shared": {
      "accounts": {
        "suppressed_email": "L'adresse e-mail associée à ce compte <span class=\"font-medium\">n'est pas accessible</span>.",
        "suppressed_email_tooltip": "L'adresse e-mail associée à ce compte est inaccessible.",
        "unconfirmed_email": {
          "unconfirmed_email": "L'adresse e-mail sera modifiée en :",
          "unconfirmed_email_explanation": "Le propriétaire de l'adresse e-mail doit confirmer le changement via un lien. L'adresse e-mail modifiée ne sera utilisée qu'après confirmation."
        }
      },
      "candidate_result_widgets": {
        "personal_information": {
          "date_of_birth": "Date de naissance",
          "education": "Niveau d'éducation",
          "email": "Adresse e-mail",
          "most_recent_work_experience": "Expérience professionnelle la plus récente",
          "study_subject": "Cours",
          "title": "Données personnelles",
          "years": {
            "one": "%{years} ans",
            "other": "%{years} ans"
          },
          "years_of_experience": "des années d'expérience"
        }
      },
      "enrollment_sidebar": {
        "advisor": "Entraîneur",
        "candidate": "Candidat",
        "coadvisor": "Cocoach",
        "comments": "Informer",
        "contact_person": "Contact",
        "room": "Appel vidéo",
        "track": "Procédure"
      },
      "forms": {
        "password": {
          "hide_password": "cacher le mot de passe",
          "show_password": "Montrer le mot de passe"
        }
      },
      "header": {
        "admin": "Administrateur",
        "advisors": "Conseillers",
        "all_organizations": "Toutes les organisations",
        "all_users": "Tous les utilisateurs",
        "approval_requests": "Accords",
        "blog": "Blog",
        "candidates": "Candidats",
        "clients": "Clientèle",
        "enrollers": "Liens d'inscription",
        "login": "se connecter",
        "organization": "Organisation",
        "prices": "Des prix",
        "products": "Modules",
        "programs": "Développer des processus",
        "rooms": "Appels vidéo",
        "settings": "Établissements",
        "subscription_types": "Types d'abonnement",
        "tasks": "Tâches",
        "tracks": "Trajectoires",
        "translations": "Traductions",
        "users": "Utilisateurs"
      },
      "header_profile": {
        "log_in_as": "Se connecter en tant que:",
        "log_in_at": "Se connecter à:",
        "logout": "Se déconnecter",
        "my_tracks": "Mes voyages",
        "profile": "Profil"
      },
      "notifications_sidebar": {
        "help_text": "Filtrez votre liste de tâches avec les options ci-dessous :",
        "title": "Filtres"
      },
      "organization_sidebar": {
        "clients_help_text": "Filtrez vos clients avec les options ci-dessous :",
        "labels": "Étiquettes",
        "programs_help_text": "Filtrez vos processus avec les options ci-dessous :",
        "title": "Filtres",
        "users_help_text": "Filtrez vos utilisateurs avec les options ci-dessous :",
        "your_own_organization": "Votre organisation"
      },
      "rebound_email_banner": {
        "action_label": "Changer l'adresse email",
        "description": "Un e-mail envoyé à [[email]] n'a pas pu être livré. C'est pourquoi : [[raison]]",
        "fallback_description": "Un e-mail récemment envoyé à [[email]] n'a pas pu être livré.",
        "title": "Mettez à jour votre adresse e-mail"
      },
      "suppressed_email": "L'adresse e-mail associée à ce compte <span class=\"font-medium\">n'est pas accessible</span>.",
      "suppressed_email_tooltip": "L'adresse e-mail associée à ce compte n'est pas accessible.",
      "tracks_sidebar": {
        "filters": {
          "client": "Du client",
          "label": "Étiquettes",
          "process": "Processus",
          "role": "Votre rôle dans le processus",
          "status": "Statut"
        },
        "help_text": "Filtrez vos candidats avec les options ci-dessous :",
        "title": "Filtres"
      },
      "user_sidebar": {
        "my_track": "Mon itinéraire",
        "new_track": "Créer une trajectoire",
        "show_deactivated": "Afficher désactivé",
        "track_of": "Trajectoire de %{name}",
        "track_with": "Trajectoire avec %{name}"
      }
    },
    "sidebar": {
      "close": "Fermer la barre latérale"
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Veuillez examiner les problèmes ci-dessous :"
      },
      "include_blank": {
        "default_mail_template": {
          "no_default": "Non"
        },
        "select_option": "fais ton choix"
      },
      "labels": {
        "advisor": {
          "avatar": "Image de profil",
          "biography": "Description personnelle",
          "can_impersonate": "Peut se connecter en tant qu'utilisateur différent",
          "consent_given": "Autorisation",
          "cost_center_id": "Centre de coûts",
          "current_password": "Mot de passe actuel",
          "first_name": "Prénom",
          "id": "Contact",
          "invite_immediately": "Envoyez immédiatement un e-mail de bienvenue",
          "label": "Étiquettes",
          "label_ids": "Étiquettes",
          "labels": "Étiquettes",
          "language": "Langue",
          "last_name": "nom de famille",
          "mailing_frequency": "Fréquence des e-mails",
          "new_password": "Nouveau mot de passe",
          "organization": "Organisation",
          "organization_language": "Organisation linguistique",
          "organization_name": "nom de l'organisation",
          "otp_code": "Code",
          "privacy_accepted": "Conditions / Confidentialité",
          "remove_avatar": "Effacez cette photo",
          "role": "Rouler",
          "roles": "Rouler",
          "status": "Statut",
          "type": "Type d'utilisateur",
          "unconfirmed_email": "E-mail"
        },
        "assignment": {
          "deadline": "Date limite d'évaluation",
          "invited_at": "Date d'invitation",
          "mail_template_id": "E-mail d'invitation"
        },
        "authentication_action": {
          "auth_action": "Type d'authentification"
        },
        "candidate": {
          "account_email": "Adresse e-mail",
          "advisor": "Conseiller",
          "advisor_id": "Conseiller",
          "client": "Clientèle",
          "client_ids": "Clientèle",
          "coadvisor": "Co-conseiller",
          "coadvisor_ids": "Co-conseillers",
          "coadvisors": "Co-conseiller",
          "contact_person": "Contacts",
          "contact_person_ids": "Contacts",
          "contact_persons": "Contacts",
          "cost_center_id": "Centre de coûts",
          "created_at": "Date d'enregistrement",
          "deadline": "Date limite d'évaluation",
          "end_date": "Date de fin",
          "first_name": "Prénom",
          "invitation_date": "Date d'invitation",
          "invite_immediately": "Inviter directement",
          "label": "Étiquettes",
          "label_ids": "Étiquettes",
          "labels": "Étiquettes",
          "language": "Langue",
          "last_name": "nom de famille",
          "mail_template_id": "E-mail d'invitation",
          "organization": "Organisation",
          "program": "Processus",
          "report_objective": "Objectif du reporting",
          "start_date": "Date de début",
          "status": "Statut"
        },
        "candidate_assessment": {
          "document_options": "Options de rapport",
          "norm_group_uuid": "Objectif du reporting",
          "report_to": "Résultats disponibles pour la première fois pour",
          "selected_report_id": "Rapport"
        },
        "candidate_assessment_program": {
          "document_options": "Options de rapport",
          "norm_group_uuid": "Objectif du reporting",
          "report_to": "Résultats disponibles pour la première fois pour",
          "selected_report_id": "Rapport"
        },
        "candidate_matching_assignment": {
          "education_level": "Niveau d'éducation",
          "no_score": "Pas de score",
          "score": "Score"
        },
        "candidate_report": {
          "candidate_assessment_program_id": "Programme d'évaluation",
          "document_options": "Options de rapport",
          "norm_group_id": "Objectif du reporting",
          "report_id": "Rapport",
          "reportable": "Assessment"
        },
        "client": {
          "label_ids": "Étiquettes",
          "name": "Nom"
        },
        "component": {
          "alt_text": "Description de l'image pour les lecteurs d'écran",
          "bov_editable_by": "BOV peut être démarré par",
          "can_approve": "À approuver par",
          "cc_load_policy": "Afficher immédiatement les sous-titres (dans votre propre langue) pendant la lecture",
          "controls": "Afficher la barre de contrôle en bas pendant la lecture",
          "description_finished": "Description supplémentaire une fois terminé",
          "editable_by": "Répondable par",
          "html": "Code HTML",
          "iframe_title": "Titre de la vidéo pour les lecteurs d'écran",
          "label": "Donnez une étiquette au champ",
          "max_selections": "Nombre maximum de sélections",
          "options": "Les choix",
          "question": "Demander",
          "rel": "Afficher également les vidéos associées d'autres chaînes après la lecture",
          "report_visible_for": "Résultat visible avant",
          "required": "Il faut répondre par « Oui » pour continuer",
          "sections": "Sections",
          "task_key": "Évaluation sélectionnée",
          "template_can_be_switched_by": "Modèle interchangeable par",
          "url": "URL de la vidéo YouTube",
          "visible_for": "Document visible par",
          "visible_to_candidate": "Rendre également l'élément visible au candidat",
          "visible_to_contact_person": "Partager automatiquement le document avec les contacts après approbation"
        },
        "contact_person": {
          "avatar": "Image de profil",
          "biography": "Description personnelle",
          "can_impersonate": "Peut se connecter en tant qu'utilisateur différent",
          "client": "Client",
          "consent_given": "Autorisation",
          "cost_center_id": "Centre de coûts",
          "current_password": "Mot de passe actuel",
          "first_name": "Prénom",
          "id": "Contact",
          "invite_immediately": "Envoyez immédiatement un e-mail de bienvenue",
          "label": "Étiquettes",
          "label_ids": "Étiquettes",
          "labels": "Étiquettes",
          "language": "Langue",
          "last_name": "nom de famille",
          "mailing_frequency": "Fréquence des e-mails",
          "new_password": "Nouveau mot de passe",
          "organization": "Organisation",
          "organization_language": "Organisation linguistique",
          "organization_name": "nom de l'organisation",
          "otp_code": "Code",
          "privacy_accepted": "Conditions / Confidentialité",
          "remove_avatar": "Effacez cette photo",
          "role": "Rouler",
          "status": "Statut",
          "type": "Type d'utilisateur",
          "unconfirmed_email": "E-mail"
        },
        "cost_center": {
          "identifier": "Nom"
        },
        "defaults": {
          "account_email": "Adresse e-mail",
          "advisor_id": "Conseiller",
          "candidate_id": "Candidat",
          "client_id": "Client",
          "coadvisor_id": "Co-conseiller",
          "contact_person_id": "Contact",
          "description": "Description",
          "email": "Adresse e-mail",
          "end_date": "Date de fin",
          "explanation": "Explication",
          "first_name": "Prénom",
          "language": "Langue de candidature",
          "last_name": "nom de famille",
          "name": "Nom",
          "organization_id": "Organisation",
          "password": "mot de passe",
          "product_id": "Modules",
          "program_id": "Processus",
          "start_date": "Date de début",
          "title": "Titre",
          "two_factor": "Vérification en deux étapes"
        },
        "discount": {
          "end_date": "Date de fin",
          "kind": "Sur",
          "organization": "Organisation",
          "organization_id": "Organisation",
          "percentage": "Pourcentage",
          "start_date": "Date de début"
        },
        "enroller": {
          "advisor": "Entraîneur",
          "advisor_id": "Entraîneur",
          "allow_repeated": "Autoriser les réinscriptions",
          "link": "Lien",
          "program": "Processus",
          "program_id": "Processus",
          "show_default": "Afficher le texte par défaut"
        },
        "enrollment": {
          "coadvisor_id": "Cocoach",
          "invite_immediately": "Inviter directement",
          "program_id": "Processus"
        },
        "entry": {
          "identifier": "identifiant",
          "type": "Taper"
        },
        "import/file": {
          "created_at": "ajoutée",
          "imported_rows": "lignes importées",
          "name": "Nom",
          "organization": "organisation",
          "organization_id": "Organisation",
          "state": "importation de statut",
          "total_rows": "nombre total de lignes",
          "type": "Taper"
        },
        "import_file": {
          "created_at": "ajoutée",
          "download_example": "Télécharger un exemple de fichier",
          "imported_rows": "lignes importées",
          "name": "Nom",
          "organization": "organisation",
          "organization_id": "Organisation",
          "state": "importation de statut",
          "total_rows": "nombre total de lignes",
          "type": "Taper"
        },
        "ixly_employee": {
          "avatar": "Image de profil",
          "biography": "Description personnelle",
          "can_impersonate": "Peut se connecter en tant qu'utilisateur différent",
          "consent_given": "Autorisation",
          "cost_center_id": "Centre de coûts",
          "current_password": "Mot de passe actuel",
          "first_name": "Prénom",
          "id": "Contact",
          "invite_immediately": "Envoyez immédiatement un e-mail de bienvenue",
          "label": "Étiquettes",
          "language": "Langue",
          "last_name": "nom de famille",
          "mailing_frequency": "Fréquence des e-mails",
          "new_password": "Nouveau mot de passe",
          "organization": "Organisation",
          "organization_language": "Organisation linguistique",
          "organization_name": "nom de l'organisation",
          "otp_code": "Code",
          "privacy_accepted": "Conditions / Confidentialité",
          "remove_avatar": "Effacez cette photo",
          "role": "Rouler",
          "roles": "Rouler",
          "status": "Statut",
          "type": "Type d'utilisateur",
          "unconfirmed_email": "E-mail"
        },
        "job_profile": {
          "competencies": "Compétences",
          "education_level": "Niveau d'études",
          "job_profile": "Profil de l'emploi",
          "label": "Étiquettes",
          "label_ids": "Étiquettes",
          "title": "Titre d'emploi",
          "user": "Utilisateur"
        },
        "join": {
          "display_name": "Afficher un nom",
          "start_hidden": "Commencer caché",
          "start_muted": "Commencer en sourdine"
        },
        "leniency": {
          "credits": "Crédits",
          "reason": "Raison"
        },
        "mail_template": {
          "body": "Texte",
          "language": "Langue",
          "subject": "Sujet",
          "title": "Titre"
        },
        "matching_assignment": {
          "education_level": "Niveau d'études",
          "job_profile": "Profil de l'emploi",
          "job_profile_competencies": "Compétences",
          "job_profile_education_level": "Niveau d'études",
          "job_profile_title": "Profil de l'emploi",
          "label": "Étiquettes",
          "label_ids": "Étiquettes",
          "matching_score": "Score correspondant",
          "no_score": "Pas de score",
          "title_description": "Fonctionnalité",
          "user": "Utilisateur",
          "vacancy_number_placeholder": "Numéro de poste vacant"
        },
        "media_item": {
          "advisor": "Conseiller",
          "candidate": "Candidat",
          "contact_person": "Contact"
        },
        "organization": {
          "account_manager_id": "Gestionnaire de compte",
          "address": "Adresse",
          "advisor_can_create_candidates": "Les conseillers peuvent créer des candidats",
          "advisor_can_manage_existing_candidates": "Les conseillers peuvent voir et mettre à jour tous les candidats",
          "advisor_can_manage_users": "Les conseillers peuvent créer des coachs de processus et des contacts",
          "advisor_can_remove_candidates": "Les conseillers peuvent supprimer des candidats",
          "advisor_can_view_enrollers": "Les coachs de processus peuvent consulter les liens d’inscription",
          "advisor_can_view_prices": "Les conseillers peuvent voir les prix",
          "advisor_deactivation_period": "Période de désactivation pour les conseillers",
          "advisor_rooms_permission": "Les coachs de processus peuvent visualiser les appels vidéo",
          "allow_compare_select": "Autoriser Compare & Select",
          "allow_impersonation": "Autoriser les utilisateurs à se connecter en tant que",
          "allow_ixly_support": "Autoriser le support Ixly",
          "api_anonymous": "API anonyme",
          "api_key": "Clé API",
          "api_organization_id": "uuid de l'organisation de la boîte à outils de test",
          "api_user": "Courrier utilisateur de l'API",
          "available_languages": "Langues disponibles",
          "brand_logo": "Logo",
          "candidate_deactivation_period": "Période de désactivation pour les candidats",
          "city": "Ville",
          "clients_enabled": "Les clients et les personnes de contact sont engagés pour l'organisation",
          "country": "Pays",
          "credit_bundle": "Paquet",
          "default_language": "Langue standard",
          "default_report_objective": "Valeur par défaut pour « Objectif du rapport »",
          "default_report_to": "Résultats disponibles pour la première fois pour",
          "has_dashboard_link": "Les conseillers ont un lien vers l'application Dashboard",
          "interactive_button_color": "Icônes de couleur",
          "interactive_header_color": "Couleur secondaire",
          "interactive_header_text_color": "Couleur du texte du titre de la page",
          "interactive_primary_color": "Couleur primaire",
          "invoice_company_name": "Nom de l'entreprise facturée",
          "invoice_company_name_placeholder": "Laisser vide comme le nom de l'organisation",
          "invoice_email": "Adresse email pour les factures",
          "invoice_remarks": "Commentaires sur la facture",
          "invoice_remarks_placeholder": "Affiché sous la facture",
          "name": "nom de l'organisation",
          "organization_manager_can_view_enrollments": "Les responsables de l'organisation peuvent visualiser les processus de tous les candidats",
          "payment_type": "Mode de paiement",
          "postal_code": "Code postal",
          "privacy_link": "Confidentialité",
          "profile_picture_disclaimer": "Avis de non-responsabilité sous forme de texte",
          "remove_logo": "Supprimer le logo",
          "remove_report_cover_logo": "Supprimer le logo de la couverture du rapport",
          "remove_report_header_logo": "Supprimer le logo de l'en-tête du rapport",
          "report_cover_logo": "Logo de couverture pour le rapport",
          "report_header_logo": "Logo de couverture pour le rapport",
          "retention_period": "Durée de conservation des données personnelles",
          "show_interactive_results": "Montrer les résultats",
          "show_posts": "Afficher le blog",
          "status": "Statut",
          "subscription_type": "Abonnement",
          "terms_link": "Conditions",
          "two_factor": "Exiger une validation en deux étapes pour ces rôles",
          "vat_number": "Numéro d'identification TVA",
          "welcome_mail_subject": "Objet de l'e-mail de bienvenue",
          "welcome_mail_text": "Texte de l'e-mail de bienvenue",
          "whitelabeled": "En marque blanche"
        },
        "organization_default_mail_template": {
          "mail_template_id": "Modèle d'e-mail standard"
        },
        "post_rule": {
          "label_ids": "Étiquettes",
          "program_ids": "Processus",
          "roles": "Rôles des utilisateurs"
        },
        "premium_candidate": {
          "coadvisor_ids": "Co-conseillers",
          "contact_person_ids": "Contacts",
          "label_ids": "Étiquettes"
        },
        "price": {
          "amc": "Prix AMC, €",
          "bov": "Prix BOV, €",
          "external_user": "Cotisation mensuelle par candidat, €",
          "fit": "Prix FIT, €",
          "internal_user": "Tarif mensuel par coach, €",
          "job_market_scan": "Opportunités de prix sur le marché du travail, €",
          "job_suggestions": "Prix des suggestions d'emploi, €",
          "jobfeed": "Prix du flux d'emploi, €",
          "start_date": "À compter de"
        },
        "product": {
          "name": "Nome"
        },
        "product_program_templates": {
          "ids": "Modèles de processus"
        },
        "product_tasks": {
          "ids": "Évaluations"
        },
        "program": {
          "invite_mail_subject": "Objet de l'e-mail d'invitation",
          "invite_mail_text": "E-mail d'invitation par SMS",
          "label": "Étiquettes",
          "language": "Langue",
          "status": "Statut",
          "step_order": "Ordre des étapes",
          "type": "Taper"
        },
        "program_page": {
          "approve_by": "Approbation par",
          "has_comments": "Afficher les messages en bas de l'étape",
          "hidden_for_candidate": "Masquer l'étape au candidat",
          "optional": "Rendre l'étape facultative (insérable après affectation)"
        },
        "program_template": {
          "invite_mail_subject": "Objet de l'e-mail d'invitation",
          "invite_mail_text": "E-mail d'invitation par SMS",
          "label": "Étiquettes",
          "status": "Statut",
          "step_order": "Ordre des étapes",
          "type": "Taper"
        },
        "report_objectives": {
          "advice": "Conseil",
          "other": "Autrement",
          "selection": "Sélection"
        },
        "room": {
          "end_to_end_encryption": "Chiffrement de bout en bout",
          "everyone_starts_hidden": "Tout le monde commence caché",
          "everyone_starts_muted": "Tout le monde commence en sourdine",
          "link": "Lien",
          "only_managers_can_join": "Seuls les responsables de l'organisation peuvent participer",
          "only_managers_can_kick": "Seuls les responsables de l'organisation peuvent en supprimer d'autres",
          "only_managers_can_mute": "Seuls les responsables de l'organisation peuvent mettre les autres en sourdine",
          "subject": "Sujet"
        },
        "subscription": {
          "annual_fee": "Frais d'abonnement annuel",
          "end_date": "Date de fin",
          "start_date": "Date de début",
          "subscription_type": "Formulaire d'inscription",
          "subscription_type_id": "Formulaire d'inscription"
        },
        "subscription_type": {
          "can_downgrade_to": "Peut revenir au formulaire d'abonnement",
          "can_upgrade_to": "Peut passer au formulaire d'abonnement",
          "candidate_price": "Coûts annuels par candidat pour des évaluations illimitées",
          "credit_price": "Prix par crédit",
          "credits_notice": "Quelques crédits sur le rapport à",
          "external_user": "Coûts mensuels par candidat",
          "internal_user": "Coûts mensuels par autocar",
          "invoice_line_type": "Catégorie comptable",
          "max_candidates": "Nombre maximum de candidats actifs",
          "max_coaches": "Nombre maximum de coachs actifs",
          "max_users": "Nombre maximum de coachs actifs",
          "month_price": "Frais d'abonnement mensuels",
          "payment_period": "Délai de paiement",
          "prepay": "Payé à l'avance",
          "prepay_credits": "Forfaits de crédits disponibles",
          "type": "Catégorie"
        },
        "task": {
          "language": "Langue"
        },
        "translation": {
          "identifier": "identifiant"
        },
        "user": {
          "avatar": "Image de profil",
          "biography": "Description personnelle",
          "can_impersonate": "Peut se connecter en tant qu'utilisateur différent",
          "consent_given": "Autorisation",
          "cost_center_id": "Centre de coûts",
          "current_password": "Mot de passe actuel",
          "first_name": "Prénom",
          "invite_immediately": "Envoyez immédiatement un e-mail de bienvenue",
          "label": "Étiquettes",
          "language": "Langue",
          "last_name": "nom de famille",
          "mailing_frequency": "Fréquence des e-mails",
          "new_password": "Nouveau mot de passe",
          "organization": "Organisation",
          "organization_language": "Organisation linguistique",
          "organization_name": "nom de l'organisation",
          "otp_code": "Code",
          "privacy_accepted": "Conditions / Confidentialité",
          "remove_avatar": "Effacez cette photo",
          "role": "Rouler",
          "status": "Statut",
          "type": "Type d'utilisateur",
          "unconfirmed_email": "E-mail"
        },
        "whitelabel": {
          "domain": "Domaine",
          "subdomain": "Sous-domaine"
        }
      },
      "options": {
        "component": {
          "bov_editable_by": {
            "both": "Les deux (le coach ne peut commencer qu'après le départ du candidat)",
            "candidate": "Le candidat"
          },
          "can_approve": {
            "candidate": "Le candidat",
            "contact_person": "Le contact"
          },
          "editable_by": {
            "advisor": "L'entraîneur",
            "both": "Les deux",
            "candidate": "Le candidat"
          },
          "report_visible_for": {
            "advisor": "L'entraîneur",
            "both": "Les deux",
            "candidate": "Le candidat"
          },
          "template_can_be_switched_by": {
            "advisor": "L'entraîneur",
            "both": "Les deux",
            "candidate": "Le candidat",
            "creator": "Personne"
          },
          "visible_for": {
            "advisor": "L'entraîneur",
            "both": "Les deux",
            "candidate": "Le candidat"
          }
        },
        "discount": {
          "kind": {
            "amc": "PrixAMC",
            "bov": "Prix BOV",
            "candidate_price": "Coûts annuels par candidat pour des évaluations illimitées",
            "credit_price": "Prix par crédit",
            "external_user": "Coûts mensuels par candidat",
            "fit": "Prix CONVENABLE",
            "internal_user": "Coûts mensuels par autocar",
            "job_market_scan": "Prix des opportunités sur le marché du travail",
            "job_suggestions": "Suggestions d'emplois",
            "jobfeed": "Prix Jobfeed",
            "month_price": "Frais d'abonnement mensuels"
          }
        },
        "organization": {
          "advisor_deactivation_period": {
            "never": "Jamais"
          },
          "payment_type": {
            "automatic": "Automatiquement",
            "invoice": "Facture"
          },
          "two_factor": {
            "Advisor": "Conseiller",
            "ContactPerson": "Contact",
            "OrganizationManager": "Responsable d'organisation",
            "User": "Candidat"
          }
        },
        "program": {
          "step_order": {
            "approval": "Le candidat ne peut procéder qu'après approbation de l'étape en cours",
            "free": "Le candidat peut suivre les étapes dans n'importe quel ordre",
            "submission": "Le candidat ne peut continuer qu'après avoir soumis l'étape en cours"
          }
        },
        "program_page": {
          "approve_by": {
            "none": "L'étape n'a pas besoin d'être approuvée",
            "seen": "L'étape est automatiquement approuvée lorsque le candidat la consulte",
            "set": "Le coach et le candidat peuvent tous deux approuver la démarche",
            "submit": "Le coach valide l'étape après soumission par le candidat"
          }
        },
        "subscription_type": {
          "invoice_line_type": {
            "frequent": "Fréquemment",
            "intensive": "Intensif"
          },
          "payment_period": {
            "month": "Mois",
            "quarter": "Quart",
            "year": "année"
          },
          "type": {
            "AllYouCanSubscription": "Signaler à volonté",
            "AnnualSubscription": "Annuel",
            "DemoSubscription": "Démo",
            "IncidentalSubscription": "Accessoire",
            "ManualSubscription": "Manuellement",
            "ResearchSubscription": "Recherche",
            "StandardSubscription": "Standard",
            "TestSubscription": "Test"
          }
        },
        "user": {
          "mailing_frequency": {
            "every_morning": "Collectez des notifications et envoyez-les par e-mail tous les matins.",
            "immediately": "Recevez des notifications immédiatement.",
            "never": "Ne recevez jamais de notifications par e-mail.",
            "once_an_hour": "Collectez des notifications et envoyez-les par e-mail toutes les heures."
          },
          "type": {
            "Advisor": "Conseiller",
            "ContactPerson": "Contact",
            "IxlyEmployee": "Employé Ixly",
            "User": "Candidat"
          }
        }
      },
      "placeholders": {
        "search_by_name": "Rechercher par nom",
        "search_by_title": "Recherche par titre",
        "search_for_coadvisor_ids": "Co-conseiller en recherche",
        "search_for_competencies": "Compétences de recherche",
        "search_for_contact_person_ids": "Trouver une personne à contacter",
        "search_for_job_profile_competencies": "Compétences de recherche",
        "search_for_label_ids": "Rechercher une étiquette",
        "search_for_labels": "Rechercher une étiquette",
        "search_for_options": "Options de recherche",
        "user": {
          "edit": {
            "biography": "Le texte saisi ici sera montré à toute personne qui consulte votre profil"
          }
        }
      },
      "required": {
        "mark": "*",
        "text": "Ceci est un champ obligatoire"
      }
    },
    "skiplink": "Passer au contenu principal",
    "status": {
      "advisor": {
        "active": "Actif",
        "created": "Créé",
        "inactive": "Inactif",
        "invited": "Invité"
      },
      "candidate": {
        "active": "Actif",
        "created": "Créé",
        "inactive": "Inactif",
        "invited": "Invité"
      },
      "candidate_email_message": {
        "bounced": "Ne peut pas être livré",
        "delivered": "Livré",
        "loading": "Chargement",
        "sent": "Envoyé",
        "transient": "Problèmes possibles à la livraison",
        "unsubscribed": "Désabonné"
      },
      "contact_person": {
        "active": "Actif",
        "created": "Créé",
        "inactive": "Inactif",
        "invited": "Invité"
      },
      "enroller": {
        "active": "Actif",
        "created": "Créé",
        "deactivated": "Désactivé",
        "inactive": "Inactif"
      },
      "enrollment": {
        "action_required": "Action requise",
        "created": "Créé",
        "finished": "arrondi",
        "invited": "Invité",
        "started": "Commencé"
      },
      "enrollment_page": {
        "created": "Créé",
        "done": "arrondi",
        "rejected": "Refusé",
        "submitted": "Soumis"
      },
      "import_file": {
        "created": "Créé",
        "failed": "Échoué",
        "processed": "Incorporé",
        "validating": "Valider"
      },
      "mail_template": {
        "draft": "Conception",
        "published": "Publié"
      },
      "organization": {
        "active": "Actif",
        "created": "Créé",
        "deleted": "Supprimé",
        "inactive": "Inactif"
      },
      "price": {
        "active": "Actif",
        "expired": "Expiré",
        "pending": "Pas encore actif"
      },
      "product": {
        "active": "Actif",
        "deactivated": "Désactivé"
      },
      "program": {
        "deleted": "Supprimé",
        "editable": "Modifiable",
        "published": "Publié",
        "template": "Modèle"
      },
      "program_template": {
        "deleted": "Supprimé",
        "editable": "Modifiable",
        "published": "Publié",
        "template": "Modèle"
      },
      "ttk_migration": {
        "completed": "Migration terminée",
        "created": "Créé",
        "failed": "La migration échoue",
        "ready_to_migrate": "Prêt à migrer",
        "syncing_default_reports": "Migrer les rapports standards",
        "syncing_settings": "Migrer les paramètres",
        "syncing_users_candidates": "Migrer les comptes",
        "updating_managed_via_ap": "Migration complète",
        "updating_manager": "Configurer le gestionnaire d'organisation"
      },
      "user": {
        "active": "Actif",
        "created": "Créé",
        "inactive": "Inactif",
        "invited": "Invité"
      }
    },
    "subscription_type_product_status": {
      "active": "Actif",
      "deactivated": "Désactivé"
    },
    "subscription_type_products": {
      "create": {
        "created": "Les modules ont été ajoutés au formulaire d'inscription",
        "products_blank": "Aucun module n'a été sélectionné"
      },
      "deactivate": {
        "deactivated": "La connexion au module est désactivée"
      },
      "destroy": {
        "deleted": "Le module a été supprimé du formulaire d'abonnement"
      },
      "list": {
        "table_header": [
          "Nom",
          "Lien",
          "Actions"
        ]
      },
      "new": {
        "title": "Ajouter des modules à %{subscription_type}"
      },
      "reactivate": {
        "reactivated": "La connexion au module a été réactivée"
      },
      "row": {
        "product_delete": "Etes-vous sûr de vouloir supprimer ce module du formulaire d'abonnement ?"
      }
    },
    "subscription_type_status": {
      "active": "Actif",
      "deactivated": "Désactivé"
    },
    "subscription_types": {
      "create": {
        "subscription_type_created": "Le formulaire d'abonnement a été créé",
        "title": "Nouveau formulaire d'abonnement"
      },
      "deactivate": {
        "deactivated": "Le formulaire d'abonnement a été désactivé"
      },
      "destroy": {
        "deleted": "Le formulaire d'abonnement a été supprimé"
      },
      "edit": {
        "title": "Modifier le formulaire d'abonnement"
      },
      "index": {
        "new_button": "Nouveau formulaire d'abonnement",
        "table_header": [
          "Nom",
          "Statut",
          "Statut modifié le",
          "Abonnements actifs",
          "Catégorie",
          "Actions"
        ],
        "title": "Types d'abonnement"
      },
      "new": {
        "title": "Nouveau formulaire d'abonnement"
      },
      "reactivate": {
        "reactivated": "Le formulaire d'abonnement a été réactivé"
      },
      "row": {
        "subscription_type_delete": "Êtes-vous sûr de vouloir supprimer ce formulaire d'abonnement ?"
      },
      "show": {
        "disabled": "non",
        "enabled": "Oui",
        "product_new_button": "Ajouter des modules",
        "status": "Statut"
      },
      "update": {
        "subscription_type_updated": "Le formulaire d'abonnement a été mis à jour",
        "title": "Modifier le formulaire d'abonnement"
      }
    },
    "support": {
      "array": {
        "last_word_connector": " et",
        "two_words_connector": " et",
        "words_connector": ","
      }
    },
    "templates": {
      "document": "document"
    },
    "time": {
      "am": "am",
      "formats": {
        "date_with_day": "%A, %e %B %Y",
        "date_with_time": "%e %B %Y, %H:%M",
        "date_with_time_short": "%e %b %Y, %H:%M",
        "date_without_day": "%e %B %Y",
        "date_without_day_and_year": "%d %b",
        "date_without_time": "%d-%m-%Y",
        "default": "%d %B %Y %Hh %Mmin %Ss",
        "long": "%A %d %B %Y %Hh%M",
        "short": "%d %b %Hh%M",
        "time_only": "%H:%M",
        "time_with_date": "%H:%M (%e %B %Y)"
      },
      "pm": "pm"
    },
    "translations": {
      "actions": "Actions",
      "edit": "Modifier la traduction",
      "new": "Nouvelle traduction",
      "show": "Voir la traduction",
      "source": "Source",
      "translation_created": "La traduction a été créée",
      "translation_destroyed": "La traduction a été supprimée",
      "translation_updated": "La traduction a été mise à jour"
    },
    "two_factor": {
      "activate_help_text": "Scannez ce code QR avec votre application d'authentification préférée (par exemple Google Authenticator ou Authy) et saisissez le code pour activer la vérification en 2 étapes.",
      "title": "Vérification en deux étapes",
      "verify_help_text": "Entrez le code fourni par l'application d'authentification"
    },
    "type": {
      "Advisor": "Conseiller",
      "Candidate": "Candidat",
      "CandidateAssessmentEntry": "Évaluation",
      "CandidateAssessmentProgramEntry": "Programme d'évaluation",
      "CandidateReportEntry": "Rapport (test uniquement)",
      "Cocoach": "Co-conseiller",
      "ContactPerson": "Contact",
      "GeneratedReportEntry": "Rapport (Processus)",
      "IxlyEmployee": "Employé Ixly",
      "LeniencyEntry": "Gentillesse",
      "OrderEntry": "Commande",
      "PremiumCandidate": "Candidat",
      "TestOnlyCandidate": "Candidat",
      "User": "Candidat",
      "plural": {
        "Advisor": "Conseillers",
        "Candidate": "Candidats",
        "ContactPerson": "Contacter des personnes",
        "PremiumCandidate": "Candidats",
        "TestOnlyCandidate": "Candidats"
      }
    },
    "unauthorized": {
      "manage": {
        "all": "Vous ne disposez pas des autorisations appropriées pour ce faire"
      }
    },
    "user_types": {
      "Advisor": "Conseiller",
      "Candidate": "Candidat",
      "Co_coach": "Co-conseiller",
      "Coach": "Conseiller",
      "ContactPerson": "Contact"
    },
    "users": {
      "all": {
        "new_button": "Nouvel utilisateur",
        "table_header": [
          "Nom",
          "Organisation",
          "Taper",
          "Rouler",
          "Statut",
          "Centre de coûts"
        ],
        "title": "Tous les utilisateurs"
      },
      "confirmations": {
        "form": {
          "privacy_accepted_label": "J'accepte les <a href='%{terms_url}' target='_blank' class='underline hover:text-blue-light'>conditions générales</a> et les <a href='%{privacy_statement_url}' target='_blank' class='underline hover:text-blue-light'>déclaration de confidentialité</a>",
          "submit": "Plus loin"
        },
        "show": {
          "already_confirmed_account": "<span class='font-medium'>Remarque !</span> Un compte existe déjà avec cette adresse e-mail. Utilisez le même mot de passe pour vous connecter à votre nouveau compte.",
          "already_confirmed_resource": "Vous avez déjà créé un compte. Utilisez votre adresse e-mail et votre mot de passe pour vous connecter.",
          "confirmed": "L'adresse e-mail de votre compte a été confirmée, connectez-vous avec votre compte ici",
          "title": "faire un compte"
        },
        "update": {
          "confirmed": "Votre compte a été créé, connectez-vous avec votre compte ici"
        }
      },
      "create": {
        "created": "L'utilisateur a été créé",
        "created_and_invited": "L'utilisateur a été créé et accueilli par email",
        "help_text": "Créez ici un nouvel utilisateur pour votre organisation",
        "more_information": "Plus d'information",
        "title": "Nouvel utilisateur"
      },
      "deactivate": {
        "user_deactivated": "L'utilisateur a été désactivé"
      },
      "edit": {
        "title": "Modifier l'utilisateur"
      },
      "form": {
        "impersonation_explanation": "Si vous sélectionnez cette option, vous donnez à cet utilisateur le droit de se connecter en tant qu'autre utilisateur.",
        "labels": "Étiquettes",
        "unconfirmed_email": "L'adresse e-mail sera modifiée en :",
        "unconfirmed_email_explanation": "Le propriétaire de l'adresse e-mail doit confirmer le changement via un lien. L'adresse e-mail modifiée ne sera utilisée qu'après confirmation.",
        "upload_avatar": "Cliquez sur l'image pour télécharger votre propre photo",
        "user_type_documentation": "(cliquez ici pour la documentation sur les rôles utilisateur)",
        "your_labels": "Vos étiquettes"
      },
      "index": {
        "help_text": "Vous trouverez ici tous les utilisateurs de votre organisation",
        "import": "Importation CSV",
        "new_button": "Nouvel utilisateur",
        "title": "Utilisateurs"
      },
      "new": {
        "help_text": "Créez ici un nouvel utilisateur pour votre organisation",
        "title": "Nouvel utilisateur"
      },
      "password": {
        "title": "changer le mot de passe"
      },
      "reactivate": {
        "user_reactivated": "L'utilisateur a été réactivé"
      },
      "removed_member": "supprimé %{type}",
      "reset_password": {
        "sent": "L'e-mail de réinitialisation du mot de passe a été envoyé"
      },
      "row": {
        "number_of_assigned_programs": {
          "one": "1 processus",
          "other": "%{count} processus"
        }
      },
      "send_welcome": {
        "bulk_button": "Inviter",
        "welcome_mail_sent": "L'email de bienvenue a été envoyé"
      },
      "show": {
        "account_manager": "Responsable de comptes de",
        "advisor": "Conseiller",
        "biography": "Description personnelle",
        "candidate_overview": "Aperçu des candidats",
        "client": "Client",
        "cost_center": "Centre de coûts",
        "created_at": "Fait sur",
        "disabled": "Éteint",
        "email": "Adresse e-mail",
        "enabled": "Activé",
        "impersonate": "Se connecter en tant que",
        "organization": "Organisation",
        "resend_welcome_email": "Renvoyer l'e-mail de bienvenue",
        "reset_password": "Récupérer mot de passe",
        "send_email": "Envoyer un e-mail",
        "send_welcome_email": "Envoyer un e-mail de bienvenue",
        "status": "Statut",
        "two_factor": "Vérification en deux étapes",
        "type": "Rôle",
        "user_overview": "Aperçu des utilisateurs"
      },
      "update": {
        "title": "Modifier l'utilisateur",
        "updated": "L'utilisateur a été mis à jour"
      }
    },
    "validations": {
      "minlength_12": "au moins douze caractères."
    },
    "valvat": {
      "country_adjectives": {
        "at": "autrichien",
        "be": "belge",
        "bg": "bulgare",
        "cy": "chypriote",
        "cz": "tchèque",
        "de": "allemand",
        "dk": "danois",
        "ee": "estonien",
        "es": "espagnol",
        "eu": "intracommunautaire",
        "fi": "finlandais",
        "fr": "français",
        "gb": "britannique",
        "gr": "grec",
        "ie": "irlandais",
        "it": "italien",
        "lt": "lituanien",
        "lu": "luxembourgeois",
        "lv": "letton",
        "mt": "maltais",
        "nl": "hollandais",
        "pl": "polonais",
        "pt": "portugais",
        "ro": "roumain",
        "se": "suédois",
        "si": "slovène",
        "sk": "slovaque"
      }
    },
    "will_paginate": {
      "next_label": "Suivant →",
      "page_gap": "&hellip;",
      "previous_label": "← Précédent"
    }
  }
}