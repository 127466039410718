import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";

// Connects to data-controller="report-settings"
export default class extends Controller {
  static values = {
    additionalReport: Boolean,
    assessment: Number,
    candidate: String,
    definition: Number,
    index: Number,
    assessableType: String
  }
  static targets = ['options']

  show_report_options_form(event) {
    let report_id = event.target.value;
    let assessableTypeParam = this.assessableTypeValue === 'candidate_assessment_program' ? `candidate_assessment_program_id=${this.assessmentValue}` : `candidate_assessment_id=${this.assessmentValue}`;
    let definitionTypeParam = this.assessableTypeValue === 'candidate_assessment_program' ? `assessment_program_definition_id=${this.definitionValue}` : `assessment_definition_id=${this.definitionValue}`;

    if (report_id !== "") {
      get(`/assessments/report_options/${report_id}/edit?${definitionTypeParam}&${assessableTypeParam}&index=${this.indexValue}&additional_report=${this.additionalReportValue}`, {
        responseKind: "turbo-stream"
      })
    } else {
      this.optionsTarget.innerHTML = ""
    }
  }

  reload_form(event) {
    let assessable_global_id = event.target.value;

    if (assessable_global_id !== "") {
      get(`/assessments/candidates/${this.candidateValue}/candidate_reports/new?reportable=${assessable_global_id}`, {
        responseKind: "turbo-stream"
      })
    }
  }
}
