import { I18n } from 'i18n-js'
import de from './locales/de'
import en from './locales/en'
import fr from './locales/fr'
import it from './locales/it'
import nl from './locales/nl'

const defaultLocale = document.head.querySelector('meta[name="default-locale"]') ? document.head.querySelector('meta[name="default-locale"]').content : 'nl'
const locale = document.head.querySelector('meta[name="locale"]') ? document.head.querySelector('meta[name="locale"]').content : 'nl'

const i18n = new I18n(
    { ...de, ...en, ...fr, ...nl, ...it },
    {
        defaultLocale: defaultLocale,
        locale: locale
    })

window.I18n = i18n
