import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"
import {Turbo} from "@hotwired/turbo-rails"
import { get, post } from "@rails/request.js"

// Connects to data-controller="filterbar"
export default class extends Controller {
  static targets = ['query', 'form']
  static values = { path: String }
  static debounces = ['search']

  connect() {
    useDebounce(this, { wait: 500 })
  }

  search(query) {
    this.queryTarget.value = query
    this.filter()
  }

  filter() {
    this.formTarget.requestSubmit()
  }

  async reset() {
    let path = this.pathValue

    try {
      await post(`${path}/reset`, { responseKind: "turbo-stream" });
      await get(`${path}`, { responseKind: "turbo-stream" });
    } catch (error) {
      console.log('Error:', error);
    }
  }
}
