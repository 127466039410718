import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js"

// Connects to data-controller="overview-list"
export default class extends Controller {
  static outlets = ["selected-row"]
  static targets = ["selectAll"]
  static values = {url: String, params: String}

  toggleBulk() {
    let [overview_counter, checked_element] = this.overviewCounter()
    this.showActionMenu(overview_counter, checked_element)
    this.toggleSelectAllCheckbox()
  }

  selectAll() {
    let checkboxes = this.selectedRowOutlets.map(row => row.checkboxTarget)

    if (this.selectAllTarget.checked) {
      Array.from(checkboxes).forEach(function (element) {
        element.checked = true
      })
    } else {
      Array.from(checkboxes).forEach(function (element) {
        element.checked = false
      })
    }

    this.selectedRowOutlets.forEach(row => row.toggle())

    // There is different select-all handler for candidates page
    const candidatesPageUrls = ['assessments/candidates', 'selection/matching_assignments']
    if (candidatesPageUrls.includes(this.urlValue)) {
      document.dispatchEvent(new CustomEvent("select-all", {
        detail: {
          candidate_uuids: checkboxes.map(checkbox => checkbox.value),
          source_context: event.target.dataset.sourceContext,
          matching_assignment_uuid: event.target.dataset.matchingAssignmentUuid,
          checked: this.selectAllTarget.checked
        }
      }))
    } else {
      this.toggleBulk()
    }
  }

  overviewCounter() {
    let checkboxes = this.selectedRowOutlets.map(row => row.checkboxTarget)
    let overview_counter = 0
    let checked_element_value = undefined

    Array.from(checkboxes).forEach(function (element) {
      if (element.checked) {
        overview_counter += 1
        checked_element_value = element.value
      }
    })

    return [overview_counter, checked_element_value]
  }

  showActionMenu(elements_count, checked_element_value) {
    let path = this.urlValue
    let params = elements_count == 1 ? `&${this.paramsValue}` : `?${this.paramsValue}`

    if (elements_count == 1) {
      let record_id = checked_element_value
      get(`/action_menu/single/${path}?id=${record_id}${params}`, {
        responseKind: "turbo-stream"
      })
    } else if (elements_count > 1) {
      get(`/action_menu/multiple/${path}${params}`, {
        responseKind: "turbo-stream"
      })
    } else if (elements_count == 0) {
      get(`/action_menu/default/${path}${params}`, {
        responseKind: "turbo-stream"
      })
    }
  }

  toggleSelectAllCheckbox() {
    let allCheckboxes = this.selectedRowOutlets.map(row => row.checkboxTarget)
    let checkedCheckboxesSize = allCheckboxes.filter(checkbox => checkbox.checked).length
    let selectAllCheckbox = document.getElementById('select-all')

    if (allCheckboxes.length === checkedCheckboxesSize) {
      selectAllCheckbox.checked = true
    } else {
      selectAllCheckbox.checked = false
    }
  }
}
