{
  "nl": {
    "accounts": {
      "passwords": {
        "form": {
          "login": "Inloggen",
          "submit": "Aanvragen"
        }
      },
      "sessions": {
        "form": {
          "forgot_your_password": "Wachtwoord vergeten?",
          "submit": "Inloggen"
        },
        "new": {
          "title": "Inloggen"
        }
      },
      "switch_users": {
        "success": "Succesvol ingelogd bij %{organization}"
      }
    },
    "action_menu": {
      "admin": {
        "mail_templates": {
          "single": {
            "delete_confirm": "Weet u zeker dat u dit e-mail template wilt verwijderen?"
          }
        }
      },
      "assessments": {
        "clients_contact_persons": {
          "single": {
            "delete_confirm": "Weet u zeker dat u deze contactpersoon wilt verwijderen?"
          }
        },
        "labels": {
          "single": {
            "delete_confirm": "Weet u zeker dat u dit label wilt verwijderen?"
          }
        },
        "program_templates": {
          "single": {
            "delete_confirm": "Weet u zeker dat u dit proces wilt verwijderen?"
          }
        },
        "programs": {
          "single": {
            "delete_confirm": "Weet u zeker dat u dit proces wilt verwijderen?"
          }
        }
      },
      "deactivation_confirm": "Door de %{name} te deactiveren, heeft %{name} geen toegang meer tot de site en wordt deze automatisch verwijderd over %{retention_period}. Houd er rekening mee dat inactieve %{type} verborgen worden door de standaard filterinstellingen. Pas uw filters aan als u ze in het overzicht wilt opnemen.",
      "delete_confirm": "Door %{name} te verwijderen worden ook automatische alle mogelijke onderliggende trajecten verwijderd.",
      "ixly": {
        "fixed_prices": {
          "single": {
            "delete_confirm": "Weet u zeker dat u deze vaste prijs wilt verwijderen?"
          }
        },
        "job_profiles": {
          "single": {
            "delete_confirm": "Weet je zeker dat je dit template wilt verwijderen?"
          }
        },
        "migration_organizations": {
          "default": {
            "add": "Nieuwe migratie organisatie toevoegen",
            "start": "Start migraties"
          }
        },
        "subscriptions": {
          "single": {
            "delete_confirm": "Weet u zeker dat u dit abonnement wilt verwijderen?"
          }
        }
      },
      "selection": {
        "job_profiles": {
          "single": {
            "delete_confirm": "Weet u zeker dat u dit functieprofiel wilt verwijderen?"
          }
        }
      }
    },
    "activemodel": {
      "attributes": {
        "queries/usage": {
          "end_date": "Einddatum",
          "organization": "Organisatie",
          "start_date": "Startdatum"
        }
      }
    },
    "admin": {
      "authentication_actions": {
        "filter_form": {
          "authenticate": "Inloggen",
          "impersonate": "Inloggen als ..."
        },
        "index": {
          "action": "Actie",
          "browser": "Browser",
          "caption": "Hieronder staan alle authenticatie gerelateerde events. Deze events worden maximaal 6 maanden bewaard.",
          "created_at": "Wanneer",
          "ip": "IP adres",
          "title": "Authenticatie logs"
        }
      },
      "candidate_csv_imports": {
        "import_file": {
          "log": "Logboek",
          "original": "Origineel"
        },
        "index": {
          "list": "Hierin staan kandidaat importbestanden binnen de organisatie",
          "title": "Kandidaat CSV import"
        },
        "new": {
          "title": "Kandidaat CSV import"
        }
      },
      "color_settings": {
        "destroy": {
          "destroyed": "Kleurinstellingen zijn teruggezet naar de standaardwaarden.",
          "reset_button": "Standaard schema herstellen"
        },
        "edit": {
          "color_settings_explanation": "U kunt het kleurenschema van de interactieve resultatenpagina voor alle gebruikers wijzigen door de onderstaande velden te gebruiken",
          "title": "Kleurenschema"
        },
        "update": {
          "updated": "De kleurinstellingen van de organisatie zijn succesvol bijgewerkt."
        }
      },
      "default_mail_templates": {
        "could_not_be_saved": "Standaard mail template kan niet worden opgeslagen",
        "explain": "Hier kun je kiezen uit gepubliceerde organisatie mailtemplates en deze als standaard instellen. Het is niet mogelijk om Ixly applicatie mail templates te kiezen. Wanneer je dit wilt, zul je ze eerst moeten kopieren naar je eigen organisatie.",
        "header": "Email instellingen",
        "saved_successfully": "Standaard mail template opgeslagen!",
        "updated_successfully": "Standaard mail template bijgewerkt!"
      },
      "entries": {
        "index": {
          "candidate_assessment_entry": "Assessment",
          "candidate_assessment_program_entry": "Assessment Programma",
          "candidate_report_entry": "Rapport",
          "generated_report_entry": "Rapport",
          "leniency_entry": "Coulance",
          "list": {
            "candidate": "Kandidaat",
            "cost_center": "Kostenplaats",
            "credits": "Aantal",
            "date": "Datum",
            "description": "Omschrijving",
            "entry_type": "Transactie type"
          },
          "order": {
            "frequent_credits": "Maandelijkse afschrijving voor gebruikte credits",
            "incidental_credits": "Creditbundel aankoop",
            "intensive_credits": "Maandelijkse afschrijving voor gebruikte credits"
          },
          "order_entry": "Bestelling",
          "title": "Creditoverzicht"
        }
      },
      "mail_templates": {
        "add": "E-mail template toevoegen",
        "create": {
          "success": "De nieuwe E-mail template is aangemaakt"
        },
        "destroy": {
          "deleted": "Het e-mail template is verwijderd"
        },
        "duplicate": {
          "duplicated": "Het e-mail template '%{title}' is gekopiëerd"
        },
        "duplicated_title": "Kopie van %{title}",
        "edit": {
          "title": "E-mail template bewerken"
        },
        "form": {
          "assessment_platform_link_explanation": "Een link die de kandidaat naar de juiste plek in Assessment Platform leidt. Als ze nog geen wachtwoord hebben ingesteld, worden ze doorgestuurd naar de pagina voor het instellen van het wachtwoord, anders naar de inlogpagina. Dit veld of de variabele passwordless_login_link moet aanwezig zijn in de hoofdtekst van de e-mail*.",
          "assignment_title_explanation": "De titels van toegewezen opdrachten",
          "candidate_name_explanation": "De naam van de gebruiker",
          "end_date_explanation": "De uiterste datum waarop de activiteit voltooid kan worden",
          "explanation_text": "Binnen de tekst van de mail template kunnen variable gebruikt worden om dynamisch content in te voegen. Deze variable zijn omgeven met %{VARIABLE}, waar VARIABLE de naam is, en waar het geheel vervangen wordt. Momenteel ondersteunen we de volgende",
          "organization_name_explanation": "De naam van de huidige organisatie",
          "passwordless_login_link_explanation": "Deze link verwijst de kandidaat naar de inlogpagina van het Assessment Platform. Er is geen wachtwoord vereist om in te loggen. De kandidaat kan deze link echter slechts één keer gebruiken om toegang te krijgen tot de website. Dit veld of de variabele assessment_platform_link moet aanwezig zijn in de hoofdtekst van de mail*."
        },
        "index": {
          "help_text": "E-mail templates zijn de vooraf gedefinieerde e-mails die uw adviseurs naar kandidaten kunnen sturen. Alleen gepubliceerde E-mail templates kunnen door een adviseur worden geselecteerd. Applicatie templates worden als voorbeeld verstrekt.",
          "title": "E-mail templates"
        },
        "list": {
          "application_template": "Applicatie templates",
          "language": "Taal",
          "state": "Status",
          "subject": "Onderwerp",
          "title": "Titel",
          "updated_at": "Bijgewerkt op"
        },
        "new": {
          "title": "Nieuw E-mail template"
        },
        "publish": {
          "cannot_publish": "Het e-mail template %{title} kan niet worden gepubliceerd:<br> %{error_messages}",
          "published": "Het e-mail template %{title} is gepubliceerd"
        },
        "show": {
          "body": "Mail inhoud",
          "example_title": "Voorbeeld van de e-mail die verstuurd gaat worden",
          "subject": "Onderwerp",
          "title": "Voorbeeld van de %{title} e-mail template"
        },
        "update": {
          "cannot_publish": "Het e-mail template kon niet worden bijgewerkt:<br> %{error_messages}",
          "success": "E-mail template bijgewerkt",
          "title": "E-mail template bewerken"
        },
        "withdraw": {
          "withdrawn": "Het e-mail template %{title} is teruggetrokken"
        }
      },
      "organization_permissions": {
        "edit": {
          "help_text": "Je kunt hier beperken welke gebruikersrollen toegang hebben tot welke onderdelen van de applicatie",
          "title": "Gebruikersrechten"
        },
        "update": {
          "updated": "Organisatiemachtigingen zijn bijgewerkt."
        }
      },
      "organization_settings": {
        "default_report_objective": {
          "advice": "Advies",
          "selection": "Selectie"
        },
        "default_report_to": {
          "advisor": "De coach",
          "both": "Allebei",
          "candidate": "De kandidaat"
        },
        "edit": {
          "available_languages_explanation": "Als u de talen uitschakelt die door uw gebruikers worden gebruikt, worden hun taalinstellingen automatisch overgeschakeld naar de standaardtaal van uw organisatie.",
          "default_language_explanation": "De geselecteerde taal geldt als standaard instelling voor nieuwe gebruikers binnen de organisatie.",
          "default_report_objective_explanation": "De standaardinstelling voor ‘Rapportagedoelstelling’ is ‘Advies’. De gekozen standaardinstelling bepaalt met welke doelgroep de kandidaat wordt vergeleken. Voor selectiedoeleinden adviseren we om de ‘Selectie’ doelgroep te hanteren en voor ontwikkeldoeleinden adviseren we om de ‘Advies’ doelgroep te selecteren.",
          "default_report_to_explanation": "Met deze instelling wordt bepaald wie nadat een assessment is afgerond inzicht krijgt in het rapport: de kandidaat, de adviseur of beide. Indien 'adviseur' wordt gekozen, krijgt de adviseur de optie om dit per assessment in te stellen. Bij het bewerken van een proces kan dit altijd per assessment ingesteld worden.",
          "title": "Organisatie-instellingen"
        },
        "update": {
          "required_languages": "Beschikbare talen mogen niet leeg zijn en de standaardtaal moet beschikbaar zijn in de organisatie",
          "updated": "Organisatie-instellingen zijn succesvol bijgewerkt."
        }
      },
      "privacy_settings": {
        "edit": {
          "advisor_deactivation_period_explanation": "De periode van inactiviteit waarna coaches worden gedeactiveerd.",
          "candidate_deactivation_period_explanation": "De periode van inactiviteit waarna kandidaten worden gedeactiveerd.",
          "impersonation_explanation": "Als je deze optie aanzet, kunnen adviseurs met 'log in als'-rechten inloggen op de accounts die ze beheren. Daarnaast kunnen deze adviseurs voor kandidaten inloglinks genereren en kopiëren, zodat de kandidaat hiermee eenmaal kan inloggen zonder wachtwoord.",
          "ixly_support_explanation": "Als je deze optie aanzet, geef je de Ixly-helpdeskmedewerkers de mogelijkheid mee te kijken met je gebruikers om problemen op te lossen die via de helpdesk gemeld worden.",
          "retention_period_explanation": "In de wet Algemene verordening gegevensbescherming (AVG) is vastgesteld dat persoonsgegevens niet langer bewaard mogen worden dan noodzakelijk is. Om hieraan te voldoen worden inactieve kandidaten, adviseurs en contactpersonen automatisch verwijderd op basis van het gekozen bewaartermijn. Dit bewaartermijn wordt ook toegepast op de ingezette assessments en gegenereerde rapporten.",
          "title": "Privacy- en dataretentie"
        },
        "update": {
          "updated": "De privacyinstellingen van de organisatie zijn succesvol bijgewerkt."
        }
      },
      "whitelabel": {
        "edit": {
          "title": "Whitelabel instellingen"
        },
        "update": {
          "whitelabel_updated": "Het Whitelabel is bijgewerkt"
        }
      }
    },
    "advisors": {
      "index": {
        "title": "Adviseurs"
      }
    },
    "alt_texts": {
      "approval_request_send_reason": "Verstuur deze reden",
      "component_add": "Voeg dit element toe",
      "component_delete": "Verwijder dit element",
      "component_move": "Verplaats dit element",
      "component_settings": "Open de element-instellingen",
      "details_collapse": "Vouw de details samen",
      "details_expand": "Vouw de details uit",
      "document_delete": "Verwijder dit opgeslagen rapport",
      "document_download": "Download dit opgeslagen rapport",
      "document_save": "Sla dit rapport op",
      "document_share": "Deel dit opgeslagen rapport met de kandidaat",
      "document_unshare": "Deel de instellingen hierboven met de kandidaat",
      "enrollment_page_print": "Druk deze stap af",
      "enrollment_page_requiring_action": "Bevat elementen met aanvullende instellingen",
      "file_download": "Download dit bestand",
      "filter_bar_collapse": "Vouw de filters samen",
      "filter_bar_expand": "Vouw de filters uit",
      "interactive_results_agile_average": "Grafiek met stippellijn op %{percentage} procent vanaf rechts. Hoe hoger dit percentage, hoe wendbaarder je bent.",
      "interactive_results_agile_pillars": "Grafiek die jouw subscores weergeeft als klikbare sectoren van een cirkeldiagram. Kracht %{power}, Verandering %{change}, Persoonlijke groei %{growth}.",
      "interactive_results_info_birthdate": "Geboortedatum",
      "interactive_results_info_date_of_birth": "Geboortedatum",
      "interactive_results_info_education": "Opleidingsniveau",
      "interactive_results_info_email": "E-mailadres",
      "interactive_results_info_study_subject": "Opleiding",
      "interactive_results_info_work_experience": "Meest recente werkervaring",
      "interactive_results_info_years_experience": "Aantal jaren ervaring",
      "interactive_results_information": "Open de toelichting op de scores",
      "interactive_results_intelligence_chart": "Grafiek die jouw IQ-score van %{iq} aangeeft op een normaalverdeling. Stippellijnen tonen de foutmarges.",
      "interactive_results_next": "Volgende",
      "interactive_results_paging": "Pagina %{page} van %{pages}",
      "interactive_results_previous": "Vorige",
      "interactive_results_rating": "%{score} uit %{max} sterren",
      "interactive_results_tip": "Open de tip",
      "ixly_test_download": "Download dit rapport",
      "ixly_test_settings": "Open de rapport-instellingen",
      "loading": "Even laden...",
      "navbar_expand": "Vouw het navigatiemenu uit",
      "notification_close": "Sluit deze taak",
      "notification_reopen": "Heropen deze taak",
      "organization_logo": "Logo van %{name}",
      "organization_logo_change": "Logo van %{name} – klik om te veranderen",
      "organization_logo_home": "Logo van %{name} – klik voor start",
      "post_rule_delete": "Verwijder deze deelregel",
      "price_in_euros": "in euro's",
      "program_page_delete": "Verwijder deze stap",
      "program_page_move": "Verplaats deze stap",
      "program_page_settings": "Open de stap-instellingen",
      "program_settings": "Open de proces-instellingen",
      "quiz_correct": "Dit antwoord is juist",
      "quiz_incorrect": "Dit antwoord is onjuist",
      "quiz_selected": "Dit antwoord is geselecteerd",
      "saved": "Uw veranderingen zijn opgeslagen",
      "saving": "Uw veranderingen worden opgeslagen",
      "template_column_delete": "Verwijder deze kolom",
      "template_column_move": "Verplaats deze kolom",
      "template_column_settings": "Open de kolom-instellingen",
      "template_element_delete": "Verwijder deze sectie",
      "template_element_move": "Verplaats deze sectie",
      "template_element_settings": "Open de sectie-instellingen",
      "template_element_variables": "Open de variabelen-instellingen",
      "template_item_delete": "Verwijder dit item",
      "template_row_delete": "Verwijder deze rij",
      "template_row_move": "Verplaats deze rij",
      "template_zoom_in": "Zoom in",
      "template_zoom_out": "Zoom uit",
      "top_step_delete": "Verwijder deze stappengroep",
      "top_step_insert": "Voeg een stappengroep in",
      "user_avatar": "Profielfoto van %{name}",
      "user_avatar_change": "Profielfoto van %{name} – klik om te veranderen",
      "view_switcher_cards": "Bekijk als tegels",
      "view_switcher_table": "Bekijk als tabel"
    },
    "approval_requests": {
      "index": {
        "help_text": "Hier vindt u alle documenten die u verzocht bent om te accorderen",
        "table_header": [
          "Bestand",
          "Kandidaat",
          "Proces",
          "Status",
          "Reden",
          "Coach"
        ],
        "title": "Accorderingen"
      }
    },
    "assessments": {
      "advisors": {
        "account_email": "E-mail",
        "create": {
          "created": "De adviseur is aangemaakt",
          "created_and_invited": "De adviseur is aangemaakt en verwelkomd via e-mail",
          "title": "Adviseur toevoegen"
        },
        "created_at": "Aangemaakt op",
        "deactivate": {
          "advisor_deactivated": "De adviseur is gedeactiveerd"
        },
        "edit": {
          "labels": "Labels",
          "title": "De adviseur bewerken"
        },
        "form": {
          "add_label": "Label toevoegen",
          "impersonation_explanation": "Als u deze optie selecteert, dan geeft u deze gebruiker het recht om in te loggen als een andere gebruiker",
          "role_explanation": "Klik om gedetailleerde roldocumentatie te bekijken"
        },
        "index": {
          "list": "Hier worden alle adviseurs binnen uw organisatie weergegeven",
          "title": "Adviseurs"
        },
        "label": {
          "remove": "Verwijderen"
        },
        "last_activity": "Laatste activiteit",
        "list": {
          "candidates": "Kandidaten",
          "labels": "Labels"
        },
        "name": "Naam",
        "new": {
          "title": "Adviseur toevoegen"
        },
        "organization": "Organisatie",
        "privacy_accepted_at": "Privacy geaccepteerd",
        "reactivate": {
          "advisor_reactivated": "De adviseur is weer geactiveerd"
        },
        "roles": "Rollen",
        "send_welcome": {
          "welcome_mail_sent": "De welkomstmail is verstuurd"
        },
        "status": "Status",
        "two_factor": "Verificatie in twee stappen",
        "type": "Type",
        "unconfirmed_email": "Onbevestigde e-mail",
        "update": {
          "advisor_updated": "De adviseur is bijgewerkt",
          "title": "De adviseur bewerken"
        }
      },
      "assessment_report_settings": {
        "edit": {
          "title": "Rapportinstellingen"
        },
        "update": {
          "success": "De rapportinstellingen zijn succesvol bijgewerkt"
        }
      },
      "assessment_verifications": {
        "link": "Verificatie",
        "show": {
          "about_verification_tests": "Een verificatietest is een optionele, verkorte versie van de volledige %{assessment}. Capaciteitentesten worden steeds vaker remote, zonder toezicht, ingevuld. Het is in deze situatie lastig in te schatten of de kandidaat de test zelf en zonder hulpmiddelen heeft gemaakt. Door een verificatietest in te zetten, die wel onder onder toezicht ingevuld wordt, kan je deze inschatting beter maken.",
          "about_verification_tests_title": "Over verificatie testen",
          "run_test": "Voer nu de verificatietest uit",
          "title": "Verificatie benodigd voor %{assessment} van %{candidate}",
          "verification_tests_report": "Als de verificatietest gemaakt is krijg je een extra pagina in het rapport die weergeeft of de scores van de eerder gemaakte %{assessment} enigszins overeenkomen met de scores van de verificatietest. Als dit zo is, zal je een groen vinkje in het rapport zien. Zie je een rood kruisje in het rapport, dan is het verschil tussen de uitslag van de remote gemaakte %{assessment} en de verificatietest groter dan dat we op basis van kans mogen verwachten.",
          "verification_tests_report_title": "Verschillen in het rapport",
          "why_skip_verification_tests": "Als een kandidaat een %{assessment} ingevuld heeft onder toezicht of wanneer je geen reden hebt om aan te nemen dat de kandidaat hulp gehad heeft bij het invullen van de test, dan is het niet nodig om een verificatietest in te zetten. Ook is het minder relevant als de uitslagen van de kandidaat onder het verwachte niveau liggen. De verificatietest geeft namelijk niet aan of de kandidaat een hogere score heeft behaald na het maken van deze verificatietest. Je krijgt alleen te zien of de scores op de tweede test de eerste test evenaren.",
          "why_skip_verification_tests_title": "Wel of niet inzetten?"
        },
        "skip": {
          "success": "De verificatie van %{title} is met succes overgeslagen"
        },
        "update": {
          "success": "De verificatie van %{title} is succesvol gestart"
        }
      },
      "assignments": {
        "edit": {
          "end_date": "Aanpassen einddatum",
          "end_date_explanation": "De uiterste datum waarop de activiteit voltooid kan worden. Maak het veld leeg om de deadline te verwijderen.",
          "invitation_date": "Bewerk uitnodigings datum",
          "invitation_mail_title": "Uitnodigingsmail aanpassen",
          "reminder_date": "Herinneringsmail aanpassen",
          "reminder_date_explanation": "De datum waarop de kandidaat via e-mail wordt herinnerd aan de activiteit. Maak het veld leeg om de herinneringsmail te verwijderen.",
          "reminder_mail_title": "Herinneringsmail aanpassen",
          "title": "Bewerk herinneringsmail en deadline voor %{assessment} van %{candidate}"
        },
        "invite": {
          "candidate_double_invited": "De kandidaat is per e-mail uitgenodigd en geattendeerd op %{title}",
          "candidate_invited": "De kandidaat is per e-mail geattendeerd op %{title}",
          "cannot_invite": "Het is niet gelukt om de kandidaat uit te nodigen voor %{title}, de kandidaat was al uitgenodigd."
        },
        "prepare_invite": {
          "title": "%{candidate} uitnodigen voor %{assessment}"
        },
        "updated": "Activiteit is succesvol aangepast"
      },
      "bulk_assignments": {
        "already_assigned": "%{assignment} is al geselecteerd",
        "assessment_not_available_in_subscription": "Dit proces bevat een assessment dat niet meer beschikbaar is in jouw abonnement. Pas het proces aan zodat het weer toegekend kan worden.",
        "blank_description": "Er is geen beschrijving beschikbaar voor dit proces.",
        "create": {
          "action_required": "Het proces is toegekend. Er is nog actie benodigd voor de kandidaat uitgenodigd kan worden.",
          "candidate_invited": "De kandidaat is per e-mail geattendeerd op het nieuw toegekende proces",
          "cannot_enroll": "U kunt dit proces momenteel niet toekennen",
          "enrollment_created": "Het proces is toegekend. De kandidaat is nog niet op de hoogte gebracht."
        },
        "duration": "%{n} minuten",
        "example_report": "Open voorbeeldrapport in nieuw tabblad",
        "more_information": "Meer informatie",
        "new": {
          "selected_assessment_programs": "Geselecteerde assessment programma's",
          "selected_assessments": "Toegewezen assessments",
          "selected_programs": "Toegewezen processen",
          "title": "Toewijzen Assessment - %{candidate}"
        },
        "preview": "Voorbeeld"
      },
      "candidate_assessments": {
        "confirm_destroy": "Weet u zeker dat u %{assessment_title} van %{candidate_name} wilt verwijderen?",
        "confirm_reset": "Weet je zeker dat je assessment %{assessment_title} wil resetten? Door te resetten worden alle antwoorden verwijderd zodat de kandidaat opnieuw aan het assessment kan beginnen.",
        "destroy": {
          "success": "%{title} is succesvol teruggetrokken"
        },
        "report_tos": {
          "advisor": "De coach",
          "both": "Allebei",
          "candidate": "De kandidaat"
        },
        "reset": {
          "success": "%{title} is succesvol gereset"
        }
      },
      "candidate_email_messages": {
        "index": {
          "title": "E-mails verzonden naar %{candidate}"
        },
        "list": {
          "sent_at": "Verzonden op",
          "status": "Status",
          "subject": "Onderwerp"
        }
      },
      "candidate_reports": {
        "add": "Rapport genereren",
        "create": {
          "success": "Het rapport wordt gegenereerd. Je krijgt een notificatie als het document gereed staat bij je Documenten en Media."
        },
        "download": "Rapport downloaden",
        "form": {
          "external_report": "Rapport voor %{candidate_assessment}",
          "generated_reports": "Rapporten gegenereerd voor %{candidate_assessment}",
          "no_external_reports_generated_yet": "Er zijn geen rapporten gegenereerd voor %{candidate_assessment}",
          "no_reports_generated_yet": "Er zijn nog geen rapporten gegenereerd voor %{candidate_assessment}",
          "report_objective": "Rapportagedoelstelling: %{norm_group}"
        },
        "form_list": {
          "document_options": "Rapport opties",
          "language": "Taal",
          "media_item": "Document",
          "no_media_item_available": "Document nog niet beschikbaar",
          "norm_group_id": "Rapportagedoelstelling",
          "shared_with": "Gedeeld met",
          "title": "Gegenereerd rapport"
        },
        "index": {
          "link_title": "Rapporten",
          "title": "Rapporten van %{candidate}"
        },
        "list": {
          "assessment": "Assessment",
          "created_at": "Aangemaakt op",
          "document_options": "Rapport opties",
          "language": "Taal",
          "media_item": "Document",
          "no_media_item_available": "Document nog niet beschikbaar",
          "norm_group_id": "Rapportagedoelstelling",
          "shared_with": "Gedeeld met",
          "title": "Rapport"
        },
        "new": {
          "link_title": "Rapport genereren",
          "title": "Rapport genereren voor %{candidate}"
        },
        "only_shared_with": {
          "one": "%{count} organisatie manager",
          "other": "%{count} organisatie managers"
        },
        "shared_with": {
          "one": "en %{count} organisatie manager",
          "other": "en %{count} organisatie managers"
        },
        "stitch_reports": "Voeg rapporten samen"
      },
      "candidate_results": {
        "index": {
          "title": "Persoonlijk resultatenoverzicht"
        }
      },
      "candidate_selection": {
        "active_filter_info": "<span class='font-bold'> / %{count}</span> van het actieve filter zijn geselecteerd.",
        "deselect_button": "Deselecteer alles",
        "select_button": "Selecteer alles",
        "total_info": "<span class='font-bold'> / %{count} kandidaten</span> zijn geselecteerd."
      },
      "candidate_steps": {
        "create": {
          "action_required": "De kandidaat is aangemaakt. Om de kandidaat uit te nodigen voor het proces is nog actie benodigd",
          "candidate_created": "De kandidaat is aangemaakt",
          "candidate_invited": "De kandidaat is per e-mail geattendeerd op het nieuw toegekende proces",
          "cannot_enroll": "U kunt dit proces momenteel niet toekennen"
        },
        "filter": {
          "all": "Allemaal",
          "assessment_definitions": "Assessments",
          "assessment_programs": "Assessment Programs",
          "programs": "Processen"
        },
        "info_block": "Gedetailleerde informatie",
        "new": {
          "candidate_title": "Kandidaat toevoegen",
          "coadvisor_title": "Toewijzen Contactpersoon",
          "enrollment_title": "Toewijzen Assessment"
        }
      },
      "candidates": {
        "add": "Kandidaat toevoegen",
        "add_label": "Label toevoegen",
        "added": "Kandidaat <a class='underline' href='%{link}'>%{name}</a> is aangemaakt.",
        "assign_bulk_button": "Ken toe",
        "contact_person": {
          "remove": "Verwijderen"
        },
        "create": {
          "title": "Kandidaat toevoegen"
        },
        "deactivate": "De kandidaat is gedeactiveerd",
        "delete": "Kandidaat verwijderen",
        "deleted": "De kandidaat %{name} is verwijderd",
        "download_report": {
          "not_shared_alt_text": "Het resultaat is nog niet gedeeld met jou"
        },
        "edit": {
          "contact_persons": "Contactpersonen",
          "labels": "Labels",
          "title": "Kandidaat aanpassen"
        },
        "export": {
          "started": "De export van kandidaten wordt gestart. U ontvangt een melding zodra de export is voltooid."
        },
        "index": {
          "title": "Kandidaten"
        },
        "label": {
          "remove": "Verwijderen"
        },
        "list": {
          "advisor": "Adviseur",
          "api_identifier": "API identifier",
          "coadvisor": "Coadviseur",
          "contact_person": "Contactpersonen",
          "cost_center": "Kostenplaats",
          "created_at": "Aangemaakt op",
          "email": "E-mail",
          "first_name": "Voornaam",
          "labels": "Labels",
          "language": "Taal",
          "last_activity": "Laatst actief",
          "last_name": "Achternaam",
          "name": "Naam",
          "organization": "Organisatie",
          "passwordless_login_link": "Wachtwoordloze inloglink",
          "privacy_accepted_at": "Privacy geaccepteerd",
          "report_objective": "Rapportagedoelstelling",
          "status": "Status",
          "track": "Laatste activiteit",
          "unconfirmed_email": "Onbevestigde e-mail"
        },
        "premium_candidate_tooltip": "Dit is een premium kandidaat",
        "reactivated": "De kandidaat is opnieuw geactiveerd",
        "review_required": "Review nodig",
        "update": {
          "title": "Kandidaat aanpassen"
        },
        "updated": "Kandidaat bijgewerkt",
        "upload_candidates": "Kandidaten uploaden"
      },
      "candidates_contact_persons": {
        "show": {
          "account_email": "E-mailadres",
          "contact_person": "Contactpersoon",
          "name": "Naam",
          "organization": "Organisatie",
          "role": "Rol"
        }
      },
      "clients": {
        "add": "Opdrachtgever toevoegen",
        "contact_person": {
          "edit": "Aanpassen",
          "remove": "Verwijderen"
        },
        "create": {
          "client_created": "De opdrachtgever is aangemaakt",
          "help_text": "Maak hier een nieuwe opdrachtgever aan voor uw organisatie",
          "title": "Nieuwe opdrachtgever"
        },
        "edit": {
          "labels": "Labels",
          "title": "Bewerk opdrachtgever"
        },
        "form": {
          "add_contact_person": "Contactpersoon toevoegen",
          "add_label": "Label toevoegen",
          "labels": "Labels"
        },
        "index": {
          "help_text": "Contactpersonen zijn belanghebbenden in een traject van een kandidaat. Ze kunnen de voortgang van een kandidaat bekijken zonder specifieke details te zien, ze kunnen documenten delen met kandidaten en hun adviseurs en ze kunnen worden gevraagd om documenten goed te keuren. U kunt uw contactpersonen indelen in groepen die 'opdrachtgevers' worden genoemd.",
          "new_button": "Nieuwe opdrachtgever",
          "title": "Opdrachtgevers"
        },
        "label": {
          "remove": "Verwijderen"
        },
        "list": {
          "candidates": "Kandidaten",
          "contact_persons": "Contactpersonen",
          "name": "Naam",
          "updated_at": "Bijgewerkt op"
        },
        "new": {
          "help_text": "Maak hier een nieuwe opdrachtgever aan voor uw organisatie",
          "title": "Nieuwe opdrachtgever"
        },
        "row": {
          "number_of_candidates": "%{number} actief",
          "number_of_contact_persons": "%{number} actief"
        },
        "show": {
          "help_text": "Hier vindt u al uw contactpersonen en kandidaten bij deze opdrachtgever",
          "new_button": "Nieuwe contactpersoon",
          "table_header": [
            "Gegevens",
            "E-mailadres",
            "Status"
          ]
        },
        "update": {
          "client_updated": "De opdrachtgever is bijgewerkt",
          "title": "Bewerk opdrachtgever"
        }
      },
      "clients_contact_persons": {
        "add": "Contactpersoon toevoegen",
        "create": {
          "created": "Contactpersoon %{name} is aangemaakt"
        },
        "deactivate": {
          "deactivated": "De contactpersoon is gedeactiveerd"
        },
        "destroy": {
          "deleted": "De contactpersoon is verwijderd"
        },
        "edit": {
          "labels": "Labels",
          "title": "Contactpersoon bewerken"
        },
        "form": {
          "add_label": "Label toevoegen",
          "new": "Nieuw contactpersoon"
        },
        "index": {
          "title": "Contactpersonen"
        },
        "label": {
          "remove": "Verwijderen"
        },
        "list": {
          "account_email": "E-mail",
          "candidates": "Kandidaten",
          "client": "Opdrachtgever",
          "created_at": "Aangemaakt op",
          "labels": "Labels",
          "list_text": "Contactpersonen zijn belanghebbenden in een traject van een kandidaat. Ze kunnen de voortgang van een kandidaat bekijken zonder specifieke details te zien, ze kunnen documenten delen met kandidaten en hun adviseurs en ze kunnen worden gevraagd om documenten goed te keuren. U kunt uw contactpersonen indelen in groepen die 'opdrachtgevers' worden genoemd.",
          "name": "Naam",
          "organization": "Organisatie",
          "privacy_accepted_at": "Privacy geaccepteerd",
          "status": "Status",
          "two_factor": "2-factor authenticatie",
          "unconfirmed_email": "Onbevestigde e-mail",
          "updated_at": "Bijgewerkt op"
        },
        "new": {
          "title": "Nieuwe contactpersoon",
          "title_with_client": "Nieuwe contactpersoon voor de %{client}"
        },
        "reactivate": {
          "reactivated": "De contactpersoon is opnieuw geactiveerd"
        },
        "send_welcome": {
          "welcome_mail_sent": "De welkomstmail is verstuurd"
        },
        "show": {
          "client": "Cliënt",
          "email": "E-mailadres",
          "email_address": "E-mailadres",
          "status": "Status"
        },
        "update": {
          "updated": "De contactpersoon is bijgewerkt"
        }
      },
      "contact_persons_labels": {
        "destroy": {
          "removed": "Label succesvol verwijderd van de contactpersoon"
        },
        "form": {
          "new": "Nieuw label"
        }
      },
      "dashboards": {
        "show": {
          "title": "Dashboard"
        }
      },
      "enrollers": {
        "add": "Inschrijver toevoegen",
        "create": {
          "created": "De inschrijvingslink is aangemaakt",
          "created_with_premium_components": {
            "one": "De inschrijvingslink is aangemaakt (%{costs} per inschrijving i.v.m. 1 premium element)",
            "other": "De inschrijvingslink is aangemaakt (%{costs} per inschrijving vanwege %{count} premiumelementen)"
          },
          "title": "Nieuwe inschrijflink"
        },
        "created": "De inschrijflink is aangemaakt",
        "created_with_premium_components": {
          "one": "De inschrijflink is aangemaakt (%{costs} per inschrijving vanwege 1 premium element)",
          "other": "De inschrijflink is aangemaakt (%{costs} per inschrijving vanwege %{count} premium elementen)"
        },
        "deactivate": {
          "deactivated": "De inschrijflink is gedeactiveerd"
        },
        "destroy": {
          "deleted": "De inschrijflink is verwijderd",
          "destroy_confirm": "Weet u zeker dat u deze inschrijflink wilt verwijderen?"
        },
        "edit": {
          "explanation": "Deelnemers kunnen de bovenstaande link gebruiken om zich in te schrijven voor het geselecteerde proces. Deze link kunt u delen per e-mail of bijvoorbeeld via uw website. Houd er rekening mee dat iedereen zich kan inschrijven als u de link openbaar maakt.",
          "link": "Koppeling",
          "title": "Bewerk inschrijflink"
        },
        "form": {
          "help_texts": {
            "advisor": "De coach gaat de kandidaat begeleiden in het traject nadat de kandidaat zichzelf heeft aangemeld.",
            "show_default": "Er wordt standaard een korte tekst getoond die uitlegt hoe in te schrijven. Als alternatief kunt u een eigen tekst opgeven in de beschrijving van het proces.",
            "url": "Inschrijflink",
            "url_explanation": "Met behulp van bovenstaande link kunnen kandidaten zich inschrijven voor het opgegeven proces. Deze kunt u verspeiden via bijvoorbeeld een e-mail of uw website. Houd er rekening mee dat iedereen zich in kan schrijven als u de link publiek maakt."
          }
        },
        "index": {
          "actions": "Acties",
          "add": "Nieuwe inschrijflink",
          "coach": "Coach",
          "enrolments": "Inschrijvingen",
          "list_of_enrollers": "Hier vindt u alle inschrijflinks voor uw organisatie",
          "process": "Proces",
          "title": "Inschrijflinks",
          "url": "Inschrijflink"
        },
        "new": {
          "title": "Nieuwe inschrijflink"
        },
        "reactivate": {
          "reactivated": "De inschrijflink is geheractiveerd"
        },
        "show": {
          "coach": "Coach",
          "created_at": "Datum",
          "enrolments": "Inschrijvingen",
          "process": "Proces",
          "status": "Status",
          "url": "Inschrijflink"
        },
        "update": {
          "title": "Bewerk inschrijflink",
          "updated": "De inschrijflink is bijgewerkt"
        }
      },
      "enrollments": {
        "create": {
          "cannot_enroll": "U kunt dit proces momenteel niet toekennen"
        },
        "destroy": {
          "cannot_destroy": "Je kunt niet verwijderen, omdat het al gestart is.",
          "enrollment_destroyed": "Process verwijderd"
        },
        "form": {
          "add": "Contactpersoon toevoegen"
        },
        "invite": {
          "cannot_invite": "Je kunt niet uitnodigen, omdat enrollment ongeldige status heeft."
        },
        "new": {
          "contact_persons": "Contactpersonen"
        }
      },
      "external_reports": {
        "create": {
          "error": "Het rapport kan niet worden gegenereerd",
          "success": "Het rapport wordt gegenereerd."
        }
      },
      "integrations": {
        "show": {
          "api_status": {
            "incorrect": "Er zijn momenteel technische problemen waardoor er geen assessments kunnen worden toegekend aan kandidaten. Neem contact op met support als dit probleem blijft voordoen.",
            "new": "Momenteel kunnen nog geen assessments worden toegekend aan kandidaten binnen de organisatie, neem contact op met support om deze functionaliteit beschikbaar te maken."
          }
        }
      },
      "label_multiples": {
        "create": {
          "send": "Er werden labels gekoppeld aan %{number} kandidaten"
        },
        "index": {
          "title": "Labels toevoegen"
        }
      },
      "labels": {
        "create": {
          "label_created": "Label is aangemaakt"
        },
        "destroy": {
          "removed": "Label succesvol verwijderd"
        },
        "edit": {
          "title": "Label aanpassen"
        },
        "form": {
          "new": "Nieuw label"
        },
        "index": {
          "help_text": "Hier vindt u al uw labels die u kunt gebruiken binnen de applicatie",
          "title": "Labels"
        },
        "list": {
          "advisors": "Adviseurs",
          "candidates": "Kandidaten",
          "contact_persons": "Contact personen",
          "name": "Naam",
          "programs": "Processen",
          "updated_at": "Laatste aangepast"
        },
        "new": {
          "title": "Nieuw label"
        },
        "update": {
          "label_updated": "Label is bijgewerkt"
        }
      },
      "mail_multiples": {
        "create": {
          "send": {
            "one": "E-mail verzonden naar %{count} kandidaat",
            "other": "E-mail verzonden naar %{count} kandidaten",
            "zero": "Geen e-mail verzonden, inactieve kandidaten kunnen niet ge-e-maild worden."
          }
        },
        "index": {
          "title": "Mail versturen"
        }
      },
      "multiple_bulk_assignments": {
        "create": {
          "candidates_invited": "De kandidaten zijn per e-mail op de hoogte gebracht van de nieuw toegewezen processen",
          "cannot_enroll": "U kunt dit proces momenteel niet toekennen",
          "processes_created": "De processen zijn toegewezen. De kandidaten zijn nog niet op de hoogte gebracht van het nieuw toegewezen proces."
        },
        "new": {
          "title": "Wijs processen toe aan kandidaten: %{names}"
        }
      },
      "multiple_talent_solution_assignments": {
        "create": {
          "cannot_enroll": "Toegekende process is al aan alle kandidaten toegewezen."
        }
      },
      "news": {
        "index": {
          "title": "Nieuws"
        },
        "show": {
          "title": "Nieuws"
        }
      },
      "norm_groups": {
        "advice": "Advies",
        "selection": "Selectie"
      },
      "organization_tasks": {
        "index": {
          "title": "Standaard rapporten"
        },
        "list": {
          "credits": "Credits",
          "default_report": "Standaard rapport",
          "help_text": "U kunt hier per beschikbaar assessment het standaard rapport instellen",
          "key": "Key",
          "language": "Taal",
          "module": "Product",
          "name": "Naam",
          "price": "Prijs"
        },
        "task": {
          "de": "Duits",
          "en": "Engels",
          "nl": "Nederlands"
        }
      },
      "program_templates": {
        "destroy": {
          "destroyed": "Het proces '%{program_title}' is verwijderd"
        },
        "duplicate": {
          "duplicate_prefix": "Kopie:",
          "duplicate_suffix": "[kopie]",
          "program_duplicated": "Het proces '%{program_title}' is gekopiëerd",
          "template_duplicated": "Het proces '%{program_title}' is gekopiëerd naar uw organisaties eigen processen"
        },
        "index": {
          "list": "Lijst met ontworpen organisatiesjabloonprocessen",
          "title": "Procestemplates"
        },
        "list": {
          "actions": "Acties",
          "assigned": "Gekoppeld aan",
          "date": "Aangepast op",
          "labels": "Label",
          "length": "Stappen",
          "status": "Status",
          "title": "Titel",
          "type": "Type"
        },
        "new": "Proces toevoegen",
        "program": {
          "candidates": "Kandidaten",
          "delete_confirm": "Weet u zeker dat u dit process wilt verwijderen?",
          "steps": "Stappen",
          "type": {
            "program": "Eigen process",
            "program_template": "Template"
          }
        },
        "publish": {
          "all_pages_invisible": "U kunt dit proces niet publiceren omdat geen enkele stap is ingesteld als zichtbaar voor de kandidaat",
          "published": "Het proces '%{program_title}' is gepubliceerd"
        },
        "publish_as_template": {
          "published_as_template": "Het proces '%{program_title}' is gepubliceerd als template"
        },
        "update": {
          "update_failed": "Het proces kon niet bijgewerkt worden",
          "updated": "Het proces is bijgewerkt"
        },
        "withdraw": {
          "withdrawn": "Het proces '%{program_title}' is teruggetrokken"
        }
      },
      "programs": {
        "destroy": {
          "destroyed": "Het proces '%{program_title}' is verwijderd"
        },
        "duplicate": {
          "duplicate_prefix": "Kopie:",
          "duplicate_suffix": "[kopie]",
          "program_duplicated": "Het proces '%{program_title}' is gekopiëerd",
          "template_duplicated": "Het proces '%{program_title}' is gekopiëerd naar uw organisaties eigen processen"
        },
        "index": {
          "list": "Ontwerp, bewerk of bekijk de processen van uw organisatie. Processen zijn beschikbaar voor adviseurs met de coach rol en premium kandidaten.",
          "title": "Processen ontwikkelen"
        },
        "list": {
          "actions": "Acties",
          "assigned": "Gekoppeld aan",
          "date": "Aangepast op",
          "labels": "Label",
          "length": "Stappen",
          "status": "Status",
          "title": "Titel",
          "type": "Type"
        },
        "new": "Proces toevoegen",
        "program": {
          "candidates": "Kandidaten",
          "delete_confirm": "Weet u zeker dat u dit process wilt verwijderen?",
          "steps": "Stappen",
          "type": {
            "program": "Eigen process",
            "program_template": "Template"
          }
        },
        "publish": {
          "all_pages_invisible": "U kunt dit proces niet publiceren omdat geen enkele stap is ingesteld als zichtbaar voor de kandidaat",
          "published": "Het proces '%{program_title}' is gepubliceerd"
        },
        "publish_as_template": {
          "published_as_template": "Het proces '%{program_title}' is gepubliceerd als template"
        },
        "update": {
          "update_failed": "Het proces kon niet bijgewerkt worden",
          "updated": "Het proces is bijgewerkt"
        },
        "withdraw": {
          "withdrawn": "Het proces '%{program_title}' is teruggetrokken"
        }
      },
      "rooms": {
        "add": "Videogesprek toevoegen",
        "create": {
          "created": "Het videogesprek is aangemaakt",
          "title": "Nieuw videogesprek"
        },
        "destroy": {
          "deleted": "Het videogesprek is verwijderd"
        },
        "edit": {
          "title": "Bewerk videogesprek"
        },
        "form": {
          "help_texts": {
            "end_to_end_encryption": "Deze extra beveiligingslaag werkt alleen op nieuwere browsers met ondersteuning voor Insertable Streams. Dit verhindert deelname vanaf de meeste browsers."
          }
        },
        "index": {
          "title": "Videogesprekken"
        },
        "list": {
          "actions": "Acties",
          "help_text": "Hier vindt u alle videogesprekken van uw organisatie",
          "participants": "Deelnemers",
          "subject": "Onderwerp",
          "updated_at": "Laatste activiteit"
        },
        "new": {
          "title": "Nieuw videogesprek"
        },
        "room": {
          "participants_present": "%{number} aanwezig"
        },
        "update": {
          "title": "Bewerk videogesprek",
          "updated": "Het videogesprek is bijgewerkt"
        }
      },
      "shared": {
        "assessment_program_card": {
          "assessment_program_subtasks": "Dit Assessment Programma combineert de %{subtasks} Assessments."
        },
        "candidate_form": {
          "add_contact_person": "Contactpersoon toevoegen",
          "add_label": "Label toevoegen",
          "help_texts": {
            "advisor": "De adviseur kan trajecten toekennen aan de kandidaat en de kandidaat begeleiden in deze trajecten.",
            "coadvisor": "Een coadviseur is extra adviseur die de trajecten van de kandidaat kan inzien.",
            "contact_person": "Een contactpersoon is een stakeholder in een traject van een kandidaat. Zij kunnen de voortgang van een kandidaat bekijken, documenten delen met de kandidaat en diens adviseurs en worden gevraagd documenten goed te keuren",
            "intro": {
              "text": "Kies voor 'Opslaan' en de kandidaat wordt nog niet uitgenodigd om te werken op het platform, er wordt geen mail verstuurd. Kies voor 'Volgende' en je kunt de kandidaat uitnodigen om een account aan te maken en een Assessment te maken.",
              "title": "Kandidaat toevoegen"
            },
            "outro": {
              "text": "Kies 'Opslaan' om een kandidaat later uit te nodigen, de kandidaat ontvangt nog geen e-mail. Kies 'Volgende' om een kandidaat direct uit te kunnen nodigen en om direct een Assessment toe te kunnen wijzen.",
              "title": "Toewijzen Assessment"
            },
            "report_objective": "Is het doel om kandidaten te adviseren om zich verder te ontwikkelen, kies dan 'advies'; is het doel om de meest geschikte kandidaten te kunnen selecteren, kies dan 'selectie'. Op basis van deze doelstelling zal het meest geschikte rapport voor de kandidaat automatisch geselecteerd worden."
          },
          "primary_title": "Primaire gegevens"
        },
        "mail_and_settings_form": {
          "candidate_without_account": "Voor deze kandidaat is geen e-mailadres bekend in het systeem. Hierdoor kan de kandidaat alleen via een eenmalig te gebruiken paswoordloze inloglink toegang krijgen tot de toegekende assessments. Voeg een e-mailadres toe als de kandidaat meer dan eenmalig toegang nodig heeft.",
          "delivery_date": "Verzenddatum",
          "invitation_mail_title": "Uitnodigingsmail instellen",
          "reminder_mail": "Herinneringsmail",
          "reminder_mail_title": "Herinneringsmail instellen",
          "report_settings_explanation": "Wanneer een kandidaat een assessment heeft afgerond wordt er automatisch een rapport gegenereerd. Op basis van de instellingen van je organisatie en de rapportagedoelstelling van je kandidaat hebben wij alvast een geschikt rapport geselecteerd.",
          "report_settings_title": "Rapportinstellingen"
        },
        "selected_candidates": "Geselecteerde kandidaten"
      },
      "stitched_reports": {
        "create": {
          "success": "Het samengevoegde rapport wordt gegenereerd. Je krijgt een notificatie als het rapport gereed staat bij je Documenten en Media."
        },
        "new": {
          "stitch_button": "Samenvoegen",
          "title": "Voeg rapporten samen voor %{candidate}"
        }
      },
      "whitelabel": {
        "edit": {
          "title": "Whitelabel instellingen"
        },
        "update": {
          "whitelabel_updated": "Het Whitelabel is bijgewerkt"
        }
      }
    },
    "authentication_actions": {
      "authenticate": "%{actor} heeft successvol ingelogd.",
      "impersonate": "%{actor} heeft ingelogd als %{target}",
      "unknown": "Onbekende actie: %{action}"
    },
    "boolean": {
      "yes": "Ja"
    },
    "builder": {
      "component": {
        "component_delete_confirm": "Weet u zeker dat u dit element wilt verwijderen?",
        "settings": "Element-instellingen"
      },
      "elements": {
        "advisor_introduction": "Coach introductie",
        "advisor_introduction_tooltip": "Hiermee kan de coach zich introduceren aan de kandidaat",
        "amc": "AMC",
        "amc_tooltip": "Met de AMC kan de adviseur de beperkingen van de kandidaat in kaart brengen en zicht krijgen op beroepsmogelijkheden",
        "appointment": "Afspraak planner",
        "appointment_tooltip": "Hiermee kunnen coach en kandidaat een (fysieke) ontmoeting afspreken",
        "blog": "Blog",
        "blog_tooltip": "Blog met alle artikelen die gedeeld zijn met de kandidaat",
        "bov": "BOV",
        "bov_tooltip": "Met de BOV kan de kandidaat door een database van beroepen zoeken",
        "checkboxes": "Aankruisvakjes",
        "checkboxes_tooltip": "Aantal keuzes om meerdere uit te selecteren",
        "consent": "Toestemming",
        "consent_tooltip": "Ja-neevraag die de kandidaat moet beantwoorden om verder te gaan",
        "custom_html": "Custom HTML",
        "custom_html_tooltip": "Eigen HTML code invoegen",
        "date": "Datuminvoer",
        "date_tooltip": "Een invoerveld voor een datum",
        "document_approval": "Document accordering",
        "document_approval_tooltip": "Hiermee kan de coach een bestand uploaden, voor de kandidaat of contactpersoon om te accorderen",
        "document_download": "Document download",
        "document_download_tooltip": "Hiermee kan de coach bestanden uploaden, voor de kandidaat om te downloaden",
        "document_upload": "Document upload",
        "document_upload_tooltip": "Hiermee kan de kandidaat bestanden uploaden, voor de coach om te downloaden",
        "docx_template": "CV & brief builder (Word)",
        "docx_template_tooltip": "Hiermee kan een DOCX document template geüpload worden, welke de kandidaat kan vullen met persoonlijke informatie",
        "fit": "FIT",
        "fit_tooltip": "Met de FIT kan de kandidaat zijn beperkingen in kaart brengen en zich oriënteren op beroepsmogelijkheden",
        "image": "Afbeelding",
        "image_tooltip": "Afbeelding om te bekijken",
        "input_text": "Tekstinvoer (kort)",
        "input_text_tooltip": "Een invoerveld van één regel, in te vullen door de kandidaat",
        "ixly_test": "Ixly assessment",
        "ixly_test_tooltip": "Hiermee kan de kandidaat een test of vragenlijst invullen en daarna een rapport downloaden",
        "job_market_scan": "Arbeidsmarktkansen",
        "job_market_scan_en": "Arbeidsmarktkansen Engelstalig",
        "job_market_scan_en_tooltip": "Met Arbeidsmarktkansen kan de kandidaat zijn kansen op de arbeidsmarkt berekenen",
        "job_market_scan_tooltip": "Met Arbeidsmarktkansen kan de kandidaat zijn kansen op de arbeidsmarkt berekenen",
        "job_suggestions": "Baansuggesties",
        "job_suggestions_plus": "Baansuggesties Plus",
        "job_suggestions_plus_tooltip": "Met Baansuggesties Plus kan de kandidaat hem passende banen ontdekken, plus gerelateerde opleidingen en vacatures",
        "job_suggestions_tooltip": "Met Baansuggesties kan de kandidaat hem passende banen ontdekken",
        "jobfeed": "Jobfeed",
        "jobfeed_tooltip": "Met Jobfeed kan de kandidaat door alle vacatures in Nederland zoeken",
        "logbook": "Logboek",
        "logbook_tooltip": "Hiermee kan de kandidaat notities bijhouden",
        "no_premium_components_available_yet": "(er zijn op dit moment geen premium elementen beschikbaar)",
        "pdf_template": "CV & brief builder",
        "pdf_template_tooltip": "Hiermee kunnen templates samengesteld worden voor PDF documenten, welke de kandidaat kan vullen met persoonlijke informatie",
        "premium": "Premium",
        "premium_costs_are_per_assignment": "(bovenstaande prijzen zijn per toekenning van het proces en exclusief %{vat}% btw)",
        "quiz": "Quiz",
        "quiz_tooltip": "Hiermee kan de kandidaat vragen (open of meerkeuze) beantwoorden en daarna zijn antwoorden controleren",
        "radio_buttons": "Meerkeuze",
        "radio_buttons_tooltip": "Aantal keuzes om één uit te selecteren (keuzerondjes)",
        "search_job": "Indeed vacaturezoeker",
        "search_job_tooltip": "Geïntegreerde vacaturezoeker voor de Indeed database",
        "selectbox": "Selectie",
        "selectbox_tooltip": "Aantal keuzes om één uit te selecteren (drop-down)",
        "standard": "Standaard",
        "text": "Leestekst",
        "text_tooltip": "Tekst om te lezen",
        "textarea": "Tekstinvoer (lang)",
        "textarea_tooltip": "Een invoerveld van meerdere regels, in te vullen door de kandidaat",
        "title": "Elementen",
        "vacancy": "Vacature manager",
        "vacancy_tooltip": "Hiermee kan de kandidaat interessante vacatures en zijn voortgang daarmee bijhouden",
        "youtube_video": "YouTube video",
        "youtube_video_tooltip": "YouTube video om te bekijken"
      },
      "menu_item_page": {
        "page_delete_confirm": "Weet u zeker dat u deze stap met al zijn elementen wilt verwijderen?",
        "settings": "Stap-instellingen"
      },
      "sidebar": {
        "new_page": "Nieuwe stap",
        "settings": "Proces-instellingen"
      },
      "top_steps": {
        "placeholder": "Naam stappengroep..."
      }
    },
    "button": {
      "add": "Toevoegen",
      "add_migration_organization": "TTK organisatie migreren",
      "back": "Terug",
      "cancel": "Annuleren",
      "click_to_close_modal": "(klik hier om dit venster te sluiten)",
      "close": "Sluiten",
      "complete_setup": "Uitnodiging versturen",
      "copy": "Kopiëer",
      "copy_token_authentication_link": "Kopieer link voor eenmalig wachtwoordloos inloggen",
      "deactivate": "Deactiveer",
      "delete": "Verwijder",
      "download_csv": "Download als CSV",
      "duplicate": "Kopie",
      "edit": "Bewerk",
      "export": "Export",
      "filter": "Toepassen",
      "inspect": "Inspecteer",
      "invite": "Nodig uit",
      "join": "Deelnemen",
      "mark_all_as_read": "Markeren als gelezen",
      "mark_as_template": "Markeer als template",
      "next": "Volgende",
      "publish": "Publiceer",
      "publish_as_template": "Publiceer als template",
      "reactivate": "Heractiveer",
      "refresh": "Herladen",
      "reset": "Reset",
      "reset_password": "Wachtwoord vergeten mail versturen",
      "select": "Selecteren",
      "send": "Versturen",
      "skip": "Overslaan",
      "start": "Begin",
      "test_results": "Resultaten",
      "unmark_as_template": "Markering als template ongedaan maken",
      "verify": "Verifiëren",
      "view": "Bekijk",
      "withdraw": "Trek terug"
    },
    "candidate": {
      "assessments": {
        "advisor_action": "De adviseur moet actie ondernemen naar aanleiding van dit assessment",
        "finished_at": "Afgerond op",
        "started_at": "Gestart op"
      },
      "assignments": {
        "active_count": "%{number} gestarte toekenning(en)",
        "finished_count": "%{number} voltooide toekenning(en)",
        "not_started_count": "%{number} niet gestarte toekenning(en)"
      },
      "created": {
        "empty_list": "Er staan geen nieuwe taken klaar"
      },
      "enrollment": {
        "deadline": "Dit traject moet uiterlijk op %{date} afgerond worden.",
        "download_alt_text": "Rapport downloaden",
        "enrolment_link_alt_text": "Open %{title}",
        "enrolment_link_disabled_alt_text": "%{title} niet beschikbaar - deadline verstreken",
        "finished_at": "Afgerond op",
        "invite_alt_text": "Nodig kandidaat uit voor %{title}",
        "invited_at": "De kandidaat wordt op %{date} uitgenodigd voor dit traject.",
        "not_invited": "De kandidaat is nog niet uitgenodigd voor dit traject.",
        "not_started": "Nog niet gestart",
        "reminder_date": "Herinneringsmail op %{date}",
        "started_at": "Gestart op"
      },
      "enrollments": {
        "finished": "afgerond",
        "not_started": "nog niet gestart",
        "started": "gestart"
      },
      "finished": {
        "empty_list": "Er zijn nog geen afgeronde taken"
      },
      "started": {
        "empty_list": "Er zijn geen gestarte taken"
      },
      "title": "Mijn trajecten",
      "unknown_initials": "n.b."
    },
    "candidates": {
      "advisors": {
        "show": {
          "coach_role": "Coach",
          "email": "E-mail",
          "role": "Rol"
        }
      },
      "confirmations": {
        "advisors": {
          "show": {
            "coach_role": "Coach",
            "email": "E-mail",
            "role": "Rol"
          }
        },
        "contact_people": {
          "show": {
            "client": "Opdrachtgever",
            "contact_person_role": "Contactpersoon",
            "email": "E-mail",
            "role": "Rol"
          }
        },
        "show": {
          "title": "Maak een account"
        }
      },
      "contact_people": {
        "show": {
          "client": "Cliënt",
          "contact_person_role": "Contactpersoon",
          "email": "E-mail",
          "role": "Rol"
        }
      },
      "external_reports": {
        "create": {
          "error": "Het rapport kan niet worden gegenereerd",
          "success": "Het rapport wordt gegenereerd."
        }
      },
      "results": {
        "index": {
          "title": "Persoonlijk resultatenoverzicht"
        }
      },
      "send_mail": {
        "blank_email": "E-mail kan niet worden verzonden, de kandidaat heeft geen e-mailadres",
        "inactive_candidate": "E-mail kan niet worden verzonden, de kandidaat is inactief",
        "message": "Stuur e-mail",
        "resend_welcome": "Verstuur welkomstmail opnieuw",
        "send_welcome": "Verstuur welkomstmail"
      },
      "token_authentications": {
        "new": {
          "invalid": "De wachtwoordloze inloglink is niet meer geldig"
        },
        "update": {
          "copied": "De link voor eenmalig wachtwoordloos inloggen is gekopieerd!"
        }
      }
    },
    "categories": {
      "adaptive": "Adaptieve test",
      "all": "Alle categorieën",
      "e_assessment": "E-Assessment",
      "game": "Psychometrische game",
      "intelligence": "Intelligentie",
      "personality": "Persoonlijkheid",
      "sample_tests": "Voorbeeldtesten",
      "simulation": "Simulatie"
    },
    "clients": {
      "create": {
        "client_created": "De opdrachtgever is aangemaakt",
        "help_text": "Maak hier een nieuwe opdrachtgever aan voor uw organisatie",
        "title": "Nieuwe opdrachtgever"
      },
      "edit": {
        "title": "Bewerk opdrachtgever"
      },
      "form": {
        "labels": "Labels"
      },
      "index": {
        "help_text": "Contactpersonen zijn belanghebbenden in een traject van een kandidaat. Ze kunnen de voortgang van een kandidaat bekijken zonder specifieke details te zien, ze kunnen documenten delen met kandidaten en hun adviseurs en ze kunnen worden gevraagd om documenten goed te keuren. U kunt uw contactpersonen indelen in groepen die 'opdrachtgevers' worden genoemd.",
        "new_button": "Nieuwe opdrachtgever",
        "table_header": [
          "Naam",
          "Contactpersonen",
          "Kandidaten"
        ],
        "title": "Opdrachtgevers"
      },
      "new": {
        "help_text": "Maak hier een nieuwe opdrachtgever aan voor uw organisatie",
        "title": "Nieuwe opdrachtgever"
      },
      "row": {
        "number_of_candidates": "%{number} actief",
        "number_of_contact_persons": "%{number} actief"
      },
      "show": {
        "help_text": "Hier vindt u al uw contactpersonen en kandidaten bij deze opdrachtgever",
        "new_button": "Nieuwe contactpersoon",
        "table_header": [
          "Gegevens",
          "E-mailadres",
          "Status"
        ]
      },
      "update": {
        "client_updated": "De opdrachtgever is bijgewerkt",
        "title": "Bewerk opdrachtgever"
      }
    },
    "comments": {
      "index": {
        "title": "Berichtengeschiedenis"
      }
    },
    "common": {
      "by": "door"
    },
    "competencies": {
      "accuracy": "Nauwkeurigheid",
      "ambition": "Ambitie",
      "assertiveness": "Assertiviteit",
      "collaboration": "Samenwerking",
      "commitment": "Inzet",
      "customer_orientation": "Klantoriëntatie",
      "decisiveness": "Besluitvaardigheid",
      "delegation": "Delegeren",
      "employee_coaching": "Coachen van medewerkers",
      "entrepreneurship": "Ondernemerschap",
      "flexibility": "Flexibiliteit",
      "group_leadership": "Aansturen van groepen",
      "independence": "Zelfstandigheid",
      "individual_leadership": "Aansturen van individuen",
      "initiative": "Initiatief",
      "negotiation": "Onderhandelen",
      "organizational_sensitivity": "Organisatiesensitiviteit",
      "performance": "Optreden",
      "perseverance": "Doorzettingsvermogen",
      "persuasiveness": "Overtuigingskracht",
      "planning_organizing": "Plannen en organiseren",
      "progress_monitoring": "Voortgangscontrole",
      "quality_focus": "Kwaliteitsgerichtheid",
      "relationship_management": "Relatiebeheer",
      "results_orientation": "Resultaatgerichtheid",
      "sensitivity": "Sensitiviteit",
      "sociability": "Sociabiliteit",
      "stress_resistance": "Stressbestendigheid",
      "willingness_to_learn": "Leerbereidheid"
    },
    "competencies_rnd": {
      "aansturenvangroepen": "group_leadership",
      "aansturenvanindividuen": "individual_leadership",
      "ambitie": "ambition",
      "assertiviteit": "assertiveness",
      "besluitvaardigheid": "decisiveness",
      "coachenvanmedewerkers": "employee_coaching",
      "delegeren": "delegation",
      "doorzettingsvermogen": "perseverance",
      "flexibiliteit": "flexibility",
      "initiatief": "initiative",
      "inzet": "commitment",
      "klantorientatie": "customer_orientation",
      "kwaliteitsgerichtheid": "quality_focus",
      "leerbereidheid": "willingness_to_learn",
      "nauwkeurigheid": "accuracy",
      "onderhandelen": "negotiation",
      "ondernemerschap": "entrepreneurship",
      "optreden": "performance",
      "organisatiesensitiviteit": "organizational_sensitivity",
      "overtuigingskracht": "persuasiveness",
      "plannenenorganiseren": "planning_organizing",
      "relatiebeheer": "relationship_management",
      "resultaatgerichtheid": "results_orientation",
      "samenwerking": "collaboration",
      "sensitiviteit": "sensitivity",
      "sociabiliteit": "sociability",
      "stressbestendigheid": "stress_resistance",
      "voortgangscontrole": "progress_monitoring",
      "zelfstandigheid": "independence"
    },
    "components": {
      "iframe": {
        "back": "Terug naar proces"
      },
      "report_visible_for_explanation": "Het rapport kan achteraf alsnog zichtbaar gemaakt worden voor de ander.",
      "update": {
        "error": "Dit element kon niet worden opgeslagen omdat:"
      }
    },
    "consents": {
      "cannot_share": "Unable to share the report.",
      "confirm_retract": "Weet je zeker dat je de toestemming wil intrekken om de resultaten te delen?",
      "consent_retracted": "Toestemming ingetrokken",
      "retract_consent": "Toestemming intrekken",
      "share_consent": "Deel resultaten",
      "shared_successfully": "Rapport is succesvol gedeeld"
    },
    "contact_people": {
      "enrollments": {
        "list": {
          "advisor": "Coach",
          "candidate": "Kandidaat",
          "coadvisor": "Cocoach",
          "contact_person": "Contactpersoon",
          "pages": "Pagina's",
          "status": "Status"
        }
      }
    },
    "current": "Huidig",
    "datatables": {
      "filtered_from_after_count": "resultaten",
      "filtered_from_before_count": "gefilterd uit",
      "loading": "Even laden...",
      "next": "Volgende",
      "previous": "Vorige",
      "showing_page_after_count": "van",
      "showing_page_before_count": "Pagina",
      "zero_records": "Geen resultaten beschikbaar"
    },
    "date": {
      "abbr_day_names": [
        "zo",
        "ma",
        "di",
        "wo",
        "do",
        "vr",
        "za"
      ],
      "abbr_month_names": [
        null,
        "jan",
        "feb",
        "mrt",
        "apr",
        "mei",
        "jun",
        "jul",
        "aug",
        "sep",
        "okt",
        "nov",
        "dec"
      ],
      "day_names": [
        "zondag",
        "maandag",
        "dinsdag",
        "woensdag",
        "donderdag",
        "vrijdag",
        "zaterdag"
      ],
      "formats": {
        "date_with_day": "%A, %e %B %Y",
        "date_without_day": "%e %B %Y",
        "default": "%d-%m-%Y",
        "long": "%e %B %Y",
        "short": "%e %b"
      },
      "month_names": [
        null,
        "januari",
        "februari",
        "maart",
        "april",
        "mei",
        "juni",
        "juli",
        "augustus",
        "september",
        "oktober",
        "november",
        "december"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "ongeveer een uur",
          "other": "ongeveer %{count} uur"
        },
        "about_x_months": {
          "one": "ongeveer een maand",
          "other": "ongeveer %{count} maanden"
        },
        "about_x_years": {
          "one": "ongeveer een jaar",
          "other": "ongeveer %{count} jaar"
        },
        "almost_x_years": {
          "one": "bijna een jaar",
          "other": "bijna %{count} jaar"
        },
        "half_a_minute": "een halve minuut",
        "less_than_x_minutes": {
          "one": "minder dan een minuut",
          "other": "minder dan %{count} minuten"
        },
        "less_than_x_seconds": {
          "one": "minder dan een seconde",
          "other": "minder dan %{count} seconden"
        },
        "over_x_years": {
          "one": "meer dan een jaar",
          "other": "meer dan %{count} jaar"
        },
        "x_days": {
          "one": "%{count} dag",
          "other": "%{count} dagen"
        },
        "x_minutes": {
          "one": "%{count} minuut",
          "other": "%{count} minuten"
        },
        "x_months": {
          "one": "%{count} maand",
          "other": "%{count} maanden"
        },
        "x_seconds": {
          "one": "%{count} seconde",
          "other": "%{count} seconden"
        },
        "x_years": {
          "one": "%{count} jaar",
          "other": "%{count} jaar"
        }
      },
      "prompts": {
        "day": "dag",
        "days": "dagen",
        "hour": "uur",
        "minute": "minuut",
        "month": "maand",
        "months": "maanden",
        "second": "seconde",
        "year": "jaar",
        "years": "jaren"
      }
    },
    "delimit_options_by_commas": "Scheid de verschillende keuzes met komma's (bijvoorbeeld: appel, peer, banaan).",
    "delimit_sections_by_commas": "Scheid de verschillende secties met komma's.",
    "devise": {
      "confirmations": {
        "confirmed": "Je account is bevestigd.",
        "new": {
          "resend_confirmation_instructions": "Verstuur bevestiging-instructies opnieuw"
        },
        "send_instructions": "Je ontvangt via e-mail instructies hoe je je account kan bevestigen.",
        "send_paranoid_instructions": "Als we je e-mailadres terugvinden in onze database, zal je binnen enkele ogenblikken een e-mail ontvangen met instructies hoe je je account kan bevestigen."
      },
      "failure": {
        "already_authenticated": "Je bent reeds aangemeld.",
        "inactive": "Je account is nog niet geactiveerd.",
        "invalid": "Er is geen account gevonden met de opgegeven email/wachtwoord combinatie",
        "last_attempt": "Je hebt nog één poging voordat je account vergrendeld wordt.",
        "locked": "Je account is vergrendeld.",
        "not_found_in_database": "Er is geen account gevonden met de opgegeven email/wachtwoord combinatie",
        "organization_inactive": "Je kunt niet inloggen, de organisatie waar je lid van bent is niet actief",
        "timeout": "Je sessie is verlopen, meld je opnieuw aan om door te gaan.",
        "unauthenticated": "Je dient je aan te melden om door te gaan",
        "unconfirmed": "Je dient eerst je account te bevestigen.",
        "user_status_created": "Je account is nog niet actief",
        "user_status_inactive": "Je account is niet actief",
        "user_status_invited": "Je kunt nog niet inloggen, gebruik de uitnodigingsmail om een wachtwoord aan te maken"
      },
      "mailer": {
        "confirmation_instructions": {
          "action": "Bevestig mijn account",
          "greeting": "Welkom %{recipient}!",
          "instruction": "Je kunt je e-mailadres bevestigen via onderstaande link:",
          "subject": "Bevestiging"
        },
        "email_changed": {
          "greeting": "Beste %{recipient}!",
          "message": "Het e-mailadres van uw account is gewijzigd in %{email}.",
          "message_unconfirmed": "We nemen contact op om je te laten weten dat je emailadres is gewijzigd naar %{email}.",
          "subject": "E-mailadres veranderd"
        },
        "password_change": {
          "greeting": "Hallo %{recipient}!",
          "message": "We stellen je met deze e-mail ervan op de hoogte dat je wachtwoord is gewijzigd.",
          "subject": "Je wachtwoord is gewijzigd"
        },
        "reset_password_instructions": {
          "action": "Verander mijn wachtwoord",
          "greeting": "Hallo %{recipient}!",
          "instruction": "Iemand heeft een link gevraagd om uw wachtwoord te wijzigen; dit kan via onderstaande link.",
          "instruction_2": "Als je dit niet zelf hebt gevraagd, negeer deze mail dan alsjeblieft.",
          "instruction_3": "Je wachtwoord zal niet wijzigen totdat je bovenstaande link hebt gevolgd en een nieuw wachtwoord hebt aangemaakt.",
          "subject": "Instructies om je wachtwoord opnieuw in te stellen"
        },
        "unlock_instructions": {
          "action": "Ontgrendel mijn account",
          "greeting": "Hallo %{recipient}!",
          "instruction": "Je kunt je account ontgrendelen via onderstaande link:",
          "message": "Je account is vergrendeld door te veel mislukte pogingen om in te loggen.",
          "subject": "Ontgrendelinstructies"
        }
      },
      "omniauth_callbacks": {
        "failure": "We konden je niet aanmelden op je %{kind} omdat \"%{reason}\".",
        "success": "Je bent succesvol ingelogd op je %{kind} account."
      },
      "passwords": {
        "edit": {
          "change_my_password": "Verander mijn wachtwoord",
          "change_your_password": "Wijzig je wachtwoord",
          "confirm_new_password": "Bevestig nieuw wachtwoord",
          "new_password": "Nieuw wachtwoord"
        },
        "new": {
          "forgot_your_password": "Wachtwoord vergeten?",
          "send_me_reset_password_instructions": "Stuur me wachtwoord reset instructies"
        },
        "no_token": "Deze pagina is alleen bereikbaar via een wachtwoord vergeten e-mail. Als je wel via een wachtwoord vergeten e-mail komt, zorg er dan voor dat je de volledige URL gebruikt.",
        "send_instructions": "Je ontvangt een e-mail met instructies hoe je je wachtwoord opnieuw kan instellen.",
        "send_paranoid_instructions": "Als we je e-mailadres terugvinden in onze database, zal je binnen enkele ogenblikken via e-mail een link ontvangen om je wachtwoord opnieuw in te stellen.",
        "updated": "Je wachtwoord is gewijzigd. Je bent nu aangemeld.",
        "updated_not_active": "Je wachtwoord is met succes gewijzigd."
      },
      "registrations": {
        "destroyed": "Je account is verwijderd, wellicht tot ziens!",
        "edit": {
          "are_you_sure": "Weet je het zeker?",
          "cancel_my_account": "Annuleer mijn account",
          "currently_waiting_confirmation_for_email": "Aan het wachten op de bevestiging voor: %{email}",
          "leave_blank_if_you_don_t_want_to_change_it": "laat leeg als je het niet wilt wijzigen",
          "title": "Bewerk %{resource}",
          "unhappy": "Niet blij",
          "update": "Bijwerken",
          "we_need_your_current_password_to_confirm_your_changes": "we hebben je huidige wachtwoord nodig om je veranderingen te bevestigen"
        },
        "new": {
          "sign_up": "Maak account aan"
        },
        "signed_up": "Je bent ingeschreven.",
        "signed_up_but_inactive": "Je bent ingeschreven, maar we konden je niet inloggen omdat je account nog niet is geactiveerd.",
        "signed_up_but_locked": "Je bent ingeschreven, maar we konden je niet inloggen omdat je account is vergrendeld.",
        "signed_up_but_unconfirmed": "Een e-mail met een confirmatielink is naar je e-mailadres gestuurd. Open de link in je browser om je account te activeren.",
        "update_needs_confirmation": "Je account is bijgewerkt, maar we moeten je e-mailadres nog valideren. Een e-mail met een confirmatielink is naar je e-mailadres gestuurd. Open de link in je browser om je e-mailadres te confirmeren.",
        "updated": "Je accountgegevens zijn opgeslagen.",
        "updated_but_not_signed_in": "Je account is succesvol bijgewerkt, maar omdat je wachtwoord is aangepast moet je opnieuw inloggen."
      },
      "sessions": {
        "already_signed_out": "Je bent succesvol uitgelogd.",
        "new": {
          "sign_in": "Inloggen"
        },
        "signed_in": "Je bent succesvol ingelogd.",
        "signed_out": "Je bent succesvol uitgelogd."
      },
      "shared": {
        "links": {
          "back": "Terug",
          "didn_t_receive_confirmation_instructions": "Geen bevestigingsmail ontvangen?",
          "didn_t_receive_unlock_instructions": "Geen ontgrendel-instructies ontvangen?",
          "forgot_your_password": "Wachtwoord vergeten?",
          "sign_in": "Inloggen",
          "sign_in_with_provider": "Inloggen met %{provider}",
          "sign_up": "Maak account aan"
        },
        "minimum_password_length": {
          "one": "(minimaal %{count} karakter)",
          "other": "(minimaal %{count} karakters)"
        }
      },
      "sign_out": "Uitloggen",
      "two_factor_authentication": {
        "attempt_failed": "Foutieve code.",
        "code_has_been_sent": "Je twee staps verificatie code is verstuurd.",
        "contact_administrator": "Neem contact op met een organisatiebeheerder.",
        "max_login_attempts_reached": "Toegang volledig geweigerd omdat je het maximaal aantal pogingen hebt bereikt",
        "success": "Two factor authentication geslaagd."
      },
      "unlocks": {
        "new": {
          "resend_unlock_instructions": "Verstuur ontgrendel-instructies opnieuw"
        },
        "send_instructions": "Je ontvangt via e-mail instructies hoe je je account kan ontgrendelen.",
        "send_paranoid_instructions": "Als we je e-mailadres terugvinden in onze database, zal je binnen enkele ogenblikken een e-mail ontvangen met instructies hoe je je account kan ontgrendelen.",
        "unlocked": "Je account is ontgrendeld. Je kan nu weer inloggen."
      }
    },
    "discounts": {
      "create": {
        "created": "De kortingen zijn aangemaakt"
      },
      "destroy": {
        "already_started": "Deze korting is al van start gegaan."
      },
      "index": {
        "new_button": "Nieuwe kortingen",
        "table_header": [
          "Op",
          "Percentage",
          "Startdatum",
          "Einddatum",
          "Acties"
        ],
        "title": "Kortingen"
      },
      "new": {
        "premium_components": "Premium elementen",
        "subscriptions": "Abonnementen",
        "title": "Nieuwe kortingen voor %{organization}"
      },
      "row": {
        "discount_delete": "Weet u zeker dat u deze korting wilt verwijderen?"
      }
    },
    "education_levels": {
      "academic": "WO/Master",
      "higher_vocational": "HBO",
      "pre_vocational": "VMBO",
      "secondary_vocational": "MBO"
    },
    "education_levels_rnd": {
      "academic": "wo",
      "higher_vocational": "hbo",
      "pre_vocational": "vmbo",
      "secondary_vocational": "mbo"
    },
    "education_levels_rnd_keys": {
      "hbo": "higher_vocational",
      "mbo": "secondary_vocational",
      "vmbo": "pre_vocational",
      "wo": "academic"
    },
    "enrollers": {
      "enroll": {
        "cannot_enroll": "U kunt zich momenteel niet inschrijven voor dit proces",
        "enrolled": "U neemt nu deel aan dit proces"
      },
      "enroll_existing": {
        "cannot_enroll": "U kunt zich momenteel niet inschrijven voor dit proces",
        "enrolled": "U neemt nu deel aan dit proces",
        "enrolling_user_is_a_contact_person": "Als contactpersoon kunt u zelf niet deelnemen aan processen",
        "enrolling_user_is_enrollers_advisor": "Als coach van deze inschrijflink kunt u zelf niet deelnemen aan dit proces"
      },
      "enroll_test": {
        "cannot_enroll": "U kunt zich momenteel niet inschrijven voor dit proces"
      },
      "form": {
        "accept": "Ik accepteer",
        "and": "en",
        "participate": "Deelnemen",
        "privacy_label": "het <a class='btn-link' href='%{privacy_link}' target='_blank'>privacy statement</a>",
        "terms_label": "de <a class='btn-link' href='%{terms_link}' target='_blank'>algemene voorwaarden</a>"
      },
      "show": {
        "deactivated": "Deze inschrijflink is niet meer beschikbaar",
        "sign_in_or_sign_up": "Als u al een account heeft kunt u <a class='btn-link' href='%{login_link}'>inloggen</a> om deel te nemen aan dit proces. Zo niet, dan dient u zich eerst via onderstaand formulier aan te melden bij de Assessment Platform."
      },
      "signed_in_form": {
        "already_enrolled": "U neemt al deel aan dit proces, ga verder vanuit <a class='alert-link' href='%{link}'>%{text}</a>",
        "participate": "Deelnemen",
        "participate_as": "Via onderstaande knop kunt u direct deelnemen aan dit proces."
      },
      "test_only_form": {
        "accept": "Ik accepteer",
        "and": "en",
        "participate": "Deelnemen",
        "privacy_label": "het <a class='btn-link' href='%{privacy_link}' target='_blank'>privacy statement</a>",
        "terms_label": "de <a class='btn-link' href='%{terms_link}' target='_blank'>algemene voorwaarden</a>"
      },
      "tests_assigned": {
        "email_sent": "Bedankt voor je deelname. Je ontvangt een e-mail van no-reply@test-toolkit.nl met instructies hoe je kan beginnen."
      }
    },
    "enrollment_pages": {
      "control_panel": {
        "accept_page": "Stap goedkeuren",
        "back_to_tracks_as_advisor": "Terug naar het kandidaatsoverzicht",
        "back_to_tracks_as_candidate": "Terug naar mijn trajectenlijst",
        "comments": "Berichten",
        "finish_enrollment": "Proces afronden",
        "finish_enrollment_confirm": "Weet u zeker dat u het proces wilt afronden? Een afgerond proces kan niet meer bewerkt worden door de kandidaat of coach.",
        "finish_page": "Stap afronden",
        "finish_page_and_next": "Afronden en volgende",
        "next_page": "Volgende stap",
        "next_page_unavailable": "Volgende stap nog niet beschikbaar...",
        "reject_page": "Stap afwijzen",
        "reopen_enrollment": "Proces heropenen",
        "reopen_enrollment_confirm": "Weet u zeker dat u het proces wilt heropenen? De kandidaat zal weer wijzigingen kunnen maken.",
        "submit_page": "Stap inleveren",
        "submit_page_and_next": "Inleveren en volgende"
      },
      "insert_optional": {
        "cannot_enroll": "De pagina kon niet ingevoegd worden omdat het een assessment bevat die momenteel niet in het abonnement beschikbaar is.",
        "page_inserted": "De stap '%{page_title}' is ingevoegd"
      },
      "show": {
        "insert": "Invoegen",
        "is_optional": "Deze stap is momenteel geen onderdeel van dit proces, maar kan optioneel nog ingevoegd worden",
        "original_page_deleted": "Deze optionele stap kan niet langer ingevoegd worden, omdat het origineel verwijderd is",
        "page_insert_confirm_part_1": "Invoeging is onomkeerbaar",
        "page_insert_confirm_part_2": "Weet u zeker dat u deze stap op de aangegeven plek in het proces wilt invoegen?",
        "with_premium_components": {
          "one": "en kost %{costs} vanwege 1 premium element",
          "other": "en kost %{costs} vanwege %{count} premium elementen"
        }
      }
    },
    "enrollments": {
      "confirm_withdraw": "Weet u zeker dat u dit proces wilt terugtrekken?",
      "create": {
        "candidate_double_invited": "De kandidaat is per e-mail uitgenodigd en geattendeerd op het nieuw toegekende proces",
        "candidate_invited": "De kandidaat is per e-mail geattendeerd op het nieuw toegekende proces",
        "cannot_enroll": "U kunt dit proces momenteel niet toekennen",
        "enrollment_created": "Het proces is toegekend. De kandidaat is nog niet op de hoogte gebracht."
      },
      "destroy": {
        "candidate_enrollment_already_started": "Dit proces kan niet worden teruggetrokken omdat het proces al gestart is",
        "candidate_enrollment_destroyed": "Het proces is teruggetrokken"
      },
      "finish": {
        "enrollment_finished": "Het proces is afgerond",
        "finish_enrollment_failed": "Het proces kan niet worden afgerond omdat het proces niet gestart of al afgerond is"
      },
      "navbar_page": {
        "not_available_yet": "Beschikbaar na afronden voorgaande stappen"
      },
      "new": {
        "help_text": "Selecteer een van uw gepubliceerde processen om toe te kennen aan de kandidaat",
        "table_header": [
          "Proces",
          "Acties"
        ],
        "title": "Proces toekennen"
      },
      "progress": {
        "done": "afgerond",
        "rejected": "opnieuw",
        "submitted": "ingeleverd"
      },
      "reopen": {
        "enrollment_reopened": "Het proces is heropend",
        "reopen_enrollment_failed": "Het proces kan niet worden heropend omdat het proces niet afgerond is"
      },
      "row": {
        "add": "Ken toe",
        "enrollment_create_confirm": {
          "one": "Het geselecteerde proces bevat 1 premium element, dat %{costs} kost. Weet u zeker dat u het wilt toekennen?",
          "other": "Het geselecteerde proces bevat %{count} premium elementen, die samen %{costs} kosten. Weet u zeker dat u het wilt toekennen?"
        },
        "invite_later": "Nodig later uit",
        "view": "Bekijk"
      }
    },
    "errors": {
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "moet worden geaccepteerd",
        "advisor_cannot_be_coadvisor": "Het is niet mogelijk om een geselecteerde adviseur als co-adviseur aan te wijzen",
        "already_confirmed": "is reeds bevestigd",
        "already_generated": "is al gegenereerd",
        "already_passed": "ligt in het verleden",
        "aspect_ratio_is_not": "moet een beeldverhouding hebben van %{aspect_ratio}",
        "aspect_ratio_not_landscape": "moet een liggende afbeelding zijn",
        "aspect_ratio_not_portrait": "moet een staande afbeelding zijn",
        "aspect_ratio_not_square": "moet een vierkante afbeelding zijn",
        "aspect_ratio_unknown": "heeft een onbekende beeldverhouding",
        "assessment_platform_link_or_passwordless_login_link_missing": "is ongeldig omdat het een assessment_platform_link of een passwordless_login_link moet bevatten",
        "attachment_size": "Moet kleiner zijn dan 50 MB",
        "before_start_date": "moet na de startdatum liggen",
        "blank": "moet opgegeven zijn",
        "candidate_limit_reached": "Uw account kan niet geactiveerd worden omdat het maximaal aantal actieve kandidaten is bereikt",
        "cannot_downgrade_before_year": "moet ten minste een jaar na de startdatum van het vorige abonnement zijn",
        "confirmation": "komt niet overeen met %{attribute}",
        "confirmation_period_expired": "moet binnen %{period} worden bevestigd, plaats a.u.b. een nieuw verzoek",
        "contains_unpermitted_variables": "is ongeldig omdat het niet-toegestane variabelen bevat",
        "content_type_blacklist_error": "SVG afbeeldingen worden niet ondersteund",
        "content_type_invalid": "heeft een ongeldig inhoudstype",
        "content_type_is_invalid": "Alleen csv bestanden worden ondersteund",
        "content_type_whitelist_error": "Het geüploade bestand is geen afbeelding",
        "create_otp_organization_failed": "Er trad een probleem op bij het aanmaken van de organisatie in de Test-Toolkit",
        "current_included": "mag niet zichzelf bevatten",
        "destroy_otp_organization_failed": "Er trad een probleem op bij het verwijderen van de organisatie in de Test-Toolkit",
        "dimension_height_equal_to": "hoogte moet gelijk zijn aan %{length} pixels",
        "dimension_height_greater_than_or_equal_to": "hoogte moet groter of gelijk zijn aan %{length} pixels",
        "dimension_height_inclusion": "hoogte niet tussen de %{min} en %{max} pixels",
        "dimension_height_less_than_or_equal_to": "hoogte moet kleiner of gelijk zijn aan %{length} pixels",
        "dimension_max_inclusion": "moet kleiner of gelijk zijn aan %{width} x %{height} pixels",
        "dimension_min_inclusion": "moet groter of gelijk zijn aan %{width} x %{height} pixels",
        "dimension_width_equal_to": "breedte moet gelijk zijn aan %{length} pixels",
        "dimension_width_greater_than_or_equal_to": "breedte moet groter of gelijk zijn aan %{length} pixels",
        "dimension_width_inclusion": "breedte niet tussen de %{min} en %{max} pixels",
        "dimension_width_less_than_or_equal_to": "breedte moet kleiner of gelijk zijn aan %{length} pixels",
        "email_invalid": "moet de volgende opbouw hebben: naam@domein.nl",
        "empty": "moet opgegeven zijn",
        "equal_to": "moet gelijk zijn aan %{count}",
        "even": "moet even zijn",
        "exclusion": "is gereserveerd",
        "expired": "is verlopen, vraag een nieuwe aan",
        "file_size_not_between": "bestandsgrootte moet tussen %{min_size} en %{max_size} liggen (huidige grootte is %{file_size})",
        "file_size_not_greater_than": "bestandsgrootte moet groter zijn dan %{min_size} (huidige grootte is %{file_size})",
        "file_size_not_greater_than_or_equal_to": "bestandsgrootte moet groter zijn dan of gelijk zijn aan %{min_size} (huidige grootte is %{file_size})",
        "file_size_not_less_than": "bestandsgrootte moet kleiner zijn dan %{max_size} (huidige grootte is %{file_size})",
        "file_size_not_less_than_or_equal_to": "bestandsgrootte moet kleiner zijn dan of gelijk zijn aan %{max_size} (huidige grootte is %{file_size})",
        "free_included": "mag geen gratis abonnementen bevatten",
        "greater_than": "moet groter zijn dan %{count}",
        "greater_than_or_equal_to": "moet groter dan of gelijk zijn aan %{count}",
        "image_metadata_missing": "is geen geldige afbeelding",
        "image_not_processable": "is geen geldige afbeelding",
        "inclusion": "is niet in de lijst opgenomen",
        "incomplete_post_rule": "De onderstaande deelregel is onvolledig",
        "insufficient_credits": "Het rapport kan niet gegenereerd worden omdat er niet voldoende prepaid credits zijn.",
        "internet_explorer_error": "De browser die u gebruikt (Internet Explorer) wordt niet langer ondersteund. Gebruik een andere browser.",
        "invalid": "is ongeldig",
        "invalid_annual_end_date": "van %{subscription_type} moet een veelvoud van %{period_in_months} maanden na zijn startdatum zijn (- 1 dag)",
        "invalid_annual_start_date": "van %{subscription_type} moet een veelvoud van %{period_in_months} maanden voor zijn einddatum zijn (+ 1 dag)",
        "invalid_category": "%{value} categorie is ongeldig",
        "invalid_date_range": "is ongeldig, moet na de uitnodigingsdatum zijn",
        "invalid_otp": "De ingevulde code is onjuist",
        "invalid_subscription_type": "wijzigen naar %{subscription_type} is niet toegestaan",
        "invalid_vat": "is geen geldig %{country_adjective} btw nummer",
        "language_not_available": "is niet beschikbaar in de organisatie",
        "less_than": "moet minder zijn dan %{count}",
        "less_than_or_equal_to": "moet minder dan of gelijk zijn aan %{count}",
        "limit_out_of_range": "totaal aantal valt buiten het vereiste bereik",
        "login_not_allowed": "Uw organisatie of account is niet actief",
        "maximum_number_of_advisors_reached": "Het is niet mogelijk een nieuwe coach toe te voegen omdat het maximaal aantal coaches is bereikt",
        "maximum_number_of_candidates_reached": "Het is niet mogelijk een nieuwe kandidaat toe te voegen omdat het maximaal aantal kandidaten is bereikt",
        "mini_magick_processing_error": "Manipulatie met MiniMagick mislukte",
        "model_invalid": "Validatie mislukt: %{errors}",
        "mollie_error": "Er trad een fout op bij onze betaalprovider Mollie; probeer het later nog een keer",
        "more_than_two_decimals": "mag niet meer dan 2 decimalen hebben",
        "not_a_number": "is geen getal",
        "not_an_integer": "moet een geheel getal zijn",
        "not_found": "niet gevonden",
        "not_locked": "is niet gesloten",
        "not_saved": {
          "one": "Een fout verhinderde het opslaan van deze %{resource}:",
          "other": "%{count} fouten verhinderde het opslaan van deze %{resource}:"
        },
        "odd": "moet oneven zijn",
        "one_pending_subscription": "is niet toegestaan omdat er al een abonnement gepland is",
        "organization_address_missing": "adres moet worden ingesteld om een betaald abonnement toe te wijzen",
        "organization_must_be_ixly": "Het is niet mogelijk een Ixly-medewerker aan te maken buiten de Ixly organisatie",
        "organization_vat_number_missing": "Om een betaald abonnement toe te wijzen, moet een BTW-nummer worden ingesteld",
        "other_than": "moet anders zijn dan %{count}",
        "overlaps_existing_dates": "mag niet overlappen met bestaande datums",
        "present": "moet leeg zijn",
        "required": "moet bestaan",
        "restrict_dependent_destroy": {
          "has_many": "kan item niet verwijderen omdat er afhankelijke %{record} bestaan",
          "has_one": "kan item niet verwijderen omdat er een afhankelijke %{record} bestaat"
        },
        "subscriptions_attached": "kan niet worden gewijzigd omdat er abonnementen zijn met deze vorm",
        "taken": "is al in gebruik",
        "too_long": {
          "one": "is te lang (maximaal %{count} teken)",
          "other": "is te lang (maximaal %{count} tekens)"
        },
        "too_short": {
          "one": "is te kort (minimaal %{count} teken)",
          "other": "is te kort (minimaal %{count} tekens)"
        },
        "url_invalid": "moet de volgende opbouw hebben: https://www.example.com/pad/naar/pagina",
        "user_limit_reached": "Uw account kan niet geactiveerd worden omdat het maximaal aantal coaches is bereikt",
        "vies_down": "We kunnen uw btw-nummer niet bevestigen: de VIES-service is momenteel niet beschikbaar. Probeer het later opnieuw.",
        "wrong_length": {
          "one": "heeft onjuiste lengte (moet %{count} teken lang zijn)",
          "other": "heeft onjuiste lengte (moet %{count} tekens lang zijn)"
        }
      },
      "template": {
        "body": "Er zijn problemen met de volgende velden:",
        "header": {
          "one": "%{model} niet opgeslagen: 1 fout gevonden",
          "other": "%{model} niet opgeslagen: %{count} fouten gevonden"
        }
      }
    },
    "filterable": {
      "no_results": "Geen resultaten",
      "reset": "reset filters",
      "title": "Filteren"
    },
    "flash": {
      "try_again": "Probeer het opnieuw"
    },
    "helpers": {
      "select": {
        "prompt": "maak een keuze",
        "sr_only": "selecteer"
      },
      "submit": {
        "create": "Aanmaken",
        "submit": "%{model} opslaan",
        "update": "Opslaan"
      }
    },
    "i18n": {
      "plural": {
        "keys": [
          "one",
          "other"
        ]
      }
    },
    "impersonated_as": "Je bent ingelogd als %{name}.",
    "impersonation_ended": "Je bent nu weer als jezelf ingelogd.",
    "import": {
      "current_template": "Template",
      "download_template": "Download template",
      "errors": {
        "duplicate": "Duplicaat"
      },
      "import/candidate_file": {
        "candidate": {
          "account": {
            "email": "E-mail %{message}"
          },
          "advisor": "Adviseur %{message}",
          "language": "Taal %{message}"
        },
        "file": {
          "headers": "Kolomkoppen onjuist %{message}"
        }
      },
      "import/organization_file": {
        "file": {
          "headers": "Kolomkoppen onjuist %{message}",
          "unique": "Organisatie '%{organization_name}' bestaat al. (api_organization_id: %{api_organization_id}"
        }
      },
      "labels": {
        "description": "Labels",
        "placeholder": "Voer labels in"
      },
      "loading": "Even laden...",
      "processing": "Het bestand wordt verwerkt. U kunt deze pagina nu sluiten.",
      "status": {
        "errors": "Problemen",
        "existed": "Onveranderd",
        "invalid": "Ongeldig",
        "new": "Nieuw",
        "previous_data": "Was hiervoor",
        "updated": "Veranderd"
      },
      "store": "Opslaan",
      "title": "CSV import",
      "upload": "Upload CSV"
    },
    "info": {
      "error_occured": "Er ging iets mis. Stuur deze foutmelding door naar uw coach of organisatiemanager.",
      "flagged_elements": "Gemarkeerde pagina's bevatten gemarkeerde elementen die aanvullende instellingen nodig hebben voordat de kandidaat het proces kan beginnen.",
      "no_results_found": "Geen resultaten gevonden",
      "review_required": "Een review is benodigd. De coach moet het assessment beoordelen voordat er rapportopties beschikbaar worden.",
      "review_started": "Rond de review af in het andere tabblad en herlaad kort daarna deze pagina.",
      "verification_planned": "De kandidaat moet de verificatietest starten.",
      "verification_required": "Verificatie is benodigd. De coach moet het assessment controleren voordat er rapportopties beschikbaar worden.",
      "verification_started": "De kandidaat moet de verificatietest afronden voordat er nieuwe rapporten kunnen worden gedownload."
    },
    "invoice_kind": {
      "administrative_costs": "Administratieve kosten",
      "change_internal_limit": "Aanpassing interne limiet",
      "change_mandate": "Autorisatiebetaling",
      "extra_external_credit": "Verhoging externe tegoed",
      "fixed_price": "Afgeronde assessments",
      "frequent_credits": "Creditgebruik",
      "incidental_credits": "Creditbundel",
      "initial_authorization": "Initiële autorisatie",
      "intensive_credits": "Creditgebruik",
      "monthly_bill": "Maandelijkse afschrijving",
      "premium_components": "Premium elementen"
    },
    "invoice_status": {
      "canceled": "geannuleerd",
      "charged_back": "teruggeboekt",
      "closed": "geannuleerd",
      "expired": "verlopen",
      "failed": "mislukt",
      "open": "open",
      "paid": "betaald",
      "processed": "doorgevoerd",
      "refunded": "teruggestort",
      "revoked": "geannuleerd",
      "sent": "verstuurd",
      "settled": "verrekend",
      "unconfirmed": "onbevestigd"
    },
    "invoices": {
      "no_longer_unconfirmed": "Deze betalingsopdracht is intussen al bevestigd",
      "show": {
        "administrative_costs": "Administratieve kosten",
        "annual_fee": "Periodieke abonnementskosten",
        "back_to_account": "(klik hier om terug te gaan naar uw facturering)",
        "change_internal_limit_explanation": "Aanpassingen hebben slechts invloed op de kosten van de huidige maand als de limiet verhoogd wordt naar een deze maand nog niet bereikte hoogte. Het verschil met de hiervoor hoogste limiet (%{previous_limit}) wordt dan bij de volgende maandelijkse factuur verrekend, proportioneel aan het aantal dagen dat de maand nog duurde.",
        "confirm_direct_debit": "Afschrijven van x-%{mandate_string}",
        "confirm_direct_invoice": "Deze factuur ontvangen",
        "confirm_direct_payment": "Naar het betalingsscherm",
        "costs_next_months": "Vanaf de komende maand te betalen",
        "costs_now": "Nu direct te betalen",
        "credit_charge": "Credits",
        "external_user_spots": "Actieve kandidaten (maandprijs)",
        "external_user_spots_partial": {
          "general": "Actieve kandidaten",
          "one": "Actieve kandidaten (1 dag)",
          "other": "Actieve kandidaten (%{count} dagen)"
        },
        "fill_out_address": "We missen adresgegevens die benodigd zijn voor het vesturen van een correcte factuur voor je aankoop. Controleer en corrigeer de gegevens hieronder om een aankoop te kunnen doen.",
        "internal_user_spots": "Actieve coaches (maandprijs)",
        "internal_user_spots_partial": {
          "general": "Actieve coaches",
          "one": "Actieve coaches (1 dag)",
          "other": "Actieve coaches (%{count} dagen)"
        },
        "internal_users": "Interne gebruikersplekken (maandprijs)",
        "monthly_bill": "Maandelijkse factuur voor %{month} %{year}",
        "premium_components": "Toekenning van premium elementen: %{kind}",
        "premium_components_invoice": "Toekenning van premium elementen",
        "status_flash_part_1": "Deze betalingsopdracht werd gegeven door",
        "status_flash_part_2": "en is %{status} op %{updated_at}",
        "system_initiated": "Assessment Platform",
        "table_header": [
          "Datum",
          "Product",
          "Kostenplaats",
          "Stukprijs",
          "Aantal",
          "Bedrag"
        ],
        "terms_and_services": "door te betalen accepteert u automatisch",
        "terms_and_services_link": "de algemene voorwaarden van Ixly",
        "title": "Bevestig uw opdracht",
        "total_with_vat": "Totaal inclusief btw",
        "total_without_vat": "Totaal exclusief btw",
        "vat": "%{vat}% btw"
      }
    },
    "ixly": {
      "accounts": {
        "add": "Account aanmaken",
        "index": {
          "title": "Alle Accounts"
        }
      },
      "advisors": {
        "create": {
          "success": "Adviseur aangemaakt!",
          "title": "Nieuwe adviseur toevoegen"
        },
        "edit": {
          "title": "Adviseur bewerken"
        },
        "new": {
          "title": "Nieuwe adviseur toevoegen"
        },
        "update": {
          "success": "Adviseur gewijzigd!",
          "title": "Adviseur bewerken"
        }
      },
      "candidates": {
        "edit": {
          "title": "Kandidaat bewerken"
        },
        "index": {
          "title": "Alle kandidaten"
        }
      },
      "discounts": {
        "create": {
          "created": "De kortingen zijn aangemaakt",
          "title": "Nieuwe korting"
        },
        "destroy": {
          "already_started": "Deze korting is al van start gegaan.",
          "destroyed": "Korting verwijderd"
        },
        "edit": {
          "title": "Bewerk korting"
        },
        "index": {
          "actions": "Acties",
          "add": "Korting toevoegen",
          "end_date": "Einddatum",
          "help_text": "Een overzicht van alle kortingen. Gebruik de filters voor de kortingen voor een organisatie of een prijstype te zien.",
          "on": "Op",
          "organization": "Organisatie",
          "percentage": "Percentage",
          "start_date": "Startdatum",
          "title": "Kortingen",
          "true": "Op"
        },
        "new": {
          "title": "Nieuwe korting"
        },
        "update": {
          "title": "Bewerk korting",
          "updated": "De korting is bijgewerkt"
        }
      },
      "entries": {
        "create": "Coulance"
      },
      "fixed_prices": {
        "create": {
          "already_exists": "Vaste prijs voor deze organisatie en taak bestaat al",
          "created": "Nieuwe vaste prijs gecreëerd"
        },
        "destroy": {
          "destroyed": "De vaste prijs is vernietigd"
        },
        "edit": {
          "title": "Vaste prijs bewerken"
        },
        "index": {
          "add": "Vaste prijs toevoegen",
          "help_text": "Een overzicht van alle vaste prijzen. Gebruik de filters om de vaste prijzen voor een specifieke organisatie of taak te zien.",
          "title": "Vaste prijzen"
        },
        "list": {
          "description": "Omschrijving op de factuur",
          "organization": "Organisatie",
          "price": "Vaste prijs",
          "task": "Taak",
          "task_price": "Standaard taakprijs"
        },
        "new": {
          "title": "Nieuwe vaste prijs"
        },
        "update": {
          "updated": "De vaste prijs is bijgewerkt"
        }
      },
      "import_files": {
        "import_file": {
          "delete": "Verwijderen",
          "delete_confirm": "Weet u zeker dat u deze import wilt verwijderen?",
          "failed": "Mislukt",
          "original": "Orgineel",
          "processed": "Verwerkt"
        },
        "index": {
          "title": "Geïmporteerde CSV bestanden"
        },
        "new": {
          "title": "Importeer CSV bestand"
        }
      },
      "integrations": {
        "edit": {
          "title": "Test-Toolkit integratie"
        },
        "form": {
          "api_anonymous_explanation": "Als u deze optie selecteert, dan worden gebruikersnamen niet gedeeld met de Test-Toolkit",
          "checking_integration": "API status wordt gecontroleerd"
        },
        "link_testtoolkit": "Bekijk de gekoppelde organisatie in de Test-Toolkit",
        "show": {
          "api_status": {
            "correct": "De organisatie heeft een werkende verbinding met de Test-Toolkit",
            "incorrect": "De verbinding met de Test-Toolkit API heeft problemen, neem contact op met het SD team als het probleem blijft voordoen.",
            "new": "Api is nog niet geconfigureerd. Voeg een api gebruiker, api key en organisatie uuid van de Test-Toolkit organisatie toe om een access token te genereren."
          }
        }
      },
      "invoices": {
        "index": {
          "title": "Facturen"
        },
        "stats": {
          "last_month_amount": "Vorige maand",
          "last_month_count": "Facturen (vorige maand)",
          "search_results": "Zoekresultaten",
          "upcoming_candidate_assessment_entry_count": "Assessment (aankomend)",
          "upcoming_candidate_assessment_program_entry_count": "Programmas (aankomend)",
          "upcoming_candidate_report_entry_count": "Kandidaat rapporten (aankomend)",
          "upcoming_leniency_entry_count": "Leniency (aankomend)"
        }
      },
      "ixly_employees": {
        "create": {
          "created": "Ixly-medewerker succesvol aangemaakt <a class='underline' href='%{link}'>%{name}</a>."
        },
        "edit": {
          "title": "Ixly-medewerker bewerken"
        },
        "new": {
          "title": "Ixly-medewerker toevoegen"
        },
        "update": {
          "success": "Wijzigingen aan Ixly-medewerker opgeslagen"
        }
      },
      "job_profile_templates": {
        "create": {
          "success": "Het functieprofiel template is succesvol aangemaakt",
          "title": "Nieuw Functieprofiel template"
        },
        "destroy": {
          "success": "Functieprofiel template verwijderd"
        },
        "edit": {
          "title": "Bewerk Functieprofiel template"
        },
        "index": {
          "title": "Functieprofiel templates"
        },
        "new": {
          "title": "Nieuw Functieprofiel template"
        },
        "update": {
          "success": "Functieprofiel template is succesvol gewijzigd",
          "title": "Bewerk Functieprofiel template"
        }
      },
      "leniency_entries": {
        "create": {
          "created": "Coulance transactie is succesvol aangemaakt.",
          "title": "Nieuwe coulance transactie"
        },
        "new": {
          "form": {
            "in_euro": "Bedrag in Euro"
          },
          "title": "Nieuwe coulance transactie"
        }
      },
      "mail_templates": {
        "mark_as_template": {
          "marked": "Het e-mail template %{title} is gemarkeerd als template"
        },
        "unmark_as_template": {
          "unmarked": "Het e-mail template %{title} is niet meer gemarkeerd als template"
        }
      },
      "migrated_organizations": {
        "index": {
          "title": "Gemigreerde organisaties"
        }
      },
      "migration_organizations": {
        "create": {
          "success": "Migratie organisatie succesvol aangemaakt"
        },
        "index": {
          "title": "Te migreren organisaties"
        },
        "new": {
          "title": "Nieuwe TTK organisatie migreren"
        },
        "reset": {
          "reset": "Migratie organisatie hersteld naar 'created'"
        },
        "run_migration": {
          "run_migration": "Migreren is gestart!"
        },
        "show": {
          "title": "Details voor migratie organisatie %{name}"
        }
      },
      "organization_csv_imports": {
        "import_file": {
          "log": "Log",
          "original": "Original"
        },
        "index": {
          "list": "Lijst van organization import bestanden",
          "title": "Organization CSV imports"
        },
        "new": {
          "title": "Organization CSV import"
        }
      },
      "organization_settings": {
        "edit": {
          "title": "Ixly-instellingen"
        }
      },
      "organization_statistics": {
        "customer_credits": {
          "index": {
            "candidate_assessment_entry": "Assessment",
            "candidate_assessment_program_entry": "Assessment Programma",
            "candidate_report_entry": "Rapport (Test-only)",
            "generated_report_entry": "Rapport (Processen)",
            "leniency_entry": "Coulance",
            "list": {
              "balance_at": "Credit balans op %{date}:",
              "candidate": "Kandidaat",
              "cost_center": "Kostenplaats",
              "credits": "Aantal",
              "date": "Datum",
              "description": "Omschrijving",
              "entry_type": "Transactie type",
              "no_entries": "In deze periode zijn er geen credits verbruikt"
            },
            "order": {
              "frequent_credits": "Maandelijkse afschrijving voor gebruikte credits",
              "incidental_credits": "Creditbundel aankoop",
              "intensive_credits": "Maandelijkse afschrijving voor gebruikte credits"
            },
            "order_entry": "Bestelling",
            "title": "Credit overzicht"
          }
        },
        "finished_assessments": {
          "index": {
            "title": "Afgeronde Assessments"
          },
          "list": {
            "number_finished": "Aantal afgerond",
            "task_key": "Taak key",
            "task_name": "Taak naam"
          }
        },
        "premium_components": {
          "index": {
            "list": {
              "number_assigned": "Aantal toegekend",
              "premium_component": "Premium Component"
            },
            "title": "Toegekende Premium Componenten"
          }
        }
      },
      "organizations": {
        "create": {
          "organization_created": "De organisatie is aangemaakt! <a class='alert-link' href='%{link}'>Start een abonnement</a> zodat haar gebruikers kunnen inloggen",
          "title": "Nieuwe organisatie"
        },
        "destroy": {
          "organization_deleted": "De organisatie is verwijderd",
          "organization_deletion_failed": "De organisatie kon niet verwijderd worden"
        },
        "index": {
          "add": "Organisatie toevoegen",
          "statistics_button": "Gebruik",
          "subscription_button": "Abonnementen",
          "title": "Organisaties"
        },
        "new": {
          "title": "Nieuwe organisatie"
        }
      },
      "prices": {
        "create": {
          "title": "Nieuwe prijs"
        },
        "edit": {
          "title": "Prijs bewerken"
        },
        "index": {
          "help_text": "Hier zijn lijst met prijzen",
          "title": "Prijzen"
        },
        "new": {
          "title": "Nieuwe prijs"
        },
        "show": {
          "amc_price": "AMC prijs",
          "bov_price": "BOV prijs",
          "fit_price": "FIT prijs",
          "help_text": "Prijs details",
          "job_market_opportunities_price": "Arbeidsmarkt kansen prijs",
          "job_suggestions_price": "Job suggesties prijs",
          "jobfeed_price": "Jobfeed-prijs",
          "monthly_fee_per_coach": "Maandelijkse vergoeding per coach",
          "monthly_fee_per_participant": "Maandelijkse bijdrage per kandidaat",
          "title": "Prijs"
        },
        "update": {
          "title": "Prijs bewerken"
        }
      },
      "products": {
        "create": {
          "created": "Het product is aangemaakt"
        },
        "deactivate": {
          "deactivated": "Het product is gedeactiveerd"
        },
        "destroy": {
          "delete_confirm": "Weet u zeker dat u dit product wilt verwijderen?",
          "deleted": "Het product is verwijderd"
        },
        "edit": {
          "title": "Bewerk product"
        },
        "index": {
          "help_text": "Hier is de lijst met alle producten",
          "title": "Producten"
        },
        "list": {
          "actions": "Acties",
          "name": "Naam",
          "status": "Status",
          "status_changed_at": "Status veranderd op",
          "updated_at": "Bijgewerkt op"
        },
        "new": {
          "title": "Nieuw product"
        },
        "reactivate": {
          "reactivated": "Het product is geheractiveerd"
        },
        "update": {
          "updated": "Het product is bijgewerkt"
        }
      },
      "subscription_type_products": {
        "create": {
          "created": "De producten zijn toegevoegd aan de abonnementsvorm",
          "products_blank": "Er waren geen producten geselecteerd"
        },
        "form": {
          "add_module": "Product toevoegen"
        },
        "new": {
          "title": "Producten toevoegen aan %{subscription_type}"
        }
      },
      "subscriptions": {
        "create": {
          "incidental_subscription_credits_left": "Indien er credits over zijn wanneer het nieuwe abonnement start dan worden deze eerst gebruikt",
          "success": "Het abonnement is toegevoegd."
        },
        "destroy": {
          "success": "Het abonnement is verwijderd"
        },
        "edit": {
          "title": "Bewerk %{subscription} abonnement"
        },
        "index": {
          "add": "Nieuw abonnement toevoegen",
          "help_text": "Hier vindt u het overzicht van abonnementen die aan de organisatie zijn toegewezen",
          "invoice_settings": "Factuurinstellingen",
          "title": "Abonnementen van %{organization}"
        },
        "list": {
          "end_date": "Einddatum",
          "name": "Naam",
          "start_date": "Startdatum"
        },
        "new": {
          "title": "Nieuw abonnement voor %{organization}"
        },
        "update": {
          "success": "Het abonnement is succesvol bijgewerkt"
        }
      },
      "users": {
        "index": {
          "title": "Gebruikers"
        }
      }
    },
    "javascript": {
      "before_unload": "Uw laatste wijziging is nog niet opgeslagen.",
      "components": {
        "add_to_calendar": {
          "add_appointment": "Zet afspraak in agenda"
        },
        "advisor_introduction": {
          "avatar_placeholder": "Bij toekenning wordt hier de profielfoto van de coach getoond",
          "biography_placeholder": "Hier wordt de persoonlijke beschrijving van de coach getoond, zoals hij die in zijn profiel ingesteld heeft.",
          "default_biography": "%{advisor_first_name} begeleidt u gedurende uw traject. U kunt bij uw coach terecht met eventuele vragen.",
          "default_biography_explanation": "(uw kandidaat ziet momenteel de bovenstaande standaard tekst omdat u nog geen persoonlijke beschrijving heeft ingesteld in uw profiel)",
          "title_placeholder": "[naam van de coach]",
          "your_advisor": "Uw coach"
        },
        "appointment": {
          "accept": "Accepteren",
          "appointment": "Afspraak planner",
          "appointment_accepted": "Afspraak geaccepteerd",
          "appointment_cancelled": "Afspraak geannuleerd",
          "appointment_created": "Afspraak aangemaakt",
          "appointment_declined": "Afspraak afgewezen",
          "appointment_submitted": "Voorgestelde afspraak",
          "at_time": "om",
          "back": "Terug",
          "cancel": "Annuleren",
          "date": "Datum",
          "decline": "Afwijzen",
          "delete": "Verwijder",
          "description": "Beschrijving",
          "description_placeholder": "optionele toelichting",
          "end_time": "tot",
          "help_text": "Er zijn nog geen afspraken voorgesteld.",
          "join": "Deelnemen aan gesprek",
          "location": "Locatie",
          "location_placeholder": "ontmoetingsplek",
          "on_date": "op",
          "plan_appointment": "Stel een afspraak voor",
          "propose": "Voorstellen",
          "start_time": "van",
          "title": "Titel",
          "title_placeholder": "titel voor deze afspraak"
        },
        "blog": {
          "help_text": "Weergeeft alle blogartikelen die gedeeld zijn met de kandidaat.",
          "manage": "Beheer artikelen",
          "title": "Blog"
        },
        "comments": {
          "placeholder": "bericht",
          "send": "Verstuur bericht",
          "step_unavailable": "Deze stap is momenteel niet beschikbaar voor de kandidaat. De kandidaat zal een melding ontvangen over dit bericht zodra de stap beschikbaar geworden is."
        },
        "consent": [
          "Nee",
          "Ja",
          "Voer bij de instellingen van dit element de vraag in"
        ],
        "custom_html": {
          "html_placeholder": "Voer bij de instellingen van dit element HTML code in ter creatie van een custom element"
        },
        "document_approval": {
          "approve": "Accorderen",
          "approved": "Geaccordeerd",
          "candidate": "de kandidaat",
          "contact_person": "de contactpersoon",
          "no_file_uploaded": "Geen bestand geüpload door de coach",
          "no_file_uploaded_again": "Geen nieuw bestand geüpload door de coach",
          "reason_placeholder": "reden (optioneel)",
          "reason_placeholder_table": "Afgewezen? Vul een reden in",
          "reject": "Afwijzen",
          "rejected": "Afgewezen",
          "reset": "Opnieuw",
          "status_message_responded": "%{action} op %{date} om %{time}",
          "status_message_submitted": "%{can_approve} heeft nog niet gereageerd op het verzoek tot accordering",
          "status_message_submitted_again": "%{can_approve} heeft nog niet gereageerd op het nieuwe verzoek tot accordering",
          "submitted": "Ingediend",
          "upload_file": "Upload bestand",
          "upload_file_again": "Upload nieuw bestand",
          "with_reason": "vanwege \"%{reason}\""
        },
        "document_upload": {
          "no_file_uploaded": {
            "advisor": "Geen bestand geüpload door de coach",
            "candidate": "Geen bestand geüpload door de kandidaat"
          },
          "upload_file": "Upload bestand"
        },
        "filter_bar": {
          "labels": {
            "filter": "Filteren",
            "records": "Records",
            "search": "Zoeken",
            "sort": "Sorteren"
          },
          "no_filters": "alle",
          "sort": {
            "ascending": "oplopend",
            "default": "Standaard",
            "descending": "aflopend"
          }
        },
        "filter_notifications": {
          "all": "Alle",
          "done": "Gedaan",
          "label": "Status",
          "open": "To-do"
        },
        "image": {
          "no_image_uploaded": "Geen afbeelding geüpload",
          "upload_image": "Upload afbeelding"
        },
        "interactive_results": {
          "agile": {
            "descriptions": {
              "groups": {
                "early_adopter": [
                  "Je behoort tot de categorie ‘early adopter’. Je staat open voor veranderingen, en bent bereid hierin mee te gaan, ook als misschien nog niet helemaal duidelijk is welke problemen gaan spelen of wat precies de gevolgen zijn. Je kan zo goed omgaan met vernieuwingen en met de snel veranderende wereld, wat je wendbaar maakt.\n\nEen risico kan zijn dat je niet goed de gevolgen inschat van veranderingen. Probeer af en toe pas op de plaats te maken, en te luisteren naar de ideeën van anderen, en welke haken en ogen zij nog zien bij een bepaalde verandering.\n\nAgile werken zal goed bij je passen, omdat het goed past bij jouw wendbaarheid. Het is wel belangrijk dat je niet steeds met elke verandering meegaat, maar dat je ook zaken afmaakt voordat er aan iets nieuws begonnen wordt.",
                  "Je behoort tot de categorie ‘early adopter’. Je staat open voor veranderingen, en bent bereid hierin mee te gaan, ook als misschien nog niet helemaal duidelijk is welke problemen gaan spelen of wat precies de gevolgen zijn. Je kan zo goed omgaan met vernieuwingen en met de snel veranderende wereld, wat je wendbaar maakt.\n\nEen risico kan zijn dat je niet goed de gevolgen inschat van veranderingen. Probeer af en toe pas op de plaats te maken, en te luisteren naar de ideeën van anderen, en welke haken en ogen zij nog zien bij een bepaalde verandering.\n\nAgile werken zal goed bij je passen, omdat het goed past bij jouw wendbaarheid. Het is wel belangrijk dat je niet steeds met elke verandering meegaat, maar dat je ook zaken afmaakt voordat er aan iets nieuws begonnen wordt."
                ],
                "early_majority": [
                  "Je behoort tot de ‘early majority’. Je bent iets meer dan gemiddeld geneigd om met vernieuwing mee te gaan, maar je vindt het wel altijd prettig om af te wachten tot de kinderziektes eruit zijn. Je hebt een positieve grondhouding voor verandering, maar je wil wel weten wat het voor jou betekent. Plannen, veranderingen en nieuwe werkwijzen mogen voor jou wel eerst heel goed uitgetest zijn.\n\nJe kan redelijk goed meegaan met Agile werken, en je zal het op prijs stellen dat daar ook gestructureerde werkmethoden voor zijn. Stel de methode echter niet tot doel op zich, het gaat er uiteindelijk om de stijl van werken en het realiseren van bruikbare, nieuwe producten en werkwijzen.",
                  "Je behoort tot de ‘early majority’. Meer dan gemiddeld ben je geneigd om met vernieuwing mee te gaan, maar je vindt het prettig om af te wachten tot de kinderziektes eruit zijn. Je hebt een positieve grondhouding voor verandering, maar je wil wel weten wat het voor jou betekent. Plannen, veranderingen en nieuwe werkwijzen mogen voor jou wel goed uitgetest zijn.\n\nJe kan goed meegaan met Agile werken, en je zal het op prijs stellen dat daar ook gestructureerde werkmethoden voor zijn. Stel de methode echter niet tot doel op zich, het gaat er uiteindelijk om de stijl van werken en het realiseren van bruikbare, nieuwe producten en werkwijzen."
                ],
                "innovator": [
                  "Je bent een ‘innovator’ en voorloper. Je initieert vaak verandering, komt met nieuwe ideeën en staat open voor nieuwe avonturen. Je moedigt anderen aan om mee te gaan in veranderingsprocessen. Agile werken vind je vooral prettig omdat het een manier van werken is die wendbaar is en die ruimte biedt aan vernieuwing. Het bevalt je dat er zo sneller beweging ontstaat. Omdat je vooral in grote lijnen werkt, bestaat er wel een kans dat je details over het hoofd zal zien. Gebruik daarvoor de talenten van anderen.\n\nDaarbij zou voor jou een risico kunnen zijn dat je soms te hard van stapel wilt lopen. Waak ervoor dat je niet te ver voor de troepen uit loopt en dat jouw plannen realistisch blijven. Weerstand en onbegrip kunnen je irriteren. Ook is verslaglegging niet echt jouw ding. Dit kan tot slordigheden leiden en je wat minder navolgbaar maken voor anderen.",
                  "Je bent een ‘innovator’ en duidelijke voorloper. Je initieert verandering, komt met nieuwe ideeën en staat open voor nieuwe avonturen. Je moedigt anderen aan om mee te gaan in veranderingsprocessen. Agile werken vind je vooral prettig omdat het een manier van werken is die wendbaar is en die ruimte biedt aan vernieuwing. Het bevalt je dat er zo sneller beweging ontstaat. Omdat je vooral in grote lijnen werkt, bestaat er een kans dat je details over het hoofd zal zien. Gebruik daarvoor de talenten van anderen.\n\nDaarbij zou voor jou een risico kunnen zijn dat je te hard van stapel wilt lopen. Waak ervoor dat je niet te ver voor de troepen uit loopt en dat jouw plannen realistisch blijven. Weerstand en onbegrip irriteren je. Ook is verslaglegging niet echt jouw ding. Dit kan tot slordigheden leiden en je wat minder navolgbaar maken voor anderen."
                ],
                "laggards": [
                  "Je behoort tot de zogenaamde ‘laggards’, de achterlopers. Bij veranderingen kijk je de kat uit de boom en wil je het liefst alles bij het oude houden. Je moet echt overtuigd worden om te veranderen, en je doet dat liever in kleine stapjes dan in grote sprongen tegelijkertijd. Je staat sceptisch tegenover verandering en soms is dat ook terecht. Niet alle verandering is ten slotte een verbetering. Maar pas ervoor op dat je niet al te zeer achterop raakt, dat je koste wat kost vast wilt houden aan het oude vertrouwde. Per slot van rekening verandert de wereld om ons heen snel. Soms zal je toch jezelf moeten overwinnen om nieuwe werkwijzen te leren en toe te passen.\n\nWaar jij je nuttig kan maken bij veranderingen is ervoor te zorgen dat er aandacht is voor de details, waarmee je de kwaliteit van een verandering waarborgt. Ook kan je helpen bij het praktisch maken van vernieuwing, ervoor zorgen dat alles tot in de puntjes uitgewerkt en vastgelegd wordt.\n\nWerken in Agile teams zal minder geschikt voor je zijn. Liever maak je deel uit van een team waarin werkzaamheden gestructureerd zijn en ook meer routinematig van aard zijn.",
                  "Je behoort tot de zogenaamde ‘laggards’, de achterlopers. Bij veranderingen ben je geneigd om de kat uit de boom te kijken. Je hebt de neiging om alles bij het oude te willen houden. Je moet echt overtuigd worden om te veranderen, en je doet dat liever in kleine stapjes dan in grote sprongen tegelijkertijd. Je staat sceptisch tegenover verandering en soms is dat ook terecht. Niet alle verandering is ten slotte een verbetering. Maar pas ervoor op dat je niet al te zeer achterop raakt, dat je koste wat kost vast wilt houden aan het oude vertrouwde. Per slot van rekening verandert de wereld om ons heen snel. Soms zal je toch jezelf moeten overwinnen om nieuwe werkwijzen te leren en toe te passen.\n\nWaar jij je nuttig kan maken bij veranderingen is ervoor te zorgen dat er aandacht is voor de details, waarmee je de kwaliteit van een verandering waarborgt. Ook kan je helpen bij het praktisch maken van vernieuwing, ervoor zorgen dat alles tot in de puntjes uitgewerkt en vastgelegd wordt.\n\nWerken in Agile teams is mogelijk minder geschikt voor je. Liever maak je deel uit van een team waarin werkzaamheden gestructureerd zijn en mogelijk ook meer routinematig van aard zijn.",
                  "Je behoort tot de zogenaamde ‘laggards’, de achterlopers. Bij veranderingen ben je geneigd om de kat uit de boom te kijken. Je hebt de neiging om alles bij het oude te willen houden. Je moet echt overtuigd worden om te veranderen, en je doet dat liever in kleine stapjes dan in grote sprongen tegelijkertijd. Je staat sceptisch tegenover verandering en soms is dat ook terecht. Niet alle verandering is ten slotte een verbetering. Maar pas ervoor op dat je niet al te zeer achterop raakt, dat je koste wat kost vast wilt houden aan het oude vertrouwde. Per slot van rekening verandert de wereld om ons heen snel. Soms zal je toch jezelf moeten overwinnen om nieuwe werkwijzen te leren en toe te passen.\n\nWaar jij je nuttig kan maken bij veranderingen is ervoor te zorgen dat er aandacht is voor de details, waarmee je de kwaliteit van een verandering waarborgt. Ook kan je helpen bij het praktisch maken van vernieuwing, ervoor zorgen dat alles tot in de puntjes uitgewerkt en vastgelegd wordt.\n\nWerken in Agile teams is mogelijk minder geschikt voor je. Liever maak je deel uit van een team waarin werkzaamheden gestructureerd zijn en mogelijk ook meer routinematig van aard zijn."
                ],
                "late_majority": [
                  "Je behoort tot de ‘late majority’. Uiteindelijk ga je wel mee in veranderingen, maar meestal niet van harte. Het kost je moeite om je aan te passen. Je wil daarom eerst altijd weten wat de voordelen van de verandering zijn, wat het nut ervan is en wat je er persoonlijk aan hebt. Pas ervoor op dat je niet neigt naar een ‘fixed mindset’, dat je te veel wilt vasthouden aan het oude vertrouwde. Je wil natuurlijk ook niet al te zeer achterlopen, de wereld staat immers niet stil.\n\nJouw waarde bij veranderingsprocessen is dat je goed de bezwaren kan noemen, de punten die nog verbeterd kunnen worden, voordat het praktisch bruikbaar is. Jouw kritische houding verhoogt hiermee de slagingskans van de verandering.\n\nOf werken in Agile teams bij je aansluit, is maar de vraag. Jouw rol in het team zou wellicht die van controleur of tester kunnen zijn. Iemand die juist de risico’s benoemt en de bezwaren zichtbaar maakt. Ook daaraan is behoefte in teams!",
                  "Je behoort tot de ‘late majority’. Uiteindelijk ga je wel mee in veranderingen, maar niet altijd van harte. Het kost je enige moeite om je aan te passen. Je wil daarom weten wat de voordelen van de verandering zijn, wat het nut ervan is en wat je er persoonlijk aan hebt. Pas ervoor op dat je niet neigt naar een ‘fixed mindset’, dat je te veel wilt vasthouden aan het oude vertrouwde. Je wil natuurlijk ook niet al te zeer achterlopen, de wereld staat immers niet stil.\n\nJouw waarde bij veranderingsprocessen is dat je goed de bezwaren kan noemen, de punten die nog verbeterd kunnen worden, voordat het praktisch bruikbaar is. Jouw kritische houding verhoogt hiermee de slagingskans van de verandering.\n\nOf werken in Agile teams bij je aansluit, is maar de vraag. Jouw rol in het team zou wellicht die van controleur of tester kunnen zijn. Iemand die juist de risico’s benoemt en de bezwaren zichtbaar maakt. Ook daaraan is behoefte in teams!"
                ]
              },
              "pillars": {
                "change": [
                  "Je houdt ervan te weten waar je aan toe bent. Je vindt het prettig als dingen hetzelfde blijven en je een bepaalde routine hebt in jouw werkzaamheden. Het liefste werk je aan een vast takenpakket, volgens voorgeschreven procedures. Je speelt liever op zeker, dan dat je risico’s neemt. Van jou hoeven dingen niet zo nodig te veranderen of te vernieuwen. Veranderingen doorvoeren in jouw werkzaamheden is iets dat je mogelijk hinderlijk vindt. Anderen kunnen dat ook als lastig ervaren, als weerstand. Probeer toch het positieve te zien in wat er op je afkomt. Maak kleine stapjes, maar blijf wel in beweging. Pas op dat je niet tot de achterblijvers gaat behoren.",
                  "Je houdt ervan als dingen hetzelfde blijven, maar hebt niets tegen kleine veranderingen. Doorgaans werk je het liefst volgens voorgeschreven procedures aan een vast takenpakket. Hoewel je het prettig vindt om te weten waar je aan toe bent, ben je soms ook nieuwsgierig naar veranderingen die gericht zijn op verbetering. Je zal niet aan het hoofd staan van deze veranderingen, aangezien je het nemen van risico’s liever tot het hoognodige beperkt, maar je zal je wel conformeren aan de veranderingen. Echt snel pak je het ook weer niet op.",
                  "Je staat neutraal tegenover veranderingen. Hoewel je het prettig vindt om bepaalde vaste taken te hebben, vind je het ook aangenaam om wat verscheidenheid in jouw omgeving of taken te ervaren. Wanneer er een verandering plaatsvindt in jouw organisatie, zal je niet direct degene zijn die die verandering initieert, maar zal je je wel redelijk gemakkelijk aan kunnen passen aan de nieuwe situatie. Je vindt het niet nodig om volgens vaste kaders te werken, al vind je het ook niet storend. Bovendien verwelkom je nieuwe taken die nog uitgedacht moeten worden.",
                  "Je vindt het aangenaam als er regelmatig veranderingen plaatsvinden in jouw werk. Je hebt weinig behoefte aan een vast takenpakket, liever heb je een afwisselende baan. Wanneer je vaste taken uitvoert zal je nadenken over nieuwe manieren waarop je dit werk anders kan aanpakken. Je vindt het dan ook demotiverend als je voorgeschreven wordt hoe je bepaalde zaken moet uitvoeren. Wanneer iemand een verandering initieert zal je er dan ook voor open staan, echter mogen de bijbehorende risico’s wat jou betreft niet te hoog zijn.",
                  "Je omarmt veranderingen. Vaak initieer je ook vernieuwing; nieuwe inzichten en experimenten komen als vanzelf bij je op. Het liefst zou elke werkdag voor jou anders zijn. Je vindt het heerlijk om uitdagende en afwisselende taken te hebben, die je naar eigen inzicht mag inrichten. Procedures, routine, en voorschriften ervaar je als demotiverend. Doorgaans zal je aan het hoofd van veranderingen staan, iets dat je regelmatig initieert. Daarbij schrikt het nemen van risico’s je niet af, want je acht vernieuwing belangrijker dan zekerheid."
                ],
                "growth": [
                  "Je hebt behoefte aan zekerheid en routine. Ambitie of je meten met anderen ervaar je niet als stimulerend, eerder knap je er op af. Je hebt je een aantal taken eigen gemaakt waar je goed in bent en je vindt het niet noodzakelijk om andere taken aan te leren. Doorgaans ben je tevreden met het niveau waarop je de taken nu uitvoert en je ervaart geen druk om taken beter uit te voeren dan jouw collega’s. Je vermijdt taken die naar jouw idee buiten jouw vermogen liggen, omdat je weinig fouten wilt maken. Omdat je zo gericht bent op kwaliteit, kan je het niet altijd even goed van je af zetten als je een fout maakt.",
                  "Je hebt meer behoefte aan zekerheid dan aan ontwikkeling. Je hebt je een aantal taken eigen gemaakt waar je goed in bent en je bent doorgaans tevreden met het niveau waarop je de taken nu uitvoert. Je wil wel leren hoe je deze taken nog beter zou kunnen uitvoeren, maar ervaart weinig behoefte om andere taken aan te leren. Je vermijdt taken die naar jouw idee buiten jouw vermogen liggen, omdat je weinig fouten wilt maken. Feedback op jouw werk kan je je mogelijk persoonlijk aantrekken, omdat je graag goed overkomt op anderen.",
                  "Je hebt een gemiddelde behoefte aan zelfontwikkeling. Je vindt het fijn om een aantal vaste taken te hebben, maar je staat tevens open voor het aanleren van nieuwe taken. Ook vind je het leuk om beter te worden in jouw huidige taken. Je ziet jouw prestatie op zichzelf, en bent niet geneigd om jouw prestatie af te zetten tegen die van jouw collega’s. Hoewel je het niet prettig vindt om fouten te maken, snap je dat dit onderdeel is van het leerproces.",
                  "Je hebt een hoge behoefte aan zelfontwikkeling. Je hebt als doel om veel te leren en om jezelf te verbeteren in de taken waar je verantwoordelijk voor bent. Het liefst voer je jouw taken beter uit, en pak je lastigere taken aan dan jouw collega’s. Je vindt het niet zo erg om fouten te maken, want dat hoort bij het leerproces. Je hebt een veerkrachtige insteek en laat je niet gemakkelijk tegenhouden. Ten slotte vind je het niet erg om ook enkele taken te hebben die minder kansen voor ontwikkeling bieden.",
                  "Je vindt jouw persoonlijke ontwikkeling enorm belangrijk. Je hebt een grote dorst naar kennis en je wil graag stappen zetten. Routinematige taken vind je vervelend, omdat je het liefst zoveel mogelijk nadenkt. Je hebt als doel om veel te leren en vindt het geen enkel probleem om fouten te maken. Je ziet deze fouten als informatiebronnen, want dit zijn de punten waarop je jezelf nog verder kan ontplooien. Lastige taken pak je met beide handen aan. Zoveel mogelijk probeer je het beste uit jezelf te halen en wil je beter zijn dan de rest."
                ],
                "power": [
                  "Je bent gericht op het behouden van wat goed is, vanuit jouw kwaliteitsgerichte insteek. Je werkt het liefst aan bekende werkzaamheden of projecten, waarvan je je de vaardigheden al eigen hebt gemaakt, omdat je graag feilloos werk wil leveren. Het starten van nieuwe projecten of het aanleren van andere taken spreekt je minder aan, omdat je beducht bent op het maken van fouten. Projecten die je middels ervaring en routine in de vingers hebt, genieten daarom jouw voorkeur. Je staat kritisch tegenover veranderingen, wat de kwaliteit van deze vernieuwingen ten goede kan komen. Echter, omdat je geregeld beren op de weg ziet, heb je ook de neiging om veranderingen tegen te houden. Veranderingen kunnen je ook te veel energie kosten. Het is aan te raden te onderzoeken hoe je jouw power kan versterken, om voldoende energie te hebben om jouw plek in de veranderende wereld te behouden en daar positief aan bij te dragen.",
                  "Je hebt een voorliefde om jezelf in één onderwerp te verdiepen. Je werkt liever langer aan een project waar je expertise in vergaart, dan dat je je richt op verbreding. Ook vind je het prettig om te werken aan projecten waarin alle processen bekend en gestroomlijnd zijn. Werkzaamheden en projecten die je door ervaring en routine in de vingers hebt genieten jouw voorkeur. Kinderziektes binnen nieuwe projecten ergeren je. Je zal daarom een kritische houding aannemen bij vernieuwingen, wat de kwaliteit van deze veranderingen ten goede kan komen. Pas wel op dat je je niet te veel laat tegenhouden door mogelijke beren die je op de weg ziet, want veranderingen kunnen ook veel positieve gevolgen hebben. Het is aan te raden te onderzoeken hoe je jouw power kan versterken, om voldoende energie te hebben om jouw plek in de veranderende wereld te behouden en daar positief aan bij te dragen.",
                  "Je hebt voldoende energie om met veranderingen om te kunnen gaan, zonder dat je daar een initiërende rol in speelt. Enerzijds zie je de voordelen van veranderingen in en tegelijkertijd vind je het aangenaam om vast te houden aan wat bekend voor je is. Je staat open voor nieuwe taken en werkzaamheden, maar je vindt het wel prettig als er niet te veel veranderingen tegelijkertijd plaatsvinden. Het voordeel van deze afwisseling is dat je hierdoor de ruimte hebt om de mogelijke kinderziektes van deze veranderingen het hoofd te bieden.",
                  "Je bezit een hoge dosis vernieuwingsenergie. Je toont veel kracht en inzet en kan daarom behoorlijk goed met veranderingen omgaan. Je ziet doorgaans de positieve aspecten van vernieuwing in, al kan je het soms als vervelend ervaren dat daardoor nog niet alle processen geoptimaliseerd zijn. Je laat je daardoor echter niet tegenhouden en je werkt actief mee aan het verbeteren van de veranderingsprocessen op de werkvloer.",
                  "Je bezit een enorme dosis vernieuwingstemperament. Dit wil zeggen dat je de kracht, inzet en instelling hebt om goed met veranderingen om te kunnen gaan. Hoewel vernieuwingen veel positieve gevolgen kunnen hebben, is er ook vaak sprake van kinderziektes of processen die nog niet geoptimaliseerd zijn. Je begrijpt dat dit bij het proces van veranderingen hoort en kan dat goed hanteren. Bij een dergelijke tegenslag zal je je niet laten afschrikken en met een positieve instelling blijven doorwerken. Je gelooft dat je met hard werken en doorzettingsvermogen elke uitdaging aankan."
                ]
              },
              "tips": {
                "change": [
                  "Hoewel rationeel gezien elke verandering zijn positieve punten en kansen kent, let je misschien vaker op de gevaren en bedreigingen die zij met zich meebrengt. Vraag jezelf in veranderingsprocessen dan ook regelmatig af wat er nou werkelijk met zekerheid geconcludeerd kan worden en wat je er zelf van hebt gemaakt. Stel de beren die je op de weg ziet eens ter discussie. Door jezelf bewust te maken van de grens tussen feiten en jouw eigen interpretaties leer je op een meer neutrale manier met veranderingen om te gaan. Een andere manier waarop je om kan gaan met zaken die je als bedreigingen ziet, is door alle risico's en nadelen op te schrijven die in jouw hoofd zitten. Dwing jezelf vervolgens om daar net zoveel voordelen en nieuwe kansen tegenover te stellen.",
                  "Hoewel rationeel gezien elke verandering zijn positieve punten en kansen kent, let je misschien vaker op de gevaren en bedreigingen die zij met zich meebrengt. Vraag jezelf in veranderingsprocessen dan ook regelmatig af wat er nou werkelijk met zekerheid geconcludeerd kan worden en wat je er zelf van hebt gemaakt. Stel de beren die je op de weg ziet eens ter discussie. Door jezelf bewust te maken van de grens tussen feiten en jouw eigen interpretaties leer je op een meer neutrale manier met veranderingen om te gaan. Een andere manier waarop je om kan gaan met zaken die je als bedreigingen ziet, is door alle risico's en nadelen op te schrijven die in jouw hoofd zitten. Dwing jezelf vervolgens om daar net zoveel voordelen en nieuwe kansen tegenover te stellen.",
                  "Je weet je redelijk gemakkelijk aan te passen aan veranderingen. Echter, je zal niet altijd even geneigd zijn om enthousiast op veranderingen te reageren. Rationeel gezien kent elke verandering zijn positieve punten en kansen. Om jouw enthousiasme voor veranderingen te vergroten kan je een lijst maken van alle voordelen en nieuwe kansen die deze verandering met zich meebrengt. Door jezelf bewust te maken van de positieve gevolgen van de verandering, zal je gemakkelijker enthousiast worden.",
                  "Met jouw open houding voor veranderingen zal je een goed kompas zijn voor Innovators, die nog wel eens te ver voor de troepen uit willen lopen. Maak een inschatting van de risico's van veranderingen en hoe realistisch je deze acht, en maak deze bespreekbaar met de Innovators.",
                  "Zorg er voor dat je in verbinding blijft met de rest, loop niet te ver voor de troepen uit. Aandacht besteden aan waarborging van de kwaliteit van veranderingen kan geen kwaad."
                ],
                "growth": [
                  "Onderzoek op welke competenties je jezelf nog verder kan en wilt ontplooien, en welke vaardigheden nuttig zijn om te bezitten voor jouw employability. Omdat je gericht bent op zekerheid en routine, zou het kunnen zijn dat je je minder richt op competenties die in de toekomst relevant en interessant zijn voor jouw werkgever. Probeer hiervoor te waken en kansen aan te grijpen wanneer je mogelijkheden ziet om je verder te ontwikkelen op competenties die je nuttig acht.",
                  "Onderzoek op welke competenties je jezelf nog verder kan en wilt ontplooien, en welke vaardigheden nuttig zijn om te bezitten voor jouw employability. Omdat je een grotere behoefte hebt aan zekerheid dan aan ontwikkeling, zou het kunnen zijn dat je je minder richt op competenties die in de toekomst relevant en interessant zijn voor jouw werkgever. Probeer hiervoor te waken, door uit te vinden welke relevante competenties je je eigen kan maken en initiatieven te nemen om deze competenties te ontwikkelen.",
                  "Je beschikt over een goede combinatie van eigenschappen: je vindt het geen probleem om bekende taken te verrichten, maar je vindt het ook prettig om door te leren. Bekijk of jouw huidige balans naar wens is. Zijn er nog competenties die je je eigen wil maken? Wat kan je doen om deze competenties te ontwikkelen? Probeer hiertoe initiatieven te nemen en kansen te creëren op jouw werk.",
                  "Je beschikt over een goede combinatie van eigenschappen: je houdt ervan jezelf op diverse vlakken te ontplooien, maar je vindt het ook geen probleem om bekende taken te verrichten. Bekijk of jouw huidige balans naar wens is. Zijn er nog competenties die je je eigen wil maken? Wat kan je doen om deze competenties te ontwikkelen? Probeer hiertoe initiatieven te nemen en kansen te creëren op jouw werk.",
                  "Je hebt een grote dorst naar kennis en zelfontwikkeling, een goede eigenschap als het gaat om veranderingen in organisaties. Bedenk je echter ook dat niet alles tegelijk zal kunnen veranderen, en dat sommige routinematige taken nou eenmaal bij jouw werk horen. Laat jouw stemming en motivatie je niet te veel beïnvloeden wanneer je bekendere werkzaamheden moet uitvoeren, want met jouw openheid voor ontwikkeling komt er vanzelf weer een uitdagende nieuwe taak op jouw pad terecht."
                ],
                "power": [
                  "Om jouw power te versterken, zou je kunnen uitzoeken wat voor positieve gevolgen andere mensen hebben ervaren als gevolg van veranderingen. Probeer ook bij jezelf te rade te gaan welke veranderingen er in jouw leven of op de werkvloer hebben plaatsgevonden, en hoe je hiermee om bent gegaan. Focus daarbij vooral op de aspecten die je goed hebt aangepakt. Zijn er momenten geweest dat je zelf veranderingen geïnitieerd hebt? Wat waren daar de positieve gevolgen van? Door op deze elementen te focussen, kan je jouw zelfvertrouwen in jouw omgang met veranderingen vergroten.",
                  "Om jouw power te versterken, zou je kunnen uitzoeken wat voor positieve gevolgen andere mensen hebben ervaren als gevolg van veranderingen. Probeer ook bij jezelf te raden te gaan welke veranderingen er in jouw leven of op de werkvloer hebben plaatsgevonden, en hoe je hiermee om bent gegaan. Focus daarbij vooral op de aspecten die je goed hebt aangepakt. Zijn er momenten geweest dat je zelf veranderingen geïnitieerd hebt? Wat waren daar de positieve gevolgen van? Door op deze elementen te focussen, kan je jouw zelfvertrouwen in jouw omgang met veranderingen vergroten.",
                  "Zie jouw kans: voor jou is een belangrijke rol weggelegd in het matigen van overambitieuze plannen, waarin men te veel tegelijkertijd wil aanpakken. Hoewel je open staat voor veranderingen, sta je met beide benen op de grond. Deze houding kan een ontnuchterende werking hebben op de Innovators.",
                  "Zie jouw kans: voor jou is een belangrijke rol weggelegd in het matigen van overambitieuze plannen, waarin men te veel tegelijkertijd wil aanpakken. Hoewel je zeer open staat voor veranderingen, heb je tevens oog voor mogelijke obstakels. Probeer de Innovators hierop te wijzen en na te denken hoe je de obstakels kan voorkomen of oplossen.",
                  "Het is een goede eigenschap om veel energie te hebben om met veranderingen om te gaan. Zorg er echter wel voor dat je niet door jouw enthousiasme verblind wordt, en luister naar de zorgen en wensen die jouw collega's uiten over de veranderingen. Neem de mogelijke obstakels die zij zien serieus, en probeer hier een oplossing voor te vinden. Dit zal de kwaliteit van de veranderingen ten goede komen."
                ]
              }
            },
            "header": "Wendbaarheid",
            "tip": "TIP!",
            "titles": {
              "groups": {
                "early_adopter": "Early adopter",
                "early_majority": "Early majority",
                "innovator": "Innovator",
                "laggards": "Laggards",
                "late_majority": "Late majority"
              },
              "pillars": {
                "change": "Verandering (change)",
                "growth": "Persoonlijke groei (growth)",
                "power": "Kracht (power)"
              }
            }
          },
          "behavior": {
            "header": "Gedragskenmerken",
            "keys": {
              "aanspreken": "Aan-en uitspreken",
              "durft": "Durven",
              "veranderbereid": "Verander bereidheid"
            }
          },
          "candidate": {
            "date_of_birth": "Geboortedatum",
            "education": "Opleidingsniveau",
            "email": "E-mailadres",
            "header": "Persoonsgegevens",
            "most_recent_work_experience": "Meest recente werkervaring",
            "study_subject": "Opleiding",
            "years": {
              "one": "%{years} jaar",
              "other": "%{years} jaren"
            },
            "years_of_experience": "Jaren ervaring"
          },
          "competences": {
            "descriptions": {
              "aansturenvangroepen": "In staat zijn leiding te geven aan een groep.",
              "aansturenvanindividuen": "In staat zijn leiding te geven aan een individu.",
              "ambitie": "Gedreven zijn, meer dan gemiddeld willen presteren.",
              "assertiviteit": "Komt op voor eigen meningen, ook als er vanuit de omgeving druk op wordt gelegd.",
              "besluitvaardigheid": "In staat zijn om snel en adequaat beslissingen te kunnen nemen.",
              "coachenvanmedewerkers": "In staat zijn de rol van leidinggevende medewerkers te stimuleren en te begeleiden in hun ontwikkeling.",
              "delegeren": "In staat zijn werkzaamheden op een gerichte manier aan anderen over te dragen.",
              "doorzettingsvermogen": "Erop gericht zijn, ondanks tegenslagen, eenmaal begonnen zaken te volbrengen.",
              "flexibiliteit": "Kan door te wisselen van gedragsstijl doelgericht en effectief optreden onder verschillende en veranderende omstandigheden.",
              "initiatief": "In staat zijn om zaken te initiëren, uit zichzelf doelmatig actie kunnen ondernemen.",
              "inzet": "In staat zijn gedurende een lange periode een grote inspanning te willen en kunnen leveren.",
              "klantorientatie": "In staat zijn om zich in te leven in en te reageren op de behoeften van een klant.",
              "kwaliteitsgerichtheid": "Erop gericht zijn een hoge kwaliteit te leveren en zaken te perfectioneren.",
              "leerbereidheid": "Bereid zijn tot het ontwikkelen en uitbreiden van kennis en vaardigheden door leren.",
              "nauwkeurigheid": "In staat zijn secuur te werken en oog te hebben voor details en verbanden.",
              "onderhandelen": "In staat zijn wederzijdse belangen en standpunten af te tasten om tot een voor alle partijen geaccepteerde overeenkomst te komen.",
              "ondernemerschap": "Er op gericht zijn winst te behalen door zakelijke kansen te signaleren en te benutten en door gecalculeerde risico's te durven nemen.",
              "optreden": "Een krachtige, professionele indruk maken op anderen.",
              "organisatiesensitiviteit": "In staat zijn om de invloed en gevolgen van eigen beslissingen of activiteiten te onderkennen op andere organisatieonderdelen.",
              "overtuigingskracht": "In staat zijn anderen mee te krijgen met een bepaald standpunt, voorstel of idee.",
              "plannenenorganiseren": "In staat zijn om activiteiten en werkzaamheden te plannen en te organiseren.",
              "relatiebeheer": "Het op constructieve wijze aangaan en onderhouden van relaties.",
              "resultaatgerichtheid": "Gedreven zijn concrete doelen en resultaten te bereiken.",
              "samenwerking": "Samen met anderen op constructieve wijze bereiken van gemeenschappelijke doelen.",
              "sensitiviteit": "In staat zijn signalen van anderen aan te voelen en daar adequaat op in te spelen.",
              "sociabiliteit": "Beweegt zich graag en gemakkelijk in groepen mensen.",
              "stressbestendigheid": "In staat zijn onder stressvolle omstandigheden effectief te blijven functioneren.",
              "voortgangscontrole": "In staat zijn om eenmaal geïnitieerde zaken te volgen, op voortgang te controleren.",
              "zelfstandigheid": "In staat zijn zelfstandig werkzaamheden te verrichten, doelen te stellen en daar vorm en inhoud aan te geven."
            },
            "header": "Jouw top 5 competenties",
            "keys": {
              "aansturenvangroepen": "Aansturen van groepen",
              "aansturenvanindividuen": "Aansturen van individuen",
              "ambitie": "Ambitie",
              "assertiviteit": "Assertiviteit",
              "besluitvaardigheid": "Besluitvaardigheid",
              "coachenvanmedewerkers": "Coachen van medewerkers",
              "delegeren": "Delegeren",
              "doorzettingsvermogen": "Doorzettingsvermogen",
              "flexibiliteit": "Flexibiliteit",
              "initiatief": "Initiatief",
              "inzet": "Inzet",
              "klantorientatie": "Klantoriëntatie",
              "kwaliteitsgerichtheid": "Kwaliteitsgerichtheid",
              "leerbereidheid": "Leerbereidheid",
              "nauwkeurigheid": "Nauwkeurigheid",
              "nauwkeurigheid_comp": "Nauwkeurigheid",
              "onderhandelen": "Onderhandelen",
              "ondernemerschap": "Ondernemerschap",
              "optreden": "Optreden",
              "organisatiesensitiviteit": "Organisatiesensitiviteit",
              "overtuigingskracht": "Overtuigingskracht",
              "plannenenorganiseren": "Plannen en organiseren",
              "relatiebeheer": "Relatiebeheer",
              "resultaatgerichtheid": "Resultaatgerichtheid",
              "samenwerking": "Samenwerking",
              "samenwerking_comp": "Samenwerking",
              "sensitiviteit": "Sensitiviteit",
              "sociabiliteit": "Sociabiliteit",
              "stressbestendigheid": "Stressbestendigheid",
              "voortgangscontrole": "Voortgangscontrole",
              "zelfstandigheid": "Zelfstandigheid"
            }
          },
          "development": {
            "header": "Ontwikkelpotentieel",
            "keys": {
              "ambitie": "Ambitie",
              "growth": "Persoonlijke groei",
              "onafhankelijkheid": "Onafhankelijkheid",
              "samenwerking": "In teamverband werken",
              "sociabiliteit": "Sociabiliteit"
            }
          },
          "documents": {
            "date": "Datum",
            "header": "Documenten (%{documents})",
            "less": "Toon minder",
            "more": "Toon meer",
            "refresh": "Jouw sessie is verlopen. Klik op de knop hieronder om de pagina te herladen."
          },
          "explanations": {
            "behavior": {
              "description": "Gedragskenmerken zijn de uitgangspunten die de basis vormen voor hoe wij binnen NS met elkaar willen samenwerken"
            },
            "intelligence": {
              "description": "In deze widget zie je twee typen scores. Op de eerste pagina wordt jouw intelligentiescore getoond. Daarbij is een score van 100 gemiddeld in de Nederlandse beroepsbevolking.\n\nOp de volgende pagina's wordt jouw score vergeleken met referentiegroepen van verschillende opleidingsniveaus. De getallen op deze pagina's zijn sten-scores, daarmee laten we zien hoe jij scoort ten opzichte van de referentiegroep. Scores van bijvoorbeeld 5 of 6 zijn gemiddeld en komen veel voor. Scores van bijvoorbeeld 1 of 10 zijn uitzonderlijk en komen weinig voor."
            },
            "interests": {
              "description": "In deze widget zie je twee types scores, jouw interesse in sectoren en in taken. Jouw interesse in sectoren laat zien in welke sector van de arbeidsmarkt je graag werkzaam wilt zijn. Jouw interesse in taken geeft aan welke werkzaamheden je graag zou willen doen. Deze informatie kan je helpen om te ontdekken welk werk voor jou interessant is. Je zult dit werk met meer motivatie en plezier vervullen, dan werk dat minder aansluit bij jouw interesses.\n\nOp de eerste pagina wordt jouw top 5 interesses in sectoren getoond. De scores lopen van 1 tot 10, waarbij een 1 staat voor een ver onder gemiddelde interesse en een 10 voor ver boven gemiddelde interesse. Op de tweede pagina wordt jouw top 5 interesses in taken getoond. De scores lopen van 1 tot 5, waarbij een 1 staat voor ver onder gemiddelde interesse en een 5 voor ver boven gemiddelde interesse."
            },
            "star_scores": {
              "description": "De sterren in deze widget laten zien hoe ontwikkelbaar de competenties voor jou zijn. Hoe meer sterren geel gekleurd zijn, hoe groter jouw aanleg is. Dit wil niet zeggen dat de competentie al ontwikkeld is en tot welk niveau."
            },
            "sten_scores": {
              "description": "De getallen in deze widget zijn sten-scores, daarmee laten we zien hoe jij scoort ten opzichte van de Nederlandse beroepsbevolking. Scores van bijvoorbeeld 5 of 6 zijn gemiddeld en komen veel voor. Scores van bijvoorbeeld 1 of 10 zijn uitzonderlijk en komen weinig voor. Onder- en bovengemiddelde scores brengen zowel kwaliteiten als valkuilen met zich mee.",
              "scores": [
                "Ver onder gemiddeld",
                "Ruim beneden gemiddeld",
                "Beneden gemiddeld",
                "Juist beneden gemiddeld",
                "Gemiddeld",
                "Gemiddeld",
                "Juist boven gemiddeld",
                "Boven gemiddeld",
                "Ruim boven gemiddeld",
                "Ver boven gemiddeld"
              ]
            }
          },
          "filter": {
            "clear": "Selectie wissen",
            "empty": "Selecteer tot 10 competenties om jouw resultaten te zien",
            "header": "Filter competenties"
          },
          "hybrid_work": {
            "average": {
              "gemiddeld": "gemiddeld",
              "hoog": "hoog",
              "laag": "laag"
            },
            "header": "21st century skills",
            "skills": {
              "autonomie": {
                "descriptions": {
                  "gemiddeld": "Bij het thuiswerken ervaar je enige vrijheid om zelfstandig te beslissen hoe je jouw werk uitvoert. Wel heb je soms toestemming nodig om bepaalde taken uit te voeren en ervaar je enige mate van controle. Verder ben je mogelijk wel eens afgeleid door je thuisomgeving. Waarschijnlijk voel je je enigszins in staat je vaardigheden te gebruiken om een bijdrage te leveren aan het team en/of de organisatie waar je werkt. Dat kan leiden tot enige motivatie en tevredenheid.",
                  "hoog": "Bij het thuiswerken ervaar je de vrijheid om zelfstandig te beslissen hoe je jouw werk uitvoert. Je wordt weinig gecontroleerd. Je ervaart een hoge mate van verantwoordelijkheid en bent in staat je werkzaamheden zelfstandig te plannen en te organiseren. Je hebt weinig last van afleiding vanuit je thuisomgeving en je ervaart nauwelijks conflicten tussen privé en werk. Waarschijnlijk voel je je in staat je vaardigheden te gebruiken om een bijdrage te leveren aan het team en/of de organisatie waar je werkt. Dat kan leiden tot een hoge motivatie en tevredenheid.",
                  "laag": "Bij het thuiswerken ervaar je weinig vrijheid om zelfstandig te beslissen hoe je jouw werk uitvoert. Je wordt vaak gecontroleerd en hebt toestemming nodig om bepaalde taken uit te voeren. Dat maakt je afhankelijk van anderen om beslissingen te nemen over je werk. Waarschijnlijk word je tijdens werk wel eens afgeleid door je thuisomgeving en ervaar je conflicten tussen privé en werk. Mogelijk voel je je daardoor geremd en heb je weinig motivatie en tevredenheid."
                },
                "label": "Autonomie"
              },
              "competentie": {
                "descriptions": {
                  "gemiddeld": "Bij het thuiswerken ervaar je een gemiddeld gevoel van competentie. Dit betekent dat je meestal wel de vaardigheden en kennis hebt om je werk effectief uit te voeren, maar dat je soms moeite hebt om bepaalde taken goed uit te voeren. Je kan soms worden beperkt door je technologische vaardigheden. Over het algemeen heb je enig vertrouwen in je eigen vaardigheden, maar je kunt je onzeker voelen over je capaciteiten om je werk goed te doen. Dat kan leiden tot een verminderde motivatie en tevredenheid.",
                  "hoog": "Bij het thuiswerken ervaar je een hoge mate van competentie. Je bezit de vaardigheden en kennis om je werk effectief uit te kunnen voeren. Je ervaart weinig of geen problemen met je technische vaardigheden bij het thuiswerken. Je hebt veel vertrouwen in je vaardigheden en bent waarschijnlijk gemotiveerd en erg tevreden over je prestaties op je werk.",
                  "laag": "Bij het thuiswerken ervaar je een laag gevoel van competentie. Dit betekent dat je waarschijnlijk beperkte vaardigheden en kennis hebt om je werk effectief uit te voeren en dat je moeite hebt ombepaalde taken uit te voeren. Je ervaart mogelijk problemen met je technologische vaardigheden bij het thuiswerken. Waarschijnlijk heb je daarom weinig vertrouwen in je eigen vaardigheden. Mogelijk voel je je onzeker over je capaciteiten om je werk goed te doen. Dat kan leiden tot een verminderde motivatie en tevredenheid."
                },
                "label": "Competentie"
              },
              "verbondenheid": {
                "descriptions": {
                  "gemiddeld": "Bij het thuiswerken ervaar je een redelijke mate van verbondenheid met je collega’s. Mogelijk heb je af en toe moeite om met collega’s in contact te komen. Je ervaart niet altijd betrokkenheid van je collega’s. Dat kan leiden tot een verminderde motivatie en tevredenheid.",
                  "hoog": "Bij het thuiswerken ervaar je een sterk gevoel van verbondenheid met je collega’s. Je komt gemakkelijk in contact met je collega’s en ervaart steun en betrokkenheid van hen wanneer je dat nodig hebt. Dat draagt bij aan je interesse in je werk. Waarschijnlijk ben je gemotiveerd om te presteren en tevreden over je werk.",
                  "laag": "Bij het thuiswerken ervaar je een zwak gevoel van verbondenheid met je collega’s. Je hebt moeite om met je collega’s in contact te komen en ervaart weinig steun en betrokkenheid van hen. Dat kan leiden tot een verminderde motivatie en tevredenheid."
                },
                "label": "Verbondenheid"
              }
            },
            "title": "Hybride werken"
          },
          "information": {
            "skills": {
              "informatievaardigheden": {
                "label": "Informatievaardigheden: Omgaan met informatie",
                "tips": [
                  "De manier waarop je informatie zoekt en verwerkt kan op een aantal punten nog beter. Op <a href=\"https://o21.nu/informatievaardigheden\" target=\"_blank\">deze</a> website lees je meer over het Big 6-model van informatievaardigheden. Ga eens bij jezelf na welke stappen je nog kunt verbeteren!",
                  "De manier waarop je informatie zoekt en verwerkt kan op een aantal punten nog beter. Op <a href=\"https://o21.nu/informatievaardigheden\" target=\"_blank\">deze</a> website lees je meer over het Big 6-model van informatievaardigheden. Ga eens bij jezelf na welke stappen je nog kunt verbeteren!",
                  "De manier waarop je informatie zoekt en verwerkt is op veel punten al goed! Maar er is ook nog ruimte voor verbetering. Op <a href=\"https://o21.nu/informatievaardigheden\" target=\"_blank\">deze</a> website lees je meer over het Big 6-model van informatievaardigheden. Ga eens bij jezelf na welke stappen je al goed uitvoert en welke stappen je kunt verbeteren.",
                  "De manier waarop je informatie zoekt en verwerkt is al van een hoog niveau. Maar wie weet is er nog ruimte voor verbetering. Op <a href=\"https://o21.nu/informatievaardigheden\" target=\"_blank\">deze</a> website lees je meer over het Big 6 model van informatievaardigheden. Ga eens bij jezelf na welke stappen je al goed uitvoert en bij welke stappen je nog verder kan verbeteren!",
                  "De manier waarop je informatie zoekt en verwerkt is al van een hoog niveau. Maar wie weet is er nog ruimte voor verbetering. Op <a href=\"https://o21.nu/informatievaardigheden\" target=\"_blank\">deze</a> website lees je meer over het Big 6-model van informatievaardigheden. Ga eens bij jezelf na welke stappen je al goed uitvoert en welke stappen je nog verder kan verbeteren!"
                ]
              },
              "kritisch": {
                "description": "Het kritisch bekijken van informatie",
                "label": "Kritisch",
                "tips": [
                  "Je kijkt niet kritisch naar de bron van je informatie en je hebt de neiging informatie vrij letterlijk over te nemen. Probeer wat vaker informatie van verschillende bronnen te combineren en in je eigen woorden te zetten. Op die manier ben je automatisch kritischer over de informatie die je overneemt.",
                  "Je bent niet altijd heel kritisch op de bron van je informatie. Je hebt de neiging informatie vrij letterlijk over te nemen. Probeer wat vaker informatie van verschillende bronnen te combineren en in je eigen woorden te zetten. Zo ben je automatisch kritischer over de informatie die je overneemt.",
                  "Je probeert om informatie niet zomaar aan te nemen en letterlijk over te schrijven. Maar je zou dit nog meer kunnen doen. Probeer wat vaker informatie van verschillende bronnen te combineren en in je eigen woorden te zetten. Zo ben je automatisch kritischer over de informatie die je overneemt.",
                  "Je gaat grondig te werk tijdens je zoektocht en bij het verwerken van de informatie. Je bekijkt verschillende bronnen kritisch. Ook probeer je informatie in je eigen woorden neer te zetten in plaats van het zomaar over te schrijven. Laat deze kritische blik niet verslappen.",
                  "Je gaat grondig te werk tijdens je zoektocht en tijdens het verwerken van de informatie. Je bekijkt verschillende bronnen kritisch. Ook probeer je informatie in je eigen woorden neer te zetten in plaats van deze zomaar over te schrijven. Houd deze kritische blik vast."
                ]
              },
              "organisatie": {
                "description": "Het organiseren van informatie",
                "label": "Organisatie",
                "tips": [
                  "Je neemt niet altijd de tijd om je zoekproces voor te bereiden of te evalueren. Hierdoor vind je niet altijd de juiste informatie en kan je zoekproces langer duren dan nodig.",
                  "Je kunt soms meer tijd nemen om je zoekproces voor te bereiden of tussendoor te evalueren. Hierdoor kun je nog gerichter zoeken en je zoekproces versnellen.",
                  "Je probeert je zoekproces voor te bereiden en te evalueren, maar er is nog ruimte voor verbetering. Met een goede voorbereiding en een kritische blik tijdens het zoekproces kun je nóg gerichter zoeken.",
                  "Je neemt de tijd om je zoekproces voor te bereiden en te evalueren. Probeer dit nog vaker te doen, want met een goede voorbereiding en een kritische blik tijdens het zoekproces kun je nóg gerichter zoeken.",
                  "Je neemt uitgebreid de tijd om je zoekproces voor te bereiden en te evalueren. Zo voorkom je fouten en dubbel werk."
                ]
              },
              "presenteren": {
                "description": "Het presenteren van informatie",
                "label": "Presenteren",
                "tips": [
                  "De manier waarop je informatie presenteert, is niet altijd duidelijk en overzichtelijk. Misschien heb je moeite om structuur aan te brengen of houd je geen rekening met je publiek.",
                  "De manier waarop je informatie presenteert, kan duidelijker en overzichtelijker zijn. Misschien heb je moeite om structuur aan te brengen of houd je geen rekening met je publiek.",
                  "Je doet je best om informatie duidelijk en overzichtelijk te presenteren. Je zou dit nog verder kunnen verbeteren door meer structuur aan te brengen. Ook kun je nog meer rekening houden met het niveau en de voorkennis van je publiek.",
                  "De manier waarop je informatie presenteert, is over het algemeen duidelijk en overzichtelijk. Om dit verder te verbeteren is het goed om na te denken over wie je publiek is. Zo kun je beter rekening houden met het niveau en de voorkennis van je publiek.",
                  "De manier waarop je informatie presenteert, is duidelijk en overzichtelijk. Je let erop dat je verhaal een rode draad heeft. Ook houd je rekening met het niveau en de voorkennis van je publiek."
                ]
              },
              "strategie": {
                "description": "Het strategisch zoeken van informatie",
                "label": "Strategie",
                "tips": [
                  "Je beperkt je tot één zoekmethode. Hierdoor vind je niet altijd de juiste informatie. Ook duurt je zoektocht langer dan nodig is. Zoek eens naar tips om je manier van zoeken te verfijnen. Verander van strategie als je merkt dat je niet de juiste informatie kunt vinden.",
                  "Je hebt de neiging om je te beperken tot één zoekmethode. Hierdoor kan het lastig zijn om de juiste informatie te vinden. Ook duurt je zoektocht langer dan nodig is. Zoek eens naar tips om je manier van zoeken te verbeteren. Verander van strategie als je merkt dat je niet de juiste informatie kunt vinden.",
                  "Je probeert je manier van zoeken aan te passen aan de situatie. Hierdoor vind je meestal snel de juiste informatie. Bedenk of je bepaalde soorten informatie moeilijker kan vinden. Zou je dit kunnen verbeteren door bijvoorbeeld filters of specifiekere zoektermen te gebruiken?",
                  "Je past je zoekmethode meestal aan aan de informatie die je zoekt. Hierdoor kun je meestal snel de juiste informatie vinden. Gebruik deze kennis om je zoekproces nóg meer te stroomlijnen.",
                  "Tijdens je zoektocht pas je je zoekmethode aan om tot de beste resultaten te komen. Hierdoor vind je vaak gemakkelijk en snel de informatie die je zoekt."
                ]
              }
            }
          },
          "intelligence": {
            "closest_group": "Jouw score komt het beste overeen met referentiegroep:",
            "description": "In deze grafiek zie je jouw score vergeleken met de Nederlandse beroepsbevolking. We kunnen met 80% zekerheid zeggen dat jouw score zich tussen de stippellijnen bevindt.",
            "groups": {
              "hbo_ba": "HBO/Bachelor",
              "mbo": "MBO",
              "mbo1": "MBO1",
              "mbo2": "MBO2",
              "mbo3": "MBO3",
              "mbo4": "MBO4",
              "vmbo": "VMBO",
              "wo_ma": "WO/Master"
            },
            "header": "Werk- en denkniveau",
            "keys": {
              "abstract": "Abstract",
              "numeriek": "Numeriek",
              "totaalscore": "Totaalscore",
              "verbaal": "Verbaal"
            },
            "labels": {
              "average": "Gemiddeld",
              "high": "Bovengemiddeld",
              "low": "Benedengemiddeld"
            },
            "subtitles": [
              "Vergeleken met een %{group} referentiegroep ligt jouw totaalscore ruim onder het gemiddelde",
              "Vergeleken met een %{group} referentiegroep ligt jouw totaalscore onder het gemiddelde",
              "Vergeleken met een %{group} referentiegroep ligt jouw totaalscore rond het gemiddelde",
              "Vergeleken met een %{group} referentiegroep ligt jouw totaalscore boven het gemiddelde",
              "Vergeleken met een %{group} referentiegroep ligt jouw totaalscore ruim boven het gemiddelde"
            ],
            "title": "Referentiegroep %{group}"
          },
          "interests": {
            "sectoren": {
              "header": "Top 5 interesse in sectoren",
              "keys": {
                "administratie": "Administratie",
                "advieseninformatie": "Advies en informatie",
                "agrarischeondersteuning": "Agrarische ondersteuning",
                "beheer": "Beheer",
                "bouw": "Bouw",
                "commercieledienstverlening": "Commerciële dienstverlening",
                "communicatie": "Communicatie",
                "cultuur": "Cultuur",
                "delfstofwinning": "Delfstofwinning",
                "dieren": "Dieren",
                "elektrotechniek": "Elektrotechniek",
                "gezondheidszorg": "Gezondheidszorg",
                "glasaardewerkkeramiek": "Glas, aardewerk en keramiek",
                "grafischetechniek": "Grafische techniek",
                "handel": "Handel",
                "horeca": "Horeca",
                "houtmeubileringstechniek": "Houtmeubileringstechniek",
                "huishouding": "Huishouding",
                "industrielereiniging": "Industriële reiniging",
                "installatietechniek": "Installatietechniek",
                "magazijnopslagbezorging": "Magazijn, opslag en bezorging",
                "media": "Media",
                "metaal": "Metaal",
                "milieu": "Milieu",
                "onderwijs": "Onderwijs",
                "ontwerpenontwikkeling": "Ontwerp en ontwikkeling",
                "openbaarbestuur": "Openbaar bestuur",
                "organisatieenadvies": "Organisatie en advies",
                "personeelarbeidloopbaan": "Personeel, arbeid en loopbaan",
                "persoonlijkeverzorging": "Persoonlijke verzorging",
                "planningproductiebegeleiding": "Planning en productiebegeleiding",
                "planten": "Planten",
                "procestechniek": "Procestechniek",
                "rechtspraak": "Rechtspraak",
                "recreatie": "Recreatie",
                "schoonmaak": "Schoonmaak",
                "sportontspanning": "Sport en ontspanning",
                "textielmode": "Textiel en mode",
                "toerisme": "Toerisme",
                "veiligheid": "Veiligheid",
                "vervoer": "Vervoer",
                "visserij": "Visserij",
                "welzijn": "Welzijn",
                "wetenschap": "Wetenschap"
              }
            },
            "taken": {
              "header": "Top 5 interesse in taken",
              "keys": {
                "administratievetaken": "Administratieve taken",
                "analyseren": "Analyseren",
                "assisteren": "Assisteren",
                "conflictenoplossen": "Conflicten oplossen",
                "contactonderhouden": "Contact onderhouden",
                "creatiefzijn": "Creatief zijn",
                "cursussentrainingengeven": "Cursussen en trainingen geven",
                "elektronischeapparatierepareren": "Elektronische apparaten repareren",
                "fysiekinspannen": "Fysiek inspannen",
                "grotemachinesbedienen": "Grote machines bedienen",
                "informatieverzamelen": "Informatie verzamelen",
                "inroosteren": "Inroosteren",
                "klantenhelpen": "Klanten helpen",
                "leidinggeven": "Leiding geven",
                "lesgeven": "Les geven",
                "lezen": "Lezen",
                "machinesrepareren": "Machines repareren",
                "mensenadviseren": "Mensen adviseren",
                "mensenbegeleiden": "Mensen begeleiden",
                "mensenbeinvloeden": "Mensen beïnvloeden",
                "mensenovertuigen": "Mensen overtuigen",
                "metcollegasoverleggen": "Met collega's overleggen",
                "metcomputerswerken": "Met computers werken",
                "metelektronischeapparatenwerken": "Met elektronische apparaten werken",
                "methandenwerken": "Met handen werken",
                "metmachineswerken": "Met machines werken",
                "middelenvoorwerpenbeheren": "Middelen en voorwerpen beheren",
                "motiveren": "Motiveren",
                "onderhandelen": "Onderhandelen",
                "organiseren": "Organiseren",
                "projectenopzetten": "Projecten opzetten",
                "rekenen": "Rekenen",
                "schrijven": "Schrijven",
                "strategischdenken": "Strategisch denken",
                "teplannen": "Te plannen",
                "voertuigenbesturen": "Voertuigen besturen",
                "werkzaamhedenplannen": "Werkzaamheden plannen"
              }
            }
          },
          "it": {
            "descriptions": {
              "gemiddeld": "Jouw ICT-basisvaardigheden zijn voldoende, maar je hebt nog ruimte om te leren.",
              "hoog": "Jouw ICT-basisvaardigheden zijn uitstekend.",
              "laag": "Jouw ICT-basisvaardigheden kunnen een update gebruiken."
            },
            "header": "21st century skills",
            "title": "ICT-basisvaardigheden: Omgaan met technologie"
          },
          "media": {
            "categories": [
              "Je bent het beste in",
              "Je bent erg goed in",
              "Je bent net zo goed als de meeste anderen in",
              "Je kunt je nog verbeteren in",
              "Je hebt nog veel te leren over",
              "Dit is hoe jij omgaat met sociale media"
            ],
            "header": "21st century skills",
            "skills": {
              "consumptie": {
                "description": "Het kritisch zijn over welke informatie je deelt en consumeert",
                "label": "Consumptie",
                "tips": [
                  "Je bent niet kritisch over de informatie die je deelt. Probeer alerter te zijn op wat er online over je te vinden is en welke berichten je verspreidt.",
                  "Je bent niet altijd even kritisch op de informatie je deelt. Probeer alerter te zijn op wat er online over je te vinden is en welke berichten je verspreidt.",
                  "Je denkt meestal eerst na voordat je informatie deelt en zorgt ervoor dat er niks geks online over jou te vinden is.",
                  "Je bent meestal kritisch over de informatie die je deelt en wat er online over jou te vinden is.",
                  "Je bent kritisch over de informatie die je deelt en wat er online over jou te vinden is."
                ]
              },
              "maatschappelijk": {
                "description": "De maatschappelijke invloed van media",
                "label": "Maatschappelijk",
                "tips": [
                  "Je vindt het lastig om de maatschappelijke invloed van media in te schatten. Je hebt niet altijd door in hoeverre media mensen kunnen beïnvloeden of misleiden.",
                  "Je hebt een gematigd inzicht in de maatschappelijke invloed van media. Je hebt niet altijd goed door in hoeverre media gebruikt kunnen worden om mensen te beïnvloeden of zelfs te misleiden.",
                  "Je hebt gemiddeld inzicht in de maatschappelijke invloed van media. Je ziet meestal in hoe mensen gebruikmaken van media en erdoor misleid kunnen worden. Je kunt deze invloed van media nog niet in alle situaties herkennen.",
                  "Je hebt prima inzicht in de maatschappelijke invloed van media. Je ziet meestal in hoe mensen gebruikmaken van media en hoe mensen erdoor misleid kunnen worden.",
                  "Je hebt goed inzicht in de maatschappelijke invloed van media. Je ziet in hoe mensen gebruikmaken van media en hoe mensen erdoor misleid kunnen worden."
                ]
              },
              "mediawijsheid": {
                "label": "Mediawijsheid: Omgaan met media",
                "tips": [
                  "Sociale media zijn niet meer weg te denken. Niet alleen privé, ook op de werkplek spelen sociale media een rol. Hoe ga je als medewerker om met sociale media? Krijg inzicht in en handvatten voor het gebruik van sociale media als ambtenaar met de workshop <a href=\"https://www.ubrijk.nl/service/advocaten-en-adviseurs-arbeidsrecht/masterclasses-en-trainingen/social-media-en-ambtenaren\" target=\"_blank\">Social media en ambtenaren</a>.",
                  "Sociale media zijn niet meer weg te denken. Niet alleen privé, ook op de werkplek spelen sociale media een rol. Hoe ga je als medewerker om met sociale media? Krijg inzicht in en handvatten voor het gebruik van sociale media als ambtenaar met de workshop <a href=\"https://www.ubrijk.nl/service/advocaten-en-adviseurs-arbeidsrecht/masterclasses-en-trainingen/social-media-en-ambtenaren\" target=\"_blank\">Social media en ambtenaren</a>.",
                  "Je bent je bewust van de sterke en zwakke punten van moderne media, maar er is ook nog ruimte voor verbetering. Mediawijsheid gaat niet over of je media veel gebruikt. Mediawijsheid is vaak een kwestie van het bewust afwegen van voor- en nadelen. Geef jezelf de ruimte om je keuzes te (her)overwegen. Ben je misschien te kritisch en voorzichtig en maak je hierdoor te weinig gebruik van de krachten van moderne media? Of ben je je juist niet bewust genoeg van de risico's en maak je jezelf daardoor kwetsbaar?",
                  "Maak gebruik van je actieve en scherpe houding ten opzichte van mediawijsheid. Blijf op de hoogte van de laatste technologische ontwikkelingen en abonneer je op het <a href=\"https://ibestuur.nl/nieuwsbrief/\" target=\"_blank\">iBestuur magazine</a>.",
                  "Maak gebruik van je actieve en scherpe houding ten opzichte van mediawijsheid. Blijf op de hoogte van de laatste technologische ontwikkelingen en abonneer je op het <a href=\"https://ibestuur.nl/nieuwsbrief/\" target=\"_blank\">iBestuur magazine</a>."
                ]
              },
              "schuwheid": {
                "descriptions": [
                  "Je maakt weinig gebruik van sociale media in vergelijking met anderen.",
                  "Je maakt minder gebruik van sociale media dan gemiddeld.",
                  "Je maakt ongeveer evenveel gebruik van sociale media als anderen.",
                  "In vergelijking met anderen maak jij meer gebruik van sociale media.",
                  "Je maakt veel gebruik van sociale media in vergelijking met anderen."
                ],
                "label": "Schuwheid",
                "tips": [
                  "Je maakt weinig tot geen gebruik van sociale/digitale media. Veel mensen gebruiken sociale media om op de hoogte te blijven of contacten te onderhouden. Kan het zo zijn dat je informatie of mogelijkheden mist omdat je niet online actief bent? Of zie je het juist als een voordeel dat je zelf bepaalt welke informatie je ontvangt?",
                  "Je maakt relatief weinig gebruik van sociale/digitale media. Veel mensen gebruiken sociale media om op de hoogte te blijven of contacten te onderhouden. Kan het zo zijn dat je informatie of mogelijkheden mist omdat je niet online actief bent? Of zie je het juist als een voordeel dat je zelf bepaalt welke informatie je ontvangt?",
                  "Je maakt regelmatig gebruik van sociale/digitale media. Dit kan je helpen om op de hoogte te blijven en contacten te onderhouden. Maar dit soort media kan ook een vertekend beeld geven van de werkelijkheid.",
                  "Je maakt bovengemiddeld gebruik van sociale/digitale media. Waarschijnlijk ben je hierdoor op de hoogte van wat er (online) speelt en helpt het je contacten onderhouden. Maar wees je ervan bewust dat dit soort media een vertekend beeld kan geven van de werkelijkheid.",
                  "Je maakt relatief veel gebruik van sociale/digitale media. Waarschijnlijk ben je hierdoor goed op de hoogte van wat er (online) speelt. Ook helpt het je contacten te onderhouden. Maar wees je ervan bewust dat dit soort media een vertekend beeld kan geven van de werkelijkheid."
                ]
              },
              "trend": {
                "description": "Een oog hebben voor trends",
                "label": "Trend",
                "tips": [
                  "Je hebt geen scherp oog voor trends. Je houdt je niet bezig met wat wel en niet populair is.",
                  "Je hebt niet echt een oog voor trends. Je houdt je niet veel bezig met wat wel en niet populair is.",
                  "Je probeert op de hoogte te blijven van trends. Zaken die erg populair zijn of juist aan het verouderen zijn, vallen je op. Maar je bent ook niet iemand die meteen met alle trends meeloopt.",
                  "Je blijft op de hoogte van trends. Je hebt meestal door wat populair is en ziet het als zaken verouderen.",
                  "Je blijft goed op de hoogte van trends. Je hebt inzicht in wat populair is en merkt het als zaken verouderen."
                ]
              }
            }
          },
          "no_data": "(er zijn nog geen resultaten beschikbaar)",
          "no_relevant_data": "(er zijn nog geen resultaten beschikbaar waarvoor widgets bestaan)",
          "personality": {
            "descriptions": {
              "competitie": "Beter willen zijn dan anderen.",
              "conformisme": "Zich kunnen aanpassen aan de geldende normen en waarden. Gezag respecteren.",
              "contactbehoefte": "Behoefte hebben aan gezelschap.",
              "dominantie": "De leiding nemen. Een bepalende rol spelen bij de samenwerking.",
              "energie": "De energie hebben om veel te doen.",
              "frustratietolerantie": "Zich verdraagzaam opstellen naar anderen.",
              "hartelijkheid": "Aardig, vrolijk en opgewekt zijn tegen anderen.",
              "incasseringsvermogen": "Adequaat om kunnen gaan met kritiek en tegenslag.",
              "nauwkeurigheid": "Zorgvuldig werken, met oog voor details.",
              "onafhankelijkheid": "Zaken op eigen manier uit willen voeren.",
              "ordelijkheid": "Zelf structuur aanbrengen.",
              "originaliteit": "Nieuwe oplossingen bedenken. Creatief zijn.",
              "positivisme": "Een positieve kijk op het leven hebben.",
              "regelmaat": "Behoefte hebben aan orde en regels.",
              "sociaal_ontspannen": "Zich ontspannen voelen in het contact met anderen.",
              "status": "Het best mogelijke willen bereiken. Hogerop willen komen.",
              "vernieuwing": "Houden van verandering en het opdoen van nieuwe ervaringen.",
              "vertrouwen": "Geloven in de goede bedoelingen van anderen.",
              "volharding": "Inzet tonen. Taken en afspraken serieus nemen en nakomen.",
              "weloverwogen": "Zorgvuldig nadenken voordat gehandeld wordt.",
              "zelfonthulling": "Eigen gevoelens met anderen willen delen.",
              "zelfontwikkeling": "Gedreven zijn eigen kwaliteiten helemaal te benutten.",
              "zelfvertoon": "Graag in het middelpunt van de belangstelling staan.",
              "zelfvertrouwen": "Zelfverzekerd zijn.",
              "zorgzaamheid": "Zich betrokken voelen bij anderen. Anderen graag willen helpen."
            },
            "header": "Persoonlijkheid",
            "keys": {
              "competitie": "Competitie",
              "conformisme": "Conformisme",
              "contactbehoefte": "Contactbehoefte",
              "dominantie": "Dominantie",
              "energie": "Energie",
              "frustratietolerantie": "Frustratietolerantie",
              "hartelijkheid": "Hartelijkheid",
              "incasseringsvermogen": "Incasseringsvermogen",
              "nauwkeurigheid": "Nauwkeurigheid",
              "onafhankelijkheid": "Onafhankelijkheid",
              "ordelijkheid": "Ordelijkheid",
              "originaliteit": "Originaliteit",
              "positivisme": "Positivisme",
              "regelmaat": "Regelmaat",
              "sociaal_ontspannen": "Sociaal ontspannen",
              "status": "Status",
              "vernieuwing": "Variatiebehoefte",
              "vertrouwen": "Vertrouwen",
              "volharding": "Volharding",
              "weloverwogen": "Weloverwogen",
              "zelfonthulling": "Zelfonthulling",
              "zelfontwikkeling": "Zelfontwikkeling",
              "zelfvertoon": "Zelfvertoon",
              "zelfvertrouwen": "Zelfvertrouwen",
              "zorgzaamheid": "Zorgzaamheid"
            },
            "titles": [
              "Jouw meest kenmerkende eigenschappen",
              "Jouw kenmerkende eigenschappen",
              "Jouw minst kenmerkende eigenschappen"
            ]
          },
          "thinking": {
            "average": {
              "benedengemiddeld": "Benedengemiddeld",
              "bovengemiddeld": "Bovengemiddeld",
              "gemiddeld": "Gemiddeld",
              "hoog": "Hoog",
              "laag": "Laag"
            },
            "header": "21st century skills",
            "skills": {
              "communiceren": {
                "descriptions": {
                  "benedengemiddeld": "In de 21ste eeuw gaat communicatie steeds vaker schriftelijk, bijvoorbeeld via e-mail of messenger-apps. Voor goede communicatie is het onder andere belangrijk dat je je boodschap een goede structuur heeft en te volgen is voor anderen. Ook moet je rekening houden met de ontvanger van je boodschap. Vergeleken met andere rijksmedewerkers gaat communiceren je van nature wat minder gemakkelijker af.",
                  "bovengemiddeld": "In de 21ste eeuw gaat communicatie steeds vaker schriftelijk, bijvoorbeeld via e-mail of messenger-apps. Voor goede communicatie is het onder andere belangrijk dat je je boodschap een goede structuur heeft en te volgen is voor anderen. Ook moet je rekening houden met de ontvanger van je boodschap. Vergeleken met andere rijksmedewerkers gaat communiceren je van nature relatief gemakkelijk af.",
                  "gemiddeld": "In de 21ste eeuw gaat communicatie steeds vaker schriftelijk, bijvoorbeeld via e-mail of messenger-apps. Voor goede communicatie is het onder andere belangrijk dat je je boodschap een goede structuur heeft en te volgen is voor anderen. Ook moet je rekening houden met de ontvanger van je boodschap. Je hebt een gemiddelde aanleg voor communiceren vergeleken met de referentiegroep van rijksmedewerkers.",
                  "hoog": "In de 21ste eeuw gaat communicatie steeds vaker schriftelijk, bijvoorbeeld via e-mail of messenger-apps. Voor goede communicatie is het onder andere belangrijk dat je je boodschap een goede structuur heeft en te volgen is voor anderen. Ook moet je rekening houden met de ontvanger van je boodschap. Van nature heb je een sterke aanleg voor communiceren.",
                  "laag": "In de 21ste eeuw gaat communicatie steeds vaker schriftelijk, bijvoorbeeld via e-mail of messenger-apps. Voor goede communicatie is het onder andere belangrijk dat je je boodschap een goede structuur heeft en te volgen is voor anderen. Ook moet je rekening houden met de ontvanger van je boodschap. Vergeleken met andere rijksmedewerkers heb jij minder aanleg voor communiceren."
                },
                "label": "Communiceren"
              },
              "creatief_denken": {
                "descriptions": {
                  "benedengemiddeld": "Vergeleken met andere rijksmedewerkers kom je van nature minder gemakkelijk tot nieuwe ideeën en toepassingen. Creatieve personen hebben originele ideeën. Ze hebben vaak minder behoefte aan structuur en zekerheid. Creatief denken helpt je om nieuwe mogelijkheden te zien en je verder te ontwikkelen.",
                  "bovengemiddeld": "Vergeleken met andere rijksmedewerkers gaat het je van nature wat gemakkelijker af om tot nieuwe ideeën en toepassingen te komen. Creatieve personen hebben originele ideeën. Ze hebben vaak minder behoefte aan structuur en zekerheid. Creatief denken helpt je om nieuwe mogelijkheden te zien en je verder te ontwikkelen. Zoek bewust situaties op waar je deze vaardigheid kan toepassen en onderhouden.",
                  "gemiddeld": "Vergeleken met andere rijksmedewerkers heb je een gemiddelde aanleg om tot nieuwe ideeën en toepassingen te komen. Creatieve personen hebben originele ideeën. Ze hebben vaak minder behoefte aan structuur en zekerheid. Creatief denken helpt je om nieuwe mogelijkheden te zien en je verder te ontwikkelen. Er is ruimte om je hierin te ontwikkelen.",
                  "hoog": "Vergeleken met andere rijksmedewerkers gaat het je van nature gemakkelijk af om tot nieuwe ideeën en toepassingen te komen. Creatieve personen hebben originele ideeën. Ze hebben vaak minder behoefte aan structuur en zekerheid. Creatief denken helpt je om nieuwe mogelijkheden te zien en je verder te ontwikkelen. Zoek bewust situaties op waar je deze vaardigheid kan toepassen en onderhouden.",
                  "laag": "Vergeleken met andere rijksmedewerkers heb je van nature meer moeite om tot nieuwe ideeën en toepassingen te komen. Creatieve personen hebben originele ideeën. Ze hebben vaak minder behoefte aan structuur en zekerheid. Creatief denken helpt je om nieuwe mogelijkheden te zien en je verder te ontwikkelen."
                },
                "label": "Creatief denken"
              },
              "kritisch_denken": {
                "descriptions": {
                  "benedengemiddeld": "Kritische denkers zijn onafhankelijk en vinden het leuk om zaken tot de bodem uit te zoeken. Kritisch denken houdt je scherp en zorgt ervoor dat je niet zomaar vertrouwt op wat technologie ons vertelt. Je natuurlijke vermogen om een probleem kritisch en diepgaand te benaderen is benedengemiddeld in vergelijking met andere rijksmedewerkers.",
                  "bovengemiddeld": "Kritische denkers zijn onafhankelijk en vinden het leuk om zaken tot de bodem uit te zoeken. Je natuurlijke vermogen om een probleem kritisch en diepgaand te benaderen is bovengemiddeld. Kritisch denken houdt je scherp en zorgt ervoor dat je niet zomaar vertrouwt op wat technologie ons vertelt.",
                  "gemiddeld": "Kritische denkers zijn onafhankelijk en vinden het leuk om zaken tot de bodem uit te zoeken. Kritisch denken houdt je scherp en zorgt ervoor dat je niet zomaar vertrouwt op wat technologie ons vertelt. Je natuurlijke vermogen om een probleem kritisch en diepgaand te benaderen is vergelijkbaar met dat van de gemiddelde rijksmedewerker.",
                  "hoog": "Kritische denkers zijn onafhankelijk en vinden het leuk om zaken tot de bodem uit te zoeken. Je natuurlijke vermogen om een probleem kritisch en diepgaand te benaderen is sterk. Kritisch denken houdt je scherp en zorgt ervoor dat je niet zomaar vertrouwt op wat technologie ons vertelt.",
                  "laag": "Kritische denkers zijn onafhankelijk en vinden het leuk om zaken tot de bodem uit te zoeken. Kritisch denken houdt je scherp en zorgt ervoor dat je niet zomaar vertrouwt op wat technologie ons vertelt. Je natuurlijke vermogen om een probleem kritisch en diepgaand te benaderen is minder sterk dan dat van andere rijksmedewerkers."
                },
                "label": "Kritisch denken"
              },
              "probleem_oplossen": {
                "descriptions": {
                  "benedengemiddeld": "Mensen met een sterk probleemoplossend vermogen kunnen tegen een stootje en geven niet snel op. Ze zetten door en krijgen anderen gemakkelijk mee met hun ideeën. Hierdoor weten ze ook complexe problemen of langdurige conflicten op te lossen. Vergeleken met andere rijksmedewerkers is jouw aanleg voor het oplossen van problemen benedengemiddeld.",
                  "bovengemiddeld": "Mensen met een sterk probleemoplossend vermogen kunnen tegen een stootje en geven niet snel op. Ze zetten door en krijgen anderen gemakkelijk mee met hun ideeën. Hierdoor weten ze ook complexe problemen of langdurige conflicten op te lossen. Jouw aanleg voor het oplossen van problemen is bovengemiddeld.",
                  "gemiddeld": "Mensen met een sterk probleemoplossend vermogen kunnen tegen een stootje en geven niet snel op. Ze zetten door en krijgen anderen gemakkelijk mee met hun ideeën. Hierdoor weten ze ook complexe problemen of langdurige conflicten op te lossen. Jouw aanleg voor het oplossen van problemen is vergelijkbaar met dat van de gemiddelde rijksmedewerker.",
                  "hoog": "Mensen met een sterk probleemoplossend vermogen kunnen tegen een stootje en geven niet snel op. Ze zetten door en krijgen anderen gemakkelijk mee met hun ideeën. Hierdoor weten ze ook complexe problemen of langdurige conflicten tot een goed einde te brengen. Vergeleken met de gemiddelde rijksmedewerker heb je een sterke aanleg voor het oplossen van problemen.",
                  "laag": "Mensen met een sterk probleemoplossend vermogen kunnen tegen een stootje en geven niet snel op. Ze zetten door en krijgen anderen gemakkelijk mee met hun ideeën. Hierdoor weten ze ook complexe problemen of langdurige conflicten op te lossen. Vergeleken met de gemiddelde rijksmedewerker heb je een minder sterke aanleg voor het oplossen van problemen."
                },
                "label": "Problemen oplossen"
              },
              "samenwerken": {
                "descriptions": {
                  "benedengemiddeld": "Van nature heb je een wat mindere aanleg voor samenwerken dan de gemiddelde rijksmedewerker. Om goed samen te werken, moet je op anderen durven vertrouwen en goed communiceren met elkaar. Ook moet je je verantwoordelijkheid nemen.",
                  "bovengemiddeld": "Van nature heb je een bovengemiddelde aanleg voor samenwerken. De vaardigheid samenwerken bestaat uit je verantwoordelijkheid nemen, op anderen durven vertrouwen en communiceren met elkaar.",
                  "gemiddeld": "Van nature heb je een gemiddelde aanleg voor samenwerken vergeleken met andere rijksmedewerkers. Om goed samen te werken moet je op anderen durven vertrouwen en goed communiceren met elkaar. Ook moet je je verantwoordelijkheid nemen.",
                  "hoog": "Van nature heb je een sterke aanleg voor samenwerken. De vaardigheid samenwerken bestaat uit je verantwoordelijkheid nemen, op anderen durven vertrouwen en communiceren met elkaar.",
                  "laag": "Van nature heb je minder aanleg voor samenwerken dan de gemiddelde rijksmedewerker. Om goed samen te werken, moet je op anderen durven vertrouwen en goed communiceren met elkaar. Ook moet je je verantwoordelijkheid nemen."
                },
                "label": "Samenwerken"
              },
              "sociale_culturele_vaardigheden": {
                "descriptions": {
                  "benedengemiddeld": "Vergeleken met de referentiegroep heb je van nature wat meer moeite om rekening te houden met iemands achtergrond. Om goed te kunnen omgaan met mensen met een andere achtergrond is het belangrijk dat je openstaat voor verschillende opvattingen en ideeën. Ook heb je goede sociale voelsprieten nodig en moet je openstaan voor zelfverbetering.",
                  "bovengemiddeld": "Vergeleken met de referentiegroep houd je van nature gemakkelijk rekening met iemands achtergrond. Om goed te kunnen omgaan met mensen met een andere achtergrond is het belangrijk dat je openstaat voor verschillende opvattingen en ideeën. Ook heb je goede sociale voelsprieten nodig en sta je open voor zelfverbetering.",
                  "gemiddeld": "Vergeleken met de referentiegroep gaat het je ongeveer even goed af als het gemiddelde om rekening te houden met iemands achtergrond. Om goed te kunnen omgaan met mensen met een andere achtergrond is het belangrijk dat je openstaat voor verschillende opvattingen en ideeën. Ook heb je goede sociale voelsprieten nodig en moet je openstaan voor zelfverbetering.",
                  "hoog": "Vergeleken met de referentiegroep houd je van nature gemakkelijk rekening met iemands achtergrond. Om goed te kunnen omgaan met mensen met een andere achtergrond is het belangrijk dat je openstaat voor verschillende opvattingen en ideeën. Ook heb je goede sociale voelsprieten nodig en sta je open voor zelfverbetering.",
                  "laag": "Vergeleken met de referentiegroep heb je van nature moeite om rekening te houden met iemands achtergrond. Om goed te kunnen omgaan met mensen met een andere achtergrond is het belangrijk dat je openstaat voor verschillende opvattingen en ideeën. Ook heb je goede sociale voelsprieten nodig en moet je openstaan voor zelfverbetering."
                },
                "label": "Sociale en culturele vaardigheden"
              },
              "zelfregulering": {
                "descriptions": {
                  "benedengemiddeld": "In het werk, vandaag en zeker in de toekomst, wordt het steeds belangrijker dat je als medewerker meer doet dan simpelweg orders opvolgen. Daarom is het belangrijk dat je verantwoordelijkheid neemt om je eigen werk in te plannen en volgens planning af te maken. Vergeleken met andere rijksmedewerkers heb je van nature iets meer moeite om je eigen werkzaamheden te reguleren.",
                  "bovengemiddeld": "In het werk, vandaag en zeker in de toekomst, wordt het steeds belangrijker dat je als medewerker meer doet dan simpelweg orders opvolgen. Daarom is het belangrijk dat je verantwoordelijkheid neemt om je eigen werk in te plannen en volgens planning af te maken. Vergeleken met andere rijksmedewerkers heb je een bovengemiddelde aanleg voor het reguleren van je werkzaamheden.",
                  "gemiddeld": "In het werk, vandaag en zeker in de toekomst, wordt het steeds belangrijker dat je als medewerker meer doet dan simpelweg orders opvolgen. Daarom is het belangrijk dat je verantwoordelijkheid neemt om je eigen werk in te plannen en volgens planning af te maken. Je hebt hier een gemiddelde aanleg voor vergeleken met andere rijksmedewerkers.",
                  "hoog": "In het werk, vandaag en zeker in de toekomst, wordt het steeds belangrijker dat je als medewerker meer doet dan simpelweg orders opvolgen. Daarom is het belangrijk dat je verantwoordelijkheid neemt om je eigen werk in te plannen en volgens planning af te maken. Je hebt van nature talent voor het reguleren van je werkzaamheden.",
                  "laag": "In het werk, vandaag en zeker in de toekomst, wordt het steeds belangrijker dat je als medewerker meer doet dan simpelweg orders opvolgen. Daarom is het belangrijk dat je verantwoordelijkheid neemt om je eigen werk in te plannen en volgens planning af te maken. Het reguleren van je werkzaamheden past van nature minder goed bij je."
                },
                "label": "Zelfregulering"
              }
            },
            "titles": {
              "thinking_styles": "Denkstijlen: Hoe werk ik samen met technologie?",
              "work_competences": "Werkcompetenties: Hoe voer ik mijn werk uit?"
            }
          },
          "title": "Persoonlijk resultatenoverzicht",
          "values": {
            "descriptions": {
              "demotivators": {
                "aandacht": "Het liefst blijf je op de achtergrond. Wanneer je jezelf zou moeten profileren, staat dat je tegen.",
                "analyseren": "Problemen analyseren ervaar je als demotiverend, iets wat je liever niet doet.",
                "autonomie": "Autonomie en zelfstandigheid zoek je liever niet op. Liever stem je af met anderen en laat je anderen beslissingen voor je nemen.",
                "beinvloeden": "Het moeten beïnvloeden van anderen staat je tegen.",
                "carriere": "Je hebt mogelijk een aversie tegen status en prestatiedrang. Indien men je vraagt je daarop te richten, zal dat waarschijnlijk juist een demotiverend effect hebben.",
                "concrete_resultaten": "Concrete resultaten moeten bereiken zal je eerder demotiveren dan stimuleren. Je bent mogelijk eerder procesgericht dan resultaatgericht.",
                "creatief_denken": "Wanneer er een beroep gedaan wordt op jouw creativiteit, zal dat je waarschijnlijk eerder demotiveren dan stimuleren.",
                "financiele_beloning": "Financiële targets kunnen je demotiveren. De financiële kant van de zaak interesseert je juist niet, dus als je daarop aangestuurd wordt kan dat je demotiveren.",
                "fysiek_actief_zijn": "Je bent liever niet lichamelijk actief in werk. Wanneer dat te veel fysieke inspanning vraagt, ervaar je dat mogelijk als negatief.",
                "hectiek": "Je prefereert juist een rustige omgeving, waar je weet wat er op je af komt en waar je rustig jouw werk kan doen.",
                "hulp_verlenen": "Zelf direct hulp verlenen aan anderen demotiveert je.",
                "kwaliteit": "Het gaat je waarschijnlijk eerder om de grote lijn dan de details. Hoge kwaliteitseisen daaraan kunnen je tegenstaan.",
                "ondernemen": "Je vindt het prima om initiatieven aan anderen over te laten. Van jou moet men dat niet verlangen; dat zou je juist demotiveren.",
                "ontwikkelen": "Je hoeft jezelf niet te ontwikkelen. Dat doe je ook minder graag. Misschien vind je dat je prima toe kan met jouw huidige kwaliteiten.",
                "samenwerking": "Je werkt liever alleen dan met anderen. Moeten samenwerken en vooral moeten afstemmen met anderen frustreert je eerder dan dat je er plezier aan beleeft.",
                "taakuitdaging": "Nieuwe of moeilijke uitdagingen demotiveren je juist. Liever heb je het gevoel dat je zeker bent dat je jouw taken aankan.",
                "waardering_en_erkenning": "Je bent niet gevoelig voor waardering en erkenning, eerder staat het je tegen.",
                "zekerheid_en_stabiliteit": "Zekerheid en stabiliteit demotiveren je juist. Je vindt het prettiger als je niet precies weet waar je aan toe bent en als je verrast wordt.",
                "zinvolle_bijdrage": "Je bent geen idealist. Sterker nog, waarschijnlijk demotiveert het je wanneer je voor een maatschappelijk gerichte organisatie moet werken."
              },
              "neutral_motivators": {
                "aandacht": "Je zoekt niet telkens gelegenheden op om op de voorgrond te treden. Integendeel, je vindt het prima als niet alle ogen op je gericht zijn.",
                "analyseren": "Analyseren van informatie of problemen ervaar je niet als motiverend, maar je hebt er ook geen hekel aan.",
                "autonomie": "Zelfstandigheid en autonomie in jouw werk motiveren je niet in sterke mate. Je hebt er geen problemen mee om verantwoording af te leggen aan anderen.",
                "beinvloeden": "Je vindt het minder belangrijk om invloed te hebben. Je kan je ook laten leiden door anderen.",
                "carriere": "Status en een carrière doorlopen stimuleren je maar in beperkte mate. Je bent er niet per se op gericht om een eervolle positie te bereiken.",
                "concrete_resultaten": "Je hoeft niet per se naar duidelijke of concrete resultaten toe te werken. Je kan ook meer procesmatig bezig zijn.",
                "creatief_denken": "Je hoeft niet zelf creatief te zijn of creatief te kunnen denken in jouw werk.",
                "financiele_beloning": "Financiële beloning kan je wel prettig vinden, maar je vindt het niet heel belangrijk.",
                "fysiek_actief_zijn": "Je staat neutraal tegenover beweging en lichamelijke activiteiten in jouw werk.",
                "hectiek": "Dynamiek motiveert je niet speciaal. Je wil graag de tijd hebben om zaken af te ronden en om je te concentreren op jouw werk.",
                "hulp_verlenen": "Het verlenen van hulp aan anderen motiveert je niet per se, je zoekt het niet actief op.",
                "kwaliteit": "Je bent minder gemotiveerd door hoge kwaliteitsnormen. Je wordt hierdoor niet uitgedaagd.",
                "ondernemen": "Je hoeft niet altijd de ruimte te hebben om zelf initiatieven te nemen.",
                "ontwikkelen": "Het motiveert je op dit moment niet in hoge mate om jezelf verder te ontwikkelen. Waarschijnlijk wil je juist gebruik maken van de kennis en kunde die je momenteel bezit.",
                "samenwerking": "Je staat neutraal tegenover samenwerking met anderen. Je zou in een team kunnen werken, maar ook prima alleen kunnen functioneren. Je hoeft niet per se ergens bij te horen of in een team te werken.",
                "taakuitdaging": "Jouw uitdaging zit niet zozeer in de moeilijkheidsgraad van jouw werk. Ook hoef je niet telkens met nieuwe uitdagingen geconfronteerd te worden.",
                "waardering_en_erkenning": "Waardering en erkenning zijn weliswaar plezierig, maar motiveren je in beperkte mate. Je bent waarschijnlijk meer gericht op jouw eigen mening over jouw handelen, dan dat je gevoelig bent voor de feedback van anderen.",
                "zekerheid_en_stabiliteit": "Zekerheid en stabiliteit zijn voor jou niet zo belangrijk. Je hoeft niet precies te weten waar je aan toe bent.",
                "zinvolle_bijdrage": "Een zinvolle bijdrage leveren aan de maatschappij draagt niet specifiek bij aan jouw motivatie. Je vindt het niet belangrijk om met jouw werk iets te betekenen voor anderen."
              },
              "primary_motivators": {
                "aandacht": "Je staat graag op de voorgrond en wordt gemotiveerd wanneer de ogen op je gericht staan. Je kan ervan genieten wanneer anderen tegen je opkijken. Een functie die een zekere mate van status biedt zal je prettig vinden. Een risico kan zijn dat je ten onrechte aandacht vraagt of dat je anderen te weinig ruimte biedt.",
                "analyseren": "Je vindt het motiverend om analyses te maken, na te denken over vraagstukken en problemen. Je houdt van hersenkrakers en denkwerk. Een risico hiervan kan zijn dat je te lang dooranalyseert en je te lang vastbijt in een probleem.",
                "autonomie": "Je vindt het motiverend om autonoom te kunnen werken en om zelf te bepalen hoe je jouw werkt doet. Vrijheid en onafhankelijkheid stimuleren je zeer. Wanneer anderen je kort houden of direct aansturen kan dat tot irritaties leiden. Een risico kan zijn dat anderen je al te onafhankelijk en mogelijk eigenwijs vinden. Een goede inbedding in een organisatie is voor jou van belang. Geheel zelfstandig werken kan een optie voor je zijn.",
                "beinvloeden": "Je vindt het motiverend om anderen te beïnvloeden. Jouw mening wil je graag terugzien in beslissingen en hoe dingen lopen. Anderen overtuigen en motiveren spreekt je aan. Een risico kan zijn dat je minder gevoelig bent voor de mening van anderen of te veel doordrukt.",
                "carriere": "Je vindt het belangrijk om in jouw werk iets te bereiken, hogerop te komen. Status en aanzien spelen daarbij een rol. Daarbij kan je competitief ingesteld zijn en jezelf meten met anderen. Een risico kan zijn dat je al te zeer gericht bent op de buitenkant, bewaak dat je jezelf niet voorbij loopt en ook intrinsiek gemotiveerd bezig bent.",
                "concrete_resultaten": "Je werkt graag naar een duidelijk en concreet resultaat toe. Je vindt een zichtbaar eindresultaat waaraan jouw directe bijdrage is te herkennen stimulerend. Een risico kan zijn dat je verslapt wanneer er niet regelmatig concrete resultaten worden bereikt.",
                "creatief_denken": "Je wil graag jouw creativiteit gebruiken, ideeën genereren en buiten de bestaande kaders denken. Daarbij zoek je vaak nieuwe oplossingen. Een risico kan zijn dat je te veel ideeën hebt om allemaal uit te voeren, of dat niet alle ideeën even praktisch zijn.",
                "financiele_beloning": "Je wordt gemotiveerd door het krijgen van een goede financiële beloning. Financiële prikkels, zoals targets en variabele beloning, stimuleren je extra. Dit geldt ook voor het bereiken van financiële resultaten voor jouw opdrachtgevers of werkgevers. Een risico hiervan kan zijn dat je vanwege financiële prikkels iets oppakt wat je beter niet had kunnen doen, bijvoorbeeld omdat het je op andere aspecten minder voldoening geeft.",
                "fysiek_actief_zijn": "Je bent graag fysiek actief. Bewegen, sport of lichamelijke activiteiten ervaar je als stimulerend. Dit kan betekenen dat je graag fysiek werk doet. Maar omdat dat niet altijd mogelijk is, zal je in ieder geval privé een uitlaatklep nodig hebben in bijvoorbeeld sport. Een risico kan zijn dat je te onrustig bent voor een echte kantoorbaan.",
                "hectiek": "Je houdt van dynamiek en drukte om je heen: een hectische omgeving stimuleert je. Ook ben je zelf graag druk bezig. Wanneer het te rustig is op het werk, kan je verslappen en je mogelijk minder concentreren.",
                "hulp_verlenen": "Wanneer je anderen van dienst kan zijn of kan helpen met concrete activiteiten geeft dat je veel voldoening. Je zal daarmee ook sterk gericht zijn op het welbevinden van anderen. Een risico van deze waarde is dat je daarin te ver gaat en jouw grenzen te weinig bewaakt.",
                "kwaliteit": "Je ervaart het als stimulerend om een hoge kwaliteit te leveren. Goed is niet altijd goed genoeg voor je, je wil graag aan hoge eisen voldoen. Een risico kan zijn dat daar niet altijd tijd voor is, of dat anderen niet in dezelfde mate aan jouw kwaliteitseisen voldoen.",
                "ondernemen": "Je wil graag ondernemend bezig zijn. Je houdt ervan initiatieven te nemen en kansen te benutten. Dat kan als zelfstandig ondernemer, maar ook binnen een organisatie waarin ruimte geboden wordt aan ondernemerschap. Een nadeel van deze drijfveer kan zijn dat je te optimistisch bent en te veel risico's neemt.",
                "ontwikkelen": "Je hecht er veel waarde aan jezelf te ontwikkelen en nieuwe kennis en vaardigheden op te doen. Stilstand zie je als achteruitgang. Vaak zal je het ook leuk vinden om anderen te helpen zich verder te ontwikkelen. Een risico kan zijn dat je (te) snel uitgekeken raakt en weer iets nieuws wilt leren.",
                "samenwerking": "Samenwerking met anderen vind je erg belangrijk. Je houdt ervan om in direct contact met anderen te staan en samen te werken aan een gemeenschappelijke taak of een gezamenlijk doel. Een risico kan zijn dat je verslapt wanneer je zelfstandig of alleen werkt.",
                "taakuitdaging": "Je wordt geprikkeld door uitdagende taken die veel van je vragen. Je wil graag het maximale uit jezelf halen. Een risico kan zijn dat je vrij snel toe bent aan iets nieuws. Ook kan de uitdaging te groot zijn, waardoor je risico's loopt dat zaken niet goed gaan.",
                "waardering_en_erkenning": "Waardering en erkenning zijn voor jou zeer motiverend. Wanneer anderen je complimenteren en erkentelijk zijn, stimuleert dat je. Je zet je daar ook voor in. Een risico kan zijn dat je te afhankelijk wordt van waardering. Blijf ook voldoening halen uit het werk zelf.",
                "zekerheid_en_stabiliteit": "Je wil graag zekerheid en stabiliteit. Je voelt je prettig als je weet waar je aan toe bent. In jouw werk vind je het plezierig om een duidelijk omschreven takenpakket te hebben, zodat je weet wat er van je verwacht wordt. Een risico kan zijn dat je minder goed met veranderingen en onzekerheden om kan gaan.",
                "zinvolle_bijdrage": "Je levert graag een zinvolle bijdrage aan de maatschappij of aan goede doelen. Je vindt het belangrijk om met jouw werk iets te betekenen voor anderen. Dat kan in jouw functie tot uitdrukking komen of in de organisatie waarvoor je werkt. Een risico hiervan kan zijn dat je te idealistisch bent en daarmee minder realistisch bent of jouw eigen belangen te zeer opzij schuift."
              },
              "secondary_motivators": {
                "aandacht": "Je staat graag op de voorgrond en wordt gemotiveerd wanneer de ogen op je gericht staan. Je kan ervan genieten wanneer anderen tegen je opkijken.",
                "analyseren": "Je vindt het motiverend om analyses te maken, na te denken over vraagstukken en problemen. Je houdt van hersenkrakers en denkwerk.",
                "autonomie": "Je vindt het motiverend om autonoom te kunnen werken. Je vindt het motiverend om zelf te kunnen bepalen hoe je jouw werk doet. Vrijheid en onafhankelijkheid stimuleren je zeer.",
                "beinvloeden": "Je vindt het motiverend om anderen te beïnvloeden. Jouw mening wil je graag terugzien in beslissingen en hoe dingen lopen. Anderen overtuigen en motiveren spreekt je aan.",
                "carriere": "Je vindt het belangrijk om in jouw werk iets te bereiken, hogerop te komen. Status en aanzien spelen daarbij een rol. Daarbij kan je enigszins competitief ingesteld zijn en jezelf meten met anderen.",
                "concrete_resultaten": "Je werkt graag naar een duidelijk en concreet resultaat toe. Je vindt een zichtbaar eindresultaat waaraan jouw directe bijdrage is te herkennen stimulerend.",
                "creatief_denken": "Je wil graag jouw creativiteit gebruiken, ideeën genereren en buiten de bestaande kaders denken. Daarbij zoek je vaak nieuwe oplossingen.",
                "financiele_beloning": "Je wordt gemotiveerd door het krijgen van een goede financiële beloning. Financiële prikkels, zoals targets en variabele beloning, stimuleren je extra. Dit geldt ook voor het bereiken van financiële resultaten voor jouw opdrachtgevers of werkgevers.",
                "fysiek_actief_zijn": "Je bent graag fysiek actief. Bewegen, sport of lichamelijke activiteiten ervaar je als stimulerend. Dit kan betekenen dat je graag fysiek werk doet. Maar omdat dat niet altijd mogelijk is, zal je in ieder geval privé een uitlaatklep nodig hebben in bijvoorbeeld sport.",
                "hectiek": "Je houdt van dynamiek en drukte om je heen: een drukke omgeving stimuleert je. Ook ben je zelf graag druk bezig.",
                "hulp_verlenen": "Wanneer je anderen van dienst kan zijn of kan helpen met concrete activiteiten geeft dat je veel voldoening. Je zal daarmee ook sterk gericht zijn op het welbevinden van anderen.",
                "kwaliteit": "Je ervaart het als stimulerend om een hoge kwaliteit te leveren. Goed is niet altijd goed genoeg voor je, je wil graag aan hoge eisen voldoen.",
                "ondernemen": "Je wil graag ondernemend bezig zijn. Je houdt ervan initiatieven te nemen en kansen te benutten. Dat kan ook binnen een organisatie waarin ruimte geboden wordt aan ondernemerschap.",
                "ontwikkelen": "Je hecht er veel waarde aan jezelf te ontwikkelen en nieuwe kennis en vaardigheden op te doen. Stilstand zie je als achteruitgang. Vaak zal je het ook leuk vinden om anderen te helpen zich verder te ontwikkelen.",
                "samenwerking": "Samenwerking met anderen vind je erg belangrijk. Je houdt ervan om in direct contact met anderen te staan en samen te werken aan een gemeenschappelijke taak of een gezamenlijk doel.",
                "taakuitdaging": "Je wordt geprikkeld door uitdagende taken die veel van je vragen. Je wil graag het maximale uit jezelf halen.",
                "waardering_en_erkenning": "Waardering en erkenning zijn voor jou zeer motiverend. Wanneer anderen je complimenteren en erkentelijk zijn, stimuleert dat je.",
                "zekerheid_en_stabiliteit": "Je wil graag zekerheid en stabiliteit. Je voelt je prettig als je weet waar je aan toe bent. In jouw werk vind je het plezierig om een duidelijk omschreven takenpakket te hebben, zodat je weet wat er van je verwacht wordt.",
                "zinvolle_bijdrage": "Je levert graag een zinvolle bijdrage aan de maatschappij of aan goede doelen. Je vindt het belangrijk om met jouw werk iets te beteken voor anderen. Dat kan in jouw functie tot uitdrukking komen of in de organisatie waarvoor je werkt."
              }
            },
            "header": "Carrièrewaarden",
            "keys": {
              "aandacht": "Profilering",
              "analyseren": "Analyseren",
              "autonomie": "Autonomie",
              "beinvloeden": "Beïnvloeden",
              "carriere": "Carrière",
              "concrete_resultaten": "Concrete resultaten",
              "creatief_denken": "Creatief denken",
              "financiele_beloning": "Financiële beloning",
              "fysiek_actief_zijn": "Fysiek actief zijn",
              "hectiek": "Dynamiek",
              "hulp_verlenen": "Hulp verlenen",
              "kwaliteit": "Kwaliteit",
              "ondernemen": "Ondernemen",
              "ontwikkelen": "Ontwikkelen",
              "samenwerking": "Samenwerking",
              "taakuitdaging": "Taakuitdaging",
              "waardering_en_erkenning": "Waardering en erkenning",
              "zekerheid_en_stabiliteit": "Zekerheid en stabiliteit",
              "zinvolle_bijdrage": "Zinvolle bijdrage"
            },
            "titles": {
              "demotivators": "Wat zijn jouw demotivatoren?",
              "neutral_motivators": "Wat zijn jouw neutrale motivatoren?",
              "primary_motivators": "Wat zijn jouw primaire motivatoren?",
              "secondary_motivators": "Wat zijn jouw secundaire motivatoren?"
            }
          }
        },
        "ixly_test": {
          "calculating_result": "Resultaat wordt berekend...",
          "component": "Element",
          "document_option_for": "Documentopties voor",
          "download_modal_header": "Rapport downloaden",
          "download_report": "Download rapport",
          "generating_report": "Rapport wordt gegenereerd...",
          "make_visible_confirm": "Deze handeling is onomkeerbaar. Weet u zeker dat u door wilt gaan?",
          "no_test_selected_desc": "Selecteer een assessment via de instellingen van dit element",
          "no_test_selected_title": "Geen assessment geselecteerd",
          "norm_group_for": "Beschikbare normgroepen",
          "payment_required": "Uw organisatie heeft onvoldoende credits om dit rapport te downloaden.",
          "program_subtasks": "%{program} bestaat uit %{subtest_names}",
          "report_name": "Rapportnaam",
          "report_settings": "Rapport-instellingen",
          "report_visible_for_advisor": "Resultaat zichtbaar voor coach",
          "report_visible_for_both": "Resultaat zichtbaar voor allebei",
          "report_visible_for_candidate": "Resultaat zichtbaar voor kandidaat",
          "resume_subtest": "Hervat %{test}",
          "resume_test": "Hervat het assessment",
          "save_date": "Datum Opgeslagen",
          "select_document_option_placeholder": "Selecteer de gewenste documentoptie voor dit rapport:",
          "select_norm_group_placeholder": "Selecteer de gewenste normgroep voor dit rapport:",
          "settings_modal_header": "Rapport-instellingen",
          "skip_verification": "Overslaan",
          "start_review": "Begin de review",
          "start_subtest": "Start %{test}",
          "start_test": "Start het assessment",
          "start_verification": "Starten",
          "start_verification_subtest": "Begin de verificatie van %{test}",
          "start_verification_test": "Begin de verificatie",
          "task": "Taak",
          "test_completed": "Afgerond",
          "test_inprogress": "Gestart",
          "test_pending": "Nog niet gestart",
          "test_reviewrequired": "Review benodigd",
          "test_verificationrequired": "Verificatie benodigd",
          "verification_completed": "De verificatietest is afgerond.",
          "verification_restart": "Herstarten",
          "verification_skipped": "Verificatie is overgeslagen. Indien nodig kunt u deze herstarten met behulp van de knop.",
          "verification_skipped_processing": "Verificatie is overgeslagen. Indien nodig kunt u deze herstarten met behulp van de knop, nadat het rapport gegenereerd is.",
          "view_results": "Bekijk resultaten",
          "waiting_verification_start": "De kandidaat heeft de verificatietest nog niet gestart; verificatie kan nog steeds worden overgeslagen."
        },
        "job_market_scan": {
          "compare": "Vergelijk beroepen",
          "description_created": "Arbeidsmarktkansen berekent huidige en toekomstige kansen op de Nederlandse arbeidsmarkt. Na het invullen van enkele gegevens over opleiding en baan wordt er eenmalig een score gegeven tussen 1 (zeer slecht) en 99 (zeer goed). Aan het eind is een rapport te downloaden dat uitgebreide resultaten bevat.",
          "description_finished": "Arbeidsmarktkansen is afgerond! Alle resultaten zijn berekend. Klik op de 'Download' knop om het rapport (opnieuw) te downloaden als PDF.",
          "description_in_progress": "Arbeidsmarktkansen is nog niet volledig afgerond. Dit is nodig om het rapport te genereren. Klik op de knop hieronder om verder te gaan.",
          "download": "Download",
          "download_alert": "Arbeidsmarktkansen is meer dan 6 maanden geleden afgerond; er kunnen geen nieuwe rapporten meer gegenereerd worden.",
          "download_description_1": "Download hieronder alle opgeslagen rapporten",
          "download_description_2": "om een nieuw rapport te genereren met geactualiseerde vacatures, opleidingen en cursussen (6 maanden beschikbaar)",
          "download_link_text": "Of klik hier",
          "download_title": "Rapporten downloaden",
          "resume": "Hervat",
          "start": "Start",
          "terms_and_conditions": "Ik ga akkoord met de algemene voorwaarden",
          "title": "Arbeidsmarktkansen",
          "title_finished": "Resultaten",
          "working": "Bezig..."
        },
        "job_market_scan_en": {
          "title": "Arbeidsmarktkansen Engelstalig",
          "title_finished": "Resultaten"
        },
        "logbook": {
          "back": "Terug",
          "content_placeholder": "hier komt de inhoud",
          "delete": "Verwijder",
          "help_text": "In uw logboek kunt u pagina's met notities bijhouden.",
          "logbook": "Logboek",
          "new_page": "Nieuwe pagina",
          "title_placeholder": "titel logboekpagina"
        },
        "quiz": {
          "add_answer": "Antwoord toevoegen",
          "answer_correct": "Juist",
          "answer_incorrect": "Onjuist",
          "answer_placeholder": "Vul hier een antwoord in",
          "answer_placeholder_viewer": "De kandidaat vult hier een antwoord in",
          "answer_text_placeholder": "Vul hier antwoord %{index} in",
          "back_current": "Vorige",
          "back_started": "Terug",
          "correct_placeholder": "Vul hier het juiste antwoord in",
          "delete": "Verwijder",
          "next_add": "Vraag toevoegen",
          "next_check": "Controleer",
          "next_current": "Volgende",
          "question_of": "VRAAG %{index} VAN %{length}",
          "question_text_placeholder": "Vul hier de vraag in",
          "question_type_choice": "Meerkeuze",
          "question_type_open": "Open",
          "selections_number": "Aantal selecties",
          "selections_to": "tot",
          "start_text_checking": "Bekijk antwoorden",
          "start_text_resume": "Hervat",
          "start_text_set_up": "Stel in",
          "start_text_start": "Start",
          "start_text_viewer": "Bekijk vragen"
        },
        "report_visibility": {
          "advisor": {
            "both": "Het rapport is nu beschikbaar voor de kandidaat. Het recht van de kandidaat om het rapport te kunnen downloaden/bekijken kan worden teruggetrokken door het schuifknopje rechts te deactiveren.",
            "self": "Het rapport is nog niet beschikbaar voor de kandidaat. Door het schuifknopje rechts te activeren krijgt de kandidaat het recht om het rapport te downloaden en te bekijken."
          },
          "candidate": {
            "both": "Het rapport is nu beschikbaar voor de coach. Het recht van de coach om het rapport te kunnen downloaden/bekijken kan worden teruggetrokken door het schuifknopje rechts te deactiveren.",
            "self": "Het rapport is nog niet beschikbaar voor de coach. Door het schuifknopje rechts te activeren krijgt de coach het recht om het rapport te downloaden en te bekijken."
          }
        },
        "search_job": {
          "delete": "Verwijderen",
          "favorite": "Favoriet",
          "favorites": "Favorieten",
          "location": "Waar?",
          "no_favorites_chosen": "(geen favorieten gekozen)",
          "no_jobs_found": "(geen vacatures gevonden)",
          "query": "Wat?",
          "search": "Zoeken",
          "view": "Bekijk"
        },
        "templates": {
          "add": "Voeg toe",
          "add_template": "Voeg template toe",
          "added": "Selecteerbaar",
          "builder": {
            "add_column": "Voeg kolom toe",
            "add_element": "Voeg sectie toe",
            "add_row": "Voeg rij toe",
            "block": "Blok",
            "circle": "Cirkel",
            "date_formats": {
              "D": "dagnummer (1 of 2 cijfers)",
              "DD": "dagnummer (2 cijfers)",
              "M": "maandnummer (1 of 2 cijfers)",
              "MM": "maandnummer (2 cijfers)",
              "MMM": "maandnaam (afgekort)",
              "MMMM": "maandnaam",
              "YY": "jaar (2 cijfers)",
              "YYYY": "jaar (4 cijfers)",
              "ddd": "dagnaam (afgekort)",
              "dddd": "dagnaam"
            },
            "description": "Beschrijving",
            "destroy_confirm_column": "Weet u zeker dat u deze kolom wilt verwijderen?",
            "destroy_confirm_element": "Weet u zeker dat u deze sectie wilt verwijderen?",
            "destroy_confirm_row": "Weet u zeker dat u deze rij wilt verwijderen?",
            "global": "Voor alle organisaties?",
            "header": "Kopje",
            "header_font_size": "Lettergrootte kop",
            "height": "Hoogte",
            "hide_borders": "Verberg omlijningen",
            "hide_preview": "Verberg voorbeeld",
            "margin_from_top": "Ruimte boven sectie",
            "optional": "Optioneel",
            "padding": "Ruimte rondom blok",
            "shape": "Vorm",
            "show_borders": "Toon omlijningen",
            "show_header": "Toon kop",
            "show_icon": "Toon icoontje",
            "show_preview": "Toon voorbeeld",
            "square": "Vierkant",
            "title": "Titel",
            "type": "Type sectie",
            "variable_add_option": "Voeg keuze toe",
            "variable_date": "Datum",
            "variable_date_format": "Formaat",
            "variable_dropdown": "Selectie",
            "variable_help_text": "Helptekst",
            "variable_options": "Keuzes",
            "variable_text": "Tekst",
            "variable_type": "Type variabele",
            "width": "Breedte"
          },
          "change_template": "Verander template",
          "default": "Geselecteerd",
          "delete": "Verwijder",
          "deselect": "Deselecteer",
          "destroy_confirm": "Weet u zeker dat u deze template wilt verwijderen?",
          "documentation": "(klik hier voor de CV & brief builder documentatie)",
          "edit": "Bewerk",
          "edit_replacements": "Bewerk helpteksten",
          "filler": {
            "add_item": "Voeg item toe",
            "add_optional": "Voeg deze sectie aan het document toe",
            "change": "Veranderen",
            "crop_and_save": "Bijsnijden & Opslaan",
            "download_docx": "Download DOCX",
            "download_pdf": "Download PDF",
            "remove": "Verwijderen",
            "remove_optional": "Verwijder deze sectie uit het document",
            "upload_image": "Kies een afbeelding"
          },
          "image": "Afbeelding",
          "list": "Lijst",
          "no_template_selected": "Geen template geselecteerd",
          "not_ready_yet_text": "Nog niet klaar",
          "open": "Open",
          "remove": "Haal weg",
          "remove_template": "Verwijder template",
          "select": "Selecteer",
          "select_template": "Selecteer een template",
          "switch_template": "Template selectie",
          "text": "Tekst"
        },
        "text": {
          "placeholder": "Klik hier om tekst in te voeren (selecteer deze vervolgens voor extra styling-opties)"
        },
        "vacancy": {
          "action": "Actie",
          "action_deadline": "Deadline actie",
          "action_placeholder": "solliciteren, eerste gesprek voorbereiden...",
          "back": "Terug",
          "before": "voor",
          "delete": "Verwijder",
          "help_text": "Noteer interessante vacatures en bepaal vervolgacties met behulp van de vacature manager.",
          "new_vacancy": "Nieuwe vacature",
          "next_action": "Hieronder kan de eerstvolgende actie worden afgesproken met een bijbehorende deadline:",
          "notes": "Notities",
          "notes_placeholder": "overige opmerkingen",
          "status": "Status",
          "statuses": {
            "created": "Aangemaakt",
            "first_interview": "Eerste interview",
            "hired": "Aangenomen",
            "interested": "Geïnteresseerd",
            "letter_sent": "Brief geschreven",
            "other": "Anders",
            "rejected": "Afgewezen",
            "second_interview": "Tweede interview"
          },
          "title": "Titel",
          "title_placeholder": "postbezorger bij PostNL",
          "vacancy_link": "Vacature-link",
          "vacancy_link_placeholder": "voeg een link naar de vacature toe",
          "vacancy_manager": "Vacature Manager",
          "view_vacancy": "bekijk vacature"
        },
        "visibility": {
          "advisor": {
            "both": "Het document is nu beschikbaar voor de kandidaat. Het recht van de kandidaat om het document te kunnen downloaden/bekijken kan worden teruggetrokken door het schuifknopje rechts te deactiveren.",
            "self": "Het document is nog niet beschikbaar voor de kandidaat. Door het schuifknopje rechts te activeren krijgt de kandidaat het recht om het document te downloaden en te bekijken."
          },
          "candidate": {
            "both": "Het document is nu beschikbaar voor de coach. Het recht van de coach om het document te kunnen downloaden/bekijken kan worden teruggetrokken door het schuifknopje rechts te deactiveren.",
            "self": "Het document is nog niet beschikbaar voor de coach. Door het schuifknopje rechts te activeren krijgt de coach het recht om het document te downloaden en te bekijken."
          }
        },
        "youtube_video": {
          "url_placeholder": "Voer bij de instellingen van dit element de volledige URL van een YouTube video in"
        }
      },
      "cost_centers": {
        "number_of_users": "%{number} actief"
      },
      "editable_empty": "(geen)",
      "program_templates": {
        "number_of_pages": {
          "one": "1 stap",
          "other": "%{count} stappen"
        },
        "status": {
          "deleted": "Verwijderd",
          "editable": "Bewerkbaar",
          "hidden": "Verwijderd",
          "published": "Gepubliceerd",
          "template": "Template"
        }
      },
      "programs": {
        "number_of_pages": {
          "one": "1 stap",
          "other": "%{count} stappen"
        },
        "status": {
          "deleted": "Verwijderd",
          "editable": "Bewerkbaar",
          "hidden": "Verwijderd",
          "published": "Gepubliceerd",
          "template": "Template"
        }
      },
      "rooms": {
        "browser_unsupported": "Deze browser wordt niet ondersteund. Gebruik een moderne browser zoals Chrome of Firefox.",
        "default_local_display_name": "ik",
        "default_remote_display_name": "Anoniem"
      }
    },
    "label": {
      "search": "Zoeken"
    },
    "labels": {
      "index": {
        "title": "Labels"
      }
    },
    "language": {
      "name": "Nederlands"
    },
    "languages": {
      "all": "Alle talen",
      "de": "Duits",
      "en": "Engels",
      "fr": "Frans",
      "it": "Italiaans",
      "nl": "Nederlands"
    },
    "layouts": {
      "assessment": {
        "header": {
          "logout": "Uitloggen",
          "notifications": "Notificaties"
        },
        "language_menu": {
          "select_language": "Selecteer taal"
        },
        "resource_header": {
          "notifications": "Notificaties",
          "profile": "Profiel",
          "switch_account": "Switch naar een ander account"
        },
        "users": {
          "dropdown": {
            "notifications": "Notificaties"
          }
        }
      },
      "mailer": {
        "personal_info": "Persoonlijke gegevens wijzigen",
        "privacy_policy": "Privacy Policy",
        "rights": "Ixly 2023 Vleutensevaart 100 3532 AD Utrecht",
        "terms": "Algemene Voorwaarden",
        "unsubscribe": "Afmelden"
      }
    },
    "legacy_invoices": {
      "show": {
        "title": "Test-Toolkit factuur"
      }
    },
    "login_mailer": {
      "confirmation_button": {
        "text": "Account aanmaken"
      },
      "welcome_mail": {
        "subject": "Welkom"
      }
    },
    "media_item_share": {
      "empty_shared_with_list": "Er is niemand waarmee je een media-item kunt delen",
      "only_shared_with": {
        "one": "%{count} organisatie manager",
        "other": "%{count} organisatie managers"
      },
      "send": "Delen",
      "share_with": "Delen met",
      "shared_with": {
        "one": "en %{count} organisatie manager",
        "other": "en %{count} organisatie managers"
      }
    },
    "media_item_shares": {
      "deleted": "Delen van media-items is succesvol verwijderd.",
      "drawer_content": {
        "download": "Download"
      },
      "failed_create": "Het media-item kon niet gedeeld worden, selecteer een gebruiker om het media item mee te delen.",
      "list": {
        "created_at": "Datum",
        "filename": "Naam",
        "relates_to": "Gerelateerd aan"
      },
      "success_create": "Het media-item is succesvol gedeeld."
    },
    "media_items": {
      "add": "Bestand toevoegen",
      "allowed_formats": "Toegestane bestandsformaten:",
      "cannot_delete": "Verijderen mislukt. Een media-item dat in gebruik is kan niet verwijderd worden.",
      "created": "Gemaakt",
      "deleted": "Media-item is succesvol verwijderd.",
      "destroy": "Verwijderen",
      "download": "Download",
      "failed_upload": "Bestandsgrootte is te groot (maximum is 50 MB)",
      "files": "Mijn bestanden",
      "list": {
        "created_at": "Datum",
        "filename": "Naam",
        "relates_to": "Gerelateerd aan",
        "shared_with": "Gedeeld met"
      },
      "relates_to": "Gerelateerd aan",
      "shared_by": "Gedeeld door",
      "shared_with": "Gedeeld met",
      "shared_with_me": "Gedeeld met mij",
      "success_upload": "Media-item is succesvol aangemaakt.",
      "title": "Documenten & Media",
      "upload_title": "Kies bestand"
    },
    "notice": {
      "bulk": {
        "candidates_enrolled": "De kandidaten zijn per e-mail op de hoogte gebracht van het nieuw toegewezen proces",
        "send_welcome": "Welkomstmails zijn verzonden"
      },
      "password_has_been_updated": "Het wachtwoord is aangepast"
    },
    "notification": {
      "details": {
        "assessment_finished_notification": "%{candidate} heeft %{assessment} afgerond.",
        "candidate_assessment": {
          "finished": "%{candidate} heeft %{assessment} afgerond.",
          "invited": "Het assessment '%{assessment}' is aan je toegekend."
        },
        "comment_notification": "%{sender} heeft je een bericht gestuurd: \"%{message}\"",
        "credit_limit_reached_notification": "Je ontvangt dit bericht omdat de vooruitbetaalde credits die voor je organisatie beschikbaar zijn onder de %{credit_limit} zijn uitgekomen. We raden je aan om meer credits aan te schaffen zodat je rapporten kan blijven genereren.",
        "document": {
          "approved_notification": "%{sender} heeft het document %{filename} geaccordeerd.",
          "rejected_notification": "%{sender} heeft het document %{filename} afgewezen. Je kunt een nieuw document uploaden en deze opnieuw laten accorderen.",
          "submitted_notification": "%{sender} verzoekt je om het document %{filename} te accorderen.",
          "uploaded_notification": "%{sender} heeft het document %{filename} geüpload."
        },
        "enroller_notification": "%{candidate} heeft zich ingeschreven in '%{title}'.",
        "enrollment": {
          "coach_action_notification": "%{candidate}'s proces '%{title}' heeft assessments waar een rapport voor geselecteerd dient te worden. Nadat de gewenste rapporten geselecteerd zijn kan de kandidaat worden uitgenodigd om het proces te starten.",
          "created_notification": "Het proces '%{title}' is aan je toegekend."
        },
        "enrollment_page": {
          "component_action_notification": "De stap %{title} in het proces %{process} bevat een onderdeel waar actie voor benodigd is.",
          "done_notification": "De stap %{title} in het proces %{process} is afgerond.",
          "rejected_notification": "De stap %{title} in het proces %{process} is afgewezen. Je kunt de onderdelen van de stap bijwerken en deze opnieuw inleveren.",
          "submitted_notification": "De stap %{title} in het proces %{process} is ingeleverd. Je kunt de stap controleren en goed- of afkeuren."
        },
        "media_item_notification": "Het bestand %{filename} is toegevoegd aan de Documenten & Mediabibliotheek.",
        "media_item_share_notification": "%{name} heeft %{filename} met je gedeeld in de Documenten & Mediabibliotheek.",
        "post_notification": "Het blogartikel %{title} is met je gedeeld.",
        "postmark": {
          "inactive_recipient": "Er is iets misgegaan met het versturen van een e-mail naar %{candidate}. Controleer het e-mailadres %{email} op fouten en neem contact op met onze klantenservice via info@ixly.nl met de details van het probleem als het e-mailadres correct is.",
          "invalid_address": "Het e-mailadres %{email} van %{candidate} is niet geldig. Corrigeer het e-mailadres zodat %{candidate} e-mails kan ontvangen."
        },
        "review_required": "Het assessment %{assessment_title} van %{candidate_name} heeft een review nodig.",
        "suppressed_email": "We hebben geprobeerd een e-mail te sturen naar %{name}, maar dit is niet gelukt vanwege de %{reason} reden. Zorg ervoor dat het gebruikte e-mailadres correct is (%{email}).\n%{name} heeft geen e-mail ontvangen.\n",
        "unsuppressed_email": "We kunnen weer berichten sturen via %{email} naar %{name}.",
        "verification_required": "Het assessment %{assessment_title} van %{candidate_name} heeft verificatie nodig.\nKies of de verificatie gestart kan worden, of dat deze overgeslagen wordt zodat het assessment afgerond kan worden.\n",
        "verification_started": "Het assessment %{assessment_title} heeft een verificatietest die gestart kan worden"
      },
      "type": {
        "AppointmentAcceptedNotification": "Afspraak geaccepteerd",
        "AppointmentCancelledNotification": "Afspraak geannuleerd",
        "AppointmentDeclinedNotification": "Afspraak afgewezen",
        "AppointmentSubmittedNotification": "Afspraak voorgesteld",
        "assessment_finished_notification": "Assessment afgerond",
        "candidate_assessment": {
          "finished": "Assessment afgerond",
          "invited": "Nieuw toegekend assessment"
        },
        "comment_notification": "Nieuw bericht",
        "credit_limit_reached_notification": "Weinig credits over",
        "document": {
          "approved_notification": "Document geaccordeerd",
          "rejected_notification": "Document afgewezen",
          "submitted_notification": "Te accorderen document",
          "uploaded_notification": "Bestand geüpload"
        },
        "enroller_notification": "Nieuwe inschrijving",
        "enrollment": {
          "coach_action_notification": "Rapportselectie benodigd",
          "created_notification": "Nieuw toegekend proces"
        },
        "enrollment_page": {
          "component_action_notification": "Actie benodigd",
          "done_notification": "Stap afgerond",
          "rejected_notification": "Stap afgewezen",
          "submitted_notification": "Stap ingeleverd"
        },
        "media_item_notification": "Er is een nieuw bestand toegevoegd aan de Documenten & Mediabibliotheek",
        "media_item_share_notification": "Er is een bestand met je gedeeld",
        "post_notification": "Nieuw blogartikel",
        "postmark_notification": "Kan e-mail niet bezorgen",
        "review_required": "Review benodigd voor %{assessment_title}",
        "suppressed_email": "We konden %{member_type} niet bereiken via e-mail",
        "unsuppressed_email": "De %{member_type} is weer bereikbaar via e-mail",
        "verification_required": "Verificatie benodigd voor %{assessment_title}",
        "verification_started": "Het assessment %{assessment_title} heeft een verificatietest die gestart kan worden"
      }
    },
    "notifications_overview_mailer": {
      "notification_mail": {
        "subject": "Je hebt nieuwe meldingen ontvangen"
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ".",
          "format": "%u %n",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": ".",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "miljard",
            "million": "miljoen",
            "quadrillion": "biljard",
            "thousand": "duizend",
            "trillion": "biljoen",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "byte",
              "other": "bytes"
            },
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "tb": "TB"
          }
        }
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "organization": "Organisatie",
    "organization_products": {
      "create": {
        "created": "Producten toegevoegd aan de organisatie"
      },
      "destroy": {
        "already_started": "Dit product is al van start gegaan."
      },
      "index": {
        "from_subscription": "Uit abonnement",
        "new_button": "Producten toevoegen",
        "table_header": [
          "Naam",
          "Startdatum",
          "Einddatum",
          "Acties"
        ],
        "title": "Producten"
      },
      "new": {
        "title": "Producten toevoegen aan %{organization}"
      },
      "row": {
        "product_delete": "Weet u zeker dat u dit product uit de organisatie wilt verwijderen?"
      }
    },
    "organization_status": {
      "active": "Actief",
      "created": "Aangemaakt",
      "deleted": "Verwijderd",
      "inactive": "Inactief"
    },
    "organizations": {
      "billing": {
        "details": {
          "cost_centers": {
            "in_use": "Deze kostenplaats heeft nog actieve gebruikers.",
            "new_button": "Nieuwe kostenplaats",
            "table_header": [
              "Naam",
              "Beschrijving",
              "Gebruikers",
              "Kandidaten",
              "Acties"
            ],
            "title": "Kostenplaatsen"
          },
          "organization": "Organisatie",
          "title": "Gegevens"
        },
        "help_text": "Hier vindt u een overzicht van uw abonnement, gebruik, facturen en gegevens",
        "info": "Kies een betalingsmethode en de creditbundel die je wilt aanschaffen.",
        "invoices": {
          "legacy_invoices": "Test-Toolkit facturen",
          "no_orders": "(er zijn nog geen bestellingen gedaan)",
          "previous_orders": "Vorige bestellingen",
          "title": "Facturen"
        },
        "payment": "Betalingsmethode",
        "payment_title": "Creditbundel kopen",
        "subscription": {
          "credits": {
            "invoice": "De credit ophoging is doorgevoerd! Er wordt een factuur gestuurd. U heeft nu %{count} credits",
            "open": "De credit ophoging is doorgevoerd! Het bedrag wordt automatisch afgeschreven. U heeft nu %{count} credits",
            "paid": "De credit ophoging is doorgevoerd! U heeft nu %{count} credits"
          },
          "mandate": {
            "activate_credit_purchase": "Activeer je organisatie met een creditbundel aankoop zodat jouw kandidaten aan de slag kunnen!",
            "change_html": "<a href='%{link}' data-method='post'>Klik hier om een nieuwe automatische incasso te autoriseren</a>.",
            "credit_card": "de creditcard met het nummer XXXX XXXX XXXX %{number}.",
            "direct_debit": "de IBAN-rekening met het nummer %{number}.",
            "not_valid_html": "Uw gebruikers kunnen pas inloggen na een autorisatiebetaling. Dit zorgt ervoor dat Ixly B.V. gemachtigd wordt om af te schrijven van de hiervoor gebruikte rekening. Hierop zijn <a class='alert-link' href='%{link}' target='_blank'>onze algemene voorwaarden</a> van toepassing.",
            "open": "De autorisatiebetaling wordt momenteel nog verwerkt; herlaad de pagina voor een update",
            "paid": "De autorisatiebetaling is geslaagd! Uw kosten worden voortaan afgeschreven van %{number}",
            "valid": "Uw kosten worden automatisch afgeschreven van"
          },
          "not_active": "Uw organisatie heeft geen actief abonnement",
          "order": {
            "canceled": "De betaling is geannuleerd"
          },
          "payment": {
            "automatic_method": {
              "explain": "Autoriseer door een eerste kredietbundel aan te schaffen voor vlotte en moeiteloze krediettransacties, nu en in de toekomst",
              "title": "Automatische betaling"
            },
            "direct_method": {
              "info_text1": "Ervaar directe betalingen zonder extra kosten met iDEAL",
              "info_text2": "Veilig, snel en gemakkelijk.",
              "title": "Directe betaling"
            },
            "info_text1": "Geen zorgen meer over betalingen!",
            "info_text2": "Met automatische incasso regel je het simpel en snel.",
            "manual_method": {
              "confirmation": "Weet je het zeker? Dit betekent dat u vanaf juni € 9,99 per factuur betaalt.",
              "explain": "We brengen per 1 juli voor elke factuur € 9,99 administratiekosten in rekening",
              "info_text1": "Betaal je liever achteraf?",
              "info_text2": "Dat kan met handmatig betalen.",
              "please_note": "Let wel op",
              "title": "Handmatige betaling"
            },
            "mollie": {
              "conditional1": "Handmatig betalen is verleden tijd",
              "conditional2": "Je hebt volledige grip op je financiën",
              "conditional3": "Bespaar op extra kosten en gedoe met facturen"
            },
            "payment_type": {
              "automatic": "Automatische betaling",
              "direct": "Directe betaling met ideal",
              "invoice": "Handmatige betaling"
            }
          },
          "subscriptions": {
            "active_advisors_title": "Actieve adviseurs",
            "active_candidates": "(van %{max_candidates})",
            "active_candidates_title": "Actieve kandidaten",
            "active_premium_candidates_title": "Actieve premium kandidaten",
            "active_users": "(van %{max_users})",
            "active_users_title": "Actieve coaches",
            "already_started": "Dit abonnement is al van start gegaan.",
            "credits": "Credits:",
            "downgrade": "Downgrade",
            "downgrades": "Downgrades",
            "incidental_subscription_credits_left": "Indien er credits over zijn wanneer het nieuwe abonnement start dan worden deze eerst gebruikt",
            "new_button": "Nieuw abonnement",
            "prices": "Prijzen",
            "purchase": "Kopen",
            "settings": "Specificaties",
            "table_header": [
              "Naam",
              "Startdatum",
              "Einddatum",
              "Acties"
            ],
            "title": "Abonnementen",
            "total_advisors": "(van %{max_users})",
            "total_advisors_title": "Alle adviseurs",
            "total_candidates": "(van %{max_candidates})",
            "total_candidates_title": "Alle kandidaten",
            "total_coaches_title": "Alle coaches",
            "total_premium_candidates_title": "Alle premium kandidaten",
            "upgrade": "Upgrade",
            "upgrades": "Upgrades",
            "user_counts": "Gebruikersaantallen"
          },
          "title": "Abonnement"
        },
        "title": "Facturering",
        "usage": {
          "active_candidates": {
            "prepaid": "vooruitbetaald",
            "table_header": [
              "Kandidaat",
              "Geactiveerd op",
              "Prijs"
            ],
            "title": "Actieve kandidaten"
          },
          "active_coaches": {
            "table_header": [
              "Coach",
              "Geactiveerd op",
              "Prijs"
            ],
            "title": "Actieve coaches"
          },
          "aycr_periods": {
            "table_header": [
              "Kandidaat",
              "Geldig voor",
              "Prijs"
            ],
            "title": "Kandidaten met gestarte assessments"
          },
          "generated_reports": {
            "table_header": [
              "Assessment",
              "Rapport",
              "Kandidaat",
              "Gegenereerd op",
              "Credits",
              "Prijs"
            ],
            "title": "Gegenereerde rapporten"
          },
          "period": "Periode",
          "premium_components": {
            "table_header": [
              "Element",
              "Kandidaat",
              "Gestart op",
              "Prijs"
            ],
            "title": "Gestarte premium elementen"
          },
          "title": "Gebruik",
          "total_with_vat": "Totale kosten inclusief %{vat}% btw: %{price}"
        }
      },
      "edit": {
        "available_languages_confirm": "U staat op het punt om talen uit te schakelen die gebruikt worden door uw gebruikers. Hun taal wordt veranderd naar uw organisaties standaard taal. Weet u het zeker?",
        "explanation": "De selecteerde taal geldt als standaard instelling voor nieuwe gebruikers binnen de organisatie.",
        "logo": "Logo",
        "title": "Organisatie-instellingen",
        "upload_logo": "Klik hier om een logo te uploaden"
      },
      "index": {
        "table_header": [
          "Naam",
          "Abonnement",
          "Gebruikers",
          "Processen",
          "Status",
          "Acties"
        ]
      },
      "payment_method_mollie_direct": {
        "success": "Directe betaling is succesvol aangemaakt"
      },
      "row": {
        "impersonate": "Log in als",
        "organization_delete": "Weet u zeker dat u deze organisatie wilt verwijderen?"
      },
      "texts": {
        "welcome_mail": {
          "example_mail_body": "Hi %{user_name}. Welkom bij Assessment Platform.\n\nEr is een nieuw account voor je aangemaakt bij Assessment Platform.\n\nStel direct je wachtwoord in om toegang te krijgen tot je persoonlijke omgeving.\n\n<a href='%{password_set_link}'>Account aanmaken</a>.\n\nHet is belangrijk om een sterk en uniek wachtwoord te kiezen om je account te beschermen. Zorg er daarom voor dat je nieuwe wachtwoord minstens tien tekens lang is en een combinatie is van letters, cijfers en symbolen.\n\nMet vriendelijke groet,\n%{organization_name}"
        }
      },
      "update": {
        "organization_updated": "De organisatie is bijgewerkt"
      }
    },
    "otp_secret": {
      "cancel_otp": "Annuleer het authenticator proces",
      "input_the_code": "Voer de code in die door de authenticator-app is verstrekt",
      "scan_the_code": "Scan de code met een authenticator app zoals %{app_1} of %{app_2}."
    },
    "page_status": {
      "created": {
        "seen": "Ongelezen",
        "set": "Open",
        "submit": "Open"
      },
      "done": {
        "seen": "Bekeken",
        "set": "Afgerond",
        "submit": "Goedgekeurd"
      },
      "hidden": "Verborgen",
      "optional": "Optioneel",
      "rejected": {
        "submit": "Opnieuw"
      },
      "submitted": {
        "submit": "Ingeleverd"
      }
    },
    "pagy": {
      "combo_nav_js": "<label>Pagina %{page_input} van %{pages}</label>",
      "info": {
        "multiple_pages": "Toont <b>%{from}-%{to}</b> %{item_name} van <b>%{count}</b> in totaal",
        "no_items": "Geen %{item_name} gevonden",
        "single_page": "Toont <b>%{count}</b> %{item_name}"
      },
      "item_name": {
        "one": "stuk",
        "other": "stuks"
      },
      "items_selector_js": "<label>Toont %{items_input} %{item_name} per pagina</label>",
      "nav": {
        "gap": "&hellip;",
        "next": "Volgende&nbsp;&rsaquo;",
        "prev": "&lsaquo;&nbsp;Vorige"
      }
    },
    "pallet": {
      "image": {
        "label": "Kies een afbeelding"
      },
      "ixly_test": {
        "description": "Toelichting op het assessment",
        "label": "Naam van het assessment"
      },
      "label": "Label",
      "quiz": {
        "title": "Quiz"
      },
      "templates": {
        "title": "CV & Brief Builder"
      }
    },
    "password": {
      "forgot_password": {
        "help_text": "Vul uw e-mailadres in om een nieuw wachtwoord aan te vragen",
        "request": "Aanvragen",
        "title": "Wachtwoord vergeten"
      },
      "reset_password_form": {
        "button": "Wijzigen",
        "help_text": "Voer uw nieuwe wachtwoord in en bevestig deze",
        "invalid_link": "Uw herstel-link is gebruikt, verlopen of ongeldig: vraag er opnieuw een aan",
        "title": "Wachtwoord wijzigen"
      },
      "send_reset_password_link": {
        "help_text": "Binnen enkele minuten ontvangt u de e-mail waarmee u een nieuw wachtwoord kunt instellen",
        "title": "Wachtwoordherstelmail verstuurd",
        "unknown_email": "Het opgegeven e-mailadres is bij ons niet bekend"
      },
      "update_password_with_reset_token": {
        "invalid_link": "Uw herstel-link is gebruikt, verlopen of ongeldig: vraag er opnieuw een aan",
        "password_has_been_reset": "Uw wachtwoord is gewijzigd en u bent succesvol ingelogd",
        "password_is_of_insufficient_length": "Het nieuwe wachtwoord moet ten minste 10 tekens bevatten",
        "passwords_dont_match": "Wachtwoord en bevestiging komen niet overeen"
      }
    },
    "placeholder": {
      "search": "Zoeken"
    },
    "posts": {
      "available_locales": "Beschikbare talen",
      "destroy": {
        "destroyed": "Het artikel is verwijderd"
      },
      "destroy_confirm": "Weet u zeker dat u dit artikel wilt verwijderen?",
      "edit": "Bewerk artikel",
      "edit_rules": "Bewerk deelregels",
      "empty": "(er zijn nog geen artikelen geplaatst)",
      "help_text": "Beheer deze artikelen en hun deelregels",
      "hide_more": "Verberg",
      "index": {
        "title": "Blogartikelen"
      },
      "new": "Nieuw artikel",
      "open": "Open",
      "post_rules": {
        "add": "Voeg een deelregel toe",
        "private": "(niemand nog)",
        "program_labels": {
          "multiple": ", waaraan <strong>processen met labels %{list}</strong> toegekend zijn",
          "single": ", waaraan <strong>processen met label %{list}</strong> toegekend zijn"
        },
        "programs": {
          "multiple": ", waaraan de <strong>processen %{list}</strong> toegekend zijn",
          "single": ", waaraan het <strong>proces %{list}</strong> toegekend is"
        },
        "public": "(zichtbaar voor iedereen)",
        "roles": {
          "account_managers": "accountmanagers",
          "accountmanagers": "Account managers",
          "advisors": "adviseurs",
          "all_internal_users": "coaches",
          "all_users": "gebruikers",
          "coachs": "procescoaches",
          "contact_persons": "contactpersonen",
          "ixly_admins": "Ixly-beheerders",
          "ixly_employees": "Ixly-medewerkers",
          "organization_managers": "organisatiemanagers",
          "selected": "Alle <strong>%{list}</strong>",
          "selected_advisor": "met <strong>coach %{author_name}</strong>",
          "selected_global": "van <strong>alle organisaties</strong>",
          "users": "kandidaat"
        },
        "user_labels": {
          "multiple": "met <strong>labels %{list}</strong>",
          "single": "met <strong>label %{list}</strong>"
        },
        "users": {
          "multiple": "Gebruikers <strong>%{names}</strong>",
          "single": "Gebruiker <strong>%{names}</strong>"
        },
        "users_type": {
          "certain": "Deel met specifieke gebruikers",
          "filters": "Deel met rollen, labels en processen",
          "public": "Openbaar"
        }
      },
      "private": "Privé",
      "published_by": "Gepubliceerd door",
      "shared": "Gedeeld",
      "shared_with": "Dit artikel is gedeeld met:",
      "show_more": "Toon meer",
      "sidebar": {
        "advisor": "Voor je eigen kandidaten",
        "global": "Voor alle organisaties",
        "organization": "Voor %{organization_name}",
        "shared": "Gedeeld met jou"
      },
      "status": "Status",
      "title": "Titel",
      "update": {
        "updated": "De deelregels zijn bijgewerkt"
      }
    },
    "prices": {
      "active": "Actief",
      "amc": "AMC prijs",
      "bov": "Prijs BOV",
      "cannot_modify_expired": "U kunt actieve en verlopen prijzenlijsten niet bewerken",
      "created_by": "Aangemaakt door",
      "does_not_exist": "Prijzenlijst niet gevonden",
      "edit": "Bewerk prijzenlijst",
      "effective_from": "In effect vanaf",
      "expired": "Verlopen",
      "external_user": "Maandelijkse kosten per kandidaat",
      "fit": "Prijs FIT",
      "internal_user": "Maandelijkse kosten per coach",
      "job_market_scan": "Prijs Arbeidsmarktkansen",
      "job_market_scan_en": "Prijs Arbeidsmarktkansen Engelstalig",
      "job_suggestions": "Prijs Baansuggesties",
      "jobfeed": "Prijs Jobfeed",
      "new": "Nieuwe prijzenlijst",
      "pending": "In afwachting",
      "price_created": "De prijzenlijst is aangemaakt",
      "price_updated": "De prijzenlijst is bijgewerkt",
      "something_went_wrong": "Er ging iets mis",
      "status": "Status",
      "updated_at": "Bijgewerkt op"
    },
    "product_program_templates": {
      "create": {
        "created": "Procestemplates zijn toegevoegd aan het product",
        "product_program_templates_blank": "Er waren geen procestemplates geselecteerd"
      },
      "destroy": {
        "product_program_template_destroyed": "Procestemplate is verwijderd uit het product"
      },
      "new": {
        "title": "Procestemplates toevoegen aan %{product}"
      },
      "row": {
        "product_program_template_delete": "Weet u zeker dat u deze procestemplates uit het product wilt verwijderen?"
      }
    },
    "product_status": {
      "active": "Actief",
      "deactivated": "Gedeactiveerd"
    },
    "product_tasks": {
      "create": {
        "created": "De assessments zijn toegevoegd aan het product",
        "product_tasks_blank": "Er waren geen assessments geselecteerd"
      },
      "deactivate": {
        "deactivated": "De verbinding met het assessment is gedeactiveerd"
      },
      "destroy": {
        "task_destroyed": "Het assessment is verwijderd uit het product"
      },
      "new": {
        "title": "Assessments toevoegen aan %{product}"
      },
      "reactivate": {
        "reactivated": "De verbinding met het assessment is geheractiveerd"
      },
      "row": {
        "task_delete": "Weet u zeker dat u dit assessment uit het product wilt verwijderen?"
      }
    },
    "products": {
      "create": {
        "product_created": "Het product is aangemaakt",
        "title": "Nieuw product"
      },
      "deactivate": {
        "deactivated": "Het product is gedeactiveerd"
      },
      "destroy": {
        "deleted": "Het product is verwijderd"
      },
      "edit": {
        "title": "Bewerk product"
      },
      "index": {
        "new_button": "Nieuw product",
        "table_header": [
          "Naam",
          "Status",
          "Status veranderd op",
          "Acties"
        ],
        "title": "Producten"
      },
      "new": {
        "title": "Nieuw product"
      },
      "reactivate": {
        "reactivated": "Het product is geheractiveerd"
      },
      "row": {
        "product_delete": "Weet u zeker dat u dit product wilt verwijderen?"
      },
      "show": {
        "new_program_template_button": "Processen toevoegen",
        "new_task_button": "Assessments toevoegen",
        "organizations": "Organisaties",
        "product_program_templates": "Procestemplates",
        "product_program_templates_table_header": [
          "Naam",
          "Taal",
          "Status",
          "Acties"
        ],
        "product_tasks": "Assessments",
        "product_tasks_table_header": [
          "Naam",
          "Key",
          "Taal",
          "Credits",
          "Status",
          "Verbinding",
          "Acties"
        ],
        "status": "Status",
        "subscription_types": "Abonnementsvormen"
      },
      "update": {
        "product_updated": "Het product is bijgewerkt",
        "title": "Bewerk product"
      }
    },
    "profile": {
      "audits": {
        "show": {
          "action": "Actie",
          "browser": "Browser",
          "caption": "Hieronder vindt je alle authenticatie acties voor je account.",
          "created_at": "Ingelogd op",
          "ip": "IP",
          "title": "Audit geschiedenis"
        }
      },
      "base": {
        "tabs": {
          "audit_history": "Audit geschiedenis",
          "personal_information": "Persoonlijke informatie",
          "settings": "Instellingen"
        }
      },
      "candidates": {
        "form": {
          "create_account": "Account aanmaken"
        },
        "update": {
          "updated": "Jouw persoonlijke informatie is bijgewerkt"
        }
      },
      "images": {
        "destroy": {
          "deleted": "Profiel foto verwijderd",
          "destroy_confirm": "Verwijderen van profiel foto"
        },
        "form": {
          "allowed_formats": "Toegestane bestandsformaten: JPG, PNG",
          "upload": "Upload Foto"
        },
        "update": {
          "updated": "Profiel foto aangepast"
        }
      },
      "modal": {
        "profile_image": {
          "change": "Aanpassen",
          "save": "Opslaan",
          "title": "Profiel foto aanpassen",
          "upload": "Upload"
        }
      },
      "notification_settings": {
        "email_notifications": {
          "help_text": "Kies de gewenste notificatie instellingen, houd er rekening mee dat alleen ongeziene notificaties per mail verstuurd worden.",
          "title": "Notificatie E-mailfrequentie"
        },
        "update": {
          "updated": "Jouw persoonlijke instellingen zijn bijgewerkt"
        }
      },
      "notifications": {
        "all": "Toon alles",
        "bulk_update": {
          "marked_as_read": "De meldingen zijn gemarkeerd als gelezen"
        },
        "document_approvals": "Document accorderingen",
        "documents": "Documenten",
        "last_30_days": "Laatste 30 dagen",
        "messages": "Berichten",
        "news": "Nieuws",
        "system": "Systeem",
        "title": "Notificaties",
        "tracks": "Trajecten"
      },
      "passwords": {
        "update": {
          "sent": "Je ontvangt een email met instructies om je wachtwoord te veranderen"
        }
      },
      "profiles": {
        "show": {
          "password": {
            "instructions": "Als je het wachtwoord van je account wilt aanpassen, ontvang je van ons een email met instructies.",
            "request": "vraag aan",
            "title": "Wachtwoord veranderen"
          },
          "profile_image": "Profiel foto",
          "title": "Jouw info"
        }
      },
      "settings": {
        "activate_2fa": {
          "activate": "Activeren",
          "inactive": "2FA is nog niet geactiveerd"
        },
        "deactivate_2fa": {
          "active": "2FA is geactiveerd",
          "deactivate": "Deactiveren",
          "disabled": "Het gebruik van verificatie in twee stappen is verplicht door de organisatie"
        },
        "two_factor_authentication": {
          "title": "2-Factor Authenticatie"
        }
      },
      "title": "Mijn profiel",
      "two_factor_authentications": {
        "destroy": {
          "destroyed": "2FA is gedeactiveerd",
          "tfa_required": "2FA kan niet gedeactiveerd worden"
        }
      },
      "users": {
        "update": {
          "updated": "Jouw persoonlijke informatie is bijgewerkt"
        }
      }
    },
    "profile_picture_disclaimer": {
      "accept": "Accepteren",
      "cancel": "Annuleren",
      "title": "Profielfoto disclaimer"
    },
    "program_pages": {
      "edit": {
        "drop": "Sleep hier elementen in"
      },
      "new": {
        "default_page_title": "Nieuwe stap"
      },
      "show": {
        "not_editable": "Dit proces is gepubliceerd en daarom toekenbaar, maar pas na terugtrekken weer bewerkbaar",
        "not_editable_advisor": "Als procescoach kunt u dit proces niet bewerken, maar wel bekijken (inclusief alle instellingen)",
        "not_editable_deleted": "Dit proces is verwijderd en daardoor niet langer bewerkbaar of toekenbaar",
        "not_editable_template": "Deze template kunt u bekijken of kopiëren naar uw organisaties eigen processen"
      }
    },
    "program_templates": {
      "bulk": {
        "publish": "Meerdere processen zijn gepubliceerd",
        "withdraw": "Meerdere processen zijn teruggetrokken"
      },
      "destroy": {
        "destroyed": "Het proces '%{program_title}' is verwijderd"
      },
      "duplicate": {
        "duplicate_prefix": "Kopie:",
        "program_duplicated": "Het proces '%{program_title}' is gekopiëerd",
        "template_duplicated": "Het proces '%{program_title}' is gekopiëerd naar uw organisaties eigen processen"
      },
      "form": {
        "invite_mail": "Uitnodigingsmail",
        "invite_mail_documentation": "(klik hier voor de uitnodigingsmail documentatie)",
        "labels": "Labels",
        "options": "Opties",
        "title_placeholder": "Kies een titel",
        "your_labels": "Uw labels"
      },
      "index": {
        "help_text": "Ontwerp, bewerk of bekijk de processen van uw organisatie. Processen zijn beschikbaar voor adviseurs met de coach rol en premium kandidaten.",
        "new_button": "Nieuw proces",
        "own_programs": "Eigen processen",
        "table_header": [
          "Status",
          "Gegevens",
          "Lengte",
          "Acties"
        ],
        "templates": "Templates",
        "title": "Processen ontwikkelen"
      },
      "new": {
        "default_program_title": "Proces"
      },
      "publish": {
        "all_pages_invisible": "U kunt dit proces niet publiceren omdat geen enkele stap is ingesteld als zichtbaar voor de kandidaat",
        "published": "Het proces '%{program_title}' is gepubliceerd"
      },
      "publish_as_template": {
        "published_as_template": "Het proces '%{program_title}' is gepubliceerd als template"
      },
      "update": {
        "update_failed": "Het proces kon niet bijgewerkt worden",
        "updated": "Het proces is bijgewerkt"
      },
      "withdraw": {
        "withdrawn": "Het proces '%{program_title}' is teruggetrokken"
      }
    },
    "programs": {
      "bulk": {
        "publish": "Meerdere processen zijn gepubliceerd",
        "withdraw": "Meerdere processen zijn teruggetrokken"
      },
      "destroy": {
        "destroyed": "Het proces '%{program_title}' is verwijderd"
      },
      "duplicate": {
        "duplicate_prefix": "Kopie:",
        "program_duplicated": "Het proces '%{program_title}' is gekopiëerd",
        "template_duplicated": "Het proces '%{program_title}' is gekopiëerd naar uw organisaties eigen processen"
      },
      "form": {
        "labels": "Labels",
        "options": "Opties",
        "title_placeholder": "Kies een titel",
        "your_labels": "Uw labels"
      },
      "index": {
        "help_text": "Ontwerp, bewerk of bekijk de processen van uw organisatie. Processen zijn beschikbaar voor adviseurs met de coach rol en premium kandidaten.",
        "new_button": "Nieuw proces",
        "own_programs": "Eigen processen",
        "table_header": [
          "Status",
          "Gegevens",
          "Lengte",
          "Acties"
        ],
        "templates": "Templates",
        "title": "Processen ontwikkelen"
      },
      "new": {
        "default_program_title": "Proces"
      },
      "publish": {
        "all_pages_invisible": "U kunt dit proces niet publiceren omdat geen enkele stap is ingesteld als zichtbaar voor de kandidaat",
        "published": "Het proces '%{program_title}' is gepubliceerd"
      },
      "publish_as_template": {
        "published_as_template": "Het proces '%{program_title}' is gepubliceerd als template"
      },
      "update": {
        "update_failed": "Het proces kon niet bijgewerkt worden",
        "updated": "Het proces is bijgewerkt"
      },
      "withdraw": {
        "withdrawn": "Het proces '%{program_title}' is teruggetrokken"
      }
    },
    "prompt": {
      "cost_center_delete_confirm": "Weet u zeker dat u deze kostenplaats wilt verwijderen?",
      "file_remove_confirm": "Weet u zeker dat u dit bestand wilt verwijderen?",
      "program_delete_confirm": "Weet u zeker dat u dit proces met al zijn stappen en elementen wilt verwijderen?",
      "room_delete_confirm": "Weet u zeker dat u dit videogesprek wilt verwijderen?",
      "subscription_delete_confirm": "Weet u zeker dat u dit abonnement wilt verwijderen?",
      "subscription_upgrade_confirm": "Weet u zeker dat u uw abonnement wilt upgraden? U kunt niet downgraden binnen een jaar nadat het nieuwe abonnement van start gaat."
    },
    "resource": {
      "candidate_email_messages": "Verstuurde e-mails",
      "close": "Sluiten",
      "edit": "Wijzigen",
      "filter": "Filter",
      "impersonate": "Log in als",
      "interactive_results": "Resultaten",
      "new_enrollment": "Traject aanmaken",
      "reinvite": "Opnieuw uitnodigen"
    },
    "role": {
      "AccountManager": "Accountmanager",
      "Coach": "Procescoach",
      "IxlyAdmin": "Ixly-beheerder",
      "OrganizationManager": "Organisatiemanager",
      "SelectionManager": "Selectie manager"
    },
    "rooms": {
      "show": {
        "cannot_join": "Dit videogesprek is momenteel gesloten"
      }
    },
    "score": {
      "no_score": "Zonder score",
      "with_score": "Met score"
    },
    "selection": {
      "compare_candidates": {
        "new": {
          "current_position": "Huidige functie:",
          "no_scores": "Je kunt alleen kandidaten met resultaten vergelijken",
          "position_unknown": "Onbekend",
          "title": "Kandidaten vergelijken",
          "too_many_candidates": "U kunt maximaal 5 kandidaten tegelijk vergelijken."
        }
      },
      "job_profile_templates": {
        "duplicate": "Het functieprofiel '%{job_profile_title}' is gekopieerd naar jouw organisatie",
        "index": {
          "list": "Lijst met ontworpen standaard functieprofielen",
          "title": "Functieprofiel templates"
        },
        "new": {
          "tooltip": "Template toevoegen"
        },
        "show": {
          "competencies": "Competenties",
          "education_level": "Opleidingsniveau",
          "job_profile_title": "Titel",
          "title": "%{job_profile_title}"
        }
      },
      "job_profiles": {
        "create": {
          "success": "Het aanmaken van het functieprofiel is geslaagd",
          "title": "Nieuw Functieprofiel"
        },
        "destroy": {
          "failure": "kan niet worden verwijderd omdat het gekoppeld is aan een of meer Matchingsopdrachten",
          "success": "Het geselecteerde functieprofiel is verwijderd"
        },
        "edit": {
          "title": "Functieprofiel bewerken"
        },
        "form": {
          "job_profile_explanation": "Met Compare & Select zetten wij geavanceerde assessments in, die competenties en intelligentie van kandidaten objectief meten. Onze gestandaardiseerde evaluaties leveren concrete scores op, die de basis vormen voor onze datagedreven matching. Met deze wetenschappelijke aanpak overstijgen we subjectiviteit en introduceren zorgvuldigheid"
        },
        "index": {
          "compare_select_icon_alt": "Compare & Select icoon",
          "list": "Navigeer naar het <strong>kandidatenoverzicht, selecteer de kandidaten,</strong> en druk op de knop <strong>'Compare & Select'</strong> %{icon} om een matching opdracht aan te maken.",
          "title": "Functieprofielen"
        },
        "list": {
          "created_at": "Aangemaakt op",
          "created_by": "Aangemaakt door",
          "title": "Titel",
          "updated_at": "Laatst bewerkt op"
        },
        "new": {
          "title": "Nieuw functieprofiel",
          "tooltip": "Functieprofiel toevoegen"
        },
        "update": {
          "success": "Het bewerken van het functieprofiel is geslaagd",
          "title": "Functieprofiel bewerken"
        }
      },
      "matching_assignments": {
        "create": {
          "candidates": "Kandidaten",
          "success": "Het aanmaken van de matching opdracht is geslaagd",
          "title": "Matching opdracht samenstellen"
        },
        "edit": {
          "title": "Matching opdracht bewerken"
        },
        "index": {
          "candidate": "kandidaten",
          "caption": "Hier vind je een overzicht van je matchingopdrachten",
          "incomplete": "Matching nog niet compleet",
          "list": {
            "candidates": "Kandidaten",
            "created_at": "Aangemaakt op",
            "job_title": "Functietitel",
            "label": "Label",
            "status": "Status",
            "title": "Kenmerk",
            "updated_at": "Laatst bewerkt op"
          },
          "title": "Matching opdrachten"
        },
        "list": {
          "assignment_note": "<span class='font-bold'>Let op:</span> Niet alle kandidaten hebben een ACT en een WPV toegewezen gekregen. <a href='%{assignment_link}' class='font-bold underline hover:text-blue-light' data-turbo-frame='_top'>Klik hier</a> om direct een intelligentietest (ACT) en een competentietest (WPV) toe te wijzen.<br> Wij zorgen ervoor dat dit proces goed verloopt en voorkomen dubbele toewijzingen.",
          "name": "Naam"
        },
        "new": {
          "title": "Matching opdracht samenstellen"
        },
        "show": {
          "education_level": "Gemeten opleidingsniveau",
          "title": "Matching resultaten"
        },
        "tooltip": {
          "start_comparison": "Vergelijk kandidaten"
        },
        "update": {
          "success": "Het bewerken van de matching opdracht is geslaagd",
          "title": "Matching opdracht bewerken"
        }
      },
      "matching_results": {
        "index": {
          "education_level": "Gemeten opleidingsniveau",
          "list": {
            "competencies": "Competenties",
            "education_level": "Gemeten opleidingsniveau",
            "favorite": "Favorieten",
            "matching_score": "Matching score",
            "name": "Naam"
          },
          "title": "Matching opdracht"
        },
        "list": {
          "assignment_note": "<span class='font-bold'>Opmerking:</span> niet alle kandidaten zijn toegewezen aan assessments. <a href='%{assignment_link}' class='font-bold underline hover:text-blue-light' data-turbo-frame='_top'>Klik hier</a> om beoordelingen direct toe te wijzen.",
          "name": "Naam"
        }
      },
      "title": "Compare & Select"
    },
    "sessions": {
      "create": {
        "combination_does_not_exist": "Uw e-mailadres of wachtwoord is fout"
      },
      "destroy": {
        "logout": "U bent succesvol uitgelogd"
      },
      "new": {
        "forgot_your_password": "Wachtwoord vergeten?",
        "help_text": "Welkom op de inlogpagina van de Assessment Platform",
        "new_login": "Log in",
        "title": "Inloggen"
      },
      "verify_otp": {
        "invalid": "De opgegeven code was onjuist"
      }
    },
    "shared": {
      "accounts": {
        "suppressed_email": "Het e-mailadres dat aan dit account is gekoppeld, <span class=\"font-medium\">kan niet worden bereikt</span>.",
        "suppressed_email_tooltip": "Het e-mailadres dat aan dit account is gekoppeld, is niet bereikbaar.",
        "unconfirmed_email": {
          "unconfirmed_email": "Het e-mailadres wordt gewijzigd naar:",
          "unconfirmed_email_explanation": "De eigenaar van het e-mailadres moet via een link de wijziging bevestigen. Pas na bevestigen wordt het gewijzigde e-mailadres gebruikt."
        }
      },
      "candidate_result_widgets": {
        "personal_information": {
          "date_of_birth": "Geboortedatum",
          "education": "Opleidingsniveau",
          "email": "E-mailadres",
          "most_recent_work_experience": "Meest recente werkervaring",
          "study_subject": "Opleiding",
          "title": "Persoonsgegevens",
          "years": {
            "one": "%{years} jaar",
            "other": "%{years} jaren"
          },
          "years_of_experience": "Jaren ervaring"
        }
      },
      "enrollment_sidebar": {
        "advisor": "Coach",
        "candidate": "Kandidaat",
        "coadvisor": "Cocoach",
        "comments": "Berichten",
        "contact_person": "Contactpersoon",
        "room": "Videogesprek",
        "track": "Traject"
      },
      "forms": {
        "password": {
          "hide_password": "verberg wachtwoord",
          "show_password": "Toon wachtwoord"
        }
      },
      "header": {
        "admin": "Beheerder",
        "advisors": "Adviseurs",
        "all_organizations": "Alle organisaties",
        "all_users": "Alle gebruikers",
        "approval_requests": "Accorderingen",
        "blog": "Blog",
        "candidates": "Kandidaten",
        "clients": "Opdrachtgevers",
        "enrollers": "Inschrijflinks",
        "login": "Inloggen",
        "organization": "Organisatie",
        "prices": "Prijzen",
        "products": "Producten",
        "programs": "Processen ontwikkelen",
        "rooms": "Videogesprekken",
        "settings": "Instellingen",
        "subscription_types": "Abonnementsvormen",
        "tasks": "Taken",
        "tracks": "Trajecten",
        "translations": "Vertalingen",
        "users": "Gebruikers"
      },
      "header_profile": {
        "log_in_as": "Log in als:",
        "log_in_at": "Log in bij:",
        "logout": "Uitloggen",
        "my_tracks": "Mijn trajecten",
        "profile": "Profiel"
      },
      "notifications_sidebar": {
        "help_text": "Filter uw takenlijst met de onderstaande opties:",
        "title": "Filters"
      },
      "organization_sidebar": {
        "clients_help_text": "Filter uw opdrachtgevers met de onderstaande opties:",
        "labels": "Labels",
        "programs_help_text": "Filter uw processen met de onderstaande opties:",
        "title": "Filters",
        "users_help_text": "Filter uw gebruikers met de onderstaande opties:",
        "your_own_organization": "Uw organisatie"
      },
      "rebound_email_banner": {
        "action_label": "Pas emailadres aan",
        "description": "Een email verstuurd aan [[email]] kon niet afgeleverd worden. Dit is waarom: [[reason]]",
        "fallback_description": "Een recent verstuurde email naar [[email]] kon niet worden afgeleverd.",
        "title": "Werk je emailadres bij"
      },
      "suppressed_email": "Het e-mailadres dat aan dit account is gekoppeld <span class=\"font-medium\">kan niet worden bereikt</span>.",
      "suppressed_email_tooltip": "Het e-mailadres dat aan dit account is gekoppeld kan niet worden bereikt.",
      "tracks_sidebar": {
        "filters": {
          "client": "Van opdrachtgever",
          "label": "Labels",
          "process": "Processen",
          "role": "Uw rol in het traject",
          "status": "Status"
        },
        "help_text": "Filter uw kandidaten met de onderstaande opties:",
        "title": "Filters"
      },
      "user_sidebar": {
        "my_track": "Mijn traject",
        "new_track": "Maak traject aan",
        "show_deactivated": "Toon gedeactiveerd",
        "track_of": "Traject van %{name}",
        "track_with": "Traject met %{name}"
      }
    },
    "sidebar": {
      "close": "Sluit zijbalk"
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Bekijk alstublieft de problemen hieronder:"
      },
      "include_blank": {
        "default_mail_template": {
          "no_default": "Geen"
        },
        "select_option": "Maak uw keuze"
      },
      "labels": {
        "advisor": {
          "avatar": "Profielfoto",
          "biography": "Persoonlijke beschrijving",
          "can_impersonate": "Mag inloggen als een andere gebruiker",
          "consent_given": "Toestemming",
          "cost_center_id": "Kostenplaats",
          "current_password": "Huidige wachtwoord",
          "first_name": "Voornaam",
          "invite_immediately": "Direct een welkomstmail toesturen",
          "label": "Labels",
          "label_ids": "Labels",
          "language": "Taal",
          "last_name": "Achternaam",
          "mailing_frequency": "E-mailfrequentie",
          "new_password": "Nieuwe wachtwoord",
          "organization": "Organisatie",
          "organization_language": "Taal organisatie",
          "organization_name": "Naam organisatie",
          "otp_code": "Code",
          "privacy_accepted": "Voorwaarden / Privacy",
          "remove_avatar": "Verwijder deze foto",
          "role": "Rollen",
          "roles": "Rollen",
          "status": "Status",
          "type": "Gebruikerstype",
          "unconfirmed_email": "E-mail"
        },
        "assignment": {
          "deadline": "Assessment deadline",
          "invited_at": "Uitnodigingsdatum",
          "mail_template_id": "Uitnodigingsmail",
          "reminder_mail_delivery_date": "Herinneringsmaildatum",
          "reminder_mail_template_id": "Herinneringsmail"
        },
        "authentication_action": {
          "auth_action": "Type authenticatie"
        },
        "candidate": {
          "account_email": "E-mailadres",
          "advisor": "Adviseur",
          "advisor_id": "Adviseur",
          "client": "Opdrachtgevers",
          "client_ids": "Opdrachtgevers",
          "coadvisor": "Coadviseur",
          "coadvisor_ids": "Coadviseurs",
          "coadvisors": "Coadviseur",
          "contact_person": "Contactpersonen",
          "contact_person_ids": "Contactpersonen",
          "contact_persons": "Contactpersonen",
          "cost_center_id": "Kostenplaats",
          "created_at": "Datum aanmelding",
          "deadline": "Assessment deadline",
          "end_date": "Einddatum",
          "first_name": "Voornaam",
          "invitation_date": "Uitnodigingsdatum",
          "invite_immediately": "Direct uitnodigen",
          "label": "Labels",
          "label_ids": "Labels",
          "language": "Taal",
          "last_name": "Achternaam",
          "mail_template_id": "Uitnodigingsmail",
          "name": "Naam",
          "organization": "Organisatie",
          "program": "Processen",
          "reminder_mail_delivery_date": "Herinneringsmaildatum",
          "reminder_mail_template_id": "Herinneringsmail",
          "report_objective": "Rapportagedoelstelling",
          "start_date": "Begindatum",
          "status": "Status"
        },
        "candidate_assessment": {
          "document_options": "Rapport opties",
          "end_date": "Afgerond tot en met",
          "norm_group_uuid": "Rapportagedoelstelling",
          "report_to": "Resultaten eerst beschikbaar voor",
          "selected_report_id": "Rapport",
          "start_date": "Afgerond vanaf"
        },
        "candidate_assessment_program": {
          "document_options": "Rapport opties",
          "norm_group_uuid": "Rapportagedoelstelling",
          "report_to": "Resultaten eerst beschikbaar voor",
          "selected_report_id": "Rapport"
        },
        "candidate_matching_assignment": {
          "education_level": "Opleidingsniveau",
          "no_score": "Geen score",
          "score": "Score"
        },
        "candidate_report": {
          "candidate_assessment_program_id": "Assessment programma",
          "document_options": "Rapport opties",
          "norm_group_id": "Rapportagedoelstelling",
          "report_id": "Rapport",
          "reportable": "Assessment"
        },
        "client": {
          "label_ids": "Labels",
          "name": "Naam"
        },
        "component": {
          "alt_text": "Omschrijving van de afbeelding voor schermlezers",
          "bov_editable_by": "BOV startbaar door",
          "can_approve": "Te accorderen door",
          "cc_load_policy": "Bij het afspelen direct ondertiteling (in eigen taal) tonen",
          "controls": "Tijdens het afspelen de controlebalk onderaan tonen",
          "description_finished": "Extra beschrijving na afronding",
          "editable_by": "Beantwoordbaar door",
          "html": "HTML code",
          "iframe_title": "Titel van de video voor schermlezers",
          "label": "Geef veld een label",
          "max_selections": "Maximum aantal selecties",
          "options": "Keuzes",
          "question": "Vraag",
          "rel": "Na het afspelen ook gerelateerde video's van andere kanalen tonen",
          "report_visible_for": "Resultaat zichtbaar voor",
          "required": "Moet met 'Ja' beantwoord worden om verder te gaan",
          "sections": "Secties",
          "task_key": "Geselecteerd assessment",
          "template_can_be_switched_by": "Template verwisselbaar door",
          "url": "YouTube video URL",
          "visible_for": "Document zichtbaar voor",
          "visible_to_candidate": "Maak het element ook zichtbaar voor de kandidaat",
          "visible_to_contact_person": "Deel het document na goedkeuring automatisch met de contactpersonen"
        },
        "contact_person": {
          "avatar": "Profielfoto",
          "biography": "Persoonlijke beschrijving",
          "can_impersonate": "Mag inloggen als een andere gebruiker",
          "client": "Opdrachtgever",
          "consent_given": "Toestemming",
          "cost_center_id": "Kostenplaats",
          "current_password": "Huidige wachtwoord",
          "first_name": "Voornaam",
          "id": "Contact Persoon",
          "invite_immediately": "Direct een welkomstmail toesturen",
          "label": "Labels",
          "label_ids": "Labels",
          "language": "Taal",
          "last_name": "Achternaam",
          "mailing_frequency": "E-mailfrequentie",
          "new_password": "Nieuwe wachtwoord",
          "organization": "Organisatie",
          "organization_language": "Taal organisatie",
          "organization_name": "Naam organisatie",
          "otp_code": "Code",
          "privacy_accepted": "Voorwaarden / Privacy",
          "remove_avatar": "Verwijder deze foto",
          "role": "Rollen",
          "status": "Status",
          "type": "Gebruikerstype",
          "unconfirmed_email": "E-mail"
        },
        "cost_center": {
          "identifier": "Naam"
        },
        "defaults": {
          "account_email": "E-mailadres",
          "advisor_id": "Adviseur",
          "candidate_id": "Kandidaat",
          "client_id": "Opdrachtgever",
          "coadvisor_id": "Coadviseur",
          "contact_person_id": "Contactpersoon",
          "description": "Beschrijving",
          "email": "E-mailadres",
          "end_date": "Einddatum",
          "explanation": "Toelichting",
          "first_name": "Voornaam",
          "language": "Applicatietaal",
          "last_name": "Achternaam",
          "name": "Naam",
          "organization_id": "Organisatie",
          "password": "Wachtwoord",
          "product_id": "Producten",
          "program_id": "Proces",
          "start_date": "Startdatum",
          "title": "Titel",
          "two_factor": "Verificatie in twee stappen"
        },
        "discount": {
          "end_date": "Einddatum",
          "kind": "Op",
          "organization": "Organisatie",
          "organization_id": "Organisatie",
          "percentage": "Percentage",
          "start_date": "Startdatum"
        },
        "enroller": {
          "advisor": "Coach",
          "advisor_id": "Coach",
          "allow_repeated": "Sta herhaalde inschrijvingen toe",
          "link": "Link",
          "program": "Proces",
          "program_id": "Proces",
          "show_default": "Toon standaardtekst"
        },
        "enrollment": {
          "coadvisor_id": "Cocoach",
          "invite_immediately": "Direct uitnodigen",
          "program_id": "Proces"
        },
        "entry": {
          "identifier": "Identifier",
          "type": "Type"
        },
        "fixed_price": {
          "description": "Omschrijving op de factuur",
          "organization": "Organisatie",
          "price": "Prijs",
          "task": "Taak"
        },
        "import/file": {
          "created_at": "toegevoegd",
          "imported_rows": "geïmporteerd rijen",
          "name": "Naam",
          "organization": "organisatie",
          "organization_id": "Organisatie",
          "state": "status import",
          "total_rows": "totale rijen",
          "type": "Type"
        },
        "import_file": {
          "created_at": "toegevoegd",
          "download_example": "Download voorbeeldbestand",
          "imported_rows": "geïmporteerd rijen",
          "name": "Naam",
          "organization": "organisatie",
          "organization_id": "Organisatie",
          "state": "status import",
          "total_rows": "totale rijen",
          "type": "Type"
        },
        "ixly_employee": {
          "avatar": "Profielfoto",
          "biography": "Persoonlijke beschrijving",
          "can_impersonate": "Mag inloggen als een andere gebruiker",
          "consent_given": "Toestemming",
          "cost_center_id": "Kostenplaats",
          "current_password": "Huidige wachtwoord",
          "first_name": "Voornaam",
          "invite_immediately": "Direct een welkomstmail toesturen",
          "label": "Labels",
          "language": "Taal",
          "last_name": "Achternaam",
          "mailing_frequency": "E-mailfrequentie",
          "new_password": "Nieuwe wachtwoord",
          "organization": "Organisatie",
          "organization_language": "Taal organisatie",
          "organization_name": "Naam organisatie",
          "otp_code": "Code",
          "privacy_accepted": "Voorwaarden / Privacy",
          "remove_avatar": "Verwijder deze foto",
          "role": "Rollen",
          "roles": "Rollen",
          "status": "Status",
          "type": "Gebruikerstype",
          "unconfirmed_email": "E-mail"
        },
        "job_profile": {
          "competencies": "Competenties",
          "education_level": "Opleidingsniveau",
          "job_profile": "Functieprofiel",
          "label": "Labels",
          "label_ids": "Labels",
          "title": "Functie titel",
          "user": "Gebruiker"
        },
        "join": {
          "display_name": "Weergavenaam",
          "start_hidden": "Start verborgen",
          "start_muted": "Start gedempt"
        },
        "leniency": {
          "credits": "Credits",
          "reason": "Reden"
        },
        "mail_template": {
          "body": "Tekst",
          "language": "Taal",
          "subject": "Onderwerp",
          "title": "Titel"
        },
        "matching_assignment": {
          "education_level": "Opleidingsniveau",
          "job_profile": "Functieprofiel",
          "job_profile_competencies": "Competenties",
          "job_profile_education_level": "Opleidingsniveau",
          "job_profile_title": "Functieprofiel",
          "label": "Labels",
          "label_ids": "Labels",
          "matching_score": "Matching score",
          "no_score": "Geen score",
          "title_description": "Kenmerk",
          "user": "Gebruiker",
          "vacancy_number_placeholder": "Voeg een kenmerk toe aan dit functieprofiel zoals een vacaturenummer, afdelingsnaam of vestiging."
        },
        "media_item": {
          "advisor": "Adviseur",
          "candidate": "Kandidaat",
          "contact_person": "Contactpersoon"
        },
        "organization": {
          "account_manager_id": "Accountmanager",
          "address": "Adres",
          "advisor_can_create_candidates": "Adviseurs kunnen kandidaten aanmaken",
          "advisor_can_manage_existing_candidates": "Adviseurs kunnen alle kandidaten inzien en bijwerken",
          "advisor_can_manage_users": "Adviseurs kunnen procescoaches en contactpersonen aanmaken",
          "advisor_can_remove_candidates": "Adviseurs kunnen kandidaten verwijderen",
          "advisor_can_view_enrollers": "Procescoaches kunnen inschrijflinks bekijken",
          "advisor_can_view_prices": "Adviseurs kunnen prijzen zien",
          "advisor_deactivation_period": "Deactivatieperiode voor adviseurs",
          "advisor_rooms_permission": "Procescoaches kunnen videogesprekken bekijken",
          "allow_compare_select": "Compare & Select toestaan",
          "allow_impersonation": "Sta gebruikers toe om in te loggen als",
          "allow_ixly_support": "Ixly ondersteuning toestaan",
          "api_anonymous": "API anoniem",
          "api_key": "API key",
          "api_organization_id": "Test Toolkit organisatie uuid",
          "api_user": "API gebruikersmail",
          "available_languages": "Beschikbare talen",
          "brand_logo": "Logo",
          "candidate_deactivation_period": "Deactivatieperiode voor kandidaten",
          "city": "Stad",
          "clients_enabled": "Opdrachtgevers en contactpersonen zijn ingeschakeld voor de organisatie",
          "country": "Land",
          "credit_bundle": "Bundel",
          "default_language": "Standaard taal",
          "default_report_objective": "Standaard voor 'Rapportagedoelstelling'",
          "default_report_to": "Resultaten eerst beschikbaar voor",
          "has_dashboard_link": "Adviseurs hebben een link naar de Dashboard app",
          "has_suborganizations": "Organisatie mag gebruik maken van suborganizaties",
          "interactive_button_color": "Kleur iconen",
          "interactive_header_color": "Secundaire kleur",
          "interactive_header_text_color": "Pagina titel tekst kleur",
          "interactive_primary_color": "Primaire kleur",
          "invoice_company_name": "Factuur bedrijfsnaam",
          "invoice_company_name_placeholder": "Laat leeg als zelfde als organisatie naam",
          "invoice_email": "E-mailadres voor facturen",
          "invoice_remarks": "Factuur opmerkingen",
          "invoice_remarks_placeholder": "Wordt getoond onder de factuur",
          "name": "Naam organisatie",
          "organization_manager_can_view_enrollments": "Organisatiemanagers kunnen processen inzien van alle kandidaten",
          "payment_type": "Betalingswijze",
          "postal_code": "Postcode",
          "privacy_link": "Privacy",
          "profile_picture_disclaimer": "Tekst disclaimer",
          "remove_logo": "Logo verwijderen",
          "remove_report_cover_logo": "Verwijder het rapportomslaglogo",
          "remove_report_header_logo": "Verwijder het logo van de rapportkoptekst",
          "report_cover_logo": "Omslaglogo voor rapport",
          "report_header_logo": "Omslaglogo voor rapport",
          "retention_period": "Bewaartermijn persoonsgegevens",
          "show_interactive_results": "Toon Resultaten",
          "show_posts": "Toon Blog",
          "status": "Status",
          "subscription_type": "Abonnement",
          "terms_link": "Voorwaarden",
          "two_factor": "Verplicht verificatie in twee stappen voor deze rollen",
          "vat_number": "Btw-identificatienummer",
          "welcome_mail_subject": "Onderwerp welkomstmail",
          "welcome_mail_text": "Tekst welkomstmail",
          "whitelabeled": "White labeled"
        },
        "organization_default_mail_template": {
          "mail_template_id": "Standaard mail template"
        },
        "post_rule": {
          "label_ids": "Labels",
          "program_ids": "Processen",
          "roles": "Gebruikersrollen"
        },
        "premium_candidate": {
          "coadvisor_ids": "Coadviseurs",
          "contact_person_ids": "Contactpersonen",
          "label_ids": "Labels"
        },
        "price": {
          "amc": "AMC prijs, €",
          "bov": "BOV prijs, €",
          "external_user": "Maandelijkse bijdrage per kandidaat, €",
          "fit": "FIT prijs, €",
          "internal_user": "Maandelijkse vergoeding per coach, €",
          "job_market_scan": "Prijs kansen op de arbeidsmarkt, €",
          "job_suggestions": "Job suggesties prijs, €",
          "jobfeed": "Jobfeed-prijs, €",
          "start_date": "Effectief vanaf"
        },
        "product": {
          "name": "Naam"
        },
        "product_program_templates": {
          "ids": "Procestemplates"
        },
        "product_tasks": {
          "ids": "Assessments"
        },
        "program": {
          "invite_mail_subject": "Onderwerp uitnodigingsmail",
          "invite_mail_text": "Tekst uitnodigingsmail",
          "label": "Labels",
          "language": "Taal",
          "status": "Status",
          "step_order": "Volgorde van de stappen",
          "type": "Type"
        },
        "program_page": {
          "approve_by": "Goedkeuring door",
          "has_comments": "Toon berichten aan de onderkant van de stap",
          "hidden_for_candidate": "Verberg de stap voor de kandidaat",
          "optional": "Maak de stap optioneel (invoegbaar na toekenning)"
        },
        "program_template": {
          "invite_mail_subject": "Onderwerp uitnodigingsmail",
          "invite_mail_text": "Tekst uitnodigingsmail",
          "label": "Labels",
          "status": "Status",
          "step_order": "Volgorde van de stappen",
          "type": "Type"
        },
        "report_objectives": {
          "advice": "Advies",
          "other": "Anders",
          "selection": "Selectie"
        },
        "role": {
          "AccountManager": "Accountmanager",
          "Coach": "Coach",
          "IxlyAdmin": "Ixly-beheerder",
          "OrganizationManager": "Organisatiebeheerder",
          "SelectionManager": "Selectiemanager"
        },
        "room": {
          "end_to_end_encryption": "Eind-tot-eind-versleuteling",
          "everyone_starts_hidden": "Iedereen start verborgen",
          "everyone_starts_muted": "Iedereen start gedempt",
          "link": "Link",
          "only_managers_can_join": "Alleen organisatiemanagers kunnen deelnemen",
          "only_managers_can_kick": "Alleen organisatiemanagers kunnen anderen verwijderen",
          "only_managers_can_mute": "Alleen organisatiemanagers kunnen anderen dempen",
          "subject": "Onderwerp"
        },
        "subscription": {
          "annual_fee": "Jaarlijkse abonnementskosten",
          "end_date": "Einddatum",
          "start_date": "Startdatum",
          "subscription_type": "Abonnementsvorm",
          "subscription_type_id": "Abonnementsvorm"
        },
        "subscription_type": {
          "can_downgrade_to": "Kan downgraden naar abonnementsvorm",
          "can_upgrade_to": "Kan upgraden naar abonnementsvorm",
          "candidate_price": "Jaarlijkse kosten per kandidaat voor ongelimiteerde assessments",
          "credit_price": "Prijs per credit",
          "credits_notice": "Weinig credits over melding bij",
          "external_user": "Maandelijkse kosten per kandidaat",
          "internal_user": "Maandelijkse kosten per coach",
          "invoice_line_type": "Boekhoudkundige categorie",
          "max_candidates": "Maximaal aantal actieve kandidaten",
          "max_coaches": "Maximaal aantal actieve coaches",
          "max_users": "Maximaal aantal actieve coaches",
          "month_price": "Maandelijkse abonnementskosten",
          "payment_period": "Betaalperiode",
          "prepay": "Vooruitbetaald",
          "prepay_credits": "Beschikbare creditbundels",
          "type": "Categorie"
        },
        "task": {
          "language": "Taal"
        },
        "translation": {
          "identifier": "Identificatie"
        },
        "user": {
          "avatar": "Profielfoto",
          "biography": "Persoonlijke beschrijving",
          "can_impersonate": "Mag inloggen als een andere gebruiker",
          "consent_given": "Toestemming",
          "cost_center_id": "Kostenplaats",
          "current_password": "Huidige wachtwoord",
          "first_name": "Voornaam",
          "invite_immediately": "Direct een welkomstmail toesturen",
          "label": "Labels",
          "language": "Taal",
          "last_name": "Achternaam",
          "mailing_frequency": "E-mailfrequentie",
          "new_password": "Nieuwe wachtwoord",
          "organization": "Organisatie",
          "organization_language": "Taal organisatie",
          "organization_name": "Naam organisatie",
          "otp_code": "Code",
          "privacy_accepted": "Voorwaarden / Privacy",
          "remove_avatar": "Verwijder deze foto",
          "role": "Rollen",
          "status": "Status",
          "type": "Gebruikerstype",
          "unconfirmed_email": "E-mail"
        },
        "whitelabel": {
          "domain": "Domein",
          "subdomain": "Subdomein"
        }
      },
      "options": {
        "component": {
          "bov_editable_by": {
            "both": "Allebei (de coach kan pas starten nadat de kandidaat gestart is)",
            "candidate": "De kandidaat"
          },
          "can_approve": {
            "candidate": "De kandidaat",
            "contact_person": "De contactpersoon"
          },
          "editable_by": {
            "advisor": "De coach",
            "both": "Allebei",
            "candidate": "De kandidaat"
          },
          "report_visible_for": {
            "advisor": "De coach",
            "both": "Allebei",
            "candidate": "De kandidaat"
          },
          "template_can_be_switched_by": {
            "advisor": "De coach",
            "both": "Allebei",
            "candidate": "De kandidaat",
            "creator": "Niemand"
          },
          "visible_for": {
            "advisor": "De coach",
            "both": "Allebei",
            "candidate": "De kandidaat"
          }
        },
        "discount": {
          "kind": {
            "amc": "Prijs AMC",
            "bov": "Prijs BOV",
            "candidate_price": "Jaarlijkse kosten per kandidaat voor ongelimiteerde assessments",
            "credit_price": "Prijs per credit",
            "external_user": "Maandelijkse kosten per kandidaat",
            "fit": "Prijs FIT",
            "internal_user": "Maandelijkse kosten per coach",
            "job_market_scan": "Prijs Arbeidsmarktkansen",
            "job_suggestions": "Prijs Baansuggesties",
            "jobfeed": "Prijs Jobfeed",
            "month_price": "Maandelijkse abonnementskosten"
          }
        },
        "organization": {
          "advisor_deactivation_period": {
            "never": "Nooit"
          },
          "payment_type": {
            "automatic": "Automatisch",
            "invoice": "Factuur"
          },
          "two_factor": {
            "Advisor": "Adviseur",
            "ContactPerson": "Contactpersoon",
            "OrganizationManager": "Organisatiemanager",
            "User": "Kandidaat"
          }
        },
        "program": {
          "step_order": {
            "approval": "De kandidaat kan pas verder na goedkeuring van de huidige stap",
            "free": "De kandidaat mag de stappen in willekeurige volgorde doorlopen",
            "submission": "De kandidaat kan pas verder na inleveren van de huidige stap"
          }
        },
        "program_page": {
          "approve_by": {
            "none": "De stap hoeft niet te worden goedgekeurd",
            "seen": "De stap wordt automatisch goedgekeurd wanneer de kandidaat hem bekijkt",
            "set": "De coach en de kandidaat kunnen beiden de stap goedkeuren",
            "submit": "De coach keurt de stap goed na inlevering door de kandidaat"
          }
        },
        "subscription_type": {
          "invoice_line_type": {
            "frequent": "Frequent",
            "intensive": "Intensief"
          },
          "payment_period": {
            "month": "Maand",
            "quarter": "Kwartaal",
            "year": "Jaar"
          },
          "type": {
            "AllYouCanSubscription": "All-you-can-report",
            "AnnualSubscription": "Jaarlijks",
            "DemoSubscription": "Demo",
            "IncidentalSubscription": "Incidenteel",
            "ManualSubscription": "Handmatig",
            "ResearchSubscription": "Onderzoek",
            "StandardSubscription": "Standaard",
            "TestSubscription": "Test"
          }
        },
        "user": {
          "mailing_frequency": {
            "every_morning": "Verzamel notificaties en mail deze iedere ochtend.",
            "immediately": "Ontvang onmiddelijk notificaties.",
            "never": "Ontvang nooit notificaties per mail.",
            "once_an_hour": "Verzamel notificaties en mail deze ieder uur."
          },
          "type": {
            "Advisor": "Adviseur",
            "ContactPerson": "Contactpersoon",
            "IxlyEmployee": "Ixly-medewerker",
            "User": "Kandidaat"
          }
        }
      },
      "placeholders": {
        "search_by_name": "Zoek op naam",
        "search_by_title": "Zoek op titel",
        "search_for_coadvisor_ids": "Zoek Coadviseur",
        "search_for_competencies": "Zoek Competenties",
        "search_for_contact_person_ids": "Zoek Contactpersoon",
        "search_for_job_profile_competencies": "Zoek Competenties",
        "search_for_label_ids": "Zoek Label",
        "search_for_labels": "Zoek Label",
        "search_for_options": "Zoekopties",
        "user": {
          "edit": {
            "biography": "hier ingevulde tekst wordt getoond aan iedereen die uw profiel bekijkt"
          }
        }
      },
      "required": {
        "mark": "*",
        "text": "Dit is een verplicht veld"
      }
    },
    "skiplink": "Direct naar hoofdinhoud",
    "status": {
      "advisor": {
        "active": "Actief",
        "created": "Aangemaakt",
        "inactive": "Inactief",
        "invited": "Uitgenodigd"
      },
      "candidate": {
        "active": "Actief",
        "created": "Aangemaakt",
        "inactive": "Inactief",
        "invited": "Uitgenodigd"
      },
      "candidate_email_message": {
        "bounced": "Kan niet afgeleverd worden",
        "delivered": "Afgeleverd",
        "loading": "Bezig met laden",
        "sent": "Verstuurd",
        "transient": "Mogelijke problemen bij aflevering",
        "unsubscribed": "Uitgeschreven"
      },
      "contact_person": {
        "active": "Actief",
        "created": "Aangemaakt",
        "inactive": "Inactief",
        "invited": "Uitgenodigd"
      },
      "enroller": {
        "active": "Actief",
        "created": "Aangemaakt",
        "deactivated": "Gedeactiveerd",
        "inactive": "Inactief"
      },
      "enrollment": {
        "action_required": "Actie benodigd",
        "created": "Aangemaakt",
        "finished": "Afgerond",
        "invited": "Uitgenodigd",
        "started": "Gestart"
      },
      "enrollment_page": {
        "created": "Aangemaakt",
        "done": "Afgerond",
        "rejected": "Afgewezen",
        "submitted": "Ingediend"
      },
      "import_file": {
        "created": "Aangemaakt",
        "failed": "Mislukt",
        "processed": "Verwerkt",
        "validating": "Valideren"
      },
      "invoice": {
        "canceled": "Geannuleerd",
        "charged_back": "Teruggeboekt",
        "closed": "Gesloten",
        "expired": "Verlopen",
        "failed": "Mislukt",
        "open": "Open",
        "paid": "Betaald",
        "processed": "Verwerkt",
        "refunded": "Terugbetaald",
        "revoked": "Ingetrokken",
        "sent": "Verstuurd",
        "settled": "Betaald",
        "unconfirmed": "Onbevestigd"
      },
      "mail_template": {
        "draft": "Ontwerp",
        "published": "Gepubliceerd"
      },
      "organization": {
        "active": "Actief",
        "created": "Aangemaakt",
        "deleted": "Verwijderd",
        "inactive": "Inactief"
      },
      "price": {
        "active": "Actief",
        "expired": "Verlopen",
        "pending": "Nog niet actief"
      },
      "product": {
        "active": "Actief",
        "deactivated": "Gedeactiveerd"
      },
      "program": {
        "deleted": "Verwijderd",
        "editable": "Bewerkbaar",
        "published": "Gepubliceerd",
        "template": "Template"
      },
      "program_template": {
        "deleted": "Verwijderd",
        "editable": "Bewerkbaar",
        "published": "Gepubliceerd",
        "template": "Template"
      },
      "ttk_migration": {
        "completed": "Migratie voltooid",
        "created": "Aangemaakt",
        "failed": "Migratie mislukt",
        "ready_to_migrate": "Klaar om te migreren",
        "syncing_default_reports": "Standaard rapporten migreren",
        "syncing_settings": "Instellingen migreren",
        "syncing_users_candidates": "Accounts migreren",
        "updating_managed_via_ap": "Migratie afronden",
        "updating_manager": "Organisatie manager instellen"
      },
      "user": {
        "active": "Actief",
        "created": "Aangemaakt",
        "inactive": "Inactief",
        "invited": "Uitgenodigd"
      }
    },
    "subscription_type_product_status": {
      "active": "Actief",
      "deactivated": "Gedeactiveerd"
    },
    "subscription_type_products": {
      "create": {
        "created": "De producten zijn toegevoegd aan de abonnementsvorm",
        "products_blank": "Er waren geen producten geselecteerd"
      },
      "deactivate": {
        "deactivated": "De verbinding met het product is gedeactiveerd"
      },
      "destroy": {
        "deleted": "Het product is verwijderd uit de abonnementsvorm"
      },
      "list": {
        "table_header": [
          "Naam",
          "Verbinding",
          "Acties"
        ]
      },
      "new": {
        "title": "Producten toevoegen aan %{subscription_type}"
      },
      "reactivate": {
        "reactivated": "De verbinding met Het product is geheractiveerd"
      },
      "row": {
        "product_delete": "Weet u zeker dat u dit product uit de abonnementsvorm wilt verwijderen?"
      }
    },
    "subscription_type_status": {
      "active": "Actief",
      "deactivated": "Gedeactiveerd"
    },
    "subscription_types": {
      "create": {
        "subscription_type_created": "De abonnementsvorm is aangemaakt",
        "title": "Nieuwe abonnementsvorm"
      },
      "deactivate": {
        "deactivated": "De abonnementsvorm is gedeactiveerd"
      },
      "destroy": {
        "deleted": "De abonnementsvorm is verwijderd"
      },
      "edit": {
        "title": "Bewerk abonnementsvorm"
      },
      "index": {
        "new_button": "Nieuwe abonnementsvorm",
        "table_header": [
          "Naam",
          "Status",
          "Status veranderd op",
          "Actieve abonnementen",
          "Categorie",
          "Acties"
        ],
        "title": "Abonnementsvormen"
      },
      "new": {
        "title": "Nieuwe abonnementsvorm"
      },
      "reactivate": {
        "reactivated": "De abonnementsvorm is geheractiveerd"
      },
      "row": {
        "subscription_type_delete": "Weet u zeker dat u deze abonnementsvorm wilt verwijderen?"
      },
      "show": {
        "disabled": "Nee",
        "enabled": "Ja",
        "product_new_button": "Producten toevoegen",
        "status": "Status"
      },
      "update": {
        "subscription_type_updated": "De abonnementsvorm is bijgewerkt",
        "title": "Bewerk abonnementsvorm"
      }
    },
    "support": {
      "array": {
        "last_word_connector": " en ",
        "two_words_connector": " en ",
        "words_connector": ", "
      }
    },
    "templates": {
      "document": "document"
    },
    "time": {
      "am": "'s ochtends",
      "formats": {
        "date_with_day": "%A, %e %B %Y",
        "date_with_time": "%e %B %Y, %H:%M",
        "date_with_time_short": "%e %b %Y, %H:%M",
        "date_without_day": "%e %B %Y",
        "date_without_day_and_year": "%d %b",
        "date_without_time": "%d-%m-%Y",
        "default": "%a %d %b %Y %H:%M:%S %Z",
        "long": "%d %B %Y %H:%M",
        "short": "%d %b %H:%M",
        "time_only": "%H:%M",
        "time_with_date": "%H:%M (%e %B %Y)"
      },
      "pm": "'s middags"
    },
    "translations": {
      "actions": "Acties",
      "edit": "Bewerk vertaling",
      "new": "Nieuwe vertaling",
      "show": "Bekijk vertaling",
      "source": "Bron",
      "translation_created": "De vertaling is aangemaakt",
      "translation_destroyed": "De vertaling is verwijderd",
      "translation_updated": "De vertaling is bijgewerkt"
    },
    "two_factor": {
      "activate_help_text": "Scan deze QR-code met uw gewenste authenticator-app (bijvoorbeeld Google Authenticator of Authy) en voer de code in om verificatie in twee stappen te activeren.",
      "title": "Verificatie in twee stappen",
      "verify_help_text": "Voer de code in die door de authenticator-app is verstrekt"
    },
    "type": {
      "Advisor": "Adviseur",
      "Candidate": "Kandidaat",
      "CandidateAssessmentEntry": "Assessment",
      "CandidateAssessmentProgramEntry": "Assessment Programma",
      "CandidateReportEntry": "Rapport (Test-only)",
      "Cocoach": "Coadviseur",
      "ContactPerson": "Contactpersoon",
      "GeneratedReportEntry": "Rapport (Processen)",
      "IxlyEmployee": "Ixly-medewerker",
      "LeniencyEntry": "Coulance",
      "OrderEntry": "Bestelling",
      "PremiumCandidate": "Kandidaat",
      "TestOnlyCandidate": "Kandidaat",
      "User": "Kandidaat",
      "plural": {
        "Advisor": "Adviseurs",
        "Candidate": "Kandidaten",
        "ContactPerson": "Contact personen",
        "PremiumCandidate": "Kandidaten",
        "TestOnlyCandidate": "Kandidaten"
      }
    },
    "unauthorized": {
      "manage": {
        "all": "U heeft niet de juiste bevoegdheden om dit te doen"
      }
    },
    "user_types": {
      "Advisor": "Adviseur",
      "Candidate": "Kandidaat",
      "Co_coach": "Coadviseur",
      "Coach": "Adviseur",
      "ContactPerson": "Contactpersoon"
    },
    "users": {
      "all": {
        "new_button": "Nieuwe gebruiker",
        "table_header": [
          "Naam",
          "Organisatie",
          "Type",
          "Rollen",
          "Status",
          "Kostenplaats"
        ],
        "title": "Alle gebruikers"
      },
      "confirmations": {
        "form": {
          "privacy_accepted_label": "Ik ga akkoord met de <a href='%{terms_url}' target='_blank' class='underline hover:text-blue-light'>algemene voorwaarden</a> en het <a href='%{privacy_statement_url}' target='_blank' class='underline hover:text-blue-light'>privacy statement</a>",
          "submit": "Verder"
        },
        "show": {
          "already_confirmed_account": "<span class='font-medium'>Let op!</span> Er bestaat reeds een account met dit email adres. Voor je nieuwe account gebruik je hetzelfde wachtwoord om in te loggen.",
          "already_confirmed_resource": "Je hebt al een account aangemaakt. Gebruik je e-mailadres en wachtwoord om in te loggen.",
          "confirmed": "Het e-mailadres van je account is bevestigd, log hier in met je account",
          "title": "Maak een account"
        },
        "update": {
          "confirmed": "Je account is aangemaakt, log hier in met je account"
        }
      },
      "create": {
        "created": "De gebruiker is aangemaakt",
        "created_and_invited": "De gebruiker is aangemaakt en verwelkomd via e-mail",
        "help_text": "Maak hier een nieuwe gebruiker aan voor uw organisatie",
        "more_information": "Meer informatie",
        "title": "Nieuwe gebruiker"
      },
      "deactivate": {
        "user_deactivated": "De gebruiker is gedeactiveerd"
      },
      "edit": {
        "title": "Bewerk gebruiker"
      },
      "form": {
        "impersonation_explanation": "Als u deze optie selecteert, dan geeft u deze gebruiker het recht om in te loggen als een andere gebruiker.",
        "labels": "Labels",
        "unconfirmed_email": "Het e-mailadres wordt gewijzigd naar:",
        "unconfirmed_email_explanation": "De eigenaar van het e-mailadres moet via een link de wijziging bevestigen. Pas na bevestigen wordt het gewijzigde e-mailadres gebruikt.",
        "upload_avatar": "Klik op de afbeelding om een eigen foto te uploaden",
        "user_type_documentation": "(klik hier voor de gebruikersrollen documentatie)",
        "your_labels": "Uw labels"
      },
      "index": {
        "help_text": "Hier vindt u alle gebruikers binnen uw organisatie",
        "import": "CSV import",
        "new_button": "Nieuwe gebruiker",
        "title": "Gebruikers"
      },
      "new": {
        "help_text": "Maak hier een nieuwe gebruiker aan voor uw organisatie",
        "title": "Nieuwe gebruiker"
      },
      "password": {
        "title": "Wachtwoord wijzigen"
      },
      "reactivate": {
        "user_reactivated": "De gebruiker is geheractiveerd"
      },
      "removed_member": "verwijderde %{type}",
      "reset_password": {
        "sent": "De wachtwoordherstelmail is verstuurd"
      },
      "row": {
        "number_of_assigned_programs": {
          "one": "1 proces",
          "other": "%{count} processen"
        }
      },
      "send_welcome": {
        "bulk_button": "Uitnodigen",
        "welcome_mail_sent": "De welkomstmail is verstuurd"
      },
      "show": {
        "account_manager": "Accountmanager van",
        "advisor": "Adviseur",
        "biography": "Persoonlijke beschrijving",
        "candidate_overview": "Kandidaatsoverzicht",
        "client": "Opdrachtgever",
        "cost_center": "Kostenplaats",
        "created_at": "Aangemaakt op",
        "disabled": "Uitgeschakeld",
        "email": "E-mailadres",
        "enabled": "Ingeschakeld",
        "impersonate": "Log in als",
        "organization": "Organisatie",
        "resend_welcome_email": "Verstuur welkomstmail opnieuw",
        "reset_password": "Herstel wachtwoord",
        "send_email": "Verstuur mail",
        "send_welcome_email": "Verstuur welkomstmail",
        "status": "Status",
        "two_factor": "Verificatie in twee stappen",
        "type": "Rol",
        "user_overview": "Gebruikersoverzicht"
      },
      "update": {
        "title": "Bewerk gebruiker",
        "updated": "De gebruiker is bijgewerkt"
      }
    },
    "validations": {
      "minlength_12": "minimaal twaalf tekens."
    },
    "valvat": {
      "country_adjectives": {
        "at": "Oostenrijks",
        "be": "Belgisch",
        "bg": "Bulgaars",
        "cy": "Cypriotisch",
        "cz": "Tsjechisch",
        "de": "Duits",
        "dk": "Deens",
        "ee": "Ests",
        "es": "Spaans",
        "eu": "Europees",
        "fi": "Fins",
        "fr": "Frans",
        "gb": "Brits",
        "gr": "Grieks",
        "hu": "Hongaars",
        "ie": "Iers",
        "it": "Italiaans",
        "lt": "Litouws",
        "lu": "Luxemburgs",
        "lv": "Lets",
        "mt": "Maltees",
        "nl": "Nederlands",
        "pl": "Pools",
        "pt": "Portugees",
        "ro": "Roemeens",
        "se": "Zweeds",
        "si": "Sloveens",
        "sk": "Slowaaks"
      }
    },
    "will_paginate": {
      "next_label": "Volgende &#8594;",
      "page_gap": "&hellip;",
      "previous_label": "&#8592; Vorige"
    }
  }
}