{
  "de": {
    "accounts": {
      "passwords": {
        "form": {
          "login": "Einloggen",
          "submit": "Anfordern"
        }
      },
      "sessions": {
        "form": {
          "forgot_your_password": "Passwort vergessen?",
          "submit": "Login"
        },
        "new": {
          "title": "Einloggen"
        }
      },
      "switch_users": {
        "success": "Erfolgreich bei %{organization} angemeldet"
      }
    },
    "action_menu": {
      "admin": {
        "mail_templates": {
          "single": {
            "delete_confirm": "Sind Sie sicher, dass Sie diese E-Mail-Vorlage löschen möchten?"
          }
        }
      },
      "assessments": {
        "labels": {
          "single": {
            "delete_confirm": "Möchten Sie dieses Etikett wirklich entfernen?"
          }
        },
        "program_templates": {
          "single": {
            "delete_confirm": "Sind Sie sicher, dass Sie diesen Prozess löschen möchten?"
          }
        },
        "programs": {
          "single": {
            "delete_confirm": "Sind Sie sicher, dass Sie diesen Prozess löschen möchten?"
          }
        }
      },
      "deactivation_confirm": "Durch die Deaktivierung von %{name} hat %{name} keinen Zugriff mehr auf die Site und wird automatisch über %{retention_period} gelöscht. Bitte beachten Sie, dass inaktive %{type} durch die Standardfiltereinstellungen ausgeblendet werden. Passen Sie Ihre Filter an, wenn Sie diese in die Übersicht aufnehmen möchten.",
      "delete_confirm": "Durch das Löschen von %{name} werden auch automatisch alle möglichen zugrunde liegenden Trajektorien gelöscht.",
      "ixly": {
        "migration_organizations": {
          "default": {
            "add": "Neue Migrationsorganisation hinzufügen",
            "start": "Starten Sie Migrationen"
          }
        },
        "subscriptions": {
          "single": {
            "delete_confirm": "Möchten Sie dieses Abonnement wirklich löschen?"
          }
        }
      },
      "selection": {
        "job_profiles": {
          "single": {
            "delete_confirm": "Sind Sie sicher, dass Sie dieses Jobprofil löschen möchten?"
          }
        }
      }
    },
    "activemodel": {
      "attributes": {
        "queries/usage": {
          "end_date": "Enddatum",
          "organization": "Organisation",
          "start_date": "Anfangsdatum"
        }
      }
    },
    "admin": {
      "authentication_actions": {
        "filter_form": {
          "authenticate": "Anmeldung",
          "impersonate": "Anmelden als..."
        },
        "index": {
          "action": "Aktion",
          "browser": "Browser",
          "caption": "Nachfolgend finden Sie alle Ereignisse im Zusammenhang mit der Authentifizierung. Diese Ereignisse werden maximal 6 Monate lang aufbewahrt.",
          "created_at": "Wann",
          "ip": "IP Adresse",
          "title": "Authentifizierungsprotokolle"
        }
      },
      "candidate_csv_imports": {
        "import_file": {
          "log": "Log",
          "original": "Original"
        },
        "index": {
          "list": "Dies listet Kandidaten Importdateien innerhalb der Organisation auf",
          "title": "Kandidaten CSV Importe"
        },
        "new": {
          "title": "Kandidaten CSV Import"
        }
      },
      "color_settings": {
        "destroy": {
          "destroyed": "Die Farbeinstellungen wurden auf die Standardwerte zurückgesetzt.",
          "reset_button": "Standardzeitplan wiederherstellen"
        },
        "edit": {
          "color_settings_explanation": "Sie können das Farbschema der interaktiven Ergebnisseite für alle Benutzer ändern, indem Sie die Felder unten verwenden",
          "title": "Farbschema"
        },
        "update": {
          "updated": "Die Farbeinstellungen der Organisation wurden erfolgreich aktualisiert."
        }
      },
      "default_mail_templates": {
        "could_not_be_saved": "Standard-Mailvorlage konnte nicht gespeichert werden",
        "explain": "Hier können Sie aus veröffentlichten Organisations-E-Mail-Vorlagen auswählen und diese als Standard festlegen. Es ist nicht möglich, Bewerbungsmail-Vorlagen auszuwählen. Wenn Sie dies wünschen, müssen Sie diese zunächst in Ihre eigene Organisation kopieren.",
        "header": "Email Einstellungen",
        "saved_successfully": "Standard-Mailvorlage gespeichert!",
        "updated_successfully": "Standard-Mail-Vorlage erfolgreich aktualisiert!"
      },
      "entries": {
        "index": {
          "candidate_assessment_entry": "Bewertung",
          "candidate_assessment_program_entry": "Bewertungsprogramm",
          "candidate_report_entry": "Bericht",
          "generated_report_entry": "Bericht",
          "leniency_entry": "Freundlichkeit",
          "list": {
            "candidate": "Kandidat",
            "cost_center": "Kostenstelle",
            "credits": "Nummer",
            "date": "Datum",
            "description": "Beschreibung",
            "entry_type": "Art der Transaktion"
          },
          "order": {
            "frequent_credits": "Monatliche Abbuchung der verbrauchten Guthaben",
            "incidental_credits": "Kauf eines Credit-Pakets",
            "intensive_credits": "Monatliche Abbuchung der verbrauchten Guthaben"
          },
          "order_entry": "Befehl",
          "title": "Übersicht über das Hauptbuch"
        }
      },
      "mail_templates": {
        "add": "E-Mail-Vorlage hinzufügen",
        "create": {
          "success": "Die neue E-Mail-Vorlage wurde erstellt"
        },
        "destroy": {
          "deleted": "Die E-Mail-Vorlage wurde gelöscht"
        },
        "duplicate": {
          "duplicated": "Die E-Mail-Vorlage „%{title}“ wurde kopiert"
        },
        "duplicated_title": "Kopie von %{title}",
        "edit": {
          "title": "E-Mail-Vorlage bearbeiten"
        },
        "form": {
          "assessment_platform_link_explanation": "Ein Link, der den Kandidaten zur richtigen Stelle in der Assessment Platform führt. Wenn sie noch kein Passwort festgelegt haben, werden sie zur Seite „Passwort festlegen“ weitergeleitet, andernfalls zur Anmeldeseite. Dieses Feld ist erforderlich*",
          "assignment_title_explanation": "Die Titel der zugewiesenen Aufgaben",
          "candidate_name_explanation": "Der Name des Benutzers",
          "end_date_explanation": "Das späteste Datum, bis zu dem die Aktivität abgeschlossen werden kann",
          "explanation_text": "Innerhalb des Textes der Mailvorlage können Variablen verwendet werden, um Inhalte dynamisch einzufügen. Diese Variablen sind von %{VARIABLE} umgeben, wobei VARIABLE der Name ist und vollständig ersetzt wird. Wir unterstützen derzeit Folgendes",
          "organization_name_explanation": "Der Name der aktuellen Organisation"
        },
        "index": {
          "help_text": "E-Mail-Vorlagen sind vordefinierte E-Mails, die Ihre Berater an Kandidaten senden können. Nur veröffentlichte Vorlagen können von einem Berater ausgewählt werden. Als Beispiel dienen Bewerbungsvorlagen.",
          "title": "E-Mail-Vorlagen"
        },
        "list": {
          "application_template": "Bewerbungsvorlagen",
          "language": "Sprache",
          "state": "Status",
          "subject": "Thema",
          "title": "Titel",
          "updated_at": "Aktualisiert am"
        },
        "new": {
          "title": "Neue E-Mail-Vorlage"
        },
        "publish": {
          "cannot_publish": "Die E-Mail-Vorlage %{title} kann nicht veröffentlicht werden:<br> %{error_messages}",
          "published": "Die E-Mail-Vorlage %{title} wurde veröffentlicht"
        },
        "show": {
          "body": "E-Mail-Inhalt",
          "example_title": "Beispiel für die E-Mail, die gesendet wird",
          "subject": "Thema",
          "title": "Beispiel der %{title}-E-Mail-Vorlage"
        },
        "update": {
          "cannot_publish": "Die E-Mail-Vorlage konnte nicht aktualisiert werden:<br> %{error_messages}",
          "success": "E-Mail-Vorlage aktualisiert",
          "title": "E-Mail-Vorlage bearbeiten"
        },
        "withdraw": {
          "withdrawn": "Die E-Mail-Vorlage %{title} wurde eingestellt"
        }
      },
      "organization_permissions": {
        "edit": {
          "help_text": "Hier können Sie einschränken, welche Benutzerrollen Zugriff auf welche Teile der Anwendung haben",
          "title": "Nutzerrechte"
        },
        "update": {
          "updated": "Die Organisationsberechtigungen wurden aktualisiert."
        }
      },
      "organization_settings": {
        "default_report_objective": {
          "advice": "Beratung",
          "selection": "Auswahl"
        },
        "default_report_to": {
          "advisor": "Der Trainer",
          "both": "Beide",
          "candidate": "Der Teilnehmer"
        },
        "edit": {
          "available_languages_explanation": "Wenn Sie die von Ihren Benutzern verwendeten Sprachen deaktivieren, werden deren Spracheinstellungen automatisch auf die Standardsprache Ihrer Organisation umgestellt.",
          "default_language_explanation": "Die ausgewählte Sprache dient als Standardeinstellung für neue Benutzer innerhalb der Organisation.",
          "default_report_objective_explanation": "Beim Erstellen eines neuen Kandidaten wird standardmäßig das Standardberichtsziel ausgewählt. Wenn Sie Kandidaten beurteilen, um sie bei ihrer Entwicklung zu unterstützen, wählen Sie „Beratung“; Wenn Sie Kandidaten bewerten, um unter ihnen auszuwählen, wählen Sie „Auswahl“. Basierend auf diesem Ziel wählen wir automatisch den relevantesten Bericht für diesen Kandidaten aus.",
          "default_report_to_explanation": "Diese Einstellung bestimmt, wer nach Abschluss einer Beurteilung Einblick in den Bericht erhält: der Kandidat, der Berater oder beide. Wenn „Berater“ ausgewählt ist, hat der Berater die Möglichkeit, dies pro Bewertung festzulegen. Beim Bearbeiten eines Prozesses kann dies immer pro Bewertung eingestellt werden.",
          "title": "Organisationseinstellungen"
        },
        "update": {
          "required_languages": "Verfügbare Sprachen dürfen nicht leer sein und die Standardsprache muss in der Organisation verfügbar sein",
          "updated": "Die Organisationseinstellungen wurden erfolgreich aktualisiert."
        }
      },
      "privacy_settings": {
        "edit": {
          "advisor_deactivation_period_explanation": "Der Zeitraum der Inaktivität, nach dem Coaches deaktiviert werden.",
          "candidate_deactivation_period_explanation": "Der Zeitraum der Inaktivität, nach dem Teilnehmer deaktiviert werden.",
          "impersonation_explanation": "Wenn Sie diese Option wählen, können sich Nutzer mit ‚Einloggen als‘ -Rechten in die Accounts all Ihrer Nutzer einloggen. Hiermit geben Sie auch dem Helpdesk- und IT-Personal von Ixly die Möglichkeit, beim Helpdesk gemeldete Probleme aus Nutzerperspektive zu betrachten und anschließend zu lösen.",
          "ixly_support_explanation": "Wenn Sie diese Option aktivieren, geben Sie den Ixly-Helpdesk-Mitarbeitern die Möglichkeit, Ihre Benutzer zu überwachen, um über den Helpdesk gemeldete Probleme zu lösen.",
          "retention_period_explanation": "Die Datenschutz-Grundverordnung (DSGVO) schreibt vor, dass personenbezogene Daten nicht länger als nötig gespeichert werden dürfen. Um dem gerecht zu werden, werden inaktive Kandidaten, Berater und Kontakte automatisch basierend auf der gewählten Aufbewahrungsfrist gelöscht. Diese Aufbewahrungsfrist gilt auch für die verwendeten Bewertungen und erstellten Berichte.",
          "title": "Datenschutzeinstellungen"
        },
        "update": {
          "updated": "Die Datenschutzeinstellungen der Organisation wurden erfolgreich aktualisiert."
        }
      },
      "whitelabel": {
        "edit": {
          "title": "Whitelabeleinstellungen"
        },
        "update": {
          "whitelabel_updated": "Das Whitelabel ist aktualisiert"
        }
      }
    },
    "advisors": {
      "index": {
        "title": "Berater"
      }
    },
    "alt_texts": {
      "approval_request_send_reason": "Diesen Grund versenden",
      "component_add": "Dieses Element hinzufügen",
      "component_delete": "Dieses Element löschen",
      "component_move": "Dieses Element verschieben",
      "component_settings": "Die Elementeinstellungen öffnen",
      "details_collapse": "Details reduzieren",
      "details_expand": "Details anzeigen",
      "document_delete": "Diese gespeicherte Auswertung löschen",
      "document_download": "Diese gespeicherte Auswertung herunterladen",
      "document_save": "Diese Auswertung speichern",
      "document_share": "Diese gespeicherte Auswertung mit dem Teilnehmer teilen",
      "document_unshare": "Die obigen Einstellungen mit dem Teilnehmer teilen",
      "enrollment_page_print": "Diesen Schritt drucken",
      "enrollment_page_requiring_action": "Enthält Elemente mit zusätzlichen Einstellungen",
      "file_download": "Diese Datei herunterladen",
      "filter_bar_collapse": "Filter reduzieren",
      "filter_bar_expand": "Filter anzeigen",
      "interactive_results_agile_average": "Grafik mit gepunkteter Linie bei %{percentage} von rechts. Je höher dieser Prozentsatz, desto flexibler sind Sie.",
      "interactive_results_agile_pillars": "Grafik, die Ihre Teilergebnisse als klickbare Sektoren eines Tortendiagramms anzeigt. Kraft %{power}, Veränderung %{change}, Persönliches Wachstum %{growth}.",
      "interactive_results_info_birthdate": "Geburtsdatum",
      "interactive_results_info_date_of_birth": "Geburtsdatum",
      "interactive_results_info_education": "Bildungsabschluss",
      "interactive_results_info_email": "E-Mail-Adresse",
      "interactive_results_info_study_subject": "Bildung",
      "interactive_results_info_work_experience": "Neueste Berufserfahrung",
      "interactive_results_info_years_experience": "Anzahl Jahre Erfahrung",
      "interactive_results_information": "Die Erläuterung der Scores öffnen",
      "interactive_results_intelligence_chart": "Grafik, das Ihren IQ-Wert von %{iq} in einer Normalverteilung zeigt. Die gestrichelten Linien zeigen die Fehlermargen an.",
      "interactive_results_next": "Nächste",
      "interactive_results_paging": "Seite %{page} von %{pages}",
      "interactive_results_previous": "Vorige",
      "interactive_results_rating": "%{score} von %{max} Sternen",
      "interactive_results_tip": "Der tipp öffnen",
      "ixly_test_download": "Diese Auswertung herunterladen",
      "ixly_test_settings": "Die Auswertungseinstellungen öffnen",
      "loading": "Bin dabei ...",
      "navbar_expand": "Navigationsmenü anzeigen",
      "notification_close": "Diese Aufgabe schließen",
      "notification_reopen": "Diese Aufgabe noch einmal öffnen",
      "organization_logo": "Logo von %{name}",
      "organization_logo_change": "Logo von %{name} – zum Ändern klicken",
      "organization_logo_home": "Logo von %{name} – zum Starten klicken",
      "post_rule_delete": "Diese Regel zum Teilen löschen",
      "price_in_euros": "in Euro",
      "program_page_delete": "Dieser Schritt löschen",
      "program_page_move": "Dieser Schritt verschieben",
      "program_page_settings": "Die Schritteinstellungen öffnen",
      "program_settings": "Die Prozesseinstellungen öffnen",
      "quiz_correct": "Diese Antwort ist richtig",
      "quiz_incorrect": "Diese Antwort ist falsch",
      "quiz_selected": "Diese Antwort wurde markiert",
      "saved": "Ihre Änderungen wurden gespeichert",
      "saving": "Ihre Änderungen werden gespeichert",
      "template_column_delete": "Diese Spalte löschen",
      "template_column_move": "Diese Spalte verschieben",
      "template_column_settings": "Die Spalteneinstellungen öffnen",
      "template_element_delete": "Diesen Abschnitt löschen",
      "template_element_move": "Diesen Abschnitt verschieben",
      "template_element_settings": "Die Abschnittseinstellungen öffnen",
      "template_element_variables": "Die Variableneinstellungen öffnen",
      "template_item_delete": "Dieses Item löschen",
      "template_row_delete": "Diese Zeile löschen",
      "template_row_move": "Diese Zeile verschieben",
      "template_zoom_in": "Einzoomen",
      "template_zoom_out": "Auszoomen",
      "top_step_delete": "Dieser Schrittgruppe löschen",
      "top_step_insert": "Eine Schrittgruppe einfügen",
      "user_avatar": "Profilfoto von %{name}",
      "user_avatar_change": "Profilfoto von %{name} – zum Ändern klicken",
      "view_switcher_cards": "Als Kacheln anzeigen",
      "view_switcher_table": "Als Tabelle anzeigen"
    },
    "approval_requests": {
      "index": {
        "help_text": "Hier finden Sie alle Dokumente, für die um Ihre Zustimmung gebeten wurde",
        "table_header": [
          "Datei",
          "Teilnehmer",
          "Prozess",
          "Status",
          "Grund",
          "Coach"
        ],
        "title": "Zustimmungen"
      }
    },
    "assessments": {
      "advisors": {
        "account_email": "Email",
        "create": {
          "created": "Der Berater wurde erstellt",
          "created_and_invited": "Der Berater wurde erstellt und per E-Mail begrüßt",
          "title": "Neuer Berater"
        },
        "created_at": "Datum",
        "deactivate": {
          "advisor_deactivated": "Der Berater wurde deaktiviert"
        },
        "edit": {
          "labels": "Labels",
          "title": "Der Berater bearbeiten"
        },
        "form": {
          "add_label": "Etikett hinzufügen",
          "impersonation_explanation": "Wenn Sie diese Option wählen, geben Sie diesem Nutzer das Recht, sich im Account eines anderen Nutzers einzuloggen",
          "role_explanation": "Klicken Sie hier, um eine detaillierte Rollendokumentation anzuzeigen"
        },
        "index": {
          "list": "Hier werden alle Benutzer in Ihrer Organisation aufgelistet",
          "title": "Berater"
        },
        "label": {
          "remove": "Löschen"
        },
        "last_activity": "Letzte Aktivität",
        "list": {
          "candidates": "Teilnehmers",
          "labels": "Labels"
        },
        "name": "Name",
        "new": {
          "title": "Neuer Berater"
        },
        "organization": "Organisation",
        "privacy_accepted_at": "Datenschutz akzeptiert",
        "reactivate": {
          "advisor_reactivated": "Der Berater wurde reaktiviert"
        },
        "roles": "Rollen",
        "send_welcome": {
          "welcome_mail_sent": "Die Willkommensmail ist verschickt"
        },
        "status": "Status",
        "two_factor": "Verifizierung in zwei Schritten",
        "type": "Typ",
        "unconfirmed_email": "Unbestätigte E-Mail",
        "update": {
          "advisor_updated": "Der Berater wurde aktualisiert",
          "title": "Der Berater bearbeiten"
        }
      },
      "advisors_labels": {
        "destroy": {
          "removed": "Das Label wurde erfolgreich aus dem Advisor entfernt"
        },
        "form": {
          "new": "Neu Label"
        }
      },
      "assessment_report_settings": {
        "edit": {
          "title": "Berichtseinstellungen"
        },
        "update": {
          "success": "Die Berichtseinstellungen wurden erfolgreich aktualisiert"
        }
      },
      "assessment_verifications": {
        "link": "Überprüfung",
        "show": {
          "about_verification_tests": "Ein Verifizierungstest ist eine optionale, verkürzte Version des vollständigen %{assessment}. Kapazitätstests werden zunehmend aus der Ferne und ohne Aufsicht durchgeführt. In dieser Situation ist es schwierig abzuschätzen, ob der Kandidat die Prüfung selbst und ohne Hilfsmittel absolviert hat. Sie können diese Beurteilung verbessern, indem Sie einen Verifizierungstest nutzen, der unter Aufsicht durchgeführt wird.",
          "about_verification_tests_title": "Über Verifizierungstests",
          "run_test": "Führen Sie nun den Verifizierungstest durch",
          "title": "Authentifizierung für %{assessment} von %{candidate} erforderlich",
          "verification_tests_report": "Sobald der Verifizierungstest erstellt wurde, erhalten Sie im Bericht eine Extraseite, die zeigt, ob die Ergebnisse des zuvor erstellten %{assessment} einigermaßen mit den Ergebnissen des Verifizierungstests übereinstimmen. Wenn ja, sehen Sie im Bericht ein grünes Häkchen. Wenn Sie im Bericht ein rotes Kreuz sehen, ist der Unterschied zwischen den Ergebnissen des Remote-%{assessment} und des Verifizierungstests größer, als wir aufgrund des Zufalls erwarten würden.",
          "verification_tests_report_title": "Unterschiede im Bericht",
          "why_skip_verification_tests": "Wenn ein Kandidat eine abgeschlossen hat Es ist auch weniger relevant, wenn die Ergebnisse des Kandidaten unter dem erwarteten Niveau liegen. Der Verifizierungstest gibt keinen Aufschluss darüber, ob der Kandidat nach der Teilnahme an diesem Verifizierungstest eine höhere Punktzahl erreicht hat. Sie sehen nur, ob die Ergebnisse des zweiten Tests mit denen des ersten Tests übereinstimmen.",
          "why_skip_verification_tests_title": "Wetten oder nicht wetten?"
        },
        "skip": {
          "success": "Die Verifizierung von %{title} wurde erfolgreich übersprungen"
        },
        "update": {
          "success": "Die Überprüfung von %{title} wurde erfolgreich gestartet"
        }
      },
      "assignments": {
        "edit": {
          "end_date": "Enddatum anpassen",
          "end_date_explanation": "Das späteste Datum, bis zu dem die Aktivität abgeschlossen werden kann",
          "invitation_date": "Einladungsdatum bearbeiten",
          "invitation_mail_title": "Passen Sie die Einladungs-E-Mail an"
        },
        "invite": {
          "candidate_double_invited": "Der Teilnehmer wurde per E-Mail eingeladen und über %{title} benachrichtigt",
          "candidate_invited": "Der Teilnehmer wurde per E-Mail über %{title} benachrichtigt",
          "cannot_invite": "Es war nicht möglich, den Kandidaten zu %{title} einzuladen, der Kandidat war bereits eingeladen."
        },
        "prepare_invite": {
          "title": "Laden Sie %{candidate} zu %{assessment} ein"
        },
        "updated": "Die Aktivität wurde erfolgreich geändert"
      },
      "bulk_assignments": {
        "already_assigned": "%{assignment} ist bereits ausgewählt",
        "assessment_not_available_in_subscription": "Dieser Vorgang umfasst eine Bewertung, die in Ihrem Abonnement nicht mehr verfügbar ist. Passen Sie den Prozess so an, dass er erneut vergeben werden kann.",
        "blank_description": "Für diesen Prozess ist keine Beschreibung verfügbar.",
        "create": {
          "action_required": "Der Klage wurde stattgegeben. Bevor der Kandidat eingeladen werden kann, sind noch Maßnahmen erforderlich.",
          "candidate_invited": "Der Teilnehmer wurde per E-Mail über den neu zugewiesenen Vorgang informiert",
          "cannot_enroll": "Sie können diesen Prozess derzeit nicht zuordnen",
          "enrollment_created": "Der Klage wurde stattgegeben. Der Teilnehmer wurde noch nicht informiert."
        },
        "duration": "%{n} Minuten",
        "example_report": "Beispielbericht in neuem Tab öffnen",
        "more_information": "Mehr Informationen",
        "new": {
          "selected_assessment_programs": "Zugewiesene Bewertungsprogramme",
          "selected_assessments": "Zugewiesene Beurteilungen",
          "selected_programs": "Zugewiesene Prozesse",
          "title": "Bewertung zuweisen – %{candidate}"
        },
        "preview": "Beispiel"
      },
      "candidate_assessments": {
        "confirm_destroy": "Sind Sie sicher, dass Sie %{assessment_title} von %{candidate_name} entfernen möchten?",
        "confirm_reset": "Sind Sie sicher, dass Sie die Bewertung %{assessment_title} zurücksetzen möchten? Durch das Zurücksetzen werden alle Antworten gelöscht, sodass der Kandidat die Bewertung erneut beginnen kann.",
        "destroy": {
          "success": "%{title} wurde erfolgreich zurückgezogen"
        },
        "report_tos": {
          "advisor": "Der Trainer",
          "both": "Beide",
          "candidate": "Der Teilnehmer"
        },
        "reset": {
          "success": "%{title} wurde erfolgreich zurückgesetzt"
        }
      },
      "candidate_email_messages": {
        "index": {
          "title": "E-Mails an %{candidate} gesendet"
        },
        "list": {
          "sent_at": "Gesendet am",
          "status": "Status",
          "subject": "Thema"
        }
      },
      "candidate_reports": {
        "add": "Bericht generieren",
        "create": {
          "success": "Der Bericht wird erstellt. Sie erhalten eine Benachrichtigung, wenn das Dokument in der Dokumente und Mediathek bereit ist."
        },
        "download": "Bericht herunterladen",
        "form": {
          "external_report": "Bericht für %{candidate_assessment}",
          "generated_reports": "Berichte generiert für %{candidate_assessment}",
          "no_external_reports_generated_yet": "Für %{candidate_assessment} wurden keine Berichte generiert",
          "no_reports_generated_yet": "Für %{candidate_assessment} wurden keine Berichte generiert",
          "report_objective": "Berichtsziel: %{norm_group}"
        },
        "form_list": {
          "document_options": "Berichtsoptionen",
          "language": "Sprache",
          "media_item": "Dokumentieren",
          "no_media_item_available": "Dokument noch nicht verfügbar",
          "norm_group_id": "Berichtsziel",
          "shared_with": "Geteilt mit",
          "title": "Generierter Bericht"
        },
        "index": {
          "link_title": "Berichte",
          "title": "Berichte für %{candidate}"
        },
        "list": {
          "assessment": "Assessment",
          "created_at": "Gemacht auf",
          "document_options": "Berichtsoptionen",
          "language": "Sprache",
          "media_item": "Dokument",
          "no_media_item_available": "Dokument noch nicht verfügbar",
          "norm_group_id": "Berichtsziel",
          "shared_with": "Geteilt mit",
          "title": "Bericht"
        },
        "new": {
          "link_title": "Bericht erstellen",
          "title": "Bericht für %{candidate} erstellen"
        },
        "shared_with": {
          "one": "und %{count} Organisationsmanager",
          "other": "und %{count} Organisationsmanager"
        },
        "stitch_reports": "Berichte zusammenführen"
      },
      "candidate_results": {
        "index": {
          "title": "Persönliche Ergebnisübersicht"
        }
      },
      "candidate_selection": {
        "active_filter_info": "<span class='font-bold'> / %{count}</span> des aktiven Filters werden ausgewählt.",
        "deselect_button": "alles abwählen",
        "select_button": "Alles auswählen",
        "total_info": "<span class='font-bold'> / %{count} Kandidaten</span> wurden ausgewählt."
      },
      "candidate_steps": {
        "create": {
          "action_required": "Der Kandidat wurde erstellt. Es besteht noch Handlungsbedarf, um den Kandidaten zum Prozess einzuladen",
          "candidate_created": "Der Teilnehmer wurde per E-Mail über das erstellte Konto informiert",
          "candidate_invited": "Der Teilnehmer wurde per E-Mail über den neu zugewiesenen Vorgang informiert",
          "cannot_enroll": "Sie können diesen Prozess derzeit nicht zuordnen",
          "enrollment_created": "Sie haben <a class='underline font-bold' href='%{candidate_link}'>%{name}</a> erstellt und <a class='underline font-bold' href='%{enrollment_link}'>%{title}</a> zugewiesen."
        },
        "filter": {
          "all": "Alle",
          "assessment_definitions": "Beurteilungen",
          "assessment_programs": "Bewertungsprogramme",
          "programs": "Prozesse"
        },
        "info_block": "Genaue Information",
        "new": {
          "candidate_title": "Teilnehmer hinzufügen",
          "coadvisor_title": "Ansprechpartner zuweisen",
          "enrollment_title": "Bewertung zuweisen"
        }
      },
      "candidates": {
        "add": "Teilnehmer hinzufügen",
        "add_label": "Etikett hinzufügen",
        "added": "Teilnehmer <a class='underline' href='%{link}'>%{name}</a> wurde erstellt.",
        "assign_bulk_button": "Gewähren",
        "contact_person": {
          "remove": "Löschen"
        },
        "create": {
          "title": "Teilnehmer hinzufügen"
        },
        "deactivate": "Der Kandidat wurde deaktiviert",
        "delete": "Kandidat löschen",
        "deleted": "Der Kandidat %{name} wurde gelöscht",
        "edit": {
          "contact_persons": "Kontaktpersonen",
          "labels": "Labels",
          "title": "Kandidat bearbeiten"
        },
        "export": {
          "started": "Der Export der Kandidaten wird gestartet. Sobald der Export abgeschlossen ist, erhalten Sie eine Benachrichtigung."
        },
        "index": {
          "title": "Kandidaten"
        },
        "label": {
          "remove": "Löschen"
        },
        "list": {
          "advisor": "Berater",
          "api_identifier": "API-Kennung",
          "coadvisor": "Co-Berater",
          "contact_person": "Kontakte",
          "cost_center": "Kostenstelle",
          "created_at": "Gemacht auf",
          "email": "Email",
          "first_name": "Vorname",
          "labels": "Etiketten",
          "language": "Sprache",
          "last_activity": "Letzte Aktivität",
          "last_name": "Familienname, Nachname",
          "name": "Name",
          "organization": "Organisation",
          "privacy_accepted_at": "Datenschutz akzeptiert",
          "report_objective": "Berichtsziel",
          "status": "Status",
          "track": "Letzte Aktivität",
          "unconfirmed_email": "Unbestätigte E-Mail"
        },
        "premium_candidate_tooltip": "Dies ist ein Premium-Teilnehmer",
        "reactivated": "Der Kandidat wurde reaktiviert",
        "review_required": "Überprüfung erforderlich",
        "update": {
          "title": "Teilnehmer anpassen"
        },
        "updated": "Teilnehmer aktualisiert",
        "upload_candidates": "Kandidaten hochladen"
      },
      "candidates_contact_persons": {
        "show": {
          "account_email": "E-Mail-Adresse",
          "contact_person": "Kontakt",
          "created_at": "Gemacht um",
          "name": "Name",
          "organization": "Organisation",
          "privacy_accepted_at": "Datenschutz akzeptiert",
          "role": "Rolle",
          "status": "Status"
        }
      },
      "clients": {
        "add": "Kunde hinzufügen",
        "contact_person": {
          "edit": "Bearbeiten",
          "remove": "Löschen"
        },
        "create": {
          "client_created": "Der neue Auftraggeber ist erstellt",
          "help_text": "Erstellen Sie hier einen neuen Auftraggeber für Ihre Organisation",
          "title": "Neuer Auftraggeber"
        },
        "edit": {
          "labels": "Labels",
          "title": "Auftraggeber bearbeiten"
        },
        "form": {
          "add_contact_person": "Ansprechpartner hinzufügen",
          "add_label": "Etikett hinzufügen",
          "labels": "Labels"
        },
        "index": {
          "help_text": "Hier finden Sie alle Auftraggeber (Betriebe) Ihrer Organisation",
          "new_button": "Neuer Auftraggeber",
          "title": "Auftraggeber"
        },
        "label": {
          "remove": "Löschen"
        },
        "list": {
          "candidates": "Teilnehmers",
          "contact_persons": "Kontakte",
          "name": "Name",
          "updated_at": "Aktualisiert am"
        },
        "new": {
          "help_text": "Erstellen Sie hier einen neuen Auftraggeber für Ihre Organisation",
          "title": "Neuer Auftraggeber"
        },
        "row": {
          "number_of_candidates": "%{number} aktiv",
          "number_of_contact_persons": "%{number} aktiv"
        },
        "show": {
          "help_text": "Hier finden Sie alle Ihre Kontaktpersonen und Teilnehmer dieses Auftraggebers",
          "new_button": "Neue Kontaktperson",
          "table_header": [
            "Angaben",
            "E-Mail-Adresse",
            "Status"
          ]
        },
        "update": {
          "client_updated": "Der Auftraggeber ist aktualisiert",
          "title": "Auftraggeber bearbeiten"
        }
      },
      "clients_contact_persons": {
        "add": "Kontaktperson hinzufügen",
        "create": {
          "created": "Kontaktperson %{name} wurde erstellt"
        },
        "deactivate": {
          "deactivated": "Der Kontaktperson wurde deaktiviert"
        },
        "edit": {
          "labels": "Etiketten",
          "title": "Kontakt bearbeiten"
        },
        "form": {
          "add_label": "Etikett hinzufügen",
          "new": "Neuer Ansprechpartner"
        },
        "index": {
          "title": "Kontaktpersonen"
        },
        "label": {
          "remove": "Löschen"
        },
        "list": {
          "account_email": "Email",
          "candidates": "Teilnehmers",
          "client": "Auftraggeber",
          "created_at": "Gemacht um",
          "labels": "Labels",
          "list_text": "Diese listet alle Kontaktpersonen innerhalb Ihrer Organisation auf",
          "name": "Name",
          "organization": "Organisation",
          "privacy_accepted_at": "Datenschutz akzeptiert",
          "status": "Status",
          "two_factor": "2-Faktor-Authentifizierung",
          "unconfirmed_email": "Unbestätigte E-Mail",
          "updated_at": "Aktualisiert am"
        },
        "new": {
          "title": "Neuer Kontaktperson",
          "title_with_client": "Neuer Kontaktperson für %{client}"
        },
        "reactivate": {
          "reactivated": "Der Kontaktperson wurde reaktiviert"
        },
        "send_welcome": {
          "welcome_mail_sent": "Die Willkommensmail ist verschickt"
        },
        "show": {
          "client": "Auftraggeber",
          "email": "E-Mail-Adresse",
          "email_address": "E-Mail-Adresse",
          "status": "Status"
        },
        "update": {
          "updated": "Der Ansprechpartner wurde aktualisiert"
        }
      },
      "contact_persons_labels": {
        "destroy": {
          "removed": "Das Label wurde erfolgreich vom Kontakt entfernt"
        },
        "form": {
          "new": "Neues Label"
        }
      },
      "dashboards": {
        "show": {
          "title": "Armaturenbrett"
        }
      },
      "enrollers": {
        "add": "Registranten hinzufügen",
        "create": {
          "created": "Der Link zum Einschreiben ist erstellt",
          "created_with_premium_components": {
            "one": "Der Link zum Einschreiben ist erstellt (%{costs} pro Einschreibung wegen 1 Elements mit Mehrkosten)",
            "other": "Der Link zum Einschreiben ist erstellt (%{costs} pro Einschreibung wegen %{count} Elementen mit Mehrkosten)"
          },
          "title": "Neuer Link zum Einschreiben"
        },
        "created": "Der Registrierungslink wurde erstellt",
        "created_with_premium_components": {
          "one": "Der Registrierungslink wurde erstellt (%{costs} pro Registrierung aufgrund 1 Prämienelement)",
          "other": "Der Registrierungslink wurde erstellt (%{costs} pro Registrierung aufgrund von %{count} Premium-Elementen)"
        },
        "deactivate": {
          "deactivated": "Der Link zum Einschreiben ist deaktiviert"
        },
        "destroy": {
          "deleted": "The enrolment link has been deleted",
          "destroy_confirm": "Sind Sie sicher, dass Sie diesen Registrierungslink löschen möchten?"
        },
        "edit": {
          "explanation": "Mithilfe des obenstehenden Links können sich Teilnehmer für den angegebenen Prozess einschreiben. Den Link können Sie beispielsweise per E-Mail verbreiten oder auf Ihre Webseite setzen. Beachten Sie dabei, dass jeder sich einschreiben kann, wenn Sie den Link öffentlich zugänglich machen.",
          "link": "Link",
          "title": "Link zum Einschreiben bearbeiten"
        },
        "form": {
          "help_texts": {
            "advisor": "Der Coach wird den Teilnehmer nach der Anmeldung durch den Prozess begleiten.",
            "show_default": "Standardmäßig wird ein kurzer Text angezeigt, der Ihnen erläutert, wie Sie sich anmelden können. Alternativ können Sie in die Prozessbeschreibung einen eigenen Text aufnehmen.",
            "url": "Link zum Einschreiben",
            "url_explanation": "Mithilfe des obenstehenden Links können sich Teilnehmer für den angegebenen Prozess einschreiben. Den Link können Sie beispielsweise per E-Mail verbreiten oder auf Ihre Webseite setzen. Beachten Sie dabei, dass jeder sich einschreiben kann, wenn Sie den Link öffentlich zugänglich machen."
          }
        },
        "index": {
          "actions": "Handlungen",
          "add": "Neuer Link zum Einschreiben",
          "coach": "Coach",
          "enrolments": "Einschreibungen",
          "list_of_enrollers": "Hier finden Sie alle Links zum Einschreiben für Ihre Organisation",
          "process": "Prozess",
          "title": "Links zum Einschreiben",
          "url": "Link zum Einschreiben"
        },
        "new": {
          "title": "Neuer Link zum Einschreiben"
        },
        "reactivate": {
          "reactivated": "Der Link zum Einschreiben ist wieder aktiviert"
        },
        "show": {
          "coach": "Coach",
          "created_at": "Datum",
          "enrolments": "Einschreibungen",
          "process": "Prozess",
          "status": "Status",
          "title": "Links zum Einschreiben",
          "url": "Link zum Einschreiben"
        },
        "update": {
          "title": "Link zum Einschreiben bearbeiten",
          "updated": "Der Link zum Einschreiben ist aktualisiert"
        }
      },
      "enrollments": {
        "create": {
          "cannot_enroll": "Sie können in Moment diesen Prozess nicht zuweisen"
        },
        "destroy": {
          "cannot_destroy": "Sie können es nicht löschen, da es bereits gestartet ist.",
          "enrollment_destroyed": "Prozess gelöscht"
        },
        "form": {
          "add": "Ansprechpartner hinzufügen"
        },
        "invite": {
          "cannot_invite": "Sie können nicht einladen, da die Registrierung den Status „Ungültig“ hat."
        },
        "new": {
          "contact_persons": "Kontakte"
        }
      },
      "external_reports": {
        "create": {
          "error": "Der Bericht kann nicht generiert werden",
          "success": "Der Bericht wird generiert."
        }
      },
      "integrations": {
        "show": {
          "api_status": {
            "incorrect": "Derzeit gibt es technische Probleme, die die Zuordnung von Beurteilungen zu Kandidaten verhindern. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an den Support.",
            "new": "Derzeit können keine Bewertungen Kandidaten innerhalb der Organisation zugewiesen werden. Bitte wenden Sie sich an den Support, um diese Funktion verfügbar zu machen."
          }
        }
      },
      "label_multiples": {
        "create": {
          "send": "%{number}-Kandidaten wurden mit Etiketten versehen"
        },
        "index": {
          "title": "Fügen Sie Etiketten hinzu"
        }
      },
      "labels": {
        "create": {
          "label_created": "Etikett wurde erstellt"
        },
        "destroy": {
          "removed": "Etikett erfolgreich entfernt"
        },
        "edit": {
          "title": "Beschriftung anpassen"
        },
        "form": {
          "new": "Neues Label"
        },
        "index": {
          "help_text": "Hier finden Sie alle Ihre Etiketten, die Sie innerhalb der Anwendung verwenden können",
          "title": "Labels"
        },
        "list": {
          "advisors": "Berater",
          "candidates": "Kandidaten",
          "contact_persons": "Kontaktpersonen",
          "name": "Name",
          "programs": "Prozesse",
          "updated_at": "Zuletzt bearbeitet"
        },
        "new": {
          "title": "Neues Label"
        },
        "update": {
          "label_updated": "Etikett wurde aktualisiert"
        }
      },
      "mail_multiples": {
        "create": {
          "send": {
            "other": "E-Mail an %{count} Kandidaten gesendet"
          }
        },
        "index": {
          "title": "Senden Sie eine E-Mail"
        }
      },
      "multiple_bulk_assignments": {
        "create": {
          "candidates_invited": "Die Teilnehmer wurden per E-Mail über die neu zugewiesenen Prozesse informiert",
          "cannot_enroll": "Sie können diesen Prozess derzeit nicht zuordnen",
          "processes_created": "Die Prozesse sind zugeordnet. Die Teilnehmer wurden noch nicht über den neu zugewiesenen Prozess informiert."
        },
        "new": {
          "title": "Prozesse den Kandidaten zuweisen: %{names}"
        }
      },
      "multiple_talent_solution_assignments": {
        "create": {
          "cannot_enroll": "Der zugewiesene Prozess wurde bereits allen Kandidaten zugewiesen."
        }
      },
      "news": {
        "index": {
          "title": "Nachricht"
        },
        "show": {
          "title": "Nachricht"
        }
      },
      "norm_groups": {
        "advice": "Beratung",
        "selection": "Auswahl"
      },
      "organization_tasks": {
        "index": {
          "title": "Standardberichte"
        },
        "list": {
          "credits": "Credits",
          "default_report": "Standardauswertung",
          "help_text": "Hier können Sie den Standardauswertung für jedes verfügbare Assessment festlegen",
          "key": "Key",
          "language": "Sprache",
          "module": "Modul",
          "name": "Name",
          "price": "Preis"
        },
        "task": {
          "de": "Deutsch",
          "en": "Englisch",
          "nl": "Niederländisch"
        }
      },
      "program_templates": {
        "destroy": {
          "destroyed": "Der Prozess „%{program_title}“ wurde gelöscht"
        },
        "duplicate": {
          "duplicate_prefix": "Kopieren:",
          "duplicate_suffix": "[Kopieren]",
          "program_duplicated": "Der Prozess „%{program_title}“ wurde kopiert",
          "template_duplicated": "Der Prozess „%{program_title}“ wurde in die eigenen Prozesse Ihrer Organisation kopiert"
        },
        "index": {
          "list": "Liste der entworfenen Organisationsvorlagenprozesse",
          "title": "Prozessvorlagen"
        },
        "list": {
          "actions": "Aktionen",
          "assigned": "Verbunden mit",
          "date": "Angepasst an",
          "labels": "Etikett",
          "length": "Schritte",
          "status": "Status",
          "title": "Titel",
          "type": "Typ"
        },
        "new": "Prozess hinzufügen",
        "program": {
          "candidates": "Kandidaten",
          "delete_confirm": "Sind Sie sicher, dass Sie diesen Prozess löschen möchten?",
          "steps": "Schritte",
          "type": {
            "program": "Eigener Prozess",
            "program_template": "Vorlage"
          }
        },
        "publish": {
          "all_pages_invisible": "Sie können diesen Prozess nicht veröffentlichen, da keiner der Schritte so eingestellt ist, dass er für den Teilnehmer sichtbar ist",
          "published": "Der Prozess „%{program_title}“ wurde veröffentlicht"
        },
        "publish_as_template": {
          "published_as_template": "Als Vorlage wurde der Prozess „%{program_title}“ veröffentlicht"
        },
        "update": {
          "update_failed": "Der Prozess konnte nicht aktualisiert werden",
          "updated": "Der Prozess wurde aktualisiert"
        },
        "withdraw": {
          "withdrawn": "Der Prozess „%{program_title}“ wurde eingestellt"
        }
      },
      "programs": {
        "destroy": {
          "destroyed": "Der Prozess „%{program_title}“ wurde gelöscht"
        },
        "duplicate": {
          "duplicate_prefix": "Kopieren:",
          "duplicate_suffix": "[Kopieren]",
          "program_duplicated": "Der Prozess „%{program_title}“ wurde kopiert",
          "template_duplicated": "Der Prozess „%{program_title}“ wurde in die eigenen Prozesse Ihrer Organisation kopiert"
        },
        "index": {
          "list": "Entwerfen, bearbeiten oder sehen Sie die Prozesse Ihrer Organisation. Prozesse stehen für Berater mit der Coach-Rolle und Premium-Kandidaten zur Verfügung.",
          "title": "Prozesse entwickeln"
        },
        "list": {
          "actions": "Aktionen",
          "assigned": "Verbunden mit",
          "date": "Angepasst an",
          "labels": "Etikett",
          "length": "Schritte",
          "status": "Status",
          "title": "Titel",
          "type": "Typ"
        },
        "new": "Prozess hinzufügen",
        "program": {
          "candidates": "Kandidaten",
          "delete_confirm": "Sind Sie sicher, dass Sie diesen Prozess löschen möchten?",
          "steps": "Schritte",
          "type": {
            "program": "Eigener Prozess",
            "program_template": "Vorlage"
          }
        },
        "publish": {
          "all_pages_invisible": "Sie können diesen Prozess nicht veröffentlichen, da keiner der Schritte so eingestellt ist, dass er für den Teilnehmer sichtbar ist",
          "published": "Der Prozess „%{program_title}“ wurde veröffentlicht"
        },
        "publish_as_template": {
          "published_as_template": "Als Vorlage wurde der Prozess „%{program_title}“ veröffentlicht"
        },
        "update": {
          "update_failed": "Der Prozess konnte nicht aktualisiert werden",
          "updated": "Der Prozess wurde aktualisiert"
        },
        "withdraw": {
          "withdrawn": "Der Prozess „%{program_title}“ wurde eingestellt"
        }
      },
      "rooms": {
        "add": "Videoanruf hinzufügen",
        "create": {
          "created": "Das Videogespräch ist erstellt",
          "title": "Neues Videogespräch"
        },
        "destroy": {
          "deleted": "Das Videogespräch ist gelöscht"
        },
        "edit": {
          "title": "Videogespräch bearbeiten"
        },
        "form": {
          "help_texts": {
            "end_to_end_encryption": "Diese zusätzliche Sicherheitsebene funktioniert nur bei neueren Browsern, die Insertable Streams unterstützen. Dadurch wird die Teilnahme über die meisten Browser verhindert."
          }
        },
        "index": {
          "title": "Videogespräche"
        },
        "list": {
          "actions": "Handlungen",
          "help_text": "Hier finden Sie alle Videogespräche Ihrer Organisation",
          "participants": "Teilnehmer",
          "subject": "Betreff",
          "updated_at": "Letzte Aktivität"
        },
        "new": {
          "title": "Neues Videogespräch"
        },
        "room": {
          "participants_present": "%{number} anwesend"
        },
        "update": {
          "title": "Videogespräch bearbeiten",
          "updated": "Das Videogespräch ist aktualisiert"
        }
      },
      "shared": {
        "assessment_program_card": {
          "assessment_program_subtasks": "Dieses Bewertungsprogramm kombiniert die %{subtasks}-Bewertungen."
        },
        "candidate_form": {
          "add_contact_person": "Ansprechpartner hinzufügen",
          "add_label": "Etikett hinzufügen",
          "help_texts": {
            "advisor": "Der Berater kann dem Kandidaten Programme zuweisen und den Kandidaten in diesen Prozessen begleiten.",
            "coadvisor": "Ein Co-Berater ist ein zusätzlicher Berater, der die Prozesse des Kandidaten einsehen kann.",
            "contact_person": "Eine Kontaktperson ist ein Stakeholder im Prozess eines Kandidaten. Sie können den Fortschritt eines Kandidaten einsehen, Dokumente mit dem Kandidaten und seinen Beratern teilen und um die Genehmigung von Dokumenten gebeten werden",
            "intro": {
              "text": "Wählen Sie „Speichern“ und der Kandidat wird noch nicht zur Arbeit auf der Plattform eingeladen, es wird keine E-Mail gesendet. Wählen Sie „Weiter“ und Sie können den Kandidaten einladen, ein Konto zu erstellen und an einem Assessment teilzunehmen.",
              "title": "Kandidaten hinzufügen"
            },
            "outro": {
              "text": "Wählen Sie „Speichern“, um einen Kandidaten später einzuladen. Der Kandidat erhält noch keine E-Mail. Wählen Sie „Weiter“, um einen Kandidaten direkt einzuladen und sofort eine Bewertung zuzuweisen.",
              "title": "Bewertung zuweisen"
            },
            "report_objective": "Wenn Sie Kandidaten beurteilen, um sie bei ihrer Entwicklung zu unterstützen, wählen Sie „Beratung“; Wenn Sie Kandidaten bewerten, um unter ihnen auszuwählen, wählen Sie „Auswahl“. Basierend auf diesem Ziel wählen wir automatisch den relevantesten Bericht für diesen Kandidaten aus."
          },
          "primary_title": "Primärdaten"
        },
        "mail_and_settings_form": {
          "invitation_mail_title": "Einladungsmail festlegen",
          "report_settings_explanation": "Wenn ein Kandidat ein Assessment abschließt, wird automatisch ein Bericht generiert. Basierend auf den Einstellungen Ihrer Organisation und dem Berichtsziel Ihres Kandidaten haben wir bereits einen geeigneten Bericht für Sie ausgewählt.",
          "report_settings_title": "Berichtseinstellungen"
        },
        "selected_candidates": "Ausgewählte Kandidaten"
      },
      "stitched_reports": {
        "create": {
          "success": "Der zusammengeführte Bericht wird generiert. Sie erhalten eine Benachrichtigung, wenn der Bericht in Ihren Dokumenten und Medien verfügbar ist."
        },
        "new": {
          "stitch_button": "Kombinieren",
          "title": "Berichte für %{candidate} zusammenführen"
        }
      },
      "whitelabel": {
        "edit": {
          "title": "White-Label-Einstellungen"
        },
        "update": {
          "whitelabel_updated": "Das Whitelabel wurde aktualisiert"
        }
      }
    },
    "authentication_actions": {
      "authenticate": "%{actor} hat sich erfolgreich angemeldet.",
      "impersonate": "%{actor} hat sich als %{target} angemeldet",
      "unknown": "Unbekannte Aktion: %{action}"
    },
    "boolean": {
      "yes": "Ja"
    },
    "builder": {
      "component": {
        "component_delete_confirm": "Sind Sie sicher, dass Sie dieses Element wirklich löschen wollen?",
        "settings": "Elementeinstellungen"
      },
      "elements": {
        "advisor_introduction": "Vorstellung Coach",
        "advisor_introduction_tooltip": "Dies ermöglicht es dem Coach, sich dem Kandidaten vorzustellen",
        "amc": "AMC",
        "amc_tooltip": "Mit dem AMC kann der Berater die Einschränkungen des Kandidaten erkennen und Einblick in Karrieremöglichkeiten gewinnen",
        "appointment": "Terminplaner",
        "appointment_tooltip": "Dies ermöglicht es dem Coach und dem Kandidaten, ein (physisches) Treffen zu vereinbaren",
        "blog": "Blog",
        "blog_tooltip": "Blog mit allen mit dem Kandidaten geteilten Artikeln",
        "bov": "BOV (Niederländisch)",
        "bov_tooltip": "Das BOV ermöglicht es dem Kandidaten, eine Berufsdatenbank zu durchsuchen",
        "checkboxes": "Kontrollkästchen",
        "checkboxes_tooltip": "Anzahl der Optionen, von denen mehrere selektiert werden können",
        "consent": "Zustimmung",
        "consent_tooltip": "Ja-Nein-Frage, die der Kandidat beantworten muss, um fortzufahren",
        "custom_html": "Custom-HTML",
        "custom_html_tooltip": "Eigenen HTML-Code eingeben",
        "date": "Datumseingabe",
        "date_tooltip": "Ein Eingabefeld für das Datum",
        "document_approval": "Dokument-Zustimmung",
        "document_approval_tooltip": "Dadurch kann der Coach eine Datei hochladen, die der Kandidat oder die Kontaktperson freigeben kann",
        "document_download": "Dokument Download",
        "document_download_tooltip": "Dadurch kann der Coach Dateien hochladen, die der Kandidat herunterladen kann",
        "document_upload": "Dokument Upload",
        "document_upload_tooltip": "Dadurch kann der Kandidat Dateien hochladen, die der Coach herunterladen kann",
        "docx_template": "Lebenslauf & Brief Builder (Word)",
        "docx_template_tooltip": "Dadurch kann eine DOCX-Dokumentvorlage hochgeladen werden, die der Kandidat mit persönlichen Informationen füllen kann",
        "fit": "FIT (Niederländisch)",
        "fit_tooltip": "Mit dem FIT kann der Kandidat seine Grenzen erkennen und sich an Karrieremöglichkeiten orientieren",
        "image": "Abbildung",
        "image_tooltip": "Abbildung zum Anschauen",
        "input_text": "Texteingabe (kurz)",
        "input_text_tooltip": "Ein einzeiliges Eingabefeld, das vom Kandidaten auszufüllen ist",
        "ixly_test": "Assessment von Ixly",
        "ixly_test_tooltip": "Dadurch kann der Kandidat einen Test oder Fragebogen ausfüllen und anschließend einen Bericht herunterladen",
        "job_market_scan": "Arbeitsmarktchancen (Niederländisch)",
        "job_market_scan_en": "Arbeitsmarktchancen (Englisch)",
        "job_market_scan_en_tooltip": "Mit Arbeitsmarktchancen kann der Kandidat seine Chancen auf dem Arbeitsmarkt berechnen",
        "job_market_scan_tooltip": "Mit Arbeitsmarktchancen kann der Kandidat seine Chancen auf dem Arbeitsmarkt berechnen",
        "job_suggestions": "Arbeitsvorschläge (Niederländisch)",
        "job_suggestions_plus": "Arbeitsvorschläge Plus (Niederländisch)",
        "job_suggestions_plus_tooltip": "Mit Job Suggestions Plus kann der Kandidat passende Jobs sowie passende Ausbildungs- und Stellenangebote entdecken",
        "job_suggestions_tooltip": "Mit Jobvorschlägen kann der Kandidat passende Jobs entdecken",
        "jobfeed": "Jobfeed (Niederländisch)",
        "jobfeed_tooltip": "Mit Jobfeed kann der Kandidat alle offenen Stellen in den Niederlanden durchsuchen",
        "logbook": "Logbuch",
        "logbook_tooltip": "Dadurch kann der Kandidat Notizen machen",
        "no_premium_components_available_yet": "(im Moment stehen keine Elemente mit Mehrkosten zur Verfügung)",
        "pdf_template": "Lebenslauf & Brief Builder",
        "pdf_template_tooltip": "Dadurch können Vorlagen für PDF-Dokumente erstellt werden, die der Kandidat mit persönlichen Informationen füllen kann",
        "premium": "Mehrkosten",
        "premium_costs_are_per_assignment": "(obenstehende Preise fallen pro Zuweisung des Prozesses an und sind exklusive %{vat} % MwSt.)",
        "quiz": "Quiz",
        "quiz_tooltip": "Dadurch kann der Kandidat Fragen beantworten (offen oder Multiple-Choice) und anschließend seine Antworten überprüfen",
        "radio_buttons": "Optionsfeld",
        "radio_buttons_tooltip": "Eine Anzahl von Optionen, von denen man eine einzige selektieren kann (Radiobutton)",
        "search_job": "Indeed Stellensucher",
        "search_job_tooltip": "Integrierter Stellensucher für die Indeed-Datenbank",
        "selectbox": "Dropdown-Liste",
        "selectbox_tooltip": "Eine Anzahl von Optionen, von denen man eine einzige selektieren kann (bei Klicken erscheinen die Optionen in der Dropdown-Liste)",
        "standard": "Standard",
        "text": "Lesetext",
        "text_tooltip": "Text zum Lesen",
        "textarea": "Texteingabe (lang)",
        "textarea_tooltip": "Ein mehrzeiliges Eingabefeld, das vom Kandidaten auszufüllen ist",
        "title": "Elemente",
        "vacancy": "Stellenmanager",
        "vacancy_tooltip": "Dies ermöglicht es dem Kandidaten, den Überblick über interessante Stellenangebote und deren Entwicklung zu behalten",
        "youtube_video": "YouTube-Video",
        "youtube_video_tooltip": "YouTube-Video zum Anschauen"
      },
      "menu_item_page": {
        "page_delete_confirm": "Sind Sie sicher, dass Sie diesen Schritt mit all seinen Elementen wirklich löschen wollen?",
        "settings": "Schritteinstellungen"
      },
      "sidebar": {
        "new_page": "Neuer Schritt",
        "settings": "Prozesseinstellungen"
      },
      "top_steps": {
        "placeholder": "Name Schrittgruppe ..."
      }
    },
    "button": {
      "add": "Hinzufügen",
      "add_migration_organization": "TTK-Organisation migrieren",
      "back": "Zurück",
      "cancel": "Abbrechen",
      "click_to_close_modal": "(hier klicken, um dieses Fenster zu schließen)",
      "close": "Schließen",
      "complete_setup": "Einladung versenden",
      "copy": "Kopieren",
      "copy_token_authentication_link": "Kopieren Sie den Link für die einmalige passwortlose Anmeldung",
      "deactivate": "Deaktivieren",
      "delete": "Löschen",
      "download_csv": "Als CSV-Datei herunterladen",
      "duplicate": "Duplikat",
      "edit": "Bearbeiten",
      "export": "Export",
      "filter": "Bewerben",
      "inspect": "Inspiziere",
      "invite": "Einladen",
      "join": "Teilnehmen",
      "mark_all_as_read": "Als gelesen markieren",
      "mark_as_template": "Als Vorlage markieren",
      "next": "Weiter",
      "publish": "Veröffentlichen",
      "publish_as_template": "Als Vorlage veröffentlichen",
      "reactivate": "Wieder aktivieren",
      "refresh": "Erneut laden",
      "reset": "Zurücksetzen",
      "reset_password": "Senden Sie eine E-Mail zum Zurücksetzen des Passworts",
      "select": "Selektieren",
      "send": "Lenken",
      "skip": "Zu überspringen",
      "start": "Loslegen",
      "test_results": "Ergebnisse",
      "unmark_as_template": "Markierung als Vorlage aufheben",
      "verify": "Verifizieren",
      "view": "Ansehen",
      "withdraw": "Zurückziehen"
    },
    "candidate": {
      "assessments": {
        "advisor_action": "Auf der Grundlage dieser Einschätzung muss der Berater Maßnahmen ergreifen",
        "finished_at": "Gerundet auf",
        "started_at": "Begann am"
      },
      "assignments": {
        "active_count": "%{number} begonnene Zuweisung(en)",
        "finished_count": "%{number} abgeschlossene Zuweisung(en)",
        "not_started_count": "%{number} nicht begonnene Zuweisung(en)"
      },
      "created": {
        "empty_list": "Es sind keine neuen Aufgaben bereit"
      },
      "enrollment": {
        "deadline": "Dieser Vorgang muss spätestens am %{date} abgeschlossen sein.",
        "download_alt_text": "Bericht herunterladen",
        "enrolment_link_alt_text": "Öffnen Sie %{title}",
        "enrolment_link_disabled_alt_text": "%{title} nicht verfügbar – Frist abgelaufen",
        "finished_at": "Abgeschlossen am",
        "invite_alt_text": "Kandidaten für %{title} einladen",
        "invited_at": "Der Kandidat wird am %{date} zu diesem Prozess eingeladen.",
        "not_invited": "Der Kandidat wurde zu diesem Verfahren noch nicht eingeladen.",
        "not_started": "Noch nicht angefangen",
        "started_at": "Begann am"
      },
      "enrollments": {
        "finished": "gerundet",
        "not_started": "noch nicht angefangen",
        "started": "gestartet"
      },
      "finished": {
        "empty_list": "Es sind noch keine erledigten Aufgaben vorhanden"
      },
      "started": {
        "empty_list": "Es gibt keine gestarteten Aufgaben"
      },
      "title": "Meine Projekte",
      "unknown_initials": "n / A."
    },
    "candidates": {
      "advisors": {
        "show": {
          "coach_role": "Trainer",
          "email": "Email",
          "role": "Rolle"
        }
      },
      "confirmations": {
        "advisors": {
          "show": {
            "coach_role": "Trainer",
            "email": "Email",
            "role": "Rolle"
          }
        },
        "contact_people": {
          "show": {
            "client": "Klient",
            "contact_person_role": "Kontakt",
            "email": "Email",
            "role": "Rolle"
          }
        },
        "show": {
          "title": "ein Konto erstellen"
        }
      },
      "contact_people": {
        "show": {
          "client": "Klient",
          "contact_person_role": "Kontakt",
          "email": "Email",
          "role": "Rolle"
        }
      },
      "external_reports": {
        "create": {
          "error": "Der Bericht kann nicht generiert werden",
          "success": "Der Bericht wird generiert."
        }
      },
      "results": {
        "index": {
          "title": "Persönliche Ergebnisübersicht"
        }
      },
      "send_mail": {
        "blank_email": "E-Mail kann nicht gesendet werden, der Kandidat hat keine E-Mail-Adresse",
        "inactive_candidate": "E-Mail kann nicht gesendet werden, Kandidat ist inaktiv",
        "message": "E-Mail senden",
        "resend_welcome": "Willkommens-E-Mail erneut senden",
        "send_welcome": "Willkommens-E-Mail senden"
      },
      "token_authentications": {
        "new": {
          "invalid": "Der passwortlose Login-Link ist nicht mehr gültig"
        },
        "update": {
          "copied": "Der Link für die einmalige passwortlose Anmeldung wurde kopiert!"
        }
      }
    },
    "categories": {
      "adaptive": "Adaptiver Test",
      "all": "Alle Kategorien",
      "e_assessment": "E-Bewertung",
      "game": "Psychometrisches Spiel",
      "intelligence": "Intelligenz",
      "personality": "Persönlichkeit",
      "sample_tests": "Beispieltests",
      "simulation": "Simulation"
    },
    "clients": {
      "create": {
        "client_created": "Der neue Auftraggeber ist erstellt",
        "help_text": "Erstellen Sie hier einen neuen Auftraggeber für Ihre Organisation",
        "title": "Neuer Auftraggeber"
      },
      "edit": {
        "title": "Auftraggeber bearbeiten"
      },
      "form": {
        "labels": "Labels"
      },
      "index": {
        "help_text": "Hier finden Sie alle Auftraggeber (Betriebe) Ihrer Organisation",
        "new_button": "Neuer Auftraggeber",
        "table_header": [
          "Name",
          "Kontaktpersonen",
          "Kandidat"
        ],
        "title": "Auftraggeber"
      },
      "new": {
        "help_text": "Erstellen Sie hier einen neuen Auftraggeber für Ihre Organisation",
        "title": "Neuer Auftraggeber"
      },
      "row": {
        "number_of_candidates": "%{number} aktiv",
        "number_of_contact_persons": "%{number} aktiv"
      },
      "show": {
        "help_text": "Hier finden Sie alle Ihre Kontakte und Kandidaten zu diesem Kunden",
        "new_button": "Neue Kontaktperson",
        "table_header": [
          "Angaben",
          "E-Mail-Adresse",
          "Status"
        ]
      },
      "update": {
        "client_updated": "Der Auftraggeber ist aktualisiert",
        "title": "Auftraggeber bearbeiten"
      }
    },
    "comments": {
      "index": {
        "title": "Historie Nachrichten"
      }
    },
    "common": {
      "by": "Durch"
    },
    "competencies": {
      "accuracy": "Genauigkeit",
      "ambition": "Ehrgeiz",
      "assertiveness": "Durchsetzungsvermögen",
      "collaboration": "Zusammenarbeit",
      "commitment": "Einsatz",
      "customer_orientation": "Kundenorientierung",
      "decisiveness": "Entschlossenheit",
      "delegation": "Delegieren",
      "employee_coaching": "Coaching von Mitarbeitern",
      "entrepreneurship": "Unternehmerschaft",
      "flexibility": "Flexibilität",
      "group_leadership": "Gruppen verwalten",
      "independence": "Unabhängigkeit",
      "individual_leadership": "Einzelpersonen verwalten",
      "initiative": "Initiative",
      "negotiation": "Verhandeln",
      "organizational_sensitivity": "Organisatorische Sensibilität",
      "performance": "Leistung",
      "perseverance": "Ausdauer",
      "persuasiveness": "Überzeugungskraft",
      "planning_organizing": "Planen und organisieren",
      "progress_monitoring": "Fortschrittskontrolle",
      "quality_focus": "Qualitätsorientierung",
      "relationship_management": "Beziehungsmanagement",
      "results_orientation": "Ergebnisorientierung",
      "sensitivity": "Empfindlichkeit",
      "sociability": "Geselligkeit",
      "stress_resistance": "Stressresistenz",
      "willingness_to_learn": "Lernbereitschaft"
    },
    "components": {
      "iframe": {
        "back": "Zurück zum Prozess"
      },
      "report_visible_for_explanation": "Die Auswertung kann im Nachhinein immer noch für den Anderen sichtbar gemacht werden.",
      "update": {
        "error": "Dieses Element konnte nicht gespeichert werden, weil:"
      }
    },
    "consents": {
      "cannot_share": "Unable to share the report.",
      "confirm_retract": "Sind Sie sicher, dass Sie die Erlaubnis zur Weitergabe der Ergebnisse widerrufen möchten?",
      "consent_retracted": "Einwilligung zurückgezogen",
      "retract_consent": "Einwilligung widerrufen",
      "share_consent": "Ergebnisse teilen",
      "shared_successfully": "Report shared with advisor successfully."
    },
    "contact_people": {
      "enrollments": {
        "list": {
          "advisor": "Trainer",
          "candidate": "Kandidat",
          "coadvisor": "Kakao",
          "contact_person": "Kontakt",
          "pages": "Seiten",
          "status": "Status"
        }
      }
    },
    "current": "Aktuell",
    "datatables": {
      "filtered_from_after_count": "Resultate",
      "filtered_from_before_count": "gefiltert aus",
      "loading": "Bin dabei ...",
      "next": "Weiter",
      "previous": "Zurück",
      "showing_page_after_count": "von",
      "showing_page_before_count": "Seite",
      "zero_records": "Keine Resultate gefunden"
    },
    "date": {
      "abbr_day_names": [
        "So",
        "Mo",
        "Di",
        "Mi",
        "Do",
        "Fr",
        "Sa"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mär",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez"
      ],
      "day_names": [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag"
      ],
      "formats": {
        "date_without_day": "%e. %B %Y",
        "date_without_day_and_year": "%d %b",
        "default": "%d.%m.%Y",
        "long": "%e. %B %Y",
        "short": "%e. %b"
      },
      "month_names": [
        null,
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "etwa eine Stunde",
          "other": "etwa %{count} Stunden"
        },
        "about_x_months": {
          "one": "etwa ein Monat",
          "other": "etwa %{count} Monate"
        },
        "about_x_years": {
          "one": "etwa ein Jahr",
          "other": "etwa %{count} Jahre"
        },
        "almost_x_years": {
          "one": "fast ein Jahr",
          "other": "fast %{count} Jahre"
        },
        "half_a_minute": "eine halbe Minute",
        "less_than_x_minutes": {
          "one": "weniger als eine Minute",
          "other": "weniger als %{count} Minuten"
        },
        "less_than_x_seconds": {
          "one": "weniger als eine Sekunde",
          "other": "weniger als %{count} Sekunden"
        },
        "over_x_years": {
          "one": "mehr als ein Jahr",
          "other": "mehr als %{count} Jahre"
        },
        "x_days": {
          "one": "ein Tag",
          "other": "%{count} Tage"
        },
        "x_minutes": {
          "one": "eine Minute",
          "other": "%{count} Minuten"
        },
        "x_months": {
          "one": "ein Monat",
          "other": "%{count} Monate"
        },
        "x_seconds": {
          "one": "eine Sekunde",
          "other": "%{count} Sekunden"
        },
        "x_years": {
          "one": "ein Jahr",
          "other": "%{count} Jahre"
        }
      },
      "prompts": {
        "day": "Tag",
        "days": "Tage",
        "hour": "Stunde",
        "minute": "Minute",
        "month": "Monat",
        "months": "Monate",
        "second": "Sekunde",
        "year": "Jahr",
        "years": "Jahre"
      }
    },
    "delimit_options_by_commas": "Trennen Sie die verschiedenen Optionen durch ein Komma (z.B.: Apfel, Birne, Banane).",
    "delimit_sections_by_commas": "Trennen Sie die verschiedenen Sektionen durch ein Komma.",
    "devise": {
      "confirmations": {
        "confirmed": "Ihre E-Mail-Adresse wurde erfolgreich bestätigt.",
        "new": {
          "resend_confirmation_instructions": "Bestätigungsanleitung erneut senden"
        },
        "send_instructions": "In wenigen Minuten erhalten Sie eine E-Mail zur Bestätigung Ihrer Registrierung.",
        "send_paranoid_instructions": "Falls Ihre E-Mail-Adresse in unserer Datenbank existiert, erhalten Sie in wenigen Minuten eine E-Mail zur Bestätigung Ihrer Registrierung."
      },
      "failure": {
        "already_authenticated": "Sie sind bereits angemeldet.",
        "inactive": "Ihr Konto ist noch nicht aktiv.",
        "invalid": "Mit der angegebenen E-Mail-/Passwort-Kombination wurde kein Konto gefunden",
        "last_attempt": "Sie haben noch einen Versuch, bevor Ihr Konto gesperrt wird.",
        "locked": "Ihr Konto ist gesperrt.",
        "not_found_in_database": "Mit der angegebenen E-Mail-/Passwort-Kombination wurde kein Konto gefunden",
        "organization_inactive": "Sie können sich nicht anmelden, da die Organisation, der Sie angehören, nicht aktiv ist",
        "timeout": "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.",
        "unauthenticated": "Sie müssen sich anmelden, bevor Sie fortfahren können",
        "unconfirmed": "Sie müssen Ihr Konto bestätigen, bevor Sie fortfahren können.",
        "user_status_created": "Ihr Konto ist noch nicht aktiv",
        "user_status_inactive": "Ihr Konto ist nicht aktiv",
        "user_status_invited": "Sie können sich noch nicht anmelden. Verwenden Sie die Einladungs-E-Mail, um ein Passwort zu erstellen"
      },
      "mailer": {
        "confirmation_instructions": {
          "action": "Mein Konto bestätigen",
          "greeting": "Willkommen %{recipient}!",
          "instruction": "Klicken Sie auf den folgenden Link, um Ihr Konto zu bestätigen:",
          "subject": "Anleitung zur Konto-Bestätigung"
        },
        "email_changed": {
          "greeting": "Hallo %{recipient}!",
          "message": "Wir möchten Sie informieren, dass Ihre E-Mail-Adresse zu %{email} geändert wurde.",
          "message_unconfirmed": "Wir möchten Sie informieren, dass Ihre E-Mail-Adresse zu %{email} geändert wird.",
          "subject": "E-Mail-Adresse geändert"
        },
        "password_change": {
          "greeting": "Hallo %{recipient}!",
          "message": "Wir möchten Sie darüber informieren, dass Ihr Passwort geändert wurde.",
          "subject": "Passwortänderung"
        },
        "reset_password_instructions": {
          "action": "Passwort ändern",
          "greeting": "Hallo %{recipient}!",
          "instruction": "Jemand hat einen Link zum Ändern Ihres Passworts angefordert. Klicken Sie auf den folgenden Link, um Ihr Passwort zu ändern.",
          "instruction_2": "Ignorieren Sie diese E-Mail, wenn Sie kein neues Passwort angefordert haben.",
          "instruction_3": "Ihr Passwort wird erst geändert, wenn Sie den obigen Link nutzen und ein neues Passwort festlegen.",
          "subject": "Anweisungen zum Zurücksetzen Ihres Passworts"
        },
        "unlock_instructions": {
          "action": "Mein Konto entsperren",
          "greeting": "Hallo %{recipient}!",
          "instruction": "Klicken Sie auf den folgenden Link, um Ihr Konto zu entsperren:",
          "message": "Aufgrund zahlreicher fehlgeschlagener Anmeldeversuche wurde Ihr Konto gesperrt.",
          "subject": "Anweisungen zur Entsperrung Ihres Kontos"
        }
      },
      "omniauth_callbacks": {
        "failure": "Die Anmeldung mit Ihrem %{kind}-Konto war aufgrund des folgenden Grundes nicht möglich: \"%{reason}\".",
        "success": "Sie haben sich erfolgreich über Ihr %{kind}-Konto angemeldet."
      },
      "passwords": {
        "edit": {
          "change_my_password": "Mein Passwort ändern",
          "change_your_password": "Ihr Passwort ändern",
          "confirm_new_password": "Neues Passwort bestätigen",
          "new_password": "Neues Passwort"
        },
        "new": {
          "forgot_your_password": "Haben Sie Ihr Passwort vergessen?",
          "send_me_reset_password_instructions": "Anweisungen zum Zurücksetzen meines Passworts senden"
        },
        "no_token": "Sie können diese Seite nicht nutzen, es sei denn, Sie stammen von einer Passwort-Zurücksetzen-E-Mail. Wenn dies der Fall ist, stellen Sie sicher, dass Sie die vollständige URL verwendet haben.",
        "send_instructions": "Sie erhalten in Kürze eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts.",
        "send_paranoid_instructions": "Wenn Ihre E-Mail-Adresse in unserer Datenbank existiert, erhalten Sie in Kürze eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts.",
        "updated": "Ihr Passwort wurde geändert. Sie sind jetzt angemeldet.",
        "updated_not_active": "Ihr Passwort wurde erfolgreich geändert."
      },
      "registrations": {
        "destroyed": "Ihr Konto wurde gelöscht. Wir hoffen, Sie bald wiederzusehen.",
        "edit": {
          "are_you_sure": "Sind Sie sicher, dass Sie das tun möchten?",
          "cancel_my_account": "Konto löschen",
          "currently_waiting_confirmation_for_email": "Bestätigung für %{email} ausstehend.",
          "leave_blank_if_you_don_t_want_to_change_it": "Leer lassen, wenn Sie es unverändert lassen möchten.",
          "title": "%{resource} bearbeiten",
          "unhappy": "Nicht zufrieden?",
          "update": "Änderungen speichern",
          "we_need_your_current_password_to_confirm_your_changes": "Zur Bestätigung geben Sie bitte Ihr aktuelles Passwort ein."
        },
        "new": {
          "sign_up": "Registrieren"
        },
        "signed_up": "Sie haben sich erfolgreich registriert.",
        "signed_up_but_inactive": "Ihre Registrierung war erfolgreich, Ihr Konto ist jedoch noch nicht aktiviert.",
        "signed_up_but_locked": "Ihre Registierung war erfolgreich, Ihr Konto ist jedoch gesperrt.",
        "signed_up_but_unconfirmed": "Sie erhalten in Kürze eine Bestätigungs-E-Mail. Bitte klicken Sie auf den darin enthaltenen Link, um Ihr Konto zu aktivieren.",
        "update_needs_confirmation": "Sie haben Ihre Daten aktualisiert. Eine Bestätigungs-E-Mail wird Ihnen zugesandt. Bitte klicken Sie auf den Link in der E-Mail, um Ihre Änderungen zu bestätigen.",
        "updated": "Ihre Einstellungen wurden aktualisiert.",
        "updated_but_not_signed_in": "Sie haben Ihre Daten aktualisiert, aber Sie müssen sich wegen einer Passwortänderung erneut anmelden."
      },
      "sessions": {
        "already_signed_out": "Sie sind bereits abgemeldet.",
        "new": {
          "sign_in": "Anmelden"
        },
        "signed_in": "Sie sind nun angemeldet.",
        "signed_out": "Sie haben sich erfolgreich abgemeldet."
      },
      "shared": {
        "links": {
          "back": "Zurück",
          "didn_t_receive_confirmation_instructions": "Keine Bestätigungs-E-Mail erhalten?",
          "didn_t_receive_unlock_instructions": "Keine Anweisungen zum Entsperren erhalten?",
          "forgot_your_password": "Passwort vergessen?",
          "sign_in": "Anmelden",
          "sign_in_with_provider": "Mit %{provider} anmelden",
          "sign_up": "Registrieren"
        },
        "minimum_password_length": {
          "one": "(mindestens %{count} Zeichen)",
          "other": "(mindestens %{count} Zeichen)"
        }
      },
      "sign_out": "Abmelden",
      "two_factor_authentication": {
        "attempt_failed": "Falscher Code.",
        "code_has_been_sent": "Ihr zweistufiger Bestätigungscode wurde gesendet.",
        "contact_administrator": "Wenden Sie sich an einen Organisationsadministrator.",
        "max_login_attempts_reached": "Der Zugriff wurde vollständig verweigert, da Sie die maximale Anzahl an Versuchen erreicht haben",
        "success": "Zwei-Faktor-Authentifizierung bestanden."
      },
      "unlocks": {
        "new": {
          "resend_unlock_instructions": "Anweisungen zum Entsperren erneut senden"
        },
        "send_instructions": "Sie erhalten in Kürze eine E-Mail mit Anweisungen, wie Sie Ihr Konto entsperren können.",
        "send_paranoid_instructions": "Wenn Ihre E-Mail-Adresse in unserer Datenbank verzeichnet ist, erhalten Sie Anweisungen zur Entsperrung Ihres Kontos.",
        "unlocked": "Ihr Konto wurde entsperrt. Bitte melden Sie sich jetzt an."
      }
    },
    "discounts": {
      "create": {
        "created": "Die Rabatte sind erstellt"
      },
      "destroy": {
        "already_started": "Dieser Rabatt wurde bereits gestartet."
      },
      "index": {
        "new_button": "Neue Rabatte",
        "table_header": [
          "Auf",
          "Prozentsatz",
          "Anfangsdatum",
          "Enddatum",
          "Handlungen"
        ],
        "title": "Rabatte"
      },
      "new": {
        "premium_components": "Elemente mit Mehrkosten",
        "subscriptions": "Abonnements",
        "title": "Neue Rabatte für %{organization}"
      },
      "row": {
        "discount_delete": "Sind Sie sicher, dass Sie diesen Rabatt löschen wollen?"
      }
    },
    "education_levels": {
      "academic": "WO",
      "higher_vocational": "HBO",
      "pre_vocational": "VMBO",
      "secondary_vocational": "MBO"
    },
    "education_levels_rnd": {
      "academic": "Heiraten",
      "higher_vocational": "HBO",
      "pre_vocational": "vorberufliche Sekundarschulbildung",
      "secondary_vocational": "MBO"
    },
    "enrollers": {
      "enroll": {
        "cannot_enroll": "Sie können sich im Moment nicht für diesen Prozess einschreiben",
        "enrolled": "Sie nehmen nun an diesem Prozess teil"
      },
      "enroll_existing": {
        "cannot_enroll": "Sie können sich im Moment nicht für diesen Prozess einschreiben",
        "enrolled": "Sie nehmen nun an diesem Prozess teil",
        "enrolling_user_is_a_contact_person": "Als Kontaktperson können Sie selbst nicht an Prozessen teilnehmen",
        "enrolling_user_is_enrollers_advisor": "Als Coach dieses Links zum Einschreiben können Sie selbst nicht an diesem Prozess teilnehmen"
      },
      "enroll_test": {
        "cannot_enroll": "Sie können sich im Moment nicht für diesen Prozess einschreiben"
      },
      "form": {
        "accept": "Ich akzeptiere",
        "and": "und",
        "participate": "Teilnehmen",
        "privacy_label": "die <a class='btn-link' href='%{privacy_link}' target='_blank'>Datenschutzbestimmungen</a>",
        "terms_label": "die <a class='btn-link' href='%{terms_link}' target='_blank'>allgemeinen Geschäftsbedingungen</a>"
      },
      "show": {
        "deactivated": "Dieser Link zum Einschreiben ist nicht mehr verfügbar",
        "sign_in_or_sign_up": "Wenn Sie bereits einen Account haben, können Sie sich <a class='btn-link' href='%{login_link}'>einloggen</a>, um an diesem Prozess teilzunehmen. Falls nicht, müssen Sie sich erst über das untenstehende Formular beim Assessment Platform anmelden."
      },
      "signed_in_form": {
        "already_enrolled": "Sie nehmen bereits an diesem Prozess teil, fahren Sie fort ab <a class='alert-link' href='%{link}'>%{text}</a>",
        "participate": "Teilnehmen",
        "participate_as": "Über diesen Button können Sie direkt an diesem Prozess teilnehmen."
      },
      "test_only_form": {
        "accept": "Ich akzeptiere",
        "and": "und",
        "participate": "Teilnehmen",
        "privacy_label": "die <a class='btn-link' href='%{privacy_link}' target='_blank'>Datenschutzbestimmungen</a>",
        "terms_label": "die <a class='btn-link' href='%{terms_link}' target='_blank'>allgemeinen Geschäftsbedingungen</a>"
      },
      "tests_assigned": {
        "email_sent": "Vielen Dank für Ihre Teilnahme. Sie erhalten eine E-Mail von no-reply@test-toolkit.nl mit einer Anleitung, wie Sie anfangen können."
      }
    },
    "enrollment_pages": {
      "control_panel": {
        "accept_page": "Schritt genehmigen",
        "back_to_tracks_as_advisor": "Zurück zur Kandidatenübersicht",
        "back_to_tracks_as_candidate": "Zurück zu meiner Projektübersicht",
        "comments": "Nachrichten",
        "finish_enrollment": "Prozess abschließen",
        "finish_enrollment_confirm": "Sind Sie sicher, dass Sie den Vorgang abschließen möchten? Ein abgeschlossener Prozess kann vom Kandidaten oder Coach nicht mehr bearbeitet werden.",
        "finish_page": "Schritt abschließen",
        "finish_page_and_next": "Abschließen und weiter",
        "next_page": "Nächster Schritt",
        "next_page_unavailable": "Nächster Schritt noch nicht verfügbar ...",
        "reject_page": "Schritt abweisen",
        "reopen_enrollment": "Prozess neu öffnen",
        "reopen_enrollment_confirm": "Sind Sie sicher, dass Sie den Vorgang erneut öffnen möchten? Der Kandidat kann wieder Änderungen vornehmen.",
        "submit_page": "Schritt einreichen",
        "submit_page_and_next": "Einreichen und weiter"
      },
      "insert_optional": {
        "cannot_enroll": "Die Seite konnte nicht eingefügt werden, da sie eine Bewertung enthält, die derzeit nicht im Abonnement verfügbar ist.",
        "page_inserted": "Der Schritt '%{page_title}' ist eingefügt"
      },
      "show": {
        "insert": "Einfügen",
        "is_optional": "Dieser Schritt ist momentan nicht Teil dieses Prozesses, kann aber optional noch eingefügt werden",
        "original_page_deleted": "Dieser optionale Schritt kann nicht mehr eingefügt werden, weil das Original gelöscht wurde",
        "page_insert_confirm_part_1": "Einfügen ist unwiderruflich",
        "page_insert_confirm_part_2": "Sind Sie sicher, dass Sie diesen Schritt an der angegebenen Stelle im Prozess einfügen wollen?",
        "with_premium_components": {
          "one": "und kostet %{costs} wegen 1 Elements mit Mehrkosten",
          "other": "und kostet %{costs} wegen %{count} Elementen mit Mehrkosten"
        }
      }
    },
    "enrollments": {
      "confirm_withdraw": "Sind Sie sicher, dass Sie diesen Prozess zurückziehen wollen?",
      "create": {
        "candidate_double_invited": "Der Kandidat wurde per E-Mail eingeladen und über den neu zugewiesenen Prozess informiert",
        "candidate_invited": "Der Kandidat wurde per E-Mail über den neu zugewiesenen Prozess informiert",
        "cannot_enroll": "Sie können in Moment diesen Prozess nicht zuweisen",
        "enrollment_created": "Der Klage wurde stattgegeben. Der Kandidat wurde noch nicht benachrichtigt."
      },
      "destroy": {
        "candidate_enrollment_already_started": "Dieser Vorgang kann nicht zurückgezogen werden, da der Vorgang bereits begonnen hat",
        "candidate_enrollment_destroyed": "Der Prozess wurde erfolgreich zurückgezogen"
      },
      "finish": {
        "enrollment_finished": "Der Prozess ist abgeschlossen",
        "finish_enrollment_failed": "Dieser Prozess kann nicht abgeschlossen werden, da er noch nicht begonnen hat oder bereits abgeschlossen ist"
      },
      "navbar_page": {
        "not_available_yet": "Verfügbar nach Vollendung der vorhergehenden Schritte"
      },
      "new": {
        "help_text": "Wählen Sie einen Ihrer veröffentlichten Prozesse aus, um ihn dem Kandidaten zuzuweisen",
        "table_header": [
          "Prozess",
          "Handlungen"
        ],
        "title": "Prozess zuweisen"
      },
      "progress": {
        "done": "abgeschlossen",
        "rejected": "nochmals",
        "submitted": "eingereicht"
      },
      "reopen": {
        "enrollment_reopened": "Der Prozess wurde neu aufgerollt",
        "reopen_enrollment_failed": "Dieser Prozess kann nicht erneut geöffnet werden, da er noch nicht abgeschlossen ist"
      },
      "row": {
        "add": "Zuweisen",
        "enrollment_create_confirm": {
          "one": "Der gewählte Prozess enthält ein Element mit Mehrkosten von %{costs}. Sind Sie sicher, dass Sie den Prozess zuweisen wollen?",
          "other": "Der gewählte Prozess enthält %{count} Elemente mit Mehrkosten von insgesamt %{costs}. Sind Sie sicher, dass Sie den Prozess zuweisen wollen?"
        },
        "invite_later": "Später einladen",
        "view": "Ansehen"
      }
    },
    "errors": {
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "muss akzeptiert werden",
        "advisor_cannot_be_coadvisor": "Die Bestellung eines ausgewählten Beraters als Co-Berater ist nicht möglich",
        "already_confirmed": "Bereits bestätigt. Versuchen Sie, sich anzumelden.",
        "already_generated": "wurde bereits generiert",
        "already_passed": "liegt in der Vergangenheit",
        "aspect_ratio_is_not": "muss ein Bildseitenverhältnis von %{aspect_ratio} haben",
        "aspect_ratio_not_landscape": "muss Querformat sein",
        "aspect_ratio_not_portrait": "muss Hochformat sein",
        "aspect_ratio_not_square": "muss quadratisch sein",
        "aspect_ratio_unknown": "hat ein unbekanntes Bildseitenverhältnis",
        "attachment_size": "Sollte kleiner als 50 MB sein",
        "before_start_date": "muss nach dem Anfangsdatum liegen",
        "blank": "muss ausgefüllt werden",
        "candidate_limit_reached": "Ihr Konto kann nicht aktiviert werden, da die maximale Anzahl aktiver Kandidaten erreicht ist",
        "cannot_downgrade_before_year": "muss mindestens ein Jahr nach dem Anfangsdatum des vorherigen Abonnements liegen",
        "confirmation": "stimmt nicht mit %{attribute} überein",
        "confirmation_period_expired": "Die Bestätigungsfrist von %{period} ist abgelaufen. Bitte erneut anfordern.",
        "contains_unpermitted_variables": "ist ungültig, da es nicht autorisierte Variablen enthält",
        "content_type_blacklist_error": "SVG-Abbildungen werden nicht unterstützt",
        "content_type_invalid": "hat einen ungültigen Dateityp",
        "content_type_is_invalid": "Es werden nur CSV-Dateien unterstützt",
        "content_type_whitelist_error": "Die hochgeladene Datei ist keine Abbildung",
        "create_otp_organization_failed": "Es gab ein Problem bei der Erstellung von der Organisation im Test-Toolkit",
        "current_included": "dürfen sich nicht selbst enthalten",
        "destroy_otp_organization_failed": "Es gab ein Problem bei der Löschung von der Organisation im Test-Toolkit",
        "dimension_height_equal_to": "Bildhöhe muss genau %{length} Pixel sein",
        "dimension_height_greater_than_or_equal_to": "Bildhöhe muss größer oder gleich %{length} Pixel sein",
        "dimension_height_inclusion": "Bildhöhe muss zwischen %{min} und %{max} Pixel liegen",
        "dimension_height_less_than_or_equal_to": "Höhe muss kleiner oder gleich %{length} Pixel sein",
        "dimension_max_inclusion": "muss kleiner oder gleich %{width} x %{height} Pixel sein",
        "dimension_min_inclusion": "muss größer oder gleich %{width} x %{height} Pixel sein",
        "dimension_width_equal_to": "Bildbreite muss genau %{length} Pixel sein",
        "dimension_width_greater_than_or_equal_to": "Bildbreite muss größer oder gleich %{length} Pixel sein",
        "dimension_width_inclusion": "Bildbreite muss zwischen %{min} und %{max} Pixel liegen",
        "dimension_width_less_than_or_equal_to": "Breite muss kleiner oder gleich %{length} Pixel sein",
        "email_invalid": "muss die folgende Struktur haben: Name@Domain.de",
        "empty": "muss ausgefüllt werden",
        "equal_to": "muss genau %{count} sein",
        "even": "muss gerade sein",
        "exclusion": "ist nicht verfügbar",
        "expired": "Der Link ist abgelaufen. Bitte erneut anfordern.",
        "file_size_not_between": "Dateigröße muss zwischen %{min_size} und %{max_size} liegen (aktuelle Dateigröße ist %{file_size})",
        "file_size_not_greater_than": "Dateigröße muss größer als %{min_size} sein (aktuelle Dateigröße ist %{file_size})",
        "file_size_not_greater_than_or_equal_to": "Dateigröße muss größer oder gleich %{min_size} sein (aktuelle Dateigröße ist %{file_size})",
        "file_size_not_less_than": "Dateigröße muss kleiner als %{max_size} sein (aktuelle Dateigröße ist %{file_size})",
        "file_size_not_less_than_or_equal_to": "Dateigröße muss kleiner oder gleich %{max_size} sein (aktuelle Dateigröße ist %{file_size})",
        "free_included": "darf keine kostenlosen Abonnementtypen enthalten",
        "greater_than": "muss größer als %{count} sein",
        "greater_than_or_equal_to": "muss größer oder gleich %{count} sein",
        "image_metadata_missing": "ist kein gültiges Bild",
        "image_not_processable": "ist kein gültiges Bild",
        "inclusion": "ist kein gültiger Wert",
        "incomplete_post_rule": "Die untenstehende Regel zum Teilen ist unvollständig",
        "insufficient_credits": "Der Bericht kann nicht erstellt werden, da nicht genügend Prepaid-Credits vorhanden sind",
        "internet_explorer_error": "Der von Ihnen verwendete Browser (Internet Explorer) wird nicht mehr unterstützt. Bitte verwenden Sie einen anderen Browser.",
        "invalid": "ist nicht gültig",
        "invalid_annual_end_date": "von %{subscription_type} muss ein Vielfaches von %{period_in_months} Monaten nach dem Anfangsdatum sein (- 1 Tag)",
        "invalid_annual_start_date": "von %{subscription_type} muss ein Vielfaches von %{period_in_months} Monaten vor dem Enddatum sein (+ 1 Tag)",
        "invalid_category": "%{value} kategorie ist ungültig",
        "invalid_date_range": "ungültig ist, muss nach dem Einladungsdatum liegen",
        "invalid_otp": "Der eingegebene Code ist falsch",
        "invalid_subscription_type": "darf nicht in %{subscription_type} geändert werden",
        "invalid_vat": "ist keine gültige %{country_adjective} USt-IdNr.",
        "language_not_available": "ist in der Organisation nicht verfügbar",
        "less_than": "muss kleiner als %{count} sein",
        "less_than_or_equal_to": "muss kleiner oder gleich %{count} sein",
        "limit_out_of_range": "Anzahl ist außerhalb des gültigen Bereichs",
        "login_not_allowed": "Ihre Organisation oder Account ist nicht aktiv",
        "maximum_number_of_advisors_reached": "Es ist nicht möglich, einen neuen Coach hinzuzufügen, da die maximale Anzahl der Coaches erreicht wurde",
        "maximum_number_of_candidates_reached": "Das Hinzufügen eines neuen Kandidaten ist nicht möglich, da die maximale Kandidatenanzahl erreicht ist",
        "mini_magick_processing_error": "Manipulation mit MiniMagick fehlgeschlagen",
        "model_invalid": "Gültigkeitsprüfung ist fehlgeschlagen: %{errors}",
        "mollie_error": "Es ist ein Fehler bei unserem Zahlungsanbieter Mollie aufgetreten; bitte versuchen Sie es später noch einmal",
        "more_than_two_decimals": "braucht nicht mehr als 2 Decimalstellen",
        "not_a_number": "ist keine Zahl",
        "not_an_integer": "muss ganzzahlig sein",
        "not_found": "Nicht gefunden.",
        "not_locked": "Nicht gesperrt.",
        "not_saved": {
          "one": "%{resource} konnte wegen eines Fehlers nicht gespeichert werden:",
          "other": "%{count} Fehler verhinderten das Speichern von %{resource}:"
        },
        "odd": "muss ungerade sein",
        "one_pending_subscription": "ist nicht erlaubt, da bereits ein Abonnement geplant ist",
        "organization_address_missing": "Für die Zuweisung eines kostenpflichtigen Abonnements muss die Adresse festgelegt werden",
        "organization_must_be_ixly": "Es ist nicht möglich, einen Ixly-Mitarbeiter außerhalb der Ixly-Organisation zu erstellen",
        "organization_vat_number_missing": "Um ein kostenpflichtiges Abonnement zuzuordnen, muss eine Umsatzsteuer-Identifikationsnummer eingerichtet werden",
        "other_than": "darf nicht gleich %{count} sein",
        "overlaps_existing_dates": "darf sich nicht mit bestehenden Daten überschneiden",
        "present": "darf nicht ausgefüllt werden",
        "required": "muss ausgefüllt werden",
        "restrict_dependent_destroy": {
          "has_many": "Datensatz kann nicht gelöscht werden, da abhängige %{record} existieren.",
          "has_one": "Datensatz kann nicht gelöscht werden, da ein abhängiger %{record}-Datensatz existiert."
        },
        "subscriptions_attached": "kann nicht geändert werden, da es Abonnements mit diesem Typ gibt",
        "taken": "ist bereits vergeben",
        "too_long": {
          "one": "ist zu lang (mehr als %{count} Zeichen)",
          "other": "ist zu lang (mehr als %{count} Zeichen)"
        },
        "too_short": {
          "one": "ist zu kurz (weniger als %{count} Zeichen)",
          "other": "ist zu kurz (weniger als %{count} Zeichen)"
        },
        "url_invalid": "muss die folgende Struktur haben: https://www.example.com/Pfad/zur/Seite",
        "user_limit_reached": "Ihr Account kann nicht aktiviert werden, da die maximale Anzahl Coaches erreicht wurde",
        "vies_down": "Die USt-IdNr. kann nicht überprüft werden: der VIES-Webservice ist nicht verfügbar. Bitte später noch einmal versuchen.",
        "wrong_length": {
          "one": "hat die falsche Länge (muss genau %{count} Zeichen haben)",
          "other": "hat die falsche Länge (muss genau %{count} Zeichen haben)"
        }
      },
      "template": {
        "body": "Bitte überprüfen Sie die folgenden Felder:",
        "header": {
          "one": "Konnte %{model} nicht speichern: ein Fehler.",
          "other": "Konnte %{model} nicht speichern: %{count} Fehler."
        }
      }
    },
    "filterable": {
      "no_results": "keine Ergebnisse",
      "reset": "Filter zurücksetzen",
      "title": "Filter"
    },
    "flash": {
      "try_again": "versuchen Sie es erneut"
    },
    "helpers": {
      "select": {
        "prompt": "Bitte wählen",
        "sr_only": "wählen"
      },
      "submit": {
        "create": "Erstellen",
        "submit": "%{model} speichern",
        "update": "Speichern"
      }
    },
    "i18n": {
      "plural": {
        "keys": [
          "one",
          "other"
        ]
      },
      "transliterate": {
        "rule": {
          "Ä": "Ae",
          "Ö": "Oe",
          "Ü": "Ue",
          "ß": "ss",
          "ä": "ae",
          "é": "e",
          "ö": "oe",
          "ü": "ue",
          "ẞ": "SS"
        }
      }
    },
    "impersonated_as": "Sie sind als %{name} angemeldet.",
    "impersonation_ended": "Sie sind nun wieder als Sie selbst angemeldet.",
    "import": {
      "current_template": "Vorlage",
      "download_template": "Vorlage herunterladen",
      "errors": {
        "duplicate": "Duplizieren"
      },
      "import/candidate_file": {
        "candidate": {
          "account": {
            "email": "E-Mail an %{message}"
          },
          "advisor": "Berater %{message}",
          "language": "Sprache %{message}"
        },
        "file": {
          "headers": "Spaltenüberschriften falsch %{message}"
        }
      },
      "import/organization_file": {
        "file": {
          "headers": "Spaltenüberschriften falsch %{message}",
          "unique": "Organisation „%{organization_name}“ existiert bereits. (api_organization_id: %{api_organization_id}"
        }
      },
      "labels": {
        "description": "Labels",
        "placeholder": "Labels einführen"
      },
      "loading": "Bin dabei ...",
      "processing": "Das Dokument wird verarbeitet. Sie können diese Seite nun schließen.",
      "status": {
        "errors": "Probleme",
        "existed": "Unverändert",
        "invalid": "Ungültig",
        "new": "Neu",
        "previous_data": "War zuvor",
        "updated": "Angepasst"
      },
      "store": "Speichern",
      "title": "CSV Import",
      "upload": "CSV hochladen"
    },
    "info": {
      "error_occured": "Es ist ein Fehler aufgetreten. Bitte leiten Sie diese Fehlermeldung an Ihren Coach oder den Organisationsverwalter weiter.",
      "flagged_elements": "Hervorgehobene Seiten enthalten hervorgehobene Elemente, die eine zusätzliche Einrichtung erfordern, bevor der Kandidat mit dem Prozess beginnen kann.",
      "no_results_found": "keine Ergebnisse gefunden",
      "review_required": "Eine Überprüfung ist erforderlich. Bevor Berichtsoptionen verfügbar werden, muss der Coach das Assessment beurteilen.",
      "review_started": "Schließen Sie die Überprüfung im anderen Tab ab und laden Sie diese Seite kurz darauf erneut.",
      "verification_planned": "Der Kandidat muss der Verifizierungstest starten.",
      "verification_required": "Verifikation erforderlich. Der Coach muss das Assessment überprüfen bevor die Optionen der Auswertung zur Verfügung gestellt werden können.",
      "verification_started": "Der Kandidat muss der Verifizierungstest abschließen, bevor neue Auswertungen heruntergeladen werden können."
    },
    "invoice_kind": {
      "administrative_costs": "Administrative Kosten",
      "change_internal_limit": "Anpassung internes Limit",
      "change_mandate": "Autorisierungszahlung",
      "extra_external_credit": "Erhöhung externes Guthaben",
      "fixed_price": "Abgeschlossene Assessments",
      "frequent_credits": "Credit-Verbrauch",
      "incidental_credits": "Credit-Bündel",
      "initial_authorization": "Initiale Autorisierung",
      "intensive_credits": "Credit-Verbrauch",
      "monthly_bill": "Monatliche Abbuchung",
      "premium_components": "Elemente mit Mehrkosten"
    },
    "invoice_status": {
      "canceled": "abgebrochen",
      "charged_back": "zurückgebucht",
      "closed": "abgebrochen",
      "expired": "abgelaufen",
      "failed": "fehlgeschlagen",
      "open": "offen",
      "paid": "bezahlt",
      "processed": "durchgeführt",
      "refunded": "zurückerstattet",
      "revoked": "abgebrochen",
      "sent": "verschickt",
      "settled": "verrechnet",
      "unconfirmed": "unbestätigt"
    },
    "invoices": {
      "no_longer_unconfirmed": "Dieser Zahlungsauftrag wurde inzwischen schon bestätigt",
      "show": {
        "administrative_costs": "Administrative Kosten",
        "annual_fee": "Regelmäßige Abonnementgebühr",
        "back_to_account": "(klicken Sie hier, um zurück zu Ihren Zahlungen zu kommen)",
        "change_internal_limit_explanation": "Änderungen haben nur dann Einfluss auf die Kosten für den aktuellen Monat, wenn das Limit auf eine diesen Monat noch nicht erreichte Höhe gesetzt wird. Die Differenz mit dem ursprünglichen Limit (%{previous_limit}) wird für den Folgemonat verrechnet, in Proportion zur Anzahl der Tage, die der aktuelle Monat noch hat.",
        "confirm_direct_debit": "Abbuchen von x-%{mandate_string}",
        "confirm_direct_invoice": "Diese Rechnung erhalten",
        "confirm_direct_payment": "Zur Seite für Zahlungen",
        "costs_next_months": "Ab kommenden Monat zu zahlen",
        "costs_now": "Jetzt direkt zu zahlen",
        "credit_charge": "Credits",
        "external_user_spots": "Aktive Kandidaten (Monatspreis)",
        "external_user_spots_partial": {
          "general": "Aktive Kandidaten",
          "one": "Aktive Kandidaten (1 Tag)",
          "other": "Aktive Kandidaten (%{count} Tage)"
        },
        "fill_out_address": "Uns fehlen Adressangaben, die erforderlich sind, um eine korrekte Rechnung für Ihren Kauf zu senden. Bitte überprüfen und korrigieren Sie die untenstehenden Informationen, um einen Kauf abzuschließen.",
        "internal_user_spots": "Aktive Coaches (Monatspreis)",
        "internal_user_spots_partial": {
          "general": "Aktive Coaches",
          "one": "Aktive Coaches (1 Tag)",
          "other": "Aktive Coaches (%{count} Tage)"
        },
        "internal_users": "Plätze für Berater (Monatspreis)",
        "monthly_bill": "Monatliche Rechnung von %{month} %{year}",
        "premium_components": "Zuweisung von Elementen mit Mehrkosten: %{kind}",
        "premium_components_invoice": "Zuweisung von Elementen mit Mehrkosten",
        "status_flash_part_1": "Dieser Zahlungsauftrag wurde erstellt durch",
        "status_flash_part_2": "und ist %{status} am %{updated_at}",
        "system_initiated": "das Assessment Platform",
        "table_header": [
          "Datum",
          "Produkt",
          "Kostenstelle",
          "Stückpreis",
          "Anzahl",
          "Betrag"
        ],
        "terms_and_services": "durch Ihre Zahlung akzeptieren Sie automatisch",
        "terms_and_services_link": "die allgemeinen Geschäftsbedingungen von Ixly",
        "title": "Bestätigen Sie bitte Ihren Auftrag",
        "total_with_vat": "Total inklusive MwSt.",
        "total_without_vat": "Total exklusive MwSt.",
        "vat": "%{vat} % MwSt."
      }
    },
    "ixly": {
      "accounts": {
        "add": "ein Konto erstellen",
        "index": {
          "title": "Alle Konten"
        }
      },
      "advisors": {
        "create": {
          "success": "Berater erstellt!",
          "title": "Neuen Berater hinzufügen"
        },
        "edit": {
          "title": "Berater bearbeiten"
        },
        "new": {
          "title": "Neuen Berater hinzufügen"
        },
        "update": {
          "success": "Berater gewechselt!",
          "title": "Berater bearbeiten"
        }
      },
      "candidates": {
        "index": {
          "title": "Alle Kandidaten"
        },
        "new": {
          "title": "Alle Kandidaten"
        }
      },
      "discounts": {
        "create": {
          "created": "Die Rabatte wurden erstellt",
          "title": "Neuer Rabatt"
        },
        "destroy": {
          "already_started": "Dieser Rabatt hat bereits begonnen.",
          "destroyed": "Rabatt entfernt"
        },
        "edit": {
          "title": "Rabatt bearbeiten"
        },
        "index": {
          "actions": "Aktionen",
          "add": "Rabatt hinzufügen",
          "end_date": "Endtermin",
          "help_text": "Hier sind die Rabattlisten",
          "on": "An",
          "organization": "Organisation",
          "percentage": "Prozentsatz",
          "start_date": "Startdatum",
          "title": "Rabatte",
          "true": "An"
        },
        "new": {
          "title": "Neuer Rabatt"
        },
        "update": {
          "title": "Rabatt bearbeiten",
          "updated": "Der Rabatt wurde aktualisiert"
        }
      },
      "entries": {
        "create": "Freundlichkeit"
      },
      "import_files": {
        "import_file": {
          "delete": "Löschen",
          "delete_confirm": "Möchten Sie diesen Import wirklich löschen?",
          "failed": "Fehlgeschlagen",
          "original": "Original",
          "processed": "Eingetragen"
        },
        "index": {
          "title": "Importierte CSV-Dateien"
        },
        "new": {
          "title": "CSV importieren"
        }
      },
      "integrations": {
        "edit": {
          "title": "Integration Test-Toolkit"
        },
        "form": {
          "api_anonymous_explanation": "Wenn Sie diese Option wählen, werden die Nutzernamen nicht für das Test-Toolkit freigegeben",
          "checking_integration": "Der API-Status wird überprüft"
        },
        "link_testtoolkit": "Sehen Sie sich die verknüpfte Organisation im Test-Toolkit an",
        "show": {
          "api_status": {
            "correct": "The organization has a working connection with the Test-Toolkit API",
            "incorrect": "The organization is missing an access token to connect with the Test-Toolkit API, please contact SD",
            "new": "Neue"
          }
        }
      },
      "ixly_employees": {
        "create": {
          "created": "Ixly-Mitarbeiter hat erfolgreich <a class='underline' href='%{link}'>%{name}</a> erstellt."
        },
        "new": {
          "title": "Ixly-Mitarbeiter hinzufügen"
        }
      },
      "leniency_entries": {
        "create": {
          "created": "Die Kronzeugentransaktion wurde erfolgreich erstellt.",
          "title": "Neues Kronzeugenabkommen"
        },
        "new": {
          "form": {
            "in_euro": "Betrag in Euro"
          },
          "title": "Neues Kronzeugenabkommen"
        }
      },
      "mail_templates": {
        "mark_as_template": {
          "marked": "Die E-Mail-Vorlage %{title} ist als Vorlage markiert"
        },
        "unmark_as_template": {
          "unmarked": "Die E-Mail-Vorlage %{title} ist nicht mehr als Vorlage markiert"
        }
      },
      "migrated_organizations": {
        "index": {
          "title": "Migrierte Organisationen"
        }
      },
      "migration_organizations": {
        "create": {
          "success": "Migrationsorganisation erfolgreich erstellt"
        },
        "index": {
          "title": "Zu migrierende Organisationen"
        },
        "new": {
          "title": "Migrieren Sie eine neue TTK-Organisation"
        },
        "reset": {
          "reset": "Migrationsorganisation auf „erstellt“ zurückgesetzt"
        },
        "run_migration": {
          "run_migration": "Die Migration hat begonnen!"
        },
        "show": {
          "title": "Details zur Migrationsorganisation"
        }
      },
      "organization_csv_imports": {
        "import_file": {
          "log": "Protokoll",
          "original": "Original"
        },
        "index": {
          "list": "Liste der Importdateien der Organisation",
          "title": "CSV-Importe der Organisation"
        },
        "new": {
          "title": "CSV-Import der Organisation"
        }
      },
      "organization_settings": {
        "edit": {
          "title": "Ixly-einstellungen"
        }
      },
      "organization_statistics": {
        "customer_credits": {
          "index": {
            "candidate_assessment_entry": "Bewertung",
            "candidate_assessment_program_entry": "Bewertungsprogramm",
            "candidate_report_entry": "Bericht (nur Test)",
            "generated_report_entry": "Bericht (Prozesse)",
            "leniency_entry": "Freundlichkeit",
            "list": {
              "balance_at": "Guthabenstand bei %{date}:",
              "candidate": "Kandidat",
              "cost_center": "Kostenstelle",
              "credits": "Nummer",
              "date": "Datum",
              "description": "Beschreibung",
              "entry_type": "Art der Transaktion",
              "no_entries": "In diesem Zeitraum wurden keine Credits verbraucht"
            },
            "order": {
              "frequent_credits": "Monatliche Abbuchung der verbrauchten Guthaben",
              "incidental_credits": "Kauf eines Credit-Pakets",
              "intensive_credits": "Monatliche Abbuchung der genutzten Guthaben"
            },
            "order_entry": "Befehl",
            "title": "Kreditübersicht"
          }
        },
        "finished_assessments": {
          "index": {
            "title": "Abgeschlossene Beurteilungen"
          },
          "list": {
            "number_finished": "Zahl gerundet",
            "task_key": "Aufgabenschlüssel",
            "task_name": "Aufgabennname"
          }
        },
        "premium_components": {
          "index": {
            "list": {
              "number_assigned": "Nummer vergeben",
              "premium_component": "Premium-Komponente"
            },
            "title": "Ausgezeichnet mit Premium-Komponenten"
          }
        },
        "show": {
          "title": "Managementübersicht"
        }
      },
      "organizations": {
        "create": {
          "organization_created": "Die Organisation wurde gegründet! <a class='alert-link' href='%{link}'>Starten Sie ein Abonnement</a>, damit sich seine Benutzer anmelden können",
          "title": "Neue Organisation"
        },
        "destroy": {
          "organization_deleted": "Die Organisation wurde gelöscht",
          "organization_deletion_failed": "Die Organisation konnte nicht gelöscht werden"
        },
        "index": {
          "add": "Organisation hinzufügen",
          "statistics_button": "Verwendung",
          "subscription_button": "Abonnements",
          "title": "Organisationen"
        },
        "new": {
          "title": "Neue Organisation"
        }
      },
      "prices": {
        "create": {
          "title": "Neuer Preis"
        },
        "edit": {
          "title": "Preis Bearbeiten"
        },
        "index": {
          "help_text": "Hier die Preisliste",
          "title": "Preise"
        },
        "new": {
          "title": "Neuer Preis"
        },
        "show": {
          "amc_price": "AMC-Preis",
          "bov_price": "BOV Preis",
          "fit_price": "FIT Preis",
          "help_text": "Preis details",
          "job_market_opportunities_price": "Preis der Arbeitsmarktchancen",
          "job_suggestions_price": "Jobvorschläge preis",
          "jobfeed_price": "Jobfeed-Preis",
          "monthly_fee_per_coach": "Monatsgebühr pro Coach",
          "monthly_fee_per_participant": "Monatlicher Beitrag pro Kandidat",
          "title": "Preis"
        },
        "update": {
          "title": "Preis Bearbeiten"
        }
      },
      "products": {
        "create": {
          "created": "Das Modul ist erstellt"
        },
        "deactivate": {
          "deactivated": "Das Modul ist deaktiviert"
        },
        "destroy": {
          "delete_confirm": "Möchten Sie dieses Modul wirklich löschen?",
          "deleted": "Das Modul ist gelöscht"
        },
        "edit": {
          "title": "Modul bearbeiten"
        },
        "index": {
          "help_text": "Hier die Liste aller Module",
          "title": "Module"
        },
        "list": {
          "actions": "Handlungen",
          "name": "Name",
          "status": "Status",
          "status_changed_at": "Status geändert am",
          "updated_at": "Aktualisiert am"
        },
        "new": {
          "title": "Neues Modul"
        },
        "reactivate": {
          "reactivated": "Das Modul ist wieder aktiviert"
        },
        "update": {
          "updated": "Das Modul ist aktualisiert"
        }
      },
      "subscription_type_products": {
        "create": {
          "created": "Die Module wurden dem Abonnementtyp hinzugefügt",
          "products_blank": "Es wurden keine Module ausgewählt"
        },
        "form": {
          "add_module": "Modul hinzufügen"
        },
        "new": {
          "title": "Module hinzufügen zu %{subscription_type}"
        }
      },
      "subscriptions": {
        "create": {
          "incidental_subscription_credits_left": "Sollten beim Start des neuen Abonnements noch Guthaben vorhanden sein, werden diese zunächst aufgebraucht",
          "success": "Das Abonnement wurde hinzugefügt."
        },
        "destroy": {
          "success": "Das Abonnement wurde gelöscht"
        },
        "edit": {
          "title": "Bearbeiten Sie das %{subscription}-Abonnement"
        },
        "index": {
          "add": "Neues Abonnement hinzufügen",
          "help_text": "Hier finden Sie die Übersicht der der Organisation zugeordneten Abonnements",
          "invoice_settings": "Abrechnungseinstellungen",
          "title": "Abonnements ab %{organization}"
        },
        "list": {
          "end_date": "Endtermin",
          "name": "Name",
          "start_date": "Startdatum"
        },
        "new": {
          "title": "Neues Abonnement für %{organization}"
        },
        "update": {
          "success": "Das Abonnement wurde erfolgreich aktualisiert"
        }
      },
      "users": {
        "index": {
          "title": "Nutzer"
        }
      }
    },
    "javascript": {
      "before_unload": "Ihre letzte Änderung ist noch nicht gespeichert.",
      "components": {
        "add_to_calendar": {
          "add_appointment": "Termin in Terminkalender einschreiben"
        },
        "advisor_introduction": {
          "avatar_placeholder": "Nach der Zuweisung wird hier das Profilfoto vom Coach angezeigt",
          "biography_placeholder": "Hier wird die persönliche Beschreibung vom Coach angezeigt, die dieser in seinem Profil eingestellt hat.",
          "default_biography": "%{advisor_first_name} begleitet Sie für die Dauer des Projekts. Bei eventuellen Fragen können Sie sich an Ihren Coach wenden.",
          "default_biography_explanation": "(Ihr Kandidat sieht derzeit den Standardtext oben, da Sie in Ihrem Profil noch keine persönliche Beschreibung festgelegt haben.)",
          "title_placeholder": "[Name des Coaches]",
          "your_advisor": "Ihr Coach"
        },
        "appointment": {
          "accept": "Akzeptieren",
          "appointment": "Terminplaner",
          "appointment_accepted": "Termin akzeptiert",
          "appointment_cancelled": "Termin abgesagt",
          "appointment_created": "Termin erstellt",
          "appointment_declined": "Termin abgewiesen",
          "appointment_submitted": "Vorgeschlagener Termin",
          "at_time": "um",
          "back": "Zurück",
          "cancel": "Absagen",
          "date": "Datum",
          "decline": "Abweisen",
          "delete": "Löschen",
          "description": "Beschreibung",
          "description_placeholder": "Eventuelle Erläuterung",
          "end_time": "bis",
          "help_text": "Es wurden noch keine Termine vorgeschlagen.",
          "join": "Beteiligen Sie sich am Gespräch",
          "location": "Ort",
          "location_placeholder": "Treffpunkt",
          "on_date": "am",
          "plan_appointment": "Schlagen Sie einen Termin vor",
          "propose": "Vorschlagen",
          "start_time": "von",
          "title": "Titel",
          "title_placeholder": "Titel für diesen Termin"
        },
        "blog": {
          "help_text": "Zeigt alle mit dem Kandidaten geteilten Blogartikel an.",
          "manage": "Artikel verwalten",
          "title": "Blog"
        },
        "comments": {
          "placeholder": "Nachricht",
          "send": "Nachricht abschicken",
          "step_unavailable": "Dieser Schritt steht dem Kandidaten derzeit nicht zur Verfügung. Der Kandidat erhält eine Benachrichtigung über diese Nachricht, sobald der Schritt verfügbar ist."
        },
        "consent": [
          "Nein",
          "Ja",
          "Geben Sie in den Einstellungen dieses Elements die Frage ein"
        ],
        "custom_html": {
          "html_placeholder": "Führen Sie über die Einstellungen dieses Elements HTML-Code ein, um ein Custom-Element zu erstellen"
        },
        "document_approval": {
          "approve": "Zustimmen",
          "approved": "Zugestimmt",
          "candidate": "der Kandidat",
          "contact_person": "die Kontaktperson",
          "no_file_uploaded": "Der Coach hat keine Datei hochgeladen",
          "no_file_uploaded_again": "Der Coach hat keine neue Datei hochgeladen",
          "reason_placeholder": "Grund (optional)",
          "reason_placeholder_table": "Abgewiesen? Geben Sie einen Grund an",
          "reject": "Abweisen",
          "rejected": "Abgewiesen",
          "reset": "Nochmals",
          "status_message_responded": "%{action} am %{date} um %{time}",
          "status_message_submitted": "%{can_approve} hat noch nicht auf die Anfrage bezüglich der Zustimmung reagiert",
          "status_message_submitted_again": "%{can_approve} hat noch nicht auf die neue Anfrage bezüglich der Zustimmung reagiert",
          "submitted": "Eingereicht",
          "upload_file": "Datei hochladen",
          "upload_file_again": "Neue Datei hochladen",
          "with_reason": "wegen \"%{reason}\""
        },
        "document_upload": {
          "no_file_uploaded": {
            "advisor": "Keine Datei vom Coach hochgeladen",
            "candidate": "Der Kandidat hat keine Datei hochgeladen"
          },
          "upload_file": "Datei hochladen"
        },
        "filter_bar": {
          "labels": {
            "filter": "Filtern",
            "records": "Records",
            "search": "Suchen",
            "sort": "Sortieren"
          },
          "no_filters": "alle",
          "sort": {
            "ascending": "aufsteigend",
            "default": "Standard",
            "descending": "absteigend"
          }
        },
        "filter_notifications": {
          "all": "Alle",
          "done": "Erledigt",
          "label": "Status",
          "open": "To-do"
        },
        "image": {
          "no_image_uploaded": "Keine Abbildung hochgeladen",
          "upload_image": "Abbildung hochladen"
        },
        "interactive_results": {
          "agile": {
            "descriptions": {
              "groups": {
                "early_adopter": [
                  "Sie gehören zur Kategorie „Early Adopters“. Sie sind offen für Veränderungen und bereit, dabei mitzugehen, auch wenn vielleicht noch nicht ganz klar ist, welche Probleme es geben wird oder was die Folgen sind. Auf diese Weise können Sie gut mit Veränderungen und der sich rasant verändernden Welt umgehen, was Sie anpassungsfähig macht. Ein Risiko kann sein, dass Sie die Folgen von Veränderungen nicht gut einschätzen. Versuchen Sie, ab und zu stillzustehen und sich die Ideen von anderen anzuhören, und welche Schwierigkeiten sie bei einer bestimmten Veränderung noch sehen.\n\nAgiles Arbeiten wird gut zu Ihnen passen, weil es gut zu Ihrer Anpassungsfähigkeit passt. Es ist allerdings wichtig, dass Sie nicht stets mit jeder Veränderung mitgehen, sondern auch Dinge fertigstellen, bevor wieder mit etwas Neuem begonnen wird.",
                  "Sie gehören zur Kategorie „Early Adopters“. Sie sind offen für Veränderungen und bereit, dabei mitzugehen, auch wenn vielleicht noch nicht ganz klar ist, welche Probleme es geben wird oder was die Folgen sind. Auf diese Weise können Sie gut mit Veränderungen und der sich rasant verändernden Welt umgehen, was Sie anpassungsfähig macht. Ein Risiko kann sein, dass Sie die Folgen von Veränderungen nicht gut einschätzen. Versuchen Sie, ab und zu stillzustehen und sich die Ideen von anderen anzuhören, und welche Schwierigkeiten sie bei einer bestimmten Veränderung noch sehen.\n\nAgiles Arbeiten wird gut zu Ihnen passen, weil es gut zu Ihrer Anpassungsfähigkeit passt. Es ist allerdings wichtig, dass Sie nicht stets mit jeder Veränderung mitgehen, sondern auch Dinge fertigstellen, bevor wieder mit etwas Neuem begonnen wird."
                ],
                "early_majority": [
                  "Sie gehören zur „Early Majority“. Sie sind etwas mehr als der Durchschnitt bereit, Veränderungen mitzumachen, warten aber immer gern bis die Anfangsschwierigkeiten vorbei sind. Sie haben eine positive Grundeinstellung gegenüber Veränderung, wollen aber wissen, was es für Sie bedeutet. Pläne, Veränderungen und neue Arbeitsweisen sollten Ihrer Meinung nach erst sehr gründlich erprobt sein.\n\nSie kommen mit Agilem Arbeiten recht gut zurecht und wissen es zu schätzen, dass es dafür auch strukturierte Arbeitsmethoden gibt. Machen Sie die Methode jedoch nicht zum Ziel, es geht letztlich um den Arbeitsstil und die Realisierung nützlicher neuer Produkte und Arbeitsweisen.",
                  "Sie gehören zur „Early Majority“. Sie sind mehr als der Durchschnitt bereit, Veränderungen mitzumachen, warten aber gern bis die Anfangsschwierigkeiten vorbei sind. Sie haben eine positive Grundeinstellung gegenüber Veränderung, wollen aber wissen, was es für Sie bedeutet. Pläne, Veränderungen und neue Arbeitsweisen sollten Ihrer Meinung nach gründlich erprobt sein.\n\nSie kommen mit Agilem Arbeiten gut zurecht und wissen es zu schätzen, dass es dafür auch strukturierte Arbeitsmethoden gibt. Machen Sie die Methode jedoch nicht zum Ziel, es geht letztlich um den Arbeitsstil und die Realisierung nützlicher neuer Produkte und Arbeitsweisen."
                ],
                "innovator": [
                  "Sie sind ein „Innovator“ und Vorreiter. Sie initiieren oft Veränderungen, entwickeln neue Ideen und sind offen für neue Abenteuer. Sie ermutigen andere, bei Veränderungsprozessen mitzugehen. Agiles Arbeiten finden Sie besonders gut, weil es sich um eine Arbeitsweise handelt, die flexibel ist und Raum für Innovationen bietet. Es gefällt Ihnen, dass auf diese Art alles schneller in Gang kommt. Da Sie hauptsächlich in Grundzügen arbeiten, besteht allerdings die Möglichkeit, dass Sie Details übersehen. Nutzen Sie dafür die Talente von anderen.\n\nEs könnte für Sie das Risiko bestehen, dass Sie manchmal zu schnell vom Stapel laufen wollen. Sie sollten darauf achten, dass Sie nicht zu weit vorweg laufen und Ihre Pläne realistisch bleiben. Widerstand und Unverständnis können Sie als störend empfinden. Auch ist das Schreiben von Berichten nicht wirklich Ihr Ding. Das kann zu Nachlässigkeiten führen und Sie für andere weniger nachahmenswert machen.",
                  "Sie sind ein „Innovator“ und eindeutiger Vorreiter. Sie initiieren Veränderungen, entwickeln neue Ideen und sind offen für neue Abenteuer. Sie ermutigen andere, bei Veränderungsprozessen mitzugehen. Agiles Arbeiten finden Sie besonders gut, weil es sich um eine Arbeitsweise handelt, die flexibel ist und Raum für Innovationen bietet. Es gefällt Ihnen, dass auf diese Art alles schneller in Gang kommt. Da Sie hauptsächlich in Grundzügen arbeiten, besteht die Möglichkeit, dass Sie Details übersehen. Nutzen Sie dafür die Talente von anderen.\n\nEs könnte für Sie das Risiko bestehen, dass Sie zu schnell vom Stapel laufen wollen. Sie sollten darauf achten, dass Sie nicht zu weit vorweg laufen und Ihre Pläne realistisch bleiben. Widerstand und Unverständnis können Sie als störend empfinden. Auch ist das Schreiben von Berichten nicht wirklich Ihr Ding. Das kann zu Nachlässigkeiten führen und Sie für andere weniger nachahmenswert machen."
                ],
                "laggards": [
                  "Sie gehören zu den sogenannten „Laggards“, den Nachzüglern. Bei Veränderungen sehen Sie sich erst einmal alles aus der Ferne an und wollen die Dinge am liebsten so lassen, wie sie sind. Sie müssen wirklich dazu überredet werden, sich zu verändern. Und das tun Sie auch lieber in kleinen Schritten als in großen Sprüngen. Sie stehen Veränderungen skeptisch gegenüber und manchmal ist das auch gerechtfertigt. Schließlich ist nicht jede Veränderung eine Verbesserung. Aber Sie sollten aufpassen, dass Sie nicht zu weit zurückfallen und sich nicht um jeden Preis am Altvertrauten festhalten. Schließlich verändert sich die Welt um uns herum in rasantem Tempo. Manchmal müssen Sie sich dazu überwinden, neue Arbeitsweisen zu erlernen und anzuwenden.\n\nWo Sie sich bei Veränderungen nützlich machen können, ist, sicherzustellen, dass den Details Aufmerksamkeit geschenkt wird, mit denen Sie die Qualität einer Veränderung sicherstellen. Sie können auch dazu beitragen, Veränderungen praktisch umzusetzen, indem Sie dafür sorgen, dass alles bis ins letzte Detail ausgearbeitet und festgelegt wird.\n\nDie Arbeit in Agilen Teams wird für Sie weniger geeignet sein. Sie sind lieber Teil eines Teams, in dem die Tätigkeiten strukturiert und auch eher routiniert sind.",
                  "Sie gehören zu den sogenannten „Laggards“, den Nachzüglern. Bei Veränderungen neigen Sie dazu, sich erst einmal alles aus der Ferne anzusehen. Sie tendieren dazu, die Dinge so zu lassen, wie sie sind. Sie müssen wirklich dazu überredet werden, sich zu verändern. Und das tun Sie auch lieber in kleinen Schritten als in großen Sprüngen. Sie stehen Veränderungen skeptisch gegenüber und manchmal ist das auch gerechtfertigt. Schließlich ist nicht jede Veränderung eine Verbesserung. Aber Sie sollten aufpassen, dass Sie nicht zu weit zurückfallen und sich nicht um jeden Preis am Altvertrauten festhalten. Schließlich verändert sich die Welt um uns herum in rasantem Tempo. Manchmal müssen Sie sich dazu überwinden, neue Arbeitsweisen zu erlernen und anzuwenden.\n\nWo Sie sich bei Veränderungen nützlich machen können, ist, sicherzustellen, dass den Details Aufmerksamkeit geschenkt wird, mit denen Sie die Qualität einer Veränderung sicherstellen. Sie können auch dazu beitragen, Veränderungen praktisch umzusetzen, indem Sie dafür sorgen, dass alles bis ins letzte Detail ausgearbeitet und festgelegt wird.\n\nDie Arbeit in Agilen Teams ist für Sie möglicherweise weniger geeignet. Sie sind lieber Teil eines Teams, in dem die Tätigkeiten strukturiert und möglichst auch eher routiniert sind.",
                  "Sie gehören zu den sogenannten „Laggards“, den Nachzüglern. Bei Veränderungen neigen Sie dazu, sich erst einmal alles aus der Ferne anzusehen. Sie tendieren dazu, die Dinge so zu lassen, wie sie sind. Sie müssen wirklich dazu überredet werden, sich zu verändern. Und das tun Sie auch lieber in kleinen Schritten als in großen Sprüngen. Sie stehen Veränderungen skeptisch gegenüber und manchmal ist das auch gerechtfertigt. Schließlich ist nicht jede Veränderung eine Verbesserung. Aber Sie sollten aufpassen, dass Sie nicht zu weit zurückfallen und sich nicht um jeden Preis am Altvertrauten festhalten. Schließlich verändert sich die Welt um uns herum in rasantem Tempo. Manchmal müssen Sie sich dazu überwinden, neue Arbeitsweisen zu erlernen und anzuwenden.\n\nWo Sie sich bei Veränderungen nützlich machen können, ist, sicherzustellen, dass den Details Aufmerksamkeit geschenkt wird, mit denen Sie die Qualität einer Veränderung sicherstellen. Sie können auch dazu beitragen, Veränderungen praktisch umzusetzen, indem Sie dafür sorgen, dass alles bis ins letzte Detail ausgearbeitet und festgelegt wird.\n\nDie Arbeit in Agilen Teams ist für Sie möglicherweise weniger geeignet. Sie sind lieber Teil eines Teams, in dem die Tätigkeiten strukturiert und möglichst auch eher routiniert sind."
                ],
                "late_majority": [
                  "Sie gehören zur „Late Majority“. Letztendlich machen Sie bei Veränderungen mit, aber nicht immer von ganzem Herzen. Es kostet Sie etwas Mühe sich anzupassen. Sie wollen deshalb wissen, welche Vorteile die Veränderung mit sich bringt, welchen Nutzen sie hat und was Sie persönlich davon haben. Sie sollten darauf achten, dass Sie nicht zu einem „Fixed Mindset“ tendieren und sich zu sehr am Altvertrauten festhalten. Selbstverständlich wollen Sie auch nicht zu sehr zurückbleiben, denn schließlich dreht sich die Erde immer weiter.\n\nIhr Verdienst bei Veränderungsprozessen besteht darin, dass Sie Einwände und Verbesserungsmöglichkeiten gut benennen können, bevor das Ganze in der Praxis eingesetzt werden kann. Ihre kritische Haltung erhöht somit die Erfolgschancen der Veränderung.\n\nOb die Arbeit in Agilen Teams zu Ihnen passt, ist trotzdem fraglich. Ihre Rolle im Team könnte die des Kontrolleurs oder Testers sein. Jemand, der die Risiken genau identifiziert und die Einwände deutlich aufzeigt. Auch das wird in Teams benötigt!",
                  "Sie gehören zur „Late Majority“. Letztendlich machen Sie bei Veränderungen mit, aber nicht immer von ganzem Herzen. Es kostet Sie etwas Mühe sich anzupassen. Sie wollen deshalb wissen, welche Vorteile die Veränderung mit sich bringt, welchen Nutzen sie hat und was Sie persönlich davon haben. Sie sollten darauf achten, dass Sie nicht zu einem „Fixed Mindset“ tendieren und sich zu sehr am Altvertrauten festhalten. Selbstverständlich wollen Sie auch nicht zu sehr zurückbleiben, denn schließlich dreht sich die Erde immer weiter.\n\nIhr Verdienst bei Veränderungsprozessen besteht darin, dass Sie Einwände und Verbesserungsmöglichkeiten gut benennen können, bevor das Ganze in der Praxis eingesetzt werden kann. Ihre kritische Haltung erhöht somit die Erfolgschancen der Veränderung.\n\nOb die Arbeit in Agilen Teams zu Ihnen passt, ist trotzdem fraglich. Ihre Rolle im Team könnte die des Kontrolleurs oder Testers sein. Jemand, der die Risiken genau identifiziert und die Einwände deutlich aufzeigt. Auch das wird in Teams benötigt!"
                ]
              },
              "pillars": {
                "change": [
                  "Sie mögen es zu wissen, wo Sie stehen. Sie haben es gern, wenn die Dinge gleich bleiben und Sie eine gewisse Routine bei Ihrer Arbeit haben. Am liebsten arbeiten Sie an festen Aufgaben und nach vorgegebenen Verfahren. Sie gehen lieber auf Nummer sicher als Risiken einzugehen. Wenn es nach Ihnen ginge, bräuchten die Dinge nicht unbedingt verändert oder erneuert zu werden. An der Ausübung Ihrer Tätigkeiten etwas zu ändern, finden Sie wahrscheinlich hinderlich. Andere können das auch als problematisch, als Widerstand, empfinden. Versuchen Sie, das Positive in dem zu sehen, was auf Sie zukommt. Setzen Sie kleine Schritte, aber bleiben Sie in Bewegung. Passen Sie auf, dass Sie nicht zu den Nachzüglern gehören.",
                  "Sie mögen es, wenn alles gleich bleibt, aber Sie haben auch nichts gegen kleine Veränderungen. In der Regel ziehen Sie es vor, an festen Aufgaben und nach vorgegebenen Verfahren zu arbeiten. Obwohl Sie gern wissen, wo Sie stehen, sind Sie auch manchmal neugierig auf Veränderungen, die auf Verbesserung gerichtet sind. Sie werden bei diesen Veränderungen nicht an vorderster Front stehen, da Sie es vorziehen, die Risikobereitschaft auf das Nötigste zu beschränken, aber Sie stellen sich darauf ein. So wirklich schnell nehmen Sie es allerdings auch nicht auf.",
                  "Sie sind Veränderungen gegenüber neutral eingestellt. Obwohl Sie gern bestimmte feste Aufgaben haben, finden Sie es auch schön, etwas Abwechslung in der Umgebung oder in den Tätigkeiten zu erfahren. Wenn eine Veränderung in Ihrem Unternehmen stattfindet, werden Sie nicht direkt derjenige sein, der diese Veränderung initiiert hat, aber Sie können sich relativ leicht an die neue Situation anpassen. Sie finden es nicht notwendig, nach festen Rahmenbedingungen zu arbeiten, es stört Sie allerdings auch nicht. Außerdem begrüßen Sie neue Aufgaben, die noch ausgearbeitet werden müssen.",
                  "Sie mögen es, wenn sich Ihre Arbeit regelmäßig verändert. Sie haben wenig Bedarf an festen Aufgaben, Sie wollen lieber eine abwechslungsreiche Tätigkeit. Wenn Sie feste Aufgaben ausführen, werden Sie wahrscheinlich über neue Wege nachdenken, diese Arbeit anders zu erledigen. Es ist daher demotivierend für Sie, wenn Ihnen vorgeschrieben wird, wie Sie bestimmte Dinge erledigen sollen. Wenn jemand eine Veränderung initiiert, sind Sie dafür offen, aber die damit verbundenen Risiken sollten Ihrer Meinung nach nicht zu hoch sein.",
                  "Sie begrüßen Veränderungen. Oftmals initiieren Sie auch Neuerungen, und neue Erkenntnisse und Experimente fliegen Ihnen zu. Am besten sollte jeder Arbeitstag für Sie anders sein. Sie mögen es, herausfordernde und abwechslungsreiche Aufgaben zu haben, die Sie nach eigenem Ermessen organisieren können. Aufgabenabläufe, Routine und Vorschriften empfinden Sie als demotivierend. In der Regel stehen Sie bei Veränderungen an vorderster Front, regelmäßig werden Veränderungen von Ihnen initiiert. Dabei scheuen Sie sich nicht, Risiken einzugehen, denn Innovation ist für Sie wichtiger als Sicherheit."
                ],
                "growth": [
                  "Sie haben ein Bedürfnis nach Sicherheit und Routine. Sie erleben Ehrgeiz oder das Messen mit anderen nicht als anregend, eher brechen Sie darunter zusammen. Sie haben sich eine Reihe von Aufgaben zu eigen gemacht, in denen Sie gut sind, und Sie halten es nicht für notwendig, andere Aufgaben zu erlernen. Sie sind im Allgemeinen zufrieden mit dem Niveau, auf dem Sie Aufgaben ausführen, und Sie verspüren keinen Druck, Aufgaben besser zu erfüllen als Ihre Kollegen. Sie vermeiden Aufgaben, die Ihrer Meinung nach außerhalb Ihrer Fähigkeiten liegen, weil Sie nicht so viele Fehler machen wollen. Weil Sie sich so sehr auf Qualität konzentrieren, können Sie manchmal nicht so gut damit umgehen, wenn Sie einen Fehler machen.",
                  "Sie brauchen mehr Sicherheit als Entwicklung. Sie haben sich eine Reihe von Aufgaben zu eigen gemacht, in denen Sie gut sind, und Sie sind im Großen und Ganzen mit dem Niveau zufrieden, auf dem Sie die Aufgaben ausführen. Sie wollen lernen, wie Sie diese Aufgaben noch besser erfüllen können, aber Sie haben wenig Bedarf, andere Aufgaben zu erlernen. Sie vermeiden Aufgaben, die Ihrer Meinung nach außerhalb Ihrer Fähigkeiten liegen, weil Sie nicht so viele Fehler machen wollen. Feedback bei der Arbeit nehmen Sie möglicherweise persönlich, weil Sie gern gut vor anderen dastehen wollen.",
                  "Sie haben einen durchschnittlichen Bedarf an Selbstentwicklung. Sie mögen es, ein paar feste Aufgaben zu haben, sind aber auch offen für das Erlernen neuer Tätigkeiten. Auch finden Sie es gut, sich in Ihren aktuellen Aufgaben zu verbessern. Sie sehen Ihre eigene Leistung an sich, und neigen nicht dazu, sie mit der Ihrer Kollegen zu vergleichen. Obwohl Sie es nicht schön finden, Fehler zu machen, verstehen Sie, dass dies Teil des Lernprozesses für größere Herausforderungen ist.",
                  "Sie haben einen hohen Bedarf an Selbstentwicklung. Ihr Ziel ist es, viel zu lernen und sich in den Aufgaben, für die Sie verantwortlich sind, zu verbessern. Am liebsten ist es Ihnen, Ihre Aufgaben besser zu erledigen und schwierigere Aufgaben zu übernehmen als Ihre Kollegen. Es macht Ihnen nicht so viel aus, Fehler zu machen, denn das ist Teil des Lernprozesses. Sie sind belastbar und lassen sich nicht so leicht von etwas abhalten. Schlussendlich macht es Ihnen nichts aus, ein paar Aufgaben zu erledigen, bei denen Weiterentwicklung nicht möglich ist.",
                  "Sie finden Selbstentwicklung überaus wichtig. Sie sind enorm wissbegierig und wollen gern Berge versetzen. Routineaufgaben finden Sie langweilig, weil Sie lieber so viel wie möglich selbst nachdenken. Ihr Ziel ist es, viel zu lernen und Sie haben überhaupt kein Problem damit, Fehler zu machen. Sie sehen diese Fehler als Informationsquelle, denn das sind die Punkte, bei denen Sie sich noch weiterentwickeln können. Unangenehme Aufgaben packen Sie tatkräftig an. Sie versuchen das Beste aus sich herauszuholen und wollen besser sein als die Anderen."
                ],
                "power": [
                  "Basierend auf Ihrem qualitätsorientierten Ansatz konzentrieren Sie sich auf die Erhaltung dessen, was gut ist. Sie arbeiten am liebsten an vertrauten Tätigkeiten oder Projekten, für die Sie sich die Fähigkeiten bereits angeeignet haben, weil Sie einwandfreie Arbeit leisten möchten. Neue Projekte zu beginnen oder andere Aufgaben zu erlernen spricht Sie nicht so sehr an, weil Sie Angst haben, Fehler zu machen. Deshalb bevorzugen Sie Projekte, die Sie aus Erfahrung und Routine bereits beherrschen. Sie stehen Veränderungen kritisch gegenüber, was der Qualität der Innovationen zugute kommen kann. Da Sie jedoch regelmäßig Stolpersteine auf dem Weg sehen, neigen Sie auch dazu, Veränderungen aufzuhalten. Veränderungen können Sie auch zu viel Energie kosten. Es ist ratsam herauszufinden, wie Sie Ihre Kraft stärken können, um genügend Energie dafür zu haben, Ihren Platz in der sich verändernden Welt zu erhalten und einen positiven Beitrag daran zu leisten.",
                  "Sie haben eine Vorliebe dafür, sich in ein Thema zu vertiefen. Sie arbeiten lieber länger an einem Projekt, in dem Sie spezifisches Fachwissen sammeln, als dass Sie sich auf breiteres Wissen konzentrieren. Sie arbeiten auch gern an Projekten, in denen alle Prozesse bekannt und geradlinig sind. Tätigkeiten und Projekte, die Sie aus Erfahrung und Routine bereits beherrschen, bevorzugen Sie. Über Anfangsschwierigkeiten in neuen Projekten ärgern Sie sich. Sie stehen Innovationen daher kritisch gegenüber, was der Qualität dieser Veränderungen zugute kommen kann. Sie sollten allerdings darauf achten, dass Sie sich nicht zu sehr von möglichen Stolpersteinen, die Sie auf dem Weg sehen, zurückhalten lassen, denn Veränderungen können auch viele positive Folgen haben. Es ist ratsam herauszufinden, wie Sie Ihre Kraft stärken können, um genügend Energie dafür zu haben, Ihren Platz in der sich verändernden Welt zu erhalten und einen positiven Beitrag daran zu leisten.",
                  "Sie haben genug Energie, um mit Veränderungen umgehen zu können, ohne jedoch dabei eine initiierende Rolle zu spielen. Auf der einen Seite sehen Sie die Vorteile von Veränderungen und auf der anderen Seite finden Sie es auch schön, an dem festzuhalten, was Ihnen bekannt ist. Sie sind offen für neue Aufgaben und Aktivitäten, aber Sie finden es doch angenehm, wenn sich nicht zu viele Veränderungen gleichzeitig abspielen. Der Vorteil davon ist, dass Sie dadurch die Möglichkeit haben, den möglichen Anlaufschwierigkeiten dieser Veränderungen die Stirn zu bieten.",
                  "Sie besitzen eine hohe Dosis an Innovationsenergie. Sie verfügen über ein hohes Maß an Energie und Engagement und können daher hervorragend mit Veränderungen umgehen. Sie sehen in der Regel die positiven Aspekte der Neuerung, auch wenn Sie es manchmal ärgerlich finden, dass nicht alle Prozesse damit optimiert werden. Sie lassen sich dadurch jedoch nicht aufhalten und tragen aktiv zur Verbesserung der Veränderungsprozesse am Arbeitsplatz bei.",
                  "Sie besitzen eine enorme Dosis Innovationstemperament. Das bedeutet, dass Sie über die Energie, das Engagement und die Einstellung verfügen, um mit Veränderungen gut umgehen zu können. Obwohl Innovationen viele positive Effekte mit sich bringen können, gibt es doch auch häufig Anlaufschwierigkeiten oder noch nicht optimierte Prozesse. Sie verstehen, dass dies Teil des Veränderungsprozesses ist und können gut damit umgehen. Im Falle eines Rückschlags lassen Sie sich nicht abschrecken und arbeiten weiterhin mit einer positiven Einstellung. Sie glauben daran, dass Sie mit harter Arbeit und Ausdauer jeder Herausforderung gewachsen sind."
                ]
              },
              "tips": {
                "change": [
                  "Obwohl, rational gesehen, jede Veränderung ihre positiven Seiten und Chancen bietet, achten Sie vielleicht mehr auf die Gefahren und Bedrohungen, die sie mit sich bringt. Fragen Sie sich in Veränderungsprozessen regelmäßig selbst, welche Schlussfolgerung tatsächlich mit Sicherheit gezogen werden kann, und was Sie dahingegen selbst daraus gemacht haben. Werfen Sie einen kritischen Blick auf die Stolpersteine, die Sie auf dem Weg sehen. Indem Sie sich die Grenze zwischen Fakten und Ihren eigenen Interpretationen bewusst machen, lernen Sie, mit Veränderungen neutraler umzugehen. Eine andere Möglichkeit mit Dingen umzugehen, die Sie als Bedrohung sehen, besteht darin, alle Risiken und Nachteile aufzuschreiben, die Ihnen im Kopf herumschwirren. Danach zwingen Sie sich selbst, dem genau so viele Vorteile und neue Möglichkeiten gegenüberzustellen.",
                  "Obwohl, rational gesehen, jede Veränderung ihre positiven Seiten und Chancen bietet, achten Sie vielleicht mehr auf die Gefahren und Bedrohungen, die sie mit sich bringt. Fragen Sie sich in Veränderungsprozessen regelmäßig selbst, welche Schlussfolgerung tatsächlich mit Sicherheit gezogen werden kann, und was Sie dahingegen selbst daraus gemacht haben. Werfen Sie einen kritischen Blick auf die Stolpersteine, die Sie auf dem Weg sehen. Indem Sie sich die Grenze zwischen Fakten und Ihren eigenen Interpretationen bewusst machen, lernen Sie, mit Veränderungen neutraler umzugehen. Eine andere Möglichkeit mit Dingen umzugehen, die Sie als Bedrohung sehen, besteht darin, alle Risiken und Nachteile aufzuschreiben, die Ihnen im Kopf herumschwirren. Danach zwingen Sie sich selbst, dem genau so viele Vorteile und neue Möglichkeiten gegenüberzustellen.",
                  "Sie können sich relativ leicht an Veränderungen anpassen. Sie werden jedoch nicht immer mit Begeisterung auf Veränderungen reagieren. Rational gesehen, birgt jede Veränderung ihre positiven Seiten und Chancen. Um Ihre Begeisterung für Veränderungen zu steigern, können Sie eine Liste aller Vorteile und neuen Möglichkeiten erstellen, die diese Veränderung mit sich bringt. Indem Sie sich die positiven Auswirkungen der Veränderung bewusst vor Augen halten, werden Sie einfacher zu begeistern sein.",
                  "Mit Ihrer Offenheit für Veränderungen sind Sie ein guter Kompass für Innovatoren, die manchmal zu weit vorweg laufen wollen. Schätzen Sie die Risiken von Veränderungen ein, und auch für wie wahrscheinlich Sie diese halten, und besprechen Sie sie mit den Innovatoren.",
                  "Sorgen Sie dafür, in Kontakt mit den anderen zu bleiben, laufen Sie nicht zu weit vorweg. Auf die Qualität der Veränderung zu achten, kann nicht schaden."
                ],
                "growth": [
                  "Finden Sie heraus, welche Kompetenzen Sie weiterentwickeln können und wollen und, welche Fähigkeiten für Ihre Einsetzbarkeit nützlich sind. Da Sie auf Sicherheit und Routine ausgerichtet sind, könnte es sein, dass Sie sich nicht genug auf Kompetenzen konzentrieren, die für Ihren Arbeitgeber in Zukunft relevant und interessant sind. Versuchen Sie, darauf zu achten und Chancen zu ergreifen, wenn Sie Möglichkeiten zur Weiterentwicklung von Kompetenzen sehen, die Sie für sinnvoll halten.",
                  "Finden Sie heraus, welche Kompetenzen Sie weiterentwickeln können und wollen und, welche Fähigkeiten für Ihre Einsetzbarkeit nützlich sind. Da Ihr Bedürfnis nach Sicherheit größer ist als Ihr Bedürfnis nach Entwicklung, könnte es sein, dass Sie sich nicht genug auf Kompetenzen konzentrieren, die für Ihren Arbeitgeber in Zukunft relevant und interessant sind. Versuchen Sie, sich davor in Acht zu nehmen, finden Sie heraus, welche relevanten Kompetenzen Sie sich zu eigen machen können und ergreifen Sie die Initiative zur Entwicklung dieser Kompetenzen.",
                  "Sie verfügen über eine gute Kombination an Eigenschaften: Sie haben kein Problem damit, vertraute Aufgaben zu erledigen, aber Sie finden es auch schön weiter zu lernen. Überprüfen Sie, ob Ihre aktuelle Ausgewogenheit das ist, was Sie sich wünschen. Gibt es noch Kompetenzen, die Sie sich aneignen möchten? Was können Sie tun, um diese Kompetenzen zu entwickeln? Versuchen Sie, die Initiative zu ergreifen und bei Ihrer Arbeit die Möglichkeiten dazu selbst zu schaffen.",
                  "Sie verfügen über eine gute Kombination an Eigenschaften: Sie möchten sich in verschiedenen Bereichen weiterentwickeln, haben aber auch kein Problem damit, vertraute Aufgaben zu erledigen. Überprüfen Sie, ob Ihre aktuelle Ausgewogenheit das ist, was Sie sich wünschen. Gibt es noch Kompetenzen, die Sie sich aneignen möchten? Was können Sie tun, um diese Kompetenzen zu entwickeln? Versuchen Sie, die Initiative zu ergreifen und bei Ihrer Arbeit die Möglichkeiten dazu selbst zu schaffen.",
                  "Sie haben einen großen Durst nach Wissen und Selbstentwicklung, was gut ist, wenn es um Veränderungen in Unternehmen geht. Aber denken Sie auch daran, dass sich nicht alles gleichzeitig ändern kann, und dass einige Routineaufgaben nun einmal Teil Ihrer Arbeit sind. Lassen Sie sich nicht zu sehr von Ihrer Stimmung und Motivation beeinflussen, wenn Sie vertrautere Tätigkeiten ausführen müssen, denn mit Ihrer Offenheit für Entwicklung kommt ganz von allein wieder eine neue, herausfordernde Aufgabe auf Sie zu."
                ],
                "power": [
                  "Um Ihre Kraft zu stärken, sollten Sie vielleicht herausfinden, welche positiven Effekte andere Menschen durch Veränderungen erfahren haben. Versuchen Sie auch, für sich selbst herauszufinden, welche Veränderungen in Ihrem Leben oder an Ihrem Arbeitsplatz stattgefunden haben und wie Sie damit umgegangen sind. Konzentrieren Sie sich besonders auf die Aspekte, mit denen Sie gut umgegangen sind. Gab es Zeiten, in denen Sie selbst Veränderungen in die Wege geleitet haben? Welche positiven Auswirkungen hatte das? Indem Sie sich auf diese Dinge konzentrieren, können Sie Ihr Selbstvertrauen im Umgang mit Veränderungen stärken.",
                  "Um Ihre Kraft zu stärken, sollten Sie vielleicht herausfinden, welche positiven Effekte andere Menschen durch Veränderungen erfahren haben. Versuchen Sie auch, für sich selbst herauszufinden, welche Veränderungen in Ihrem Leben oder an Ihrem Arbeitsplatz stattgefunden haben und wie Sie damit umgegangen sind. Konzentrieren Sie sich besonders auf die Aspekte, mit denen Sie gut umgegangen sind. Gab es Zeiten, in denen Sie selbst Veränderungen in die Wege geleitet haben? Welche positiven Auswirkungen hatte das? Indem Sie sich auf diese Dinge konzentrieren, können Sie Ihr Selbstvertrauen im Umgang mit Veränderungen stärken.",
                  "Erkennen Sie Ihre Chance: Sie spielen eine wichtige Rolle bei der Mäßigung zu ehrgeiziger Pläne, bei denen zu viel gleichzeitig angepackt werden soll. Obwohl Sie offen für Veränderungen sind, stehen Sie mit beiden Beinen auf dem Boden. Diese bodenständige Einstellung kann sich auf die Innovatoren übertragen.",
                  "Erkennen Sie Ihre Chance: Sie spielen eine wichtige Rolle bei der Mäßigung zu ehrgeiziger Pläne, bei denen zu viel gleichzeitig angepackt werden soll. Obwohl Sie sehr offen für Veränderungen sind, haben Sie auch ein Auge für mögliche Hindernisse. Versuchen Sie, die Innovatoren darauf hinzuweisen und darüber nachzudenken, wie Sie die Hürden verhindern oder lösen können.",
                  "Es ist eine gute Eigenschaft, viel Energie für den Umgang mit Veränderungen zu besitzen. Achten Sie jedoch darauf, dass Sie nicht von Ihrer Begeisterung geblendet werden und hören Sie sich die Bedenken und Wünsche Ihrer Kollegen zu den Veränderungen an. Nehmen Sie die potenziellen Hindernisse, die sie sehen, ernst und versuchen Sie, eine Lösung dafür zu finden. Das wird der Qualität der Veränderung zugute kommen."
                ]
              }
            },
            "header": "Agile mindset",
            "tip": "TIPP!",
            "titles": {
              "groups": {
                "early_adopter": "Early adopter",
                "early_majority": "Early majority",
                "innovator": "Innovator",
                "laggards": "Laggards",
                "late_majority": "Late majority"
              },
              "pillars": {
                "change": "Change",
                "growth": "Growth",
                "power": "Power"
              }
            }
          },
          "behavior": {
            "header": "Verhaltensmerkmale",
            "keys": {
              "aanspreken": "Reden und reden",
              "durft": "Sich trauen",
              "veranderbereid": "Veränderungsbereitschaft"
            }
          },
          "candidate": {
            "date_of_birth": "Geburtsdatum",
            "education": "Bildungsabschluss",
            "email": "E-Mail-Adresse",
            "header": "Personenbezogene Daten",
            "most_recent_work_experience": "Neueste Berufserfahrung",
            "study_subject": "Bildung",
            "years": {
              "one": "%{years} Jahr",
              "other": "%{years} Jahre"
            },
            "years_of_experience": "Anzahl Jahre Erfahrung"
          },
          "competences": {
            "descriptions": {
              "aansturenvangroepen": "Eine Gruppe von Menschen leiten können.",
              "aansturenvanindividuen": "Einzelne Personen leiten können.",
              "ambitie": "Engagiert sein, besser als mittelmäßige Leistungen erbringen wollen.",
              "assertiviteit": "Seine Meinung vertreten, auch wenn man durch andere Menschen deswegen unter Druck gesetzt wird.",
              "besluitvaardigheid": "Schnell und adäquat Entscheidungen treffen können.",
              "coachenvanmedewerkers": "Als Vorgesetzter seine Mitarbeiter stimulieren und in ihrer Entwicklung begleiten können.",
              "delegeren": "In der Lage sein, Arbeiten gezielt an andere Personen zu delegieren.",
              "doorzettingsvermogen": "Darauf aus sein, auch bei Rückschlägen einmal angefangene Dinge zu einem guten Ende zu bringen.",
              "flexibiliteit": "Kann durch das Wechseln von Verhaltensstilen zielgerichtet und effektiv mit unterschiedlichen und sich verändernden Umständen umgehen.",
              "initiatief": "Imstande sein, neue Dinge zu initiieren, selbstständig zielgerichtete Aktionen auszuführen.",
              "inzet": "Sich über längere Zeit hinweg sehr anstrengen wollen und können.",
              "klantorientatie": "Sich in den Kunden hineinversetzen und auf seine Wünsche eingehen können.",
              "kwaliteitsgerichtheid": "Danach streben, eine hohe Qualität zu liefern und Arbeiten zu perfektionieren.",
              "leerbereidheid": "Bereit sein, sich zu entwickeln und durch Lernen sein Wissen und seine Fähigkeiten zu erweitern.",
              "nauwkeurigheid": "Sorgfältig arbeiten können und ein Auge für Details und Zusammenhänge haben.",
              "onderhandelen": "Imstande sein, die beiderseitigen Interessen und Standpunkte zu erfassen und eine Einigung zu erzielen, mit der jeder zufrieden ist.",
              "ondernemerschap": "Danach streben, durch das Signalisieren und Ergreifen von Möglichkeiten und durch das Eingehen kalkulierbarer Risiken Gewinn zu erziehlen.",
              "optreden": "Einen tatkräftigen und professionellen Eindruck auf andere machen.",
              "organisatiesensitiviteit": "Erfassen, was für Konsequenzen die eigenen Entscheidungen und Aktivitäten für den Rest des Unternehmens haben.",
              "overtuigingskracht": "Andere Menschen von der eigenen Meinung, den eigenen Vorschlägen oder Ideen überzeugen können.",
              "plannenenorganiseren": "In der Lage sein, Aktivitäten und Arbeiten zu planen und zu organisieren.",
              "relatiebeheer": "Kontakt mit anderen Menschen herstellen und pflegen.",
              "resultaatgerichtheid": "Konkreten Zielen und Ergebnissen nachstreben.",
              "samenwerking": "Zusammen mit anderen konstruktiv an gemeinschaftlichen Zielen arbeiten und diese erreichen.",
              "sensitiviteit": "In der Lage sein, Signale von anderen wahrzunehmen und adäquat darauf zu reagieren.",
              "sociabiliteit": "Ist gerne unter Menschen.",
              "stressbestendigheid": "Unter stressigen Bedingungen weiter effektiv funktionieren können.",
              "voortgangscontrole": "In der Lage sein, initiierte Arbeiten zu überwachen und ihren Fortschritt zu kontrollieren.",
              "zelfstandigheid": "Selbstständig Arbeiten verrichten können, sich Ziele setzen können und diese in praktische Handlungen umsetzen können."
            },
            "header": "Ihre Top 5-Kompetenzen",
            "keys": {
              "aansturenvangroepen": "Leiten von Gruppen",
              "aansturenvanindividuen": "Leiten von Einzelpersonen",
              "ambitie": "Ehrgeiz",
              "assertiviteit": "Assertivität",
              "besluitvaardigheid": "Entschlussfähigkeit",
              "coachenvanmedewerkers": "Begleiten von Mitarbeitern",
              "delegeren": "Delegieren",
              "doorzettingsvermogen": "Durchsetzungsvermögen",
              "flexibiliteit": "Flexibilität",
              "initiatief": "Initiative",
              "inzet": "Engagement",
              "klantorientatie": "Kundenorientierung",
              "kwaliteitsgerichtheid": "Qualitätsorientierung",
              "leerbereidheid": "Lernbereitschaft",
              "nauwkeurigheid": "Präzision",
              "onderhandelen": "Verhandlungsgeschick",
              "ondernemerschap": "Unternehmertum",
              "optreden": "Auftreten",
              "organisatiesensitiviteit": "Organisationssensitivität",
              "overtuigingskracht": "Überzeugungskraft",
              "plannenenorganiseren": "Planen und organisieren",
              "relatiebeheer": "Beziehungsmanagement",
              "resultaatgerichtheid": "Ergebnisorientierung",
              "samenwerking": "Zusammenarbeit",
              "sensitiviteit": "Sensibilität",
              "sociabiliteit": "Soziabilität",
              "stressbestendigheid": "Stressstabilität",
              "voortgangscontrole": "Statuskontrolle",
              "zelfstandigheid": "Selbstständigkeit"
            }
          },
          "development": {
            "header": "Entwicklungspotenzial",
            "keys": {
              "ambitie": "Ehrgeiz",
              "growth": "Persönliche Weiterentwicklung",
              "onafhankelijkheid": "Unabhängigkeit",
              "samenwerking": "Im Team arbeiten",
              "sociabiliteit": "Soziabilität"
            }
          },
          "documents": {
            "date": "Datum",
            "header": "Dokumente (%{documents})",
            "less": "Weniger anzeigen",
            "more": "Mehr anzeigen",
            "refresh": "Ihre Sitzung ist abgelaufen. Klicken Sie auf die untenstehende Button, um die Seite neu zu laden."
          },
          "explanations": {
            "behavior": {
              "description": "Verhaltensmerkmale sind die Prinzipien, die die Grundlage dafür bilden, wie wir innerhalb von NS zusammenarbeiten wollen"
            },
            "intelligence": {
              "description": "In diesem Widget sehen Sie zwei Arten von Scores. Auf der ersten Seite wird Ihr Intelligenzscore angezeigt. Die niederländische Erwerbsbevölkerung hat einen Score von durchschnittlich 100.\n\nAuf der nächsten Seite wird Ihr Score mit Referenzgruppen verschiedener Bildungsniveaus verglichen. Die auf dieser Seite angezeigten Zahlen sind Sten-Scores. Damit zeigen wir, wie Sie im Vergleich zur Referenzgruppe abschneiden. Scores von beispielsweise 5 oder 6 sind durchschnittlich und kommen häufig vor. Scores von beispielsweise 1 oder 10 sind außergewöhnlich und kommen selten vor."
            },
            "interests": {
              "description": "In diesem Widget sehen Sie zwei Arten von Bewertungen: Ihr Interesse an Sektoren und an Aufgaben. Ihr Brancheninteresse zeigt, in welchem Sektor des Arbeitsmarktes Sie arbeiten möchten. Ihr Interesse an Aufgaben gibt Aufschluss darüber, welche Tätigkeiten Sie gerne ausführen würden. Mithilfe dieser Informationen können Sie herausfinden, welche Arbeit für Sie interessant ist. Sie werden diese Arbeit mit mehr Motivation und Freude ausführen als eine Arbeit, die weniger für Sie relevant ist.\n\nAuf der ersten Seite werden Ihre Top-5-Interessen in den Sektoren angezeigt. Die Werte reichen von 1 bis 10, wobei 1 für ein weit unterdurchschnittliches Interesse und 10 für ein weit überdurchschnittliches Interesse steht. Auf der zweiten Seite werden Ihre Top-5-Interessen an Aufgaben angezeigt. Die Werte reichen von 1 bis 5, wobei 1 für ein weit unterdurchschnittliches Interesse und 5 für ein weit überdurchschnittliches Interesse steht."
            },
            "star_scores": {
              "description": "Die Sterne in diesem Widget zeigen, wie hoch das Entwicklungspotenzial bei Ihren Kompetenzen ist. Je mehr Sterne gelb gefärbt sind, desto ausgeprägter Ihre Anlagen. Das heißt nicht, dass die Kompetenz bereits vollständig entwickelt ist bzw. auf welchem Niveau."
            },
            "sten_scores": {
              "description": "Die Zahlen in diesem Widget sind Sten-Scores. Damit zeigen wir, wie Sie im Vergleich zur niederländischen Erwerbsbevölkerung abschneiden. Scores von beispielsweise 5 oder 6 sind durchschnittlich und kommen häufig vor. Scores von beispielsweise 1 oder 10 sind außergewöhnlich und kommen selten vor. Unter- und überdurchschnittliche Scores bringen sowohl Stärken als auch Fallstricke mit sich.",
              "scores": [
                "Sehr deutlich unterduchschnittlich",
                "Deutlich unterdurchschnittlich",
                "Unterdurchschnittlich",
                "Leicht unterdurchschnittlich",
                "Durchschnittlich",
                "Durchschnittlich",
                "Leicht überdurchschnittlich",
                "Überdurchschnittlich",
                "Deutlich überdurchschnittlich",
                "Sehr deutlich überdurchschnittlich"
              ]
            }
          },
          "filter": {
            "clear": "Auswahl löschen",
            "empty": "Wählen Sie bis zu 10 Kompetenzen aus, um Ihre Ergebnisse zu sehen",
            "header": "Kompetenzen filtern"
          },
          "hybrid_work": {
            "average": {
              "gemiddeld": "Durchschnitt",
              "hoog": "hoch",
              "laag": "niedrig"
            },
            "header": "Fähigkeiten des 21. Jahrhunderts",
            "skills": {
              "autonomie": {
                "descriptions": {
                  "gemiddeld": "Wenn Sie von zu Hause aus arbeiten, haben Sie die Freiheit, selbst zu entscheiden, wie Sie Ihre Arbeit erledigen. Manchmal benötigen Sie jedoch die Erlaubnis, bestimmte Aufgaben auszuführen, und Sie haben ein gewisses Maß an Kontrolle. Darüber hinaus kann es sein, dass Sie manchmal durch Ihre häusliche Umgebung abgelenkt werden. Sie fühlen sich wahrscheinlich einigermaßen in der Lage, Ihre Fähigkeiten einzusetzen, um einen Beitrag zum Team und/oder der Organisation zu leisten, in der Sie arbeiten. Das kann zu einer gewissen Motivation und Zufriedenheit führen.",
                  "hoog": "Im Homeoffice erleben Sie die Freiheit, selbstständig zu entscheiden, wie Sie Ihre Arbeit erledigen. Sie werden nicht viel überwacht. Sie verfügen über ein hohes Maß an Verantwortungsbewusstsein und sind in der Lage, Ihre Arbeit selbstständig zu planen und zu organisieren. Ablenkungen aus dem häuslichen Umfeld stören Sie kaum und Konflikte zwischen Privatem und Beruflichem erleben Sie kaum. Sie fühlen sich wahrscheinlich in der Lage, Ihre Fähigkeiten einzusetzen, um einen Beitrag zum Team und/oder der Organisation zu leisten, in der Sie arbeiten. Dies kann zu hoher Motivation und Zufriedenheit führen.",
                  "laag": "Wenn Sie von zu Hause aus arbeiten, erleben Sie wenig Freiheit, selbstständig zu entscheiden, wie Sie Ihre Arbeit erledigen. Sie werden oft überwacht und benötigen für die Ausführung bestimmter Aufgaben eine Erlaubnis. Dadurch sind Sie bei Entscheidungen über Ihre Arbeit von anderen abhängig. Wahrscheinlich werden Sie während der Arbeit manchmal von Ihrer häuslichen Umgebung abgelenkt und es kommt zu Konflikten zwischen Privat- und Arbeitsleben. Möglicherweise fühlen Sie sich gehemmt und haben wenig Motivation und Zufriedenheit."
                },
                "label": "Autonomie"
              },
              "competentie": {
                "descriptions": {
                  "gemiddeld": "Bei der Arbeit von zu Hause aus erlebt man ein durchschnittliches Kompetenzgefühl. Das bedeutet, dass Sie in der Regel über die Fähigkeiten und das Wissen verfügen, um Ihre Arbeit effektiv zu erledigen, es kann jedoch sein, dass Sie manchmal Schwierigkeiten haben, bestimmte Aufgaben gut auszuführen. Manchmal sind Ihre technischen Fähigkeiten eingeschränkt. Im Allgemeinen haben Sie ein gewisses Vertrauen in Ihre eigenen Fähigkeiten, fühlen sich jedoch möglicherweise unsicher, ob Sie Ihre Arbeit gut erledigen können. Dies kann zu verminderter Motivation und Zufriedenheit führen.",
                  "hoog": "Bei der Arbeit von zu Hause aus erleben Sie ein hohes Maß an Kompetenz. Sie verfügen über die Fähigkeiten und Kenntnisse, um Ihre Arbeit effektiv auszuführen. Wenn Sie von zu Hause aus arbeiten, haben Sie kaum oder gar keine Probleme mit Ihren technischen Fähigkeiten. Sie haben großes Vertrauen in Ihre Fähigkeiten und sind wahrscheinlich motiviert und mit Ihrer Leistung bei der Arbeit sehr zufrieden.",
                  "laag": "Wenn Sie von zu Hause aus arbeiten, verspüren Sie ein geringes Kompetenzgefühl. Das bedeutet, dass Sie wahrscheinlich nur über begrenzte Fähigkeiten und Kenntnisse verfügen, um Ihre Arbeit effektiv zu erledigen, und möglicherweise Schwierigkeiten haben, bestimmte Aufgaben zu erledigen. Wenn Sie von zu Hause aus arbeiten, kann es zu Problemen mit Ihren technischen Kenntnissen kommen. Das ist wahrscheinlich der Grund, warum Sie wenig Vertrauen in Ihre eigenen Fähigkeiten haben. Möglicherweise fühlen Sie sich unsicher, ob Sie Ihre Arbeit gut machen können. Dies kann zu verminderter Motivation und Zufriedenheit führen."
                },
                "label": "Kompetenz"
              },
              "verbondenheid": {
                "descriptions": {
                  "gemiddeld": "Wenn Sie von zu Hause aus arbeiten, erleben Sie ein angemessenes Maß an Kontakt zu Ihren Kollegen. Möglicherweise haben Sie gelegentlich Schwierigkeiten, mit Kollegen in Kontakt zu treten. Sie erleben nicht immer die Einbeziehung Ihrer Kollegen. Dies kann zu verminderter Motivation und Zufriedenheit führen.",
                  "hoog": "Wenn Sie von zu Hause aus arbeiten, erleben Sie ein starkes Gefühl der Verbundenheit mit Ihren Kollegen. Sie kommen leicht mit Ihren Kollegen in Kontakt und erfahren von ihnen Unterstützung und Engagement, wenn Sie es brauchen. Das trägt zu Ihrem Interesse an Ihrer Arbeit bei. Sie sind wahrscheinlich leistungsmotiviert und mit Ihrer Arbeit zufrieden.",
                  "laag": "Wenn Sie von zu Hause aus arbeiten, verspüren Sie ein schwaches Gefühl der Verbundenheit mit Ihren Kollegen. Es fällt Ihnen schwer, mit Ihren Kollegen in Kontakt zu treten und Sie erfahren wenig Unterstützung und Engagement von ihnen. Dies kann zu verminderter Motivation und Zufriedenheit führen."
                },
                "label": "Verbundenheit"
              }
            },
            "title": "Hybrides Arbeiten"
          },
          "information": {
            "skills": {
              "informatievaardigheden": {
                "label": "Informationskompetenz: Umgang mit Informationen",
                "tips": [
                  "Die Art und Weise, wie Sie Informationen suchen und verarbeiten, kann in mehreren Bereichen verbessert werden. Weitere Informationen zum Big-6-Modell der Informationskompetenz finden Sie auf <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">dieser</a> Website. Prüfen Sie selbst, welche Schritte Sie noch verbessern können!",
                  "Die Art und Weise, wie Sie Informationen suchen und verarbeiten, kann in mehreren Bereichen verbessert werden. Weitere Informationen zum Big-6-Modell der Informationskompetenz finden Sie auf <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">dieser</a> Website. Prüfen Sie selbst, welche Schritte Sie noch verbessern können!",
                  "Die Art und Weise, wie Sie Informationen suchen und verarbeiten, ist in vielerlei Hinsicht bereits gut! Aber es gibt noch Raum für Verbesserungen. Weitere Informationen zum Big-6-Modell der Informationskompetenz finden Sie auf <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">dieser</a> Website. Prüfen Sie bei sich selbst, welche Schritte Sie bereits gut machen und welche Schritte Sie verbessern können.",
                  "Die Art und Weise, wie Sie Informationen suchen und verarbeiten, ist bereits auf einem hohen Niveau. Aber wer weiß, es gibt noch Raum für Verbesserungen. Auf <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">dieser</a> Website können Sie mehr über das Big-6-Modell der Informationskompetenz lesen. Prüfen Sie bei sich selbst, welche Schritte Sie bereits gut machen und welche Schritte Sie noch verbessern können!",
                  "Die Art und Weise, wie Sie Informationen suchen und verarbeiten, ist bereits auf einem hohen Niveau. Aber wer weiß, es gibt noch Raum für Verbesserungen. Weitere Informationen zum Big-6-Modell der Informationskompetenz finden Sie auf <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">dieser</a> Website. Prüfen Sie bei sich selbst, welche Schritte Sie bereits gut machen und welche Schritte Sie noch verbessern können!"
                ]
              },
              "kritisch": {
                "description": "Informationen kritisch prüfen",
                "label": "Kritisch",
                "tips": [
                  "Sie betrachten die Quelle Ihrer Informationen nicht kritisch und neigen dazu, Informationen wörtlich zu kopieren. Versuchen Sie öfter, Informationen aus verschiedenen Quellen zu kombinieren und in eigene Worte zu fassen. Dadurch sind Sie automatisch kritischer gegenüber den von Ihnen übernommenen Informationen.",
                  "Sie stehen der Quelle Ihrer Informationen nicht immer sehr kritisch gegenüber. Sie neigen dazu, Informationen wörtlich zu nehmen. Versuchen Sie öfter, Informationen aus verschiedenen Quellen zu kombinieren und in eigene Worte zu fassen. Dadurch sind Sie automatisch kritischer gegenüber den von Ihnen übernommenen Informationen.",
                  "Sie versuchen, Informationen nicht einfach zu akzeptieren und wörtlich zu kopieren. Aber Sie könnten dies noch mehr tun. Versuchen Sie öfter, Informationen aus verschiedenen Quellen zu kombinieren und in eigene Worte zu fassen. Dadurch sind Sie automatisch kritischer gegenüber den von Ihnen übernommenen Informationen.",
                  "Sie sind bei der Recherche und der Aufbereitung der Informationen gründlich. Sie prüfen kritisch verschiedene Quellen. Sie versuchen auch, Informationen in Ihre eigenen Worte zu fassen, anstatt sie einfach abzuschreiben. Lassen Sie diese kritische Sichtweise nicht locker.",
                  "Sie sind bei der Recherche und der Verarbeitung der Informationen gründlich. Sie prüfen kritisch verschiedene Quellen. Sie versuchen auch, Informationen in Ihre eigenen Worte zu fassen, anstatt sie einfach abzuschreiben. Behalten Sie diesen kritischen Blick."
                ]
              },
              "organisatie": {
                "description": "Informationen organisieren",
                "label": "Organisation",
                "tips": [
                  "Sie nehmen sich nicht immer die Zeit, Ihren Suchprozess vorzubereiten oder auszuwerten. Daher finden Sie nicht immer die richtigen Informationen und Ihr Suchvorgang kann länger als nötig dauern.",
                  "Manchmal können Sie sich mehr Zeit nehmen, um Ihren Suchprozess vorzubereiten oder auszuwerten. Dadurch können Sie noch gezielter suchen und Ihren Suchprozess beschleunigen.",
                  "Sie versuchen, Ihren Suchprozess vorzubereiten und auszuwerten, aber es gibt noch Raum für Verbesserungen. Mit einer guten Vorbereitung und einem kritischen Blick im Suchprozess können Sie noch gezielter suchen.",
                  "Sie nehmen sich die Zeit, Ihren Suchprozess vorzubereiten und auszuwerten. Versuchen Sie dies öfter, denn mit einer guten Vorbereitung und einem kritischen Blick während des Suchvorgangs können Sie noch gezielter suchen.",
                  "Sie nehmen sich ausreichend Zeit für die Vorbereitung und Auswertung Ihres Suchprozesses. So vermeiden Sie Fehler und Doppelarbeit."
                ]
              },
              "presenteren": {
                "description": "Informationen präsentieren",
                "label": "Gegenwärtig",
                "tips": [
                  "Die Art und Weise, wie Sie Informationen präsentieren, ist nicht immer klar und geordnet. Möglicherweise fällt es Ihnen schwer, eine Struktur zu schaffen, oder Sie berücksichtigen Ihre Zielgruppe nicht.",
                  "Die Art und Weise, wie Sie Informationen präsentieren, kann klarer und organisierter sein. Möglicherweise fällt es Ihnen schwer, eine Struktur zu schaffen, oder Sie berücksichtigen Ihre Zielgruppe nicht.",
                  "Sie geben Ihr Bestes, um Informationen klar und prägnant darzustellen. Sie könnten dies noch weiter verbessern, indem Sie mehr Struktur hinzufügen. Außerdem können Sie das Niveau und die Vorkenntnisse Ihres Publikums noch stärker berücksichtigen.",
                  "Die Art und Weise, wie Sie Informationen präsentieren, ist im Allgemeinen klar und geordnet. Um dies noch weiter zu verbessern, ist es gut, darüber nachzudenken, wer Ihr Publikum ist. So können Sie das Niveau und die Vorkenntnisse Ihres Publikums besser berücksichtigen.",
                  "Die Art und Weise, wie Sie Informationen präsentieren, ist klar und geordnet. Sie sorgen dafür, dass Ihre Geschichte einen roten Faden hat. Dabei berücksichtigen Sie auch das Niveau und die Vorkenntnisse Ihres Publikums."
                ]
              },
              "strategie": {
                "description": "Die strategische Suche nach Informationen",
                "label": "Strategie",
                "tips": [
                  "Sie sind auf eine Suchmethode beschränkt. Daher finden Sie nicht immer die richtigen Informationen. Ihre Suche wird auch länger als nötig dauern. Suchen Sie nach Tipps, um Ihre Suche zu verfeinern. Ändern Sie Ihre Strategie, wenn Sie merken, dass Sie nicht die richtigen Informationen finden.",
                  "Sie neigen dazu, sich auf eine Suchmethode zu beschränken. Dies kann es schwierig machen, die richtigen Informationen zu finden. Ihre Suche wird auch länger als nötig dauern. Suchen Sie nach Tipps, um Ihre Suche zu verbessern. Ändern Sie Ihre Strategie, wenn Sie merken, dass Sie nicht die richtigen Informationen finden.",
                  "Sie versuchen, Ihre Art der Suche der Situation anzupassen. Dies bedeutet in der Regel, dass Sie schnell die richtigen Informationen finden können. Überlegen Sie, ob bestimmte Arten von Informationen für Sie schwieriger zu finden sind. Könnten Sie dies verbessern, indem Sie beispielsweise Filter oder spezifischere Suchbegriffe verwenden?",
                  "Normalerweise passen Sie Ihre Suchmethode an die gesuchten Informationen an. Dadurch können Sie in der Regel schnell die richtigen Informationen finden. Nutzen Sie dieses Wissen, um Ihren Suchprozess noch weiter zu optimieren.",
                  "Passen Sie während Ihrer Suche Ihre Suchmethode an, um die besten Ergebnisse zu erzielen. Dadurch finden Sie die gesuchten Informationen oft einfach und schnell."
                ]
              }
            }
          },
          "intelligence": {
            "closest_group": "Ihr Score entspricht am besten der Referenzgruppe:",
            "description": "Diese Grafik zeigt Ihren Score im Vergleich zur niederländischen Erwerbsbevölkerung. Wir können mit einer Wahrscheinlichkeit von 80 % sagen, dass Ihr Score zwischen den gestrichelten Linien liegt.",
            "groups": {
              "hbo_ba": "HBO/Bachelor",
              "mbo": "MBO",
              "mbo1": "MBO1",
              "mbo2": "MBO2",
              "mbo3": "MBO3",
              "mbo4": "MBO4",
              "vmbo": "VMBO",
              "wo_ma": "WO/Master"
            },
            "header": "Intelligenz",
            "keys": {
              "abstract": "Abstrakt",
              "numeriek": "Numerisch",
              "totaalscore": "Gesamtergebnis",
              "verbaal": "Verbal"
            },
            "labels": {
              "average": "Durchschnittlich",
              "high": "Überdurchschnittlich",
              "low": "Unterdurchschnittlich"
            },
            "subtitles": [
              "Im Vergleich zu einer %{group} Referenzgruppe ist Ihr Gesamtergebnis deutlich unterdurchschnittlich",
              "Im Vergleich zu einer %{group} Referenzgruppe ist Ihr Gesamtergebnis unterdurchschnittlich",
              "Im Vergleich zu einer %{group} Referenzgruppe ist Ihr Gesamtergebnis durchschnittlich",
              "Im Vergleich zu einer %{group} Referenzgruppe ist Ihr Gesamtergebnis überdurchschnittlich",
              "Im Vergleich zu einer %{group} Referenzgruppe ist Ihr Gesamtergebnis deutlich überdurchschnittlich"
            ],
            "title": "Referenzgruppe %{group}"
          },
          "interests": {
            "sectoren": {
              "header": "Top 5 Interesse an Sektoren",
              "keys": {
                "administratie": "Verwaltung",
                "advieseninformatie": "Beratung und Informationen",
                "agrarischeondersteuning": "Landwirtschaftliche Unterstützung",
                "beheer": "Management",
                "bouw": "Bauen",
                "commercieledienstverlening": "Kommerzieller Dienst",
                "communicatie": "Kommunikation",
                "cultuur": "Kultur",
                "delfstofwinning": "Bergbau",
                "dieren": "Tiere",
                "elektrotechniek": "Elektrotechnik",
                "gezondheidszorg": "Gesundheitspflege",
                "glasaardewerkkeramiek": "Glas, Töpferwaren und Keramik",
                "grafischetechniek": "Grafische Technologie",
                "handel": "Handel",
                "horeca": "Gaststättengewerbe",
                "houtmeubileringstechniek": "Holzmöbeltechnik",
                "huishouding": "Hauswirtschaft",
                "industrielereiniging": "Industrielle Reinigung",
                "installatietechniek": "Installationstechnik",
                "magazijnopslagbezorging": "Lager, Lagerung und Lieferung",
                "media": "Medien",
                "metaal": "Metall",
                "milieu": "Umfeld",
                "onderwijs": "Ausbildung",
                "ontwerpenontwikkeling": "Design und Entwicklung",
                "openbaarbestuur": "Öffentliche Verwaltung",
                "organisatieenadvies": "Organisation und Beratung",
                "personeelarbeidloopbaan": "Personal, Arbeit und Karriere",
                "persoonlijkeverzorging": "Körperpflege",
                "planningproductiebegeleiding": "Planung und Produktionsüberwachung",
                "planten": "Pflanzen",
                "procestechniek": "Verfahrenstechnik",
                "rechtspraak": "Zuständigkeit",
                "recreatie": "Erholung",
                "schoonmaak": "Reinigung",
                "sportontspanning": "Sport und Freizeit",
                "textielmode": "Textilien und Mode",
                "toerisme": "Tourismus",
                "veiligheid": "Sicherheit",
                "vervoer": "Transport",
                "visserij": "Fischerei",
                "welzijn": "Wohlfahrt",
                "wetenschap": "Wissenschaft"
              }
            },
            "taken": {
              "header": "Top 5 Interesse an Aufgaben",
              "keys": {
                "administratievetaken": "Verwaltungsaufgaben",
                "analyseren": "Analysieren",
                "assisteren": "Helfen",
                "conflictenoplossen": "Konflikte lösen",
                "contactonderhouden": "Kontakt pflegen",
                "creatiefzijn": "kreativ sein",
                "cursussentrainingengeven": "Durchführung von Kursen und Schulungen",
                "elektronischeapparatierepareren": "Reparieren Sie elektronische Geräte",
                "fysiekinspannen": "Körperliche Anstrengung",
                "grotemachinesbedienen": "Bedienen Sie große Maschinen",
                "informatieverzamelen": "Informationen erhalten",
                "inroosteren": "Terminplanung",
                "klantenhelpen": "Kunden helfen",
                "leidinggeven": "Führen",
                "lesgeven": "Lehren",
                "lezen": "Lesen",
                "machinesrepareren": "Maschinen reparieren",
                "mensenadviseren": "Menschen beraten",
                "mensenbegeleiden": "Menschen führen",
                "mensenbeinvloeden": "Menschen beeinflussen",
                "mensenovertuigen": "Menschen überzeugen",
                "metcollegasoverleggen": "Beraten Sie sich mit Kollegen",
                "metcomputerswerken": "Arbeiten mit Computern",
                "metelektronischeapparatenwerken": "Arbeiten mit elektronischen Geräten",
                "methandenwerken": "Arbeiten mit den Händen",
                "metmachineswerken": "Arbeiten mit Maschinen",
                "middelenvoorwerpenbeheren": "Verwalten Sie Ressourcen und Artikel",
                "motiveren": "Motivieren",
                "onderhandelen": "Verhandeln",
                "organiseren": "Organisieren",
                "projectenopzetten": "Projekte einrichten",
                "rekenen": "Berechnung",
                "schrijven": "Schreiben",
                "strategischdenken": "Strategisches Denken",
                "teplannen": "Planen",
                "voertuigenbesturen": "Fahren von Fahrzeugen",
                "werkzaamhedenplannen": "Arbeit planen"
              }
            }
          },
          "it": {
            "descriptions": {
              "gemiddeld": "Ihre grundlegenden IKT-Kenntnisse sind ausreichend, Sie haben aber noch Raum zum Lernen.",
              "hoog": "Ihre grundlegenden IKT-Kenntnisse sind ausgezeichnet.",
              "laag": "Ihre grundlegenden IKT-Kenntnisse könnten eine Aktualisierung gebrauchen."
            },
            "header": "Fähigkeiten des 21. Jahrhunderts",
            "title": "Grundlegende IKT-Kenntnisse: Umgang mit Technologie"
          },
          "media": {
            "categories": [
              "Du bist der Beste darin",
              "Du bist sehr gut darin",
              "Du bist darin genauso gut wie die meisten",
              "Du kannst dich noch verbessern",
              "Sie müssen noch viel darüber lernen",
              "So gehen Sie mit Social Media um"
            ],
            "header": "Fähigkeiten des 21. Jahrhunderts",
            "skills": {
              "consumptie": {
                "description": "Gehen Sie kritisch damit um, welche Informationen Sie teilen und konsumieren",
                "label": "Verbrauch",
                "tips": [
                  "Sie stehen den von Ihnen weitergegebenen Informationen nicht kritisch gegenüber. Versuchen Sie, aufmerksamer darauf zu achten, was online über Sie zu finden ist und welche Botschaften Sie verbreiten.",
                  "Sie stehen den von Ihnen weitergegebenen Informationen nicht immer kritisch gegenüber. Versuchen Sie, aufmerksamer darauf zu achten, was online über Sie zu finden ist und welche Botschaften Sie verbreiten.",
                  "Normalerweise denken Sie zuerst nach, bevor Sie Informationen weitergeben, und stellen Sie sicher, dass online nichts Seltsames über Sie gefunden wird.",
                  "Normalerweise stehen Sie den Informationen, die Sie preisgeben, und dem, was online über Sie zu finden ist, kritisch gegenüber.",
                  "Sie sind kritisch gegenüber den Informationen, die Sie weitergeben, und gegenüber dem, was online über Sie zu finden ist."
                ]
              },
              "maatschappelijk": {
                "description": "Der gesellschaftliche Einfluss der Medien",
                "label": "Sozial",
                "tips": [
                  "Es fällt Ihnen schwer, den gesellschaftlichen Einfluss von Medien einzuschätzen. Man ist sich nicht immer bewusst, in welchem Ausmaß Medien Menschen beeinflussen oder irreführen können.",
                  "Sie haben einen mäßigen Einblick in den gesellschaftlichen Einfluss von Medien. Sie verstehen nicht immer ganz, inwieweit Medien dazu genutzt werden können, Menschen zu beeinflussen oder sogar in die Irre zu führen.",
                  "Sie haben ein durchschnittliches Verständnis für den gesellschaftlichen Einfluss von Medien. Normalerweise sieht man, wie Menschen Medien nutzen, und kann dadurch in die Irre geführt werden. Man kann diesen Einfluss der Medien noch nicht in allen Situationen erkennen.",
                  "Sie haben einen hervorragenden Einblick in den gesellschaftlichen Einfluss von Medien. Normalerweise sieht man, wie Menschen Medien nutzen und wie Menschen dadurch in die Irre geführt werden können.",
                  "Sie haben einen guten Einblick in den gesellschaftlichen Einfluss von Medien. Sie sehen, wie Menschen Medien nutzen und wie Menschen dadurch in die Irre geführt werden können."
                ]
              },
              "mediawijsheid": {
                "label": "Medienkompetenz: Umgang mit Medien",
                "tips": [
                  "Soziale Medien sind nicht mehr wegzudenken. Social Media spielt nicht nur im Privatleben eine Rolle, sondern auch am Arbeitsplatz. Wie gehen Sie als Mitarbeiter mit Social Media um? Gewinnen Sie mit dem Workshop <a href=\"https://www.ubrijk.nl/service/ advocaten-en-adviseurs-laborsrecht/masterclasses-en-trainingen/social-media\" Einblicke in und Tools für die Nutzung sozialer Medien als Beamter -und-Beamte\" target=\"_blank\">Soziale Medien und Beamte</a>.",
                  "Soziale Medien sind nicht mehr wegzudenken. Social Media spielt nicht nur im Privatleben eine Rolle, sondern auch am Arbeitsplatz. Wie gehen Sie als Mitarbeiter mit Social Media um? Gewinnen Sie mit dem Workshop <a href=\"https://www.ubrijk.nl/service/ advocaten-en-adviseurs-laborsrecht/masterclasses-en-trainingen/social-media\" Einblicke in und Tools für die Nutzung sozialer Medien als Beamter -und-Beamte\" target=\"_blank\">Soziale Medien und Beamte</a>.",
                  "Sie sind sich der Stärken und Schwächen moderner Medien bewusst, aber es gibt noch Raum für Verbesserungen. Bei der Medienkompetenz geht es nicht darum, ob man Medien häufig nutzt. Medienkompetenz ist oft eine Frage der bewussten Abwägung der Vor- und Nachteile. Geben Sie sich Raum, Ihre Entscheidungen (über)zu überdenken. Sind Sie vielleicht zu kritisch und vorsichtig und nutzen daher die Möglichkeiten moderner Medien nicht ausreichend? Oder sind Sie sich der Risiken nicht ausreichend bewusst und machen sich deshalb angreifbar?",
                  "Nutzen Sie Ihre aktive und scharfsinnige Einstellung zur Medienkompetenz. Bleiben Sie über die neuesten technologischen Entwicklungen auf dem Laufenden und abonnieren Sie das <a href=\"https://ibestuur.nl/nieuwsbrief/\" target=\"_blank\">iBestuur-Magazin</a>.",
                  "Nutzen Sie Ihre aktive und scharfsinnige Einstellung zur Medienkompetenz. Bleiben Sie über die neuesten technologischen Entwicklungen auf dem Laufenden und abonnieren Sie das <a href=\"https://ibestuur.nl/nieuwsbrief/\" target=\"_blank\">iBestuur-Magazin</a>."
                ]
              },
              "schuwheid": {
                "descriptions": [
                  "Im Vergleich zu anderen nutzen Sie soziale Medien kaum.",
                  "Sie nutzen soziale Medien weniger als der Durchschnitt.",
                  "Sie nutzen soziale Medien ungefähr genauso häufig wie andere.",
                  "Im Vergleich zu anderen nutzen Sie soziale Medien häufiger.",
                  "Im Vergleich zu anderen nutzen Sie häufig soziale Medien."
                ],
                "label": "Schüchternheit",
                "tips": [
                  "Sie nutzen soziale/digitale Medien kaum oder gar nicht. Viele Menschen nutzen soziale Medien, um informiert zu bleiben oder Kontakte zu pflegen. Könnte es sein, dass Ihnen Informationen oder Möglichkeiten fehlen, weil Sie online nicht aktiv sind? Oder sehen Sie es als Vorteil, dass Sie selbst entscheiden, welche Informationen Sie erhalten?",
                  "Sie nutzen soziale/digitale Medien relativ wenig. Viele Menschen nutzen soziale Medien, um informiert zu bleiben oder Kontakte zu pflegen. Könnte es sein, dass Ihnen Informationen oder Möglichkeiten fehlen, weil Sie online nicht aktiv sind? Oder sehen Sie es als Vorteil, dass Sie selbst entscheiden, welche Informationen Sie erhalten?",
                  "Sie nutzen regelmäßig soziale/digitale Medien. Dies kann Ihnen helfen, informiert zu bleiben und Kontakte zu pflegen. Aber auch solche Medien können ein verzerrtes Bild der Realität vermitteln.",
                  "Du nutzt überdurchschnittlich soziale/digitale Medien. Dies hält Sie wahrscheinlich über das aktuelle Geschehen (online) auf dem Laufenden und hilft Ihnen, Kontakte zu pflegen. Beachten Sie jedoch, dass diese Art von Medien ein verzerrtes Bild der Realität vermitteln kann.",
                  "Du nutzt soziale/digitale Medien relativ häufig. Dies bedeutet wahrscheinlich, dass Sie gut darüber informiert sind, was (online) vor sich geht. Es hilft auch, Ihre Kontakte zu pflegen. Beachten Sie jedoch, dass diese Art von Medien ein verzerrtes Bild der Realität vermitteln kann."
                ]
              },
              "trend": {
                "description": "Haben Sie ein Auge für Trends",
                "label": "Trend",
                "tips": [
                  "Sie haben kein gutes Gespür für Trends. Es geht Ihnen nicht darum, was beliebt ist und was nicht.",
                  "Du hast nicht wirklich ein Gespür für Trends. Sie machen sich keine großen Gedanken darüber, was beliebt ist und was nicht.",
                  "Sie versuchen, mit den Trends Schritt zu halten. Ihnen fallen Dinge auf, die sehr beliebt sind oder veraltet sind. Aber Sie sind auch nicht jemand, der sofort allen Trends folgt.",
                  "Sie bleiben über Trends informiert. Normalerweise erkennt man, was beliebt ist, und sieht darin Dinge, die veraltet sind.",
                  "Sie bleiben über Trends bestens informiert. Sie haben Einblick in das, was beliebt ist, und bemerken, wenn Dinge veraltet sind."
                ]
              }
            }
          },
          "no_data": "(Es sind noch keine Ergebnisse verfügbar)",
          "no_relevant_data": "(Es sind noch keine Ergebnisse verfügbar, für die Widgets bestehen)",
          "personality": {
            "descriptions": {
              "competitie": "Besser sein wollen als andere.",
              "conformisme": "Sich an geltende Normen und Werte anpassen können. Autorität respektieren.",
              "contactbehoefte": "Bedürfnis nach Gesellschaft.",
              "dominantie": "Die Führungsrolle übernehmen. Eine entscheidende Rolle in der Zusammenarbeit spielen.",
              "energie": "Die Energie haben, viele Dinge in Angriff zu nehmen.",
              "frustratietolerantie": "Sich anderen gegenüber tolerant zeigen.",
              "hartelijkheid": "Anderen gegenüber freundlich, fröhlich und heiter sein.",
              "incasseringsvermogen": "Mit Kritik und Rückschlägen angemessen umgehen können.",
              "nauwkeurigheid": "Sorgfältig arbeiten, mit Auge fürs Detail.",
              "onafhankelijkheid": "Aufgaben auf eigene Art und Weise erledigen wollen.",
              "ordelijkheid": "Selbst Struktur schaffen.",
              "originaliteit": "Neue Lösungen finden. Kreativ sein.",
              "positivisme": "Eine positive Lebenseinstellung haben.",
              "regelmaat": "Bedürfnis nach Ordnung und Regeln haben.",
              "sociaal_ontspannen": "Sich im Umgang mit anderen entspannt fühlen.",
              "status": "Bestmögliche Resultate erzielen wollen. Beruflich aufsteigen wollen.",
              "vernieuwing": "Die Veränderung und das Sammeln neuer Erfahrungen sehr mögen.",
              "vertrouwen": "An gute Absichten anderer glauben.",
              "volharding": "Einsatz zeigen. Aufgaben und Vereinbarungen ernst nehmen und erfüllen.",
              "weloverwogen": "Sorgfältig nachdenken bevor gehandelt wird.",
              "zelfonthulling": "Eigene Gefühle mit anderen teilen wollen.",
              "zelfontwikkeling": "Bestrebt sein, die eigenen Qualitäten voll auszuschöpfen.",
              "zelfvertoon": "Gern im Mittelpunkt der Aufmerksamkeit stehen.",
              "zelfvertrouwen": "Selbstsicher sein.",
              "zorgzaamheid": "Mit anderen mitfühlen. Anderen gern helfen wollen."
            },
            "header": "Persönlichkeit",
            "keys": {
              "competitie": "Wetteifer",
              "conformisme": "Konformismus",
              "contactbehoefte": "Kontaktbedürfnis",
              "dominantie": "Dominanz",
              "energie": "Energie",
              "frustratietolerantie": "Frusttoleranz",
              "hartelijkheid": "Herzlichkeit",
              "incasseringsvermogen": "Belastbarkeit",
              "nauwkeurigheid": "Präzision",
              "onafhankelijkheid": "Unabhängigkeit",
              "ordelijkheid": "Ordnung",
              "originaliteit": "Originalität",
              "positivisme": "Positivismus",
              "regelmaat": "Regelmäßigkeit",
              "sociaal_ontspannen": "Sozial entspannt",
              "status": "Status",
              "vernieuwing": "Veränderungsbedürfnis",
              "vertrouwen": "Vertrauen",
              "volharding": "Durchhaltevermögen",
              "weloverwogen": "Durchdacht",
              "zelfonthulling": "Selbstoffenbarung",
              "zelfontwikkeling": "Selbstentwicklung",
              "zelfvertoon": "Selbstdarstellung",
              "zelfvertrouwen": "Selbstvertrauen",
              "zorgzaamheid": "Fürsorglichkeit"
            },
            "titles": [
              "Ihre prägendsten Eigenschaften",
              "Ihre prägenden Eigenschaften",
              "Eigenschaften, die Sie am wenigsten prägen"
            ]
          },
          "thinking": {
            "average": {
              "benedengemiddeld": "Unterdurchschnittlich",
              "bovengemiddeld": "Überdurchschnittlich",
              "gemiddeld": "Durchschnitt",
              "hoog": "Hoch",
              "laag": "Niedrig"
            },
            "header": "Fähigkeiten des 21. Jahrhunderts",
            "skills": {
              "communiceren": {
                "descriptions": {
                  "benedengemiddeld": "Im 21. Jahrhundert erfolgt die Kommunikation zunehmend schriftlich, beispielsweise per E-Mail oder Messenger-Apps. Für eine gute Kommunikation ist es wichtig, dass Ihre Botschaft eine gute Struktur hat und von anderen verstanden werden kann. Sie müssen auch den Empfänger Ihrer Nachricht berücksichtigen. Im Vergleich zu anderen Regierungsmitarbeitern fällt Ihnen die Kommunikation naturgemäß weniger leicht.",
                  "bovengemiddeld": "Im 21. Jahrhundert erfolgt die Kommunikation zunehmend schriftlich, beispielsweise per E-Mail oder Messenger-Apps. Für eine gute Kommunikation ist es wichtig, dass Ihre Botschaft eine gute Struktur hat und von anderen verstanden werden kann. Sie müssen auch den Empfänger Ihrer Nachricht berücksichtigen. Im Vergleich zu anderen Regierungsangestellten fällt Ihnen die Kommunikation auf natürliche Weise relativ leicht.",
                  "gemiddeld": "Im 21. Jahrhundert erfolgt die Kommunikation zunehmend schriftlich, beispielsweise per E-Mail oder Messenger-Apps. Für eine gute Kommunikation ist es wichtig, dass Ihre Botschaft eine gute Struktur hat und von anderen verstanden werden kann. Sie müssen auch den Empfänger Ihrer Nachricht berücksichtigen. Im Vergleich zur Referenzgruppe der Staatsbediensteten verfügen Sie über eine durchschnittliche Kommunikationsfähigkeit.",
                  "hoog": "Im 21. Jahrhundert erfolgt die Kommunikation zunehmend schriftlich, beispielsweise per E-Mail oder Messenger-Apps. Für eine gute Kommunikation ist es wichtig, dass Ihre Botschaft eine gute Struktur hat und von anderen verstanden werden kann. Sie müssen auch den Empfänger Ihrer Nachricht berücksichtigen. Sie verfügen von Natur aus über eine ausgeprägte Kommunikationsfähigkeit.",
                  "laag": "Im 21. Jahrhundert erfolgt die Kommunikation zunehmend schriftlich, beispielsweise per E-Mail oder Messenger-Apps. Für eine gute Kommunikation ist es wichtig, dass Ihre Botschaft eine gute Struktur hat und von anderen verstanden werden kann. Sie müssen auch den Empfänger Ihrer Nachricht berücksichtigen. Im Vergleich zu anderen Regierungsangestellten verfügen Sie über eine geringere Kommunikationsfähigkeit."
                },
                "label": "Kommunizieren"
              },
              "creatief_denken": {
                "descriptions": {
                  "benedengemiddeld": "Im Vergleich zu anderen Staatsbediensteten kommen Sie naturgemäß seltener auf neue Ideen und Anwendungen. Kreative Menschen haben originelle Ideen. Sie haben oft ein geringeres Bedürfnis nach Struktur und Sicherheit. Kreatives Denken hilft Ihnen, neue Möglichkeiten zu erkennen und sich weiterzuentwickeln.",
                  "bovengemiddeld": "Im Vergleich zu anderen Beamten fällt es Ihnen natürlich leichter, neue Ideen und Anwendungen zu entwickeln. Kreative Menschen haben originelle Ideen. Sie haben oft ein geringeres Bedürfnis nach Struktur und Sicherheit. Kreatives Denken hilft Ihnen, neue Möglichkeiten zu erkennen und sich weiterzuentwickeln. Suchen Sie bewusst nach Situationen, in denen Sie diese Fähigkeit anwenden und aufrechterhalten können.",
                  "gemiddeld": "Im Vergleich zu anderen Regierungsmitarbeitern verfügen Sie über eine durchschnittliche Begabung für die Entwicklung neuer Ideen und Anwendungen. Kreative Menschen haben originelle Ideen. Sie haben oft ein geringeres Bedürfnis nach Struktur und Sicherheit. Kreatives Denken hilft Ihnen, neue Möglichkeiten zu erkennen und sich weiterzuentwickeln. Hier gibt es Raum für Entwicklung.",
                  "hoog": "Im Vergleich zu anderen Beamten fällt es Ihnen natürlich leicht, neue Ideen und Anwendungen zu entwickeln. Kreative Menschen haben originelle Ideen. Sie haben oft ein geringeres Bedürfnis nach Struktur und Sicherheit. Kreatives Denken hilft Ihnen, neue Möglichkeiten zu erkennen und sich weiterzuentwickeln. Suchen Sie bewusst nach Situationen, in denen Sie diese Fähigkeit anwenden und aufrechterhalten können.",
                  "laag": "Im Vergleich zu anderen Beamten fällt es Ihnen naturgemäß schwerer, neue Ideen und Anwendungen zu entwickeln. Kreative Menschen haben originelle Ideen. Sie haben oft ein geringeres Bedürfnis nach Struktur und Sicherheit. Kreatives Denken hilft Ihnen, neue Möglichkeiten zu erkennen und sich weiterzuentwickeln."
                },
                "label": "Kreatives Denken"
              },
              "kritisch_denken": {
                "descriptions": {
                  "benedengemiddeld": "Kritische Denker sind unabhängig und haben Spaß daran, den Dingen auf den Grund zu gehen. Kritisches Denken hält Sie wach und stellt sicher, dass Sie sich nicht einfach auf das verlassen, was uns die Technologie sagt. Ihre natürliche Fähigkeit, ein Problem kritisch und tiefgreifend anzugehen, ist im Vergleich zu anderen Regierungsmitarbeitern unterdurchschnittlich.",
                  "bovengemiddeld": "Kritische Denker sind unabhängig und haben Spaß daran, den Dingen auf den Grund zu gehen. Ihre natürliche Fähigkeit, ein Problem kritisch und tiefgreifend anzugehen, ist überdurchschnittlich. Kritisches Denken hält Sie wach und stellt sicher, dass Sie sich nicht einfach auf das verlassen, was uns die Technologie sagt.",
                  "gemiddeld": "Kritische Denker sind unabhängig und haben Spaß daran, den Dingen auf den Grund zu gehen. Kritisches Denken hält Sie wach und stellt sicher, dass Sie sich nicht einfach auf das verlassen, was uns die Technologie sagt. Ihre natürliche Fähigkeit, ein Problem kritisch und tiefgreifend anzugehen, ist mit der eines durchschnittlichen Regierungsangestellten vergleichbar.",
                  "hoog": "Kritische Denker sind unabhängig und haben Spaß daran, den Dingen auf den Grund zu gehen. Ihre natürliche Fähigkeit, ein Problem kritisch und tiefgründig anzugehen, ist stark ausgeprägt. Kritisches Denken hält Sie wach und stellt sicher, dass Sie sich nicht einfach auf das verlassen, was uns die Technologie sagt.",
                  "laag": "Kritische Denker sind unabhängig und haben Spaß daran, den Dingen auf den Grund zu gehen. Kritisches Denken hält Sie wach und stellt sicher, dass Sie sich nicht einfach auf das verlassen, was uns die Technologie sagt. Ihre natürliche Fähigkeit, ein Problem kritisch und tiefgreifend anzugehen, ist weniger ausgeprägt als die anderer Regierungsangestellter."
                },
                "label": "Kritisches Denken"
              },
              "probleem_oplossen": {
                "descriptions": {
                  "benedengemiddeld": "Menschen mit starken Fähigkeiten zur Problemlösung können Schläge aushalten und geben nicht so schnell auf. Sie sind beharrlich und gewinnen leicht andere für ihre Ideen. Dadurch wissen sie auch, wie man komplexe Probleme oder langfristige Konflikte löst. Im Vergleich zu anderen Staatsbediensteten ist Ihre Problemlösungsfähigkeit unterdurchschnittlich.",
                  "bovengemiddeld": "Menschen mit starken Fähigkeiten zur Problemlösung können Schläge aushalten und geben nicht so schnell auf. Sie sind beharrlich und gewinnen leicht andere für ihre Ideen. Dadurch wissen sie auch, wie man komplexe Probleme oder langfristige Konflikte löst. Ihre Fähigkeit zur Problemlösung ist überdurchschnittlich.",
                  "gemiddeld": "Menschen mit starken Fähigkeiten zur Problemlösung können Schläge aushalten und geben nicht so schnell auf. Sie sind beharrlich und gewinnen leicht andere für ihre Ideen. Dadurch wissen sie auch, wie man komplexe Probleme oder langfristige Konflikte löst. Ihre Fähigkeit, Probleme zu lösen, ist mit der eines durchschnittlichen Regierungsangestellten vergleichbar.",
                  "hoog": "Menschen mit starken Fähigkeiten zur Problemlösung können Schläge aushalten und geben nicht so schnell auf. Sie sind beharrlich und gewinnen leicht andere für ihre Ideen. Dadurch wissen sie auch, wie sie komplexe Probleme oder langfristige Konflikte erfolgreich lösen können. Im Vergleich zum durchschnittlichen Regierungsangestellten verfügen Sie über eine ausgeprägte Fähigkeit, Probleme zu lösen.",
                  "laag": "Menschen mit starken Fähigkeiten zur Problemlösung können Schläge aushalten und geben nicht so schnell auf. Sie sind beharrlich und gewinnen leicht andere für ihre Ideen. Dadurch wissen sie auch, wie man komplexe Probleme oder langfristige Konflikte löst. Im Vergleich zum durchschnittlichen Regierungsangestellten haben Sie eine weniger ausgeprägte Fähigkeit, Probleme zu lösen."
                },
                "label": "Probleme lösen"
              },
              "samenwerken": {
                "descriptions": {
                  "benedengemiddeld": "Sie haben naturgemäß eine geringere Fähigkeit zur Zusammenarbeit als der durchschnittliche Beamte. Um gut zusammenzuarbeiten, müssen Sie den Mut haben, anderen zu vertrauen und gut miteinander zu kommunizieren. Man muss auch Verantwortung übernehmen.",
                  "bovengemiddeld": "Sie verfügen von Natur aus über ein überdurchschnittliches Talent zur Zusammenarbeit. Die Fähigkeit zur Zusammenarbeit besteht darin, Verantwortung zu übernehmen, Vertrauen zu wagen und miteinander zu kommunizieren.",
                  "gemiddeld": "Im Vergleich zu anderen Regierungsmitarbeitern verfügen Sie von Natur aus über eine durchschnittliche Begabung zur Zusammenarbeit. Um gut zusammenzuarbeiten, müssen Sie den Mut haben, anderen zu vertrauen und gut miteinander zu kommunizieren. Man muss auch Verantwortung übernehmen.",
                  "hoog": "Sie verfügen von Natur aus über eine ausgeprägte Begabung zur Zusammenarbeit. Die Fähigkeit zur Zusammenarbeit besteht darin, Verantwortung zu übernehmen, Vertrauen zu wagen und miteinander zu kommunizieren.",
                  "laag": "Sie sind naturgemäß weniger begabt zur Zusammenarbeit als der durchschnittliche Beamte. Um gut zusammenzuarbeiten, müssen Sie den Mut haben, anderen zu vertrauen und gut miteinander zu kommunizieren. Man muss auch Verantwortung übernehmen."
                },
                "label": "Zusammenarbeiten"
              },
              "sociale_culturele_vaardigheden": {
                "descriptions": {
                  "benedengemiddeld": "Im Vergleich zur Referenzgruppe fällt es Ihnen naturgemäß schwerer, den Hintergrund einer Person zu berücksichtigen. Um gut mit Menschen unterschiedlicher Herkunft interagieren zu können, ist es wichtig, dass Sie für unterschiedliche Ansichten und Ideen offen sind. Sie benötigen außerdem gute soziale Netzwerke und müssen offen für Weiterentwicklung sein.",
                  "bovengemiddeld": "Im Vergleich zur Referenzgruppe berücksichtigt man natürlich problemlos den Hintergrund einer Person. Um gut mit Menschen unterschiedlicher Herkunft interagieren zu können, ist es wichtig, dass Sie für unterschiedliche Ansichten und Ideen offen sind. Sie benötigen außerdem gute soziale Netzwerke und sind offen für Weiterentwicklung.",
                  "gemiddeld": "Im Vergleich zur Referenzgruppe schneiden Sie bei der Berücksichtigung des Hintergrunds einer Person ungefähr so gut wie der Durchschnitt ab. Um gut mit Menschen unterschiedlicher Herkunft interagieren zu können, ist es wichtig, dass Sie für unterschiedliche Ansichten und Ideen offen sind. Sie benötigen außerdem gute soziale Netzwerke und müssen offen für Weiterentwicklung sein.",
                  "hoog": "Im Vergleich zur Referenzgruppe berücksichtigt man natürlich problemlos den Hintergrund einer Person. Um gut mit Menschen unterschiedlicher Herkunft interagieren zu können, ist es wichtig, dass Sie für unterschiedliche Ansichten und Ideen offen sind. Sie benötigen außerdem gute soziale Netzwerke und sind offen für Weiterentwicklung.",
                  "laag": "Im Vergleich zur Referenzgruppe fällt es Ihnen naturgemäß schwer, den Hintergrund einer Person zu berücksichtigen. Um gut mit Menschen unterschiedlicher Herkunft interagieren zu können, ist es wichtig, dass Sie für unterschiedliche Ansichten und Ideen offen sind. Sie benötigen außerdem gute soziale Netzwerke und müssen offen für Weiterentwicklung sein."
                },
                "label": "Soziale und kulturelle Fähigkeiten"
              },
              "zelfregulering": {
                "descriptions": {
                  "benedengemiddeld": "Im Beruf wird es heute und sicherlich auch in Zukunft immer wichtiger, dass Sie als Mitarbeiter mehr tun, als nur Befehlen Folge zu leisten. Deshalb ist es wichtig, dass Sie die Verantwortung dafür übernehmen, Ihre Arbeit selbst zu planen und sie wie geplant abzuschließen. Im Vergleich zu anderen Staatsbediensteten fällt es Ihnen naturgemäß etwas schwerer, Ihre eigene Arbeit zu regulieren.",
                  "bovengemiddeld": "Im Beruf wird es heute und sicherlich auch in Zukunft immer wichtiger, dass Sie als Mitarbeiter mehr tun, als nur Befehlen Folge zu leisten. Deshalb ist es wichtig, dass Sie die Verantwortung dafür übernehmen, Ihre Arbeit selbst zu planen und sie wie geplant abzuschließen. Im Vergleich zu anderen Staatsbediensteten verfügen Sie über eine überdurchschnittliche Fähigkeit, Ihre Arbeit zu regeln.",
                  "gemiddeld": "Im Beruf wird es heute und sicherlich auch in Zukunft immer wichtiger, dass Sie als Mitarbeiter mehr tun, als nur Befehlen Folge zu leisten. Deshalb ist es wichtig, dass Sie die Verantwortung dafür übernehmen, Ihre Arbeit selbst zu planen und sie wie geplant abzuschließen. Im Vergleich zu anderen Regierungsangestellten verfügen Sie über eine durchschnittliche Begabung hierfür.",
                  "hoog": "Im Beruf wird es heute und sicherlich auch in Zukunft immer wichtiger, dass Sie als Mitarbeiter mehr tun, als nur Befehlen Folge zu leisten. Deshalb ist es wichtig, dass Sie die Verantwortung dafür übernehmen, Ihre Arbeit selbst zu planen und sie wie geplant abzuschließen. Sie haben ein natürliches Talent für die Regulierung Ihrer Arbeit.",
                  "laag": "Im Beruf wird es heute und sicherlich auch in Zukunft immer wichtiger, dass Sie als Mitarbeiter mehr tun, als nur Befehlen Folge zu leisten. Deshalb ist es wichtig, dass Sie die Verantwortung dafür übernehmen, Ihre Arbeit selbst zu planen und sie wie geplant abzuschließen. Die Regulierung Ihrer Arbeit liegt Ihnen naturgemäß weniger gut."
                },
                "label": "Selbstregulierung"
              }
            },
            "titles": {
              "thinking_styles": "Denkstile: Wie arbeite ich mit Technologie zusammen?",
              "work_competences": "Arbeitskompetenzen: Wie erledige ich meine Arbeit?"
            }
          },
          "title": "Persönliche Ergebnisübersicht",
          "values": {
            "descriptions": {
              "demotivators": {
                "aandacht": "Am liebsten bleiben Sie im Hintergrund. Wenn Sie sich profilieren müssen, gefällt Ihnen das nicht.",
                "analyseren": "Das Analysieren von Problemen erfahren Sie als demotivierend und ist etwas, das Sie lieber nicht tun.",
                "autonomie": "Autonomie und Selbstständigkeit streben Sie nicht an. Lieber beratschlagen Sie sich mit anderen und lassen andere Entscheidungen für Sie treffen.",
                "beinvloeden": "Sie mögen es nicht, wenn Sie andere beeinflussen müssen.",
                "carriere": "Möglicherweise haben Sie eine Aversion gegen Status und Geltungsdrang. Wenn Sie sich danach richten sollen, ist das wahrscheinlich eher demotivierend für Sie.",
                "concrete_resultaten": "Es wird Sie eher demotivieren als stimulieren, wenn Sie konkrete Resultate erzielen müssen. Sie sind möglicherweise eher prozessorientiert als ergebnisorientiert.",
                "creatief_denken": "Wenn an Ihre Kreativität appelliert wird, demotiviert Sie das eher, als dass es Sie stimuliert.",
                "financiele_beloning": "Finanzielle Zielsetzungen demotivieren Sie. Die finanzielle Seite einer Sache interessiert Sie am allerwenigsten von allem, und wenn Sie gerade dadurch stimuliert werden sollen, kann Sie das demotivieren.",
                "fysiek_actief_zijn": "Sie sind in Ihrer Arbeit lieber nicht körperlich aktiv. Wenn zu viel körperliche Anstrengung geleistet werden muss, erfahren Sie das möglicherweise als negativ.",
                "hectiek": "Sie bevorzugen eine ruhige Umgebung, in der Sie wissen, was auf Sie zukommt und in der Sie ungestört Ihrer Arbeit nachgehen können.",
                "hulp_verlenen": "Selbst direkt anderen helfen demotiviert Sie.",
                "kwaliteit": "Es geht Ihnen wahrscheinlich eher um das große Ganze als um die Details. Über hohe Qualitätsanforderungen ärgern Sie sich.",
                "ondernemen": "Sie finden es in Ordnung, wenn andere die Initiative ergreifen. Von Ihnen braucht man das nicht zu verlangen; das würde Sie gerade demotivieren.",
                "ontwikkelen": "Sie brauchen sich selbst nicht unbedingt weiterzuentwickeln. Das tun Sie auch nicht so gern. Vielleicht finden Sie, dass Sie mit Ihren jetzigen Qualitäten schon genug können.",
                "samenwerking": "Sie arbeiten lieber allein als zusammen mit anderen. Zusammenzuarbeiten und vor allem sich aufeinander abstimmen zu müssen frustriert Sie eher, als dass es Ihnen Spaß macht.",
                "taakuitdaging": "Neue und schwierige Herausforderungen demotivieren Sie. Lieber haben Sie das Gefühl, Ihren Aufgaben ganz sicher gewachsen zu sein.",
                "waardering_en_erkenning": "Für Wertschätzung und Anerkennung sind Sie nicht empfänglich, Sie sind dem sogar eher abgeneigt.",
                "zekerheid_en_stabiliteit": "Sicherheit und Stabilität demotivieren Sie. Sie finden es besser, wenn Sie nicht genau wissen, was Sie erwartet und wenn Sie überrascht werden.",
                "zinvolle_bijdrage": "Sie sind kein Idealist. Stärker noch, wahrscheinlich demotiviert es Sie, wenn Sie für eine sozial ausgerichtete Organisation arbeiten müssen."
              },
              "neutral_motivators": {
                "aandacht": "Sie suchen nicht ständig Gelegenheiten, in den Vordergrund zu treten. Ganz im Gegenteil, Sie finden es prima, wenn nicht alle Augen auf Sie gerichtet sind.",
                "analyseren": "Das Analysieren von Informationen und Problemen erfahren Sie nicht als motivierend, aber Sie finden es auch nicht wirklich schlimm.",
                "autonomie": "Selbstständigkeit und Autonomie bei der Arbeit motivieren Sie nicht in hohem Maße. Sie haben kein Problem damit, die Verantwortung an andere abzugeben.",
                "beinvloeden": "Einfluss zu haben finden Sie nicht so wichtig. Sie können sich auch von anderen leiten lassen.",
                "carriere": "Status und Karriere machen motivieren Sie nur in geringem Maße. Sie streben nicht unbedingt danach, eine Position mit Anerkennung zu erlangen.",
                "concrete_resultaten": "Sie müssen nicht unbedingt auf deutliche oder konkrete Ziele hinarbeiten. Sie können auch eher prozessorientiert arbeiten.",
                "creatief_denken": "Sie brauchen in Ihrer Arbeit nicht selbst kreativ zu sein oder kreativ denken zu können.",
                "financiele_beloning": "Finanzielle Belohnungen können Sie als angenehm erfahren, aber Sie finden es nicht besonders wichtig.",
                "fysiek_actief_zijn": "Sie stehen Bewegung und körperlicher Aktivität bei Ihrer Arbeit neutral gegenüber.",
                "hectiek": "Dynamik motiviert Sie nicht besonders. Sie möchten gern genug Zeit haben, um Dinge zu Ende zu bringen und sich auf Ihre Arbeit zu konzentrieren.",
                "hulp_verlenen": "Anderen zu helfen motiviert Sie nicht unbedingt, Sie suchen jedenfalls nicht aktiv danach.",
                "kwaliteit": "Hohe Qualitätsnormen motivieren Sie nicht so sehr. Sie erfahren das nicht als Herausforderung.",
                "ondernemen": "Sie brauchen nicht immer den Freiraum, selbst die Initiative ergreifen zu können.",
                "ontwikkelen": "Im Moment ist sich selbst weiterzuentwickeln nicht sehr motivierend für Sie. Wahrscheinlich wollen Sie vor allem das Wissen und die Fähigkeiten anwenden, die sie bereits haben.",
                "samenwerking": "Sie stehen neutral gegenüber Zusammenarbeit mit anderen. Sie können in einer Gruppe arbeiten, aber auch prima allein funktionieren. Sie müssen nicht unbedingt irgendwo dazugehören oder in einem Team arbeiten.",
                "taakuitdaging": "Für Sie besteht die Herausforderung nicht so sehr aus dem Schwierigkeitsgrad Ihrer Arbeit. Sie brauchen auch nicht ständig mit neuen Herausforderungen konfrontiert zu werden.",
                "waardering_en_erkenning": "Wertschätzung und Anerkennung finden Sie zwar schön, aber das motiviert Sie nur in geringem Maße. Sie richten sich wahrscheinlich eher nach Ihrer eigenen Meinung, als dass Sie sich das Feedback von anderen zu Herzen nehmen.",
                "zekerheid_en_stabiliteit": "Sicherheit und Stabilität sind für Sie nicht so wichtig. Sie müssen nicht unbedingt wissen, was Sie erwartet.",
                "zinvolle_bijdrage": "Einen sinnvollen Beitrag an die Gesellschaft zu leisten motiviert Sie nicht unbedingt. Sie finden es nicht wichtig, mit Ihrer Arbeit etwas für andere zu tun."
              },
              "primary_motivators": {
                "aandacht": "Sie stehen gern im Vordergrund und es motiviert Sie, wenn alle Augen auf Sie gerichtet sind. Sie können es genießen, wenn andere zu Ihnen aufschauen. Eine Stellung, mit der ein gewisses Maß an Status verbunden ist, finden Sie gut. Ein Risiko davon könnte sein, dass Sie zu Unrecht Aufmerksamkeit einfordern oder dass Sie anderen zu wenig Spielraum lassen.",
                "analyseren": "Sie finden es motivierend, Analysen durchzuführen und über offene Fragen und Probleme nachzudenken. Sie mögen schwierige Rätsel und Denkarbeit. Ein Risiko davon könnte sein, dass Sie zu lange nachdenken und sich zu lange an einem Problem festbeißen.",
                "autonomie": "Sie finden es motivierend, eigenständig arbeiten zu können und selbst zu bestimmen, wie Sie Ihre Arbeit verrichten. Freiheit und Unabhängigkeit motivieren Sie sehr. Wenn andere Sie an der kurzen Leine halten oder direkt lenken, kann das bei Ihnen für Ärgernis sorgen. Ein Risiko davon könnte sein, dass andere Sie zu unabhängig und möglicherweise dickköpfig finden. Eine gute Eingliederung in eine Organisation ist für Sie wichtig. Als Freiberufler zu arbeiten könnte eine Option für Sie sein.",
                "beinvloeden": "Es motiviert Sie andere zu beeinflussen. Gern sehen Sie, dass Ihre Meinung in Entscheidungen und Prozessen Eingang findet. Andere zu überzeugen und zu motivieren spricht Sie an. Ein Risiko könnte sein, dass die Meinung von anderen nicht so schnell zu Ihnen durchdringt oder dass Sie Ihre Meinung zu oft durchsetzen.",
                "carriere": "Sie finden es wichtig, auf Ihrer Arbeit etwas zu erreichen, höher hinaus zu kommen. Status und Ansehen spielen hierbei eine Rolle. Dabei können Sie kompetitiv eingestellt sein und sich mit anderen messen. Ein Risiko könnte sein, dass Sie sich zu viel auf den äußeren Schein richten. Sorgen Sie dafür, dass Sie sich selbst nicht verlieren und auch intrinsisch motiviert bleiben.",
                "concrete_resultaten": "Sie streben in Ihrer Arbeit nach einem deutlichen und konkreten Resultat. Sie finden ein deutliches Resultat, in dem Ihr direkter Beitrag erkennbar ist, motivierend. Ein Risiko könnte sein, dass Sie nachlassen, wenn nicht regelmäßig konkrete Resultate erreicht werden.",
                "creatief_denken": "Sie wollen gern Ihre Kreativität nutzen, Ideen entwickeln und um die Ecke denken. Dabei suchen Sie oft nach neuen Lösungen. Ein Risiko könnte sein, dass Sie zu viele Ideen haben um sie alle ausführen zu können oder dass nicht alle Ideen wirklich brauchbar sind.",
                "financiele_beloning": "Eine gute finanzielle Belohnung motiviert Sie. Finanzielle Anregungen wie Zielsetzungen und variable Belohnung motivieren Sie extra. Das gilt auch für das Erreichen finanzieller Resultate für Ihre Auftraggeber oder Arbeitgeber. Ein Risiko davon kann sein, dass Sie auf Grund der finanziellen Anregungen etwas anfangen, was Sie besser nicht tun sollten, zum Beispiel, weil es Ihnen dann in anderer Hinsicht weniger Erfüllung bietet.",
                "fysiek_actief_zijn": "Sie sind gern körperlich aktiv. Bewegung, Sport und körperliche Aktivität erfahren Sie als motivierend. Das könnte heißen, dass Sie gern körperliche Arbeit verrichten. Aber weil das nicht immer möglich ist, brauchen Sie in ihrem Privatleben auf jeden Fall die Möglichkeit Dampf abzulassen, zum Beispiel durch Sport. Ein Risiko könnte sein, dass Sie für echte Büroarbeit zu unruhig sind.",
                "hectiek": "Sie mögen Dynamik und Betriebsamkeit um sich herum: eine hektische Umgebung spornt Sie an. Auch haben Sie selbst gern viel zu tun. Wenn es zu ruhig auf Arbeit ist, können Sie nachlassen und sich möglicherweise schlechter konzentrieren.",
                "hulp_verlenen": "Wenn Sie anderen einen Dienst erweisen oder bei einer konkreten Aktivität helfen können, erfüllt Sie das. Sie richten sich wahrscheinlich auch sehr nach dem Wohlehrgehen anderer. Ein Risiko davon ist, dass Sie dabei zu weit gehen und Ihre Grenzen überschreiten.",
                "kwaliteit": "Es spornt Sie an, auf einem hohen Qualitätsniveau zu arbeiten. Gut ist nicht immer gut genug für Sie, Sie wollen hohen Anforderungen gerecht werden. Ein Risiko könnte sein, dass nicht immer genügend Zeit dafür ist, oder dass andere nicht im selben Maße Ihren Qualitätsanforderungen gerecht werden.",
                "ondernemen": "Sie führen gern eine unternehmerische Tätigkeit aus. Sie mögen es, die Initiative zu ergreifen und Chancen zu nutzen. Das können Sie als selbstständiger Unternehmer tun oder innerhalb einer Organisation, die Ihnen den Freiraum gibt unternehmerisch tätig zu sein. Ein Nachteil dieser Motivation könnte sein, dass Sie zu optimistisch sind und zu viele Risiken eingehen.",
                "ontwikkelen": "Sie legen viel Wert darauf, sich weiterzuentwickeln und neues Wissen und neue Fähigkeiten zu erwerben. Stillstand sehen Sie als Rückschritt. Oft macht es Ihnen auch Spaß, anderen bei ihrer Entwicklung zu helfen. Ein Risiko könnte sein, dass Sie sich (zu) schnell langweilen und wieder etwas Neues lernen möchten.",
                "samenwerking": "Zusammenarbeit mit anderen finden Sie sehr wichtig. Sie mögen es, in direktem Kontakt mit anderen zu stehen und zusammen an einer gemeinschaftlichen Aufgabe oder für ein gemeinschaftliches Ziel zu arbeiten. Ein Risiko könnte sein, dass Sie nachlassen, wenn Sie selbstständig oder allein arbeiten.",
                "taakuitdaging": "Sie werden von herausfordernden Aufgaben, die viel von Ihnen abverlangen, motiviert. Sie wollen gern das Maximum aus sich herausholen. Ein Risiko könnte sein, dass Sie recht schnell etwas Neues anfangen möchten. Die Herausforderung kann auch zu groß sein, wodurch das Risiko besteht, dass Dinge nicht gut gehen.",
                "waardering_en_erkenning": "Wertschätzung und Anerkennung sind für Sie sehr motivierend. Wenn andere Ihnen Komplimente machen und sich erkenntlich zeigen, spornt Sie das an. Sie tun auch viel dafür. Ein Risiko könnte sein, dass Sie zu abhängig von Wertschätzung werden. Versuchen Sie auch in der Arbeit an sich Erfüllung zu finden.",
                "zekerheid_en_stabiliteit": "Sie wollen gern Sicherheit und Stabilität. Sie finden es gut zu wissen, was Sie erwartet. In Ihrer Arbeit finden Sie es schön, deutlich definierte Aufgaben zu haben, sodass Sie wissen, was von Ihnen erwartet wird. Ein Risiko könnte sein, dass Sie weniger gut mit Veränderungen und Unsicherheiten umgehen können.",
                "zinvolle_bijdrage": "Sie liefern gern einen sinnvollen Beitrag an die Gesellschaft oder an einen guten Zweck. Sie finden es wichtig, mit Ihrer Arbeit etwas für andere zu tun. Das kann in Ihrer Funktion zum Ausdruck kommen oder in der Organisation, für die Sie arbeiten. Ein Risiko davon könnte sein, dass Sie zu idealistisch sind und damit nicht sehr realistisch oder dass Sie Ihre eigenen Bedürfnisse vernachlässigen."
              },
              "secondary_motivators": {
                "aandacht": "Sie stehen gern im Vordergrund und es motiviert Sie, wenn alle Augen auf Sie gerichtet sind. Sie können es genießen, wenn andere zu Ihnen aufschauen.",
                "analyseren": "Sie finden es motivierend, Analysen durchzuführen und über offene Fragen und Probleme nachzudenken. Sie mögen schwierige Rätsel und Denkarbeit.",
                "autonomie": "Sie finden es motivierend eigenständig arbeiten zu können und selbst zu bestimmen, wie Sie Ihre Arbeit verrichten. Freiheit und Unabhängigkeit motivieren Sie sehr.",
                "beinvloeden": "Es motiviert Sie andere zu beeinflussen. Gern sehen Sie, dass Ihre Meinung in Entscheidungen und Prozessen Eingang findet. Andere zu überzeugen und zu motivieren spricht Sie an.",
                "carriere": "Sie finden es wichtig, bei Ihrer Arbeit etwas zu erreichen, höher hinaus zu kommen. Status und Ansehen spielen hierbei eine Rolle. Dabei können Sie recht kompetitiv eingestellt sein und sich mit anderen messen.",
                "concrete_resultaten": "Sie streben in Ihrer Arbeit nach einem deutlichen und konkreten Resultat. Sie finden ein deutliches Resultat, in dem Ihr direkter Beitrag erkennbar ist, motivierend.",
                "creatief_denken": "Sie wollen Ihre Kreativität nutzen, Ideen entwickeln und um die Ecke denken. Dabei suchen Sie oft nach neuen Lösungen.",
                "financiele_beloning": "Eine gute Bezahlung motiviert Sie. Finanzielle Anregungen wie Zielsetzungen und variable Belohnung motivieren Sie zusätzlich. Das gilt auch für das Erreichen von finanziellen Resultaten für Ihre Auftraggeber oder Arbeitgeber.",
                "fysiek_actief_zijn": "Sie sind gern körperlich aktiv. Bewegung, Sport und körperliche Aktivität erfahren Sie als motivierend. Das könnte heißen, dass Sie gern körperliche Arbeit verrichten. Aber weil das nicht immer möglich ist, brauchen Sie in ihrem Privatleben auf jeden Fall die Möglichkeit Dampf abzulassen, zum Beispiel durch Sport.",
                "hectiek": "Sie mögen Dynamik und Betriebsamkeit um sich herum: eine hektische Umgebung spornt Sie an. Auch haben Sie selbst gern viel zu tun.",
                "hulp_verlenen": "Wenn Sie anderen einen Dienst erweisen oder bei einer konkreten Aktivität helfen können, erfüllt Sie das. Sie richten sich wahrscheinlich auch sehr nach dem Wohlehrgehen anderer.",
                "kwaliteit": "Es spornt Sie an, auf einem hohen Qualitätsniveau zu arbeiten. Gut ist nicht immer gut genug für Sie, Sie wollen hohen Anforderungen gerecht werden.",
                "ondernemen": "Sie führen gern eine unternehmerische Tätigkeit aus. Sie mögen es, die Initiative zu ergreifen und Chancen zu nutzen. Das können Sie auch innerhalb einer Organisation tun, die Ihnen den Freiraum gibt unternehmerisch tätig zu sein.",
                "ontwikkelen": "Sie legen viel Wert darauf, sich weiterzuentwickeln und neues Wissen und neue Fähigkeiten zu erwerben. Stillstand sehen Sie als Rückschritt. Oft macht es Ihnen auch Spaß, anderen bei ihrer Entwicklung zu helfen.",
                "samenwerking": "Zusammenarbeit mit anderen finden Sie sehr wichtig. Sie mögen es, direkten Kontakt mit anderen zu haben und zusammen an einer gemeinschaftlichen Aufgabe oder für ein gemeinschaftliches Ziel zu arbeiten.",
                "taakuitdaging": "Sie werden von herausfordernden Aufgaben, die viel von Ihnen abverlangen, motiviert. Sie wollen gern das Maximum aus sich herausholen.",
                "waardering_en_erkenning": "Wertschätzung und Anerkennung sind für Sie sehr motivierend. Wenn andere Ihnen Komplimente machen und sich erkenntlich zeigen, spornt Sie das an.",
                "zekerheid_en_stabiliteit": "Sie wollen gern Sicherheit und Stabilität. Sie finden es gut zu wissen, was Sie erwartet. In Ihrer Arbeit finden Sie es schön, deutlich definierte Aufgaben zu haben, sodass Sie wissen, was von Ihnen erwartet wird.",
                "zinvolle_bijdrage": "Sie liefern gern einen sinnvollen Beitrag an die Gesellschaft oder an einen guten Zweck. Sie finden es wichtig, mit Ihrer Arbeit etwas für andere zu tun. Das kann in Ihrer Funktion zum Ausdruck kommen oder in der Organisation, für die Sie arbeiten."
              }
            },
            "header": "Karrierewerte",
            "keys": {
              "aandacht": "Profilierung",
              "analyseren": "Analysieren",
              "autonomie": "Autonomie",
              "beinvloeden": "Einfluss ausüben",
              "carriere": "Karriere",
              "concrete_resultaten": "Konkrete Resultate",
              "creatief_denken": "Kreativ denken",
              "financiele_beloning": "Finanzielle Belohnung",
              "fysiek_actief_zijn": "Körperlich aktiv sein",
              "hectiek": "Dynamik",
              "hulp_verlenen": "Anderen helfen",
              "kwaliteit": "Qualität",
              "ondernemen": "Unternehmen",
              "ontwikkelen": "Entwickeln",
              "samenwerking": "Zusammenarbeit",
              "taakuitdaging": "Herausfordernde Aufgaben",
              "waardering_en_erkenning": "Wertschätzung und Anerkennung",
              "zekerheid_en_stabiliteit": "Sicherheit und Stabilität",
              "zinvolle_bijdrage": "Sinnvoller Beitrag"
            },
            "titles": {
              "demotivators": "Was sind Ihre Demotivatoren?",
              "neutral_motivators": "Was sind Ihre Neutrale Motivatoren?",
              "primary_motivators": "Was sind Ihre Primäre Motivatoren?",
              "secondary_motivators": "Was sind Ihre Sekundäre Motivatoren?"
            }
          }
        },
        "ixly_test": {
          "calculating_result": "Ergebnis wird berechnet...",
          "component": "Element",
          "document_option_for": "Dokumentoptionen für",
          "download_modal_header": "Auswertung herunterladen",
          "download_report": "Auswertung herunterladen",
          "generating_report": "Auswertung wird generiert ...",
          "make_visible_confirm": "Diese Aktion kann nicht rückgängig gemacht werden. Sind Sie sicher, dass Sie fortfahren möchten?",
          "no_test_selected_desc": "Wählen Sie ein Assessment über die Einstellungen dieses Elements",
          "no_test_selected_title": "Kein Assessment ausgewählt",
          "norm_group_for": "Verfügbare Normgruppen",
          "payment_required": "Ihre Organisation verfügt nicht über genügend Credits, um diese Auswertung herunterzuladen.",
          "program_subtasks": "%{program} besteht aus %{subtest_names}",
          "report_name": "Berichtsname",
          "report_settings": "Auswertungseinstellungen",
          "report_visible_for_advisor": "Ergebnis sichtbar für Coach",
          "report_visible_for_both": "Ergebnis sichtbar für beide",
          "report_visible_for_candidate": "Ergebnis für den Kandidaten sichtbar",
          "resume_subtest": "Setzen Sie %{test} fort",
          "resume_test": "Das Assessment fortsetzen",
          "save_date": "Datei speichern",
          "select_document_option_placeholder": "Wählen Sie die gewünschte Dokumentoption für diesen Test:",
          "select_norm_group_placeholder": "Wählen Sie die gewünschte Normgruppe für diesen Test:",
          "settings_modal_header": "Auswertungseinstellungen",
          "skip_verification": "Überspringen",
          "start_review": "Die Überprüfung anfangen",
          "start_subtest": "Starten Sie %{test}",
          "start_test": "Das Assessment starten",
          "start_verification": "Starten",
          "start_verification_subtest": "Beginnen Sie mit der Überprüfung von %{test}",
          "start_verification_test": "Die Verifikation anfangen",
          "task": "Aufgabe",
          "test_completed": "Abgeschlossen",
          "test_inprogress": "Gestartet",
          "test_pending": "Noch nicht gestartet",
          "test_reviewrequired": "Überprüfung erforderlich",
          "test_verificationrequired": "Verifizierung erforderlich",
          "verification_completed": "Der Verifizierungstest wurde abgeschlossen.",
          "verification_restart": "Neustarten",
          "verification_skipped": "Verifikation wurde übersprungen. Über den Button könnten Sie sie dennoch starten.",
          "verification_skipped_processing": "Verifikation wurde übersprungen. Über den Button könnten Sie sie dennoch starten, nachdem die Auswertung erstellt ist.",
          "view_results": "Ergebnisse ansehen",
          "waiting_verification_start": "Der Kandidat hat den Verifizierungstest noch nicht begonnen; Die Überprüfung kann weiterhin übersprungen werden."
        },
        "job_market_scan": {
          "compare": "Vergleichen Sie Berufe",
          "description_created": "Labour Market Opportunities berechnet aktuelle und zukünftige Chancen auf dem niederländischen Arbeitsmarkt. Nach Eingabe einiger Informationen zu Ausbildung und Beruf wird einmalig eine Bewertung zwischen 1 (sehr schlecht) und 99 (sehr gut) vergeben. Am Ende können Sie einen Bericht mit detaillierten Ergebnissen herunterladen.",
          "description_finished": "Die Arbeitsmarktchancen sind abgeschlossen! Alle Ergebnisse wurden berechnet. Klicken Sie auf die Schaltfläche „Herunterladen“, um den Bericht als PDF (erneut) herunterzuladen.",
          "description_in_progress": "Die Arbeitsmarktchancen sind noch nicht vollständig ausgeschöpft. Dies ist notwendig, um den Bericht zu erstellen. Klicken Sie auf die Schaltfläche unten, um fortzufahren.",
          "download": "Herunterladen",
          "download_alert": "„Arbeitsmarktchancen“ wurde vor mehr als 6 Monaten abgeschlossen; Es können keine neuen Berichte mehr generiert werden.",
          "download_description_1": "Laden Sie unten alle gespeicherten Berichte herunter",
          "download_description_2": "um einen neuen Bericht mit aktualisierten Stellenangeboten, Schulungen und Kursen zu erstellen (verfügbar für 6 Monate)",
          "download_link_text": "Oder klicken Sie hier",
          "download_title": "Berichte herunterladen",
          "resume": "Wieder aufnehmen",
          "start": "Start",
          "terms_and_conditions": "Ich stimme den AGB zu",
          "title": "Chancen auf dem Arbeitsmarkt",
          "title_finished": "Ergebnisse",
          "working": "Beschäftigt..."
        },
        "job_market_scan_en": {
          "title": "Arbeitsmarktchancen auf Englisch",
          "title_finished": "Ergebnisse"
        },
        "logbook": {
          "back": "Zurück",
          "content_placeholder": "hier kommt der Inhalt hin",
          "delete": "Löschen",
          "help_text": "Auf den Seiten des Logbuchs können Sie sich Notizen machen.",
          "logbook": "Logbuch",
          "new_page": "Neue Seite",
          "title_placeholder": "Titel Logbuchseite"
        },
        "quiz": {
          "add_answer": "Antwort hinzufügen",
          "answer_correct": "Richtig",
          "answer_incorrect": "Falsch",
          "answer_placeholder": "Geben Sie hier eine Antwort ein",
          "answer_placeholder_viewer": "Der Kandidat gibt hier eine Antwort ein",
          "answer_text_placeholder": "Geben Sie hier Antwort %{index} ein",
          "back_current": "Vorige",
          "back_started": "Zurück",
          "correct_placeholder": "Geben Sie hier die richtige Antwort ein",
          "delete": "Löschen",
          "next_add": "Frage hinzufügen",
          "next_check": "Kontrollieren",
          "next_current": "Nächste",
          "question_of": "FRAGE %{index} VON %{length}",
          "question_text_placeholder": "Geben Sie hier die Frage ein",
          "question_type_choice": "Mehrfachauswahl",
          "question_type_open": "Offen",
          "selections_number": "Anzahl der Auswahlantworten",
          "selections_to": "bis",
          "start_text_checking": "Antworten anschauen",
          "start_text_resume": "Fortsetzen",
          "start_text_set_up": "Einsetzen",
          "start_text_start": "Starten",
          "start_text_viewer": "Fragen anschauen"
        },
        "report_visibility": {
          "advisor": {
            "both": "Der Bericht steht dem Kandidaten nun zur Verfügung. Das Recht des Kandidaten, den Bericht herunterzuladen/einzusehen, kann durch Deaktivieren des Schiebereglers auf der rechten Seite entzogen werden.",
            "self": "Der Bericht liegt dem Kandidaten noch nicht vor. Durch die Aktivierung des Schiebereglers auf der rechten Seite erhält der Kandidat das Recht, den Bericht herunterzuladen und anzusehen."
          },
          "candidate": {
            "both": "Die Auswertung ist nun für den Coach verfügbar. Wenn Sie den Regler rechts deaktivieren, hat der Coach nicht mehr das Recht, die Auswertung herunterzuladen und anzuschauen.",
            "self": "Die Auswertung ist für den Coach noch nicht verfügbar. Wenn Sie den Regler rechts aktivieren, bekommt der Coach das Recht, die Auswertung herunterzuladen und anzuschauen."
          }
        },
        "search_job": {
          "delete": "Löschen",
          "favorite": "Favorit",
          "favorites": "Favoriten",
          "location": "Wo?",
          "no_favorites_chosen": "(keine Favoriten gewählt)",
          "no_jobs_found": "(keine Stellenangebote gefunden)",
          "query": "Was?",
          "search": "Suchen",
          "view": "Ansehen"
        },
        "templates": {
          "add": "Hinzufügen",
          "add_template": "Vorlage hinzufügen",
          "added": "Selektierbar",
          "builder": {
            "add_column": "Spalte hinzufügen",
            "add_element": "Sektion hinzufügen",
            "add_row": "Zeile hinzufügen",
            "block": "Block",
            "circle": "Kreis",
            "date_formats": {
              "D": "Tag als Zahl (1 oder 2 Ziffern)",
              "DD": "Tag als Zahl (2 Ziffern)",
              "M": "Monat als Zahl (1 oder 2 Ziffern)",
              "MM": "Monat als Zahl (2 Ziffern)",
              "MMM": "Monatsname (abgekürzt)",
              "MMMM": "Monatsname (ausgeschrieben)",
              "YY": "Jahr (2 Ziffern)",
              "YYYY": "Jahr (4 Ziffern)",
              "ddd": "Wochentag (abgekürzt)",
              "dddd": "Wochentag (ausgeschrieben)"
            },
            "description": "Beschreibung",
            "destroy_confirm_column": "Sind Sie sicher, dass Sie diese Spalte löschen wollen?",
            "destroy_confirm_element": "Sind Sie sicher, dass Sie diese Sektion löschen wollen?",
            "destroy_confirm_row": "Sind Sie sicher, dass Sie diese Zeile löschen wollen?",
            "global": "Für alle Organisationen?",
            "header": "Überschrift",
            "header_font_size": "Schriftgröße Überschrift",
            "height": "Höhe",
            "hide_borders": "Rahmenlinien verbergen",
            "hide_preview": "Beispiel verbergen",
            "margin_from_top": "Rand über Sektion",
            "optional": "Optional",
            "padding": "Rand rundum den Block",
            "shape": "Form",
            "show_borders": "Rahmenlinien anzeigen",
            "show_header": "Überschrift anzeigen",
            "show_icon": "Piktogramm anzeigen",
            "show_preview": "Beispiel zeigen",
            "square": "Quadrat",
            "title": "Titel",
            "type": "Sektionstyp",
            "variable_add_option": "Option hinzufügen",
            "variable_date": "Datum",
            "variable_date_format": "Format",
            "variable_dropdown": "Auswahl",
            "variable_help_text": "Hilfstext",
            "variable_options": "Optionen",
            "variable_text": "Text",
            "variable_type": "Variabeltyp",
            "width": "Breite"
          },
          "change_template": "Vorlage ändern",
          "default": "Selektiert",
          "delete": "Löschen",
          "deselect": "Abwählen",
          "destroy_confirm": "Sind Sie sicher, dass Sie diese Vorlage löschen wollen?",
          "documentation": "(hier klicken für Informationen zu der Lebenslauf & Brief Builder)",
          "edit": "Bearbeiten",
          "edit_replacements": "Hilfstexte bearbeiten",
          "filler": {
            "add_item": "Item hinzufügen",
            "add_optional": "Diese Sektion an Dokument hinzufügen",
            "change": "Ändern",
            "crop_and_save": "Schneiden & Speichern",
            "download_docx": "Download DOCX",
            "download_pdf": "Download PDF",
            "remove": "Löschen",
            "remove_optional": "Diese Sektion aus Dokument löschen",
            "upload_image": "Abbildung auswählen"
          },
          "image": "Abbildung",
          "list": "Liste",
          "no_template_selected": "Keine Vorlage ausgewählt",
          "not_ready_yet_text": "Noch nicht fertig",
          "open": "Offen",
          "remove": "Entfernen",
          "remove_template": "Vorlage löschen",
          "select": "Wählen",
          "select_template": "Vorlage wählen",
          "switch_template": "Vorlagenauswahl",
          "text": "Text"
        },
        "text": {
          "placeholder": "Klicken Sie hier, um einen Text einzugeben (für zusätzliche Styling-Optionen diesen anschließend selektieren)"
        },
        "vacancy": {
          "action": "Handlung",
          "action_deadline": "Deadline für diese Handlung",
          "action_placeholder": "bewerben, erstes Gespräch vorbereiten, ...",
          "back": "Zurück",
          "before": "vor",
          "delete": "Löschen",
          "help_text": "Mit dem Stellenmanager können Sie interessante Stellenangebote notieren und anschließend gemeinsam mit Ihrem Coach Ihre nächsten Handlungen festlegen.",
          "new_vacancy": "Neues Stellenangebot",
          "next_action": "Hierunter kann die nächste Handlung mit dazugehöriger Deadline vereinbart werden:",
          "notes": "Notizen",
          "notes_placeholder": "andere Bemerkungen",
          "status": "Status",
          "statuses": {
            "created": "Erstellt",
            "first_interview": "Erstes Bewerbungsgespräch",
            "hired": "Angenommen",
            "interested": "Interessiert",
            "letter_sent": "Brief geschrieben",
            "other": "Anders",
            "rejected": "Abgewiesen",
            "second_interview": "Zweites Bewerbungsgespräch"
          },
          "title": "Titel",
          "title_placeholder": "Briefträger bei der Post",
          "vacancy_link": "Link zum Stellenangebot",
          "vacancy_link_placeholder": "fügen Sie hier einen Link zum Stellenangebot ein",
          "vacancy_manager": "Stellenmanager",
          "view_vacancy": "Stellenangebot ansehen"
        },
        "visibility": {
          "advisor": {
            "both": "Das Dokument steht dem Kandidaten nun zur Verfügung. Das Recht des Kandidaten, das Dokument herunterzuladen/einzusehen, kann durch Deaktivieren des Schiebereglers auf der rechten Seite entzogen werden.",
            "self": "Das Dokument liegt dem Kandidaten noch nicht vor. Durch die Aktivierung des Schiebereglers auf der rechten Seite erhält der Kandidat das Recht, das Dokument herunterzuladen und anzuzeigen."
          },
          "candidate": {
            "both": "Das Dokument ist nun für den Coach verfügbar. Wenn Sie den Regler rechts deaktivieren, hat der Coach nicht mehr das Recht, das Dokument herunterzuladen und anzuschauen.",
            "self": "Das Dokument ist für den Coach noch nicht verfügbar. Wenn Sie den Regler rechts aktivieren, bekommt der Coach das Recht, das Dokument herunterzuladen und anzuschauen."
          }
        },
        "youtube_video": {
          "url_placeholder": "Geben Sie in den Einstellungen dieses Elements den vollständigen URL eines YouTube-Videos ein"
        }
      },
      "cost_centers": {
        "number_of_users": "%{number} aktiv"
      },
      "editable_empty": "(keine)",
      "program_templates": {
        "number_of_pages": {
          "one": "1 Schritt",
          "other": "%{count} Schritte"
        },
        "status": {
          "deleted": "Gelöscht",
          "editable": "Bearbeitbar",
          "hidden": "Gelöscht",
          "published": "Veröffentlicht",
          "template": "Vorlage"
        }
      },
      "programs": {
        "number_of_pages": {
          "one": "1 Schritt",
          "other": "%{count} Schritte"
        },
        "status": {
          "deleted": "Gelöscht",
          "editable": "Bearbeitbar",
          "hidden": "Gelöscht",
          "published": "Veröffentlicht",
          "template": "Vorlage"
        }
      },
      "rooms": {
        "browser_unsupported": "Dieser Browser wird nicht unterstützt. Bitte verwenden Sie einen modernen Browser wie Chrome oder Firefox.",
        "default_local_display_name": "Ich",
        "default_remote_display_name": "Anonym"
      }
    },
    "label": {
      "search": "Suchen"
    },
    "labels": {
      "index": {
        "title": "Labels"
      }
    },
    "language": {
      "name": "Deutsch"
    },
    "languages": {
      "all": "Alle Sprachen",
      "de": "Deutsch",
      "en": "Englisch",
      "fr": "Französisch",
      "it": "Italienisch",
      "nl": "Niederländisch"
    },
    "layouts": {
      "assessment": {
        "header": {
          "logout": "Ausloggen",
          "notifications": "Benachrichtigungen"
        },
        "language_menu": {
          "select_language": "Sprache auswählen"
        },
        "resource_header": {
          "notifications": "Benachrichtigungen",
          "profile": "Profil",
          "switch_account": "Wechseln Sie zu einem anderen Konto"
        },
        "users": {
          "dropdown": {
            "notifications": "Benachrichtigungen"
          }
        }
      },
      "mailer": {
        "personal_info": "Persönliche Daten ändern",
        "privacy_policy": "Datenschutz",
        "rights": "Ixly 2023 Vleutensevaart 100 3532 AD Utrecht",
        "terms": "Allgemeine Geschäftsbedingungen",
        "unsubscribe": "Abmelden"
      }
    },
    "legacy_invoices": {
      "show": {
        "title": "Test-Toolkit-Rechnung"
      }
    },
    "login_mailer": {
      "confirmation_button": {
        "text": "Einen Account erstellen"
      },
      "welcome_mail": {
        "subject": "Willkommen"
      }
    },
    "media_item_share": {
      "empty_shared_with_list": "Es gibt niemanden, mit dem Sie ein Medienelement teilen können",
      "send": "Teilen",
      "share_with": "Teilen mit"
    },
    "media_item_shares": {
      "deleted": "Das Teilen von Medienelementen wurde erfolgreich entfernt.",
      "drawer_content": {
        "download": "Herunterladen"
      },
      "failed_create": "Das Medienelement konnte nicht geteilt werden. Wählen Sie bitte einen Benutzer aus, mit dem Sie das Medienelement teilen möchten.",
      "list": {
        "created_at": "Datum",
        "filename": "Name",
        "relates_to": "Bezüglich"
      },
      "success_create": "Das Medienelement wurde erfolgreich geteilt."
    },
    "media_items": {
      "add": "Datei hinzufügen",
      "allowed_formats": "Erlaubte Dateiformate",
      "cannot_delete": "Das Entfernen ist fehlgeschlagen. Ein verwendetes Medienelement kann nicht gelöscht werden.",
      "created": "Erstellt",
      "deleted": "Medienelement wurde erfolgreich gelöscht.",
      "destroy": "Zerstören",
      "download": "Download",
      "failed_upload": "Dateigröße ist zu groß (maximal 50 MB)",
      "files": "Meine akten",
      "list": {
        "created_at": "Datum",
        "filename": "Name",
        "relates_to": "Bezüglich",
        "shared_with": "Geteilt mit"
      },
      "relates_to": "Bezüglich",
      "shared_by": "Geteilt von",
      "shared_with": "Geteilt mit",
      "shared_with_me": "Mit mir geteilt",
      "success_upload": "Medienelement wurde erfolgreich erstellt.",
      "title": "Dokumente & Mediathek",
      "upload_title": "Datei auswählen"
    },
    "notice": {
      "bulk": {
        "candidates_enrolled": "Die Kandidaten wurden per E-Mail über den neu zugewiesenen Prozess informiert",
        "send_welcome": "Willkommens-E-Mails wurden gesendet"
      },
      "password_has_been_updated": "Ihr Passwort wurde aktualisiert"
    },
    "notification": {
      "details": {
        "assessment_finished_notification": "%{candidate} hat %{assessment} abgeschlossen.",
        "candidate_assessment": {
          "finished": "%{candidate} hat %{assessment} abgeschlossen.",
          "invited": "Das Assessment „%{assessment}“ wurde Ihnen zugewiesen."
        },
        "comment_notification": "%{sender} hat dir eine Nachricht geschickt: \"%{message}\"",
        "credit_limit_reached_notification": "Sie werden benachrichtigt, da das Prepaid-Credits für Ihre Organisation unter %{credit_limit} gesunken ist. Wir empfehlen Ihnen, zusätzliche Credits zu erwerben, um sicherzustellen, dass Sie weiterhin Auswertungen zu generieren.",
        "document": {
          "approved_notification": "%{sender} hat das %{filename}-Dokument mit Zustimmung angenommen.",
          "rejected_notification": "%{sender} hat das %{filename}-Dokument abgewiesen. Sie können ein neues Dokument hochladen und erneut zur Zustimmung lassen.",
          "submitted_notification": "%{sender} bittet Sie, das %{filename}-Dokument zu genehmigen.",
          "uploaded_notification": "%{sender} hat das Dokument %{filename} hochgeladen."
        },
        "enroller_notification": "%{candidate} hat sich für '%{title}' angemeldet.",
        "enrollment": {
          "coach_action_notification": "Der Prozess '%{title}' von %{candidate} umfasst Assessments, für die ein Auswertung ausgewählt werden muss. Nachdem die gewünschten Auswertungen ausgewählt wurden, kann der Kandidat eingeladen werden, den Prozess zu starten.",
          "created_notification": "Der Prozess „%{title}“ wurde Ihnen zugewiesen."
        },
        "enrollment_page": {
          "component_action_notification": "Der Schritt %{title} im Prozess %{process} enthält eine umsetzbare Komponente.",
          "done_notification": "Der Schritt %{title} im Prozess %{process} ist abgeschlossen.",
          "rejected_notification": "Der Schritt %{title} im Prozess %{process} ist abgewiesen. Sie können die Teile des Schritts aktualisieren und ihn erneut senden.",
          "submitted_notification": "Der Schritt %{title} im Prozess %{process} ist vorgeschlagen. Sie können den Schritt überprüfen und genehmigen oder ablehnen."
        },
        "media_item_notification": "Die Datei %{filename} wurde zur Dokumenten- und Medienbibliothek hinzugefügt.",
        "media_item_share_notification": "%{name} hat %{filename} in der Dokumenten- und Medienbibliothek für Sie freigegeben.",
        "post_notification": "Der Blogartikel %{title} wurde mit Ihnen geteilt.",
        "postmark": {
          "inactive_recipient": "Beim Senden einer E-Mail an %{candidate} ist ein Fehler aufgetreten. Überprüfen Sie die E-Mail-Adresse %{email} auf Fehler und kontaktieren Sie unseren Kundenservice unter info@ixly.nl mit den Details des Problems, wenn die E-Mail-Adresse korrekt ist.",
          "invalid_address": "Die E-Mail-Adresse %{email} von %{candidate} ist ungültig. Korrigieren Sie die E-Mail-Adresse, damit %{candidate} E-Mails empfangen kann."
        },
        "review_required": "Die Bewertung %{assessment_title} von %{candidate_name} muss überprüft werden.",
        "suppressed_email": "Wir haben versucht, eine E-Mail an %{name} zu senden, aber es ist aus %{reason}-Grund fehlgeschlagen. Stellen Sie sicher, dass die verwendete E-Mail-Adresse korrekt ist (%{email}).\n%{name} hat keine E-Mail erhalten.\n",
        "unsuppressed_email": "Wir können wieder Nachrichten per %{email} an %{name} senden.",
        "verification_required": "Die Bewertung %{assessment_title} von %{candidate_name} muss überprüft werden.\nWählen Sie, ob die Verifizierung gestartet werden kann oder ob sie übersprungen werden soll, damit die Bewertung abgeschlossen werden kann.\n",
        "verification_started": "Die Bewertung %{assessment_title} verfügt über einen Verifizierungstest, der gestartet werden kann"
      },
      "type": {
        "AppointmentAcceptedNotification": "Termin akzeptiert",
        "AppointmentCancelledNotification": "Termin abgesagt",
        "AppointmentDeclinedNotification": "Termin abgewiesen",
        "AppointmentSubmittedNotification": "Termin vorgeschlagen",
        "assessment_finished_notification": "Assessment abgeschlossen",
        "candidate_assessment": {
          "finished": "Assessment abgeschlossen",
          "invited": "Neu zugewiesene Bewertung"
        },
        "comment_notification": "Neue Nachricht",
        "credit_limit_reached_notification": "Wenig verbleibende Credits",
        "document": {
          "approved_notification": "Dokument mit Zustimmung angenommen",
          "rejected_notification": "Dokument abgewiesen",
          "submitted_notification": "Dokument zur Zustimmung",
          "uploaded_notification": "Datei hochgeladen"
        },
        "enroller_notification": "Neue Einschreibung",
        "enrollment": {
          "coach_action_notification": "Auswahl einer Auswertung erforderlich",
          "created_notification": "Neu zugewiesener Prozess"
        },
        "enrollment_page": {
          "component_action_notification": "Aktion benötigt",
          "done_notification": "Schritt abgeschlossen",
          "rejected_notification": "Schritt abgewiesen",
          "submitted_notification": "Schritt eingereicht"
        },
        "media_item_notification": "Der Dokumenten- und Medienbibliothek wurde eine neue Datei hinzugefügt",
        "media_item_share_notification": "Eine Datei wurde mit Ihnen geteilt",
        "post_notification": "Neuer Blogartikel",
        "postmark_notification": "E-Mail-Benachrichtigung kann nicht übermittelt werden",
        "review_required": "Überprüfung für %{assessment_title} erforderlich",
        "suppressed_email": "Wir konnten %{member_type} nicht per E-Mail erreichen",
        "unsuppressed_email": "Der %{member_type} ist wieder per E-Mail erreichbar",
        "verification_required": "Authentifizierung für %{assessment_title} erforderlich",
        "verification_started": "Die Bewertung %{assessment_title} verfügt über einen Verifizierungstest, der gestartet werden kann"
      },
      "what": "Was",
      "who": "Wer"
    },
    "notifications_overview_mailer": {
      "notification_mail": {
        "subject": "Sie haben neue Benachrichtigungen erhalten"
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ".",
          "format": "%n %u",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": ".",
        "precision": 2,
        "round_mode": "default",
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": {
              "one": "Milliarde",
              "other": "Milliarden"
            },
            "million": {
              "one": "Million",
              "other": "Millionen"
            },
            "quadrillion": {
              "one": "Billiarde",
              "other": "Billiarden"
            },
            "thousand": "Tausend",
            "trillion": {
              "one": "Billion",
              "other": "Billionen"
            },
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB"
          }
        }
      },
      "nth": {
        "ordinalized": "%{number}.",
        "ordinals": "."
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n %"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "organization": "Organisation",
    "organization_products": {
      "create": {
        "created": "Die Module wurden der Organisation hinzugefügt"
      },
      "destroy": {
        "already_started": "Dieses Modul wurde bereits gestartet."
      },
      "index": {
        "from_subscription": "Aus dem Abonnement",
        "new_button": "Module hinzufügen",
        "table_header": [
          "Name",
          "Anfangsdatum",
          "Enddatum",
          "Handlungen"
        ],
        "title": "Module"
      },
      "new": {
        "title": "Module hinzufügen zu %{organization}"
      },
      "row": {
        "product_delete": "Sind Sie sicher, dass Sie dieses Modul aus der Organisation entfernen wollen?"
      }
    },
    "organization_status": {
      "active": "Aktiv",
      "created": "Erstellt",
      "deleted": "Gelöscht",
      "inactive": "Inaktiv"
    },
    "organizations": {
      "billing": {
        "details": {
          "cost_centers": {
            "in_use": "Diese Kostenstelle hat noch aktive Nutzer.",
            "new_button": "Neue Kostentelle",
            "table_header": [
              "Name",
              "Beschreibung",
              "Nutzer",
              "Kandidaten",
              "Handlungen"
            ],
            "title": "Kostenstellen"
          },
          "organization": "Organisation",
          "title": "Angaben"
        },
        "help_text": "Hier finden Sie eine Übersicht Ihrer Abonnements, Verwendung, Rechnungen und Angaben",
        "info": "Wählen Sie eine Zahlungsmethode und das Credit-Paket, das Sie erwerben möchten.",
        "invoices": {
          "legacy_invoices": "Test-Toolkit-Rechnungen",
          "no_orders": "(es wurde noch nichts bestellt)",
          "previous_orders": "Vorige Bestellungen",
          "title": "Rechnungen"
        },
        "payment": "Zahlungsart",
        "payment_title": "Kreditpaket kaufen",
        "subscription": {
          "credits": {
            "invoice": "Die Credit-Erhöhung wurde umgesetzt! Eine Rechnung wird Ihnen zugeschickt. Sie haben jetzt %{count} Credits",
            "open": "Die Credit-Erhöhung wurde umgesetzt! Der Betrag wird automatisch abgebucht. Sie haben jetzt %{count} Credits",
            "paid": "Die Credit-Erhöhung wurde umgesetzt! Sie haben jetzt %{count} Credits"
          },
          "mandate": {
            "activate_credit_purchase": "Aktivieren Sie Ihre Organisation mit einem Credit-Bundle-Kauf, damit Ihre Kandidaten durchstarten können!",
            "change_html": "<a href='%{link}' data-method='post'>Klicken Sie hier, um eine neue Lastschrift zu genehmigen</a> .",
            "credit_card": "der Kreditkarte mit der Nummer XXXX XXXX XXXX %{number}.",
            "direct_debit": "dem Konto mit der IBAN %{number}.",
            "not_valid_html": "Ihre Nutzer können sich erst anmelden, nachdem eine Autorisierungszahlung erfolgt ist. Dadurch wird sichergestellt, dass Ixly B.V. berechtigt ist, die zu diesem Zweck verwendete Bankverbindung zu belasten. Es gelten dabei <a class='alert-link' href='%{link}' target='_blank'>unsere allgemeinen Geschäftsbedingungen</a>.",
            "open": "Die Autorisierungszahlung wird im Moment noch verarbeitet; Seite für eine Aktualisierung neu laden",
            "paid": "Die Autorisierungszahlung war erfolgreich! Ihre Kosten werden von nun an von %{number} abgebucht",
            "valid": "Ihre Kosten werden automatisch eingezogen von"
          },
          "not_active": "Ihre Organisation hat kein aktives Abonnement",
          "order": {
            "canceled": "Die Zahlung ist aufgehoben"
          },
          "payment": {
            "automatic_method": {
              "explain": "Autorisieren Sie durch den Kauf eines ersten Kreditpakets für reibungslose und mühelose Kredittransaktionen jetzt und in Zukunft",
              "title": "Automatische Zahlung"
            },
            "direct_method": {
              "info_text1": "Erleben Sie Direktzahlungen ohne zusätzliche Kosten mit iDEAL",
              "info_text2": "Sicher, schnell und einfach.",
              "title": "Sofortige Bezahlung"
            },
            "info_text1": "Keine Sorgen mehr über Zahlungen!",
            "info_text2": "Mit dem Lastschriftverfahren können Sie dies schnell und einfach veranlassen.",
            "manual_method": {
              "confirmation": "Bist du sicher? Das bedeutet, dass Sie ab Juni 9,99 € pro Rechnung zahlen.",
              "explain": "Ab dem 1. Juli berechnen wir pro Rechnung 9,99 € Verwaltungskosten",
              "info_text1": "Möchten Sie lieber im Nachhinein bezahlen?",
              "info_text2": "Dies ist bei manueller Zahlung möglich.",
              "please_note": "bitte beachten Sie",
              "title": "Manuelle Zahlung"
            },
            "mollie": {
              "conditional1": "Manuelle Zahlungen gehören der Vergangenheit an",
              "conditional2": "Sie haben die volle Kontrolle über Ihre Finanzen",
              "conditional3": "Sparen Sie zusätzliche Kosten und Ärger mit Rechnungen"
            },
            "payment_type": {
              "automatic": "Automatische Zahlung",
              "direct": "Direktzahlung mit ideal",
              "invoice": "Manuelle Zahlung"
            }
          },
          "subscriptions": {
            "active_advisors_title": "Aktiven Berater",
            "active_candidates": "(ab %{max_candidates})",
            "active_candidates_title": "Aktive Kandidaten",
            "active_premium_candidates_title": "Aktive Premium-Kandidaten",
            "active_users": "(ab %{max_users})",
            "active_users_title": "Aktive Coaches",
            "already_started": "Dieses Abonnement wurde bereits gestartet.",
            "credits": "Credits:",
            "downgrade": "Downgraden",
            "downgrades": "Downgrades",
            "incidental_subscription_credits_left": "Wenn bei Start des neuen Abonnements noch Credits vorhanden sind, wird dieses zuerst verbraucht",
            "new_button": "Neues Abonnement",
            "prices": "Preise",
            "purchase": "Kaufen",
            "settings": "Spezifikationen",
            "table_header": [
              "Name",
              "Anfangsdatum",
              "Enddatum",
              "Handlungen"
            ],
            "title": "Abonnements",
            "total_advisors": "(von %{max_users})",
            "total_advisors_title": "Alle Berater",
            "total_candidates": "(von %{max_candidates})",
            "total_candidates_title": "Alle Kandidaten",
            "total_coaches_title": "Alle Coaches",
            "total_premium_candidates_title": "Alle Premium-Kandidaten",
            "upgrade": "Upgraden",
            "upgrades": "Upgrades",
            "user_counts": "Nutzerzahlen"
          },
          "title": "Abonnement"
        },
        "title": "Zahlung",
        "usage": {
          "active_candidates": {
            "prepaid": "vorausbezahlt",
            "table_header": [
              "Kandidat",
              "Aktiviert am",
              "Preis"
            ],
            "title": "Aktive Kandidaten"
          },
          "active_coaches": {
            "table_header": [
              "Coach",
              "Aktiviert am",
              "Preis"
            ],
            "title": "Aktive Coaches"
          },
          "aycr_periods": {
            "table_header": [
              "Kandidat",
              "Gültig für",
              "Preis"
            ],
            "title": "Kandidat mit gestartete Assessments"
          },
          "generated_reports": {
            "table_header": [
              "Assessment",
              "Auswertung",
              "Kandidat",
              "Generiert am",
              "Credits",
              "Preis"
            ],
            "title": "Generierten Auswertungen"
          },
          "period": "Zeitraum",
          "premium_components": {
            "table_header": [
              "Element",
              "Kandidat",
              "Gestartet am",
              "Preis"
            ],
            "title": "Gestartete Elemente mit Mehrkosten"
          },
          "title": "Verwendung",
          "total_with_vat": "Totale Kosten inklusive %{vat} % MwSt.: %{price}"
        }
      },
      "edit": {
        "available_languages_confirm": "Sie sind dabei, die von Ihren Nutzern verwendeten Sprachen zu deaktivieren. Ihre Sprache wird in die Standardsprache Ihrer Organisation geändert. Sind Sie sicher?",
        "explanation": "Die hier gewählte Sprache gilt als Standardeinstellung für neue Nutzer innerhalb dieser Organisation.",
        "logo": "Logo",
        "title": "Organisationseinstellungen",
        "upload_logo": "Hier klicken, um ein Logo hochzuladen"
      },
      "index": {
        "table_header": [
          "Name",
          "Abonnement",
          "Nutzer",
          "Prozesse",
          "Status",
          "Handlungen"
        ]
      },
      "payment_method_mollie_direct": {
        "success": "Die Direktzahlung wurde erfolgreich erstellt"
      },
      "row": {
        "impersonate": "Einloggen als",
        "organization_delete": "Sind Sie sicher, dass Sie diese Organisation löschen wollen?"
      },
      "texts": {
        "welcome_mail": {
          "example_mail_body": "Hallo %{user_name}. Willkommen bei Assessment Platform.\n\nBei Assessment Platformes wurde ein neues Konto für Sie erstellt.\nLegen Sie sofort Ihr Passwort fest, um Zugriff auf Ihre persönliche Umgebung zu erhalten.\n<a href='%{password_set_link}'>Einen Account erstellen</a>.\nEs ist wichtig, ein starkes und eindeutiges Passwort zu wählen, um Ihr Konto zu schützen. Achten Sie daher darauf, dass Ihr neues Passwort mindestens zehn Zeichen lang ist und eine Kombination aus Buchstaben, Zahlen und Symbolen darstellt.\n\nDein,\n%{organization_name}"
        }
      },
      "update": {
        "organization_updated": "Die Organisation ist aktualisiert"
      }
    },
    "otp_secret": {
      "cancel_otp": "Brechen Sie den Authentifizierungsprozess ab",
      "input_the_code": "Geben Sie den von der Authentifizierungs-App bereitgestellten Code ein",
      "scan_the_code": "TRANSLATE %{app_1} oder %{app_2}."
    },
    "page_status": {
      "created": {
        "seen": "Ungelesen",
        "set": "Offen",
        "submit": "Offen"
      },
      "done": {
        "seen": "Gelesen",
        "set": "Abgeschlossen",
        "submit": "Genehmigt"
      },
      "hidden": "Verborgen",
      "optional": "Optional",
      "rejected": {
        "submit": "Nochmals"
      },
      "submitted": {
        "submit": "Eingereicht"
      }
    },
    "pagy": {
      "combo_nav_js": "<label>Seite %{page_input} von %{pages}</label>",
      "info": {
        "multiple_pages": "Zeige %{item_name} <b>%{from}-%{to}</b> von <b>%{count}</b> gesamt",
        "no_items": "Keine %{item_name} gefunden",
        "single_page": "Zeige <b>%{count}</b> %{item_name}"
      },
      "item_name": {
        "one": "Eintrag",
        "other": "Einträge"
      },
      "items_selector_js": "<label>Zeige %{items_input} %{item_name} pro Seite</label>",
      "nav": {
        "gap": "&hellip;",
        "next": "Weiter&nbsp;&rsaquo;",
        "prev": "&lsaquo;&nbsp;Zurück"
      }
    },
    "pallet": {
      "image": {
        "label": "Wählen Sie eine Abbildung"
      },
      "ixly_test": {
        "description": "Erklärung zum Assessment",
        "label": "Name des Assessments"
      },
      "label": "Label",
      "quiz": {
        "title": "Quiz"
      },
      "templates": {
        "title": "Lebenslauf & Brief Builder"
      }
    },
    "password": {
      "forgot_password": {
        "help_text": "Geben Sie Ihre E-Mail-Adresse ein, um ein neues Passwort anzufragen",
        "request": "Anfragen",
        "title": "Passwort vergessen?"
      },
      "reset_password_form": {
        "button": "Ändern",
        "help_text": "Neues Passwort eingeben und anschließend erneut bestätigen",
        "invalid_link": "Ihr Link zur Passwortwiederherstellung ist bereits genutzt, abgelaufen oder ungültig",
        "title": "Passwort ändern"
      },
      "send_reset_password_link": {
        "help_text": "In wenigen Minuten erhalten Sie die E-Mail, mit der Sie ein neues Passwort einstellen können",
        "title": "Mail zur Passwortwiederherstellung verschickt",
        "unknown_email": "Die angegebene E-Mail-Adresse ist uns nicht bekannt"
      },
      "update_password_with_reset_token": {
        "invalid_link": "Ihr Link zur Passwortwiederherstellung ist bereits genutzt, abgelaufen oder ungültig",
        "password_has_been_reset": "Ihr Passwort ist geändert und Sie haben sich erfolgreich eingeloggt",
        "password_is_of_insufficient_length": "Das neue Passwort muss mindestens 10 Zeichen haben",
        "passwords_dont_match": "Passwort und Passwortwiederholung kommen nicht überein"
      }
    },
    "placeholder": {
      "search": "Suchen"
    },
    "posts": {
      "available_locales": "Verfügbare Sprachen",
      "destroy": {
        "destroyed": "Der Artikel ist gelöscht"
      },
      "destroy_confirm": "Sind Sie sicher, dass Sie diesen Artikel löschen wollen?",
      "edit": "Artikel bearbeiten",
      "edit_rules": "Regeln zum Teilen bearbeiten",
      "empty": "(es wurde noch keine Artikel platziert)",
      "help_text": "Diese Artikel und die dazugehörigen Regeln zum Teilen verwalten",
      "hide_more": "Verbergen",
      "index": {
        "title": "Blogartikel"
      },
      "new": "Neuer Artikel",
      "open": "Offen",
      "post_rules": {
        "add": "Regel zum Teilen hinzufügen",
        "private": "(noch niemand)",
        "program_labels": {
          "multiple": ", an die <strong>Prozesse mit Labels %{list}</strong> zugewiesen sind",
          "single": ", an die <strong>Prozesse mit Label %{list}</strong> zugewiesen sind"
        },
        "programs": {
          "multiple": ", an die die <strong>Prozesse %{list}</strong> zugewiesen sind",
          "single": ", an die der <strong>Prozess %{list}</strong> zugewiesen ist"
        },
        "public": "(für jeden sichtbar)",
        "roles": {
          "account_managers": "Kundenbetreuer",
          "accountmanagers": "Kundenbetreuer",
          "advisors": "Berater",
          "all_internal_users": "Coaches",
          "all_users": "Nutzer",
          "coachs": "Prozess-Coaches",
          "contact_persons": "Kontaktpersonen",
          "ixly_admins": "Ixly-Verwalter",
          "ixly_employees": "Ixly-Mitarbeiter",
          "organization_managers": "Organisationsverwalter",
          "selected": "Alle <strong>%{list}</strong>",
          "selected_advisor": "mit <strong>Coach %{author_name}</strong>",
          "selected_global": "von <strong>allen Organisationen</strong>",
          "users": "Teilnehmer"
        },
        "user_labels": {
          "multiple": "mit <strong>Labels %{list}</strong>",
          "single": "mit <strong>Label %{list}</strong>"
        },
        "users": {
          "multiple": "Nutzer <strong>%{names}</strong>",
          "single": "Nutzer <strong>%{names}</strong>"
        },
        "users_type": {
          "certain": "Mit bestimmten Nutzern teilen",
          "filters": "Mit Rollen, Labels und Prozessen teilen",
          "public": "Öffentlich"
        }
      },
      "private": "Privat",
      "published_by": "Veröffentlicht durch",
      "shared": "Geteilt",
      "shared_with": "Dieser Artikel ist geteilt mit:",
      "show_more": "Mehr anzeigen",
      "sidebar": {
        "advisor": "Für Ihre eigenen Kandidaten",
        "global": "Für alle Organisationen",
        "organization": "Für %{organization_name}",
        "shared": "Mit Ihnen geteilt"
      },
      "status": "Status",
      "title": "Titel",
      "update": {
        "updated": "Die Regeln zum Teilen sind aktualisiert"
      }
    },
    "prices": {
      "active": "Aktiv",
      "amc": "AMC-Preis",
      "bov": "Preis BOV",
      "cannot_modify_expired": "Sie können aktive und abgelaufene Preislisten nicht bearbeiten",
      "created_by": "Erstellt von",
      "does_not_exist": "Preisliste nicht gefunden",
      "edit": "Preisliste bearbeiten",
      "effective_from": "Gültig ab",
      "expired": "Abgelaufen",
      "external_user": "Monatliche Kosten pro Kandidat",
      "fit": "Preis FIT",
      "internal_user": "Monatliche Gebühr pro Coach",
      "job_market_scan": "Preis Arbeitsmarktchancen",
      "job_market_scan_en": "Preis Arbeitsmarktchancen Englisch",
      "job_suggestions": "Preis Arbeitsvorschläge",
      "jobfeed": "Preis Jobfeed",
      "new": "Neue Preisliste",
      "pending": "Ausstehend",
      "price_created": "Die Preisliste ist erstellt",
      "price_updated": "Die Preisliste ist aktualisiert",
      "something_went_wrong": "Es ist etwas schiefgelaufen",
      "status": "Status",
      "updated_at": "Aktualisiert am"
    },
    "product_program_templates": {
      "create": {
        "created": "Dem Modul wurden Prozessvorlagen hinzugefügt",
        "product_program_templates_blank": "Es wurden keine Prozessvorlagen ausgewählt"
      },
      "destroy": {
        "product_program_template_destroyed": "Die Prozessvorlage wurde aus dem Modul entfernt"
      },
      "new": {
        "title": "Prozessvorlagen zu %{product} hinzufügen"
      },
      "row": {
        "product_program_template_delete": "Möchten Sie diese Prozessvorlagen wirklich aus dem Modul entfernen?"
      }
    },
    "product_status": {
      "active": "Aktiv",
      "deactivated": "Deaktiviert"
    },
    "product_tasks": {
      "create": {
        "created": "Die Assessments wurden dem Modul hinzugefügt",
        "product_tasks_blank": "Es wurden keine Assessments ausgewählt"
      },
      "deactivate": {
        "deactivated": "Die Verbindung zum Assessment ist deaktiviert"
      },
      "destroy": {
        "task_destroyed": "Das Assessment ist aus dem Modul entfernt"
      },
      "new": {
        "title": "Assessments hinzufügen zu %{product}"
      },
      "reactivate": {
        "reactivated": "Die Verbindung zum Assessment ist wieder aktiviert"
      },
      "row": {
        "task_delete": "Sind Sie sicher, dass Sie dieses Assessment aus dem Modul entfernen wollen?"
      }
    },
    "products": {
      "create": {
        "product_created": "Das Modul ist erstellt",
        "title": "Neues Modul"
      },
      "deactivate": {
        "deactivated": "Das Modul ist deaktiviert"
      },
      "destroy": {
        "deleted": "Das Modul ist gelöscht"
      },
      "edit": {
        "title": "Modul bearbeiten"
      },
      "index": {
        "new_button": "Neues Modul",
        "table_header": [
          "Name",
          "Status",
          "Status geändert am",
          "Handlungen"
        ],
        "title": "Module"
      },
      "new": {
        "title": "Neues Modul"
      },
      "reactivate": {
        "reactivated": "Das Modul ist wieder aktiviert"
      },
      "row": {
        "product_delete": "Sind Sie sicher, dass Sie dieses Modul löschen wollen?"
      },
      "show": {
        "new_button": "Assessments hinzufügen",
        "new_program_template_button": "Prozesse hinzufügen",
        "new_task_button": "Beurteilungen hinzufügen",
        "organizations": "Organisationen",
        "product_program_templates": "Prozessvorlagen",
        "product_program_templates_table_header": [
          "Name",
          "Sprache",
          "Status",
          "Aktionen"
        ],
        "product_tasks": "Assessments",
        "product_tasks_table_header": [
          "Name",
          "Key",
          "Sprache",
          "Credits",
          "Status",
          "Verbindung",
          "Handlungen"
        ],
        "status": "Status",
        "subscription_types": "Abonnementtypen"
      },
      "update": {
        "product_updated": "Das Modul ist aktualisiert",
        "title": "Modul bearbeiten"
      }
    },
    "profile": {
      "audits": {
        "show": {
          "action": "Aktion",
          "browser": "Browser",
          "caption": "Nachfolgend finden Sie alle Authentifizierungsaktionen für Ihr Konto.",
          "created_at": "Eingeloggt in",
          "ip": "IP",
          "title": "Audit-Historie"
        }
      },
      "base": {
        "tabs": {
          "audit_history": "Audit-Historie",
          "personal_information": "Persönliche Angaben",
          "settings": "Institutionen"
        }
      },
      "candidates": {
        "update": {
          "updated": "Ihre persönlichen Daten wurden aktualisiert"
        }
      },
      "images": {
        "destroy": {
          "deleted": "Profilfoto entfernt",
          "destroy_confirm": "Profilfoto entfernen"
        },
        "form": {
          "allowed_formats": "Zulässige Dateiformate: JPG, PNG",
          "upload": "Foto hochladen"
        },
        "update": {
          "updated": "Profilfoto angepasst"
        }
      },
      "modal": {
        "profile_image": {
          "change": "Anpassen",
          "save": "Speichern",
          "title": "Profilfoto anpassen",
          "upload": "Hochladen"
        }
      },
      "notification_settings": {
        "email_notifications": {
          "help_text": "Wählen Sie die gewünschten Benachrichtigungseinstellungen, unter Berücksichtigung, dass nur ungelesene Benachrichtigungen per E-Mail gesendet werden.",
          "title": "Häufigkeit der Benachrichtigungs-E-Mails"
        },
        "update": {
          "updated": "Ihre persönlichen Einstellungen wurden aktualisiert"
        }
      },
      "notifications": {
        "all": "Zeige alles",
        "bulk_update": {
          "marked_as_read": "Die Benachrichtigungen werden als gelesen markiert"
        },
        "document_approvals": "Dokumentengenehmigungen",
        "documenten": "Unterlagen",
        "documents": "Unterlagen",
        "last_30_days": "Letzte 30 Tage",
        "messages": "Mitteilung",
        "news": "Nachricht",
        "system": "System",
        "title": "Benachrichtigungen",
        "track": "Projekte",
        "tracks": "Flugbahnen"
      },
      "passwords": {
        "update": {
          "sent": "Sie erhalten eine E-Mail mit Anweisungen zum Ändern Ihres Passworts"
        }
      },
      "profiles": {
        "show": {
          "password": {
            "instructions": "Wenn Sie Ihr Kontopasswort ändern möchten, erhalten Sie von uns eine E-Mail mit Anweisungen.",
            "request": "Fragen",
            "title": "Kennwort ändern"
          },
          "profile_image": "Profilbild",
          "title": "Ihre Infos"
        }
      },
      "settings": {
        "activate_2fa": {
          "activate": "aktivieren Sie",
          "inactive": "2FA wurde noch nicht aktiviert"
        },
        "deactivate_2fa": {
          "active": "2FA ist aktiviert",
          "deactivate": "Deaktivieren",
          "disabled": "Die zweistufige Verifizierung ist für die Organisation verpflichtend"
        },
        "two_factor_authentication": {
          "title": "2-Faktor-Authentifizierung"
        }
      },
      "title": "Mein Profil",
      "two_factor_authentications": {
        "destroy": {
          "destroyed": "2FA ist deaktiviert",
          "tfa_required": "2FA kann nicht deaktiviert werden"
        }
      },
      "users": {
        "update": {
          "updated": "Ihre persönlichen Daten wurden aktualisiert"
        }
      }
    },
    "profile_picture_disclaimer": {
      "accept": "Akzeptieren",
      "cancel": "Abbrechen",
      "title": "Profilfoto Disclaimer"
    },
    "program_pages": {
      "edit": {
        "drop": "Hier Elemente hineinziehen"
      },
      "new": {
        "default_page_title": "Neuer Schritt"
      },
      "show": {
        "not_editable": "Dieser Prozess ist veröffentlicht und darum auch zuweisbar, aber er kann erst nach Zurückziehen wieder bearbeitet werden",
        "not_editable_advisor": "Als Prozess-Coach können Sie diesen Prozess nicht bearbeiten, aber Sie können ihn ansehen (inklusiv aller Einstellungen)",
        "not_editable_deleted": "Dieser Prozess wurde gelöscht und kann daher nicht mehr bearbeitet oder zugewiesen werden",
        "not_editable_template": "Diese Vorlage können Sie sich ansehen oder zu den eigenen Prozessen Ihrer Organisation kopieren"
      }
    },
    "program_templates": {
      "bulk": {
        "publish": "Es wurden mehrere Prozesse veröffentlicht",
        "withdraw": "Mehrere Prozesse wurden zurückgezogen"
      },
      "destroy": {
        "destroyed": "Der Prozess „%{program_title}“ wurde gelöscht"
      },
      "duplicate": {
        "duplicate_prefix": "Kopieren:",
        "program_duplicated": "Der Prozess „%{program_title}“ wurde kopiert",
        "template_duplicated": "Der Prozess „%{program_title}“ wurde in die eigenen Prozesse Ihrer Organisation kopiert"
      },
      "form": {
        "invite_mail": "Einladungs-E-Mail",
        "invite_mail_documentation": "(Klicken Sie hier für die Einladungs-E-Mail-Dokumentation)",
        "labels": "Etiketten",
        "options": "Optionen",
        "title_placeholder": "Wählen Sie einen Titel",
        "your_labels": "Ihre Etiketten"
      },
      "index": {
        "help_text": "Entwerfen, bearbeiten oder sehen Sie die Prozesse Ihrer Organisation. Prozesse stehen für Berater mit der Coach-Rolle und Premium-Kandidaten zur Verfügung.",
        "new_button": "Neuer Prozess",
        "own_programs": "Eigene Prozesse",
        "table_header": [
          "Status",
          "Fakten",
          "Länge",
          "Aktionen"
        ],
        "templates": "Vorlagen",
        "title": "Prozesse entwickeln"
      },
      "new": {
        "default_program_title": "Verfahren"
      },
      "publish": {
        "all_pages_invisible": "Sie können diesen Prozess nicht veröffentlichen, da keiner der Schritte als für den Kandidaten sichtbar festgelegt ist",
        "published": "Der Prozess „%{program_title}“ wurde veröffentlicht"
      },
      "publish_as_template": {
        "published_as_template": "Als Vorlage wurde der Prozess „%{program_title}“ veröffentlicht"
      },
      "update": {
        "update_failed": "Der Prozess konnte nicht aktualisiert werden",
        "updated": "Der Prozess wurde aktualisiert"
      },
      "withdraw": {
        "withdrawn": "Der Prozess „%{program_title}“ wurde eingestellt"
      }
    },
    "programs": {
      "bulk": {
        "publish": "Mehrere Prozesse werden veröffentlicht",
        "withdraw": "Mehrere Prozesse werden zurückgezogen"
      },
      "destroy": {
        "destroyed": "Der Prozess '%{program_title}' ist gelöscht"
      },
      "duplicate": {
        "duplicate_prefix": "Kopie:",
        "program_duplicated": "Der Prozess '%{program_title}' ist kopiert",
        "template_duplicated": "Der Prozess '%{program_title}' ist zu den Prozessen Ihrer Organisation kopiert"
      },
      "form": {
        "labels": "Labels",
        "options": "Optionen",
        "title_placeholder": "Wählen Sie einen Titel",
        "your_labels": "Ihre Labels"
      },
      "index": {
        "help_text": "Entwerfen, bearbeiten oder betrachten Sie die Prozesse Ihrer Organisation",
        "new_button": "Neuer Prozess",
        "own_programs": "Eigene Prozesse",
        "table_header": [
          "Status",
          "Angaben",
          "Länge",
          "Handlungen"
        ],
        "templates": "Vorlagen",
        "title": "Prozesse entwickeln"
      },
      "new": {
        "default_program_title": "Prozess"
      },
      "publish": {
        "all_pages_invisible": "Sie können diesen Prozess nicht veröffentlichen, da keiner der Schritte als für den Kandidaten sichtbar festgelegt ist",
        "published": "Der Prozess '%{program_title}' ist veröffentlicht"
      },
      "publish_as_template": {
        "published_as_template": "Der Prozess '%{program_title}' ist als Vorlage veröffentlicht"
      },
      "update": {
        "update_failed": "Der Prozess konnte nicht aktualisiert werden",
        "updated": "Der Prozess ist aktualisiert"
      },
      "withdraw": {
        "withdrawn": "Der Prozess '%{program_title}' ist zurückgezogen"
      }
    },
    "prompt": {
      "cost_center_delete_confirm": "Sind Sie sicher, dass Sie diese Kostenstelle löschen wollen?",
      "file_remove_confirm": "Sind Sie sicher, dass Sie diese Datei löschen wollen?",
      "program_delete_confirm": "Sind Sie sicher, dass Sie diesen Prozess mit all seinen Schritten und Elementen löschen wollen?",
      "room_delete_confirm": "Sind Sie sicher, dass Sie dieses Videogespräch löschen wollen?",
      "subscription_delete_confirm": "Sind Sie sicher, dass Sie dieses Abonnement löschen wollen?",
      "subscription_upgrade_confirm": "Sind Sie sicher, dass Sie Ihr Abonnement upgraden möchten? Sie können innerhalb eines Jahres nach Start des neuen Abonnements kein Downgrade durchführen."
    },
    "resource": {
      "candidate_email_messages": "E-Mails gesendet",
      "close": "Schließen",
      "edit": "Ändern",
      "filter": "Filtern",
      "impersonate": "Einloggen als",
      "interactive_results": "Ergebnisse",
      "new_enrollment": "Route erstellen",
      "reinvite": "Wieder einladen"
    },
    "role": {
      "AccountManager": "Account-Manager",
      "Coach": "Prozess-Coach",
      "IxlyAdmin": "Ixly-Verwalter",
      "OrganizationManager": "Organisationsverwalter",
      "SelectionManager": "Auswahlmanager"
    },
    "rooms": {
      "show": {
        "cannot_join": "Dieses Videogespräch ist derzeit geschlossen"
      }
    },
    "score": {
      "no_score": "Ohne partitur",
      "with_score": "Mit partitur"
    },
    "selection": {
      "job_profiles": {
        "create": {
          "success": "Jobprofil wurde erstellt",
          "title": "Neues Jobprofil"
        },
        "destroy": {
          "failure": "kann nicht gelöscht werden, da es mit einem oder mehreren Matching-Befehlen verknüpft ist",
          "success": "Das ausgewählte Jobprofil wurde gelöscht"
        },
        "edit": {
          "title": "Stellenprofil bearbeiten"
        },
        "form": {
          "job_profile_explanation": "Bei Compare & Select verwenden wir erweiterte Beurteilungen, die die Kompetenzen und Intelligenz der Kandidaten objektiv messen. Unsere standardisierten Auswertungen liefern konkrete Scores, die die Grundlage für unser datengetriebenes Matching bilden. Mit diesem wissenschaftlichen Ansatz überwinden wir die Subjektivität und führen Fürsorge ein"
        },
        "index": {
          "compare_select_icon_alt": "Symbol „Vergleichen und auswählen“.",
          "list": "Navigieren Sie zur <strong>Kandidatenübersicht, wählen Sie die Kandidaten aus</strong> und klicken Sie auf die Schaltfläche <strong>„Vergleichen & Auswählen“</strong> %{icon}, um die passenden Ergebnisse anzuzeigen.",
          "title": "Job-Profile"
        },
        "list": {
          "created_at": "Gemacht auf",
          "title": "Titel",
          "updated_at": "Zuletzt bearbeitet am"
        },
        "new": {
          "title": "Neues Jobprofil",
          "tooltip": "Funktion Profil hinzufügen"
        },
        "update": {
          "success": "Die Bearbeitung des Stellenprofils war erfolgreich",
          "title": "Stellenprofil bearbeiten"
        }
      },
      "matching_assignments": {
        "create": {
          "candidates": "Kandidaten",
          "success": "Die passende Aufgabe wurde erfolgreich erstellt",
          "title": "Verfassen Sie eine passende Aufgabe"
        },
        "edit": {
          "title": "Bearbeiten Sie die passende Aufgabe"
        },
        "index": {
          "candidate": "Kandidaten",
          "caption": "Hier finden Sie eine Übersicht Ihrer passenden Aufgaben",
          "list": {
            "candidates": "Kandidaten",
            "created_at": "Gemacht auf",
            "job_title": "Berufsbezeichnung",
            "label": "Etikett",
            "status": "Steht",
            "title": "Besonderheit",
            "updated_at": "Zuletzt bearbeitet am"
          },
          "title": "Passende Aufgaben"
        },
        "list": {
          "assignment_note": "<span class='font-bold'>Bitte beachten Sie:</span> Nicht allen Kandidaten wurde ein ACT und ein WPV zugewiesen. <a href='%{assignment_link}' class='font-bold underline hover:text-blue-light' data-turbo-frame='_top'>Klicken Sie hier</a>, um sofort einen Intelligenztest (ACT) und eine Kompetenz abzulegen Test (WPV) zugewiesen werden.<br> Wir sorgen für einen reibungslosen Ablauf dieses Prozesses und verhindern Doppelbelegungen.",
          "name": "Name"
        },
        "new": {
          "title": "Verfassen Sie eine passende Aufgabe"
        },
        "show": {
          "education_level": "Gemessener Bildungsstand",
          "title": "Übereinstimmende Ergebnisse"
        },
        "tooltip": {
          "start_comparison": "Vergleichen Sie Kandidaten"
        },
        "update": {
          "success": "Die Bearbeitung der passenden Aufgabe war erfolgreich",
          "title": "Bearbeiten Sie die passende Aufgabe"
        }
      },
      "matching_results": {
        "index": {
          "education_level": "Gemessener Bildungsstand",
          "list": {
            "competencies": "Kompetenzen",
            "education_level": "Gemessener Bildungsstand",
            "matching_score": "Passende Punktzahl",
            "name": "Name"
          },
          "title": "Passende Aufgabe"
        },
        "list": {
          "assignment_note": "<span class='font-bold'>Hinweis:</span> Nicht alle Kandidaten wurden Prüfungen zugewiesen. <a href='%{assignment_link}' class='font-bold underline hover:text-blue-light' data-turbo-frame='_top'>Klicken Sie hier</a>, um Bewertungen direkt zuzuweisen.",
          "name": "Name"
        }
      },
      "results_overview": {
        "index": {
          "title": "Ergebnisübersicht"
        }
      },
      "title": "Compare & Select"
    },
    "sessions": {
      "create": {
        "combination_does_not_exist": "Ihre E-Mail-Adresse oder Ihr Passwort ist falsch"
      },
      "destroy": {
        "logout": "Sie haben sich erfolgreich eingeloggt"
      },
      "new": {
        "forgot_your_password": "Passwort vergessen?",
        "help_text": "Willkommen auf der Login-Seite des Assessment Platform",
        "new_login": "Login",
        "title": "Einloggen"
      },
      "verify_otp": {
        "invalid": "Der eingegebene Code war falsch"
      }
    },
    "shared": {
      "accounts": {
        "suppressed_email": "Die mit diesem Konto verknüpfte E-Mail-Adresse ist <span class=\"font-medium\">nicht erreichbar</span>.",
        "suppressed_email_tooltip": "Die mit diesem Konto verknüpfte E-Mail-Adresse ist nicht erreichbar.",
        "unconfirmed_email": {
          "unconfirmed_email": "Die E-Mail-Adresse wird geändert in:",
          "unconfirmed_email_explanation": "Der Inhaber der E-Mail-Adresse muss die Änderung über einen Link bestätigen. Die geänderte E-Mail-Adresse wird erst nach Bestätigung verwendet."
        }
      },
      "candidate_result_widgets": {
        "personal_information": {
          "date_of_birth": "Geburtsdatum",
          "education": "Bildungsstand",
          "email": "E-Mail-Adresse",
          "most_recent_work_experience": "Neueste Berufserfahrung",
          "study_subject": "Kurs",
          "title": "persönliche Daten",
          "years": {
            "one": "%{years} Jahre",
            "other": "%{years} Jahre"
          },
          "years_of_experience": "langjährige Erfahrung"
        }
      },
      "enrollment_sidebar": {
        "advisor": "Coach",
        "candidate": "Kandidat",
        "coadvisor": "Zweitcoach",
        "comments": "Nachrichten",
        "contact_person": "Kontaktperson",
        "room": "Videogespräch",
        "track": "Projekt"
      },
      "forms": {
        "password": {
          "hide_password": "Passwort verbergen",
          "show_password": "Passwort anzeigen"
        }
      },
      "header": {
        "admin": "Verwalter",
        "advisors": "Berater",
        "all_organizations": "Alle Organisationen",
        "all_users": "Alle Nutzer",
        "approval_requests": "Zustimmungen",
        "blog": "Blog",
        "candidates": "Kandidaten",
        "clients": "Auftraggeber",
        "enrollers": "Links zum Einschreiben",
        "login": "Einloggen",
        "organization": "Organisation",
        "prices": "Preise",
        "products": "Module",
        "programs": "Prozesse entwickeln",
        "rooms": "Videogespräche",
        "settings": "Einstellungen",
        "subscription_types": "Abonnementtypen",
        "tasks": "Aufgaben",
        "tracks": "Projekte",
        "translations": "Übersetzungen",
        "users": "Nutzer"
      },
      "header_profile": {
        "log_in_as": "Einloggen als:",
        "log_in_at": "Login bei:",
        "logout": "Ausloggen",
        "my_tracks": "Meine Projekte",
        "profile": "Profil"
      },
      "notifications_sidebar": {
        "help_text": "Ihre Aufgabenübersicht mit untenstehenden Optionen filtern:",
        "title": "Filter"
      },
      "organization_sidebar": {
        "clients_help_text": "Ihre Auftraggeber mit untenstehenden Optionen filtern:",
        "labels": "Labels",
        "programs_help_text": "Ihre Prozesse mit untenstehenden Optionen filtern:",
        "title": "Filter",
        "users_help_text": "Ihre Nutzer mit untenstehenden Optionen filtern:",
        "your_own_organization": "Ihre Organisation"
      },
      "rebound_email_banner": {
        "action_label": "E-Mail Adresse ändern",
        "description": "Eine an [[email]] gesendete E-Mail konnte nicht zugestellt werden. Aus diesem Grund: [[Grund]]",
        "fallback_description": "Eine kürzlich an [[email]] gesendete E-Mail konnte nicht zugestellt werden.",
        "title": "Aktualisieren Sie Ihre E-Mail-Adresse"
      },
      "suppressed_email": "Die mit diesem Konto verknüpfte E-Mail-Adresse ist <span class=\"font-medium\">nicht erreichbar</span>.",
      "suppressed_email_tooltip": "Die mit diesem Konto verknüpfte E-Mail-Adresse ist nicht erreichbar.",
      "tracks_sidebar": {
        "filters": {
          "client": "Vom Auftraggeber",
          "label": "Labels",
          "process": "Prozesse",
          "role": "Ihre Rolle im Projekt",
          "status": "Status"
        },
        "help_text": "Filtern Sie Ihre Kandidaten mit den folgenden Optionen:",
        "title": "Filter"
      },
      "user_sidebar": {
        "my_track": "Mein Projekt",
        "new_track": "Projekt erstellen",
        "show_deactivated": "Deaktivierte anzeigen",
        "track_of": "Projekt von %{name}",
        "track_with": "Projekt mit %{name}"
      }
    },
    "sidebar": {
      "close": "Seitenleiste schließen"
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Bitte beachten Sie die untenstehenden Probleme:"
      },
      "include_blank": {
        "default_mail_template": {
          "no_default": "Kein Standardvorlage"
        },
        "select_option": "Treffen Sie Ihre Wahl"
      },
      "labels": {
        "advisor": {
          "avatar": "Profilfoto",
          "biography": "Persönliche Beschreibung",
          "can_impersonate": "Darf als anderer Nutzer einloggen",
          "consent_given": "Zustimmung",
          "cost_center_id": "Kostenstelle",
          "current_password": "Jetziges Passwort",
          "first_name": "Vorname",
          "id": "Kontakt",
          "invite_immediately": "Direkt eine Willkommensmail zuschicken",
          "label": "Labels",
          "label_ids": "Etiketten",
          "labels": "Etiketten",
          "language": "Sprache",
          "last_name": "Nachname",
          "mailing_frequency": "Häufigkeit der Benachrichtigungen per Mail",
          "new_password": "Neues Passwort",
          "organization": "Organisation",
          "organization_language": "Sprache der Organisation",
          "organization_name": "Name der Organisation",
          "otp_code": "Code",
          "privacy_accepted": "Bedingungen / Datenschutz",
          "remove_avatar": "Dieses Foto löschen",
          "role": "Rollen",
          "roles": "Rollen",
          "status": "Status",
          "type": "Benutzertyp",
          "unconfirmed_email": "E-Mail-Adresse"
        },
        "assignment": {
          "deadline": "Bewertungsfrist",
          "invited_at": "Einladungsdatum",
          "mail_template_id": "Einladungs-E-Mail"
        },
        "authentication_action": {
          "auth_action": "Art der Authentifizierung"
        },
        "candidate": {
          "account_email": "E-Mail-Adresse",
          "advisor": "Berater",
          "advisor_id": "Berater",
          "client": "Auftraggeber",
          "client_ids": "Auftraggeber",
          "coadvisor": "Mitberater",
          "coadvisor_ids": "Co-Berater",
          "coadvisors": "Mitberater",
          "contact_person": "Kontaktpersonen",
          "contact_person_ids": "Kontaktpersonen",
          "contact_persons": "Kontakte",
          "cost_center_id": "Kostenstelle",
          "created_at": "Datum der Registrierung",
          "deadline": "Bewertungsfrist",
          "end_date": "Endtermin",
          "first_name": "Vorname",
          "invitation_date": "Einladungsdatum",
          "invite_immediately": "Gleich einladen",
          "label": "Labels",
          "label_ids": "Etiketten",
          "labels": "Labels",
          "language": "Sprache",
          "last_name": "Nachname",
          "mail_template_id": "Einladungsmail",
          "organization": "Organisation",
          "program": "Prozesse",
          "report_objective": "Berichtsziel",
          "start_date": "Anfangsdatum",
          "status": "Status"
        },
        "candidate_assessment": {
          "document_options": "Berichtsoptionen",
          "norm_group_uuid": "Berichtsziel",
          "report_to": "Ergebnisse zuerst verfügbar für",
          "selected_report_id": "Bericht"
        },
        "candidate_assessment_program": {
          "document_options": "Berichtsoptionen",
          "norm_group_uuid": "Berichtsziel",
          "report_to": "Ergebnisse zuerst verfügbar für",
          "selected_report_id": "Bericht"
        },
        "candidate_matching_assignment": {
          "education_level": "Bildungsstand",
          "no_score": "Keine Punktzahl",
          "score": "Punktzahl"
        },
        "candidate_report": {
          "candidate_assessment_program_id": "Bewertungsprogramm",
          "document_options": "Berichtsoptionen",
          "norm_group_id": "Berichtsziel",
          "report_id": "Bericht",
          "reportable": "Assessment"
        },
        "client": {
          "label_ids": "Etiketten",
          "name": "Name"
        },
        "component": {
          "alt_text": "Beschreibung der Abbildung für Screenreader",
          "bov_editable_by": "BOV kann gestartet werden von",
          "can_approve": "Zustimmung zu geben durch",
          "cc_load_policy": "Beim Abspielen direkt Untertitel (in eigener Sprache) anzeigen",
          "controls": "Beim Abspielen Kontrollbalken unten anzeigen",
          "description_finished": "Zusätzliche Beschreibung nach Abschluss",
          "editable_by": "Zu beantworten von",
          "html": "HTML-Code",
          "iframe_title": "Titel des Videos für Screenreader",
          "label": "Geben Sie diesem Feld ein Label (Bezeichnung)",
          "max_selections": "Maximale Anzahl Selektionen",
          "options": "Optionen",
          "question": "Frage",
          "rel": "Nach dem Abspielen auch verwandte Videos von anderen Kanälen anzeigen lassen",
          "report_visible_for": "Ergebnis sichtbar für",
          "required": "Muss mit \"Ja\" beantwortet werden, um fortzufahren",
          "sections": "Sektionen",
          "task_key": "Gewähltes Assessment",
          "template_can_be_switched_by": "Vorlage kann ausgetauscht werden gegen",
          "url": "YouTube-Video URL",
          "visible_for": "Dokument sichtbar für",
          "visible_to_candidate": "Machen Sie das Element auch für den Kandidaten sichtbar",
          "visible_to_contact_person": "Teilen Sie das Dokument nach der Genehmigung automatisch mit den Kontakten"
        },
        "contact_person": {
          "avatar": "Profilfoto",
          "biography": "Persönliche Beschreibung",
          "can_impersonate": "Darf als anderer Nutzer einloggen",
          "client": "Auftraggeber",
          "consent_given": "Zustimmung",
          "cost_center_id": "Kostenstelle",
          "current_password": "Jetziges Passwort",
          "first_name": "Vorname",
          "id": "Kontakt",
          "invite_immediately": "Direkt eine Willkommensmail zuschicken",
          "label": "Labels",
          "label_ids": "Etiketten",
          "labels": "Etiketten",
          "language": "Sprache",
          "last_name": "Nachname",
          "mailing_frequency": "Häufigkeit der Benachrichtigungen per Mail",
          "new_password": "Neues Passwort",
          "organization": "Organisation",
          "organization_language": "Sprache der Organisation",
          "organization_name": "Name der Organisation",
          "otp_code": "Code",
          "privacy_accepted": "Bedingungen / Datenschutz",
          "remove_avatar": "Dieses Foto löschen",
          "role": "Rollen",
          "status": "Status",
          "type": "Benutzertyp",
          "unconfirmed_email": "E-Mail-Adresse"
        },
        "cost_center": {
          "identifier": "Name"
        },
        "defaults": {
          "account_email": "E-Mail-Adresse",
          "advisor_id": "Coach",
          "candidate_id": "Kandidat",
          "client_id": "Auftraggeber",
          "coadvisor_id": "Zweitcoach",
          "contact_person_id": "Kontaktperson",
          "description": "Beschreibung",
          "email": "E-Mail-Adresse",
          "end_date": "Enddatum",
          "explanation": "Erläuterung",
          "first_name": "Vorname",
          "language": "Sprache der Webanwendung",
          "last_name": "Nachname",
          "name": "Name",
          "organization_id": "Organisation",
          "password": "Passwort",
          "product_id": "Module",
          "program_id": "Prozess",
          "start_date": "Anfangsdatum",
          "title": "Titel",
          "two_factor": "Verifizierung in zwei Schritten"
        },
        "discount": {
          "end_date": "Endtermin",
          "kind": "Auf",
          "organization": "Organisation",
          "organization_id": "Organisation",
          "percentage": "Prozentsatz",
          "start_date": "Startdatum"
        },
        "enroller": {
          "advisor": "Coach",
          "advisor_id": "Coach",
          "allow_repeated": "Erlauben Sie wiederholte Registrierungen",
          "link": "Link",
          "program": "Prozess",
          "program_id": "Prozess",
          "show_default": "Standardtext anzeigen"
        },
        "enrollment": {
          "coadvisor_id": "Kakao",
          "invite_immediately": "Direkt einladen",
          "program_id": "Verfahren"
        },
        "entry": {
          "identifier": "Identifikator",
          "type": "Typ"
        },
        "import/file": {
          "created_at": "hinzugefügt",
          "imported_rows": "importierte Zeilen",
          "name": "Name",
          "organization": "Organisation",
          "organization_id": "Organisation",
          "state": "Statusimport",
          "total_rows": "Gesamtzahl der Zeilen",
          "type": "Typ"
        },
        "import_file": {
          "created_at": "hinzugefügt",
          "download_example": "Beispieldatei herunterladen",
          "imported_rows": "importierte Zeilen",
          "name": "Name",
          "organization": "Organisation",
          "organization_id": "Organisation",
          "state": "Statusimport",
          "total_rows": "Gesamtzahl der Zeilen",
          "type": "Typ"
        },
        "ixly_employee": {
          "avatar": "Profilfoto",
          "biography": "Persönliche Beschreibung",
          "can_impersonate": "Darf als anderer Nutzer einloggen",
          "consent_given": "Zustimmung",
          "cost_center_id": "Kostenstelle",
          "current_password": "Jetziges Passwort",
          "first_name": "Vorname",
          "id": "Kontakt",
          "invite_immediately": "Direkt eine Willkommensmail zuschicken",
          "label": "Labels",
          "language": "Sprache",
          "last_name": "Nachname",
          "mailing_frequency": "Häufigkeit der Benachrichtigungen per Mail",
          "new_password": "Neues Passwort",
          "organization": "Organisation",
          "organization_language": "Sprache der Organisation",
          "organization_name": "Name der Organisation",
          "otp_code": "Code",
          "privacy_accepted": "Bedingungen / Datenschutz",
          "remove_avatar": "Dieses Foto löschen",
          "role": "Rollen",
          "roles": "Rollen",
          "status": "Status",
          "type": "Benutzertyp",
          "unconfirmed_email": "E-Mail-Adresse"
        },
        "job_profile": {
          "competencies": "Kompetenzen",
          "education_level": "Bildungsstand",
          "job_profile": "Berufsbild",
          "label": "Etiketten",
          "label_ids": "Etiketten",
          "title": "Berufsbezeichnung",
          "user": "Benutzer"
        },
        "join": {
          "display_name": "Anzeigename",
          "start_hidden": "Verborgen beitreten",
          "start_muted": "Stummgeschaltet beitreten"
        },
        "leniency": {
          "credits": "Credits",
          "reason": "Grund"
        },
        "mail_template": {
          "body": "Text",
          "language": "Sprache",
          "subject": "Thema",
          "title": "Titel"
        },
        "matching_assignment": {
          "education_level": "Bildungsstand",
          "job_profile": "Berufsbild",
          "job_profile_competencies": "Kompetenzen",
          "job_profile_education_level": "Bildungsstand",
          "job_profile_title": "Berufsbild",
          "label": "Etiketten",
          "label_ids": "Etiketten",
          "matching_score": "Passende Punktzahl",
          "no_score": "Kein Ergebnis",
          "title_description": "Besonderheit",
          "user": "Benutzer",
          "vacancy_number_placeholder": "Stellenangebotsnummer"
        },
        "media_item": {
          "advisor": "Berater",
          "candidate": "Kandidat",
          "contact_person": "Kontaktperson"
        },
        "organization": {
          "account_manager_id": "Account-Manager",
          "address": "Adresse",
          "advisor_can_create_candidates": "Berater können Kandidaten erstellen",
          "advisor_can_manage_existing_candidates": "Berater können alle Kandidaten einsehen und aktualisieren",
          "advisor_can_manage_users": "Berater können Prozesscoaches und Kontakte anlegen",
          "advisor_can_remove_candidates": "Berater können Kandidaten entfernen",
          "advisor_can_view_enrollers": "Prozess-Coaches können sich Links zum Einschreiben ansehen",
          "advisor_can_view_prices": "Prozess-Coaches können Preise sehen",
          "advisor_deactivation_period": "Automatische Deaktivierung des Prozess-Coaches",
          "advisor_rooms_permission": "Prozess-Coaches können sich Videogespräche ansehen",
          "allow_compare_select": "Compare & Select zulassen",
          "allow_impersonation": "Einloggen als -Funktion zulassen",
          "allow_ixly_support": "Erlauben Sie Ixly-Support",
          "api_anonymous": "API anonym",
          "api_key": "API key",
          "api_organization_id": "Test Toolkit organisation uuid",
          "api_user": "API Nutzer-E-Mail",
          "available_languages": "Verfügbare Sprachen",
          "brand_logo": "Logo",
          "candidate_deactivation_period": "Sperrfrist für Kandidaten",
          "city": "Stadt",
          "clients_enabled": "Kunden und Ansprechpartner werden für die Organisation engagiert",
          "country": "Land",
          "credit_bundle": "Bündel",
          "default_language": "Standardsprache",
          "default_report_objective": "Standard für „Berichtsziel“",
          "default_report_to": "Ergebnisse zuerst verfügbar für",
          "has_dashboard_link": "Berater haben einen Link zur Dashboard-App",
          "interactive_button_color": "Farbe Icons",
          "interactive_header_color": "Sekundäre Farbe",
          "interactive_header_text_color": "Textfarbe des Seitentitels",
          "interactive_primary_color": "Primäre Farbe",
          "invoice_company_name": "Name des Rechnungsunternehmens",
          "invoice_company_name_placeholder": "Lassen Sie das Feld leer, da es mit dem Namen der Organisation übereinstimmt",
          "invoice_email": "E-Mail-Adresse für Rechnungen",
          "invoice_remarks": "Rechnungskommentare",
          "invoice_remarks_placeholder": "Wird unterhalb der Rechnung angezeigt",
          "name": "Name der Organisation",
          "organization_manager_can_view_enrollments": "Geben Sie dem Organisationsmanager mehr Einblick in die Prozesse",
          "payment_type": "Zahlungsmethode",
          "postal_code": "Postleitzahl",
          "privacy_link": "Datenschutz",
          "profile_picture_disclaimer": "Text Disclaimer",
          "remove_logo": "Logo löschen",
          "remove_report_cover_logo": "Entfernen Sie das Logo des Berichtscovers",
          "remove_report_header_logo": "Entfernen Sie das Logo aus der Berichtskopfzeile",
          "report_cover_logo": "Cover-Logo für Bericht",
          "report_header_logo": "Cover-Logo für Bericht",
          "retention_period": "Aufbewahrungsdauer personenbezogener Daten",
          "show_interactive_results": "Ergebnisse anzeigen",
          "show_posts": "Blog anzeigen",
          "status": "Status",
          "subscription_type": "Abonnement",
          "terms_link": "Bedingungen",
          "two_factor": "Für diese Rollen ist eine Bestätigung in zwei Schritten erforderlich",
          "vat_number": "USt-IdNr",
          "welcome_mail_subject": "Betreff der Willkommensmail",
          "welcome_mail_text": "Text der Willkommensmail",
          "whitelabeled": "White labeled"
        },
        "organization_default_mail_template": {
          "mail_template_id": "Standard-Mail-Vorlage"
        },
        "post_rule": {
          "label_ids": "Labels",
          "program_ids": "Prozesse",
          "roles": "Nutzerrollen"
        },
        "premium_candidate": {
          "coadvisor_ids": "Co-Berater",
          "contact_person_ids": "Kontakte",
          "label_ids": "Etiketten"
        },
        "price": {
          "amc": "AMC-Preis, €",
          "bov": "BOV Preis, €",
          "external_user": "Monatlicher Beitrag pro Kandidat, €",
          "fit": "FIT Preis, €",
          "internal_user": "Monatsgebühr pro Coach, €",
          "job_market_scan": "Preis der Arbeitsmarktchancen, €",
          "job_suggestions": "Jobvorschläge preis, €",
          "jobfeed": "Jobfeed-Preis, €",
          "start_date": "Wirksam ab"
        },
        "product": {
          "name": "Name"
        },
        "product_program_templates": {
          "ids": "Prozessvorlagen"
        },
        "product_tasks": {
          "ids": "Assessments"
        },
        "program": {
          "invite_mail_subject": "Betreff der Einladungsmail",
          "invite_mail_text": "Text der Einladungsmail",
          "label": "Labels",
          "language": "Sprache",
          "status": "Status",
          "step_order": "Reihenfolge der Schritte",
          "type": "Typ"
        },
        "program_page": {
          "approve_by": "Genehmigung durch",
          "has_comments": "Nachrichten unten im Schritt anzeigen",
          "hidden_for_candidate": "Verstecken Sie den Schritt vor dem Kandidaten",
          "optional": "Macht diesen Schritt optional (einfügbar nach Zuweisung)"
        },
        "program_template": {
          "invite_mail_subject": "Betreff: Einladungs-E-Mail",
          "invite_mail_text": "SMS-Einladung per E-Mail",
          "label": "Etiketten",
          "status": "Status",
          "step_order": "Reihenfolge der Schritte",
          "type": "Typ"
        },
        "report_objectives": {
          "advice": "Beratung",
          "other": "Ansonsten",
          "selection": "Auswahl"
        },
        "room": {
          "end_to_end_encryption": "Ende-zu-Ende-Verschlüsselung",
          "everyone_starts_hidden": "Jeder start verborgen",
          "everyone_starts_muted": "Jeder start stummgeschaltet",
          "link": "Link",
          "only_managers_can_join": "Nur Organisationsleiter können teilnehmen",
          "only_managers_can_kick": "Nur Organisationsverwalter können andere entfernen",
          "only_managers_can_mute": "Nur Organisationsverwalter können andere stummschalten",
          "subject": "Betreff"
        },
        "subscription": {
          "annual_fee": "Jährliche Abonnementgebühr",
          "end_date": "Endtermin",
          "start_date": "Startdatum",
          "subscription_type": "Abonnementtyp",
          "subscription_type_id": "Abonnementtyp"
        },
        "subscription_type": {
          "can_downgrade_to": "Kann der Abonnementtyp zurückstufen",
          "can_upgrade_to": "Kann der Abonnementtyp hochstufen",
          "candidate_price": "Jährliche Kosten pro Kandidat für unbegrenzte Beurteilungen",
          "credit_price": "Preis pro Credit",
          "credits_notice": "Meldung \"wenig verbleibende Credits\" bei",
          "external_user": "Monatliche Kosten pro Kandidat",
          "internal_user": "Monatliche Gebühr pro Coach",
          "invoice_line_type": "Kategorie Buchhaltung",
          "max_candidates": "Maximale Anzahl aktiver Kandidaten",
          "max_coaches": "Maximale Anzahl von aktiven Coaches",
          "max_users": "Maximale Anzahl von aktiven Coaches",
          "month_price": "Monatliche Abonnementgebühr",
          "payment_period": "Zahlungszeitraum",
          "prepay": "Im Voraus bezahlt",
          "prepay_credits": "Verfügbare Credit-Bündel",
          "type": "Kategorie"
        },
        "task": {
          "language": "Sprache"
        },
        "translation": {
          "identifier": "Identifier"
        },
        "user": {
          "avatar": "Profilfoto",
          "biography": "Persönliche Beschreibung",
          "can_impersonate": "Darf als anderer Nutzer einloggen",
          "consent_given": "Zustimmung",
          "cost_center_id": "Kostenstelle",
          "current_password": "Jetziges Passwort",
          "first_name": "Vorname",
          "id": "Kontakt",
          "invite_immediately": "Direkt eine Willkommensmail zuschicken",
          "label": "Labels",
          "language": "Sprache",
          "last_name": "Nachname",
          "mailing_frequency": "Häufigkeit der Benachrichtigungen per Mail",
          "new_password": "Neues Passwort",
          "organization": "Organisation",
          "organization_language": "Sprache der Organisation",
          "organization_name": "Name der Organisation",
          "otp_code": "Code",
          "privacy_accepted": "Bedingungen / Datenschutz",
          "remove_avatar": "Dieses Foto löschen",
          "role": "Rollen",
          "status": "Status",
          "type": "Benutzertyp",
          "unconfirmed_email": "E-Mail-Adresse"
        },
        "whitelabel": {
          "domain": "Domäne",
          "subdomain": "Subdomäne"
        }
      },
      "options": {
        "component": {
          "bov_editable_by": {
            "both": "Beides (der Trainer kann erst starten, nachdem der Kandidat gestartet ist)",
            "candidate": "Der Kandidat"
          },
          "can_approve": {
            "candidate": "Der Kandidat",
            "contact_person": "Die Kontaktperson"
          },
          "editable_by": {
            "advisor": "Der Coach",
            "both": "Beide",
            "candidate": "Der Kandidat"
          },
          "report_visible_for": {
            "advisor": "Der Coach",
            "both": "Beide",
            "candidate": "Der Kandidat"
          },
          "template_can_be_switched_by": {
            "advisor": "Der Coach",
            "both": "Beide",
            "candidate": "Der Kandidat",
            "creator": "Niemand"
          },
          "visible_for": {
            "advisor": "Der Coach",
            "both": "Beide",
            "candidate": "Der Kandidat"
          }
        },
        "discount": {
          "kind": {
            "amc": "Preis AMC",
            "bov": "Preis BOV",
            "candidate_price": "Jährliche Kosten pro Kandidat für unbegrenzte Beurteilungen",
            "credit_price": "Preis pro Credit",
            "external_user": "Monatliche Kosten pro Kandidat",
            "fit": "Preis FIT",
            "internal_user": "Monatliche Gebühr pro Coach",
            "job_market_scan": "Preis Arbeitsmarktchancen",
            "job_suggestions": "Preis Arbeitsvorschläge",
            "jobfeed": "Preis Jobfeed",
            "month_price": "Monatliche Abonnementgebühr"
          }
        },
        "organization": {
          "advisor_deactivation_period": {
            "never": "Nie"
          },
          "payment_type": {
            "automatic": "Automatisch",
            "invoice": "Rechnung"
          },
          "two_factor": {
            "Advisor": "Berater",
            "ContactPerson": "Kontaktperson",
            "OrganizationManager": "Organisationsverwalter",
            "User": "Teilnehmer"
          }
        },
        "program": {
          "step_order": {
            "approval": "Der Kandidat kann erst fortfahren, nachdem er den aktuellen Schritt genehmigt hat",
            "free": "Der Kandidat kann die Schritte in beliebiger Reihenfolge durchlaufen",
            "submission": "Der Kandidat kann erst fortfahren, nachdem er den aktuellen Schritt abgeschickt hat"
          }
        },
        "program_page": {
          "approve_by": {
            "none": "Dieser Schritt braucht nicht genehmigt zu werden",
            "seen": "Der Schritt wird automatisch genehmigt, wenn der Kandidat ihn anzeigt",
            "set": "Sowohl der Coach als auch der Kandidat können dem Schritt zustimmen",
            "submit": "Der Coach genehmigt den Schritt nach Einreichung durch den Kandidaten"
          }
        },
        "subscription_type": {
          "invoice_line_type": {
            "frequent": "Frequent",
            "intensive": "Intensiv"
          },
          "payment_period": {
            "month": "Monat",
            "quarter": "Quartal",
            "year": "Jahr"
          },
          "type": {
            "AllYouCanSubscription": "All-you-can-report",
            "AnnualSubscription": "Jährlich",
            "DemoSubscription": "Demo",
            "IncidentalSubscription": "Inzidentell",
            "ManualSubscription": "Manuell",
            "ResearchSubscription": "Studie",
            "StandardSubscription": "Standard",
            "TestSubscription": "Test"
          }
        },
        "user": {
          "mailing_frequency": {
            "every_morning": "Sammle Benachrichtigungen und sende sie jeden Morgen per E-Mail.",
            "immediately": "Erhalte sofortige Benachrichtigungen.",
            "never": "Erhalte niemals Benachrichtigungen per E-Mail.",
            "once_an_hour": "Sammle Benachrichtigungen und sende sie stündlich per E-Mail."
          },
          "type": {
            "Advisor": "Berater",
            "ContactPerson": "Kontaktperson",
            "IxlyEmployee": "Ixly-Mitarbeiter",
            "User": "Teilnehmer"
          }
        }
      },
      "placeholders": {
        "search_by_name": "Suche nach Name",
        "search_by_title": "Suche nach Titel",
        "search_for_coadvisor_ids": "Co-Berater suchen",
        "search_for_competencies": "Suchkompetenzen",
        "search_for_contact_person_ids": "Ansprechpartner finden",
        "search_for_job_profile_competencies": "Suchkompetenzen",
        "search_for_label_ids": "Finde ein Etikett",
        "search_for_labels": "Finde ein Etikett",
        "search_for_options": "Suchoptionen",
        "user": {
          "edit": {
            "biography": "Der Text, den Sie hier eingeben, ist für jeden sichtbar, der sich Ihr Profil anschaut"
          }
        }
      },
      "required": {
        "mark": "*",
        "text": "Dieses Feld ist erforderlich"
      }
    },
    "skiplink": "Zum Hauptinhalt springen",
    "status": {
      "advisor": {
        "active": "Aktiv",
        "created": "Erstellt",
        "inactive": "Deaktiviert",
        "invited": "Eingeladen"
      },
      "candidate": {
        "active": "Aktiv",
        "created": "Erstellt",
        "inactive": "Deaktiviert",
        "invited": "Eingeladen"
      },
      "candidate_email_message": {
        "bounced": "Kann nicht geliefert werden",
        "delivered": "Geliefert",
        "loading": "Wird geladen",
        "sent": "Gesendet",
        "transient": "Mögliche Probleme bei der Lieferung",
        "unsubscribed": "Abgemeldet"
      },
      "contact_person": {
        "active": "Aktiv",
        "created": "Erstellt",
        "inactive": "Deaktiviert",
        "invited": "Eingeladen"
      },
      "enroller": {
        "active": "Aktiv",
        "created": "Erstellt",
        "deactivated": "Deaktiviert",
        "inactive": "Deaktiviert"
      },
      "enrollment": {
        "action_required": "Handlung erforderlich",
        "created": "Erstellt",
        "finished": "gerundet",
        "invited": "Eingeladen",
        "started": "Gestartet"
      },
      "enrollment_page": {
        "created": "Erstellt",
        "done": "gerundet",
        "rejected": "Abgelehnt",
        "submitted": "Eingereicht"
      },
      "import_file": {
        "created": "Erstellt",
        "failed": "Gescheitert",
        "processed": "Verarbeitet",
        "validating": "Validierung"
      },
      "mail_template": {
        "draft": "Design",
        "published": "Veröffentlicht"
      },
      "organization": {
        "active": "Aktiv",
        "created": "Erstellt",
        "deleted": "Gelöscht",
        "inactive": "Deaktiviert"
      },
      "price": {
        "active": "Aktiv",
        "expired": "Abgelaufen",
        "pending": "Noch nicht aktiv"
      },
      "product": {
        "active": "Aktiv",
        "deactivated": "Deaktiviert"
      },
      "program": {
        "deleted": "Gelöscht",
        "editable": "Bearbeitbar",
        "published": "Veröffentlicht",
        "template": "Vorlage"
      },
      "program_template": {
        "deleted": "Gelöscht",
        "editable": "Bearbeitbar",
        "published": "Veröffentlicht",
        "template": "Vorlage"
      },
      "ttk_migration": {
        "completed": "Migration abgeschlossen",
        "created": "Erstellt",
        "failed": "Die Migration schlägt fehl",
        "ready_to_migrate": "Bereit zur Migration",
        "syncing_default_reports": "Standardberichte migrieren",
        "syncing_settings": "Einstellungen migrieren",
        "syncing_users_candidates": "Konten migrieren",
        "updating_managed_via_ap": "Vollständige Migration",
        "updating_manager": "Organisationsmanager einrichten"
      },
      "user": {
        "active": "Aktiv",
        "created": "Erstellt",
        "inactive": "Deaktiviert",
        "invited": "Eingeladen"
      }
    },
    "subscription_type_product_status": {
      "active": "Aktiv",
      "deactivated": "Deaktiviert"
    },
    "subscription_type_products": {
      "create": {
        "created": "Die Module wurden dem Abonnementtyp hinzugefügt",
        "products_blank": "Es wurden keine Module ausgewählt"
      },
      "deactivate": {
        "deactivated": "Die Verbindung zum Modul ist deaktiviert"
      },
      "destroy": {
        "deleted": "Das Modul ist aus dem Abonnementtyp entfernt"
      },
      "list": {
        "table_header": [
          "Name",
          "Verbindung",
          "Handlungen"
        ]
      },
      "new": {
        "title": "Module hinzufügen zu %{subscription_type}"
      },
      "reactivate": {
        "reactivated": "Die Verbindung zum Modul ist wieder aktiviert"
      },
      "row": {
        "product_delete": "Sind Sie sicher, dass Sie dieses Modul aus dem Abonnementtyp entfernen wollen?"
      }
    },
    "subscription_type_status": {
      "active": "Aktiv",
      "deactivated": "Deaktiviert"
    },
    "subscription_types": {
      "create": {
        "subscription_type_created": "Der Abonnementtyp ist erstellt",
        "title": "Neuer Abonnementtyp"
      },
      "deactivate": {
        "deactivated": "Der Abonnementtyp ist deaktiviert"
      },
      "destroy": {
        "deleted": "Der Abonnementtyp ist gelöscht"
      },
      "edit": {
        "title": "Abonnementtyp bearbeiten"
      },
      "index": {
        "new_button": "Neuer Abonnementtyp",
        "table_header": [
          "Name",
          "Status",
          "Status geändert am",
          "Aktive Abonnements",
          "Kategorie",
          "Handlungen"
        ],
        "title": "Abonnementtypen"
      },
      "new": {
        "title": "Neuer Abonnementtyp"
      },
      "reactivate": {
        "reactivated": "Der Abonnementtyp ist wieder aktiviert"
      },
      "row": {
        "subscription_type_delete": "Sind Sie sicher, dass Sie diesen Abonnementtyp löschen wollen?"
      },
      "show": {
        "disabled": "Nein",
        "enabled": "Ja",
        "product_new_button": "Module hinzufügen",
        "status": "Status"
      },
      "update": {
        "subscription_type_updated": "Der Abonnementtyp ist aktualisiert",
        "title": "Abonnementtyp bearbeiten"
      }
    },
    "support": {
      "array": {
        "last_word_connector": " und ",
        "two_words_connector": " und ",
        "words_connector": ", "
      }
    },
    "templates": {
      "document": "Dokument"
    },
    "time": {
      "am": "vormittags",
      "formats": {
        "date_with_day": "%A, %e. %B %Y",
        "date_with_time": "%e. %B %Y, %H:%M Uhr",
        "date_with_time_short": "%e %b %Y, %H:%M",
        "date_without_day": "%e. %B %Y",
        "date_without_day_and_year": "%d %b",
        "date_without_time": "%d-%m-%Y",
        "default": "%A, %d. %B %Y, %H:%M Uhr",
        "long": "%A, %d. %B %Y, %H:%M Uhr",
        "short": "%d. %b, %H:%M Uhr",
        "time_only": "%H:%M Uhr",
        "time_with_date": "%H:%M Uhr (%e. %B %Y)"
      },
      "pm": "nachmittags"
    },
    "translations": {
      "actions": "Handlungen",
      "edit": "Übersetzung bearbeiten",
      "new": "Neue Übersetzung",
      "show": "Übersetzung anschauen",
      "source": "Quelle",
      "translation_created": "Die Übersetzung ist erstellt",
      "translation_destroyed": "Die Übersetzung ist gelöscht",
      "translation_updated": "Die Übersetzung ist aktualisiert"
    },
    "two_factor": {
      "activate_help_text": "Scannen Sie diesen QR-Code mit Ihrer Authentifizierungs-App (beispielsweise Google Authenticator oder Authy) und geben Sie den Code ein, um die zweistufige Verifizierung zu aktivieren.",
      "title": "Verifizierung in zwei Schritten",
      "verify_help_text": "Geben Sie den Code aus Ihrer Authentifizierungs-App ein"
    },
    "type": {
      "Advisor": "Berater",
      "Candidate": "Kandidat",
      "CandidateAssessmentEntry": "Bewertung",
      "CandidateAssessmentProgramEntry": "Bewertungsprogramm",
      "CandidateReportEntry": "Bericht (nur Test)",
      "Cocoach": "Co-Berater",
      "ContactPerson": "Kontaktperson",
      "GeneratedReportEntry": "Bericht (Prozesse)",
      "IxlyEmployee": "Ixly-Mitarbeiter",
      "LeniencyEntry": "Freundlichkeit",
      "OrderEntry": "Befehl",
      "PremiumCandidate": "Kandidat",
      "TestOnlyCandidate": "Kandidat",
      "User": "Teilnehmer",
      "plural": {
        "Advisor": "Berater",
        "Candidate": "Kandidaten",
        "ContactPerson": "Kontaktpersonen",
        "PremiumCandidate": "Kandidaten",
        "TestOnlyCandidate": "Kandidaten"
      }
    },
    "unauthorized": {
      "manage": {
        "all": "Für diese Handlung sind Sie nicht autorisiert"
      }
    },
    "user_types": {
      "Advisor": "Berater",
      "Candidate": "Kandidat",
      "Co_coach": "Zweitcoach",
      "Coach": "Berater",
      "ContactPerson": "Kontaktperson"
    },
    "users": {
      "all": {
        "new_button": "Neuer Nutzer",
        "table_header": [
          "Name",
          "Organisation",
          "Rollen",
          "Status",
          "Kostenstelle"
        ],
        "title": "Alle Nutzer"
      },
      "confirmations": {
        "form": {
          "privacy_accepted_label": "Ich stimme den <a href='%{terms_url}' target='_blank' class='underline hover:text-blue-light'>Geschäftsbedingungen</a> und den <a href='%{privacy_statement_url}' target='_blank' zu. class='underline hover:text-blue-light'>Datenschutzerklärung</a>",
          "submit": "Fortsetzen"
        },
        "show": {
          "already_confirmed_account": "<span class='font-medium'>Hinweis!</span> Es existiert bereits ein Konto mit dieser E-Mail-Adresse. Verwenden Sie dasselbe Passwort, um sich für Ihr neues Konto anzumelden.",
          "already_confirmed_resource": "Sie haben bereits ein Konto erstellt. Verwenden Sie Ihre E-Mail-Adresse und Ihr Passwort, um sich anzumelden.",
          "confirmed": "Die E-Mail-Adresse Ihres Kontos wurde bestätigt. Melden Sie sich hier mit Ihrem Konto an",
          "title": "ein Konto erstellen"
        },
        "update": {
          "confirmed": "Ihr Konto wurde erstellt. Melden Sie sich hier mit Ihrem Konto an"
        }
      },
      "create": {
        "created": "Der Nutzer ist erstellt",
        "created_and_invited": "Der Nutzer ist erstellt und mit einer E-Mail willkommen geheißen",
        "help_text": "Erstellen Sie hier einen neuen Nutzer für Ihre Organisation",
        "more_information": "Mehr Informationen",
        "title": "Neuer Nutzer"
      },
      "deactivate": {
        "user_deactivated": "Der Nutzer ist deaktiviert"
      },
      "edit": {
        "title": "Nutzer bearbeiten"
      },
      "form": {
        "impersonation_explanation": "Wenn Sie diese Option wählen, geben Sie diesem Nutzer das Recht, sich im Account eines anderen Nutzers einzuloggen.",
        "labels": "Labels",
        "unconfirmed_email": "Die E-Mail-Adresse wird geändert in:",
        "unconfirmed_email_explanation": "Der Inhaber der E-Mail-Adresse muss die Änderung über einen Link bestätigen. Die geänderte E-Mail-Adresse wird erst nach Bestätigung verwendet.",
        "upload_avatar": "Klicken Sie auf die Abbildung, um ein eigenes Foto hochzuladen",
        "user_type_documentation": "(hier klicken für Informationen zu den Nutzerrollen)",
        "your_labels": "Ihre Labels"
      },
      "index": {
        "help_text": "Hier finden Sie alle Nutzer Ihrer Organisation",
        "import": "CSV Import",
        "new_button": "Neuer Nutzer",
        "title": "Nutzer"
      },
      "new": {
        "help_text": "Erstellen Sie hier einen neuen Nutzer für Ihre Organisation",
        "title": "Neuer Nutzer"
      },
      "password": {
        "title": "Passwort ändern"
      },
      "reactivate": {
        "user_reactivated": "Der Nutzer ist wieder aktiviert"
      },
      "removed_member": "%{type} gelöscht",
      "reset_password": {
        "sent": "Die Mail zur Passwortwiederherstellung ist verschickt"
      },
      "row": {
        "number_of_assigned_programs": {
          "one": "1 Prozess",
          "other": "%{count} Prozesse"
        }
      },
      "send_welcome": {
        "bulk_button": "Einladen",
        "welcome_mail_sent": "Die Willkommensmail ist verschickt"
      },
      "show": {
        "account_manager": "Account-Manager von",
        "advisor": "Berater",
        "biography": "Persönliche Beschreibung",
        "candidate_overview": "Kandidatenübersicht",
        "client": "Auftraggeber",
        "cost_center": "Kostenstelle",
        "created_at": "Erstellt am",
        "disabled": "Ausgeschaltet",
        "email": "E-Mail-Adresse",
        "enabled": "Eingeschaltet",
        "impersonate": "Einloggen als",
        "organization": "Organisation",
        "resend_welcome_email": "Willkommensmail erneut verschicken",
        "reset_password": "Passwort wiederherstellen",
        "send_email": "E-Mail senden",
        "send_welcome_email": "Willkommensmail verschicken",
        "status": "Status",
        "two_factor": "Verifizierung in zwei Schritten",
        "type": "Rolle",
        "user_overview": "Nutzerübersicht"
      },
      "update": {
        "title": "Nutzer bearbeiten",
        "updated": "Der Nutzer ist aktualisiert"
      }
    },
    "validations": {
      "minlength_12": "mindestens zwölf Zeichen."
    },
    "valvat": {
      "country_adjectives": {
        "at": "österreichische",
        "be": "belgische",
        "bg": "bulgarische",
        "cy": "zypriotische",
        "cz": "tschechische",
        "de": "deutsche",
        "dk": "dänische",
        "ee": "estnische",
        "es": "spanische",
        "eu": "europäische",
        "fi": "finnische",
        "fr": "französische",
        "gb": "britische",
        "gr": "griechische",
        "hu": "ungarische",
        "ie": "irische",
        "it": "italienische",
        "lt": "litauische",
        "lu": "luxemburgische",
        "lv": "lettische",
        "mt": "maltesische",
        "nl": "niederländische",
        "pl": "polnische",
        "pt": "portugiesische",
        "ro": "rumänische",
        "se": "schwedische",
        "si": "slowenische",
        "sk": "slowakische"
      }
    },
    "will_paginate": {
      "next_label": "Nächste &#8594;",
      "page_gap": "&hellip;",
      "previous_label": "&#8592; Vorige"
    }
  }
}